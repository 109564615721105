import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { auditsActions } from 'Modules/audits';
import { getAuditTasks } from 'Modules/tasks/selectors';
import { useSelectedOperatorOrThrow, useStateSelector } from 'hooks';
import { Div, FlexLoaderPanel } from 'Components';

import NextAuditCard from '../Dashboards/Components/NextAuditCard';
import TaskTable from './TaskTable';

const OperatorAuditTasks = () => {
  const dispatch = useDispatch();

  const operator = useSelectedOperatorOrThrow();
  const nextAudit = useStateSelector(state => operator.nextAuditId ? state.audits.entities.audits[operator.nextAuditId] : undefined);
  const isLoadingOperatorAudit = useStateSelector(state => state.audits.loading.isLoadingOperatorAudit);
  const nextAuditTasks = useStateSelector(getAuditTasks);

  useEffect(() => {
    if (operator.nextAuditId) {
      dispatch(auditsActions.getOperatorAudit(operator.nextAuditId));
      dispatch(auditsActions.getOperatorAuditTasks(operator.nextAuditId, true));
    }
  }, []);

  return (
    <FlexLoaderPanel className="pt-2" isLoading={isLoadingOperatorAudit}>
      {!nextAudit && (
        <>
          <Row>
            <Col className="dashboard-column" md={6} xl={3}>
              <NextAuditCard audit={nextAudit}/>
            </Col>
          </Row>
        </>
      )}
      {nextAudit && (
        <>
          <Row>
            <Col className="dashboard-column" md={6} xl={3}>
              <NextAuditCard audit={nextAudit}/>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Div white>
                <TaskTable audit={nextAudit} tasks={nextAuditTasks}/>
              </Div>
            </Col>
          </Row>
        </>
      )}
    </FlexLoaderPanel>
  );
};

export default OperatorAuditTasks;
