import './list-panel.scss';

import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { DebounceInputProps } from 'react-debounce-input';
import { Button } from 'reactstrap';

import { PaginatedRequest } from 'core';
import { Flex, FlexColumn, FlexLoader, LoadableButtonProps, PaginationShowing, ScrollPanel, SearchInputCollapse } from 'Components';

export interface ListPanelProps {
  Header?: ReactNode;
  panelClassName?: string;
  headerContainerClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
  isLoading?: boolean;
  search?: {
    inputProps?: Omit<DebounceInputProps<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>, 'onChange' | 'value'>;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    value: string;
    isLoading?: boolean;
  };
  Tools?: ReactNode;
  loadMore?: {
    hasMore: boolean;
    pagination?: {
      request: PaginatedRequest;
      total: number;
    };
    onClick: (e: React.MouseEvent) => void;
    buttonProps?: LoadableButtonProps;
  };
}

export interface Props extends ListPanelProps {
  children: ReactNode;
}

export interface ListPanelRef {
  scrollTop: (value: number) => void,
  scrollToBottom: () => void,
}
const ListPanel = ({
  Header,
  Tools,
  search,
  isLoading,
  headerContainerClassName,
  headerClassName,
  contentClassName,
  panelClassName,
  children,
  loadMore,
}: Props) => {
  return (
    <div className={classnames('list-panel', panelClassName)}>
      <div
        className={classnames(
          'list-panel-header-container',
          headerContainerClassName
        )}
      >
        <div className={classnames('list-panel-header', headerClassName)}>
          {Header}

          <div>
            {Tools}
          </div>
        </div>

        {search && (
          <SearchInputCollapse {...search.inputProps} onChange={search.onChange} isShowing isLoading={search.isLoading} />
        )}
      </div>

      <div className={classnames('list-panel-content', contentClassName)}>
        <ScrollPanel>
          {children}
        </ScrollPanel>

        {isLoading && (
          <Flex fill overlay>
            <FlexLoader />
          </Flex>
        )}
      </div>

      {loadMore && (
        <FlexColumn alignCenter className="border-top p-1">
          <Button
            color="anchor"
            {...loadMore.buttonProps}
            onClick={loadMore.onClick}
            disabled={!loadMore.hasMore}
          >
            <i className="mdi mdi-chevron-double-down" /> Load More <i className="mdi mdi-chevron-double-down" />
          </Button>
          {loadMore.pagination && (
            <span className="text-muted small">
              Showing <PaginationShowing request={loadMore.pagination.request} total={loadMore.pagination.total} />
            </span>
          )}
        </FlexColumn>
      )}
    </div>
  );
};

export default ListPanel;
