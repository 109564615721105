import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Input, InputProps } from 'reactstrap';

import { isInteger, isIntegerInputValid } from '../helpers/formatHelpers';

interface Props extends InputProps {
  value: string;
  onNumberChange: (integer?: number) => void;
}

const IntegerInput = (props: Props) => {
  const { onNumberChange, value, ...rest } = props;
  const [ localValue, setLocalValue ] = useState<string>(value);

  useEffect(() => {
    if (parseInt(localValue, 10) !== parseInt(value, 10)) {
      setLocalValue(value);
    }
  }, [ localValue, value ]);


  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    if (!isIntegerInputValid(value)) {
      return;
    }

    setLocalValue(value);

    if (!_.trim(value)) {
      props.onNumberChange(undefined);
      return;
    }

    if (isInteger(value)) {
      props.onNumberChange(parsedValue);
    }
  }

  return (
    <Input {...rest} onChange={onChange} value={localValue || ''}/>
  );
};

export default IntegerInput;
