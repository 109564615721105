import { commonActions } from '../common/actions';
import appConfig from '../../config';
import { Action, ApiAction, OperatorAuditModel } from '../../models';
import { SuperAgent } from 'superagent';

export const auditsActions = {
  CHANGE_MODIFYING_AUDIT_VALUES: 'AVSolutions/audits/changeModifyingAuditValues',

  GET_OPERATOR_AUDITS_REQUEST: 'AVSolutions/audits/GetOperatorAuditsRequest',
  GET_OPERATOR_AUDITS_SUCCESS: 'AVSolutions/audits/GetOperatorAuditsSuccess',
  GET_OPERATOR_AUDITS_FAILURE: 'AVSolutions/audits/GetOperatorAuditsFailure',

  GET_OPERATOR_AUDIT_REQUEST: 'AVSolutions/audits/GetOperatorAuditRequest',
  GET_OPERATOR_AUDIT_SUCCESS: 'AVSolutions/audits/GetOperatorAuditSuccess',
  GET_OPERATOR_AUDIT_FAILURE: 'AVSolutions/audits/GetOperatorAuditFailure',

  GET_OPERATOR_AUDIT_TASKS_REQUEST: 'AVSolutions/audits/GetOperatorAuditTasksRequest',
  GET_OPERATOR_AUDIT_TASKS_SUCCESS: 'AVSolutions/audits/GetOperatorAuditTasksSuccess',
  GET_OPERATOR_AUDIT_TASKS_FAILURE: 'AVSolutions/audits/GetOperatorAuditTasksFailure',

  UPDATE_OPERATOR_AUDIT_REQUEST: 'AVSolutions/audits/UpdateOperatorAuditRequest',
  UPDATE_OPERATOR_AUDIT_SUCCESS: 'AVSolutions/audits/UpdateOperatorAuditSuccess',
  UPDATE_OPERATOR_AUDIT_FAILURE: 'AVSolutions/audits/UpdateOperatorAuditFailure',

  INSERT_OPERATOR_AUDIT_REQUEST: 'AVSolutions/audits/InsertOperatorAuditRequest',
  INSERT_OPERATOR_AUDIT_SUCCESS: 'AVSolutions/audits/InsertOperatorAuditSuccess',
  INSERT_OPERATOR_AUDIT_FAILURE: 'AVSolutions/audits/InsertOperatorAuditFailure',

  SYNC_AUDIT_TEMPLATE_TO_AUDIT_REQUEST: 'AVSolutions/audits/SyncAuditTemplateToAuditRequest',
  SYNC_AUDIT_TEMPLATE_TO_AUDIT_SUCCESS: 'AVSolutions/audits/SyncAuditTemplateToAuditSuccess',
  SYNC_AUDIT_TEMPLATE_TO_AUDIT_FAILURE: 'AVSolutions/audits/SyncAuditTemplateToAuditFailure',

  DELETE_OPERATOR_AUDIT_REQUEST: 'AVSolutions/audits/DeleteOperatorAuditRequest',
  DELETE_OPERATOR_AUDIT_SUCCESS: 'AVSolutions/audits/DeleteOperatorAuditSuccess',
  DELETE_OPERATOR_AUDIT_FAILURE: 'AVSolutions/audits/DeleteOperatorAuditFailure',

  DELETE_OPERATOR_AUDIT_TASKS_REQUEST: 'AVSolutions/audits/DeleteOperatorAuditTasksRequest',
  DELETE_OPERATOR_AUDIT_TASKS_SUCCESS: 'AVSolutions/audits/DeleteOperatorAuditTasksSuccess',
  DELETE_OPERATOR_AUDIT_TASKS_FAILURE: 'AVSolutions/audits/DeleteOperatorAuditTasksFailure',
};

export const changeModifyingAuditValues = (values: any | null): Action => {
  return {
    type: auditsActions.CHANGE_MODIFYING_AUDIT_VALUES,
    payload: {
      values: values,
    },
  };
};

export const hideAuditModifyModal = (): Action => changeModifyingAuditValues(null);

export const getOperatorAudit = (operatorAuditId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/audits/${operatorAuditId}`);
      },
      beforeRequest: (): Action => ({ type: auditsActions.GET_OPERATOR_AUDIT_REQUEST }),
      success: (data: any): Action => ({ type: auditsActions.GET_OPERATOR_AUDIT_SUCCESS, payload: { operatorAuditId, audit: data } }),
      failure: (response: any): Action => ({ type: auditsActions.GET_OPERATOR_AUDIT_FAILURE, payload: { ...response } }),
    },
  };
};

export const getOperatorAudits = (operatorId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/audits/`)
          .query({ operatorId: operatorId });
      },
      beforeRequest: (): Action => ({ type: auditsActions.GET_OPERATOR_AUDITS_REQUEST }),
      success: (data: any): Action => ({ type: auditsActions.GET_OPERATOR_AUDITS_SUCCESS, payload: { operatorId, audits: data } }),
      failure: (response: any): Action => ({ type: auditsActions.GET_OPERATOR_AUDITS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getOperatorAuditTasks = (auditId: number, includeChildren: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/audits/${auditId}/tasks`).query({ includeChildren });
      },
      beforeRequest: (): Action => ({ type: auditsActions.GET_OPERATOR_AUDIT_TASKS_REQUEST }),
      success: (data: any): Action => ({ type: auditsActions.GET_OPERATOR_AUDIT_TASKS_SUCCESS, payload: { auditId, tasks: data } }),
      failure: (response: any): Action => ({ type: auditsActions.GET_OPERATOR_AUDIT_TASKS_FAILURE, payload: { ...response } }),
    },
  };
};

export const updateAudit = (operator: any): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/audits`)
          .send(operator);
      },
      beforeRequest: (): Action => ({ type: auditsActions.UPDATE_OPERATOR_AUDIT_REQUEST, payload: { operator } }),
      success: (data: any): Action => ({ type: auditsActions.UPDATE_OPERATOR_AUDIT_SUCCESS, payload: { audit: data } }),
      failure: (response: any): Action => ({ type: auditsActions.UPDATE_OPERATOR_AUDIT_FAILURE, payload: { ...response, operator } }),
    },
  };
};

export const insertAudit = (audit: any): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/audits`)
          .send(audit);
      },
      beforeRequest: (): Action => ({ type: auditsActions.INSERT_OPERATOR_AUDIT_REQUEST, payload: { audit } }),
      success: (data: any): Action => ({ type: auditsActions.INSERT_OPERATOR_AUDIT_SUCCESS, payload: { audit: data } }),
      failure: (response: any): Action => ({ type: auditsActions.INSERT_OPERATOR_AUDIT_FAILURE, payload: { ...response, audit } }),
    },
  };
};

export const syncAuditTemplateToAudit = (operatorAuditId: number, auditTemplateId: number, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/audits/${operatorAuditId}/templates/${auditTemplateId}/sync`);
      },
      beforeRequest: (): Action => ({ type: auditsActions.SYNC_AUDIT_TEMPLATE_TO_AUDIT_REQUEST }),
      success: (): Action => ({ type: auditsActions.SYNC_AUDIT_TEMPLATE_TO_AUDIT_SUCCESS }),
      successCallback,
      failure: (response: any): Action => ({ type: auditsActions.SYNC_AUDIT_TEMPLATE_TO_AUDIT_FAILURE, payload: { ...response } }),
    },
  };
};

export const deleteAudit = (operatorAudit: OperatorAuditModel): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/audits/${operatorAudit.operatorAuditId}`);
      },
      beforeRequest: (): Action => ({ type: auditsActions.DELETE_OPERATOR_AUDIT_REQUEST, payload: { operatorAuditId: operatorAudit.operatorAuditId } }),
      success: (): Action => ({ type: auditsActions.DELETE_OPERATOR_AUDIT_SUCCESS, payload: { operatorAuditId: operatorAudit.operatorAuditId, operatorId: operatorAudit.operatorId } }),
      failure: (response: any): Action => ({ type: auditsActions.DELETE_OPERATOR_AUDIT_FAILURE, payload: { ...response, operatorAuditId: operatorAudit.operatorAuditId } }),
    },
  };
};

export const deleteAuditTasks = (operatorAuditId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/audits/${operatorAuditId}/tasks`);
      },
      beforeRequest: (): Action => ({ type: auditsActions.DELETE_OPERATOR_AUDIT_TASKS_REQUEST, payload: { operatorAuditId } }),
      success: (): Action[] => ([
        { type: auditsActions.DELETE_OPERATOR_AUDIT_TASKS_SUCCESS, payload: { operatorAuditId: operatorAuditId } },
        getOperatorAuditTasks(operatorAuditId, false),
      ]),
      failure: (response: any): Action => ({ type: auditsActions.DELETE_OPERATOR_AUDIT_TASKS_FAILURE, payload: { ...response, operatorAuditId } }),
    },
  };
};
