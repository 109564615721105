import React from 'react';
import { EditorProps } from 'react-draft-wysiwyg';
import classnames from 'classnames';

import DraftEditor from './DraftEditor';
import UploadMessageFileOption from 'Components/Draft/Editor/Options/UploadMessageFileOption';
import _ from 'lodash';

interface Props extends EditorProps {
  fileType: string;
  uploadFile?: (file: File) => Promise<any>;
}

const MessageDraftEditor = (props: Props) => {
  const CustomOptions = _.compact([
    // @ts-ignore
    props.uploadFile ? <UploadMessageFileOption key="upload-file" uploadFile={props.uploadFile} fileType={props.fileType} /> : null,
  ]);

  return (
    <DraftEditor
      wrapperClassName={classnames(props.wrapperClassName, { 'read-only': props.readOnly })}
      toolbar={{
        options: [
          'inline',
          'link',
        ],
        inline: {
          options: [ 'bold', 'italic', 'underline', 'strikethrough' ],
        },
      }}
      {...props}
      toolbarCustomButtons={CustomOptions}
    />
  );
};

export default MessageDraftEditor;
