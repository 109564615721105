import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedIdStateSelector, useStateSelector } from 'hooks';
import { PollOption, pollsActions, pollsSelectors } from 'Modules/polls';

const usePollOptions = (pollId: number): [ PollOption[], boolean ] => {
  const dispatch = useDispatch();
  const pollOptions = useMemoizedIdStateSelector(pollId, pollsSelectors.makeGetOptionsByPoll);
  const isLoadingPollOptions = useStateSelector(state => state.polls.loading.isLoadingPollOptions);

  useEffect(() => {
    dispatch(pollsActions.async.getAllPollOptions(pollId));
  }, [ pollId ]);

  return [ pollOptions, isLoadingPollOptions ];
};

export default usePollOptions;
