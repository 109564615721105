import React, { ReactNode, useEffect } from 'react';
import useStateSelector from './hooks/useStateSelector';
import { useDispatch } from 'react-redux';
import { userActions } from 'Modules/users';

interface Props {
  children: ReactNode;
}

const UserLoader = ({ children }: Props) => {
  const dispatch = useDispatch();
  const userIsLoaded = useStateSelector(state => !!state.site.user);

  useEffect(() => {
    (async () => {
      dispatch(userActions.getCurrentLoggedInUser());
    })();
  }, [ ]);

  if (!userIsLoaded) {
    return null;
  }

  return <>{children}</>;
};

export default UserLoader;
