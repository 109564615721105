import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DomClass, Panel } from 'Components';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import SurveyResultsModal from './SurveyResultsModal';

const SurveyPage = () => {
  const survey = new Model({
    'title': 'Operator Profile - BABP',
    'logo': 'https://api.surveyjs.io/private/Surveys/files?name=474ae004-27cb-4eb5-a9ae-166e9eb884a1',
    'logoWidth': '500',
    'logoPosition': 'right',
    'pages': [
      {
        'name': 'page1',
        'elements': [
          {
            'type': 'multipletext',
            'name': 'question2',
            'state': 'expanded',
            'title': 'Company Information',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Company Name',
              },
              {
                'name': 'text4',
                'title': 'DBA',
              },
              {
                'name': 'text2',
                'title': 'Street Address',
              },
              {
                'name': 'text3',
                'title': 'City, State, Zip',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question13',
            'state': 'expanded',
            'title': 'Accountable Executive',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Name',
              },
              {
                'name': 'text2',
                'title': 'E-Mail',
              },
              {
                'name': 'text3',
                'title': 'Phone',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question9',
            'state': 'expanded',
            'title': 'Director of Aviation',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Name',
              },
              {
                'name': 'text2',
                'title': 'E-Mail',
              },
              {
                'name': 'text3',
                'title': 'Phone',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question12',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Director of Maintenance',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Name',
              },
              {
                'name': 'text2',
                'title': 'E-Mail',
              },
              {
                'name': 'text3',
                'title': 'Phone',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question10',
            'state': 'expanded',
            'title': 'Chief Pilot',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Name',
              },
              {
                'name': 'text2',
                'title': 'E-Mail',
              },
              {
                'name': 'text3',
                'title': 'Phone',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question11',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Safety Manager',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Name',
              },
              {
                'name': 'text2',
                'title': 'E-Mail',
              },
              {
                'name': 'text3',
                'title': 'Phone',
              },
            ],
          },
          {
            'type': 'text',
            'name': 'question3',
            'state': 'expanded',
            'title': 'Airport Code',
            'hideNumber': true,
          },
          {
            'type': 'text',
            'name': 'question4',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Assigned FSDO',
            'hideNumber': true,
          },
          {
            'type': 'multipletext',
            'name': 'question5',
            'state': 'expanded',
            'title': 'Aircraft 1',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Type',
              },
              {
                'name': 'text2',
                'title': 'Tail Number',
              },
              {
                'name': 'text3',
                'title': 'Hanger Address',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question7',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Aircraft 2',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Type',
              },
              {
                'name': 'text2',
                'title': 'Tail Number',
              },
              {
                'name': 'text3',
                'title': 'Hanger Address',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question8',
            'state': 'expanded',
            'title': 'Aircraft 3',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Type',
              },
              {
                'name': 'text2',
                'title': 'Tail Number',
              },
              {
                'name': 'text3',
                'title': 'Hanger Address',
              },
            ],
          },
          {
            'type': 'multipletext',
            'name': 'question1',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Aircraft 4',
            'hideNumber': true,
            'items': [
              {
                'name': 'text1',
                'title': 'Type',
              },
              {
                'name': 'text2',
                'title': 'Tail Number',
              },
              {
                'name': 'text3',
                'title': 'Hanger Address',
              },
            ],
          },
        ],
        'title': 'General Overview ',
        'description': 'Maintenance',
      },
      {
        'name': 'page2',
        'elements': [
          {
            'type': 'checkbox',
            'name': 'question15',
            'state': 'expanded',
            'title': 'Current Tool Calibration Vendor\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'AA Calibration',
              },
              {
                'value': 'Item 2',
                'text': 'Caltronix, Henrietta NY',
              },
              {
                'value': 'Item 3',
                'text': 'Fox Valley Metrology',
              },
              {
                'value': 'Item 4',
                'text': 'Tool Testing Lab, Ohio',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
            'maxSelectedChoices': 1,
          },
          {
            'type': 'checkbox',
            'name': 'question16',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Current Aircraft Maintenance Tracking Software Vendor\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'CAMP',
              },
              {
                'value': 'Item 2',
                'text': 'FlightDocs',
              },
              {
                'value': 'Item 3',
                'text': 'Traxall',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
            'maxSelectedChoices': 1,
          },
          {
            'type': 'checkbox',
            'name': 'question17',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Current Authorized Maintenance Organization (AMO)\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'Duncan Aviation',
              },
              {
                'value': 'Item 2',
                'text': 'Goodyear / ZLT Zeppelin Luftschifftechnik',
              },
              {
                'value': 'Item 3',
                'text': 'Gulfstream',
              },
              {
                'value': 'Item 4',
                'text': 'Textron Service Center',
              },
              {
                'value': 'Item 5',
                'text': 'WestStar',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
            'maxSelectedChoices': 1,
          },
          {
            'type': 'checkbox',
            'name': 'question18',
            'state': 'expanded',
            'title': 'What is your average aircraft downtime for maintenance\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': '< 3 days',
              },
              {
                'value': 'Item 2',
                'text': '3 - 5 days',
              },
              {
                'value': 'Item 3',
                'text': '> 5 days',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
            'maxSelectedChoices': 1,
          },
          {
            'type': 'checkbox',
            'name': 'question19',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Current Fall Protection Vendor - Annual System & Harness Checks\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'Airgas provides annual inspections / 3M provides annual training',
              },
              {
                'value': 'Item 2',
                'text': 'Dival, Buffalo NY. for inspections and certificate of compliance',
              },
              {
                'value': 'Item 3',
                'text': 'have In-House Competent Person for Fall Protection - all FP training and equipment inspections are completed in-house annually',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
            'maxSelectedChoices': 1,
          },
          {
            'type': 'checkbox',
            'name': 'question20',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Current Aircraft Cleaning Company\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'In-house - Aircraft cleaner team member',
              },
              {
                'value': 'Item 2',
                'text': 'PWK, CAVU Detailing, OMA, Sky Aviation',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
            'maxSelectedChoices': 1,
          },
          {
            'type': 'boolean',
            'name': 'question21',
            'state': 'expanded',
            'title': 'Does your organization own/run a fuel farm\n',
          },
          {
            'type': 'boolean',
            'name': 'question6',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Do you perform heavy maintenance in-house\n',
          },
        ],
        'title': 'Operations',
        'description': 'Maintenance',
      },
      {
        'name': 'page3',
        'elements': [
          {
            'type': 'checkbox',
            'name': 'question22',
            'state': 'expanded',
            'title': 'Current Flight Planning Vendor',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'ARINC',
              },
              {
                'value': 'Item 2',
                'text': 'ForeFlight',
              },
              'Item 3',
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
          },
          {
            'type': 'checkbox',
            'name': 'question26',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Current Upset Recovery Vendor\n',
            'choices': [
              {
                'value': 'Item 4',
                'text': 'APS - Aviation Performance Solutions',
              },
              {
                'value': 'Item 5',
                'text': 'In House - Due to unique aircraft',
              },
              {
                'value': 'Item 2',
                'text': 'None',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
          },
          {
            'type': 'text',
            'name': 'question23',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Current International Trip Handling Vendor\n',
          },
          {
            'type': 'checkbox',
            'name': 'question29',
            'state': 'expanded',
            'title': 'What percentage of flying makes up international destinations',
            'choices': [
              {
                'value': 'Item 1',
                'text': '< 10%',
              },
              {
                'value': 'Item 2',
                'text': '10% - 20%',
              },
              {
                'value': 'Item 3',
                'text': '20% - 30%',
              },
              {
                'value': 'Item 4',
                'text': '30% - 50%',
              },
              {
                'value': 'Item 5',
                'text': '> 50%',
              },
            ],
          },
          {
            'type': 'text',
            'name': 'question27',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'What are your PIC hiring minimums\n',
          },
          {
            'type': 'checkbox',
            'name': 'question24',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'Frequency of pilots going to simulator training\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'Every 3 months',
              },
              {
                'value': 'Item 2',
                'text': 'Every 6 months',
              },
              {
                'value': 'Item 3',
                'text': 'Every 9 months',
              },
              {
                'value': 'Item 4',
                'text': 'Annually',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
          },
          {
            'type': 'text',
            'name': 'question28',
            'state': 'expanded',
            'title': 'How many aircraft are in your fleet?\n',
          },
          {
            'type': 'checkbox',
            'name': 'question25',
            'state': 'expanded',
            'startWithNewLine': false,
            'title': 'What is the average age of your aircraft?\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': '< 10 years',
              },
              {
                'value': 'Item 2',
                'text': '10 - 15 years',
              },
              {
                'value': 'Item 3',
                'text': '15 - 20 years',
              },
              {
                'value': 'Item 4',
                'text': '> 20 years',
              },
            ],
            'showOtherItem': true,
            'otherText': 'Other (please specify)',
          },
          {
            'type': 'boolean',
            'name': 'question30',
            'title': 'SOPs established',
          },
          {
            'type': 'boolean',
            'name': 'question31',
            'startWithNewLine': false,
            'title': 'International SOPs established',
          },
          {
            'type': 'boolean',
            'name': 'question32',
            'startWithNewLine': false,
            'title': 'EFBs in cockpit',
          },
        ],
        'title': 'Operations',
        'description': 'Flight Ops',
      },
      {
        'name': 'page4',
        'elements': [
          {
            'type': 'checkbox',
            'name': 'question14',
            'title': 'What is your Organizational Goal?\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'Customer Service',
              },
              {
                'value': 'Item 2',
                'text': 'Hours',
              },
              {
                'value': 'Item 3',
                'text': 'For Profit',
              },
              {
                'value': 'Item 4',
                'text': 'Quality',
              },
              {
                'value': 'Item 5',
                'text': 'Executive Company Transportation',
              },
            ],
          },
          {
            'type': 'text',
            'name': 'question33',
            'startWithNewLine': false,
            'title': 'Total Fleet Hours per Year',
          },
          {
            'type': 'text',
            'name': 'question34',
            'startWithNewLine': false,
            'title': 'Average Pilot Hours per Year',
          },
          {
            'type': 'text',
            'name': 'question35',
            'title': '# of Pilots\n',
          },
          {
            'type': 'checkbox',
            'name': 'question37',
            'startWithNewLine': false,
            'title': 'Contract Pilots',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'YES',
              },
              {
                'value': 'Item 2',
                'text': 'NO',
              },
            ],
            'showOtherItem': true,
            'otherText': 'If YES - from where',
          },
          {
            'type': 'text',
            'name': 'question36',
            'title': '# of Mx Personnel\n',
          },
          {
            'type': 'checkbox',
            'name': 'question38',
            'startWithNewLine': false,
            'title': 'Contract Mx',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'YES',
              },
              {
                'value': 'Item 2',
                'text': 'NO',
              },
            ],
            'showOtherItem': true,
            'otherText': 'If YES - from where',
          },
          {
            'type': 'text',
            'name': 'question39',
            'title': '# of Cabin/Flight Attendants\n',
          },
          {
            'type': 'checkbox',
            'name': 'question40',
            'startWithNewLine': false,
            'title': 'Contract Cabin/Flight Attendant\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'YES',
              },
              {
                'value': 'Item 2',
                'text': 'NO',
              },
            ],
            'showOtherItem': true,
            'otherText': 'If YES - from where',
          },
          {
            'type': 'text',
            'name': 'question41',
            'title': '# of Scheduler/Dispatcher\n',
          },
          {
            'type': 'checkbox',
            'name': 'question42',
            'startWithNewLine': false,
            'title': 'Contract Scheduler/Dispatcher\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'YES',
              },
              {
                'value': 'Item 2',
                'text': 'NO',
              },
            ],
            'showOtherItem': true,
            'otherText': 'If YES - from where',
          },
          {
            'type': 'text',
            'name': 'question43',
            'title': '# of Line Service Staff\n',
          },
          {
            'type': 'checkbox',
            'name': 'question44',
            'startWithNewLine': false,
            'title': 'Do you use a vendor for line service at home\n',
            'choices': [
              {
                'value': 'Item 1',
                'text': 'YES',
              },
              {
                'value': 'Item 2',
                'text': 'NO',
              },
            ],
            'showOtherItem': true,
            'otherText': 'If YES - name of vendor',
          },
        ],
        'title': 'Operations',
        'description': 'Organizational Footprint',
      },
      {
        'name': 'page5',
        'title': 'Training',
      },
    ],
    'showQuestionNumbers': 'off',
    'showProgressBar': 'belowheader',
    'progressBarShowPageNumbers': true,
    'autoGrowComment': true,
    'completeText': 'Submit',
    'headerView': 'advanced',
  });
  const [ surveyResults, setSurveyResults ] = useState<any>();

  const surveyComplete = useCallback((survey: Model) => {
    console.log({ survey, data: survey.data });
    setSurveyResults(survey.data);
    survey.clear(true);
  }, []);

  survey.onComplete.add(surveyComplete);

  console.log({ surveyResults });

  return (
    <>
      <Helmet>
        <title>Survey POC</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel className="p-2" white>
        <Survey
          className="survey"
          model={survey}
        />
      </Panel>

      {surveyResults && (
        <SurveyResultsModal
          show
          results={surveyResults}
          hide={() => setSurveyResults(undefined)}
        />
      )}
    </>
  );
};

export default SurveyPage;
