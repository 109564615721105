import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import { getNumberHash, getNumberMappedEntities } from '../../helpers/entityHelpers';

export const getPrograms = (state: RootState) => state.programs.entities.programs;
export const getProgramFolders = (state: RootState) => state.programs.entities.programFolders;
export const getProgramAuditTemplates = (state: RootState) => state.programs.mappings.programAuditTemplates;
export const getProgramFolderIds = (state: RootState) => state.programs.mappings.programFolders;
export const getAllProgramIds = (state: RootState) => state.programs.ui.allProgramIds;
export const getSelectedProgramId = (state: RootState) => state.programs.ui.selectedProgramId;

export const selectSelectedProgram = createSelector(
  [
    getPrograms,
    getSelectedProgramId,
  ],
  (
    programs,
    selectedProgramId
  ) => {
    return selectedProgramId ? programs[selectedProgramId] : undefined;
  }
);

export const selectAllPrograms = createSelector(
  [
    getPrograms,
    getAllProgramIds,
  ],
  (
    programs,
    allProgramIds
  ) => {
    return getNumberMappedEntities(programs, allProgramIds);
  }
);

export const selectProgramFoldersForProgram = createSelector(
  [
    getProgramFolders,
    getProgramFolderIds,
    (state: RootState, programId?: number) => programId,
  ],
  (
    programFolders,
    programFolderIds,
    programId
  ) => {
    if (!programId) {
      return [];
    }

    return getNumberMappedEntities(programFolders, programFolderIds[programId]);
  }
);

export const selectProgramSelectedTemplateHash = createSelector(
  [
    getProgramAuditTemplates,
    getSelectedProgramId,
  ],
  (
    programAuditTemplates,
    selectedProgramId
  ): {[auditTemplateId: number]: any} => {
    if (!selectedProgramId) {
      return {};
    }
    return getNumberHash(programAuditTemplates[selectedProgramId], i => i);
  }
);
