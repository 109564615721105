import { OperatorGroup } from 'Modules/operatorGroups/models';
import React, { CSSProperties } from 'react';
import Select from 'react-select';
import { Props as ReactSelectProps } from 'react-select/src/Select';

interface Props extends Omit<ReactSelectProps<OperatorGroup, true>, 'value'> {
  value: OperatorGroup[];
  options: OperatorGroup[];
  onChange: (users: readonly OperatorGroup[]) => void;
}

const selectStyles = {
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 3000 }),
};

const MultipleOperatorGroupSelect = ({ value, options, ...props }: Props) => {
  return (
    <Select<OperatorGroup, true>
      placeholder="Select Operator Groups..."
      isClearable
      {...props}
      isMulti
      menuPortalTarget={document.body}
      value={value}
      getOptionLabel={(option: OperatorGroup) => option.name}
      getOptionValue={(option: OperatorGroup) => option.operatorGroupId.toString()}
      options={options as OperatorGroup[]}
      styles={selectStyles as any}
      onChange={(users) => props.onChange(users || [])}
    />
  );
};

export default MultipleOperatorGroupSelect;
