import React from 'react';
import DashboardCard from '../../Components/DashboardCard';
import _ from 'lodash';
import { pluralCoalesce } from '../../../../helpers/listHelpers';

interface LocalProps {
  tasksPendingApproval: number | any[];
}

const NumPendingApprovalCard = ({ tasksPendingApproval }: LocalProps) => {
  return (
    <DashboardCard
      icon={<i className="mdi mdi-playlist-remove mdi-36px" />}
      heading="Tasks Pending Approval:"
      body={`${_.isArray(tasksPendingApproval) ? _.size(tasksPendingApproval) : tasksPendingApproval} ${pluralCoalesce(tasksPendingApproval, 'Task', 'Tasks')}`}
    />
  );
};

export default NumPendingApprovalCard;
