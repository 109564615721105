import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import { PaginatedRequest } from 'core';
import appConfig from 'config';
import { RootState } from 'Modules/reducers';
import { peerOperatorsSlice, PeerOperatorState } from './slice';
import { CreateOperatorToOperatorMessageRequest, OperatorGroupPeer, OperatorToOperatorMessage } from './models';
import peerOperatorActions from './actions';
import { PaginatedSearchResult } from '../../helpers/pagination';

const peerOperatorsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: PeerOperatorState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(peerOperatorsSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getCurrentUserPeerOperators = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: peerOperatorsLoadingHandler(dispatch, (peerOperatorsState, isLoading) => peerOperatorsState.loading.isLoadingCurrentUserOperatorGroupPeers = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operatorToOperators/peerOperators`),
    onSuccess: (data: OperatorGroupPeer[]) => {
      dispatch(peerOperatorActions.setCurrentUserPeerOperators(data));
    },
  });
};

export const getPeerOperatorMessage = (operatorToOperatorMessageId: number, callback?: (data: OperatorToOperatorMessage) => void): ThunkAction<Promise<OperatorToOperatorMessage>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: peerOperatorsLoadingHandler(dispatch, (peerOperatorsState, isLoading) => peerOperatorsState.loading.isLoadingPeerOperatorMessage = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operatorToOperators/messages/${operatorToOperatorMessageId}`),
    onSuccess: (data: OperatorToOperatorMessage) => {
      callback?.(data);
    },
  });
};

export const searchPeerOperatorCategories = (peerOperatorId: number, request: PaginatedRequest): ThunkAction<Promise<PaginatedSearchResult<OperatorToOperatorMessage>>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: peerOperatorsLoadingHandler(dispatch, (peerOperatorsState, isLoading) => peerOperatorsState.loading.isSearchingPeerOperatorMessages = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operatorToOperators/peerOperators/${peerOperatorId}/messages/search`).query(request),
    onSuccess: (data: PaginatedSearchResult<OperatorToOperatorMessage>) => {
      dispatch(peerOperatorActions.setSearchedPeerMessages({ result: data, peerOperatorId }));
    },
  });
};

export const submitPeerOperatorMessage = (peerOperatorId: number, request: CreateOperatorToOperatorMessageRequest, callback?: () => void): ThunkAction<Promise<OperatorToOperatorMessage>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: peerOperatorsLoadingHandler(dispatch, (peerOperatorsState, isLoading) => peerOperatorsState.loading.isSearchingPeerOperatorMessages = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/operatorToOperators/peerOperators/${peerOperatorId}/messages`).send(request),
    onSuccess: (data: OperatorToOperatorMessage) => {
      dispatch(peerOperatorActions.setCreatedPeerOperatorMessage({ peerOperatorId, message: data }));
      callback?.();
    },
  });
};
