import React, { useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { useStateSelector } from 'hooks';
import { getStandardReferencesByStandard } from 'Modules/standards/actions';
import { StandardReference } from 'Modules/standards/models';
import { selectAllStandardReferencesByStandardId } from 'Modules/standards/selectors';

interface Props extends Omit<ReactSelectProps, 'value'> {
  standardId?: number;
  value?: number;
}

const StandardReferenceSearch = ({ value, standardId, ...props }: Props) => {
  const dispatch = useDispatch();
  const standardReferences = useStateSelector(state => standardId ? selectAllStandardReferencesByStandardId(state, standardId) : []);
  const isLoadingStandardReferences = useStateSelector(state => state.standards.loading.isLoadingStandardReferences);

  useEffect(() => {
    if(standardId) {
      dispatch(getStandardReferencesByStandard(standardId));
    }
  }, [ standardId ]);

  const standardReference = _.find(standardReferences, c => c.standardReferenceId === value);

  return (
    <Select<StandardReference>
      isClearable
      {...props}
      isLoading={isLoadingStandardReferences}
      getOptionLabel={c => c.description}
      getOptionValue={c => c.standardReferenceId as any}
      value={standardReference || null}
      options={standardReferences}
    />
  );
};

export default StandardReferenceSearch;
