import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Swal from 'sweetalert2';

import FlexRow from 'Components/FlexRow';
import ModalHeaderWithClose from 'Components/ModalHeaderWithClose';
import { sOrNoS } from 'helpers/listHelpers';
import MoveToFolderFileTable, { MoveToFolderFileAccessors } from 'Components/LibraryFolder/MoveToFolderFileTable';

interface Props<T> {
  show: boolean;
  directoryPath: string;
  libraryFiles: T[];
  accessors: MoveToFolderFileAccessors<T>;
  moveFiles: (directoryPath: string, files: T[]) => Promise<void>;
  hide: () => void;
}

const MoveToFolderModal = <T extends any>({ show, directoryPath, libraryFiles, accessors, ...props }: Props<T>) => {
  function moveFiles() {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Move Files to Folder',
        text: 'Are you sure you want to move the selected files?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      props.moveFiles(directoryPath, libraryFiles)
        .then(() => {
          props.hide();
        });
    })();
  }

  return (
    <>
      <Modal isOpen={show} toggle={props.hide} size="xl">
        <ModalHeaderWithClose hide={props.hide}>
          Move Files to Folder
        </ModalHeaderWithClose>
        <ModalBody>
          <Row>
            <Col md={12}>
              <MoveToFolderFileTable<T>
                files={libraryFiles}
                accessors={accessors}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FlexRow fill justifyBetween alignCenter>
            <div>
              <Button color="light" onClick={props.hide}>Close</Button>
            </div>
            <div>
              <Button color="primary" onClick={moveFiles}>Move File{sOrNoS(libraryFiles)}</Button>
            </div>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};


export default MoveToFolderModal;
