import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { LibraryFile, OperatorModel } from 'models';
import * as libraryActions from 'Modules/library/actions';
import { TreeNode } from 'Modules/library/models';

import FileTreeFolder from 'Components/LibraryFolder/FileTreeFolder';

interface Props {
  operator: OperatorModel;
  folder: TreeNode<LibraryFile>;
  onFolderSelect: (folder: TreeNode<LibraryFile>) => void;
}

const LibraryFolder = ({ folder, operator, ...props }: Props) => {
  const dispatch = useDispatch();

  function renameLibraryFolder(folder: TreeNode<LibraryFile>, updatedDirectoryFilePath: string) {
    const decedentFiles = folder.fileTree.getDescendantDataForNode(folder);
    dispatch(libraryActions.renameLibraryFolder(folder.filePath, updatedDirectoryFilePath, _.map(decedentFiles, f => f.libraryFileId)));
  }

  function deleteFolder(folder: TreeNode<LibraryFile>, moveFilesToDirectoryPath: string) {
    return new Promise<void>((resolve) => {
      dispatch(libraryActions.deleteLibraryFolder({
        moveFilesToDirectoryPath,
        folderPath: folder.filePath || '/',
        operatorId: operator.operatorId,
      }, () => {
        dispatch(libraryActions.getOperatorHiddenFolders(operator.operatorId));
        resolve();
      }));
    });
  }

  return (
    <FileTreeFolder<LibraryFile>
      folder={folder}
      onFolderSelect={props.onFolderSelect}
      renameLibraryFolder={renameLibraryFolder}
      deleteFolder={{
        delete: deleteFolder,
      }}
      accessors={{
        filePath: (libraryFile) => libraryFile.filePath,
        originalFilename: (libraryFile) => libraryFile.file.originalFilename,
        name: (libraryFile) => libraryFile.fileName,
      }}
    />
  );
};

export default LibraryFolder;
