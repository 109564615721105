import './div.scss';

import React from 'react';
import classnames from 'classnames';

export interface DivExtendableProps extends React.HTMLProps<HTMLDivElement>{
  fill?: boolean;
  overlay?: boolean;
  overflowHidden?: boolean;
  white?: boolean;
  childSpacingX?: 1|2|3|4|5;
  childSpacingY?: 1|2|3|4|5;
}

interface Props extends DivExtendableProps {
  children: any;
}

const Div = ({ fill, white, childSpacingX, childSpacingY, overflowHidden, overlay, className, children, ...rest }: Props) => {
  return (
    <div
      {...rest}
      className={
        classnames(className, 'div', {
          'flex': fill,
          'bg-white': white,
          'flex-overlay': overlay,
          'overflow-hidden': overflowHidden,
          [`child-spacing-x-${childSpacingX}`]: childSpacingX,
          [`child-spacing-y-${childSpacingY}`]: childSpacingY,
        })
      }
    >
      {children}
    </div>
  );
};

export default Div;
