import React from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { shouldModalUpdate } from 'helpers/modalHelpers';
import { RootState } from 'Modules/reducers';
import { insertProgram } from '../../Modules/programs/actions';
import { LoadableButton } from 'Components';

interface LocalProps {
  show: boolean;
  hide: () => void;
}

interface LocalState {
  name: string;
}

interface StateProps {
  isInsertingProgram: boolean;
}

interface DispatchProps {
  insertProgram: typeof insertProgram;
}

type Props = LocalProps & StateProps & DispatchProps;

class ModifyProgramModal extends React.Component<Props, LocalState> {
  public shouldComponentUpdate = shouldModalUpdate(this);

  constructor (props: Props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  public componentDidUpdate (prevProps: Readonly<Props>): void {
    if (prevProps.show && !this.props.show) {
      this.setState({
        name: '',
      });
    }
  }

  private createTemplate = () => {
    const { name } = this.state;

    if (!_.trim(name)) {
      toastr.error('You must specify a name for the program');
      return;
    }

    this.props.insertProgram({
      name: name,
    }, this.props.hide);
  };

  private setName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.target.value,
    });
  };

  public render () {
    const { name } = this.state;
    const { show, isInsertingProgram } = this.props;

    return (
      <Modal isOpen={show} toggle={this.props.hide}>
        <ModalHeader>Create Program</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Program Name:</Label>
                <Input value={name} onChange={this.setName} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <LoadableButton isLoading={isInsertingProgram} LoadingLabel="Saving..." color="primary" onClick={this.createTemplate}>
            Create Program
          </LoadableButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isInsertingProgram: state.programs.loading.isInsertingProgram,
  };
};

const mapDispatchToProps = {
  insertProgram,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ModifyProgramModal);
