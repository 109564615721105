import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface LocalProps {
  isOpen: boolean;
  children: ReactNode;
  closeSidebar: () => void;
}

const RightSidebar = ({ isOpen, children, ...props }: LocalProps) => {
  return isOpen ? createPortal(
    <>
      {children}
      <div className="rightbar-overlay" onClick={props.closeSidebar} />
    </>, document.body) : null;
};

export default RightSidebar;
