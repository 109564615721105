import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import { PaginatedResult } from 'core';
import appConfig from 'config';
import { RootState } from 'Modules/reducers';
import {
  CreatePoll,
  CreatePollCategory,
  CreatePollOperatorGroupRequest,
  CreatePollOption,
  CreatePollResult,
  OperatorPollSearchRequest,
  Poll,
  PollCategory,
  PollCategorySearchRequest,
  PollOperatorGroup,
  PollOption,
  PollResult,
  PollResultSummary,
  pollsActions,
  PollSearchRequest,
  pollsSlice,
  PollsState,
  UpdatePoll,
  UpdatePollCategory,
  UpdatePollResult,
} from 'Modules/polls';

const pollsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: PollsState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(pollsSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getPoll = (pollId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPoll = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/${pollId}`),
    onSuccess: (data: Poll) => {
      dispatch(pollsActions.setPoll(data));
    },
  });
};

export const getPollCategory = (pollCategoryId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/categories/${pollCategoryId}`),
    onSuccess: (data: PollCategory) => {
      dispatch(pollsActions.setPollCategory(data));
    },
  });
};

export const searchPolls = (request: PollSearchRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isSearchingPolls = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/search`).send(request),
    onSuccess: (data: PaginatedResult<Poll>) => {
      dispatch(pollsActions.setSearchedPolls(data));
    },
  });
};

export const searchPollCategories = (request: PollCategorySearchRequest): ThunkAction<Promise<PaginatedResult<Poll>>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isSearchingPollCategories = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/categories/search`).send(request),
    onSuccess: (data: PaginatedResult<Poll>) => {
      dispatch(pollsActions.setSearchedPollCategories(data));
    },
  });
};

export const searchAllPollCategories = (request: PollCategorySearchRequest): ThunkAction<Promise<PaginatedResult<Poll>>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isSearchingAllPollCategories = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/categories/search/all`).send(request),
    onSuccess: (data: PaginatedResult<Poll>) => {
      dispatch(pollsActions.setSearchedPollCategories(data));
    },
  });
};

export const searchCurrentOperatorsPolls = (request: OperatorPollSearchRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isSearchingOperatorPolls = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/operators/current/polls/search`).send(request),
    onSuccess: (data: PaginatedResult<Poll>) => {
      dispatch(pollsActions.setSearchedCurrentOperatorsPolls(data));
    },
  });
};

export const getAllPollOptions = (pollId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollOptions = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/${pollId}/options`),
    onSuccess: (data: PollOption[]) => {
      dispatch(pollsActions.setPollOptions({ pollId, options: data }));
    },
  });
};

export const getPollOption = (pollId: number, optionId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollOption = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/${pollId}/options/${optionId}`),
    onSuccess: (data: PollOption) => {
      dispatch(pollsActions.setPollOption(data));
    },
  });
};

export const getAllPollResultSummaries = (pollId: number): ThunkAction<Promise<PollResultSummary[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollResultSummaries = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/${pollId}/results/summaries`),
    onSuccess: (data: PollResultSummary[]) => {
      dispatch(pollsActions.setPollResultSummaries({ pollId, results: data }));
    },
  });
};

export const getPollResultSummary = (pollId: number, pollResultId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollResultSummary = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/${pollId}/results/${pollResultId}/summary`),
    onSuccess: (data: PollResultSummary) => {
      dispatch(pollsActions.setPollResultSummary(data));
    },
  });
};

export const getCurrentOperatorPendingPollCount = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollResultSummary = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operators/current/polls/pendingCount`),
    onSuccess: (data: number) => {
      dispatch(pollsActions.setCurrentOperatorPendingPollCount(data));
    },
  });
};

export const getPollOperatorGroupsForPoll = (pollId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState, isLoading) => pollsState.loading.isLoadingPollOperatorGroups = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/polls/${pollId}/operatorGroups`),
    onSuccess: (data: PollOperatorGroup[]) => {
      dispatch(pollsActions.setPollOperatorGroupsForPoll({ pollId, pollOperatorGroups: data }));
    },
  });
};

export const insertPoll = (request: CreatePoll): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isInsertingPoll = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls`).send(request),
    onSuccess: (data: Poll) => {
      dispatch(pollsActions.onPollInserted(data));
    },
  });
};

export const insertPollOption = (pollId: number, request: CreatePollOption): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isInsertingPollOption = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/${pollId}/options`).send(request),
    onSuccess: (data: PollOption) => {
      dispatch(pollsActions.onPollOptionInserted(data));
    },
  });
};

export const insertPollOperatorGroup = (pollId: number, request: CreatePollOperatorGroupRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isInsertingPollOperatorGroup = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/${pollId}/operatorGroups`).send(request),
    onSuccess: (data: PollOperatorGroup) => {
      dispatch(pollsActions.onPollOperatorGroupInserted(data));
    },
  });
};

export const updatePollResult = (pollId: number, pollResultId: number, request: UpdatePollResult): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<PollResult>({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isUpdatingPollResult = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/polls/${pollId}/results/${pollResultId}`).send(request),
  });
};

export const insertPollResult = (pollId: number, request: CreatePollResult): ThunkAction<Promise<PollResult[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi<PollResult[]>({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isInsertingPollResult = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/${pollId}/results`).send(request),
  });
};

export const insertPredefinedPollOption = (pollId: number, request: CreatePollOption): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isInsertingPollOption = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/${pollId}/options/predefined`).send(request),
    onSuccess: (data: PollOption) => {
      dispatch(pollsActions.onPollOptionInserted(data));
    },
  });
};

export const mergePollOptions = (pollId: number, optionToKeepId: number, optionToDeleteId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isMergingPollOptions = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/polls/${pollId}/options/${optionToKeepId}/merge/${optionToDeleteId}`),
    onSuccess: () => {
      dispatch(pollsActions.onPollOptionMerged({ pollId, optionToKeepId, optionToDeleteId }));
    },
  });
};

export const updatePoll = (pollId: number, request: UpdatePoll): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isUpdatingPoll = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/polls/${pollId}`).send(request),
    onSuccess: (data: Poll) => {
      dispatch(pollsActions.onPollUpdated(data));
    },
  });
};

export const insertPollCategory = (request: CreatePollCategory): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollCategoriesState: PollsState, isLoading: boolean) => pollCategoriesState.loading.isInsertingPollCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/polls/categories`).send(request),
    onSuccess: (data: PollCategory) => {
      dispatch(pollsActions.onPollCategoryInserted(data));
    },
  });
};

export const updatePollCategory = (pollCategoryId: number, request: UpdatePollCategory): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollCategoriesState: PollsState, isLoading: boolean) => pollCategoriesState.loading.isUpdatingPollCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/polls/categories/${pollCategoryId}`).send(request),
    onSuccess: (data: PollCategory) => {
      dispatch(pollsActions.onPollCategoryUpdated(data));
    },
  });
};

export const deletePoll = (poll: Poll): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isDeletingPoll = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/polls/${poll.id}`),
    onSuccess: () => {
      dispatch(pollsActions.onPollDeleted(poll));
    },
  });
};

export const deletePollCategory = (pollCategory: PollCategory): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isDeletingPollCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/polls/categories/${pollCategory.id}`),
    onSuccess: () => {
      dispatch(pollsActions.onPollCategoryDeleted(pollCategory));
    },
  });
};

export const deletePollOption = (pollOption: PollOption): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isDeletingPoll = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/polls/${pollOption.pollId}/options/${pollOption.id}`),
    onSuccess: () => {
      dispatch(pollsActions.onPollOptionDeleted(pollOption));
    },
  });
};

export const deletePollOperatorGroup = (pollId: number, pollOperatorGroupId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isDeletingPollOperatorGroup = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/polls/${pollId}/operatorGroups/${pollOperatorGroupId}`),
    onSuccess: () => {
      dispatch(pollsActions.onPollOperatorGroupDeleted(pollOperatorGroupId));
    },
  });
};

export const deletePollResponse = (pollId: number, id: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: pollsLoadingHandler(dispatch, (pollsState: PollsState, isLoading: boolean) => pollsState.loading.isDeletingPollResponse = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/polls/${pollId}/results/${id}`),
    onSuccess: () => {
      dispatch(pollsActions.onPollResponseDeleted({ pollId, id }));
    },
  });
};
