import { createSelector } from 'reselect';
import { RootState } from '../reducers';

const getSiteState = (state: RootState) => state.site;

export const pageModelIsLoaded = createSelector([ getSiteState ], (siteState) => {
  return !!siteState.authUser && !!siteState.user && !!siteState.pageModel;
});

export const getAccessToken = (state: RootState) => state.site.authUser?.token;
