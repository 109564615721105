import { ShareBoardFile } from 'Modules/shareBoard/models';
import React from 'react';
import { ClickableDiv, FlexRow } from '../../Components/index';
import classnames from 'classnames';
import { shareBoardActions } from '../../Modules/shareBoard/index';
import { useCurrentUserPermissionChecker, useThunkDispatch } from 'hooks';
import { Permission } from '../../Modules/roles/index';

interface Props {
  shareBoardFile: ShareBoardFile;
}

const RatingRow = ({ shareBoardFile }: Props) => {
  const dispatch = useThunkDispatch();
  const canUpdate = useCurrentUserPermissionChecker()(Permission.GlobalShareBoardMaintenance);

  function updateShareBoardFileRating(rating?: number) {
    dispatch(shareBoardActions.async.updateShareBoardFileRating(shareBoardFile.id, rating));
  }

  function renderStar(starIndex: number) {
    return (
      <ClickableDiv
        stopPropagation
        onClick={() => {
          if(!canUpdate) {
            return;
          }

          updateShareBoardFileRating(starIndex);
        }}
      >
        <i
          className={classnames('mdi mdi-star', {
            'text-warning': shareBoardFile.rating && starIndex <= shareBoardFile.rating,
          })}
        />
      </ClickableDiv>
    );
  }

  return (
    <FlexRow childSpacingX={1} alignCenter className="px-2 py-1">
      {canUpdate && (
        <ClickableDiv
          stopPropagation
          onClick={() => updateShareBoardFileRating(undefined)}
        >
          <i className="mdi mdi-diameter-variant" />
        </ClickableDiv>
      )}
      {renderStar(1)}
      {renderStar(2)}
      {renderStar(3)}
      {renderStar(4)}
      {renderStar(5)}
    </FlexRow>
  );
};

export default RatingRow;
