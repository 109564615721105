import React from 'react';
import { Column } from 'react-table';
import { ActiveUser } from '../../../Modules/site/models';
import BaseTable from '../../../Components/BaseTable/BaseTable';
import RealtimeHumanizeDateTime from '../../../Components/RealtimeHumanizeDateTime';
import moment from 'moment';

interface Props {
  users: ActiveUser[];
}

const ActiveUsersTable = ({ users }: Props) => {
  const columns: Column<ActiveUser>[] = [
    {
      Header: 'Id',
      id: 'id',
      accessor: u => u.user.userId,
      width: 100,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: u => u.user.displayName,
    },
    {
      id: 'connectedTimestamp',
      Header: 'Connected At',
      accessor: u => u.connectedTimestamp,
      Cell: ({ original }: { original: ActiveUser }) => {
        const connectedMoment = moment.utc(original.connectedTimestamp);
        return (
          <div title={connectedMoment.local().format('L LT')}>
            <RealtimeHumanizeDateTime date={connectedMoment}/>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white">
      <BaseTable
        data={users}
        minRows={10}
        columns={columns}
        defaultSorted={[ { id: 'name', desc: false } ]}
      />
    </div>
  );
};

export default ActiveUsersTable;
