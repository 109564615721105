import React, { ReactNode } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

interface LocalProps {
    pageTitle: string;
    breadcrumbs?: string[];
    children: ReactNode;
}

const PageLayout = ({ pageTitle, breadcrumbs, children }: LocalProps) => {
  return (
    <React.Fragment>
      <div className="page-title-box">
        {_.size(breadcrumbs) > 0 && (
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {
                _.map(breadcrumbs, (breadcrumb, i) => {
                  const isLast = i === _.size(breadcrumbs) - 1;

                  return (
                    <li key={breadcrumb} className={classnames({ 'breadcrumb-item': true, active: isLast })}>{breadcrumb}</li>
                  );
                })
              }
            </ol>
          </div>
        )}
        <h4 className="page-title">{pageTitle}</h4>
      </div>
      {children}
    </React.Fragment>
  );
};

export default PageLayout;
