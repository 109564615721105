import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { AtomicBlockUtils, EditorState } from 'draft-js';

import { RefreshIcon } from 'Components';

interface Props {
  fileType: string;
  uploadFile: (file: File) => Promise<any>;
  onChange: Function;
  editorState: EditorState;
}

const UploadMessageFileOption = ({ fileType, editorState, ...props }: Props) => {
  const [ isLoading, setIsLoading ] = useState(false);

  function addFile (data: any) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(fileType, 'IMMUTABLE', data);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity }
    );

    props.onChange(AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' '
    ));
  }

  function handleFileSelect (e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    setIsLoading(true);

    const formData = new window.FormData();

    formData.append('file', file);

    props.uploadFile(file)
      .then((data) => {
        addFile(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="rdw-history-wrapper">
      <Label className="btn btn-sm btn-light">
        {isLoading ? <RefreshIcon isLoading /> : <i className="mdi mdi-file-plus" />} Browse <input accept="image/*" disabled={isLoading} type="file" hidden onChange={handleFileSelect} />
      </Label>
    </div>
  );
};

export default UploadMessageFileOption;
