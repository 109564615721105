import { OperatorGroup } from 'Modules/operatorGroups/models';
import { PaginatedOffsetRequest } from '../../models';

export interface Poll {
  id: number;
  name: string;
  startDate: string;
  endDate?: string;
  defaultResultDisplayType: PollResultDisplayType;
  allowMultipleAnswers: boolean;
  category?: PollCategory;
  createdUser: string;
}

export interface CreatePoll {
  name: string;
  startDate?: string;
  endDate?: string;
  defaultResultDisplayType?: PollResultDisplayType;
  allowMultipleAnswers: boolean;
  category?: PollCategory;
}

export interface UpdatePoll {
  name: string;
  startDate: string;
  endDate?: string;
  defaultResultDisplayType?: PollResultDisplayType;
  allowMultipleAnswers: boolean;
  category?: PollCategory;
}

export type ModifyPoll = CreatePoll | UpdatePoll;


export interface PollOption {
  id: number;
  name: string;
  pollId: number;
  predefined: boolean;
  createdUser: string;
}

export interface CreatePollOption {
  name: string;
}

export interface UpdatePollOption {
  name: string;
}

export type ModifyPollOption = CreatePollOption | UpdatePollOption;

export interface PollOptionSearchRequest extends PaginatedOffsetRequest {
  filter?: string;
  userDefinedOnly: boolean;
}

export interface PollResult {
  id: number;
  operatorId: number;
  pollId: number;
  pollOptionId: number;
  createdUser: string;
}

export interface CreatePollResult {
  pollOptionIds: number[];
}

export interface UpdatePollResult {
  pollOptionIds: number[];
}

export interface PollSearchRequest extends PaginatedOffsetRequest {
  filter?: string;
  categoryId?: number;
  isEffectiveStart?: string;
  isEffectiveEnd?: string;
}

export interface RetrievePollChartRequest {
  pollIds: number[];
}

export interface PollResultSummary {
  pollResultId: number;
  operatorId: number;
  operatorCodeName?: string;
  createdUserId?: string;
  createdUserName?: string;
  optionId: number;
  optionName: string;
  isCurrentOperatorsResult: boolean;
}

export interface OperatorPollSearchRequest extends PaginatedOffsetRequest {
  filter?: string;
  categoryId?: number;
  isEffectiveStart?: string;
  isEffectiveEnd?: string;
}

export interface PollChart {
  pollId: number;
  totalResponseCount: number;
  options: PollChartOption[];
}

export interface PollChartOption {
  pollOptionId: number;
  pollOptionName: string;
  responseCount: number;
}

export enum PollResultDisplayType {
  PieChart = 1,
  HorizontalBarGraph = 2
}

export interface PollCategory {
  id: number;
  name: string;
}

export interface PollCategory {
  id: number;
  name: string;
}

export interface CreatePollCategory {
  name: string;
}

export interface UpdatePollCategory {
  name: string;
}

export type ModifyPollCategory = CreatePollCategory | UpdatePollCategory;

export interface PollCategorySearchRequest extends PaginatedOffsetRequest {
  filter?: string;
}

export interface PollOperatorGroup {
  id: number;
  operatorGroup: OperatorGroup;
  pollId: number;
}

export interface CreatePollOperatorGroupRequest {
  operatorGroup: OperatorGroup;
}
