import { useState } from 'react';

const useModalState = (defaultShow?: boolean) => {
  const [ isShowingModal, setIsShowingModal ] = useState(!!defaultShow);

  return {
    isShowing: isShowingModal,
    show: () => setIsShowingModal(true),
    setIsShowing: (isShowing: boolean) => setIsShowingModal(isShowing),
    toggle: () => setIsShowingModal(!isShowingModal),
    hide: () => setIsShowingModal(false),
  };
};

export default useModalState;
