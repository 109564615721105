import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { RefreshIcon } from 'Components';
import { sOrNoS } from 'helpers/listHelpers';
import DashboardCard from './DashboardCard';

interface LocalProps {
    numPendingPolls?: number;
}

const PendingPollsCard = ({ numPendingPolls }: LocalProps) => {
  return (
    <Link to="/analytics">
      <DashboardCard
        icon={<i className="mdi mdi-playlist-remove mdi-36px" />}
        heading="Pending Polls:"
        body={_.isNil(numPendingPolls) ? <RefreshIcon isLoading /> : <span>{numPendingPolls} Poll{sOrNoS(numPendingPolls)}</span>}
      />
    </Link>
  );
};

export default PendingPollsCard;
