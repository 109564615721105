import { dashboardActions } from './actions';
import {
  Action,
  OperatorDashboardModelResponse,
  ReducerFunction,
  SafetyCoachDashboardResponse,
  UserOperatorDashboardModel,
  UserSafetyCoachDashboardModel,
} from '../../models';
import { commonActions } from '../common/actions';
import { compose } from 'redux';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import loadingConfigs from './loading';

export interface DashboardState {
  operatorDashboard?: UserOperatorDashboardModel;
  safetyCoachDashboard?: UserSafetyCoachDashboardModel;
  loading: {
    isLoadingOperatorDashboard: boolean;
    isLoadingSafetyCoachDashboard: boolean;
  };
}

const initState: DashboardState = {
  operatorDashboard: undefined,
  safetyCoachDashboard: undefined,
  loading: {
    isLoadingOperatorDashboard: false,
    isLoadingSafetyCoachDashboard: false,
  },
};

export default compose<ReducerFunction<DashboardState>>(
  makeLoadingReducer<DashboardState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): DashboardState => {
  switch (action.type) {
    case dashboardActions.GET_OPERATOR_DASHBOARD_SUCCESS: {
      const { operator, operatorNextAudit } = action.payload as OperatorDashboardModelResponse;

      return {
        ...state,
        operatorDashboard:  {
          operatorId: operator.operatorId,
          operatorNextAuditId: operatorNextAudit?.operatorAuditId,
          operatorSafetyCoaches: operator.safetyCoaches,
        },
      };
    }
    case dashboardActions.GET_SAFETY_COACH_DASHBOARD_SUCCESS: {
      const { operator, operatorNextAudit } = action.payload as SafetyCoachDashboardResponse;

      return {
        ...state,
        safetyCoachDashboard: {
          operatorId: operator.operatorId,
          operatorNextAuditId: operatorNextAudit?.operatorAuditId,
        },
      };
    }
    case commonActions.PATH_LOCATION_CHANGE: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
});
