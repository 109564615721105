import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { getValueByKey } from 'core';
import { UpdateRole } from 'Modules/roles/models';
import { rolesActions } from 'Modules/roles';
import { useMergedEntity, useRequiredPathId, useRoleForm, useStateSelector, useThunkDispatch } from 'hooks';
import { EditFormFooter, EditFormPanel, FlexLoader, Panel, PanelHeader, RoleForm } from 'Components';

const ModifyRolePanel = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const roleId = useRequiredPathId('roleId');
  const workingRole = useStateSelector((state) => state.roles.ui.maintenance.workingUpdateRole);
  const isLoadingRole = useStateSelector((state) => state.roles.loading.isLoadingRole);
  const canEdit = true; //todo permissions useHasPermission(Permission.RolesEdit);
  const canDeactivate = true; //todo permissions useHasPermission(Permission.RolesDeactivate);
  const rootRole = useStateSelector((state) => getValueByKey(state.roles.entities.roles, roleId));
  const role = useMergedEntity(rootRole, workingRole);
  const { updateRole, deleteRole, cancelRoleModification, loading } = useRoleForm();

  useEffect(() => {
    dispatch(rolesActions.async.getAllPermissions());
  }, []);

  useEffect(() => {
    dispatch(rolesActions.async.getRole(roleId));
  }, [ roleId ]);

  useEffect(() => {
    dispatch(rolesActions.async.getPermissionsByRole(roleId))
      .then((permissions) => {
        setWorkingRoleValues({ permissions: permissions });
      });
  }, [ roleId ]);

  function setWorkingRoleValues(role: Partial<UpdateRole>) {
    dispatch(rolesActions.setWorkingUpdateRoleValues(role));
  }

  function handleDelete() {
    if (!role) {
      return;
    }

    deleteRole(role)
      .then(() => {
        history.push('/admin/roles');
      });
  }

  async function handleSave() {
    if (!role) {
      return;
    }

    await updateRole(roleId, role);
  }

  if (isLoadingRole) {
    return (
      <FlexLoader />
    );
  }

  if (!role) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader white>
        Updating Role
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelRoleModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingRole,
              children: 'Deactivate',
              hidden: !canDeactivate,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isUpdatingRole,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <RoleForm
              role={role}
              setValues={setWorkingRoleValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyRolePanel;
