import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { baseTableOptions, renderDate } from 'helpers/tableHelper';
import { OperatorModel } from 'models';
import BaseTable from 'Components/BaseTable/BaseTable';

interface LocalProps {
  operators: OperatorModel[];
  selectedOperatorId?: number;
  selectOperator: (operator: OperatorModel | null) => void;
}

const OperatorsTable = (props: LocalProps) => {
  const { operators } = props;

  const tableOptions: any = {
    ...baseTableOptions,
    minRows: 10,
    data: operators,
    getTrProps: (state: any, row: any) => {
      if (!row || !row.original) {
        return {};
      }

      const { original } = row;
      const { selectedOperatorId } = props;
      const { operatorId } = original;

      const isSelected = operatorId === selectedOperatorId;

      return {
        onClick: _.isFunction(props.selectOperator) ? () => {
          props.selectOperator(!isSelected ? original : null);
        } : _.noop,
        className: classnames({
          'selected-row': isSelected,
        }),
      };
    },
    columns: [
      {
        Header: 'Id',
        accessor: 'operatorId',
        width: 45,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
      },
      {
        Header: 'Next Audit Date',
        ...renderDate('nextAuditDate'),
        width: 245,
      },
    ],
    defaultSorted: [ { id: 'name', desc: false } ],
  };

  return (
    <div className="bg-white">
      <BaseTable {...tableOptions} />
    </div>
  );
};

export default OperatorsTable;
