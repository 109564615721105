import React, { useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { DomClass, Panel } from 'Components';
import { shareBoardActions, shareBoardSelectors } from '../../Modules/shareBoard';
import { ShareBoardCategory } from '../../Modules/shareBoard/models';
import SelectCategoryPanel from './SelectCategoryPanel';
import CategoryFilesPanel from './CategoryFilesPanel';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useStateSelector, useThunkDispatch } from 'hooks';

const ShareBoardPage = () => {
  const dispatch = useThunkDispatch();

  const selectedShareBoardCategory = useStateSelector(state => state.shareBoard.ui.selectedShareBoardCategory);
  const approvedShareBoardFiles = useSelector(shareBoardSelectors.approvedShareBoardFilesForCurrentOperatorGroup);

  const filteredShareBoardFiles = useMemo(() => {
    if(selectedShareBoardCategory === null) {
      return approvedShareBoardFiles.filter(s => !s.categories.length);
    }

    if(!selectedShareBoardCategory) {
      return [];
    }

    return approvedShareBoardFiles.filter(s => s.categories.some(c => c.id === selectedShareBoardCategory.id));
  }, [ selectedShareBoardCategory, approvedShareBoardFiles ]);

  useEffect(() => {
    refreshShareBoardFiles();
  }, []);

  function refreshShareBoardFiles() {
    dispatch(shareBoardActions.async.getAllApprovedShareBoardFiles());
  }

  function setSelectedShareBoardCategory(shareBoardCategory: ShareBoardCategory | undefined | null) {
    dispatch(shareBoardActions.setSelectedShareBoardCategory(shareBoardCategory));
  }

  return (
    <>
      <Helmet>
        <title>Share Board</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white className="share-board" style={{ flex: 10 }}>
        {_.isUndefined(selectedShareBoardCategory) && (
          <SelectCategoryPanel
            allShareBoardFiles={approvedShareBoardFiles}
            setShareBoardCategory={setSelectedShareBoardCategory}
          />
        )}
        {!_.isUndefined(selectedShareBoardCategory) && (
          <CategoryFilesPanel
            operatorGroupId={undefined}
            shareBoardFiles={filteredShareBoardFiles}
            shareBoardCategory={selectedShareBoardCategory}
            toggleShareBoardFileApproval={_.noop}
            clearShareBoardCategory={() => setSelectedShareBoardCategory(undefined)}
            refresh={refreshShareBoardFiles}
          />
        )}
      </Panel>
    </>
  );
};

export default ShareBoardPage;
