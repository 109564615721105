import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { getValueByKey } from 'core';
import { UpdateUserRequest } from 'Modules/users/models';
import { userActions } from 'Modules/users';
import {
  useCurrentUserPermissionChecker,
  useMergedEntity,
  useStateSelector,
  useStringPathId,
  useUserForm,
} from 'hooks';
import {
  BackgroundImage,
  EditFormFooter,
  EditFormPanel,
  FlexLoader,
  FlexRow, OpenModalButton,
  Panel,
  PanelHeader,
  UserForm,
} from 'Components';
import { Permission } from '../../../Modules/roles/index';
import Toggle from 'react-toggle';
import { stopPropagationAnd } from '../../../helpers/eventHelpers';
import Swal from 'sweetalert2';
import UploadProfilePictureModal from '../../../Components/UploadProfilePictureModal';

const ModifyUserPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useStringPathId('userId')!;
  const currentUserHasPermission = useCurrentUserPermissionChecker();
  const workingUser = useStateSelector((state) => state.users.ui.maintenance.workingUpdateUser);
  const isLoadingUser = useStateSelector((state) => state.users.loading.isLoadingUser);
  const isInviting = useStateSelector((state) => _.has(state.users.loading.isLoadingUser, userId));
  const canEdit = currentUserHasPermission(Permission.GlobalUserMaintenance);
  const canDeactivate = currentUserHasPermission(Permission.GlobalUserMaintenance);
  const rootUser = useStateSelector((state) => getValueByKey(state.users.entities.users, userId as any));
  const user = useMergedEntity(rootUser, workingUser);
  const { updateUser, deleteUser, cancelUserModification, loading } = useUserForm();

  useEffect(() => {
    dispatch(userActions.getUser(userId));
  }, [ userId ]);

  function setWorkingUserValues(user: Partial<UpdateUserRequest>) {
    dispatch(userActions.setWorkingUpdateUserValues(user));
  }

  function handleDelete() {
    if (!user) {
      return;
    }

    deleteUser(user, () => {
      history.push('/admin/users');
    });
  }

  function handleAccountEnabledToggle() {
    if(!user) {
      return;
    }

    dispatch(userActions.setUserEnabled(userId, !user.accountEnabled));
  }

  async function handleSave() {
    if (!user) {
      return;
    }

    updateUser(userId as unknown as string, user);
  }

  function removeProfilePicture() {
    if (!user) {
      return;
    }

    dispatch(userActions.removeProfilePicture(userId));
  }

  if (isLoadingUser) {
    return (
      <FlexLoader />
    );
  }

  if (!user) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader
        white
        RightContent={(
          <FlexRow alignCenter childSpacingX={2}>
            <div>
              <>
                {!isInviting && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={stopPropagationAnd(() => dispatch(userActions.inviteUser(user.userId, ({ passwordResetUrl }) => {
                      (async () => {
                        await Swal.fire({
                          title: 'User Invite Sent',
                          html: `User email successfully sent. Password Reset URL: <br/><br/><textarea class="form-control">${passwordResetUrl}</textarea>`,
                          icon: 'success',
                          confirmButtonText: 'Okay',
                        });
                      })();
                    })))}
                  >
                    <i className="mdi mdi-email" /> Send Invite
                  </Button>
                )}
                {isInviting && (
                  <Button
                    color="primary"
                    size="sm"
                  >
                    <i className="mdi mdi-spin mdi-refresh" /> Inviting...
                  </Button>
                )}
              </>
            </div>
            <FlexRow alignCenter childSpacingX={1}>
              <Label className="mb-0">Enabled</Label>
              <div>
                <Toggle
                  checked={user.accountEnabled}
                  onChange={handleAccountEnabledToggle}
                />
              </div>
            </FlexRow>
          </FlexRow>
        )}
      >
        <div>Updating User</div>
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelUserModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingUser,
              children: 'Delete',
              hidden: !canDeactivate,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isUpdatingUser,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <UserForm
              disabled={!user.accountEnabled}
              user={user}
              setValues={setWorkingUserValues}
            />

            <FormGroup>
              <div>
                <Label>Profile Picture</Label>
              </div>
              {user.profilePictureFile ? (
                <div className="child-spacing-y-1">
                  <BackgroundImage
                    src={user.profilePictureFile.sasUrl}
                    style={{ width: 300, height: 300 }}
                  />

                  <FlexRow childSpacingX={1}>
                    <OpenModalButton
                      size="sm"
                      color="primary"
                      renderModal={(show, hide) => (
                        <UploadProfilePictureModal
                          show={show}
                          userId={user.userId}
                          hide={hide}
                        />
                      )}
                    >
                      Change Profile Picture
                    </OpenModalButton>

                    <Button
                      size="sm"
                      color="danger"
                      onClick={removeProfilePicture}
                    >
                      Remove Profile Picture
                    </Button>
                  </FlexRow>
                </div>
              ) : (
                <OpenModalButton
                  renderModal={(show, hide) => (
                    <UploadProfilePictureModal
                      show={show}
                      userId={user.userId}
                      hide={hide}
                    />
                  )}
                >
                  Upload Profile Picture
                </OpenModalButton>
              )}
            </FormGroup>
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyUserPanel;
