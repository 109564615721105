import React from 'react';

interface WithInputProps {
  name: string;
  value: any;
  onChange: (e: any) => void;
}

interface WithCheckboxProps {
  name: string;
  checked: boolean;
  onChange: (e: any) => void;
}

export const withInputProps = <T extends any>(formValues: T, stateSetter: (values: Partial<T>) => any) => {
  const setText = (property: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = {
      [property]: e.target.value,
    } as unknown as Partial<T>;

    stateSetter(values);
  };

  return (property: keyof T, valueTransform?: (value: string) => string): WithInputProps => {
    let value = (formValues[property] as any)?.toString() || '';
    if (valueTransform) {
      value = valueTransform(value);
    }

    return {
      name: property as string,
      value: value,
      onChange: setText(property),
    };
  };
};

export const withIntegerInputProps = <T extends any>(formValues: T, stateSetter: (values: Partial<T>) => any) => {
  const setText = (property: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = {
      [property]: e.target.value ? parseInt(e.target.value, 10) : '',
    } as unknown as Partial<T>;

    stateSetter(values);
  };

  return (property: keyof T, valueTransform?: (value: string) => string): WithInputProps => {
    let value = (formValues[property] as any)?.toString() || '';
    if (valueTransform) {
      value = valueTransform(value);
    }

    return {
      name: property as string,
      value: value,
      onChange: setText(property),
    };
  };
};

export const withCheckboxProps = <T extends any>(formValues: T, stateSetter: (values: Partial<T>) => any) => {
  const setChecked = (property: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = {
      [property]: e.target.checked,
    } as unknown as Partial<T>;

    stateSetter(values);
  };

  return (property: keyof T): WithCheckboxProps => {
    return {
      name: property as string,
      checked: !!formValues[property],
      onChange: setChecked(property),
    };
  };
};

export const withSelectProps = <T extends any>(formValues: T, stateSetter: (values: Partial<T>) => any) => {
  const setText = (property: keyof T) => (option: any) => {
    const values = {
      [property]: option,
    } as Partial<T>;

    stateSetter(values);
  };

  return (property: keyof T): WithInputProps => {
    return {
      name: property as string,
      value: formValues[property],
      onChange: setText(property),
    };
  };
};

export const withReactSelectProps = <T extends any>(formValues: T, stateSetter: (values: Partial<T>) => any) => {
  const setText = (property: keyof T) => (option: any) => {
    const values = {
      [property]: option,
    } as Partial<T>;

    stateSetter(values);
  };

  return (property: keyof T): WithInputProps => {
    return {
      name: property as string,
      value: formValues[property],
      onChange: setText(property),
    };
  };
};

export const makeFormHelper = <T extends any>(formValues: T, stateSetter: (values: Partial<T>) => any) => {
  return {
    getInputProps: withInputProps(formValues, stateSetter),
    getIntegerInputProps: withIntegerInputProps(formValues, stateSetter),
    getSelectProps: withSelectProps(formValues, stateSetter),
    getCheckboxProps: withCheckboxProps(formValues, stateSetter),
    getReactSelectProps: withReactSelectProps(formValues, stateSetter),
  };
};

export const baseDebouncedProps = {
  debounceTimeout: 350,
  autoComplete: 'off',
  className: 'form-control',
  onChange: () => {},
};
