import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import DictionarySelect from 'AviationSafetySolutions/DictionarySelect';
import { makeFormHelper } from 'core';
import { UserModel, UserOperatorModel } from 'models';
import { ModifyOperator } from 'Modules/operators';
import { ProgramSearch } from 'Components';

interface Props {
  isCreatingNewOperator?: boolean;
  allUsers: UserModel[];
  users: UserOperatorModel[];
  operator: ModifyOperator;
  setValues: (values: Partial<ModifyOperator>) => void;
}

const OperatorForm = ({ operator, isCreatingNewOperator, users, allUsers, ...props }: Props) => {
  const canGloballyEdit = true; // todo permissions useHasPermission(Permission.OperatorsEdit);

  function setWorkingOperatorValues(values: Partial<ModifyOperator>) {
    props.setValues(values);
  }

  const canEdit = isCreatingNewOperator || canGloballyEdit;
  const { getInputProps } = makeFormHelper(operator, setWorkingOperatorValues);

  return (
    <>
      <FormGroup>
        <Label>Name</Label>
        <Input placeholder="Name..." disabled={!canEdit} {...getInputProps('name')} />
      </FormGroup>

      <FormGroup>
        <Label>Program</Label>

        <ProgramSearch
          simpleValue={operator.programId}
          onChange={(program) => setWorkingOperatorValues({ programId: program?.programId })}
        />
      </FormGroup>

      <FormGroup>
        <Label>Dictionary</Label>

        <DictionarySelect
          value={operator.dictionaryTypeId}
          onChange={(dictionaryTypeId) => setWorkingOperatorValues({ dictionaryTypeId })}
        />
      </FormGroup>
    </>
  );
};

export default OperatorForm;
