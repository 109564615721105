import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { TasksState } from './reducer';
import { tasksActions } from './actions';
import { setLoadingHash } from '../../helpers/loadingHelpers';

const loadingConfigs: LoadingActionConfig<TasksState>[] = [
  {
    start: [ tasksActions.UPDATE_TASK_REQUEST, tasksActions.INSERT_TASK_REQUEST ],
    stop: [ tasksActions.UPDATE_TASK_SUCCESS, tasksActions.INSERT_TASK_SUCCESS, tasksActions.UPDATE_TASK_SUCCESS, tasksActions.UPDATE_TASK_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSavingTask: isLoading,
        },
      };
    },
  },
  {
    start: tasksActions.DELETE_TASK_REQUEST,
    stop: [ tasksActions.DELETE_TASK_SUCCESS, tasksActions.DELETE_TASK_FAILURE ],
    updater: (state: TasksState, isLoading, { taskId }): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          deletingTasks: setLoadingHash(state.loading.deletingTasks, taskId, isLoading),
        },
      };
    },
  },
  {
    start: tasksActions.GET_TASK_APPROVAL_DETAILS_REQUEST,
    stop: [ tasksActions.GET_TASK_APPROVAL_DETAILS_SUCCESS, tasksActions.GET_TASK_APPROVAL_DETAILS_FAILURE ],
    updater: (state: TasksState, isLoading, { taskId }): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          loadingTaskApprovals: setLoadingHash(state.loading.loadingTaskApprovals, taskId, isLoading),
        },
      };
    },
  },
  {
    start: tasksActions.SUBMIT_TASK_MESSAGE_REQUEST,
    stop: [ tasksActions.SUBMIT_TASK_MESSAGE_SUCCESS, tasksActions.SUBMIT_TASK_MESSAGE_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSubmittingTaskMessage: isLoading,
        },
      };
    },
  },
  {
    start: tasksActions.GET_TASK_FILES_REQUEST,
    stop: [ tasksActions.GET_TASK_FILES_SUCCESS, tasksActions.GET_TASK_FILES_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingTaskFiles: isLoading,
        },
      };
    },
  },
  {
    start: tasksActions.GET_TASK_REQUEST,
    stop: [ tasksActions.GET_TASK_SUCCESS, tasksActions.GET_TASK_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingTask: isLoading,
        },
      };
    },
  },
  {
    start: tasksActions.GET_PARENT_CHILDREN_TASKS_REQUEST,
    stop: [ tasksActions.GET_PARENT_CHILDREN_TASKS_SUCCESS, tasksActions.GET_PARENT_CHILDREN_TASKS_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingTaskChildren: isLoading,
        },
      };
    },
  },
  {
    start: tasksActions.GET_TASK_MESSAGES_REQUEST,
    stop: [ tasksActions.GET_TASK_MESSAGES_SUCCESS, tasksActions.GET_TASK_MESSAGES_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingTaskMessages: isLoading,
        },
      };
    },
  },
  {
    start: tasksActions.GET_TASK_HISTORY_REQUEST,
    stop: [ tasksActions.GET_TASK_HISTORY_SUCCESS, tasksActions.GET_TASK_HISTORY_FAILURE ],
    updater: (state: TasksState, isLoading): TasksState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingTaskHistory: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
