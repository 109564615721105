import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

const ListPanelHeader = ({ children }: Props) => {
  return (
    <h4>
      {children}
    </h4>
  );
};

export default ListPanelHeader;
