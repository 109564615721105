import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';
import { OperatorGroupPeer, OperatorToOperatorMessage, OperatorToOperatorMessagePeerCount } from './models';
import { PaginatedSearchContainer, PaginatedSearchResult } from '../../helpers/pagination';
import config from '../../config';

export interface PeerOperatorState {
  entities: {
    operatorToOperatorMessages: { [id: number]: OperatorToOperatorMessage };
  };
  xrefs: {};
  ui: {
    currentUserOperatorGroupPeers: OperatorGroupPeer[];
    currentUserOperatorGroupPeerCounts: OperatorToOperatorMessagePeerCount[];
    searchedPeerMessagesPagination: { [peerOperatorId: number]: PaginatedSearchContainer};
  };
  loading: {
    isLoadingCurrentUserOperatorGroupPeers: boolean;
    isLoadingCurrentUserOperatorGroupPeersUnreadCounts: boolean;
    isSearchingPeerOperatorMessages: boolean;
    isLoadingPeerOperatorMessage: boolean;
  };
}

const initialPeerOperatorState: PeerOperatorState = {
  entities: {
    operatorToOperatorMessages: {},
  },
  xrefs: {},
  ui: {
    currentUserOperatorGroupPeers: [],
    currentUserOperatorGroupPeerCounts: [],
    searchedPeerMessagesPagination: {},
  },
  loading: {
    isLoadingCurrentUserOperatorGroupPeers: false,
    isLoadingCurrentUserOperatorGroupPeersUnreadCounts: false,
    isSearchingPeerOperatorMessages: false,
    isLoadingPeerOperatorMessage: false,
  },
};

export const peerOperatorsSlice = createSlice({
  name: 'PeerOperator',
  initialState: initialPeerOperatorState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<PeerOperatorState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setCurrentUserPeerOperators: (state, action: PayloadAction<OperatorGroupPeer[]>) => {
      state.ui.currentUserOperatorGroupPeers = action.payload;
    },
    setCurrentUserPeerOperatorCounts: (state, action: PayloadAction<OperatorToOperatorMessagePeerCount[]>) => {
      state.ui.currentUserOperatorGroupPeerCounts = action.payload;
    },
    setSearchedPeerMessages: (state, action: PayloadAction<{ result: PaginatedSearchResult<OperatorToOperatorMessage>; peerOperatorId: number }>) => {
      if(!state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId]) {
        state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId] = {
          total: 0,
          ids: [],
          request: config.defaultPagination,
        };
      }

      state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId] = {
        total: action.payload.result.totalItems,
        ids: action.payload.result.currentPage === 0 ? action.payload.result.items.map((item) => item.id) : [
          ...state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId].ids,
          ...action.payload.result.items.map((item) => item.id),
        ],
        request: {
          pageSize: action.payload.result.itemsPerPage,
          offset: action.payload.result.currentPage * action.payload.result.itemsPerPage,
        },
      };

      for(const message of action.payload.result.items) {
        state.entities.operatorToOperatorMessages[message.id] = message;
      }
    },
    setCreatedPeerOperatorMessage: (state, action: PayloadAction<{ message: OperatorToOperatorMessage; peerOperatorId: number }>) => {
      if(!state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId]) {
        state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId] = {
          total: 0,
          ids: [],
          request: config.defaultPagination,
        };
      }

      state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId].ids = [
        action.payload.message.id,
        ...state.ui.searchedPeerMessagesPagination[action.payload.peerOperatorId].ids,
      ];

      state.entities.operatorToOperatorMessages[action.payload.message.id] = action.payload.message;
    },
  },
});
