import React from 'react';
import { Button } from 'reactstrap';
import { PaginatedOffsetSearchState } from 'models';

export interface PaginationNavigationExtendableProps {
  setOffset: (offset: number) => void;
}

interface LocalProps extends PaginationNavigationExtendableProps {
  pagination?: PaginatedOffsetSearchState;
}

const PaginationNavigation = function ({ pagination, ...props }: LocalProps) {
  function goFirst () {
    props.setOffset(0);
  }

  function goBack () {
    if (!pagination) {
      return;
    }

    props.setOffset(pagination.offset - pagination.pageSize);
  }

  function goNext () {
    if (!pagination) {
      return;
    }

    props.setOffset(pagination.offset + pagination.pageSize);
  }

  function goLast () {
    if (!pagination) {
      return;
    }

    const lastPage = Math.floor(pagination.total / pagination.pageSize);

    const flooredOffset = lastPage * pagination.pageSize;

    const offset = flooredOffset === pagination.total ? (lastPage - 1) * pagination.pageSize
      : lastPage * pagination.pageSize;

    props.setOffset(offset);
  }

  if (!pagination) {
    return null;
  }

  const canGoBackwards = pagination.offset > 0;
  const canGoForwards = pagination.offset + pagination.pageSize < pagination.total;
  const numPages = Math.ceil(pagination.total / pagination.pageSize);
  const currentPage = Math.floor(pagination.offset / pagination.pageSize) + 1;

  return (
    <div className="d-flex flex-row child-spacing-x-2">
      <Button color="anchor" disabled={!canGoBackwards} type="button" onClick={goFirst}>
        <i className="mdi mdi-arrow-collapse-left" />
      </Button>
      <Button color="anchor" disabled={!canGoBackwards} type="button" onClick={goBack}>
        <i className="mdi mdi-arrow-left" />
      </Button>

      <span>Page {pagination.total > 0 ? currentPage : 0} of {numPages}</span>

      <Button color="anchor" disabled={!canGoForwards} type="button" onClick={goNext}>
        <i className="mdi mdi-arrow-right" />
      </Button>
      <Button color="anchor" disabled={!canGoForwards} type="button" onClick={goLast}>
        <i className="mdi mdi-arrow-collapse-right" />
      </Button>
    </div>
  );
};

export default React.memo(PaginationNavigation);
