import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';

import { getValueByKey } from 'core';
import { UpdateOperator } from 'Modules/operators/models';
import { operatorsActions } from 'Modules/operators';
import {
  useMergedEntity,
  useOperatorAttributeForm,
  useOperatorAttributes,
  useOperatorForm,
  useRequiredPathId,
  useStateSelector,
} from 'hooks';
import {
  BackgroundImage,
  EditFormFooter,
  EditFormPanel,
  FlexLoader,
  FlexRow,
  OpenModalButton,
  OperatorForm,
  OperatorProfileFormControls,
  Panel,
  PanelHeader,
  UserSearch,
} from 'Components';
import { makeGetEnabledOperatorFeatures, makeGetOperatorUsers } from 'Modules/operators/selectors';
import { getAllUsers } from 'Modules/users/selectors';
import UserXrefType from 'constants/UserXrefType';
import { OperatorFeatureEnum, UserModel } from 'models';
import classnames from 'classnames';
import { ModifyOperatorAttribute, OperatorAttribute } from 'Modules/operatorAttributes/index';
import UploadOperatorProfilePictureModal from '../../../Components/UploadOperatorProfilePictureModal';
import { removeProfilePicture } from '../../../Modules/operators/apiActions';

enum Tab {
  Maintenance,
  Profile
}

const ModifyOperatorPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const operatorId = useRequiredPathId('operatorId');
  const [ currentTab, setCurrentTab ] = useState<Tab>(Tab.Maintenance);
  const workingOperator = useStateSelector((state) => state.operators.ui.maintenance.workingUpdateOperator);
  const isLoadingOperator = useStateSelector((state) => state.operators.loading.isLoadingOperator);
  const isLoadingOperatorFeatures = useStateSelector((state) => state.operators.loading.isLoadingOperatorFeatures);
  const enabledFeatures = useStateSelector(makeGetEnabledOperatorFeatures(operatorId));
  const canEdit = true; //todo permissions useHasPermission(Permission.OperatorsEdit);
  const canDeactivate = true; //todo permissions useHasPermission(Permission.OperatorsDeactivate);
  const rootOperator = useStateSelector((state) => getValueByKey(state.operators.entities.operators, operatorId));
  const operator = useMergedEntity(rootOperator, workingOperator);
  const { updateOperator, deleteOperator, cancelOperatorModification, loading } = useOperatorForm();
  const users = useStateSelector(makeGetOperatorUsers(operatorId));
  const allUsers = useStateSelector(getAllUsers);
  const [ operatorAttributes, isLoadingOperatorAttributes ] = useOperatorAttributes(operatorId);
  const [ operatorAttributeValues, setOperatorAttributeValues ] = useState<{[operatorAttributeTypeId: number]: Partial<ModifyOperatorAttribute>}>({});
  const { updateOperatorAttribute, insertOperatorAttribute } = useOperatorAttributeForm();

  const operatorAttributesByType = useMemo(() => {
    return _.keyBy(operatorAttributes, o => o.attributeTypeId);
  }, [ operatorAttributes ]);

  useEffect(() => {
    dispatch(operatorsActions.async.getOperator(operatorId));
    dispatch(operatorsActions.async.getOperatorFeatures(operatorId));
  }, [ operatorId ]);

  function setWorkingOperatorValues(operator: Partial<UpdateOperator>) {
    dispatch(operatorsActions.setWorkingUpdateOperatorValues(operator));
  }

  function handleDelete() {
    if (!operator) {
      return;
    }

    deleteOperator(operator)
      .then(() => {
        history.push('/admin/operators');
      });
  }

  function handleUserUserXrefTypeChange(userXrefType: UserXrefType, selectedUsers: UserModel[]) {
    dispatch(operatorsActions.async.mergeOperatorUserXrefs(operatorId, userXrefType, selectedUsers.map(u => u.userId)));
  }

  function handleFeatureToggle(featureId: OperatorFeatureEnum) {
    const isCurrentlyActive = enabledFeatures[featureId];

    const paired = {
      [OperatorFeatureEnum.ShareBoard]: [ OperatorFeatureEnum.Polling ],
      [OperatorFeatureEnum.Polling]: [ OperatorFeatureEnum.ShareBoard ],
    };

    if(isCurrentlyActive) {
      dispatch(operatorsActions.async.disableOperatorFeature(operatorId, featureId));

      _.each(paired[featureId], (feature) => {
        dispatch(operatorsActions.async.disableOperatorFeature(operatorId, feature));
      });
    } else {
      dispatch(operatorsActions.async.enableOperatorFeature(operatorId, featureId));

      _.each(paired[featureId], (feature) => {
        dispatch(operatorsActions.async.enableOperatorFeature(operatorId, feature));
      });
    }
  }

  function handleAttributeValueChange(attributeTypeId: number, values: Partial<ModifyOperatorAttribute>) {
    setOperatorAttributeValues({
      ...operatorAttributeValues,
      [attributeTypeId]: {
        ...operatorAttributeValues[attributeTypeId],
        ...values,
      },
    });
  }

  async function handleSave() {
    if (!operator) {
      return;
    }

    await updateOperator(operatorId, operator);

    const promises = _.map(operatorAttributeValues, (operatorAttributeValues, operatorAttributeTypeIdString): Promise<OperatorAttribute> => {
      const operatorAttributeTypeId = parseInt(operatorAttributeTypeIdString, 10);
      const existing = operatorAttributes.find(o => o.attributeTypeId === operatorAttributeTypeId);

      if (existing) {
        return updateOperatorAttribute(operatorId, existing.id, {
          ...operatorAttributeValues,
          attributeTypeId: operatorAttributeTypeId,
        });
      } else {
        return insertOperatorAttribute(operatorId, {
          ...operatorAttributeValues,
          attributeTypeId: operatorAttributeTypeId,
        });
      }
    });

    await Promise.all(promises);

    setOperatorAttributeValues({});
  }

  if (isLoadingOperator || isLoadingOperatorFeatures || isLoadingOperatorAttributes) {
    return (
      <FlexLoader />
    );
  }

  if (!operator) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader white>
        Updating Operator
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelOperatorModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingOperator,
              children: 'Deactivate',
              hidden: !canDeactivate,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isUpdatingOperator,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Nav tabs>
          <NavItem onClick={() => setCurrentTab(Tab.Maintenance)}>
            <NavLink
              className={classnames({ active: currentTab === Tab.Maintenance })}
              onClick={() => setCurrentTab(Tab.Maintenance)}
            >
              Overview
            </NavLink>
          </NavItem>
          <NavItem onClick={() => setCurrentTab(Tab.Profile)}>
            <NavLink
              className={classnames({ active: currentTab === Tab.Profile })}
              onClick={() => setCurrentTab(Tab.Profile)}
            >
              Profile
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentTab}>
          <TabPane className="child-spacing-y-2" tabId={Tab.Maintenance}>
            <Row>
              <Col md={8}>
                <OperatorForm
                  operator={operator}
                  allUsers={allUsers}
                  users={users}
                  setValues={setWorkingOperatorValues}
                />

                <hr />

                <FormGroup>
                  <Label>Users</Label>

                  <UserSearch
                    value={users.filter(u => u.userXrefTypeId === UserXrefType.Account)}
                    onChange={(users) => handleUserUserXrefTypeChange(UserXrefType.Account, [ ...users ])}
                    users={allUsers}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>{t('safetyCoaches')}</Label>

                  <UserSearch
                    value={users.filter(u => u.userXrefTypeId === UserXrefType.SafetyCoach)}
                    onChange={(users) => handleUserUserXrefTypeChange(UserXrefType.SafetyCoach, [ ...users ])}
                    users={allUsers}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Read-Only Users</Label>

                  <UserSearch
                    value={users.filter(u => u.userXrefTypeId === UserXrefType.ReadOnly)}
                    onChange={(users) => handleUserUserXrefTypeChange(UserXrefType.ReadOnly, [ ...users ])}
                    users={allUsers}
                  />
                </FormGroup>

                <hr />

                <FormGroup>
                  <Table size="sm" hover bordered striped>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>Enabled</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Share Board</td>
                        <td>
                          <Toggle
                            checked={enabledFeatures[OperatorFeatureEnum.ShareBoard]}
                            onChange={() => handleFeatureToggle(OperatorFeatureEnum.ShareBoard)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Polling</td>
                        <td>
                          <Toggle
                            checked={enabledFeatures[OperatorFeatureEnum.Polling]}
                            onChange={() => handleFeatureToggle(OperatorFeatureEnum.Polling)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </FormGroup>
              </Col>
            </Row>
          </TabPane>
          <TabPane className="child-spacing-y-2" tabId={Tab.Profile}>
            <Row>
              <Col md={8}>
                <OperatorProfileFormControls
                  operatorAttributesByType={operatorAttributesByType}
                  operatorAttributeTypeValues={operatorAttributeValues}
                  setValues={handleAttributeValueChange}
                />
              </Col>

              <Col md={4}>
                <FormGroup>
                  <div>
                    <Label>Profile Picture</Label>
                  </div>
                  {operator.imageFile ? (
                    <div className="child-spacing-y-1">
                      <BackgroundImage
                        src={operator.imageFile.sasUrl}
                        style={{ width: 300, height: 300 }}
                      />

                      <FlexRow childSpacingX={1}>
                        <OpenModalButton
                          size="sm"
                          color="primary"
                          renderModal={(show, hide) => (
                            <UploadOperatorProfilePictureModal
                              show={show}
                              operatorId={operator.operatorId}
                              hide={hide}
                            />
                          )}
                        >
                          Change Profile Picture
                        </OpenModalButton>

                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => dispatch(removeProfilePicture(operator.operatorId))}
                        >
                          Remove Profile Picture
                        </Button>
                      </FlexRow>
                    </div>
                  ) : (
                    <OpenModalButton
                      renderModal={(show, hide) => (
                        <UploadOperatorProfilePictureModal
                          show={show}
                          operatorId={operator.operatorId}
                          hide={hide}
                        />
                      )}
                    >
                      Upload Profile Picture
                    </OpenModalButton>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyOperatorPanel;
