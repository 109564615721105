import _ from 'lodash';

import { Action, AuditTemplate, ReducerFunction, TemplateTask, UpdateTemplateTaskPosition } from 'models';

import { templateActions } from './actions';
import { commonActions } from '../common/actions';
import { compose } from 'redux';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import loadingConfigs from './loading';

export interface TemplatesState {
  entities: {
    auditTemplates: {[auditTemplateId: number]: AuditTemplate};
    templateTasks: { [templateTaskId: number]: TemplateTask };
  };
  mappings: {
    auditTemplateTasks: {[auditTemplateId: number]: number[]};
    templateTaskChildren: { [parentTaskId: number]: number[] };
  };
  ui: {
    selectedTemplateId?: number;
    allAuditTemplateIds: number[];
  };
  loading: {
    isLoadingAllAuditTemplates: boolean;
    isCreatingAuditTemplate: boolean;
    isDeletingAuditTemplate: boolean;
    isLoadingAuditTemplateTasks: boolean;
    isLoadingParentChildrenTemplateTasks: boolean;
  };
}

const initState: TemplatesState = {
  entities: {
    auditTemplates: {},
    templateTasks: {},
  },
  mappings: {
    auditTemplateTasks: {},
    templateTaskChildren: {},
  },
  ui: {
    selectedTemplateId: undefined,
    allAuditTemplateIds: [],
  },
  loading: {
    isLoadingAllAuditTemplates: false,
    isCreatingAuditTemplate: false,
    isDeletingAuditTemplate: false,
    isLoadingAuditTemplateTasks: false,
    isLoadingParentChildrenTemplateTasks: false,
  },
};

export default compose<ReducerFunction<TemplatesState>>(
  makeLoadingReducer<TemplatesState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): TemplatesState => {
  switch (action.type) {
    case templateActions.SET_SELECTED_AUDIT_TEMPLATE: {
      const { auditTemplateId }: {auditTemplateId?: number} = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedTemplateId: auditTemplateId,
        },
      };
    }
    case templateActions.GET_ALL_AUDIT_TEMPLATES_SUCCESS: {
      const { auditTemplates }: { auditTemplates: AuditTemplate[] } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          auditTemplates: {
            ...state.entities.auditTemplates,
            ..._.keyBy(auditTemplates, t => t.auditTemplateId),
          },
        },
        ui: {
          ...state.ui,
          allAuditTemplateIds: _.map(auditTemplates, t => t.auditTemplateId),
        },
      };
    }
    case templateActions.GET_AUDIT_TEMPLATE_TASKS_SUCCESS: {
      const { auditTemplateId, templateTasks }: { auditTemplateId: number; templateTasks: TemplateTask[] } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          templateTasks: {
            ...state.entities.templateTasks,
            ..._.keyBy(templateTasks, t => t.templateTaskId),
          },
        },
        mappings: {
          ...state.mappings,
          auditTemplateTasks: {
            ...state.mappings.auditTemplateTasks,
            [auditTemplateId]: _.map(templateTasks, t => t.templateTaskId),
          },
        },
      };
    }
    case templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_SUCCESS: {
      const { parentTemplateTaskId, templateTasks }: { parentTemplateTaskId: number; templateTasks: TemplateTask[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          templateTasks: {
            ...state.entities.templateTasks,
            ..._.keyBy(templateTasks, t => t.templateTaskId),
          },
        },
        mappings: {
          ...state.mappings,
          templateTaskChildren: {
            ...state.mappings.templateTaskChildren,
            [parentTemplateTaskId]: _.map(templateTasks, t => t.templateTaskId),
          },
        },
      };
    }
    case templateActions.UPDATE_AUDIT_TEMPLATE_SUCCESS: {
      const { template }: { template: AuditTemplate } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          auditTemplates: {
            ...state.entities.auditTemplates,
            [template.auditTemplateId]: template,
          },
        },
      };
    }
    case templateActions.CREATE_AUDIT_TEMPLATE_SUCCESS: {
      const { template }: { template: AuditTemplate } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          auditTemplates: {
            ...state.entities.auditTemplates,
            [template.auditTemplateId]: template,
          },
        },
        ui: {
          ...state.ui,
          allAuditTemplateIds: _.concat(state.ui.allAuditTemplateIds, template.auditTemplateId),
        },
      };
    }
    case templateActions.UPDATE_TEMPLATE_TASK_POSITIONS_REQUEST: {
      const { positions }: { positions: UpdateTemplateTaskPosition[] } = action.payload;

      const updatedTemplateTasks = { ...state.entities.templateTasks };

      _.each(positions, (p) => {
        const existingTemplateTask = updatedTemplateTasks[p.templateTaskId];

        if (!existingTemplateTask) {
          return;
        }

        updatedTemplateTasks[p.templateTaskId] = {
          ...existingTemplateTask,
          position: p.position,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          templateTasks: updatedTemplateTasks,
        },
      };
    }
    case templateActions.UPDATE_TEMPLATE_TASK_SUCCESS: {
      const { task }: { task: TemplateTask } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          templateTasks: {
            ...state.entities.templateTasks,
            [task.templateTaskId]: task,
          },
        },
      };
    }
    case templateActions.INSERT_TEMPLATE_TASK_SUCCESS: {
      const { task }: { task: TemplateTask } = action.payload;

      const updatedTemplateTaskChildren = task.parentTemplateTaskId ?
        {
          ...state.mappings.templateTaskChildren,
          [task.parentTemplateTaskId]: _.concat(state.mappings.templateTaskChildren[task.parentTemplateTaskId] || [], task.templateTaskId),
        } : state.mappings.templateTaskChildren;

      return {
        ...state,
        entities: {
          ...state.entities,
          templateTasks: {
            ...state.entities.templateTasks,
            [task.templateTaskId]: task,
          },
        },
        mappings: {
          ...state.mappings,
          auditTemplateTasks: {
            ...state.mappings.auditTemplateTasks,
            [task.auditTemplateId]: _.concat(state.mappings.auditTemplateTasks[task.auditTemplateId], task.templateTaskId),
          },
          templateTaskChildren: updatedTemplateTaskChildren,
        },
      };
    }
    case templateActions.DELETE_AUDIT_TEMPLATE_SUCCESS: {
      const { auditTemplateId }: { auditTemplateId: number } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          allAuditTemplateIds: _.without(state.ui.allAuditTemplateIds, auditTemplateId),
          selectedTemplateId: auditTemplateId === state.ui.selectedTemplateId ? undefined : state.ui.selectedTemplateId,
        },
      };
    }
    case commonActions.PATH_LOCATION_CHANGE: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
});
