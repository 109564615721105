import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Card, CardBody } from 'reactstrap';

import { callApi } from 'api/ApiDao';
import { getAccessToken } from 'Modules/site/selectors';
import useFileDownloader from 'hooks/useFileDownloader';
import { ReferenceMaterial } from 'Modules/referenceMaterials/models';
import { getFileIconOrDefault } from 'constants/FileExtensionIcons';
import ClickableDiv from 'Components/ClickableDiv';

interface Props {
  referenceFileId: number;
}

const File = ({ referenceFileId }: Props) => {
  const [ downloadFile, isDownloading ] = useFileDownloader((superagent) => superagent.get(`/api/v1/referenceMaterials/${referenceFileId}/download`));
  const [ referenceFile, setFile ] = useState<ReferenceMaterial | undefined>(undefined);
  const [ isLoading, setIsLoading ] = useState(false);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    setIsLoading(true);
    callApi<ReferenceMaterial>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent.get(`/api/v1/referenceMaterials/${referenceFileId}`),
      onSuccess: (referenceFile) => {
        setFile(referenceFile);
      },
      onComplete: () => {
        setIsLoading(false);
      },
    });
  }, [ accessToken, referenceFileId ]);

  if (isLoading) {
    return (
      <i className="mdi mdi-spin mdi-refresh" />
    );
  }

  if (!referenceFile) {
    return null;
  }

  const extension = referenceFile.file.originalFilename.split('.').pop();

  return (
    <Card className="d-inline-flex" tag={ClickableDiv} onClick={downloadFile}>
      <CardBody className="text-center">
        <div><strong>{referenceFile.name}</strong></div>

        <i className={classnames('mdi mdi-36px', {
          'mdi-spin mdi-refresh': isDownloading,
          [getFileIconOrDefault(extension, 'mdi-referenceFile-question-outline')]: !isDownloading,
        })} />

        <div><small>Click to Download File</small></div>
      </CardBody>
    </Card>
  );
};

export default File;
