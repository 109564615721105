import React from 'react';
import _ from 'lodash';
import { BackgroundImage } from '../../../Components/index';
import { UserModel } from '../../../models';
import { useTranslation } from 'react-i18next';

interface Props {
  safetyCoaches: UserModel[];
}

const SafetyCoachTiles = ({ safetyCoaches }: Props) => {
  const { t } = useTranslation();

  if(safetyCoaches.length === 0) {
    return null;
  }

  return (
    <>
      {_.map(safetyCoaches, (sc) => {
        return (
          <div className="d-flex flex-column align-items-center">
            <BackgroundImage
              key={sc.userId}
              src={sc.profilePictureFile?.sasUrl || '/assets/img/default-user.svg'}
              alt={sc.displayName}
              title={sc.displayName}
              style={{ width: 80, height: 80 }}
            />

            <div className="text-center font-weight-bold text-primary">
              <strong>{sc.displayName}</strong>
            </div>

            <div className="text-center font-weight-bold text-primary">
              <small>{t('safetyCoach')}</small>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SafetyCoachTiles;
