import { saveAs } from 'file-saver';
import { Dispatch } from 'redux';
import { commonActions } from '../Modules/common/actions';
import { FileDownloadAction } from '../models';

const downloadFileMiddleware = () => (next: Dispatch) => async (action: FileDownloadAction) => {
  if (action.type !== commonActions.DOWNLOAD_FILE || !action.payload.file || !action.payload.filename) {
    return next(action);
  }

  const { file, filename } = action.payload;
  saveAs(file, filename);

  next(action);
};

export default downloadFileMiddleware;
