import { shareBoardSlice } from './slice';
import * as shareBoardApiActions from './apiActions';

const shareBoardActions = {
  ...shareBoardSlice.actions,
  async: {
    ...shareBoardApiActions,
  },
};

export default shareBoardActions;
