import _ from 'lodash';
import { Reducer } from 'redux';

import { Action, ReducerFunction } from 'models';
import { getStringArray } from '../../helpers/listHelpers';

export interface LoadingActionConfig<T> {
  start: string | string[];
  stop: string | string[];
  updater?: (loading: T, isLoading: boolean, payload: any) => T;
}

interface FlattenedLoadingActionConfig<T> {
  action: string;
  isLoading: boolean;
  updater?: (loading: T, isLoading: boolean, payload: any) => T;
}

interface MakeLoadingReducerConfig<T> {
  loadingKey: keyof T;
  loadingConfigs: LoadingActionConfig<T>[];
}

const makeLoadingReducer = <T>(config: MakeLoadingReducerConfig<T>) => {
  const configLookup = getFlattenedConfigsLookup(config.loadingConfigs);

  return (reducer: ReducerFunction<T>): Reducer<T, any> => (state: T | undefined, action: Action): T => {
    let updatedState = state;

    if (_.has(configLookup, action.type)) {
      _.each(configLookup[action.type], (loadingConfig) => {
        if (!updatedState) {
          return;
        }

        if (loadingConfig.updater) {
          updatedState = loadingConfig.updater(updatedState, loadingConfig.isLoading, action.payload);
        }
      });
    }

    return reducer(updatedState, action);
  };
};

function getFlattenedConfigsLookup <T> (configs: LoadingActionConfig<T>[]) {
  let configLookup: FlattenedLoadingActionConfig<T>[] = [];

  _.each(configs, (config) => {
    configLookup = configLookup
      .concat(getFlattenedConfigs(config, true, config.start))
      .concat(getFlattenedConfigs(config, false, config.stop));
  });

  return _.groupBy(configLookup, (c) => c.action);
}

function getFlattenedConfigs <T> (config: LoadingActionConfig<T>, isLoading: boolean, action: string | string[]) {
  return _.map(getStringArray(action), (action): FlattenedLoadingActionConfig<T> => {
    return {
      action: action,
      isLoading: isLoading,
      updater: config.updater,
    };
  });
}

export default makeLoadingReducer;
