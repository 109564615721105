import React, { useEffect } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import classnames from 'classnames';

import Routes from './Routes';
import Footer from './Footer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ModifyOperatorAuditModal from '../Pages/Admin/Audits/ModifyOperatorAuditModal';
import { siteActions } from '../Modules/site';
import { categoryActions } from '../Modules/categories';

import SignalRInitializer from './SignalRInitializer';
import AutoTaskModal from '../AviationSafetySolutions/Tasks/AutoTaskModal';
import * as taskActions from '../Modules/tasks/actions';
import * as libraryActions from '../Modules/library/actions';
import * as standardsActions from '../Modules/standards/actions';
import useStateSelector from '../hooks/useStateSelector';
import SelectOperatorPage from '../Pages/SelectOperator/SelectOperatorPage';
import useThunkDispatch from '../hooks/useThunkDispatch';
import OperatorInitializer from './OperatorInitializer';
import { HideWithoutPermission } from '../Components/index';
import { Permission } from '../Modules/roles/index';
import ShareBoardSponsorBanner from './ShareBoardSponsorBanner';

export const initialSearchQuery = window.location.search;

const App = () => {
  const dispatch = useThunkDispatch();
  const isPageModelIsLoaded = useStateSelector(state => !!state.site.pageModel);
  const isDevelopment = useStateSelector(state => _.includes(_.toLower(state.site.pageModel?.environment), 'development'));
  const selectedGlobalTaskId = useStateSelector(state => state.tasks.ui.selectedGlobalTaskId);
  const operatorNeedsToBeSelected = useStateSelector(state => !state.site.selectedOperator);

  useEffect(() => {
    document.title = 'Aviation Safety Solutions';

    dispatch(siteActions.async.getPageModel());
    dispatch(siteActions.async.getUserSelectedOperator());

    setTimeout(() => {
      dispatch(standardsActions.getAllStandards());
      dispatch(categoryActions.getAllCategories());
      dispatch(libraryActions.getAvailableDirectories());
    }, 1000);
  }, []);

  function resetGlobalSelectedTask() {
    dispatch(taskActions.resetGlobalSelectedTask());
  }

  return (
    <>
      <Helmet defaultTitle="Aviation Safety Solutions" titleTemplate="%s | Aviation Safety Solutions"/>
      <div id="wrapper"
        className={classnames({ 'd-flex align-items-center justify-content-center': !isPageModelIsLoaded })}
        style={{ height: classnames({ '100vh': !isPageModelIsLoaded }) }}>
        {!isPageModelIsLoaded && (
          <div>
            <h1 className="align-self-center"><i className="fa fa-spin fa-cog"/> Loading...</h1>
          </div>
        )}
        {isPageModelIsLoaded && (
          <>
            {isDevelopment && (
              <div className="corner-ribbon">
                Beta
              </div>
            )}
            <Navbar />

            <ShareBoardSponsorBanner />

            <Sidebar/>
            <div className="content-page">
              <div className="content">
                {!operatorNeedsToBeSelected && (
                  <OperatorInitializer>
                    <Routes/>
                  </OperatorInitializer>
                )}
                {operatorNeedsToBeSelected && (
                  <>
                    <SelectOperatorPage />
                  </>
                )}
              </div>
              <Footer />
            </div>
            <SignalRInitializer />
          </>
        )}
      </div>

      <HideWithoutPermission permission={Permission.OperatorAuditMaintenance}>
        <ModifyOperatorAuditModal/>
      </HideWithoutPermission>

      <AutoTaskModal
        show={!!selectedGlobalTaskId}
        taskId={selectedGlobalTaskId}
        hide={resetGlobalSelectedTask}
      />
    </>
  );
};

export default App;
