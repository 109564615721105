import './operator-group.scss';

import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import { callApi } from 'api';
import {
  usePaginatedOperatorGroupMessageFileSearch,
  usePaginatedOperatorGroupMessageSearch,
} from 'Modules/operatorGroups/searches';
import { getAccessToken } from 'Modules/site/selectors';
import { operatorGroupsActions, operatorGroupsSelectors } from 'Modules/operatorGroups';
import { OperatorGroupMessageFile } from 'Modules/operatorGroups/models';
import {
  ChatFeed,
  DomClass,
  FlexLoader,
  FlexRow,
  LoadableButton,
  Panel,
  PanelHeader,
  VerticalOnlyScrollPanel,
} from 'Components';
import { ChatMessage } from 'Components/Chat/models';
import Masonry from 'react-masonry-css';
import OperatorGroupMessageFileTile from 'Pages/OperatorGroup/OperatorGroupMessageFileTile';

enum Tab {
  Messages,
  MessageFiles,
}

const OperatorGroupPage = () => {
  const dispatch = useDispatch();
  const [ currentTab, setCurrentTab ] = useState(Tab.Messages);
  const { operatorGroupId: operatorGroupIdString } = useParams<{ operatorGroupId: string; }>();
  const operatorGroupId = parseInt(operatorGroupIdString, 10);
  const operatorGroup = useSelector(operatorGroupsSelectors.selectOperatorGroup(operatorGroupId));
  const isLoadingOperatorGroup = useSelector(operatorGroupsSelectors.isLoadingOperatorGroup);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    dispatch(operatorGroupsActions.async.getOperatorGroup(operatorGroupId));
  }, []);

  const operatorGroupMessagePagination = usePaginatedOperatorGroupMessageSearch({ operatorGroupId })({ searchOnMount: true });
  const operatorGroupMessageFilesPagination = usePaginatedOperatorGroupMessageFileSearch({ operatorGroupId })({ searchOnMount: true });

  const chatMessages = useMemo((): ChatMessage[] => {
    return _.map(operatorGroupMessagePagination.data, (message): ChatMessage => ({
      id: message.operatorGroupMessageId,
      fromMe: message.isFromMyOperator,
      senderName: message.operatorName,
      text: message.messageText,
      createdTimestamp: message.createdTimestamp,
    })).reverse();
  }, [ operatorGroupMessagePagination.data ]);

  function insertOperatorGroupMessage (messageText: string) {
    dispatch(operatorGroupsActions.async.insertOperatorGroupMessage({
      operatorGroupId,
      messageText,
    }));
  }

  function uploadOperatorGroupMessageFile(file: File) {
    const formData = new window.FormData();
    formData.append('file', file);

    return callApi<OperatorGroupMessageFile>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent
        .post(`/api/v1/operatorGroups/${operatorGroupId}/messages/files`)
        .send(formData),
    })
      .then((operatorGroupMessageFile) => {
        return { id: operatorGroupMessageFile.id, operatorGroupId };
      });
  }

  if (isLoadingOperatorGroup) {
    return (
      <FlexLoader />
    );
  }

  if (!operatorGroup) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{operatorGroup.name}</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel className="operator-group">
        <PanelHeader white>
          {operatorGroup.name}
        </PanelHeader>
        <Panel className="p-1">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: currentTab === Tab.Messages })}
                onClick={() => setCurrentTab(Tab.Messages)}
              >
                Messages
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: currentTab === Tab.MessageFiles })}
                onClick={() => setCurrentTab(Tab.MessageFiles)}
              >
                Message Files
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="flex-tabs" activeTab={currentTab}>
            <TabPane className="child-spacing-y-2" tabId={Tab.Messages}>
              <FlexRow justifyBetween>
                <div />
                <div>
                  <LoadableButton isLoading={operatorGroupMessagePagination.isSearching} LoadingLabel="Refreshing..." color="light" onClick={operatorGroupMessagePagination.refresh}>Refresh</LoadableButton>
                </div>
              </FlexRow>
              <ChatFeed
                canLoadMore={operatorGroupMessagePagination.hasMore()}
                onLoadMore={operatorGroupMessagePagination.loadMore}
                onMessageSubmit={insertOperatorGroupMessage}
                messages={chatMessages}
                input={{
                  fileType: 'operatorGroupMessage',
                  uploadFile: uploadOperatorGroupMessageFile,
                }}
              />
            </TabPane>
            <TabPane className="child-spacing-y-2" tabId={Tab.MessageFiles}>
              <FlexRow justifyBetween>
                <div />
                <div>
                  <LoadableButton isLoading={operatorGroupMessageFilesPagination.isSearching} LoadingLabel="Refreshing..." color="light" onClick={operatorGroupMessageFilesPagination.refresh}>Refresh</LoadableButton>
                </div>
              </FlexRow>
              <VerticalOnlyScrollPanel className="p-1">
                <Masonry
                  breakpointCols={{
                    default: 3,
                    500: 1,
                  }} className="project-masonry" columnClassName="project-masonry-item"
                >
                  {
                    _.map(operatorGroupMessageFilesPagination.data, (operatorGroupMessageFileSummary) => {
                      return (
                        <div key={operatorGroupMessageFileSummary.id} className="position-relative border mb-1">
                          <OperatorGroupMessageFileTile operatorMessageFile={operatorGroupMessageFileSummary}/>
                        </div>
                      );
                    })
                  }
                </Masonry>
              </VerticalOnlyScrollPanel>
            </TabPane>
          </TabContent>
        </Panel>
      </Panel>
    </>
  );
};

export default OperatorGroupPage;
