import React from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import TaskStatusBadge from './TaskStatusBadge';
import { AssignedTaskModel } from '../models';
import SetTaskStatusDropdown from './SetTaskStatusDropdown';
import * as taskActions from '../Modules/tasks/actions';
import TaskStatusType from '../constants/TaskStatusType';
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { Div, FlexColumn, FlexRow, HideWithPermission } from 'Components';
import { Permission } from '../Modules/roles/index';

interface Props {
  task?: AssignedTaskModel;
}

const TaskHeader = function ({ task }: Props) {
  const dispatch = useDispatch();
  const standardDescription = task?.standardReferenceId ? `${task?.standardName} - ${task?.standardReferenceLookup}` : '';

  async function notApplicableTask() {
    if (!task) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Task Does Not Apply',
      text: 'Are you sure you want to mark this task as not applicable?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (dismiss) {
      return;
    }

    dispatch(taskActions.notApplicableTask(task.taskId));
  }

  return (
    <FlexColumn>
      <FlexRow overflowHidden={false} childSpacingX={1} alignCenter justifyBetween>
        <FlexRow alignCenter overflowHidden={false} childSpacingX={1}>
          <span>{task?.summary}</span>
          {task?.required && (<small className="text-danger">(Required)</small>)}
          <span><TaskStatusBadge status={task?.currentStatus}/></span>
        </FlexRow>
        <Div childSpacingX={1}>
          <HideWithPermission permission={Permission.GlobalTaskMaintenance}>
            {task?.currentStatus !== TaskStatusType.NotApplicable && (
              <Button color="light" onClick={notApplicableTask}>
                Task Does Not Apply
              </Button>
            )}
          </HideWithPermission>
          <SetTaskStatusDropdown task={task}/>
        </Div>
      </FlexRow>

      {standardDescription && (
        <FlexRow>
          <span id="standard-popover" className="small">
            {task?.standardName} - {task?.standardReferenceLookup} <i className="mdi mdi-information"/>
          </span>

          <UncontrolledPopover trigger="click hover" placement="bottom" target="standard-popover">
            <PopoverHeader>{task?.standardName} - {task?.standardReferenceLookup}</PopoverHeader>
            <PopoverBody>{task?.standardReferenceDescription}</PopoverBody>
          </UncontrolledPopover>
        </FlexRow>
      )}
    </FlexColumn>
  );
};

export default TaskHeader;
