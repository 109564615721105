import React from 'react';
import _ from 'lodash';
import FlexColumn from './FlexColumn';
import { sOrNoS } from '../helpers/listHelpers';

interface LocalProps {
  selectedFiles?: File[];
}

const NUM_FILES_TO_PREVIEW = 2;

const SelectedFilePlaceholder = ({ selectedFiles }: LocalProps) => {
  if (_.isEmpty(selectedFiles)) {
    return null;
  }

  const numFilesSelected = _.size(selectedFiles);

  return (
    <FlexColumn center fill>
      <span><i className="mdi mdi-36px mdi-file-outline" /></span>
      <h4>{numFilesSelected} File{sOrNoS(numFilesSelected)} Selected</h4>
      {
        _.map(_.take(selectedFiles, NUM_FILES_TO_PREVIEW), ({ name }) => {
          return (
            <h5>{name}</h5>
          );
        })
      }
      {numFilesSelected > NUM_FILES_TO_PREVIEW && (
        <h5>and {numFilesSelected - NUM_FILES_TO_PREVIEW} more...</h5>
      )}
    </FlexColumn>
  );
};

export default SelectedFilePlaceholder;
