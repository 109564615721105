import _ from 'lodash';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { OperatorAuditModel, OperatorAuditTasks } from '../../models';
import { ChatMessage } from '../../Components/Chat/models';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';

const getCurrentUser = (state: RootState) => state.site.user;
const selectedOperator = (state: RootState) => state.site.selectedOperator;
const getSelectedOperatorId = (state: RootState) => state.tasks.ui.selectedOperatorId;
const getSelectedAuditId = (state: RootState) => state.tasks.ui.selectedAuditId;
const getTaskEntities = (state: RootState) => state.tasks.entities.tasks;
const getTaskFileEntities = (state: RootState) => state.tasks.entities.taskFiles;
const getTaskStatusEntities = (state: RootState) => state.tasks.entities.taskStatuses;
const getTaskFileIds = (state: RootState) => state.tasks.mappings.taskFiles;
const getTaskStatusIds = (state: RootState) => state.tasks.mappings.taskStatuses;
const getTaskMessageIds = (state: RootState) => state.tasks.mappings.taskMessages;
const getTaskChildren = (state: RootState) => state.tasks.mappings.taskChildren;
const getTaskMessages = (state: RootState) => state.tasks.entities.taskMessages;
const getAuditEntities = (state: RootState) => state.audits.entities.audits;
const getOperatorAuditIds = (state: RootState) => state.audits.mappings.operatorAuditIds;
const getAuditTaskIds = (state: RootState) => state.audits.mappings.auditTasks;

export const getAuditTasks = createSelector([
  getTaskEntities,
  getAuditTaskIds,
  selectedOperator,
], (
  tasks,
  auditTaskIds,
  selectedOperator
) => {
  if (!selectedOperator?.nextAuditId) {
    return [];
  }

  return _.map(auditTaskIds[selectedOperator.nextAuditId], (taskId) => tasks[taskId]);
});

export const getTaskFilesForTask = createSelector([
  getTaskFileEntities,
  getTaskFileIds,
  (state: RootState, taskId?: number) => taskId,
], (
  taskFiles,
  taskFileIds,
  taskId?: number
) => {
  if (!taskId) {
    return [];
  }

  const files = getNumberMappedEntities(taskFiles, taskFileIds[taskId]);

  return _.orderBy(files, [ f => f.uploadedBySafetyCoach, f => f.createdTimestamp ], [ 'desc', 'desc' ]);
});

export const getTaskStatusesForTask = createSelector([
  getTaskStatusEntities,
  getTaskStatusIds,
  (state: RootState, taskId?: number) => taskId,
], (
  taskStatuses,
  taskStatusIds,
  taskId?: number
) => {
  if (!taskId) {
    return [];
  }

  return _.map(taskStatusIds[taskId], (taskFileId) => taskStatuses[taskFileId]);
});

export const getTaskMessagesForTask = createSelector([
  getTaskMessages,
  getTaskMessageIds,
  (state: RootState, taskId?: number) => taskId,
], (
  taskMessages,
  taskMessageIds,
  taskId?: number
) => {
  if (!taskId) {
    return [];
  }

  return _.map(taskMessageIds[taskId], (taskFileId) => taskMessages[taskFileId]);
});

export const getSelectedOperatorAudits = createSelector(
  [ getSelectedOperatorId, getOperatorAuditIds, getAuditEntities ],
  (selectedOperatorId, operatorAuditIds, audits): OperatorAuditModel[] | undefined => {
    if (!selectedOperatorId || !_.has(operatorAuditIds, selectedOperatorId)) {
      return undefined;
    }

    return _.compact(_.map(operatorAuditIds[selectedOperatorId], (i) => audits[i]));
  });

export const getSelectedOperatorAudit = createSelector(
  [ getSelectedOperatorId, getSelectedAuditId, getAuditEntities, getAuditTaskIds, getTaskEntities ],
  (selectedOperatorId, selectedAuditId, audits, auditTaskLookup, tasks): OperatorAuditTasks | undefined => {
    if (!selectedOperatorId || !selectedAuditId || !_.has(audits, selectedAuditId) || !_.has(auditTaskLookup, selectedAuditId)) {
      return undefined;
    }

    const audit = audits[selectedAuditId];
    const auditTaskIds = _.get(auditTaskLookup, selectedAuditId, []);
    const auditTasks = _.compact(_.map(auditTaskIds, (t) => tasks[t]));
    const weeklyTasks = _.filter(auditTasks, t => !!t.taskDate);
    const persistentTasks = _.filter(auditTasks, t => !t.taskDate);

    return {
      ...audit,
      numWeeklyTasks: _.size(weeklyTasks),
      numPersistentTasks: _.size(persistentTasks),
      persistentTasks: _.sortBy(persistentTasks, t => t.position + 1),
      weeks: _.map(audit.weeks, (week) => {
        const { parsedStartDate, parsedEndDate } = week;

        const filteredWeekTasks = _.filter(weeklyTasks, ({ parsedTaskDate }) => {
          return !!parsedTaskDate && ((isEqual(parsedTaskDate, parsedStartDate) || isAfter(parsedTaskDate, parsedStartDate)) &&
            (isEqual(parsedTaskDate, parsedEndDate) || isBefore(parsedTaskDate, parsedEndDate)));
        });

        return {
          ...week,
          tasks: _.sortBy(filteredWeekTasks, (t) => t.position + 1),
        };
      }),
    };
  });

export const getTaskChatMessages = createSelector(
  [ getCurrentUser, getTaskMessagesForTask ],
  (user, taskMessages): ChatMessage[] => {
    return _.map(_.orderBy(taskMessages, m => m.createdTimestamp, 'asc'), (message) => {
      return {
        id: message.taskMessageId,
        senderName: message.createdByUser.displayName,
        text: message.messageText,
        createdTimestamp: message.createdTimestamp,
        fromMe: (user && user.userId === message.createdUser) || false,
      };
    });
  });

export const selectTaskChildren = (parentTaskId?: number) => createSelector([
  getTaskEntities,
  getTaskChildren,
], (
  tasks,
  taskChildren
) => {
  if (!parentTaskId) {
    return [];
  }

  return _.sortBy(_.map(taskChildren[parentTaskId], (taskId) => tasks[taskId]), t => t.position);
});
