import React, { useEffect } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';

import { useClientSideFilter, useStateSelector } from 'hooks';
import { userActions, userSelectors } from 'Modules/users';
import {
  DomClass,
  FlexLoaderPanel,
  NavMenuButton,
  Panel,
  PanelHeader,
  SplitPanel,
  VerticalOnlyScrollPanel,
} from 'Components';

import CreateUserPanel from './CreateUserPanel';
import ModifyUserPanel from './ModifyUserPanel';


const UsersPage = () => {
  const dispatch = useDispatch();

  const users = useStateSelector(userSelectors.getAllUsers);
  const isLoading = useStateSelector(state => state.users.loading.isLoadingAllUsers );

  useEffect(() => {
    dispatch(userActions.getAllUsers());
  }, []);

  const [ filteredUsers, filterText, setFilterText ] = useClientSideFilter(users, [ 'firstName', 'lastName', 'displayName', 'email' ]);

  return (
    <>
      <Helmet>
        <title>User Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 1,
            Component: (
              <Panel>
                <PanelHeader
                  white
                  RightContent={(
                    <Button tag={Link} to="/admin/users/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                  BottomContent={(
                    <div>
                      <Input autoFocus value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                    </div>
                  )}
                >
                  Users ({_.size(filteredUsers)})
                </PanelHeader>

                <Panel className="position-relative">
                  <FlexLoaderPanel isLoading={isLoading}>
                    <VerticalOnlyScrollPanel className="p-1">
                      {
                        _.map(filteredUsers, (user) => {
                          return (
                            <NavMenuButton key={user.userId} to={`/admin/users/${user.userId}`}>
                              {user.firstName} {user.lastName}
                            </NavMenuButton>
                          );
                        })
                      }
                    </VerticalOnlyScrollPanel>
                  </FlexLoaderPanel>
                </Panel>
              </Panel>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/users/create" component={CreateUserPanel} />
                <Route exact path="/admin/users/:userId" component={ModifyUserPanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default UsersPage;
