import React from 'react';
import { DebounceInput as ReactDebounceInput, DebounceInputProps as OriginalDebounceInputProps } from 'react-debounce-input';
import _ from 'lodash';

import appConfig from 'config';

export interface DebounceInputProps extends Omit<OriginalDebounceInputProps<HTMLInputElement, React.HTMLProps<HTMLInputElement>>, 'onChange' | 'ref'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DebounceInput = (props: DebounceInputProps) => {
  return (
    <ReactDebounceInput debounceTimeout={appConfig.debounceTimeout} onChange={_.noop} {...props} autoComplete="off" className="form-control" />
  );
};

export default DebounceInput;
