import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreatePollCategory, PollCategory } from 'Modules/polls/models';
import { pollsActions } from 'Modules/polls';
import { getNewPollCategory } from 'Modules/polls/instantiations';
import { useMergedEntity, usePollCategoryForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, Panel, PanelHeader, PollCategoryForm } from 'Components';

const CreatePollCategoryPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootPollCategory = useMemo(() => getNewPollCategory(), []);
  const workingPollCategory = useStateSelector((state) => state.polls.ui.categoryMaintenance.workingCreatePollCategory);
  const pollCategory = useMergedEntity(rootPollCategory, workingPollCategory);
  const { insertPollCategory, cancelPollCategoryModification, loading } = usePollCategoryForm();

  function setWorkingPollCategoryValues(pollCategory: Partial<CreatePollCategory>) {
    dispatch(pollsActions.setWorkingCreatePollCategoryValues(pollCategory));
  }

  function handleSave() {
    if (!pollCategory) {
      return;
    }
    insertPollCategory(pollCategory)
      .then((pollCategory: PollCategory) => {
        history.push(`/admin/pollCategories/${pollCategory.id}`);
      });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New Poll Category</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelPollCategoryModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingPollCategory,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <PollCategoryForm
              isCreatingNewPollCategory
              pollCategory={pollCategory}
              setValues={setWorkingPollCategoryValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreatePollCategoryPanel;
