import React, { useMemo } from 'react';
import { getEditorStateFromString, getTextFromEditorState } from '../../../core/draft';

interface Props {
  content?: string;
}

const DraftTextViewer = ({ content }: Props) => {
  const editorState = useMemo(() => getEditorStateFromString(content), [ content ]);
  const text = useMemo(() => getTextFromEditorState(editorState), [ editorState ]);
  return (
    <>
      {text}
    </>
  );
};

export default DraftTextViewer;
