import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import toastr from 'toastr';

import createStore from './Modules/store';
import App from './App/App';
import './hotfixes';
import './i18n/i18n';
import AuthLoader from './AuthLoader';

import './assets/scss/bootstrap.scss';
import './assets/scss/styles.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import { authConfig } from './auth';
import TermsOfServiceWrapper from 'App/TermsOfServiceWrapper';
import UserLoader from './UserLoader';
import PageModelWrapper from 'App/PageModelWrapper';

const history = createBrowserHistory();
const rootElement = document.getElementById('root');
const store = createStore(history);

toastr.options = {
  timeOut: 15 * 1000,
  extendedTimeOut: 15 * 1000,
};

/** Hotfix css-modal */

/** Hotfix css-modal */

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Auth0Provider
        domain={authConfig.domain}
        audience={authConfig.audience}
        clientId={authConfig.client_id}
        redirectUri={authConfig.redirect_uri}
      >
        <AuthLoader>
          <UserLoader>
            <PageModelWrapper>
              <TermsOfServiceWrapper>
                <App />
              </TermsOfServiceWrapper>
            </PageModelWrapper>
          </UserLoader>
        </AuthLoader>
      </Auth0Provider>
    </ConnectedRouter>
  </Provider>,
  rootElement);
