import { categoriesActions } from './actions';
import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { CategoriesState } from './reducer';

const loadingConfigs: LoadingActionConfig<CategoriesState>[] = [
  {
    start: categoriesActions.GET_CATEGORY_REQUEST,
    stop: [ categoriesActions.GET_CATEGORY_SUCCESS, categoriesActions.GET_CATEGORY_FAILURE ],
    updater: (state: CategoriesState, isLoading): CategoriesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingCategory: isLoading,
        },
      };
    },
  },
  {
    start: categoriesActions.GET_ALL_CATEGORIES_REQUEST,
    stop: [ categoriesActions.GET_ALL_CATEGORIES_SUCCESS, categoriesActions.GET_ALL_CATEGORIES_FAILURE ],
    updater: (state: CategoriesState, isLoading): CategoriesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingAllCategories: isLoading,
        },
      };
    },
  },
  {
    start: categoriesActions.INSERT_CATEGORY_REQUEST,
    stop: [ categoriesActions.INSERT_CATEGORY_SUCCESS, categoriesActions.INSERT_CATEGORY_FAILURE ],
    updater: (state: CategoriesState, isLoading): CategoriesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isInsertingCategory: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
