import _ from 'lodash';
import { categoriesActions } from './actions';
import { Action, ReducerFunction } from '../../models';
import { compose } from 'redux';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import { CategoryModel } from './models';
import loadingConfigs from './loading';

export interface CategoriesState {
  entities: {
    categories: {[categoryId: string]: CategoryModel};
  };
  ui: {
    allCategoryIds: string[];
  };
  loading: {
    isLoadingCategory: boolean;
    isInsertingCategory: boolean;
    isLoadingAllCategories: boolean;
  };
}

const initState: CategoriesState = {
  entities: {
    categories: {},
  },
  ui: {
    allCategoryIds: [],
  },
  loading: {
    isLoadingCategory: false,
    isInsertingCategory: false,
    isLoadingAllCategories: false,
  },
};

export default compose<ReducerFunction<CategoriesState>>(
  makeLoadingReducer<CategoriesState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): CategoriesState => {
  switch (action.type) {
    case categoriesActions.GET_ALL_CATEGORIES_SUCCESS: {
      const { categories }: { categories: CategoryModel[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          categories: _.keyBy(categories, c => c.categoryId),
        },
        ui: {
          ...state.ui,
          allCategoryIds: _.map(categories, c => c.categoryId),
        },
      };
    }
    case categoriesActions.INSERT_CATEGORY_SUCCESS: {
      const { category }: { category: CategoryModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          categories: {
            ...state.entities.categories,
            [category.categoryId]: category,
          },
        },
        ui: {
          ...state.ui,
          allCategoryIds: state.ui.allCategoryIds.concat(category.categoryId),
        },
      };
    }
    default: {
      return state;
    }
  }
});
