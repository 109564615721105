import { useState } from 'react';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';

const useClientSideFilter = <T extends any>(items: T[], keys: (keyof T & string)[]): [ T[], string, (filterText: string) => void ] => {
  const [ filterText, setFilterText ] = useState<string>('');

  let matched = items;
  if(_.trim(filterText)) {
    matched = matchSorter(items, filterText, { keys: keys });
  }

  return [ matched, filterText, setFilterText ];
};

export default useClientSideFilter;
