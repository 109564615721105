import React from 'react';
import PdfViewer from 'Components/PdfViewer/PdfViewer';
import { FileDetails } from 'Modules/library/models';

interface LocalProps {
  fileDetails: FileDetails;
  downloadFile: () => void;
}

const PdfPreview = ({ fileDetails, downloadFile }: LocalProps) => (
  <PdfViewer dataUrl={fileDetails.dataUrl} download={downloadFile} />
);

export default PdfPreview;
