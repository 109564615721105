import React from 'react';
import classnames from 'classnames';

import { Div, DivExtendableProps } from 'Components';

export interface FlexExtendableProps
  extends React.HTMLProps<HTMLDivElement>,
    DivExtendableProps {
  direction?: 'col' | 'row' | 'col-reverse' | 'row-reverse';
  center?: boolean;
  justifyCenter?: boolean;
  justifyBetween?: boolean;
  justifyAround?: boolean;
  justifyStart?: boolean;
  justifyEnd?: boolean;
  alignCenter?: boolean;
  flexWrap?: boolean;
}

interface Props extends FlexExtendableProps {
  children: any;
}

const Flex = ({
  direction,
  overflowHidden,
  className,
  center,
  alignCenter,
  justifyCenter,
  justifyBetween,
  justifyAround,
  justifyStart,
  justifyEnd,
  children,
  flexWrap,
  ...rest
}: Props) => {
  return (
    <Div
      {...rest}
      overflowHidden={overflowHidden}
      className={classnames('d-flex', className, {
        'flex-column': direction === 'col',
        'flex-row': direction === 'row',
        'flex-column-reverse': direction === 'col-reverse',
        'flex-row-reverse': direction === 'row-reverse',
        'justify-content-center': center || justifyCenter,
        'justify-content-start': justifyStart,
        'justify-content-end': justifyEnd,
        'justify-content-around': justifyAround,
        'justify-content-between': justifyBetween,
        'align-items-center': center || alignCenter,
        'flex-wrap': flexWrap,
      })}
    >
      {children}
    </Div>
  );
};

export default Flex;
