import { compose } from 'redux';
import _ from 'lodash';

import { Action, ReducerFunction } from 'models';
import makeLoadingReducer from 'Modules/enhancers/makeLoadingReducer';
import loadingConfigs from './loading';
import { Standard, StandardReference } from './models';
import { standardsActions } from './actions';

export interface StandardsState {
  entities: {
    standards: { [standardId: number]: Standard };
    standardReferences: { [standardReferenceId: number]: StandardReference };
  };
  mappings: {
    standardReferences: { [standardId: number]: number[] };
  };
  ui: {
    allStandardIds: number[];
  };
  loading: {
    isLoadingAllStandards: boolean;
    isLoadingStandardReferences: boolean;
  };
}

const initState: StandardsState = {
  entities: {
    standards: {},
    standardReferences: {},
  },
  mappings: {
    standardReferences: {},
  },
  ui: {
    allStandardIds: [],
  },
  loading: {
    isLoadingAllStandards: false,
    isLoadingStandardReferences: false,
  },
};

export default compose<ReducerFunction<StandardsState>>(
  makeLoadingReducer<StandardsState>({ loadingKey: 'loading', loadingConfigs: loadingConfigs })
)((state = initState, action: Action): StandardsState => {
  switch (action.type) {
    case standardsActions.GET_ALL_STANDARDS_SUCCESS: {
      const { standards }: { standards: Standard[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          standards: {
            ...state.entities.standards,
            ..._.keyBy(standards, s => s.standardId),
          },
        },
        ui: {
          ...state.ui,
          allStandardIds: _.map(standards, s => s.standardId),
        },
      };
    }
    case standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_SUCCESS: {
      const { standardId, references }: { standardId: number; references: StandardReference[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          standardReferences: {
            ...state.entities.standardReferences,
            ..._.keyBy(references, s => s.standardReferenceId),
          },
        },
        mappings: {
          ...state.mappings,
          standardReferences: {
            ...state.mappings.standardReferences,
            [standardId]: _.map(references, r => r.standardReferenceId),
          },
        },
      };
    }
    default: {
      return state;
    }
  }
});
