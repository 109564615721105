import { siteSlice } from './slice';
import * as siteApiActions from './apiActions';

const siteActions = {
  ...siteSlice.actions,
  async: {
    ...siteApiActions,
  },
};

export default siteActions;
