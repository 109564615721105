import _ from 'lodash';

interface ActionTypeConfigCollection {
  [key: number]: ActionTypeConfigModel;
}

interface ActionTypeConfigModel {
  id: ActionType;
}

enum ActionType {
  Upload = 1,
  Email = 2,
  Check = 3
}

export const ActionTypeConfig = {
  [ActionType.Upload]: {
    id: ActionType.Upload,
    label: 'Upload',
    icon: 'mdi mdi-cloud-upload',
  },
  [ActionType.Email]: {
    id: ActionType.Email,
    label: 'Message',
    icon: 'mdi mdi-email',
  },
  [ActionType.Check]: {
    id: ActionType.Check,
    label: 'Review',
    icon: 'mdi mdi-checkbox-marked',
  },
} as ActionTypeConfigCollection;

export const DisplayActionType = toPropertyLookup(ActionTypeConfig, 'label');
export const ActionTypeIcon = toPropertyLookup(ActionTypeConfig, 'icon');

export const ActionTypeOptions = _.map(ActionTypeConfig, ({ label, id }: { label: string; id: ActionType }) => ({ label: label, value: id }));

export default ActionType;

function toPropertyLookup (items: ActionTypeConfigCollection, property: string): { [key: string]: any } {
  const lookup = {} as { [key: string]: any };

  _.each(items, (item, key) => {
    lookup[key] = _.get(item, property);
  });

  return lookup;
}
