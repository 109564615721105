import React, { ReactNode, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { operatorsActions } from 'Modules/operators';
import { useSelectedOperatorOrThrow, useStateSelector } from 'hooks';
import { FlexLoader } from 'Components';

interface Props {
  children: ReactNode;
}

const OperatorInitializer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const operator = useSelectedOperatorOrThrow();

  const featuresLoaded = useStateSelector(state => _.has(state.operators.xrefs.operatorFeatures, operator.operatorId));

  useEffect(() => {
    dispatch(operatorsActions.async.getOperatorFeatures(operator.operatorId));
  }, [ operator.operatorId ]);

  if(!featuresLoaded) {
    return (
      <FlexLoader />
    );
  }
  return (
    <>
      {children}
    </>
  );
};

export default OperatorInitializer;
