import React from 'react';
import { DatetimepickerProps } from 'react-datetime';
import moment from 'moment';

import DatePicker from './DatePicker';

interface Props extends Omit<DatetimepickerProps, 'value'| 'onChange'> {
  value: string | undefined;
  onChange: (date: string | undefined) => any;
}

const StringDatePicker = ({ value, ...props }: Props) => {
  const momentDate = moment.utc(value);
  return (
    <DatePicker
      {...props}
      value={momentDate.isValid() && value ? momentDate.toDate() : undefined}
      onChange={(date) => props.onChange(date ? moment.utc(date).format('YYYY-MM-DD') : undefined)}/>
  );
};

export default StringDatePicker;
