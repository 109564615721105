import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useStateSelector } from 'hooks';
import {
  operatorAttributesActions,
  operatorAttributesSelectors,
  OperatorAttributeType,
} from 'Modules/operatorAttributes';

const useOperatorAttributeTypes = (): [ OperatorAttributeType[], boolean ] => {
  const dispatch = useDispatch();
  const operatorActiveAttributeTypes = useStateSelector(operatorAttributesSelectors.getAllActiveOperatorAttributeTypes);
  const isLoadingOperatorAttributeTypes = useStateSelector(state => state.operatorAttributes.loading.isLoadingAllOperatorAttributeTypes);

  useEffect(() => {
    dispatch(operatorAttributesActions.async.getOperatorAttributeTypes());
  }, []);

  return [ operatorActiveAttributeTypes, isLoadingOperatorAttributeTypes ];
};

export default useOperatorAttributeTypes;
