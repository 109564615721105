import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { formatDistanceToNow, parseISO } from 'date-fns';

interface Props {
  value: Date | string;
  refreshFrequencySeconds?: number;
}

const ReadableTimestamp = ({ value, refreshFrequencySeconds }: Props) => {
  const [ displayDate, setDisplayDate ] = useState<string>(getFormattedDate(value));

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayDate(getFormattedDate(value));
    }, refreshFrequencySeconds || 30);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function getFormattedDate(value: string | Date) {
    return formatDistanceToNow(_.isDate(value) ? value : parseISO(value));
  }

  return (
    <>
      {displayDate} ago
    </>
  );
};

export default ReadableTimestamp;

