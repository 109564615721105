import { AssignedTaskModel } from '../../models';
import { format, isPast, isThisWeek, parse } from 'date-fns';

export const getTaskModel = (task: AssignedTaskModel): AssignedTaskModel => {
  const parsedTaskDate = task.taskDate ? parse(task.taskDate, 'yyyy-MM-dd', new Date()) : undefined;
  const dateIsThisWeek = !!parsedTaskDate && isThisWeek(parsedTaskDate);
  const dateIsFutureWeek = !!parsedTaskDate && !isThisWeek(parsedTaskDate) && !isPast(parsedTaskDate);
  const isPastDue = !!parsedTaskDate && isPast(parsedTaskDate) && !dateIsThisWeek;

  return {
    ...task,
    displayTaskDate: parsedTaskDate ? format(parsedTaskDate, 'EEE, MMM do') : '',
    parsedTaskDate: parsedTaskDate,
    isThisWeek: dateIsThisWeek,
    isFutureWeek: dateIsFutureWeek,
    isPastDue: isPastDue,
  };
};
