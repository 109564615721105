import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useStateSelector } from 'hooks';
import { getTaskMessages, submitTaskMessage } from 'Modules/tasks/actions';
import { getTaskChatMessages } from 'Modules/tasks/selectors';
import { ChatFeed } from 'Components';
import { callApi } from 'api/index';
import { getAccessToken } from 'Modules/site/selectors';
import { TaskMessageFile } from 'models';
import { Task } from 'Modules/tasks/models';
import ActionType from '../../constants/ActionType';
import TaskStatusType from '../../constants/TaskStatusType';
import { userConfirm } from '../../core/index';
import { useTranslation } from 'react-i18next';

interface Props {
  task: Task;
}

const TaskMessages = ({ task }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const messages = useStateSelector(state => getTaskChatMessages(state, task.taskId));
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    dispatch(getTaskMessages(task.taskId));
  }, []);

  function handleMessageSubmit(message: string) {
    const shouldSubmit = task.actionTypeId === ActionType.Email && task.currentStatus !== TaskStatusType.PendingApproval;
    if (shouldSubmit) {
      userConfirm({
        title: 'Submit Task',
        text: `Are you ready to send this task to your ${t('safetyCoach')} for approval?`,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No, Just Send Message',
      }, () => {
        dispatch(submitTaskMessage(task.taskId, message, true));
      }, () => {
        dispatch(submitTaskMessage(task.taskId, message));
      });
      return;
    }

    dispatch(submitTaskMessage(task.taskId, message));
  }

  function uploadTaskMessageFile(file: File) {
    const formData = new window.FormData();
    formData.append('file', file);

    return callApi<TaskMessageFile>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent
        .post(`/api/v1/tasks/${task.taskId}/messagesFiles`)
        .send(formData),
    })
      .then((taskMessageFile) => {
        return { id: taskMessageFile.id, taskId: task.taskId };
      });
  }

  return (
    <div className="task-messages">
      <ChatFeed
        messages={messages}
        onMessageSubmit={handleMessageSubmit}
        input={{
          fileType: 'taskMessage',
          uploadFile: uploadTaskMessageFile,
        }}
      />
    </div>
  );
};

export default TaskMessages;
