import _ from 'lodash';

const makeStorageHelper = (storage: Storage) => {
  const storageHelper = {
    getOrCreate: <T>(key: string, value: T): T => {
      if (storageHelper.exists(key)) {
        return storageHelper.get(key)!;
      }

      storageHelper.set(key, value);

      return storageHelper.get(key)!;
    },
    exists: (key: string): boolean => storage.getItem(key) !== null,
    get: <T>(key: string): T | null => {
      const storageValue = storage.getItem(key);

      if (_.isNil(storageValue)) {
        return null;
      }

      return JSON.parse(storageValue);
    },
    set: <T>(key: string, value: T): void => {
      if (_.isNil(value)) {
        storage.removeItem(key);
      }

      storage.setItem(key, JSON.stringify(value));
    },
  };

  return storageHelper;
};

export type StorageHelper = ReturnType<typeof makeStorageHelper>;

export const localStorageHelper = makeStorageHelper(localStorage);

export const sessionStorageHelper = makeStorageHelper(sessionStorage);
