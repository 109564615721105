import _ from 'lodash';
import TaskStatusType from '../constants/TaskStatusType';
import { AssignedTaskModel } from '../models';

export const taskIsOperatorInProgress = (assignedTask: AssignedTaskModel) => {
  return _.includes([ TaskStatusType.Created, TaskStatusType.Rejected ], assignedTask.currentStatus);
};

export const taskIsOperatorComplete = (assignedTask: AssignedTaskModel) => {
  return _.includes([ TaskStatusType.Complete, TaskStatusType.NotApplicable, TaskStatusType.PendingApproval ], assignedTask.currentStatus);
};
