import _ from 'lodash';
import { libraryActions } from './actions';
import { Action, LibraryFile, ReducerFunction } from '../../models';
import { commonActions } from '../common/actions';
import { compose } from 'redux';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import loadingConfigs from './loading';
import { DeleteLibraryFolderRequest, LibraryPosition, OperatorHiddenFolder, UpdateLibraryFilePosition } from './models';

export interface LibraryState {
  entities: {
    files: {[taskFileId: string]: LibraryFile};
    operatorHiddenFolders: {[operatorHiddenFolderId: string]: OperatorHiddenFolder};
  };
  mappings: {
    operatorUploadFiles: {[operatorId: number]: number[]};
    operatorHiddenFolders: {[operatorId: number]: number[]};
  };
  ui: {
    availableDirectories: string[];
    libraryPositions: { [filePath: string]: number | undefined };
    operatorFolders: { [operatorId: number]: string[] };
    operatorCreatedFolders: { [operatorId: number]: string[] };
    operatorSelectedFiles: { [operatorId: number]: number[] };
    showingHiddenFolders: boolean;
  };
  loading: {
    downloadingFiles: {[taskFileId: string]: string};
    isDownloadingFiles: boolean;
    isDownloadingOperatorFiles: boolean;
    isUploadingFiles: boolean;
    isUploadingLibraryFiles: boolean;
    isLoadingLibraryFolders: boolean;
    isLoadingLibraryFiles: boolean;
    isRenamingTaskFile: boolean;
    isLoadingFolderInfo: boolean;
    isDeletingLibraryFolder: boolean;
    isMovingLibraryFiles: boolean;
    isLoadingLibraryPositions: boolean;
    isLoadingOperatorHiddenFolders: boolean;
  };
}

const initState: LibraryState = {
  entities: {
    files: {},
    operatorHiddenFolders: {},
  },
  mappings: {
    operatorUploadFiles: {},
    operatorHiddenFolders: {},
  },
  ui: {
    libraryPositions: {},
    availableDirectories: [],
    operatorFolders: {},
    operatorCreatedFolders: {},
    operatorSelectedFiles: {},
    showingHiddenFolders: false,
  },
  loading: {
    downloadingFiles: {},
    isDownloadingFiles: false,
    isDownloadingOperatorFiles: false,
    isUploadingFiles: false,
    isUploadingLibraryFiles: false,
    isLoadingLibraryFolders: false,
    isLoadingLibraryFiles: false,
    isRenamingTaskFile: false,
    isLoadingFolderInfo: false,
    isDeletingLibraryFolder: false,
    isMovingLibraryFiles: false,
    isLoadingLibraryPositions: false,
    isLoadingOperatorHiddenFolders: false,
  },
};

export default compose<ReducerFunction<LibraryState>>(
  makeLoadingReducer<LibraryState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): LibraryState => {
  switch (action.type) {
    case libraryActions.TOGGLE_SHOWING_HIDDEN_FOLDERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showingHiddenFolders: !state.ui.showingHiddenFolders,
        },
      };
    }
    case libraryActions.GET_OPERATOR_FILES_SUCCESS: {
      const { operatorId, files }: { operatorId: number; files: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            ..._.keyBy(files, f => f.libraryFileId),
          },
        },
        mappings: {
          ...state.mappings,
          operatorUploadFiles: {
            ...state.mappings.operatorUploadFiles,
            [operatorId]: _.map(files, f => f.libraryFileId),
          },
        },
      };
    }
    case libraryActions.GET_AVAILABLE_DIRECTORIES_SUCCESS: {
      const { directories }: { directories: string[] } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          availableDirectories: directories,
        },
      };
    }
    case libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_SUCCESS: {
      const { operatorId, hiddenFolders }: { operatorId: number; hiddenFolders: OperatorHiddenFolder[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          operatorHiddenFolders: {
            ...state.entities.operatorHiddenFolders,
            ..._.keyBy(hiddenFolders, h => h.operatorHiddenFolderId),
          },
        },
        mappings: {
          ...state.mappings,
          operatorHiddenFolders: {
            ...state.mappings.operatorHiddenFolders,
            [operatorId]: _.map(hiddenFolders, h => h.operatorHiddenFolderId),
          },
        },
      };
    }
    case libraryActions.RENAME_LIBRARY_FILE_SUCCESS: {
      const { libraryFile }: { libraryFile: LibraryFile } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            [libraryFile.libraryFileId]: libraryFile,
          },
        },
      };
    }
    case libraryActions.GET_OPERATOR_FOLDERS_SUCCESS: {
      const { operatorId, folders }: { operatorId: number; folders: string[] } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorFolders: {
            ...state.ui.operatorFolders,
            [operatorId]: folders,
          },
        },
      };
    }
    case libraryActions.ADD_LIBRARY_FOLDER: {
      const { operatorId, folderPath }: { operatorId: number; folderPath: string } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorCreatedFolders: {
            ...state.ui.operatorCreatedFolders,
            [operatorId]: _.concat(state.ui.operatorCreatedFolders[operatorId] || [], folderPath),
          },
        },
      };
    }
    case libraryActions.DELETE_FILES_SUCCESS: {
      const { libraryFiles }: { libraryFiles: LibraryFile[] } = action.payload;

      let files = state.entities.files;
      let operatorUploadFiles = state.mappings.operatorUploadFiles;
      let operatorSelectedFiles = state.ui.operatorSelectedFiles;

      _.each(libraryFiles, ({ libraryFileId }) => {
        const existingFile = state.entities.files[libraryFileId];

        if (!existingFile) {
          return;
        }

        files = _.omit(files, libraryFileId);
        operatorUploadFiles = {
          ...operatorUploadFiles,
          [existingFile.operatorId]: _.without(operatorUploadFiles[existingFile.operatorId], libraryFileId),
        };
        operatorSelectedFiles = {
          ...operatorSelectedFiles,
          [existingFile.operatorId]: _.without(operatorSelectedFiles[existingFile.operatorId], libraryFileId),
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          files,
        },
        mappings: {
          ...state.mappings,
          operatorUploadFiles,
        },
        ui: {
          ...state.ui,
          operatorSelectedFiles,
        },
      };
    }
    case libraryActions.MOVE_LIBRARY_FILES_SUCCESS: {
      const { libraryFiles }: { libraryFiles: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            ..._.keyBy(libraryFiles, f => f.libraryFileId),
          },
        },
        ui: {
          ...state.ui,
          operatorSelectedFiles: {},
        },
      };
    }
    case libraryActions.POST_LIBRARY_FILES_TO_SHARE_BOARD_SUCCESS: {
      const { libraryFiles }: { libraryFiles: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            ..._.keyBy(libraryFiles, f=> f.libraryFileId),
          },
        },
      };
    }
    case libraryActions.UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_SUCCESS: {
      const { libraryFiles }: { libraryFiles: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            ..._.keyBy(libraryFiles, f=> f.libraryFileId),
          },
        },
      };
    }
    case libraryActions.RENAME_LIBRARY_FOLDER_SUCCESS: {
      const { libraryFiles }: { libraryFiles: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            ..._.keyBy(libraryFiles, f => f.libraryFileId),
          },
        },
      };
    }
    case libraryActions.SELECT_FILE: {
      const { operatorId, libraryFileId }: { operatorId: number; libraryFileId: number } = action.payload;

      const currentlySelectedFileIds = state.ui.operatorSelectedFiles[operatorId] || [];

      const isAlreadySelected = _.includes(currentlySelectedFileIds, libraryFileId);

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorSelectedFiles: {
            ...state.ui.operatorSelectedFiles,
            [operatorId]: isAlreadySelected ? _.without(currentlySelectedFileIds, libraryFileId)
              : _.concat(currentlySelectedFileIds, libraryFileId),
          },
        },
      };
    }
    case libraryActions.SELECT_ALL_FILES: {
      const { operatorId, libraryFileIds }: { operatorId: number; libraryFileIds: number[] } = action.payload;

      const currentlySelectedFileIds = state.ui.operatorSelectedFiles[operatorId] || [];

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorSelectedFiles: {
            ...state.ui.operatorSelectedFiles,
            [operatorId]: _.union(currentlySelectedFileIds, libraryFileIds),
          },
        },
      };
    }
    case libraryActions.SELECT_NONE_FILES: {
      const { operatorId }: { operatorId: number } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorSelectedFiles: {
            ...state.ui.operatorSelectedFiles,
            [operatorId]: [],
          },
        },
      };
    }
    case libraryActions.DELETE_LIBRARY_FOLDER_SUCCESS: {
      const { files, request }: { request: DeleteLibraryFolderRequest; files: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          files: {
            ...state.entities.files,
            ..._.keyBy(files, f => f.libraryFileId),
          },
        },
        ui: {
          ...state.ui,
          operatorFolders: {
            ...state.ui.operatorFolders,
            [request.operatorId]: _.without(state.ui.operatorFolders[request.operatorId] || [], request.folderPath),
          },
          operatorCreatedFolders: {
            ...state.ui.operatorCreatedFolders,
            [request.operatorId]: _.without(state.ui.operatorCreatedFolders[request.operatorId] || [], request.folderPath),
          },
        },
      };
    }
    case libraryActions.UPDATE_LIBRARY_FILE_POSITIONS_REQUEST: {
      const { positions }: { positions: UpdateLibraryFilePosition[] } = action.payload;

      const updatedLibraryPositions = { ...state.ui.libraryPositions };

      _.each(positions, (position) => {
        updatedLibraryPositions[position.filePath] = position.position;
      });

      return {
        ...state,
        ui: {
          ...state.ui,
          libraryPositions: updatedLibraryPositions,
        },
      };
    }
    case libraryActions.UPDATE_LIBRARY_FILE_POSITIONS_FAILURE: {
      const { positions }: { positions: UpdateLibraryFilePosition[] } = action.payload;

      const updatedLibraryPositions = { ...state.ui.libraryPositions };

      _.each(positions, (position) => {
        updatedLibraryPositions[position.filePath] = position.originalPosition;
      });

      return {
        ...state,
        ui: {
          ...state.ui,
          libraryPositions: updatedLibraryPositions,
        },
      };
    }
    case libraryActions.GET_LIBRARY_POSITIONS_SUCCESS: {
      const { libraryPositions }: { libraryPositions: LibraryPosition[] } = action.payload;

      const updatedLibraryPositions = { ...state.ui.libraryPositions };

      _.each(libraryPositions, (position) => {
        updatedLibraryPositions[position.filePath] = position.position;
      });

      return {
        ...state,
        ui: {
          ...state.ui,
          libraryPositions: updatedLibraryPositions,
        },
      };
    }
    case commonActions.PATH_LOCATION_CHANGE: {
      return initState;
    }
    default: {
      return state;
    }
  }
});
