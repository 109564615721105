import React from 'react';
import { Button } from 'reactstrap';

import { TreeNode } from 'Modules/library/models';

interface Props<T> {
  node: TreeNode<T>;
  setFilePath: (node: TreeNode<T>) => void;
}

const FileTreeBreadcrumb = <T extends any>({ node, ...props }: Props<T>) => {
  return (
    <Button color="light" onClick={() => props.setFilePath(node)}>
      {node.name}
    </Button>
  );
};

export default FileTreeBreadcrumb;
