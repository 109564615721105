import React from 'react';

const UploadFilePlaceholder = () => {
  return (
    <>
      <span>Drop File Here</span>
      <span>or</span>
      <span className="text-primary">Browse</span>
    </>
  );
};

export default UploadFilePlaceholder;
