import React from 'react';
import classnames from 'classnames';

import Icon, { IconExtendableProps } from './Icon';

interface Props extends IconExtendableProps {
  isLoading: boolean;
}

const RefreshIcon = ({ isLoading = true, className, ...rest }: Props) => {
  return (
    <Icon
      {...rest}
      className={classnames(className, 'mdi mdi-refresh', {
        'mdi-spin': isLoading,
      })}
    />
  );
};

export default React.memo(RefreshIcon);
