import React from 'react';
import classnames from 'classnames';

interface LocalProps {
  className?: string;
}

const TaskNotificationIcon = ({ className }: LocalProps) => {
  return (
    <i title="Task has unread notification" className={classnames('mdi mdi-bell-alert text-danger', className)} />
  );
};

export default React.memo(TaskNotificationIcon);
