import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, CardHeader, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import toastr from 'toastr';
import { stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import { AuditTemplate, CreateTemplateTask, TemplateTask, UpdateTemplateTaskPosition } from 'models';
import FlexRow from '../../Components/FlexRow';
import TaskMaintenanceForm from '../../AviationSafetySolutions/TaskMaintenance/TaskMaintenanceForm/TaskMaintenanceForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParentChildrenTemplateTasks,
  insertTemplateTask,
  updateTemplateTask,
  updateTemplateTaskPositions,
} from '../../Modules/templates/actions';
import useOnModalShow from '../../hooks/useOnModalShow';
import { getEditorStateFromString } from '../../core/draft';
import { convertToRaw, EditorState } from 'draft-js';
import { RootState } from '../../Modules/reducers';
import { ReactSortable } from 'react-sortablejs';
import { selectTemplateTaskChildren } from '../../Modules/templates/selectors';
import { validateTask } from '../../Modules/tasks/validations';
import useOnModalHide from '../../hooks/useOnModalHide';

interface Props {
  show: boolean;
  template: AuditTemplate;
  templateTask: Partial<TemplateTask> & { templateTaskId: number };
  hide: () => void;
}

const ModifyTemplateTaskModal = ({ show, template, templateTask, ...props }: Props) => {
  const dispatch = useDispatch();
  const tempChildTemplateTasks = useRef<TemplateTask[] | null>();
  const [ modifyingChildTemplateTask, setModifyingChildTemplateTask ] = useState<Partial<TemplateTask> & { templateTaskId: number } | undefined>();
  const [ modifyingTemplateTask, setModifyingTemplateTask ] = useState<Partial<TemplateTask>>({});
  const [ createdParentTemplateTask, setCreatedParentTemplateTask ] = useState<TemplateTask | undefined>();
  const mergedTemplateTask = useMemo(() => ({ ...templateTask, ...modifyingTemplateTask, ...createdParentTemplateTask }), [ templateTask, modifyingTemplateTask, createdParentTemplateTask ]);
  const isSavingTemplateTask = useSelector(() => false);
  const [ descriptionEditorState, setDescriptionEditorState ] = useState(EditorState.createEmpty());
  const isLoadingParentChildrenTemplateTasks = useSelector((state: RootState) => state.templates.loading.isLoadingParentChildrenTemplateTasks);

  const childTemplateTasks = useSelector((state: RootState) => {
    if (!show || !templateTask.isParent) {
      return [];
    }

    const { templateTaskId } = createdParentTemplateTask || templateTask;

    return selectTemplateTaskChildren(templateTaskId)(state);
  });

  useEffect(() => {
    if(!createdParentTemplateTask) {
      return;
    }

    addChildTemplateTask(createdParentTemplateTask.templateTaskId);
  }, [ createdParentTemplateTask ]);

  useOnModalShow(show).then(() => {
    setDescriptionEditorState(getEditorStateFromString(templateTask.description));

    if (mergedTemplateTask.templateTaskId && mergedTemplateTask.isParent) {
      dispatch(getParentChildrenTemplateTasks(mergedTemplateTask.templateTaskId));
    }
  });

  useOnModalHide(show).then(() => {
    // setCreatedParentTask(undefined);
    setModifyingTemplateTask({});
  });

  function setWeekIndex (e: React.ChangeEvent<HTMLSelectElement>) {
    setModifyingTemplateTask({
      ...modifyingTemplateTask,
      weekIndex: e.target.value ? parseInt(e.target.value, 10) : undefined,
    });
  }

  function saveTemplateTask (): Promise<TemplateTask> | void {
    if (!template) {
      return;
    }

    const validationMessages = validateTask(mergedTemplateTask);

    if(_.size(validationMessages) > 0) {
      const [ validationMessage ] = validationMessages;

      toastr.error(validationMessage);
      return;
    }

    const dto: CreateTemplateTask = {
      templateTaskId: mergedTemplateTask.templateTaskId || 0,
      auditTemplateId: template.auditTemplateId,
      summary: mergedTemplateTask.summary!,
      categoryId: mergedTemplateTask.categoryId,
      weekIndex: mergedTemplateTask.weekIndex,
      description: JSON.stringify(convertToRaw(descriptionEditorState.getCurrentContent())),
      actionTypeId: mergedTemplateTask.actionTypeId!,
      uploadToDirectoryPath: mergedTemplateTask.uploadToDirectoryPath,
      standardId: mergedTemplateTask.standardId,
      standardReferenceId: mergedTemplateTask.standardReferenceId,
      isParent: !!mergedTemplateTask.isParent!,
      parentTemplateTaskId: mergedTemplateTask.parentTemplateTaskId,
    };

    if (mergedTemplateTask.templateTaskId > 0) {
      return new Promise((resolve) => {
        dispatch(updateTemplateTask(dto, resolve));
      });
    } else {
      return new Promise((resolve) => {
        dispatch(insertTemplateTask(dto, resolve));
      });
    }
  }

  function saveAndClose () {
    const savedParent = saveTemplateTask();

    if(!savedParent) {
      return;
    }

    savedParent.then(() => {
      props.hide();
    });
  }

  function createParentAndAddChild () {
    const savedParent = saveTemplateTask();

    if(!savedParent) {
      return;
    }

    savedParent.then((parent) => {
      setCreatedParentTemplateTask(parent);
    });
  }

  function persistPositions () {
    const updatedChildTasks = tempChildTemplateTasks.current || [];

    const dtos: UpdateTemplateTaskPosition[] = [];

    _.each(updatedChildTasks, ({ templateTaskId, position }, index) => {
      if (position !== index) {
        dtos.push({ templateTaskId, position: index });
      }
    });

    if (_.isEmpty(dtos)) {
      return;
    }

    dispatch(updateTemplateTaskPositions(dtos));
  }

  function addChildTemplateTask(templateTaskId: number) {
    setModifyingChildTemplateTask({ templateTaskId: 0, parentTemplateTaskId: templateTaskId, isParent: false });
  }

  const isExistingTask = (templateTask?.templateTaskId || 0) > 0;

  const weekOptions = _.map([ ...new Array(template.numWeeks) ], (d, weekIndex) => {
    return {
      value: weekIndex + 1,
      label: `Week ${weekIndex + 1}`,
    };
  });

  return (
    <Modal isOpen={show} size="xl" toggle={props.hide}>
      <form onSubmit={stopPropagationPreventDefaultAnd(saveAndClose)}>
        <ModalHeader>{isExistingTask ? 'Update Template Task' : 'Create Template Task'}</ModalHeader>
        <ModalBody>
          <TaskMaintenanceForm
            descriptionEditorState={descriptionEditorState}
            setDescriptionEditorState={setDescriptionEditorState}
            task={templateTask}
            values={modifyingTemplateTask}
            setValues={(values) => setModifyingTemplateTask({ ...modifyingTemplateTask, ...values })}
            PostSummary={(
              <>
                {!mergedTemplateTask.parentTemplateTaskId && (
                  <FormGroup>
                    <label>Week:</label>
                    <select className="form-control" value={mergedTemplateTask.weekIndex || ''} onChange={setWeekIndex}>
                      <option value="">None (Persistent)</option>
                      {
                        _.map(weekOptions, ({ value, label }) => {
                          return (
                            <option key={value} value={value}>{label}</option>
                          );
                        })
                      }
                    </select>
                  </FormGroup>
                )}

                {isLoadingParentChildrenTemplateTasks && (
                  <h4>Loading Children...</h4>
                )}

                {mergedTemplateTask.isParent && !isLoadingParentChildrenTemplateTasks && (
                  <>
                    {mergedTemplateTask.templateTaskId > 0 && (
                      <>
                        <Button className="mb-1" color="primary" onClick={() => addChildTemplateTask(mergedTemplateTask.templateTaskId)}>Add Child Task</Button>

                        <ReactSortable<any>
                          className="child-spacing-y-1"
                          list={childTemplateTasks}
                          setList={(updatedChildTasks) => tempChildTemplateTasks.current = updatedChildTasks}
                          onEnd={persistPositions}
                        >
                          {
                            _.map(childTemplateTasks, (childTemplateTask) => {
                              return (
                                <CardHeader key={childTemplateTask.templateTaskId}>
                                  <FlexRow justifyBetween>
                                    <span>
                                      <i className="mdi mdi-drag" /> {childTemplateTask.summary}
                                    </span>

                                    <span>
                                      <Button color="anchor" onClick={() => setModifyingChildTemplateTask(childTemplateTask)}>
                                        <i className="mdi mdi-pencil" />
                                      </Button>
                                    </span>
                                  </FlexRow>
                                </CardHeader>
                              );
                            })
                          }
                        </ReactSortable>

                        {modifyingChildTemplateTask && (
                          <ModifyTemplateTaskModal
                            show
                            template={template}
                            templateTask={modifyingChildTemplateTask}
                            hide={() => setModifyingChildTemplateTask(undefined)}
                          />
                        )}
                      </>
                    )}
                    {mergedTemplateTask.templateTaskId <= 0 && (
                      <>
                        <Button color="primary" onClick={createParentAndAddChild}>Create and Add Child...</Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <FlexRow fill justifyBetween alignCenter>
            <div>
              <Button color="light" onClick={props.hide}>Cancel</Button>
            </div>

            <div>
              {!isSavingTemplateTask && <button className="btn btn-primary" type="submit">Save</button>}
              {isSavingTemplateTask && (
                <button className="btn btn-primary" type="button"><i className="fa fa-spin fa-refresh" /> Saving...</button>
              )}
            </div>
          </FlexRow>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModifyTemplateTaskModal;
