import React, { useState } from 'react';
import ReactDateTimePicker, { DatetimepickerProps } from 'react-datetime';
import moment, { Moment } from 'moment';

interface Props extends Omit<DatetimepickerProps, 'value'| 'onChange'> {
  value: Date | undefined;
  onChange: (date: Date | undefined) => any;
}

const DatePicker = ({ value, ...props }: Props) => {
  const [ localValue, setLocalValue ] = useState<string | undefined | Moment>(value ? moment(value) : undefined);

  function setDate(date: Moment | string) {
    let shouldUpdate = true;
    if (!date) {
      shouldUpdate = props.onChange(undefined) !== false;
    }

    if (moment.isMoment(date)) {
      shouldUpdate = props.onChange(date.toDate()) !== false;
    }

    if (shouldUpdate) {
      setLocalValue(date);
    } else {
      setLocalValue(value ? moment(value) : undefined);
    }
  }

  function onBlur() {
    if (!moment.isMoment(localValue)) {
      setLocalValue(value ? moment(value) : undefined);
    }
  }

  return (
    <ReactDateTimePicker {...props} onBlur={onBlur} value={localValue} timeFormat={false} onChange={setDate}/>
  );
};

export default DatePicker;
