import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { DashboardState } from './reducer';
import { dashboardActions } from './actions';

const loadingConfigs: LoadingActionConfig<DashboardState>[] = [
  {
    start: dashboardActions.GET_OPERATOR_DASHBOARD_REQUEST,
    stop: [ dashboardActions.GET_OPERATOR_DASHBOARD_SUCCESS, dashboardActions.GET_OPERATOR_DASHBOARD_FAILURE ],
    updater: (state: DashboardState, isLoading): DashboardState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingOperatorDashboard: isLoading,
        },
      };
    },
  },
  {
    start: dashboardActions.GET_SAFETY_COACH_DASHBOARD_REQUEST,
    stop: [ dashboardActions.GET_SAFETY_COACH_DASHBOARD_SUCCESS, dashboardActions.GET_SAFETY_COACH_DASHBOARD_FAILURE ],
    updater: (state: DashboardState, isLoading): DashboardState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingSafetyCoachDashboard: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
