import React from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { RootState } from 'Modules/reducers';
import { Button, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Program, ProgramFolder } from '../../Modules/programs/models';
import ProgramTemplatesTable from './ProgramTemplatesTable';
import { selectProgramFoldersForProgram, selectProgramSelectedTemplateHash } from '../../Modules/programs/selectors';
import { selectAllTemplates } from '../../Modules/templates/selectors';
import { AuditTemplate } from '../../models';
import { deleteProgramTemplateXref, insertProgramTemplateXref, updateProgram } from '../../Modules/programs/actions';
import FlexRow from '../../Components/FlexRow';
import { LoadableButton } from 'Components';
import { preventDefaultAnd } from '../../helpers/eventHelpers';
import FlexLoaderPanel from '../../Components/FlexLoaderPanel';
import classnames from 'classnames';
import ProgramFoldersTable from './ProgramFoldersTable';
import ModifyProgramFolderModal from './ModifyProgramFolderModal';
import { getNewProgramFolder } from '../../Modules/programs/instantiations';

const Tabs = {
  Templates: 'templates',
  Folders: 'folders',
};

interface LocalProps {
  program: Program;
}

interface LocalState {
  workingProgram: Partial<Program>;
  activeTab: string;
  modifyingProgramFolder?: ProgramFolder;
}

interface StateProps {
  templates: AuditTemplate[];
  programFolders: ProgramFolder[];
  selectedTemplates: {[auditTemplateId: number]: any};
  isUpdatingProgram: boolean;
  isSavingProgramTemplateXref: boolean;
}

interface DispatchProps {
  updateProgram: typeof updateProgram;
  insertProgramTemplateXref: typeof insertProgramTemplateXref;
  deleteProgramTemplateXref: typeof deleteProgramTemplateXref;
}

type Props = LocalProps & StateProps & DispatchProps;

class ModifyProgramForm extends React.PureComponent<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      workingProgram: {},
      activeTab: Tabs.Templates,
      modifyingProgramFolder: undefined,
    };
  }

  public componentDidUpdate (prevProps: Readonly<Props>) {
    const { program } = this.props;

    if (prevProps.program !== program) {
      this.setState({ workingProgram: {} });
    }
  }

  private setTab = (tab: string) => {
    this.setState({ activeTab: tab });
  };

  private setName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setWorkingProgramValues({
      name: e.target.value,
    });
  };

  private setWorkingProgramValues = (values: Partial<Program>) => {
    const { workingProgram } = this.state;
    this.setState({
      workingProgram: {
        ...workingProgram,
        ...values,
      },
    });
  };

  private startModifyingProgramFolder = (programFolder: ProgramFolder) => {
    this.setState({
      modifyingProgramFolder: programFolder,
    });
  };

  private stopModifyingProgramFolder = () => {
    this.setState({
      modifyingProgramFolder: undefined,
    });
  };

  private getMergedProgram = () => {
    const { workingProgram } = this.state;
    const { program } = this.props;

    return {
      ...program,
      ...workingProgram,
    };
  }

  private selectTemplate = (template: AuditTemplate, isSelected: boolean) => {
    const { program } = this.props;
    if (isSelected) {
      this.props.insertProgramTemplateXref(program.programId, template.auditTemplateId, () => {
        toastr.success('Successfully associated template to program. All tasks associated to this template have been added to non-complete audits for operators on this program.');
      });
    } else {
      this.props.deleteProgramTemplateXref(program.programId, template.auditTemplateId, () => {
        toastr.success('Successfully disassociated template from program. All tasks associated to this template have been removed from non-complete audits for operators on this program.');
      });
    }
  };

  private save = () => {
    const program = this.getMergedProgram();
    this.props.updateProgram(program);
  };

  public render () {
    const { activeTab, modifyingProgramFolder } = this.state;
    const { templates, selectedTemplates, isUpdatingProgram, isSavingProgramTemplateXref, programFolders } = this.props;
    const program = this.getMergedProgram();

    return (
      <form onSubmit={preventDefaultAnd(this.save)}>
        <Row>
          <Col md={5}>
            <h5>Program</h5>

            <FormGroup>
              <Label>Name</Label>
              <Input value={program.name} onChange={this.setName} />
            </FormGroup>
          </Col>
          <Col md={7}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === Tabs.Templates })}
                  onClick={() => {
                    this.setTab(Tabs.Templates);
                  }}
                >
                  Templates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === Tabs.Folders })}
                  onClick={() => {
                    this.setTab(Tabs.Folders);
                  }}
                >
                  Default Folders
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="p-0">
              <TabPane tabId={Tabs.Templates}>
                <h5>Associated Templates</h5>
                <FlexLoaderPanel isLoading={isSavingProgramTemplateXref} overlay>
                  <ProgramTemplatesTable
                    templates={templates}
                    selectedTemplates={selectedTemplates}
                    selectTemplate={this.selectTemplate}
                  />
                </FlexLoaderPanel>
              </TabPane>
              <TabPane tabId={Tabs.Folders}>
                <FlexRow alignCenter justifyBetween>
                  <h5>Default Folders</h5>

                  <Button color="primary" size="sm" onClick={() => this.startModifyingProgramFolder(getNewProgramFolder(program.programId))}>
                    Add New Folder
                  </Button>
                </FlexRow>

                <ProgramFoldersTable
                  folders={programFolders}
                  startEditingProgramFolder={this.startModifyingProgramFolder}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        <Row className="mt-2 pt-2 border-top">
          <Col md={12}>
            <FlexRow justifyEnd>
              <LoadableButton isLoading={isUpdatingProgram} LoadingLabel="Saving..." color="primary" type="submit">
                Save Program
              </LoadableButton>
            </FlexRow>
          </Col>
        </Row>

        <ModifyProgramFolderModal
          show={!!modifyingProgramFolder}
          programFolder={modifyingProgramFolder}
          hide={this.stopModifyingProgramFolder}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: LocalProps) => {
  return {
    templates: selectAllTemplates(state),
    selectedTemplates: selectProgramSelectedTemplateHash(state),
    // @ts-ignore
    programFolders: selectProgramFoldersForProgram(state, ownProps.program.programId),
    isUpdatingProgram: state.programs.loading.isUpdatingProgram,
    isSavingProgramTemplateXref: state.programs.loading.isInsertingProgramTemplateXref || state.programs.loading.isDeletingProgramTemplateXref,
  };
};

const mapDispatchToProps = {
  updateProgram,
  insertProgramTemplateXref,
  deleteProgramTemplateXref,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ModifyProgramForm);
