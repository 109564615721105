import _ from 'lodash';
import { commonActions, downloadFileFromResponse } from '../common/actions';
import appConfig from '../../config';
import { Action, ApiAction, LibraryFile } from '../../models';
import { DeleteLibraryFolderRequest, TreeNode, UpdateLibraryFilePosition } from './models';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';

export const libraryActions = {
  ADD_LIBRARY_FOLDER: 'AVSolutions/library/AddLibraryFolder',
  SELECT_FILE: 'AVSolutions/library/SelectFile',
  SELECT_ALL_FILES: 'AVSolutions/library/SelectAllFiles',
  SELECT_NONE_FILES: 'AVSolutions/library/SelectNoneFiles',
  TOGGLE_SHOWING_HIDDEN_FOLDERS: 'AVSolutions/library/ToggleShowingHiddenFolders',

  GET_OPERATOR_UPLOAD_FOLDERS_REQUEST: 'AVSolutions/library/GetOperatorUploadFoldersRequest',
  GET_OPERATOR_UPLOAD_FOLDERS_SUCCESS: 'AVSolutions/library/GetOperatorUploadFoldersSuccess',
  GET_OPERATOR_UPLOAD_FOLDERS_FAILURE: 'AVSolutions/library/GetOperatorUploadFoldersFailure',

  GET_OPERATOR_FOLDERS_REQUEST: 'AVSolutions/library/GetOperatorFoldersRequest',
  GET_OPERATOR_FOLDERS_SUCCESS: 'AVSolutions/library/GetOperatorFoldersSuccess',
  GET_OPERATOR_FOLDERS_FAILURE: 'AVSolutions/library/GetOperatorFoldersFailure',

  GET_OPERATOR_FILES_REQUEST: 'AVSolutions/library/GetOperatorFilesRequest',
  GET_OPERATOR_FILES_SUCCESS: 'AVSolutions/library/GetOperatorFilesSuccess',
  GET_OPERATOR_FILES_FAILURE: 'AVSolutions/library/GetOperatorFilesFailure',

  REPROCESS_OPERATOR_FILES_REQUEST: 'AVSolutions/library/ReprocessOperatorFilesRequest',
  REPROCESS_OPERATOR_FILES_SUCCESS: 'AVSolutions/library/ReprocessOperatorFilesSuccess',
  REPROCESS_OPERATOR_FILES_FAILURE: 'AVSolutions/library/ReprocessOperatorFilesFailure',

  GET_AVAILABLE_DIRECTORIES_REQUEST: 'AVSolutions/library/GetAvailableDirectoriesRequest',
  GET_AVAILABLE_DIRECTORIES_SUCCESS: 'AVSolutions/library/GetAvailableDirectoriesSuccess',
  GET_AVAILABLE_DIRECTORIES_FAILURE: 'AVSolutions/library/GetAvailableDirectoriesFailure',

  GET_LIBRARY_POSITIONS_REQUEST: 'AVSolutions/library/GetLibraryPositionsRequest',
  GET_LIBRARY_POSITIONS_SUCCESS: 'AVSolutions/library/GetLibraryPositionsSuccess',
  GET_LIBRARY_POSITIONS_FAILURE: 'AVSolutions/library/GetLibraryPositionsFailure',

  GET_OPERATOR_HIDDEN_FOLDERS_REQUEST: 'AVSolutions/library/GetOperatorHiddenFoldersRequest',
  GET_OPERATOR_HIDDEN_FOLDERS_SUCCESS: 'AVSolutions/library/GetOperatorHiddenFoldersSuccess',
  GET_OPERATOR_HIDDEN_FOLDERS_FAILURE: 'AVSolutions/library/GetOperatorHiddenFoldersFailure',

  UPLOAD_TASK_FILES_REQUEST: 'AVSolutions/library/UploadTaskFilesRequest',
  UPLOAD_TASK_FILES_SUCCESS: 'AVSolutions/library/UploadTaskFilesSuccess',
  UPLOAD_TASK_FILES_FAILURE: 'AVSolutions/library/UploadTaskFilesFailure',

  UPLOAD_OPERATOR_LIBRARY_FILES_REQUEST: 'AVSolutions/library/UploadOperatorLibraryFilesRequest',
  UPLOAD_OPERATOR_LIBRARY_FILES_SUCCESS: 'AVSolutions/library/UploadOperatorLibraryFilesSuccess',
  UPLOAD_OPERATOR_LIBRARY_FILES_FAILURE: 'AVSolutions/library/UploadOperatorLibraryFilesFailure',

  DOWNLOAD_TASK_FILES_REQUEST: 'AVSolutions/library/DownloadTaskFilesRequest',
  DOWNLOAD_TASK_FILES_SUCCESS: 'AVSolutions/library/DownloadTaskFilesSuccess',
  DOWNLOAD_TASK_FILES_FAILURE: 'AVSolutions/library/DownloadTaskFilesFailure',

  DOWNLOAD_OPERATOR_FILES_REQUEST: 'AVSolutions/library/DownloadOperatorFilesRequest',
  DOWNLOAD_OPERATOR_FILES_SUCCESS: 'AVSolutions/library/DownloadOperatorFilesSuccess',
  DOWNLOAD_OPERATOR_FILES_FAILURE: 'AVSolutions/library/DownloadOperatorFilesFailure',

  RENAME_LIBRARY_FOLDER_REQUEST: 'AVSolutions/library/RenameLibraryFolderRequest',
  RENAME_LIBRARY_FOLDER_SUCCESS: 'AVSolutions/library/RenameLibraryFolderSuccess',
  RENAME_LIBRARY_FOLDER_FAILURE: 'AVSolutions/library/RenameLibraryFolderFailure',

  RENAME_LIBRARY_FILE_REQUEST: 'AVSolutions/library/RenameLibraryFileRequest',
  RENAME_LIBRARY_FILE_SUCCESS: 'AVSolutions/library/RenameLibraryFileSuccess',
  RENAME_LIBRARY_FILE_FAILURE: 'AVSolutions/library/RenameLibraryFileFailure',

  MOVE_LIBRARY_FILES_REQUEST: 'AVSolutions/library/MoveLibraryFilesRequest',
  MOVE_LIBRARY_FILES_SUCCESS: 'AVSolutions/library/MoveLibraryFilesSuccess',
  MOVE_LIBRARY_FILES_FAILURE: 'AVSolutions/library/MoveLibraryFilesFailure',

  POST_LIBRARY_FILES_TO_SHARE_BOARD_REQUEST: 'AVSolutions/library/PostLibraryFilesToShareBoardRequest',
  POST_LIBRARY_FILES_TO_SHARE_BOARD_SUCCESS: 'AVSolutions/library/PostLibraryFilesToShareBoardSuccess',
  POST_LIBRARY_FILES_TO_SHARE_BOARD_FAILURE: 'AVSolutions/library/PostLibraryFilesToShareBoardFailure',

  UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_REQUEST: 'AVSolutions/library/UnPostLibraryFilesFromShareBoardRequest',
  UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_SUCCESS: 'AVSolutions/library/UnPostLibraryFilesFromShareBoardSuccess',
  UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_FAILURE: 'AVSolutions/library/UnPostLibraryFilesFromShareBoardFailure',

  INSERT_OPERATOR_FOLDER_REQUEST: 'AVSolutions/library/InsertOperatorFolderRequest',
  INSERT_OPERATOR_FOLDER_SUCCESS: 'AVSolutions/library/InsertOperatorFolderSuccess',
  INSERT_OPERATOR_FOLDER_FAILURE: 'AVSolutions/library/InsertOperatorFolderFailure',

  DELETE_FILES_REQUEST: 'AVSolutions/library/DeleteFilesRequest',
  DELETE_FILES_SUCCESS: 'AVSolutions/library/DeleteFilesSuccess',
  DELETE_FILES_FAILURE: 'AVSolutions/library/DeleteFilesFailure',

  UPDATE_LIBRARY_FILE_POSITIONS_REQUEST: 'AVSolutions/library/UpdateLibraryFilePositionsRequest',
  UPDATE_LIBRARY_FILE_POSITIONS_SUCCESS: 'AVSolutions/library/UpdateLibraryFilePositionsSuccess',
  UPDATE_LIBRARY_FILE_POSITIONS_FAILURE: 'AVSolutions/library/UpdateLibraryFilePositionsFailure',

  DELETE_TASK_AND_RELOAD_REQUEST: 'AVSolutions/library/DeleteTaskAndReloadRequest',
  DELETE_TASK_AND_RELOAD_SUCCESS: 'AVSolutions/library/DeleteTaskAndReloadSuccess',
  DELETE_TASK_AND_RELOAD_FAILURE: 'AVSolutions/library/DeleteTaskAndReloadFailure',

  DELETE_LIBRARY_FOLDER_REQUEST: 'AVSolutions/library/DeleteLibraryFolderRequest',
  DELETE_LIBRARY_FOLDER_SUCCESS: 'AVSolutions/library/DeleteLibraryFolderSuccess',
  DELETE_LIBRARY_FOLDER_FAILURE: 'AVSolutions/library/DeleteLibraryFolderFailure',
};

export const addOperatorLibraryFolder = (operatorId: number, folderPath: string): Action => {
  return {
    type: libraryActions.ADD_LIBRARY_FOLDER,
    payload: {
      folderPath,
      operatorId,
    },
  };
};

export const selectFile = (operatorId: number, libraryFileId: number): Action => {
  return {
    type: libraryActions.SELECT_FILE,
    payload: {
      operatorId,
      libraryFileId,
    },
  };
};

export const selectAllFiles = (operatorId: number, libraryFileIds: number[]): Action => {
  return {
    type: libraryActions.SELECT_ALL_FILES,
    payload: {
      operatorId,
      libraryFileIds,
    },
  };
};

export const selectNoneFiles = (operatorId: number): Action => {
  return {
    type: libraryActions.SELECT_NONE_FILES,
    payload: {
      operatorId,
    },
  };
};

export const toggleShowingHiddenFolders = (): Action => {
  return {
    type: libraryActions.TOGGLE_SHOWING_HIDDEN_FOLDERS,
  };
};

export const getOperatorFolders = (operatorId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/folders`);
      },
      beforeRequest: () => ({ type: libraryActions.GET_OPERATOR_FOLDERS_REQUEST }),
      success: (data) => ({ type: libraryActions.GET_OPERATOR_FOLDERS_SUCCESS, payload: { operatorId, folders: data } }),
      failure: (response) => ({ type: libraryActions.GET_OPERATOR_FOLDERS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getOperatorFiles = (operatorId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/files`);
      },
      beforeRequest: () => ({ type: libraryActions.GET_OPERATOR_FILES_REQUEST }),
      success: (data) => ({ type: libraryActions.GET_OPERATOR_FILES_SUCCESS, payload: { operatorId, files: data } }),
      failure: (response) => ({ type: libraryActions.GET_OPERATOR_FILES_FAILURE, payload: { ...response } }),
    },
  };
};

export const reprocessOperatorFiles = (operatorId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/files/reprocess`);
      },
      beforeRequest: () => ({ type: libraryActions.REPROCESS_OPERATOR_FILES_REQUEST }),
      success: () => ({ type: libraryActions.REPROCESS_OPERATOR_FILES_SUCCESS, payload: { } }),
      failure: (response) => ({ type: libraryActions.REPROCESS_OPERATOR_FILES_FAILURE, payload: { ...response } }),
    },
  };
};

export const getAvailableDirectories = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/library/directories`);
      },
      beforeRequest: () => ({ type: libraryActions.GET_AVAILABLE_DIRECTORIES_REQUEST }),
      success: (data) => ({ type: libraryActions.GET_AVAILABLE_DIRECTORIES_SUCCESS, payload: { directories: data } }),
      failure: (response) => ({ type: libraryActions.GET_AVAILABLE_DIRECTORIES_FAILURE, payload: { ...response } }),
    },
  };
};

export const getLibraryPositions = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/library/positions`);
      },
      beforeRequest: () => ({ type: libraryActions.GET_LIBRARY_POSITIONS_REQUEST }),
      success: (data) => ({ type: libraryActions.GET_LIBRARY_POSITIONS_SUCCESS, payload: { libraryPositions: data } }),
      failure: (response) => ({ type: libraryActions.GET_LIBRARY_POSITIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getOperatorHiddenFolders = (operatorId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/hiddenFolders`);
      },
      beforeRequest: () => ({ type: libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_REQUEST }),
      success: (data) => ({ type: libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_SUCCESS, payload: { operatorId, hiddenFolders: data } }),
      failure: (response) => ({ type: libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_FAILURE, payload: { ...response } }),
    },
  };
};

export const uploadTaskFiles = (taskId: number, files: FileList | File[], successCallback?: () => void): ApiAction => {
  const formData = new window.FormData();

  _.each(files || [], (file) => {
    formData.append('files', file);
  });

  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/files`)
          .send(formData);
      },
      beforeRequest: () => ({ type: libraryActions.UPLOAD_TASK_FILES_REQUEST, payload: { taskId } }),
      success: ({ libraryFiles, task }) => ({ type: libraryActions.UPLOAD_TASK_FILES_SUCCESS, payload: { taskId, libraryFiles, task } }),
      successCallback: successCallback,
      failure: (response) => ({ type: libraryActions.UPLOAD_TASK_FILES_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

export const uploadOperatorLibraryFiles = (operatorId: number, directoryPath: string, files: FileList | File[], successCallback?: () => void): ApiAction => {
  const formData = new window.FormData();

  _.each(files || [], (file) => {
    formData.append('files', file);
  });

  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/files`)
          .query({ directoryPath })
          .send(formData);
      },
      beforeRequest: () => ({ type: libraryActions.UPLOAD_OPERATOR_LIBRARY_FILES_REQUEST, payload: { operatorId, directoryPath } }),
      success: (data) => [
        { type: libraryActions.UPLOAD_OPERATOR_LIBRARY_FILES_SUCCESS, payload: { operatorId, directoryPath, batch: data } },
        getOperatorFiles(operatorId),
      ],
      successCallback: successCallback,
      failure: (response) => ({ type: libraryActions.UPLOAD_OPERATOR_LIBRARY_FILES_FAILURE, payload: { ...response, operatorId, directoryPath } }),
    },
  };
};

export const downloadLibraryFiles = (libraryFileIds: number[]): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/libraryFiles/download`)
          .send(libraryFileIds || [])
          .responseType('blob');
      },
      beforeRequest: () => ({ type: libraryActions.DOWNLOAD_TASK_FILES_REQUEST, payload: { taskIds: libraryFileIds } }),
      success: (data, response): Action[] => [
        { type: libraryActions.DOWNLOAD_TASK_FILES_SUCCESS, payload: { taskIds: libraryFileIds } },
        downloadFileFromResponse(data, response),
      ],
      failure: (response) => ({ type: libraryActions.DOWNLOAD_TASK_FILES_FAILURE, payload: { ...response, taskIds: libraryFileIds } }),
    },
  };
};

export const downloadOperatorFiles = (operatorId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/downloadFiles`)
          .responseType('blob');
      },
      beforeRequest: () => ({ type: libraryActions.DOWNLOAD_OPERATOR_FILES_REQUEST, payload: { operatorId } }),
      success: (data, response): Action[] => [
        { type: libraryActions.DOWNLOAD_OPERATOR_FILES_SUCCESS, payload: { operatorId } },
        downloadFileFromResponse(data, response),
      ],
      failure: (response) => ({ type: libraryActions.DOWNLOAD_OPERATOR_FILES_FAILURE, payload: { ...response, operatorId } }),
    },
  };
};

export const renameLibraryFolder = (previousDirectoryPath: string, updatedDirectoryPath: string, libraryFileIds?: number[], successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/libraryFolders/rename`)
          .send({ previousDirectoryPath, updatedDirectoryPath, libraryFileIds: libraryFileIds || [] });
      },
      beforeRequest: () => ({ type: libraryActions.RENAME_LIBRARY_FOLDER_REQUEST }),
      success: (data): Action => ({ type: libraryActions.RENAME_LIBRARY_FOLDER_SUCCESS, payload: { libraryFiles: data } }),
      successCallback,
      failure: (response) => ({ type: libraryActions.RENAME_LIBRARY_FOLDER_FAILURE, payload: { ...response } }),
    },
  };
};

export const renameLibraryFile = (libraryFileId: number, updatedFileName: string, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/libraryFiles/${libraryFileId}/rename`)
          .send({ libraryFileId, updatedFileName });
      },
      beforeRequest: () => ({ type: libraryActions.RENAME_LIBRARY_FILE_REQUEST }),
      success: (data): Action => ({ type: libraryActions.RENAME_LIBRARY_FILE_SUCCESS, payload: { libraryFile: data } }),
      successCallback,
      failure: (response) => ({ type: libraryActions.RENAME_LIBRARY_FILE_FAILURE, payload: { ...response } }),
    },
  };
};

export const moveLibraryFiles = (updatedDirectoryPath: string, libraryFileIds: number[], successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/libraryFiles/move`)
          .send({ updatedDirectoryPath, libraryFileIds });
      },
      beforeRequest: () => ({ type: libraryActions.MOVE_LIBRARY_FILES_REQUEST }),
      success: (data): Action => ({ type: libraryActions.MOVE_LIBRARY_FILES_SUCCESS, payload: { libraryFiles: data } }),
      successCallback,
      failure: (response) => ({ type: libraryActions.MOVE_LIBRARY_FILES_FAILURE, payload: { ...response } }),
    },
  };
};

export const postLibraryFilesToShareBoard = (libraryFileIds: number[]): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/libraryFiles/postToShareBoard`)
          .send(libraryFileIds);
      },
      beforeRequest: () => ({ type: libraryActions.POST_LIBRARY_FILES_TO_SHARE_BOARD_REQUEST }),
      success: (data): Action => ({ type: libraryActions.POST_LIBRARY_FILES_TO_SHARE_BOARD_SUCCESS, payload: { libraryFiles: data } }),
      failure: (response) => ({ type: libraryActions.POST_LIBRARY_FILES_TO_SHARE_BOARD_FAILURE, payload: { ...response } }),
    },
  };
};

export const unPostFromShareBoard = (libraryFileIds: number[]): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/libraryFiles/unPostFromShareBoard`)
          .send(libraryFileIds);
      },
      beforeRequest: () => ({ type: libraryActions.UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_REQUEST }),
      success: (data): Action => ({ type: libraryActions.UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_SUCCESS, payload: { libraryFiles: data } }),
      failure: (response) => ({ type: libraryActions.UN_POST_LIBRARY_FILES_FROM_SHARE_BOARD_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertOperatorFolder = (operatorId: number, directoryPath: string, successCallback?: () => void): ThunkAction<any, RootState, any, any> => (dispatch) => {
  const action: ApiAction = {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/operatorFolders`)
          .send({ operatorId, directoryPath });
      },
      beforeRequest: () => ({ type: libraryActions.INSERT_OPERATOR_FOLDER_REQUEST }),
      success: (): Action => {
        dispatch(addOperatorLibraryFolder(operatorId, directoryPath));
        successCallback?.();
        return {
          type: libraryActions.INSERT_OPERATOR_FOLDER_SUCCESS,
          payload: { operatorId, folderPath: directoryPath },
        };
      },
      failure: (response) => ({ type: libraryActions.INSERT_OPERATOR_FOLDER_FAILURE, payload: { ...response } }),
    },
  };

  dispatch(action);
};

export const deleteLibraryFiles = (libraryFiles: LibraryFile[], successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/libraryFiles`)
          .send(_.map(libraryFiles, f => f.libraryFileId));
      },
      beforeRequest: () => ({ type: libraryActions.DELETE_FILES_REQUEST, payload: { libraryFiles } }),
      success: (): Action => ({ type: libraryActions.DELETE_FILES_SUCCESS, payload: { libraryFiles } }),
      successCallback,
      failure: (response) => ({ type: libraryActions.DELETE_FILES_FAILURE, payload: { ...response, libraryFiles } }),
    },
  };
};

export const deleteLibraryFile = (libraryFile: LibraryFile, successCallback?: () => void): ApiAction => deleteLibraryFiles([ libraryFile ], successCallback);

export const updateLibraryFilesPositions = (libraryFiles: TreeNode<LibraryFile>[]): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
  const currentLibraryPositions = getState().library.ui.libraryPositions;

  const positions = _.compact(_.map(libraryFiles, (f, i): UpdateLibraryFilePosition | null => {
    const currentPosition = currentLibraryPositions[f.filePath];
    if (currentPosition === i) {
      return null;
    }

    return { filePath: f.filePath, position: i, originalPosition: currentPosition };
  }));

  if (_.isEmpty(positions)) {
    return;
  }

  const action: ApiAction = {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/libraryFiles/positions`)
          .send(positions);
      },
      beforeRequest: () => ({ type: libraryActions.UPDATE_LIBRARY_FILE_POSITIONS_REQUEST, payload: { positions } }),
      success: (): Action => ({ type: libraryActions.UPDATE_LIBRARY_FILE_POSITIONS_SUCCESS, payload: { positions } }),
      failure: (response) => ({ type: libraryActions.UPDATE_LIBRARY_FILE_POSITIONS_FAILURE, payload: { ...response, positions } }),
    },
  };

  dispatch(action);
};

export const deleteLibraryFolder = (request: DeleteLibraryFolderRequest, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/operators/${request.operatorId}/folders`)
          .send(request);
      },
      beforeRequest: () => ({ type: libraryActions.DELETE_LIBRARY_FOLDER_REQUEST, payload: { request } }),
      success: (data: any): Action => ({ type: libraryActions.DELETE_LIBRARY_FOLDER_SUCCESS, payload: { request, files: data } }),
      successCallback,
      failure: (response) => ({ type: libraryActions.DELETE_LIBRARY_FOLDER_FAILURE, payload: { ...response, request } }),
    },
  };
};
