import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useStateSelector } from 'hooks';
import { getTaskFiles } from 'Modules/tasks/actions';
import { getTaskFilesForTask } from 'Modules/tasks/selectors';

import FileGrid from './FileGrid';

interface Props {
  taskId: number;
}

const TaskFileGrid = ({ taskId }: Props) => {
  const dispatch = useDispatch();
  const files = useStateSelector(state => getTaskFilesForTask(state, taskId));
  const isLoadingTaskFiles = useStateSelector(state => state.tasks.loading.isLoadingTaskFiles);

  useEffect(() => {
    dispatch(getTaskFiles(taskId));
  }, []);

  if (_.isEmpty(files)) {
    return null;
  }

  return (
    <FileGrid files={files} isLoading={isLoadingTaskFiles}/>
  );
};

export default TaskFileGrid;
