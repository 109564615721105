import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '../reducers';
import { ChatMessage } from '../../Components/Chat/models';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';
import { getNextPageRequest } from '../../helpers/pagination';
import { PaginatedRequest } from '../../core';

export const getOperatorMessagePagination = (state: RootState) => state.messages.ui.operatorMessagePagination;
export const getOperatorMessages = (state: RootState) => state.messages.entities.operatorMessages;
export const getOperatorMessageIds = (state: RootState) => state.messages.ui.operatorMessageIds;

export const getSelectedRecipientChatMessages = createSelector(
  [
    getOperatorMessages,
    getOperatorMessageIds,
  ],
  (
    operatorMessages,
    operatorMessageIds
  ): ChatMessage[] => {
    const messages = getNumberMappedEntities(operatorMessages, operatorMessageIds);

    return _.uniqBy(_.reverse(_.map(messages, (message): ChatMessage => ({
      id: message.operatorMessageId,
      senderName: message.createdByUser?.displayName || 'Unknown User',
      text: message.messageText,
      createdTimestamp: message.createdTimestamp.toISOString(),
      fromMe: message.isFromMe,
    }))), m => m.id);
  }
);

export const getNextChatMessagePageRequest = createSelector(
  [
    getOperatorMessagePagination,
  ],
  (
    operatorMessagePagination
  ): PaginatedRequest | undefined => {
    return getNextPageRequest(operatorMessagePagination);
  }
);
