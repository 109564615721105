import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import { RootState } from 'Modules/reducers';

import shareBoardCategoryTagsActions from './actions';
import { CreateShareBoardCategoryTag, ShareBoardCategoryTag, UpdateShareBoardCategoryTag } from './models';
import { shareBoardCategoryTagsSlice, ShareBoardCategoryTagsState } from './slice';

const shareBoardCategoryTagsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: ShareBoardCategoryTagsState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(shareBoardCategoryTagsSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getShareBoardCategoryTag = (shareBoardCategoryId: number, shareBoardCategoryTagId: number): ThunkAction<Promise<ShareBoardCategoryTag>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: shareBoardCategoryTagsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingShareBoardCategoryTag = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/shareBoardCategories/${shareBoardCategoryId}/tags/${shareBoardCategoryTagId}`),
    onSuccess: (data: ShareBoardCategoryTag) => {
      dispatch(shareBoardCategoryTagsActions.setShareBoardCategoryTag(data));
    },
  });
};

export const getShareBoardCategoryTags = (shareBoardCategoryId: number): ThunkAction<Promise<ShareBoardCategoryTag[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: shareBoardCategoryTagsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllShareBoardCategoryTags = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/shareBoardCategories/${shareBoardCategoryId}/tags`),
    onSuccess: (data: ShareBoardCategoryTag[]) => {
      dispatch(shareBoardCategoryTagsActions.setShareBoardCategoryTags({ shareBoardCategoryId, tags: data }));
    },
  });
};

export const updateShareBoardCategoryTag = (shareBoardCategoryId: number, shareBoardCategoryTagId: number, updateShareBoardCategoryTag: UpdateShareBoardCategoryTag): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: shareBoardCategoryTagsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardCategoryTag = isLoading),
    getRequest: (superagent) => superagent.put(`/api/v1/shareBoardCategories/${shareBoardCategoryId}/tags/${shareBoardCategoryTagId}`).send(updateShareBoardCategoryTag),
    onSuccess: (data: ShareBoardCategoryTag) => {
      dispatch(shareBoardCategoryTagsActions.shareBoardCategoryTagUpdated(data));
    },
  });
};

export const insertShareBoardCategoryTag = (shareBoardCategoryId: number, createShareBoardCategoryTag: CreateShareBoardCategoryTag): ThunkAction<Promise<ShareBoardCategoryTag>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: shareBoardCategoryTagsLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingShareBoardCategoryTag = isLoading),
    getRequest: (superagent) => superagent.post(`/api/v1/shareBoardCategories/${shareBoardCategoryId}/tags`).query(createShareBoardCategoryTag),
    onSuccess: (data: ShareBoardCategoryTag) => {
      dispatch(shareBoardCategoryTagsActions.shareBoardCategoryTagInserted(data));
    },
  });
};

export const deleteShareBoardCategoryTag = (shareBoardCategoryId: number, shareBoardCategoryTagId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: shareBoardCategoryTagsLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingShareBoardCategoryTag = isLoading),
    getRequest: (superagent) => superagent.del(`/api/v1/shareBoardCategories/${shareBoardCategoryId}/tags/${shareBoardCategoryTagId}`),
    onSuccess: () => {
      dispatch(shareBoardCategoryTagsActions.shareBoardCategoryTagDeleted(shareBoardCategoryTagId));
    },
  });
};
