import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';

export interface FeedbackState {
  entities: {};
  xrefs: {};
  ui: {};
  loading: {
    isInsertingFeedback: boolean;
  };
}

const initialFeedbackState: FeedbackState = {
  entities: {},
  xrefs: {},
  ui: {},
  loading: {
    isInsertingFeedback: false,
  },
};

export const feedbackSlice = createSlice({
  name: 'Feedback',
  initialState: initialFeedbackState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<FeedbackState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
  },
});
