import React, { useEffect, useState } from 'react';
import { callApi } from 'api/ApiDao';
import { useSelector } from 'react-redux';
import { getAccessToken } from 'Modules/site/selectors';

interface Props {
  referenceFileId: number;
}

const styles = {
  width: '100%',
  maxWidth: 400,
};

const Video = ({ referenceFileId }: Props) => {
  const [ streamUrl, setStreamUrl ] = useState<string | undefined>();
  const [ isLoading, setIsLoading ] = useState(false);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    setIsLoading(true);
    callApi<string>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent.get(`/api/v1/referenceMaterials/${referenceFileId}/streamUrl`),
      onSuccess: (streamUrl) => {
        setStreamUrl(streamUrl);
      },
      onComplete: () => {
        setIsLoading(false);
      },
    });
  }, [ accessToken, referenceFileId ]);

  if (isLoading) {
    return (
      <i className="mdi mdi-spin mdi-refresh" />
    );
  }

  return (
    <div>
      <video controls src={streamUrl} style={styles} />
    </div>
  );
};

export default Video;
