import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { getNewOperatorGroup } from './instantiations';

const operatorGroups = (state: RootState) => state.operatorGroups.entities.operatorGroups;
const operatorGroupOperators = (state: RootState) => state.operatorGroups.entities.operatorGroupOperatorXrefs;

const operatorGroupOperatorXrefs = (state: RootState) => state.operatorGroups.xrefs.operatorGroupOperatorXrefs;

const allOperatorGroupIds = (state: RootState) => state.operatorGroups.ui.maintenance.allOperatorGroupIds;
const selectedOperatorGroupId = (state: RootState) => state.operatorGroups.ui.maintenance.selectedOperatorGroupId;
const isLoadingAllOperatorGroups = (state: RootState) => state.operatorGroups.loading.isLoadingAllOperatorGroups;
const isLoadingOperatorGroup = (state: RootState) => state.operatorGroups.loading.isLoadingOperatorGroup;
const isLoadingCurrentUserOperatorGroups = (state: RootState) => state.operatorGroups.loading.isLoadingCurrentUserOperatorGroups;
const currentOperatorGroupIds = (state: RootState) => state.operatorGroups.ui.currentOperatorGroupIds;

const isUpdatingOperatorGroup = (state: RootState) => state.operatorGroups.loading.isUpdatingOperatorGroup;
const isInsertingOperatorGroup = (state: RootState) => state.operatorGroups.loading.isInsertingOperatorGroup;

const operatorGroupsSelectors = {
  isLoadingAllOperatorGroups,
  isLoadingCurrentUserOperatorGroups,
  isLoadingOperatorGroup,
  operatorGroups: {
    operatorOperatorGroups: createSelector([
      operatorGroups,
      currentOperatorGroupIds,
    ], (
      operatorGroups,
      operatorGroupIds
    ) => {
      return _.map(operatorGroupIds, (o) => operatorGroups[o]);
    }),
  },
  selectOperatorGroup: (operatorGroupId?: number) => createSelector([
    operatorGroups,
  ], (
    operatorGroups
  ) => {
    if (!operatorGroupId) {
      return undefined;
    }

    return operatorGroups[operatorGroupId];
  }),
  maintenance: {
    allOperatorGroups: createSelector([ operatorGroups, allOperatorGroupIds ], (operatorGroups, allOperatorGroupIds) => _.map(allOperatorGroupIds, (o) => operatorGroups[o])),
    isSavingOperatorGroup: createSelector([ isInsertingOperatorGroup, isUpdatingOperatorGroup ], (isInsertingOperatorGroup, isUpdatingOperatorGroup) => isInsertingOperatorGroup || isUpdatingOperatorGroup),
    selectedOperatorGroup: createSelector([ operatorGroups, selectedOperatorGroupId ], (operatorGroups, selectedOperatorGroupId) => {
      if (selectedOperatorGroupId === 0) {
        return getNewOperatorGroup();
      }

      return selectedOperatorGroupId ? operatorGroups[selectedOperatorGroupId] : undefined;
    }),
    selectedOperatorGroupOperators: createSelector([
      operatorGroupOperators,
      operatorGroupOperatorXrefs,
      selectedOperatorGroupId,
    ], (
      operatorGroupOperators,
      operatorGroupOperatorXrefs,
      selectedOperatorGroupId
    ) => {
      if (!selectedOperatorGroupId) {
        return [];
      }

      return _.map(operatorGroupOperatorXrefs[selectedOperatorGroupId], (o) => operatorGroupOperators[o]);
    }),
  },
};

export default operatorGroupsSelectors;
