import React, { useEffect } from 'react';
import _ from 'lodash';
import * as messageActions from '../Modules/messages/actions';
import config from '../config';
import ChatFeed from '../Components/Chat/ChatFeed';
import { useDispatch, useSelector } from 'react-redux';
import useStateSelector from '../hooks/useStateSelector';
import * as messagesSelectors from '../Modules/messages/selectors';
import { callApi } from 'api/index';
import { getAccessToken } from 'Modules/site/selectors';
import { OperatorMessageFile } from 'Modules/messages/models';

interface Props {
}

const SafetyCoachChatFeed = function ({ ...props }: Props) {
  const dispatch = useDispatch();
  const nextSafetyCoachChatMessagePageRequest = useStateSelector(messagesSelectors.getNextChatMessagePageRequest);
  const chatMessages = useStateSelector(messagesSelectors.getSelectedRecipientChatMessages);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    dispatch(messageActions.searchOperatorMessages(config.defaultPagination));
  }, []);

  function submitMessage(messageText: string) {
    if (!_.trim(messageText)) {
      return;
    }

    return new Promise<void>((resolve) => {
      dispatch(messageActions.insertMessage({
        messageText: messageText,
      }, () => {
        resolve();
      }));
    });
  }


  function loadNextPage() {
    if (!nextSafetyCoachChatMessagePageRequest) {
      return;
    }

    dispatch(messageActions.searchOperatorMessages(nextSafetyCoachChatMessagePageRequest));
  }

  function uploadOperatorMessageFile(file: File) {
    const formData = new window.FormData();
    formData.append('file', file);

    return callApi<OperatorMessageFile>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent
        .post('/api/v1/operators/current/messages/files')
        .send(formData),
    })
      .then((operatorMessageFile) => {
        return { id: operatorMessageFile.id };
      });
  }

  return (
    <ChatFeed
      direction="col"
      canLoadMore={!!nextSafetyCoachChatMessagePageRequest}
      messages={chatMessages}
      onLoadMore={loadNextPage}
      input={{
        className: 'p-1',
        fileType: 'operatorMessage',
        uploadFile: uploadOperatorMessageFile,
      }}
      onMessageSubmit={submitMessage}
    />
  );
};

export default SafetyCoachChatFeed;
