import superagent, { Response as SuperAgentResponse } from 'superagent';
import statusCodes from 'http-status-codes';
import toastr from 'toastr';
import { ApiCallParameters } from './ApiCallParameters';

const handleErrorFeedback = (response: SuperAgentResponse) => {
  const message: string = response?.body?.error?.message;

  if (response.status === statusCodes.NOT_FOUND) {
    toastr.error('Not Found');
  } else if (response.status === 400) {
    if (message) {
      toastr.warning(message);
    }
  } else {
    toastr.error('An unknown error has occurred. Please refresh the page or try the action again.');
  }
};

export const callApi = async <TData extends any>(
  apiCallParameters: ApiCallParameters<TData>
): Promise<TData> => {
  const {
    apiToken,
    getRequest,
    onRequest,
    onSuccess,
    onSuccessToast,
    onFailure,
    onComplete,
    loading,
    useDefaultErrorHandlingFeedback = true,
  } = apiCallParameters;

  loading?.(true);

  try {
    onRequest?.();
    const response = await getRequest(superagent).set({ Authorization: `Bearer ${apiToken}` });

    const { body } = response;

    onSuccess?.(body, response);
    onSuccessToast?.(toastr, body);
    onComplete?.();

    return body;
  } catch (err: any) {
    if (useDefaultErrorHandlingFeedback) {
      handleErrorFeedback(err.response as any);
    }

    onFailure?.(err.response);
    onComplete?.();

    throw new Error(err.response);
  } finally {
    loading?.(false);
  }
};
