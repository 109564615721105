import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { UpdateShareBoardCategory } from 'Modules/shareBoard/models';
import { shareBoardActions } from 'Modules/shareBoard';
import {
  useCurrentUserPermissionChecker,
  useMergedEntity,
  useRequiredPathId,
  useShareBoardCategoryForm,
  useStateSelector,
  useThunkDispatch,
} from 'hooks';
import {
  ClickableDiv,
  EditFormFooter,
  EditFormPanel,
  FlexLoader,
  FlexRow,
  Panel,
  PanelHeader,
  ShareBoardCategoryForm,
} from 'Components';
import { Permission } from 'Modules/roles';
import AutoUploadFileDropzone from '../../Components/AutoUploadFileDropzone';
import { ThumbnailFile } from '../../Modules/files/models';
import useShareBoardCategories from '../../hooks/useShareBoardCategories';
import { makeGetShareBoardCategoryTags } from '../../Modules/shareBoardCategoryTags/selectors';
import { shareBoardCategoryTagsActions } from '../../Modules/shareBoardCategoryTags/index';
import toast from 'toastr';

const ModifyShareBoardCategoryPanel = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const shareBoardCategoryId = useRequiredPathId('shareBoardCategoryId');
  const workingShareBoardCategory = useStateSelector((state) => state.shareBoard.ui.maintenance.workingUpdateCategory);
  const isUploadingShareBoardCategoryImage = useStateSelector((state) => state.shareBoard.loading.isUploadingShareBoardCategoryImage);
  const shareBoardCategoryTags = useStateSelector(makeGetShareBoardCategoryTags(shareBoardCategoryId));
  const canEdit = useCurrentUserPermissionChecker()(Permission.GlobalShareBoardMaintenance);
  const canDeactivate = useCurrentUserPermissionChecker()(Permission.GlobalShareBoardMaintenance);
  const { isLoadingAllShareBoardCategories, shareBoardCategories } = useShareBoardCategories({ activeOnly: false });
  const rootShareBoardCategory = shareBoardCategories.find((shareBoardCategory) => shareBoardCategory.id === shareBoardCategoryId);
  const shareBoardCategory = useMergedEntity(rootShareBoardCategory, workingShareBoardCategory);
  const [ thumbnail, setThumbnail ] = useState<ThumbnailFile>();

  const { updateShareBoardCategory, deleteShareBoardCategory, activateShareBoardCategory, cancelShareBoardCategoryModification, loading } = useShareBoardCategoryForm();

  useEffect(() => {
    setThumbnail(rootShareBoardCategory?.imageFile);
  }, [ rootShareBoardCategory ]);

  useEffect(() => {
    dispatch(shareBoardCategoryTagsActions.async.getShareBoardCategoryTags(shareBoardCategoryId));
  }, [ shareBoardCategoryId ]);

  function setWorkingShareBoardCategoryValues(shareBoardCategory: Partial<UpdateShareBoardCategory>) {
    dispatch(shareBoardActions.setWorkingUpdateShareBoardValues(shareBoardCategory));
  }

  function handleDelete() {
    if (!shareBoardCategory) {
      return;
    }

    deleteShareBoardCategory(shareBoardCategory)
      .then(() => {
        history.push('/admin/shareBoardCategories');
      });
  }

  function handleActivate() {
    if (!shareBoardCategory) {
      return;
    }

    activateShareBoardCategory(shareBoardCategory);
  }

  async function handleFileUpload(files: File[]): Promise<void> {
    if (!rootShareBoardCategory) {
      return;
    }

    if(files.length === 0) {
      return;
    }

    return await dispatch(shareBoardActions.async.uploadShareBoardCategoryImage(rootShareBoardCategory.id, files[0]));
  }

  async function handleDeleteShareBoardCateogryImage() {
    if (!rootShareBoardCategory?.imageFile) {
      return;
    }

    return await dispatch(shareBoardActions.async.deleteShareBoardCategoryImage(rootShareBoardCategory.id));
  }

  async function addShareBoardCategoryTag(): Promise<void> {
    const element = document.getElementById('createTagInput') as HTMLInputElement;

    if(!element) {
      return;
    }


    if (!element.value.trim()) {
      return;
    }

    await dispatch(shareBoardCategoryTagsActions.async.insertShareBoardCategoryTag(shareBoardCategoryId, {
      name: element.value,
    }));

    element.value = '';
    toast.success('Tag added successfully');
  }

  async function handleDeleteShareBoardCateogryTag(id: number) {
    await dispatch(shareBoardCategoryTagsActions.async.deleteShareBoardCategoryTag(shareBoardCategoryId, id));
    toast.success('Tag deleted successfully');
  }

  if(isLoadingAllShareBoardCategories) {
    return (
      <FlexLoader />
    );
  }

  if (!shareBoardCategory) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader white>
        Updating Share Board Category
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelShareBoardCategoryModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingShareBoardCategory,
              children: 'Delete',
              hidden: !canDeactivate || !!shareBoardCategory.deletedTimestamp,
            }}
            activateButton={{
              onClick: handleActivate,
              isLoading: loading.isActivatingShareBoardCategory,
              children: 'Activate',
              hidden: !canDeactivate || !shareBoardCategory.deletedTimestamp,
            }}
            saveButton={{
              onClick: () => updateShareBoardCategory(shareBoardCategoryId, shareBoardCategory),
              isLoading: loading.isUpdatingShareBoardCategory,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <ShareBoardCategoryForm
              shareBoardCategory={shareBoardCategory}
              setValues={setWorkingShareBoardCategoryValues}
            />

            <FormGroup>
              <Label>Tags</Label>

              <FlexRow childSpacingX={1}>
                <div className="flex-1">
                  <Input id="createTagInput" />
                </div>

                <Button
                  color="primary"
                  onClick={() => addShareBoardCategoryTag()}
                >
                  Add
                </Button>
              </FlexRow>

              {!!shareBoardCategoryTags.length && (
                <div className="d-flex flex-row mt-2" style={{ gap: 3 }}>
                  {shareBoardCategoryTags.map((tag) => (
                    <ClickableDiv
                      key={tag.id}
                      className="badge badge-dark p-1 child-space-x-1"
                      onClick={() => handleDeleteShareBoardCateogryTag(tag.id)}
                    >
                      <span>
                        {tag.name}
                      </span>

                      <i className="mdi mdi-trash-can"/>
                    </ClickableDiv>
                  ))}
                </div>
              )}
            </FormGroup>

            <Label>Thumbnail:</Label>
            {!thumbnail ? (
              <AutoUploadFileDropzone
                isUploading={isUploadingShareBoardCategoryImage}
                maxFiles={1}
                accept="image/*"
                uploadFiles={handleFileUpload}
              />
            ) : (
              <div>
                <div><img className="img-fluid" src={thumbnail.sasUrl} alt="Thumbnail"/></div>

                <div className="mt-2">
                  <Button
                    color="danger"
                    size="sm"
                    onClick={handleDeleteShareBoardCateogryImage}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyShareBoardCategoryPanel;
