import React, { useEffect } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';

import { useClientSideFilter, useStateSelector } from 'hooks';
import { operatorsActions, operatorsSelectors } from 'Modules/operators';
import * as usersActions from 'Modules/users/actions';
import {
  DomClass,
  FlexLoaderPanel,
  NavMenuButton,
  Panel,
  PanelHeader,
  SplitPanel,
  VerticalOnlyScrollPanel,
} from 'Components';

import CreateOperatorPanel from './CreateOperatorPanel';
import ModifyOperatorPanel from './ModifyOperatorPanel';


const OperatorsPage = () => {
  const dispatch = useDispatch();

  const operators = useStateSelector(operatorsSelectors.getAllOperators);
  const isLoading = useStateSelector(state =>
    state.operators.loading.isLoadingAllOperators
    || state.operators.loading.isLoadingOperatorUserXrefs
    || state.users.loading.isLoadingAllUsers
  );

  useEffect(() => {
    dispatch(operatorsActions.async.getAllOperators());
    dispatch(operatorsActions.async.getAllOperatorUserXrefs());
    dispatch(usersActions.getAllUsers());
  }, []);

  const [ filteredOperators, filterText, setFilterText ] = useClientSideFilter(operators, [ 'name' ]);

  return (
    <>
      <Helmet>
        <title>Operator Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 1,
            Component: (
              <Panel>
                <PanelHeader
                  white
                  RightContent={(
                    <Button tag={Link} to="/admin/operators/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                  BottomContent={(
                    <div>
                      <Input autoFocus value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                    </div>
                  )}
                >
                  Operators ({_.size(filteredOperators)})
                </PanelHeader>

                <Panel className="position-relative">
                  <FlexLoaderPanel isLoading={isLoading}>
                    <VerticalOnlyScrollPanel className="p-1">
                      {
                        _.map(filteredOperators, (operator) => {
                          return (
                            <NavMenuButton key={operator.operatorId} to={`/admin/operators/${operator.operatorId}`}>
                              {operator.name}
                            </NavMenuButton>
                          );
                        })
                      }
                    </VerticalOnlyScrollPanel>
                  </FlexLoaderPanel>
                </Panel>
              </Panel>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/operators/create" component={CreateOperatorPanel} />
                <Route exact path="/admin/operators/:operatorId([0-9]+)" component={ModifyOperatorPanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default OperatorsPage;
