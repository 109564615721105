import React from 'react';
import Helmet from 'react-helmet';

import OperatorAuditTasks from './OperatorAuditTasks';
import FlexLoaderPanel from '../../Components/FlexLoaderPanel';
import useStateSelector from '../../hooks/useStateSelector';

const TasksPage = () => {
  const isLoadingOperatorAuditTasks = useStateSelector(state => state.audits.loading.isLoadingOperatorAuditTasks);

  return (
    <>
      <Helmet>
        <title>Tasks</title>
      </Helmet>

      <FlexLoaderPanel loadingClassName="p-5" isLoading={isLoadingOperatorAuditTasks} overlay>
        <OperatorAuditTasks/>
      </FlexLoaderPanel>
    </>
  );
};

export default TasksPage;
