import _ from 'lodash';

import { ApiAction, AssignedTaskModel, OperatorAuditModel, OperatorModel, UpdateTaskPosition } from 'models';
import appConfig from 'config';

import { commonActions } from '../common/actions';
import { getOperatorAuditTasks } from '../audits/actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';

export const tasksActions = {
  CHANGE_MODIFYING_TASK_VALUES: 'AVSolutions/tasks/changeModifyingTaskValues',
  SET_SELECTED_OPERATOR: 'AVSolutions/tasks/setSelectedOperator',
  SET_SELECTED_AUDIT: 'AVSolutions/tasks/setSelectedAudit',
  SET_GLOBAL_SELECTED_TASK: 'AVSolutions/tasks/setGlobalSelectedTask',

  GET_TASK_REQUEST: 'AVSolutions/tasks/GetTaskRequest',
  GET_TASK_SUCCESS: 'AVSolutions/tasks/GetTaskSuccess',
  GET_TASK_FAILURE: 'AVSolutions/tasks/GetTaskFailure',

  GET_PARENT_CHILDREN_TASKS_REQUEST: 'AVSolutions/tasks/GetParentChildrenTasksRequest',
  GET_PARENT_CHILDREN_TASKS_SUCCESS: 'AVSolutions/tasks/GetParentChildrenTasksSuccess',
  GET_PARENT_CHILDREN_TASKS_FAILURE: 'AVSolutions/tasks/GetParentChildrenTasksFailure',

  GET_TASK_FILES_REQUEST: 'AVSolutions/tasks/GetTaskFilesRequest',
  GET_TASK_FILES_SUCCESS: 'AVSolutions/tasks/GetTaskFilesSuccess',
  GET_TASK_FILES_FAILURE: 'AVSolutions/tasks/GetTaskFilesFailure',

  GET_TASK_HISTORY_REQUEST: 'AVSolutions/tasks/GetTaskHistoryRequest',
  GET_TASK_HISTORY_SUCCESS: 'AVSolutions/tasks/GetTaskHistorySuccess',
  GET_TASK_HISTORY_FAILURE: 'AVSolutions/tasks/GetTaskHistoryFailure',

  GET_TASK_MESSAGES_REQUEST: 'AVSolutions/tasks/GetTaskMessagesRequest',
  GET_TASK_MESSAGES_SUCCESS: 'AVSolutions/tasks/GetTaskMessagesSuccess',
  GET_TASK_MESSAGES_FAILURE: 'AVSolutions/tasks/GetTaskMessagesFailure',

  GET_TASK_APPROVAL_DETAILS_REQUEST: 'AVSolutions/tasks/GetTaskApprovalDetailsRequest',
  GET_TASK_APPROVAL_DETAILS_SUCCESS: 'AVSolutions/tasks/GetTaskApprovalDetailsSuccess',
  GET_TASK_APPROVAL_DETAILS_FAILURE: 'AVSolutions/tasks/GetTaskApprovalDetailsFailure',

  SUBMIT_TASK_REQUEST: 'AVSolutions/tasks/SubmitTaskRequest',
  SUBMIT_TASK_SUCCESS: 'AVSolutions/tasks/SubmitTaskSuccess',
  SUBMIT_TASK_FAILURE: 'AVSolutions/tasks/SubmitTaskFailure',

  COMPLETE_TASK_REQUEST: 'AVSolutions/tasks/CompleteTaskRequest',
  COMPLETE_TASK_SUCCESS: 'AVSolutions/tasks/CompleteTaskSuccess',
  COMPLETE_TASK_FAILURE: 'AVSolutions/tasks/CompleteTaskFailure',

  REJECT_TASK_REQUEST: 'AVSolutions/tasks/RejectTaskRequest',
  REJECT_TASK_SUCCESS: 'AVSolutions/tasks/RejectTaskSuccess',
  REJECT_TASK_FAILURE: 'AVSolutions/tasks/RejectTaskFailure',

  RESET_TASK_REQUEST: 'AVSolutions/tasks/ResetTaskRequest',
  RESET_TASK_SUCCESS: 'AVSolutions/tasks/ResetTaskSuccess',
  RESET_TASK_FAILURE: 'AVSolutions/tasks/ResetTaskFailure',

  NOT_APPLICABLE_TASK_REQUEST: 'AVSolutions/tasks/NotApplicableTaskRequest',
  NOT_APPLICABLE_TASK_SUCCESS: 'AVSolutions/tasks/NotApplicableTaskSuccess',
  NOT_APPLICABLE_TASK_FAILURE: 'AVSolutions/tasks/NotApplicableTaskFailure',

  UPDATE_TASK_REQUEST: 'AVSolutions/tasks/UpdateTaskRequest',
  UPDATE_TASK_SUCCESS: 'AVSolutions/tasks/UpdateTaskSuccess',
  UPDATE_TASK_FAILURE: 'AVSolutions/tasks/UpdateTaskFailure',

  UPDATE_TASK_POSITIONS_REQUEST: 'AVSolutions/tasks/UpdateTaskPositionsRequest',
  UPDATE_TASK_POSITIONS_SUCCESS: 'AVSolutions/tasks/UpdateTaskPositionsSuccess',
  UPDATE_TASK_POSITIONS_FAILURE: 'AVSolutions/tasks/UpdateTaskPositionsFailure',

  SUBMIT_TASK_MESSAGE_REQUEST: 'AVSolutions/tasks/SubmitTaskMessageRequest',
  SUBMIT_TASK_MESSAGE_SUCCESS: 'AVSolutions/tasks/SubmitTaskMessageSuccess',
  SUBMIT_TASK_MESSAGE_FAILURE: 'AVSolutions/tasks/SubmitTaskMessageFailure',

  INSERT_TASK_REQUEST: 'AVSolutions/tasks/InsertTaskRequest',
  INSERT_TASK_SUCCESS: 'AVSolutions/tasks/InsertTaskSuccess',
  INSERT_TASK_FAILURE: 'AVSolutions/tasks/InsertTaskFailure',

  DELETE_TASK_REQUEST: 'AVSolutions/tasks/deleteTaskRequest',
  DELETE_TASK_SUCCESS: 'AVSolutions/tasks/deleteTaskSuccess',
  DELETE_TASK_FAILURE: 'AVSolutions/tasks/deleteTaskFailure',
};

export const changeModifyingTaskValues = (values: any | null) => {
  return {
    type: tasksActions.CHANGE_MODIFYING_TASK_VALUES,
    payload: {
      values: values,
    },
  };
};

export const setSelectedOperator = (selectedOperator: OperatorModel | null) => ({
  type: tasksActions.SET_SELECTED_OPERATOR,
  payload: {
    selectedOperator: selectedOperator,
  },
});

export const setSelectedAudit = (selectedAudit: OperatorAuditModel | null) => ({
  type: tasksActions.SET_SELECTED_AUDIT,
  payload: {
    selectedAudit: selectedAudit,
  },
});

export const setGlobalSelectedTask = (taskId?: number) => ({
  type: tasksActions.SET_GLOBAL_SELECTED_TASK,
  payload: {
    taskId: taskId,
  },
});

export const resetGlobalSelectedTask = () => setGlobalSelectedTask(undefined);

export const getTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/tasks/${taskId}`);
      },
      beforeRequest: () => ({ type: tasksActions.GET_TASK_REQUEST, payload: { taskId } }),
      success: (task) => ({ type: tasksActions.GET_TASK_SUCCESS, payload: { taskId, task } }),
      failure: (response) => ({ type: tasksActions.GET_TASK_FAILURE, payload: { taskId, ...response } }),
    },
  };
};

export const getParentChildrenTasks = (parentTaskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/tasks/${parentTaskId}/children`);
      },
      beforeRequest: () => ({ type: tasksActions.GET_PARENT_CHILDREN_TASKS_REQUEST, payload: { parentTaskId } }),
      success: (tasks) => ({ type: tasksActions.GET_PARENT_CHILDREN_TASKS_SUCCESS, payload: { parentTaskId, tasks } }),
      failure: (response) => ({ type: tasksActions.GET_PARENT_CHILDREN_TASKS_FAILURE, payload: { parentTaskId, ...response } }),
    },
  };
};

export const getTaskMessages = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/messages`);
      },
      beforeRequest: () => ({ type: tasksActions.GET_TASK_MESSAGES_REQUEST, payload: { taskId } }),
      success: (taskMessages) => ({ type: tasksActions.GET_TASK_MESSAGES_SUCCESS, payload: { taskId, taskMessages } }),
      failure: (response) => ({ type: tasksActions.GET_TASK_MESSAGES_FAILURE, payload: { taskId, ...response } }),
    },
  };
};

export const getTaskFiles = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/files`);
      },
      beforeRequest: () => ({ type: tasksActions.GET_TASK_FILES_REQUEST, payload: { taskId } }),
      success: (files) => ({ type: tasksActions.GET_TASK_FILES_SUCCESS, payload: { taskId, files } }),
      failure: (response) => ({ type: tasksActions.GET_TASK_FILES_FAILURE, payload: { taskId, ...response } }),
    },
  };
};

export const getTaskHistory = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/history`);
      },
      beforeRequest: () => ({ type: tasksActions.GET_TASK_HISTORY_REQUEST, payload: { taskId } }),
      success: (taskHistory) => ({ type: tasksActions.GET_TASK_HISTORY_SUCCESS, payload: { taskId, taskHistory } }),
      failure: (response) => ({ type: tasksActions.GET_TASK_HISTORY_FAILURE, payload: { taskId, ...response } }),
    },
  };
};

export const submitTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/submit`);
      },
      beforeRequest: () => ({ type: tasksActions.SUBMIT_TASK_REQUEST, payload: { taskId } }),
      success: (data) => ([ { type: tasksActions.SUBMIT_TASK_SUCCESS, payload: { taskId, task: data } }, handleTaskStatusUpdate(taskId) ]),
      failure: (response) => ({ type: tasksActions.SUBMIT_TASK_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

export const completeTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/complete`);
      },
      beforeRequest: () => ({ type: tasksActions.COMPLETE_TASK_REQUEST, payload: { taskId } }),
      success: (data) => ([ { type: tasksActions.COMPLETE_TASK_SUCCESS, payload: { taskId, task: data } }, handleTaskStatusUpdate(taskId) ]),
      failure: (response) => ({ type: tasksActions.COMPLETE_TASK_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

export const rejectTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/reject`);
      },
      beforeRequest: () => ({ type: tasksActions.REJECT_TASK_REQUEST, payload: { taskId } }),
      success: (data) => ([ { type: tasksActions.REJECT_TASK_SUCCESS, payload: { taskId, task: data } }, handleTaskStatusUpdate(taskId) ]),
      failure: (response) => ({ type: tasksActions.REJECT_TASK_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

export const resetTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/reset`);
      },
      beforeRequest: () => ({ type: tasksActions.RESET_TASK_REQUEST, payload: { taskId } }),
      success: (data) => ([ { type: tasksActions.RESET_TASK_SUCCESS, payload: { taskId, task: data } }, handleTaskStatusUpdate(taskId) ]),
      failure: (response) => ({ type: tasksActions.RESET_TASK_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

export const notApplicableTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/notApplicable`);
      },
      beforeRequest: () => ({ type: tasksActions.NOT_APPLICABLE_TASK_REQUEST, payload: { taskId } }),
      success: (data) => ([ { type: tasksActions.NOT_APPLICABLE_TASK_SUCCESS, payload: { taskId, task: data } }, handleTaskStatusUpdate(taskId) ]),
      failure: (response) => ({ type: tasksActions.NOT_APPLICABLE_TASK_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

const handleTaskStatusUpdate = (taskId: number): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
  const state = getState();

  const task = state.tasks.entities.tasks[taskId];

  if(!task) {
    return;
  }

  if(task.parentTaskId) {
    dispatch(getTask(task.parentTaskId));
  }
};

export const updateTask = (task: any, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks`)
          .send(task);
      },
      beforeRequest: () => ({ type: tasksActions.UPDATE_TASK_REQUEST, payload: { task } }),
      success: (data) => {
        if (_.isFunction(successCallback)) {
          successCallback();
        }

        return {
          type: tasksActions.UPDATE_TASK_SUCCESS,
          payload: {
            task: data,
          },
        };
      },
      failure: (response) => ({ type: tasksActions.UPDATE_TASK_FAILURE, payload: { ...response, task } }),
    },
  };
};

export const updateTaskPositions = (positions: UpdateTaskPosition[], successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/tasks/positions`)
          .send(positions);
      },
      successCallback: successCallback,
      beforeRequest: () => ({ type: tasksActions.UPDATE_TASK_POSITIONS_REQUEST, payload: { positions } }),
      success: () => ({ type: tasksActions.UPDATE_TASK_POSITIONS_SUCCESS }),
      failure: (response) => ({ type: tasksActions.UPDATE_TASK_POSITIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertTask = (task: any, successCallback?: (task: AssignedTaskModel) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/tasks`)
          .send(task);
      },
      beforeRequest: () => ({ type: tasksActions.INSERT_TASK_REQUEST, payload: { task } }),
      success: (data) => {
        return [
          { type: tasksActions.INSERT_TASK_SUCCESS, payload: { task: data } },
          getOperatorAuditTasks(data.operatorAuditId, false),
        ];
      },
      successCallback: successCallback,
      failure: (response) => ({ type: tasksActions.INSERT_TASK_FAILURE, payload: { ...response, task } }),
    },
  };
};

export const submitTaskMessage = (taskId: number, text: string, submitTask?: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/tasks/${taskId}/message`)
          .query({ submitTask: !!submitTask })
          .send({ message: text });
      },
      beforeRequest: () => ({ type: tasksActions.SUBMIT_TASK_MESSAGE_REQUEST, payload: { taskId } }),
      success: ({ task, taskMessage }) => ({ type: tasksActions.SUBMIT_TASK_MESSAGE_SUCCESS, payload: { task: task, taskMessage: taskMessage } }),
      failure: (response) => ({ type: tasksActions.SUBMIT_TASK_MESSAGE_FAILURE, payload: { ...response, taskId } }),
    },
  };
};

export const deleteTask = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/tasks/${taskId}`);
      },
      beforeRequest: () => ({ type: tasksActions.DELETE_TASK_REQUEST, payload: { taskId } }),
      success: () => ({ type: tasksActions.DELETE_TASK_SUCCESS, payload: { taskId } }),
      failure: (response) => ({ type: tasksActions.DELETE_TASK_FAILURE, payload: { ...response, taskId } }),
    },
  };
};
