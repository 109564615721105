import React from 'react';
import { PollResultDisplayType, PollResultSummary } from 'Modules/polls/index';
import { PollHorizontalBarChart, PollPieChart } from 'Components';

interface Props {
  type: PollResultDisplayType;
  results: PollResultSummary[];
  onOptionClick?: (optionId: number) => void;
}

const PollChart = ({ type, results, onOptionClick }: Props) => {
  const controlMap = {
    [PollResultDisplayType.PieChart]: PollPieChart,
    [PollResultDisplayType.HorizontalBarGraph]: PollHorizontalBarChart,
  };

  const Control = controlMap[type];

  return (
    <Control results={results} onOptionClick={onOptionClick} />
  );
};

export default PollChart;
