import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { Button } from 'reactstrap';

import { getFileIconOrDefault } from 'constants/FileExtensionIcons';
import { stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import useFileDownloader from 'hooks/useFileDownloader';
import { ReferenceMaterial } from 'Modules/referenceMaterials/models';
import { LoadableButton, RealtimeHumanizeDateTime, FlexRow } from 'Components';
import ReferenceFilePreviewModal from './ReferenceFilePreviewModal';
import useModalState from 'hooks/useModalState';
import _ from 'lodash';
import { previewFileHandlers } from '../../Components/PreviewFileModal/PreviewFileModal';
import ModifyReferenceMaterialModal from './ModifyReferenceMaterialModal';

interface Props {
  referenceMaterial: ReferenceMaterial;
  isSelected: boolean;
  toggleSelection: (referenceMaterial: ReferenceMaterial) => void;
}

const ReferenceFileTile = ({ referenceMaterial, isSelected, ...props }: Props) => {
  const { isShowing: isEditing, show: startEditing, hide: stopEditing } = useModalState();
  const { isShowing: isShowingPreviewModal, show: showPreviewModal, hide: hidePreviewModal } = useModalState();
  const extension = referenceMaterial.file.originalFilename.split('.').pop();
  const [ downloadFile, isDownloading ] = useFileDownloader((superagent) => superagent.get(`/api/v1/referenceMaterials/${referenceMaterial.referenceFileId}/download`));

  const previewIsSupported = _.some(previewFileHandlers, (fh) => _.includes(fh.extensions, extension));

  return (
    <>
      <div className="file-tile">
        <div className="text-center">
          <div className="position-relative">
            <i className={classnames('folder-icon', getFileIconOrDefault(extension, 'mdi mdi-file-question-outline'))}/>
          </div>

          <div className="text-center">
            <h4 className="mb-0">
              <span className="folder-name">{referenceMaterial.name}</span>
            </h4>

            <span className="file-name">{referenceMaterial.file.originalFilename}</span>
          </div>

          <h5 className="m-0"><small><RealtimeHumanizeDateTime date={moment.utc(referenceMaterial.file.createdTimestamp)}/></small></h5>
        </div>

        <div className="top-left-overlay p-1">
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => props.toggleSelection(referenceMaterial))}>
                <i className={classnames('mdi mdi-24px', { 'mdi-checkbox-blank-outline': !isSelected, 'mdi-checkbox-marked-outline': isSelected })} />
              </Button>
            </div>
          </FlexRow>
        </div>

        <div className="top-right-overlay p-1">
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(startEditing)}>
                <i className="mdi mdi-24px mdi-pencil"/>
              </Button>
            </div>
            <div>
              <LoadableButton LoadingLabel={null} isLoading={isDownloading} color="anchor" onClick={stopPropagationPreventDefaultAnd(downloadFile)}>
                <i className="mdi mdi-24px mdi-download"/>
              </LoadableButton>
            </div>

            {previewIsSupported && (
              <div>
                <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(showPreviewModal)}>
                  <i className="mdi mdi-24px mdi-eye"/>
                </Button>
              </div>
            )}

            {/*<div>*/}
            {/*  <HideIfNotAdmin useImpersonationPermissions>*/}
            {/*    <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(deleteFile)}>*/}
            {/*      <i className="mdi mdi-24px mdi-trash-can"/>*/}
            {/*    </Button>*/}
            {/*  </HideIfNotAdmin>*/}
            {/*</div>*/}
          </FlexRow>
        </div>
      </div>

      <ReferenceFilePreviewModal
        show={isShowingPreviewModal}
        referenceFileId={referenceMaterial.referenceFileId}
        hide={hidePreviewModal}
      />

      <ModifyReferenceMaterialModal
        show={isEditing}
        referenceMaterial={referenceMaterial}
        hide={stopEditing}
      />
    </>
  );
};

export default ReferenceFileTile;
