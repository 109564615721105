import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ModalHeaderWithClose from 'Components/ModalHeaderWithClose';

interface Props {
  show: boolean;
  results: any;
  hide: () => void;
}

const SurveyResultsModal = ({ show, results, ...props }: Props) => {
  return (
    <>
      <Modal isOpen={show} toggle={props.hide}>
        <ModalHeaderWithClose hide={props.hide}>
          Survey Results
        </ModalHeaderWithClose>
        <ModalBody>
          <pre>
            {JSON.stringify(results, null, 2)}
          </pre>
        </ModalBody>
      </Modal>
    </>
  );
};


export default SurveyResultsModal;
