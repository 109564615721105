import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import api from '../Middleware/api';
import downloadFileMiddleware from '../Middleware/downloadFileMiddleware';
import pageChangeMiddleware from '../Middleware/pageChangeMiddleware';
import signalrMiddleware from '../Middleware/signalrMiddleware';
import i18nLanguageMiddleware from '../Middleware/i18nLanguageMiddleware';

export default (history: any) => {
  let appliedMiddleware = applyMiddleware(routerMiddleware(history), thunk, i18nLanguageMiddleware, pageChangeMiddleware, api, downloadFileMiddleware, signalrMiddleware);

  const useDevTools = true;

  if (useDevTools) {
    appliedMiddleware = composeWithDevTools(appliedMiddleware);
  }

  return createStore(
    createRootReducer(history),
    appliedMiddleware
  );
};
