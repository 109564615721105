import React from 'react';

import { PreviewFileModal } from 'Components';

interface Props {
  show: boolean;
  shareBoardId?: number;
  hide: () => void;
}

const ShareBoardPreviewModal = ({ show, shareBoardId, ...props }: Props) => {
  return (
    <PreviewFileModal
      show={show}
      getPreviewRequest={(superagent) => superagent.get(`/api/v1/shareBoardFiles/${shareBoardId}/preview`)}
      getDownloadRequest={(superagent) => superagent.get(`/api/v1/shareBoardFiles/${shareBoardId}/download`)}
      hide={props.hide}
    />
  );
};

export default ShareBoardPreviewModal;
