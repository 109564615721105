import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';

import useStateSelector from 'hooks/useStateSelector';
import { getOperatorDashboard } from 'Modules/dashboard/actions';
import { getCurrentUserOperatorDashboard } from 'Modules/dashboard/selectors';
import FlexLoaderPanel from 'Components/FlexLoaderPanel';

import OperatorDashboard from './OperatorDashboard';

const OperatorDashboardPage = () => {
  const dispatch = useDispatch();
  const isLoadingOperatorDashboard = useStateSelector(state => state.dashboard.loading.isLoadingOperatorDashboard);
  const currentUserDashboard = useStateSelector(getCurrentUserOperatorDashboard);

  useEffect(() => {
    dispatch(getOperatorDashboard());
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <FlexLoaderPanel loadingClassName="p-3" isLoading={isLoadingOperatorDashboard}>
        {currentUserDashboard && (
          <OperatorDashboard operatorDashboard={currentUserDashboard}/>
        )}
      </FlexLoaderPanel>
    </>
  );
};


export default OperatorDashboardPage;
