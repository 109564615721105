import React from 'react';
import _ from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { AuditTemplate, TemplateTask } from 'models';
import CategoryTemplateTaskGroup from './CategoryTemplateTaskGroup';
import WithCategories from '../../Components/WithCategories';
import AddTaskDropdown from '../../Components/AddTaskDropdown/AddTaskDropdown';
import WithModifyTemplateTaskModal from '../Admin/Tasks/WithModifyTemplateTaskModal';

interface Props {
  template: AuditTemplate;
  tasks: TemplateTask[];
  weekIndex: number;
}

const TemplateWeek = function ({ template, weekIndex, tasks }: Props) {
  return (
    <WithModifyTemplateTaskModal template={template} weekIndex={weekIndex}>
      {({ showCreateTaskModal, showCreateParentTaskModal }) => (
        <Row>
          <Col md={3} lg={2}>
            <Card className="m-0">
              <CardBody className="d-flex align-items-center justify-content-center">
                <div>
                  <div><strong>Week {weekIndex}</strong></div>
                </div>
              </CardBody>
            </Card>

            <AddTaskDropdown
              block
              addTask={showCreateTaskModal}
              addParentTask={showCreateParentTaskModal}
            />
          </Col>

          <Col md={9} lg={10}>
            {/* @ts-ignore */}
            <WithCategories appendUndefined>
              {({ sortedCategories }) => (
                <>
                  {
                    _.map(sortedCategories, (category, i) => {
                      const categoryTasks = _.filter(tasks, t => (t.categoryId || undefined) === category?.categoryId);
                      const isLast = _.size(sortedCategories) - 1 === i;

                      if (_.isEmpty(categoryTasks)) {
                        return null;
                      }

                      return (
                        <React.Fragment
                          key={category?.categoryId || 'undefined'}
                        >
                          <Row>
                            <Col md={12}>
                              <CategoryTemplateTaskGroup
                                // @ts-ignore
                                weekIndex={weekIndex}
                                // @ts-ignore
                                tasks={categoryTasks}
                                // @ts-ignore
                                category={category}
                                // @ts-ignore
                                template={template}
                              />
                            </Col>
                          </Row>

                          {!isLast && (
                            <hr/>
                          )}
                        </React.Fragment>
                      );
                    })
                  }
                </>
              )}
            </WithCategories>
          </Col>
        </Row>
      )}
    </WithModifyTemplateTaskModal>
  );
};

export default TemplateWeek;
