import { PaginatedRequest } from './core';

export interface SiteConfig {
  baseUrl: string;
  defaultPagination: PaginatedRequest;
  defaultDropdownPagination: PaginatedRequest;
  debounceTimeout: number;
}

const config: SiteConfig = {
  defaultPagination: { pageSize: 25, offset: 0 },
  defaultDropdownPagination: { pageSize: 15, offset: 0 },
  baseUrl: '',
  debounceTimeout: 350,
};

export default config;
