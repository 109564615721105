import React from 'react';

import { baseTableOptions } from 'helpers/tableHelper';
import { PollResultSummary, pollsActions } from 'Modules/polls';
import { BaseTable } from 'Components';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';

interface LocalProps {
  pollId: number;
  pollResultSummaries: PollResultSummary[];
}

const PollResultSummaryTable = ({ pollId, pollResultSummaries }: LocalProps) => {
  const dispatch = useDispatch();
  function deletePollResponse(pollResultSummary: PollResultSummary) {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Delete Response',
        text: 'Are you sure you want to delete this poll response?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      dispatch(pollsActions.async.deletePollResponse(pollId, pollResultSummary.pollResultId));
    })();
  }

  const tableOptions: any = {
    ...baseTableOptions,
    minRows: 10,
    data: pollResultSummaries,
    columns: [
      {
        id: 'pollResultId',
        Header: 'Id',
        accessor: (r: PollResultSummary) => r.pollResultId,
        width: 45,
      },
      {
        id: 'operatorName',
        Header: 'Operator',
        accessor: (r: PollResultSummary) => {
          if(r.isCurrentOperatorsResult) {
            return 'Current Operator';
          }

          if(r.operatorCodeName) {
            return r.operatorCodeName.trim();
          }

          return 'REDACTED';
        },
      },
      {
        id: 'createdUserName',
        Header: 'User',
        accessor: (r: PollResultSummary) => r.createdUserName,
      },
      {
        id: 'optionName',
        Header: 'Response',
        accessor: (r: PollResultSummary) => r.optionName,
      },
      {
        Header: '',
        id: 'delete',
        className: 'text-center',
        width: 32,
        Cell: ({ original }: { original: PollResultSummary }) => {
          return (
            <Button color="anchor" size="sm" onClick={() => deletePollResponse(original)}>
              <i className="mdi mdi-trash-can" />
            </Button>
          );
        },
      },
    ],
    defaultSorted: [ { id: 'optionName' } ],
  };

  return (
    <div className="bg-white">
      <BaseTable {...tableOptions} />
    </div>
  );
};

export default PollResultSummaryTable;
