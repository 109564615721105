import _ from 'lodash';
import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { CreatePollCategory, ModifyPollCategory, PollCategory, pollsActions, UpdatePollCategory } from 'Modules/polls';

const usePollCategoryForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingPollCategory = useStateSelector((state) => state.polls.loading.isInsertingPollCategory);
  const isUpdatingPollCategory = useStateSelector((state) => state.polls.loading.isUpdatingPollCategory);
  const isDeletingPollCategory = useStateSelector((state) => state.polls.loading.isDeletingPollCategory);

  function validatePollCategory(pollCategory: ModifyPollCategory) {
    const errors: string[] = [];

    return errors;
  }

  function insertPollCategory(pollCategory: CreatePollCategory) {
    const errorMessages = validatePollCategory(pollCategory);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(pollsActions.async.insertPollCategory(pollCategory))
      .then((pollCategory: PollCategory) => {
        toast.success('Poll Category successfully created.');
        return pollCategory;
      });
  }

  function updatePollCategory(pollCategoryId: number, pollCategory: UpdatePollCategory) {
    const errorMessages = validatePollCategory(pollCategory);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(pollsActions.async.updatePollCategory(pollCategoryId, pollCategory))
      .then((pollCategory: PollCategory) => {
        toast.success('Poll Category successfully saved.');
        return pollCategory;
      });
  }

  function deletePollCategory(pollCategory: PollCategory) {
    return dispatch(pollsActions.async.deletePollCategory(pollCategory))
      .then((pollCategory: PollCategory) => {
        toast.success('Poll Category successfully deactivated.');
        return pollCategory;
      });
  }

  function cancelPollCategoryModification() {
    dispatch(pollsActions.cancelPollCategoryModification());
  }

  return {
    insertPollCategory,
    updatePollCategory,
    deletePollCategory,
    cancelPollCategoryModification,
    loading: {
      isInsertingPollCategory,
      isUpdatingPollCategory,
      isDeletingPollCategory,
    },
  };
};

export default usePollCategoryForm;
