import { Action, ApiAction, SidebarType } from 'models';
import { SuperAgent } from 'superagent';
import { commonActions } from '../common/actions';
import config from '../../config';
import { getPaginatedResponse } from '../../helpers/pagination';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { OperatorMessageResponse } from '../messages/models';
import { realtimeOperatorMessageReceived } from '../messages/actions';
import { UserNotificationResponse } from './models';
import { PaginatedRequest } from 'core';

export const userNotificationsActions = {
  NOTIFICATION_RECEIVED: 'AVSafetySolutions/userNotifications/NotificationReceived',
  OPERATOR_MESSAGE_NOTIFICATION_RECEIVED: 'AVSafetySolutions/userNotifications/OperatorMessageNotificationReceived',

  SEARCH_MY_NOTIFICATIONS_REQUEST: 'AVSafetySolutions/userNotifications/SearchMyNotificationsRequest',
  SEARCH_MY_NOTIFICATIONS_SUCCESS: 'AVSafetySolutions/userNotifications/SearchMyNotificationsSuccess',
  SEARCH_MY_NOTIFICATIONS_FAILURE: 'AVSafetySolutions/userNotifications/SearchMyNotificationsFailure',

  COUNT_MY_NOTIFICATIONS_REQUEST: 'AVSafetySolutions/userNotifications/CountMyNotificationsRequest',
  COUNT_MY_NOTIFICATIONS_SUCCESS: 'AVSafetySolutions/userNotifications/CountMyNotificationsSuccess',
  COUNT_MY_NOTIFICATIONS_FAILURE: 'AVSafetySolutions/userNotifications/CountMyNotificationsFailure',

  GET_OPERATOR_MESSAGE_REQUEST: 'AVSafetySolutions/userNotifications/GetOperatorMessageRequest',
  GET_OPERATOR_MESSAGE_SUCCESS: 'AVSafetySolutions/userNotifications/GetOperatorMessageSuccess',
  GET_OPERATOR_MESSAGE_FAILURE: 'AVSafetySolutions/userNotifications/GetOperatorMessageFailure',

  COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_REQUEST: 'AVSafetySolutions/userNotifications/CountMyOperatorMessageNotificationsRequest',
  COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_SUCCESS: 'AVSafetySolutions/userNotifications/CountMyOperatorMessageNotificationsSuccess',
  COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_FAILURE: 'AVSafetySolutions/userNotifications/CountMyOperatorMessageNotificationsFailure',

  GET_TASK_USER_NOTIFICATIONS_REQUEST: 'AVSolutions/userNotifications/GetTaskUserNotificationsRequest',
  GET_TASK_USER_NOTIFICATIONS_SUCCESS: 'AVSolutions/userNotifications/GetTaskUserNotificationsSuccess',
  GET_TASK_USER_NOTIFICATIONS_FAILURE: 'AVSolutions/userNotifications/GetTaskUserNotificationsFailure',

  MARK_TASK_NOTIFICATIONS_VIEWED_REQUEST: 'AVSolutions/userNotifications/MarkTaskNotificationsViewedRequest',
  MARK_TASK_NOTIFICATIONS_VIEWED_SUCCESS: 'AVSolutions/userNotifications/MarkTaskNotificationsViewedSuccess',
  MARK_TASK_NOTIFICATIONS_VIEWED_FAILURE: 'AVSolutions/userNotifications/MarkTaskNotificationsViewedFailure',

  MARK_ALL_USER_NOTIFICATIONS_VIEWED_REQUEST: 'AVSolutions/userNotifications/MarkAllUserNotificationsViewedRequest',
  MARK_ALL_USER_NOTIFICATIONS_VIEWED_SUCCESS: 'AVSolutions/userNotifications/MarkAllUserNotificationsViewedSuccess',
  MARK_ALL_USER_NOTIFICATIONS_VIEWED_FAILURE: 'AVSolutions/userNotifications/MarkAllUserNotificationsViewedFailure',

  MARK_NOTIFICATION_VIEWED_REQUEST: 'AVSafetySolutions/userNotifications/MarkNotificationViewedRequest',
  MARK_NOTIFICATION_VIEWED_SUCCESS: 'AVSafetySolutions/userNotifications/MarkNotificationViewedSuccess',
  MARK_NOTIFICATION_VIEWED_FAILURE: 'AVSafetySolutions/userNotifications/MarkNotificationViewedFailure',

  MARK_NOTIFICATION_UNREAD_REQUEST: 'AVSafetySolutions/userNotifications/MarkNotificationUnreadRequest',
  MARK_NOTIFICATION_UNREAD_SUCCESS: 'AVSafetySolutions/userNotifications/MarkNotificationUnreadSuccess',
  MARK_NOTIFICATION_UNREAD_FAILURE: 'AVSafetySolutions/userNotifications/MarkNotificationUnreadFailure',
};

export const notificationReceived = (notification: UserNotificationResponse): Action => {
  return {
    type: userNotificationsActions.NOTIFICATION_RECEIVED,
    payload: {
      notification,
    },
  };
};

export const countMyNotifications = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/userNotifications/count/me`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.COUNT_MY_NOTIFICATIONS_REQUEST }),
      success: ({ currentUserId, userNotificationCount }): Action => ({ type: userNotificationsActions.COUNT_MY_NOTIFICATIONS_SUCCESS, payload: { currentUserId, count: userNotificationCount } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.COUNT_MY_NOTIFICATIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const countMyOperatorMessageNotifications = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/operators/current/messages/count/me`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_REQUEST }),
      success: ({ currentUserId, operatorMessageNotificationCount }): Action => ({ type: userNotificationsActions.COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_SUCCESS, payload: { currentUserId, count: operatorMessageNotificationCount } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getOperatorMessage = (operatorMessageId: number, successCallback?: (operatorMessage: OperatorMessageResponse) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/operatorsMessages/${operatorMessageId}`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.GET_OPERATOR_MESSAGE_REQUEST }),
      successCallback,
      success: (data): Action => ({ type: userNotificationsActions.GET_OPERATOR_MESSAGE_SUCCESS, payload: { operatorMessage: data } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.GET_OPERATOR_MESSAGE_FAILURE, payload: { ...response } }),
    },
  };
};

export const searchMyNotifications = (request: PaginatedRequest): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/userNotifications/search/me`)
          .query(request);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.SEARCH_MY_NOTIFICATIONS_REQUEST }),
      success: ({ currentUserId, userNotifications }): Action => ({ type: userNotificationsActions.SEARCH_MY_NOTIFICATIONS_SUCCESS, payload: { currentUserId, response: getPaginatedResponse(request, userNotifications) } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.SEARCH_MY_NOTIFICATIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getTaskUserNotifications = (taskId: number, successCallback?: (data: { currentUserId: string; userNotifications: UserNotificationResponse[] }) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/tasks/${taskId}/userNotifications`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.GET_TASK_USER_NOTIFICATIONS_REQUEST }),
      success: ({ currentUserId, userNotifications }): Action => ({ type: userNotificationsActions.GET_TASK_USER_NOTIFICATIONS_SUCCESS, payload: { currentUserId, taskId, userNotifications } }),
      successCallback,
      failure: (response: any): Action => ({ type: userNotificationsActions.GET_TASK_USER_NOTIFICATIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const markTaskNotificationsViewed = (taskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${config.baseUrl}/api/v1/tasks/${taskId}/userNotifications/viewed`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.MARK_TASK_NOTIFICATIONS_VIEWED_REQUEST }),
      success: ({ currentUserId, numNotificationsMarkedViewed }): Action => ({ type: userNotificationsActions.MARK_TASK_NOTIFICATIONS_VIEWED_SUCCESS, payload: { currentUserId, taskId, numNotificationsMarkedViewed } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.MARK_TASK_NOTIFICATIONS_VIEWED_FAILURE, payload: { ...response } }),
    },
  };
};

export const markAllUserNotificationsViewed = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${config.baseUrl}/api/v1/userNotifications/viewed`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.MARK_ALL_USER_NOTIFICATIONS_VIEWED_REQUEST }),
      success: ({ currentUserId, numNotificationsMarkedViewed }): Action => ({ type: userNotificationsActions.MARK_ALL_USER_NOTIFICATIONS_VIEWED_SUCCESS, payload: { currentUserId, numNotificationsMarkedViewed } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.MARK_ALL_USER_NOTIFICATIONS_VIEWED_FAILURE, payload: { ...response } }),
    },
  };
};

export const markNotificationRead = (notificationId: string): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${config.baseUrl}/api/v1/userNotifications/${notificationId}/viewed`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.MARK_NOTIFICATION_VIEWED_REQUEST }),
      success: ({ currentUserId, userNotification }): Action => ({ type: userNotificationsActions.MARK_NOTIFICATION_VIEWED_SUCCESS, payload: { currentUserId, userNotification } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.MARK_NOTIFICATION_VIEWED_FAILURE, payload: { ...response } }),
    },
  };
};

export const markNotificationUnread = (notificationId: string): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.del(`${config.baseUrl}/api/v1/userNotifications/${notificationId}/viewed`);
      },
      beforeRequest: (): Action => ({ type: userNotificationsActions.MARK_NOTIFICATION_UNREAD_REQUEST }),
      success: ({ currentUserId, userNotification }): Action => ({ type: userNotificationsActions.MARK_NOTIFICATION_UNREAD_SUCCESS, payload: { currentUserId, userNotification } }),
      failure: (response: any): Action => ({ type: userNotificationsActions.MARK_NOTIFICATION_UNREAD_FAILURE, payload: { ...response } }),
    },
  };
};

export const operatorMessageNotificationReceived = (userId: string, operatorMessageId: number): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
  const state = getState();

  if (state.site.sidebarType === SidebarType.Chat) {
    dispatch(getOperatorMessage(operatorMessageId, (operatorMessage) => {
      dispatch(realtimeOperatorMessageReceived(operatorMessage));
    }));
  } else {
    dispatch({
      type: userNotificationsActions.OPERATOR_MESSAGE_NOTIFICATION_RECEIVED,
      payload: {
        userId,
      },
    });
  }
};
