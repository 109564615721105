import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Helmet from 'react-helmet';

import PageLayout from 'App/PageLayout';
import { OperatorAuditModel, OperatorAuditTasks, OperatorModel } from 'models';
import OperatorsTable from 'AviationSafetySolutions/Operators/OperatorsTable';
import { changeModifyingTaskValues, setSelectedAudit, setSelectedOperator } from 'Modules/tasks/actions';
import { getSelectedOperatorAudit, getSelectedOperatorAudits } from 'Modules/tasks/selectors';
import {
  changeModifyingAuditValues,
  deleteAuditTasks,
  getOperatorAudits,
  getOperatorAuditTasks,
} from 'Modules/audits/actions';
import { RootState } from 'Modules/reducers';
import { getAllOperatorModels } from 'Modules/operators/selectors';
import { getAvailableDirectories } from 'Modules/library/actions';
import { operatorsActions } from 'Modules/operators';
import { CollapsibleCardBox, CollapsibleCardBoxRef, FlexLoaderPanel } from 'Components';

import AuditsTable from '../Audits/OperatorAuditsTable';
import CreateTemplateFromAuditModal from './CreateTemplateFromAuditModal';
import PersistentTasks from './PersistentTasks';
import WeeklyTasks from './WeeklyTasks';
import SyncAuditTemplateToAuditModal from 'Pages/Tasks/SyncAuditTemplateToAuditModal';

enum SelectedTaskTab {
  Weekly = 1,
  Persistent = 2
}

interface LocalProps {}

interface LocalState {
  isShowingCreateAuditTemplateModal: boolean;
  isShowingSyncAuditTemplateToAuditModal: boolean;
  selectedTab: SelectedTaskTab;
}

interface StateProps {
  operators: OperatorModel[];
  selectedOperatorId?: number;
  selectedAuditId?: number;
  isModifyingTask: boolean;
  operatorAudits?: OperatorAuditModel[];
  selectedOperatorAudit?: OperatorAuditTasks;
  isLoadingOperators: boolean;
}

interface DispatchProps {
  getAllOperators: typeof operatorsActions.async.getAllOperators;
  getOperatorAudits: typeof getOperatorAudits;
  getOperatorAuditTasks: typeof getOperatorAuditTasks;
  getAvailableDirectories: typeof getAvailableDirectories;
  setSelectedOperator: typeof setSelectedOperator;
  setSelectedAudit: typeof setSelectedAudit;
  changeModifyingAuditValues: typeof changeModifyingAuditValues;
  changeModifyingTaskValues: typeof changeModifyingTaskValues;
  deleteAuditTasks: typeof deleteAuditTasks;
}

type Props = LocalProps & StateProps & DispatchProps;

class TasksPage extends React.Component<Props, LocalState> {
  private searchRef: CollapsibleCardBoxRef | null = null;
  private auditsRef: CollapsibleCardBoxRef | null = null;

  constructor (props: Props) {
    super(props);

    this.state = {
      selectedTab: SelectedTaskTab.Weekly,
      isShowingCreateAuditTemplateModal: false,
      isShowingSyncAuditTemplateToAuditModal: false,
    };
  }

  public shouldComponentUpdate (nextProps: Props) {
    const { isModifyingTask } = this.props;

    if (isModifyingTask && nextProps.isModifyingTask) {
      return false;
    }

    return true;
  }

  public componentDidMount () {
    this.props.getAvailableDirectories();
    this.props.getAllOperators();
  }

  public componentDidUpdate (prevProps: Props) {
    const { operatorAudits, selectedOperatorAudit, selectedOperatorId, selectedAuditId } = this.props;

    if (selectedOperatorId && prevProps.selectedOperatorId !== selectedOperatorId) {
      this.props.getOperatorAudits(selectedOperatorId);
    }

    if (selectedAuditId && prevProps.selectedAuditId !== selectedAuditId) {
      this.props.getOperatorAuditTasks(selectedAuditId, false);
    }

    if (this.searchRef && operatorAudits && prevProps.operatorAudits !== operatorAudits) {
      this.searchRef.setIsOpen(false);
    }

    if (this.auditsRef && selectedOperatorAudit && prevProps.selectedOperatorAudit !== selectedOperatorAudit) {
      this.auditsRef.setIsOpen(false);
    }
  }

  private setSearchRef = (c: CollapsibleCardBoxRef) => {
    this.searchRef = c;
  };

  private setAuditsRef = (c: CollapsibleCardBoxRef) => {
    this.auditsRef = c;
  };

  private deleteAuditTasks = async () => {
    const { selectedAuditId } = this.props;

    if (!selectedAuditId) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Delete All Tasks',
      text: 'Are you sure you want to delete all the tasks tied to the selected audit?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    });

    if (dismiss) {
      return;
    }

    this.props.deleteAuditTasks(selectedAuditId);
  };

  private openAddAuditModal = () => {
    const { selectedOperatorId } = this.props;

    if (!selectedOperatorId) {
      return;
    }

    this.props.changeModifyingAuditValues({
      operatorAuditId: 0,
      operatorId: selectedOperatorId,
    });
  };

  private openEditAuditModal = (audit: OperatorAuditModel) => {
    const { selectedOperatorId } = this.props;
    const { operatorAuditId, parsedStartPrepDate, parsedAuditDate } = audit;

    const dto = {
      operatorAuditId: operatorAuditId,
      operatorId: selectedOperatorId,
      startPrepDate: parsedStartPrepDate,
      auditDate: parsedAuditDate,
    };

    this.props.changeModifyingAuditValues(dto);
  };

  private showCreateAuditTemplateModal = () => {
    this.setState({ isShowingCreateAuditTemplateModal: true });
  }

  private hideCreateAuditTemplateModal = () => {
    this.setState({ isShowingCreateAuditTemplateModal: false });
  }

  private showSyncAuditTemplateToAuditModal = () => {
    this.setState({ isShowingSyncAuditTemplateToAuditModal: true });
  }

  private hideSyncAuditTemplateToAuditModal = () => {
    this.setState({ isShowingSyncAuditTemplateToAuditModal: false });
  }

  private handlePostAuditTemplateSync = () => {
    const { selectedAuditId } = this.props;

    if (!selectedAuditId) {
      return;
    }

    this.props.getOperatorAuditTasks(selectedAuditId, false);
    this.hideSyncAuditTemplateToAuditModal();
  }

  public render () {
    const { selectedTab, isShowingCreateAuditTemplateModal, isShowingSyncAuditTemplateToAuditModal } = this.state;
    const { operators, operatorAudits, selectedOperatorAudit, selectedOperatorId, selectedAuditId, isLoadingOperators } = this.props;

    return (
      <>
        <Helmet>
          <title>Admin - Audits</title>
        </Helmet>

        <PageLayout pageTitle="Tasks Maintenance" breadcrumbs={[ 'Administration', 'Tasks' ]}>
          <FlexLoaderPanel loadingClassName="p-5" isLoading={isLoadingOperators}>
            <Row>
              <Col md={12}>
                <CollapsibleCardBox Header={`Operators (${_.size(operators)})`} ref={this.setSearchRef} defaultOpen>
                  <Row className="mt-2">
                    <Col md={12}>
                      <OperatorsTable
                        operators={operators}
                        selectedOperatorId={selectedOperatorId}
                        selectOperator={this.props.setSelectedOperator}
                      />
                    </Col>
                  </Row>
                </CollapsibleCardBox>
              </Col>
            </Row>

            {operatorAudits && (
              <Row>
                <Col md={12}>
                  <CollapsibleCardBox Header={`Audits (${_.size(operatorAudits)})`} ref={this.setAuditsRef} defaultOpen>
                    <Row className="my-1">
                      <Col md={12}>
                        <button className="btn btn-primary" onClick={this.openAddAuditModal}><i className="fa fa-plus" /> Add Audit</button>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <AuditsTable
                          audits={operatorAudits}
                          selectedAuditId={selectedAuditId}
                          selectAudit={this.props.setSelectedAudit}
                          startEditingAudit={this.openEditAuditModal}
                        />
                      </Col>
                    </Row>
                  </CollapsibleCardBox>
                </Col>
              </Row>
            )}

            {selectedOperatorAudit &&
          (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  <Nav pills>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: selectedTab === SelectedTaskTab.Weekly })}
                        onClick={() => this.setState({ selectedTab: SelectedTaskTab.Weekly })}
                      >
                        Weekly Tasks ({selectedOperatorAudit.numWeeklyTasks})
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: selectedTab === SelectedTaskTab.Persistent })}
                        onClick={() => this.setState({ selectedTab: SelectedTaskTab.Persistent })}
                      >
                        Persistent Tasks ({selectedOperatorAudit.numPersistentTasks})
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div>
                  <UncontrolledButtonDropdown color="light">
                    <DropdownToggle color="light" caret>
                      Actions <i className="mdi mdi-settings" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-right">
                      <DropdownItem onClick={this.showCreateAuditTemplateModal}>Create Template from Audit</DropdownItem>
                      <DropdownItem onClick={this.showSyncAuditTemplateToAuditModal}>Sync Tasks from Template</DropdownItem>
                      <DropdownItem onClick={this.deleteAuditTasks}>Clear All Tasks</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                {selectedTab === SelectedTaskTab.Weekly && (
                  <WeeklyTasks
                    operatorAudit={selectedOperatorAudit}
                    operatorAuditTasks={selectedOperatorAudit}
                  />
                )}
                {selectedTab === SelectedTaskTab.Persistent && (
                  <PersistentTasks
                    operatorAudit={selectedOperatorAudit}
                    operatorAuditTasks={selectedOperatorAudit}
                  />
                )}
              </div>

              <CreateTemplateFromAuditModal
                show={isShowingCreateAuditTemplateModal}
                operatorAuditId={selectedOperatorAudit.operatorAuditId}
                hide={this.hideCreateAuditTemplateModal}
              />

              <SyncAuditTemplateToAuditModal
                show={isShowingSyncAuditTemplateToAuditModal}
                operatorAuditId={selectedOperatorAudit.operatorAuditId}
                onSyncComplete={this.handlePostAuditTemplateSync}
                hide={this.hideSyncAuditTemplateToAuditModal}
              />
            </>
          )}
          </FlexLoaderPanel>
        </PageLayout>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    operators: getAllOperatorModels(state),
    selectedOperatorId: state.tasks.ui.selectedOperatorId,
    selectedAuditId: state.tasks.ui.selectedAuditId,
    isLoadingOperators: state.operators.loading.isLoadingAllOperators,
    isModifyingTask: !!state.tasks.ui.modifyingTask,
    operatorAudits: getSelectedOperatorAudits(state),
    selectedOperatorAudit: getSelectedOperatorAudit(state),
  };
};

const mapDispatchToProps = {
  getAllOperators: operatorsActions.async.getAllOperators,
  getOperatorAudits: getOperatorAudits,
  getOperatorAuditTasks: getOperatorAuditTasks,
  getAvailableDirectories: getAvailableDirectories,
  setSelectedOperator: setSelectedOperator,
  setSelectedAudit: setSelectedAudit,
  changeModifyingAuditValues: changeModifyingAuditValues,
  changeModifyingTaskValues: changeModifyingTaskValues,
  deleteAuditTasks: deleteAuditTasks,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(TasksPage);
