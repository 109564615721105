import React, { useState } from 'react';
import classnames from 'classnames';
import { Document, Page, pdfjs } from 'react-pdf';
import FlexRow from '../FlexRow';
import FlexColumn from '../FlexColumn';
import FlexLoader from '../FlexLoader';
import { Button } from 'reactstrap';
import PaginationNavigation from '../Pagination/PaginationNavigation';
import { DocumentCallback } from 'react-pdf/src/shared/types';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.js?v=3.6.172`;

interface Props {
  dataUrl: string;
  download?: () => void;
}

interface PdfState {
  numPages: number;
  currentPage: number;
  isLoading: boolean;
}

const PdfViewer = ({ dataUrl, ...props }: Props) => {
  const [ currentState, setCurrentState ] = useState<PdfState>({
    currentPage: 0,
    numPages: 0,
    isLoading: true,
  });

  const { numPages, currentPage, isLoading } = currentState;

  function onLoadPdf(pdf: DocumentCallback) {
    setCurrentState({
      numPages: pdf.numPages,
      currentPage: pdf.numPages > 0 ? 1 : 0,
      isLoading: false,
    });
  }

  function setOffset(offset: number) {
    setCurrentState({
      ...currentState,
      currentPage: offset + 1,
    });
  }

  const PaginationRow = (
    <FlexRow overflowHidden center fill>
      <PaginationNavigation pagination={{ total: numPages, offset: currentPage - 1, pageSize: 1 }} setOffset={setOffset}/>
    </FlexRow>
  );

  return (
    <FlexColumn overflowHidden fill justifyCenter childSpacingY={2}>
      <FlexRow overflowHidden>
        <FlexRow overflowHidden className={classnames({ 'invisible': !props.download })}>
          <Button color="anchor" onClick={props.download}>
            <i className="mdi mdi-24px mdi-download"/>
          </Button>
        </FlexRow>

        {PaginationRow}

      </FlexRow>

      {isLoading && (
        <FlexLoader/>
      )}

      <Document className="d-flex justify-content-center" onLoadSuccess={onLoadPdf} file={dataUrl}>
        {currentPage && (
          <Page pageNumber={currentPage}/>
        )}
      </Document>

      {PaginationRow}
    </FlexColumn>
  );
};

export default React.memo(PdfViewer);
