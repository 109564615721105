import React, { useState } from 'react';
import toastr from 'toastr';
import _ from 'lodash';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useOnModalShow, useStateSelector, useThunkDispatch } from 'hooks';
import { feedbackActions } from 'Modules/feedback';
import { FlexRow, LoadableButton } from 'Components';

interface Props {
  show: boolean;
  hide: () => void;
}

const MaxChars = 4000;

const FeedbackModal = ({ show, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const [ feedbackText, setFeedbackText ] = useState('');
  const isInsertingFeedback = useStateSelector(state => state.feedback.loading.isInsertingFeedback);

  useOnModalShow(show)
    .then(() => {
      setFeedbackText('');
    });

  function submitFeedback() {
    dispatch(feedbackActions.async.insertFeedback({ message: feedbackText }))
      .then(() => {
        toastr.success('Thank you for your feedback, we will review it shorty and reach out if we need any more information.');
        props.hide();
      });
  }

  return (
    <Modal isOpen={show} toggle={props.hide}>
      <ModalHeader>Submit Feedback</ModalHeader>
      <ModalBody>
        <p className="text-center">Thank you for taking the time to reach out! This application is constantly evolving and we would love any feedback that you have in order to improve the experience we provide.</p>
        <Input
          type="textarea"
          value={feedbackText}
          rows={10}
          onChange={(e) => {
            let value = e.target.value;

            if(value.length > MaxChars) {
              value = value.substring(0, MaxChars);
            }

            setFeedbackText(value);
          }}
        />
        <div>
          <small>{_.size(feedbackText)}/{MaxChars} Characters Remaining</small>
        </div>
      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div>
            <Button color="light" onClick={props.hide}>Close</Button>
          </div>
          <div>
            <LoadableButton
              onClick={submitFeedback}
              isLoading={isInsertingFeedback}
              color="primary"
              disabled={!feedbackText.trim()}>
              Submit
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default FeedbackModal;
