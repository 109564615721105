import './steps.scss';

import React, { ReactNode, useMemo } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

interface Props<T> {
  items: T[];
  activeItems?: T[];
  disabledItems?: T[];
  completeItems?: T[];
  CompleteIcon?: ReactNode;
  keyAccessor: ((item: T) => string) | ((item: T) => number);
  onStepClick?: (item: T, e: React.MouseEvent) => void;
}

const Steps = <T extends any>({ items, activeItems, disabledItems, completeItems, CompleteIcon, ...props }: Props<T>) => {
  const activeItemHash = useMemo(() => getItemHash(activeItems), [ activeItems ]);
  const disabledItemHash = useMemo(() => getItemHash(disabledItems), [ disabledItems ]);
  const completeItemHash = useMemo(() => getItemHash(completeItems), [ completeItems ]);

  function getItemHash (activeItems?: T[]) {
    const hash: { [key: string]: true } = {};

    for (const activeItem of activeItems || []) {
      hash[props.keyAccessor(activeItem)] = true;
    }

    return hash;
  }

  function handleStepClick (item: T, e: React.MouseEvent) {
    if (!props.onStepClick) {
      return;
    }
    const isDisabled = _.has(disabledItemHash, props.keyAccessor(item));

    if (isDisabled) {
      return;
    }

    props.onStepClick(item, e);
  }

  return (
    <div className="steps">
      <div className="step-line">
        <div className="line" />
      </div>
      {
        _.map(items, (item, i) => {
          const isActive = _.has(activeItemHash, props.keyAccessor(item));
          const isDisabled = _.has(disabledItemHash, props.keyAccessor(item));
          const isComplete = _.has(completeItemHash, props.keyAccessor(item));

          return (
            <div
              key={props.keyAccessor(item)}
              className={classnames('step', { 'active': isActive }, { 'disabled': isDisabled }, { 'complete': isComplete })}
            >
              <div
                className="step-circle"
                onClick={(e) => handleStepClick(item, e)}
              >
                {(isComplete && CompleteIcon) ? CompleteIcon : <span>{i + 1}</span>}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default Steps;
