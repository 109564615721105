import React, { useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
// @ts-ignore
import path from 'path-browserify';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

import { sOrNoS } from 'helpers/listHelpers';
import { stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import { TreeNode } from 'Modules/library/models';
import { FlexRow, HideWithoutPermission } from 'Components';

import DeleteFileTreeFolderModal from './DeleteFileTreeFolderModal';
import { MoveToFolderFileAccessors } from './MoveToFolderFileTable';
import { Permission } from '../../Modules/roles/index';

interface Props<T> {
  folder: TreeNode<T>;
  onFolderSelect: (folder: TreeNode<T>) => void;
  accessors: MoveToFolderFileAccessors<T>;
  renameLibraryFolder: (folder: TreeNode<T>, updatedDirectoryFilePath: string) => void;
  deleteFolder?: {
    delete: (folder: TreeNode<T>, moveFilesToDirectoryPath: string) => Promise<void>;
  };
}

const FileTreeFolder = <T extends any>({ folder, accessors, deleteFolder, ...props }: Props<T>) => {
  const [ isShowingDeleteModal, setIsShowingDeleteModal ] = useState<boolean>(false);

  function selectFolder() {
    props.onFolderSelect(folder);
  }

  function showDeleteModal() {
    setIsShowingDeleteModal(true);
  }

  function hideDeleteModal() {
    setIsShowingDeleteModal(false);
  }

  function renameLibraryFolder() {
    if (!folder.parent) {
      return;
    }

    (async () => {
      const { dismiss, value: updatedFolderName } = await Swal.fire({
        title: 'Rename Folder',
        text: 'Provide a new name for the folder:',
        input: 'text',
        inputValue: (folder.name || ''),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Rename File',
        inputValidator: (value: string) => {
          if (!(value || '').trim()) {
            return 'You must enter a valid folder name';
          }

          return null;
        },
      });

      if (dismiss) {
        return;
      }

      const updatedDirectoryFilePath = path.join(folder.parent!.filePath, updatedFolderName, '/');
      props.renameLibraryFolder(folder, updatedDirectoryFilePath);
    })();
  }

  function getDecedentFiles() {
    return folder.fileTree.getDescendantDataForNode(folder);
  }

  const decedentFiles = getDecedentFiles();

  return (
    <>
      <div
        className="file-tile"
        onClick={selectFolder}
      >
        <div className="text-center">
          <div className="position-relative">
            <i className={classnames('folder-icon mdi mdi-folder', { 'is-empty': _.isEmpty(decedentFiles) })}/>

            <div className="flex-overlay file-count-overlay">
              <div>{_.size(decedentFiles)} File{sOrNoS(decedentFiles)}</div>
            </div>
          </div>
          <h4>
            <FlexRow center childSpacingX={1}>
              <span className="folder-name">{folder.name}</span>
            </FlexRow>
          </h4>
        </div>

        <HideWithoutPermission permission={Permission.OperatorMaintenance}>
          <div className="top-right-overlay p-1">

            <FlexRow alignCenter childSpacingX={1}>
              <div>
                <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(renameLibraryFolder)}>
                  <i className="mdi mdi-24px mdi-pencil"/>
                </Button>
              </div>

              <div>
                {deleteFolder && (
                  <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(showDeleteModal)}>
                    <i className="mdi mdi-24px mdi-trash-can"/>
                  </Button>
                )}
              </div>
            </FlexRow>
          </div>
        </HideWithoutPermission>
      </div>

      <DeleteFileTreeFolderModal
        show={isShowingDeleteModal}
        folder={folder}
        accessors={accessors}
        deleteFolder={deleteFolder?.delete || Promise.resolve}
        hide={hideDeleteModal}
      />
    </>
  );
};

export default FileTreeFolder;
