import {
  CreateProgramFolder,
  CreateProgramRequest,
  Program,
  UpdateProgramFolder,
  UpdateProgramRequest,
} from './models';
import { Action, ApiAction } from '../../models';
import { commonActions } from '../common/actions';
import { SuperAgent } from 'superagent';
import config from '../../config';

export const programsActions = {
  SELECT_PROGRAM: 'AVSafetySolutions/programs/SelectProgram',
  ASSIGN_TEMPLATE_TO_PROGRAM: 'AVSafetySolutions/programs/AssignTemplateToProgram',
  REMOVE_TEMPLATE_FROM_PROGRAM: 'AVSafetySolutions/programs/RemoveTemplateFromProgram',

  GET_ALL_PROGRAMS_REQUEST: 'AVSafetySolutions/programs/GetAllProgramsRequest',
  GET_ALL_PROGRAMS_SUCCESS: 'AVSafetySolutions/programs/GetAllProgramsSuccess',
  GET_ALL_PROGRAMS_FAILURE: 'AVSafetySolutions/programs/GetAllProgramsFailure',

  GET_PROGRAM_REQUEST: 'AVSafetySolutions/programs/GetProgramRequest',
  GET_PROGRAM_SUCCESS: 'AVSafetySolutions/programs/GetProgramSuccess',
  GET_PROGRAM_FAILURE: 'AVSafetySolutions/programs/GetProgramFailure',

  GET_PROGRAM_FOLDERS_REQUEST: 'AVSolutions/programs/GetProgramFoldersRequest',
  GET_PROGRAM_FOLDERS_SUCCESS: 'AVSolutions/programs/GetProgramFoldersSuccess',
  GET_PROGRAM_FOLDERS_FAILURE: 'AVSolutions/programs/GetProgramFoldersFailure',

  UPDATE_PROGRAM_REQUEST: 'AVSafetySolutions/programs/UpdateProgramRequest',
  UPDATE_PROGRAM_SUCCESS: 'AVSafetySolutions/programs/UpdateProgramSuccess',
  UPDATE_PROGRAM_FAILURE: 'AVSafetySolutions/programs/UpdateProgramFailure',

  INSERT_PROGRAM_REQUEST: 'AVSafetySolutions/programs/InsertProgramRequest',
  INSERT_PROGRAM_SUCCESS: 'AVSafetySolutions/programs/InsertProgramSuccess',
  INSERT_PROGRAM_FAILURE: 'AVSafetySolutions/programs/InsertProgramFailure',

  INSERT_PROGRAM_TEMPLATE_XREF_REQUEST: 'AVSafetySolutions/programs/InsertProgramTemplateXrefRequest',
  INSERT_PROGRAM_TEMPLATE_XREF_SUCCESS: 'AVSafetySolutions/programs/InsertProgramTemplateXrefSuccess',
  INSERT_PROGRAM_TEMPLATE_XREF_FAILURE: 'AVSafetySolutions/programs/InsertProgramTemplateXrefFailure',

  DELETE_PROGRAM_REQUEST: 'AVSafetySolutions/programs/DeleteProgramRequest',
  DELETE_PROGRAM_SUCCESS: 'AVSafetySolutions/programs/DeleteProgramSuccess',
  DELETE_PROGRAM_FAILURE: 'AVSafetySolutions/programs/DeleteProgramFailure',

  DELETE_PROGRAM_TEMPLATE_XREF_REQUEST: 'AVSafetySolutions/programs/DeleteProgramTemplateXrefRequest',
  DELETE_PROGRAM_TEMPLATE_XREF_SUCCESS: 'AVSafetySolutions/programs/DeleteProgramTemplateXrefSuccess',
  DELETE_PROGRAM_TEMPLATE_XREF_FAILURE: 'AVSafetySolutions/programs/DeleteProgramTemplateXrefFailure',

  UPDATE_PROGRAM_FOLDER_REQUEST: 'AVSafetySolutions/programs/UpdateProgramFolderRequest',
  UPDATE_PROGRAM_FOLDER_SUCCESS: 'AVSafetySolutions/programs/UpdateProgramFolderSuccess',
  UPDATE_PROGRAM_FOLDER_FAILURE: 'AVSafetySolutions/programs/UpdateProgramFolderFailure',

  INSERT_PROGRAM_FOLDER_REQUEST: 'AVSafetySolutions/programs/InsertProgramFolderRequest',
  INSERT_PROGRAM_FOLDER_SUCCESS: 'AVSafetySolutions/programs/InsertProgramFolderSuccess',
  INSERT_PROGRAM_FOLDER_FAILURE: 'AVSafetySolutions/programs/InsertProgramFolderFailure',

  DELETE_PROGRAM_FOLDER_REQUEST: 'AVSafetySolutions/programs/DeleteProgramFolderRequest',
  DELETE_PROGRAM_FOLDER_SUCCESS: 'AVSafetySolutions/programs/DeleteProgramFolderSuccess',
  DELETE_PROGRAM_FOLDER_FAILURE: 'AVSafetySolutions/programs/DeleteProgramFolderFailure',
};

export const selectProgram = (program?: Program): Action => {
  return {
    type: programsActions.SELECT_PROGRAM,
    payload: {
      program,
    },
  };
};

export const getProgram = (program: Program): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/programs/${program.programId}`);
      },
      beforeRequest: (): Action => ({ type: programsActions.GET_PROGRAM_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.GET_PROGRAM_SUCCESS, payload: { program: data } }),
      failure: (response: any): Action => ({ type: programsActions.GET_PROGRAM_FAILURE, payload: { ...response } }),
    },
  };
};

export const getProgramFolders = (programId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/programs/${programId}/folders`);
      },
      beforeRequest: (): Action => ({ type: programsActions.GET_PROGRAM_FOLDERS_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.GET_PROGRAM_FOLDERS_SUCCESS, payload: { programId, folders: data } }),
      failure: (response: any): Action => ({ type: programsActions.GET_PROGRAM_FOLDERS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getAllPrograms = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/programs`);
      },
      beforeRequest: (): Action => ({ type: programsActions.GET_ALL_PROGRAMS_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.GET_ALL_PROGRAMS_SUCCESS, payload: { programs: data } }),
      failure: (response: any): Action => ({ type: programsActions.GET_ALL_PROGRAMS_FAILURE, payload: { ...response } }),
    },
  };
};

export const updateProgram = (program: UpdateProgramRequest, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${config.baseUrl}/api/v1/programs`)
          .send(program);
      },
      beforeRequest: (): Action => ({ type: programsActions.UPDATE_PROGRAM_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.UPDATE_PROGRAM_SUCCESS, payload: { program: data } }),
      successCallback,
      failure: (response: any): Action => ({ type: programsActions.UPDATE_PROGRAM_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertProgram = (program: CreateProgramRequest, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${config.baseUrl}/api/v1/programs`)
          .send(program);
      },
      beforeRequest: (): Action => ({ type: programsActions.INSERT_PROGRAM_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.INSERT_PROGRAM_SUCCESS, payload: { program: data } }),
      successCallback,
      failure: (response: any): Action => ({ type: programsActions.INSERT_PROGRAM_FAILURE, payload: { ...response } }),
    },
  };
};

export const updateProgramFolder = (updateProgramFolder: UpdateProgramFolder, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${config.baseUrl}/api/v1/programFolders/${updateProgramFolder.programFolderId}`)
          .send(updateProgramFolder);
      },
      beforeRequest: (): Action => ({ type: programsActions.UPDATE_PROGRAM_FOLDER_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.UPDATE_PROGRAM_FOLDER_SUCCESS, payload: { programFolder: data } }),
      successCallback,
      failure: (response: any): Action => ({ type: programsActions.UPDATE_PROGRAM_FOLDER_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertProgramFolder = (createProgramFolder: CreateProgramFolder, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${config.baseUrl}/api/v1/programFolders`)
          .send(createProgramFolder);
      },
      beforeRequest: (): Action => ({ type: programsActions.INSERT_PROGRAM_FOLDER_REQUEST }),
      success: (data: any): Action => ({ type: programsActions.INSERT_PROGRAM_FOLDER_SUCCESS, payload: { programFolder: data } }),
      successCallback,
      failure: (response: any): Action => ({ type: programsActions.INSERT_PROGRAM_FOLDER_FAILURE, payload: { ...response } }),
    },
  };
};

export const deleteProgramFolder = (programFolderId: number, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.del(`${config.baseUrl}/api/v1/programFolders/${programFolderId}`);
      },
      beforeRequest: (): Action => ({ type: programsActions.DELETE_PROGRAM_FOLDER_REQUEST }),
      success: (): Action => ({ type: programsActions.DELETE_PROGRAM_FOLDER_SUCCESS, payload: { programFolderId } }),
      successCallback,
      failure: (response: any): Action => ({ type: programsActions.DELETE_PROGRAM_FOLDER_FAILURE, payload: { ...response } }),
    },
  };
};

export const deleteProgram = (programId: number, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.del(`${config.baseUrl}/api/v1/programs/${programId}`);
      },
      beforeRequest: (): Action => ({ type: programsActions.DELETE_PROGRAM_REQUEST }),
      success: (): Action => ({ type: programsActions.DELETE_PROGRAM_SUCCESS, payload: { programId: programId } }),
      successCallback,
      failure: (response: any): Action => ({ type: programsActions.DELETE_PROGRAM_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertProgramTemplateXref = (programId: number, templateId: number, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${config.baseUrl}/api/v1/programs/${programId}/templates/${templateId}`);
      },
      beforeRequest: () => ({ type: programsActions.INSERT_PROGRAM_TEMPLATE_XREF_REQUEST, payload: { programId, templateId } }),
      success: () => ({ type: programsActions.INSERT_PROGRAM_TEMPLATE_XREF_SUCCESS, payload: { programId, templateId } }),
      successCallback,
      failure: (response) => ({ type: programsActions.INSERT_PROGRAM_TEMPLATE_XREF_FAILURE, payload: { ...response, programId, templateId } }),
    },
  };
};

export const deleteProgramTemplateXref = (programId: number, templateId: number, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${config.baseUrl}/api/v1/programs/${programId}/templates/${templateId}`);
      },
      beforeRequest: () => ({ type: programsActions.DELETE_PROGRAM_TEMPLATE_XREF_REQUEST, payload: { programId, templateId } }),
      success: () => ({ type: programsActions.DELETE_PROGRAM_TEMPLATE_XREF_SUCCESS, payload: { programId, templateId } }),
      successCallback,
      failure: (response) => ({ type: programsActions.DELETE_PROGRAM_TEMPLATE_XREF_FAILURE, payload: { ...response, programId, templateId } }),
    },
  };
};
