import React, { useEffect, useState } from 'react';

import { DebounceInput, FlexColumn, FlexLoader, FlexRow, Panel, VerticalOnlyScrollPanel } from 'Components';
import { ShareBoardCategory, ShareBoardFile } from 'Modules/shareBoard/models';
import useShareBoardCategories from '../../hooks/useShareBoardCategories';
import ShareBoardCategoryListView from './ShareBoardCategoryListView';
import { Button, ButtonGroup } from 'reactstrap';
import ShareBoardCategoryTileView from './ShareBoardCategoryTileView';
import { localStorageHelper } from '../../helpers/storageHelpers';

interface Props {
  allShareBoardFiles: ShareBoardFile[];
  setShareBoardCategory: (category: ShareBoardCategory | null) => void;
}

enum ViewMode {
  Tile = 'Tile',
  List = 'List',
}

const localStorageKey = 'ShareBoardCategoryViewMode';

const SelectCategoryPanel = ({ allShareBoardFiles, ...props }: Props) => {
  const [ searchText, setSearchText ] = useState<string>('');
  const [ viewMode, setViewMode ] = useState<ViewMode>(localStorageHelper.get(localStorageKey) || ViewMode.List);

  useEffect(() => {
    localStorageHelper.set(localStorageKey, viewMode);
  }, [ viewMode ]);

  const { isLoadingAllShareBoardCategories, filteredCategories } = useShareBoardCategories({ searchText });

  if (isLoadingAllShareBoardCategories) {
    return (
      <FlexLoader />
    );
  }


  return (
    <Panel>
      <FlexColumn className="p-2">
        <FlexRow alignCenter childSpacingX={1}>
          <div className="flex-1">
            <DebounceInput
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              className="form-control form-control-lg"
              type="text"
              placeholder="Search for a Category..."
            />
          </div>

          <FlexColumn alignCenter>
            <ButtonGroup>
              <Button color={viewMode === ViewMode.List ? 'primary' : 'light'} onClick={() => setViewMode(ViewMode.List)}>List</Button>
              <Button color={viewMode === ViewMode.Tile ? 'primary' : 'light'} onClick={() => setViewMode(ViewMode.Tile)}>Tile</Button>
            </ButtonGroup>
          </FlexColumn>
        </FlexRow>
      </FlexColumn>

      <VerticalOnlyScrollPanel className="p-2">
        {viewMode === ViewMode.Tile ? (
          <ShareBoardCategoryTileView
            categories={filteredCategories}
            allShareBoardFiles={allShareBoardFiles}
            setShareBoardCategory={props.setShareBoardCategory}
          />
        ) : (
          <ShareBoardCategoryListView
            categories={filteredCategories}
            allShareBoardFiles={allShareBoardFiles}
            setShareBoardCategory={props.setShareBoardCategory}
          />
        )}
      </VerticalOnlyScrollPanel>
    </Panel>
  );
};

export default SelectCategoryPanel;
