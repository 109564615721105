import _ from 'lodash';
import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { CreateOperatorAttributeType, ModifyOperatorAttributeType, OperatorAttributeType, operatorAttributesActions, UpdateOperatorAttributeType } from 'Modules/operatorAttributes';

const useOperatorAttributeTypeForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingOperatorAttributeType = useStateSelector((state) => state.operatorAttributes.loading.isInsertingOperatorAttributeType);
  const isUpdatingOperatorAttributeType = useStateSelector((state) => state.operatorAttributes.loading.isUpdatingOperatorAttributeType);
  const isDeletingOperatorAttributeType = useStateSelector((state) => state.operatorAttributes.loading.isDeletingOperatorAttributeType);

  function validateOperatorAttributeType(operatorAttributeType: ModifyOperatorAttributeType) {
    const errors: string[] = [];

    return errors;
  }

  function insertOperatorAttributeType(operatorAttributeType: CreateOperatorAttributeType) {
    const errorMessages = validateOperatorAttributeType(operatorAttributeType);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      throw new Error(errorMessages[0]);
    }

    return dispatch(operatorAttributesActions.async.insertOperatorAttributeType(operatorAttributeType))
      .then((operatorAttributeType: OperatorAttributeType) => {
        toast.success('Operator Attribute Type successfully created.');
        return operatorAttributeType;
      });
  }

  function updateOperatorAttributeType(operatorAttributeTypeId: number, operatorAttributeType: UpdateOperatorAttributeType) {
    const errorMessages = validateOperatorAttributeType(operatorAttributeType);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(operatorAttributesActions.async.updateOperatorAttributeType(operatorAttributeTypeId, operatorAttributeType))
      .then((operatorAttributeType: OperatorAttributeType) => {
        toast.success('Operator Attribute Type successfully saved.');
        return operatorAttributeType;
      });
  }

  function deleteOperatorAttributeType(operatorAttributeType: OperatorAttributeType) {
    return dispatch(operatorAttributesActions.async.deleteOperatorAttributeType(operatorAttributeType.id))
      .then((operatorAttributeType: OperatorAttributeType) => {
        toast.success('Operator Attribute Type successfully deactivated.');
        return operatorAttributeType;
      });
  }

  function cancelOperatorAttributeTypeModification() {
    dispatch(operatorAttributesActions.cancelOperatorAttributeTypeModification());
  }

  return {
    insertOperatorAttributeType,
    updateOperatorAttributeType,
    deleteOperatorAttributeType,
    cancelOperatorAttributeTypeModification,
    loading: {
      isInsertingOperatorAttributeType,
      isUpdatingOperatorAttributeType,
      isDeletingOperatorAttributeType,
    },
  };
};

export default useOperatorAttributeTypeForm;
