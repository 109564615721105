import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Collapse } from 'reactstrap';
import classnames from 'classnames';
import TaskFileDropzone from './TaskFileDropzone';
import { AssignedTaskModel } from '../../models';

interface Props {
  task: AssignedTaskModel;
  defaultOpen?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onFileUploaded?: () => void;
}

const CollapsableTaskFileDropzone = ({ task, open, defaultOpen, ...props }: Props) => {
  const [ isUploadDropzoneOpen, setIsUploadDropzoneOpen ] = useState(!!defaultOpen);

  useEffect(() => {
    if(_.isUndefined(open)) {
      return;
    }

    setIsUploadDropzoneOpen(open);
  }, [ open ]);

  function handleToggle() {
    if(_.isUndefined(open)) {
      setIsUploadDropzoneOpen(!isUploadDropzoneOpen);
      return;
    }

    if(props.setOpen) {
      props.setOpen(!isUploadDropzoneOpen);
    }
  }

  return (
    <>
      <Button
        block
        size="sm"
        className="mb-1"
        color="light"
        onClick={handleToggle}
      >
        <i className={classnames('mdi', { 'mdi-chevron-double-down': isUploadDropzoneOpen, 'mdi-chevron-double-up': !isUploadDropzoneOpen })} /> {task.numFiles > 0 ? 'Upload More Files' : 'Upload Files'} <i className={classnames('mdi', { 'mdi-chevron-double-down': isUploadDropzoneOpen, 'mdi-chevron-double-up': !isUploadDropzoneOpen })} />
      </Button>
      <Collapse className="mb-2" isOpen={isUploadDropzoneOpen}>
        <TaskFileDropzone task={task} onFileUploaded={props.onFileUploaded} />
      </Collapse>
    </>
  );
};

export default CollapsableTaskFileDropzone;
