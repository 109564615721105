/* eslint-disable no-undef */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';
import { getPasswordResetUrl } from '../Modules/users/actions';
import { RootState } from '../Modules/reducers';
import { LoginUserModel, SidebarType } from '../models';
import { useAuth0 } from '@auth0/auth0-react';
import UserNotificationButton from '../AviationSafetySolutions/Notifications/UserNotificationButton';
import ChatSidebar from '../AviationSafetySolutions/ChatSidebar';
import RightSidebar from './RightSidebar';
import NotificationSidebar from '../AviationSafetySolutions/NotificationSidebar';
import OperatorMessageUserNotificationButton
  from '../AviationSafetySolutions/Notifications/OperatorMessageUserNotificationButton';
import { siteActions } from '../Modules/site';
import { markAllOperatorMessagesRead } from '../Modules/messages/actions';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import useStateSelector from '../hooks/useStateSelector';
import UploadProfilePictureModal from '../Components/UploadProfilePictureModal';
import { useModalState } from 'hooks';
import { BackgroundImage, FlexRow } from 'Components';
import UploadOperatorProfilePictureModal from '../Components/UploadOperatorProfilePictureModal';

interface LocalProps {}

interface StateProps {
  user?: LoginUserModel;
  sidebarType?: SidebarType;
}

interface DispatchProps {
  getPasswordResetUrl: typeof getPasswordResetUrl;
  setSidebarType: typeof siteActions.setSidebarType;
  markAllOperatorMessagesRead: typeof markAllOperatorMessagesRead;
}

type Props = LocalProps & StateProps & DispatchProps;

const Navbar = ({ user, sidebarType, ...props }: Props) => {
  const { logout } = useAuth0();
  const { isShowing: isShowingProfilePictureModal, show: showProfilePictureModal, hide: hideProfilePictureModal }= useModalState();
  const [ settingOperatorProfilePictureId, setSettingOperatorProfilePictureId ] = useState<number>();
  const dispatch = useDispatch();
  const selectedOperator = useStateSelector(state => state.site.selectedOperator);

  async function logoutUser () {
    logout({
      returnTo: window.location.origin,
    });
  }

  function toggleLeftSidebar() {
    document.body.classList.toggle('sidebar-enable');
  }

  function resetPassword () {
    props.getPasswordResetUrl((passwordResetUrl) => {
      window.location.href = passwordResetUrl;
    });
  }

  function openChatSidebar () {
    props.setSidebarType(SidebarType.Chat);
    props.markAllOperatorMessagesRead();
  }

  function openNotificationSidebar () {
    props.setSidebarType(SidebarType.Notification);
  }

  function closeSidebar () {
    props.setSidebarType(undefined);
  }

  function clearOperator () {
    dispatch(siteActions.setSelectedOperator(undefined));
  }

  return (
    <React.Fragment>
      <div className="navbar-custom d-flex flex-row">
        {/* LOGO */}
        <div className="logo-box">
          <Link to="/" className="logo text-center">
            <span className="logo-lg">
              <img src="./assets/img/logo-lg.png" alt="" height={40} />
            </span>
            <span className="logo-sm">
              <img src="/assets/img/logo-sm.png" alt="" height={40} />
            </span>
          </Link>
        </div>
        <ul className="d-block d-md-none list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button className="button-menu-trigger button-menu-mobile toggler waves-effect waves-light" onClick={toggleLeftSidebar}>
              <i className="fa fa-bars" />
            </button>
          </li>
        </ul>
        <div className="logo-box-sm">
          <Link to="/" className="logo text-center">
            <span className="logo-box-sm-static">
              <img src="/assets/img/logo-sm.png" alt="" height={40} />
            </span>
          </Link>
        </div>

        <div className="d-flex flex-row flex">
          {/*<div className="d-none d-md-flex flex-row flex justify-content-center align-items-center">*/}
          {/*  <h5 className="text-muted">Skyla Software</h5>*/}
          {/*</div>*/}
        </div>

        <ul className="list-unstyled topnav-menu mb-0">
          <li>
            <UserNotificationButton openSidebar={openNotificationSidebar} />
          </li>

          {selectedOperator && (
            <>
              <li>
                <OperatorMessageUserNotificationButton openSidebar={openChatSidebar} />
              </li>

              <li className="dropdown notification-list">
                <UncontrolledButtonDropdown>
                  <DropdownToggle className="nav-link waves-effect waves-light pr-0" color="anchor">
                    <FlexRow alignCenter childSpacingX={1}>
                      {selectedOperator.imageFile && (
                        <BackgroundImage
                          className="rounded-circle"
                          style={{ width: 30, height: 30 }}
                          src={selectedOperator.imageFile.sasUrl}
                        />
                      )}
                      <span>{selectedOperator.name}</span>
                      <i className="mdi mdi-chevron-down" />
                    </FlexRow>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={clearOperator}>Change Operator</DropdownItem>
                    <DropdownItem onClick={() => setSettingOperatorProfilePictureId(selectedOperator.operatorId)}>Set Profile Picture</DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </li>
            </>
          )}

          <li className="user-dropdown dropdown notification-list">
            <UncontrolledButtonDropdown>
              <DropdownToggle className="nav-link waves-effect waves-light mr-0" color="anchor">
                <FlexRow alignCenter childSpacingX={1}>
                  {user?.profilePictureFile && (
                    <BackgroundImage
                      className="rounded-circle"
                      style={{ width: 30, height: 30 }}
                      src={user.profilePictureFile.sasUrl}
                    />
                  )}
                  <span>{user?.displayName}</span>
                  <i className="mdi mdi-chevron-down" />
                </FlexRow>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={showProfilePictureModal}><i className="mdi mdi-file-image" /> <span>Set Profile Picture</span></DropdownItem>
                <DropdownItem onClick={resetPassword}><i className="mdi mdi-lock-reset" /> <span>Reset Password</span></DropdownItem>
                <DropdownItem onClick={logoutUser}><i className="mdi mdi-logout" /> <span>Logout</span></DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </li>
        </ul>
      </div>

      <RightSidebar isOpen={!!sidebarType} closeSidebar={closeSidebar}>
        {sidebarType === SidebarType.Chat && <ChatSidebar closeSidebar={closeSidebar} />}
        {sidebarType === SidebarType.Notification && <NotificationSidebar closeSidebar={closeSidebar} />}
      </RightSidebar>

      <UploadProfilePictureModal
        show={isShowingProfilePictureModal}
        userId={user?.userId!}
        hide={hideProfilePictureModal}
      />

      {settingOperatorProfilePictureId && (
        <UploadOperatorProfilePictureModal
          show
          operatorId={settingOperatorProfilePictureId}
          hide={() => setSettingOperatorProfilePictureId(undefined)}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ site }: RootState) => {
  return {
    user: site.user,
    sidebarType: site.sidebarType,
  };
};

const mapDispatchToProps = {
  getPasswordResetUrl,
  setSidebarType: siteActions.setSidebarType,
  markAllOperatorMessagesRead,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(Navbar);
