import { LoginUserModel } from 'models';
import useLoggedInUser from 'hooks/useLoggedInUser';

const useLoggedInUserOrThrow = (): LoginUserModel => {
  const loggedInUser = useLoggedInUser();

  if(!loggedInUser) {
    throw new Error('Logged in user expected but not selected.');
  }

  return loggedInUser;
};

export default useLoggedInUserOrThrow;
