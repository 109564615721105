import React, { ReactNode, useState } from 'react';

import { AuditTemplate } from 'models';
import ActionType from 'constants/ActionType';
import ModifyTemplateTaskModal from '../../Templates/ModifyTemplateTaskModal';

interface ProvidedProps {
  showCreateTaskModal: () => void;
  showCreateParentTaskModal: () => void;
  hideModal: () => void;
}

interface Props {
  template: AuditTemplate;
  weekIndex?: number;
  children?: (providedProps: ProvidedProps) => ReactNode;
}

enum ShowingTaskModalType {
  NewTask,
  NewParentTask
}

const WithModifyTemplateTaskModal = ({ template, weekIndex, children }: Props) => {
  const [ showingTaskModalType, setShowingTaskModalType ] = useState<ShowingTaskModalType | undefined>();

  function showCreateTaskModal() {
    setShowingTaskModalType(ShowingTaskModalType.NewTask);
  }

  function showCreateParentTaskModal() {
    setShowingTaskModalType(ShowingTaskModalType.NewParentTask);
  }

  function hideModal() {
    setShowingTaskModalType(undefined);
  }

  return (
    <>
      {children?.({
        showCreateTaskModal,
        showCreateParentTaskModal,
        hideModal,
      })}

      <ModifyTemplateTaskModal
        show={showingTaskModalType === ShowingTaskModalType.NewTask}
        template={template}
        templateTask={{ templateTaskId: 0, weekIndex: weekIndex, actionTypeId: ActionType.Check }}
        hide={hideModal}
      />

      <ModifyTemplateTaskModal
        show={showingTaskModalType === ShowingTaskModalType.NewParentTask}
        template={template}
        templateTask={{ templateTaskId: 0, weekIndex: weekIndex, isParent: true, actionTypeId: ActionType.Check }}
        hide={hideModal}
      />
    </>
  );
};

export default WithModifyTemplateTaskModal;
