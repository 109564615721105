import React, { CSSProperties, useState } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import toastr from 'toastr';

import { useOnModalShow, usePollOptions, useStateSelector, useThunkDispatch } from 'hooks';
import { FlexRow, LoadableButton } from 'Components';
import { PollOption, pollsActions } from '../../Modules/polls/index';
import Select from 'react-select';

interface Props {
  show: boolean;
  pollId: number;
  hide: () => void;
}

const selectStyles = {
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 3000 }),
};

const MergePollOptionsModal = ({ show, pollId, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const isInsertingFeedback = useStateSelector(state => state.feedback.loading.isInsertingFeedback);
  const [ pollOptions, isLoadingPollOptions ] = usePollOptions(pollId);
  const [ optionToKeep, setOptionToKeep ] = useState<PollOption>();
  const [ optionToDelete, setOptionToDelete ] = useState<PollOption>();

  useOnModalShow(show)
    .then(() => {
      setOptionToKeep(undefined);
      setOptionToDelete(undefined);
    });

  function submit() {
    if(!optionToKeep || !optionToDelete) {
      return;
    }

    dispatch(pollsActions.async.mergePollOptions(pollId, optionToKeep.id, optionToDelete.id))
      .then(() => {
        toastr.success('Poll Options Merged Successfully');
        props.hide();
      });
  }

  return (
    <Modal isOpen={show} toggle={props.hide}>
      <ModalHeader>Merge Poll Options</ModalHeader>
      <ModalBody>
        <Label>Target:</Label>
        <Select<PollOption, false>
          placeholder="Select a Poll Option to Merge Into..."
          menuPortalTarget={document.body}
          value={optionToKeep || null}
          getOptionValue={(option) => option.id.toString()}
          getOptionLabel={(option) => option.name}
          options={pollOptions}
          isLoading={isLoadingPollOptions}
          styles={selectStyles as any}
          onChange={(option) => setOptionToKeep(option || undefined)}
        />

        <hr />

        <Label>Source (will be deleted):</Label>
        <Select<PollOption, false>
          placeholder="Select a Poll Option to be Merged..."
          menuPortalTarget={document.body}
          value={optionToDelete || null}
          isDisabled={!optionToKeep}
          getOptionValue={(option) => option.id.toString()}
          getOptionLabel={(option) => option.name}
          isLoading={isLoadingPollOptions}
          options={pollOptions.filter((option) => option.id !== optionToKeep?.id)}
          styles={selectStyles as any}
          onChange={(option) => setOptionToDelete(option || undefined)}
        />
      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div>
            <Button color="light" onClick={props.hide}>Close</Button>
          </div>
          <div>
            <LoadableButton
              onClick={submit}
              isLoading={isInsertingFeedback}
              color="primary"
              disabled={!optionToKeep || !optionToDelete}
            >
              Submit
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default MergePollOptionsModal;
