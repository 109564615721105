import React from 'react';

import appConfig from 'config';
import { useThunkDispatch } from 'hooks';
import { PollCategory, pollsActions } from 'Modules/polls/index';

import CommonSearch, { CommonSearchProps } from './CommonSearch';

interface Props extends CommonSearchProps<PollCategory> {
  debounceWait?: number;
  minInputLength?: number;
}

const PollCategorySearch = ({ minInputLength = 0, debounceWait = 300, ...props }: Props) => {
  const dispatch = useThunkDispatch();

  async function searchForData(term: string) {
    const paginatedResult = await dispatch(pollsActions.async.searchAllPollCategories({
      ...appConfig.defaultDropdownPagination,
      filter: term,
    }));

    return paginatedResult.results;
  }

  return (
    <CommonSearch
      {...props}
      modal
      searchOnFocus
      getOptionLabel={((option: PollCategory) => option.name) as any}
      getOptionValue={((option: PollCategory) => option.id) as any}
      debounceWait={debounceWait}
      minInputLength={minInputLength}
      searchForData={searchForData}
    />
  );
};

export default PollCategorySearch;
