import React from 'react';
import { ContentState } from 'draft-js';
import { ContentBlock } from 'react-draft-wysiwyg';

import File from './File';
import Video from './Video';
import { MessageFile } from 'Components/index';

interface Props {
  contentState: ContentState;
  block: ContentBlock;
}

const CustomBlock = ({ contentState, block }: Props) => {
  const entity = contentState.getEntity(block.getEntityAt(0));
  const data = entity.getData();
  const type = entity.getType();

  if(type === 'file') {
    const { referenceFileId }: { referenceFileId: number } = data;

    return (
      <File referenceFileId={referenceFileId} />
    );
  }

  if(type === 'operatorGroupMessage') {
    const { id, operatorGroupId }: { id: number; operatorGroupId: number; } = data;

    return (
      <MessageFile id={id} getRequest={(superagent) => superagent.get(`/api/v1/operatorGroups/${operatorGroupId}/messages/files/${id}/streamUrl`)} />
    );
  }

  if(type === 'taskMessage') {
    const { id, taskId }: { id: number; taskId: number; } = data;

    return (
      <MessageFile id={id} getRequest={(superagent) => superagent.get(`/api/v1/tasks/${taskId}/messagesFiles/${id}/streamUrl`)} />
    );
  }

  if(type === 'operatorMessage') {
    const { id }: { id: number; } = data;

    return (
      <MessageFile id={id} getRequest={(superagent) => superagent.get(`/api/v1/operators/current/messages/files/${id}/streamUrl`)} />
    );
  }

  if (type === 'video') {
    const { referenceFileId }: { referenceFileId: number } = data;

    return (
      <Video referenceFileId={referenceFileId} />
    );
  }

  return null;
};

export default CustomBlock;
