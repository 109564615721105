import React from 'react';
import { AssignedTaskModel } from '../../../models';
import BaseDashboardTask from '../Components/BaseDashboardTask';

interface Props {
  task: AssignedTaskModel;
  onTaskClick: (task: AssignedTaskModel) => void;
}

const SafetyCoachDashboardTask = ({ task, ...props }: Props) => {
  return (
    <>
      <BaseDashboardTask includeAction={false} task={task} onTaskClick={props.onTaskClick} />
    </>
  );
};

export default SafetyCoachDashboardTask;
