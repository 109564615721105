import React from 'react';
import _ from 'lodash';
import { ResponsivePie } from '@nivo/pie';

import { sOrNoS } from 'helpers/listHelpers';
import { PollResultSummary } from 'Modules/polls';
import { schemeTableau10 } from 'core/index';

interface Props {
  results: PollResultSummary[];
  onOptionClick?: (optionId: number) => void;
}

const PollPieChart = ({ results, ...props }: Props) => {
  const optionGroups = _.groupBy(results, r => r.optionId);

  let index = 0;
  const pieChartData = _.map(optionGroups, (options) => {
    const [ first ] = options;

    return {
      id: first.optionId,
      label: first.optionName,
      value: options.length,
      color: schemeTableau10[index++ % schemeTableau10.length],
    };
  });

  return (
    <ResponsivePie
      data={pieChartData}
      colors={bar => bar.data.color}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      activeOuterRadiusOffset={8}
      onClick={({ id }: any) => props.onOptionClick?.(id)}
      tooltip={({ datum: { value, color } }) => (
        <div
          style={{
            padding: 12,
            color,
            background: '#222222',
          }}
        >
          <strong>
            {value} Response{sOrNoS(value)}
          </strong>
        </div>
      )}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.2,
          ],
        ],
      }}
      arcLinkLabel="label"
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
    />
  );
};

export default PollPieChart;
