import React from 'react';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import classnames from 'classnames';
// @ts-ignore
import path from 'path-browserify';

import { sOrNoS } from 'helpers/listHelpers';
import { TreeNode } from 'Modules/library/models';
import { Div, FlexRow } from 'Components';
import { useModalState } from 'hooks';

import FileTreeBreadcrumb from './FileTreeBreadcrumb';
import { MoveToFolderFileAccessors } from '../LibraryFolder/MoveToFolderFileTable';
import MoveToFolderModal from '../LibraryFolder/MoveToFolderModal';

interface Props<T> {
  currentNode: TreeNode<T>;
  setCurrentFilePath: (filePath: string) => void;
  addFolder: (filePath: string) => void;
  selectAll: (items: T[]) => void;
  selectNone: () => void;
  selectedFiles: T[];
  hiddenFolders?: {
    isShowing: boolean;
    toggle: () => void;
  };
  upload?: {
    startUploading: () => void;
  };
  defaultFolders?: {
    addFolder: (filePath: string) => void;
  };
  deleteFiles?: {
    delete: (items: T[]) => void;
  };
  accessors: MoveToFolderFileAccessors<T>;
  moveFiles: (directoryPath: string, files: T[]) => Promise<void>;
  permissions: {
    canCreateFolder: (directoryPath: string) => boolean;
  }
}

const FileTreeBreadcrumbs = <T extends any>({ currentNode, hiddenFolders, defaultFolders, accessors, selectedFiles, upload, deleteFiles, permissions, ...props }: Props<T>) => {
  const { isShowing: isShowingMoveFilesModal, show: showMoveFilesModal, hide: hideMoveFilesModal } = useModalState();

  const crumbs = [];

  let tempNode = currentNode.parent;
  while (tempNode) {
    crumbs.push((
      <React.Fragment key={tempNode.filePath}>
        <FileTreeBreadcrumb node={tempNode} setFilePath={(node) => props.setCurrentFilePath(node.filePath)}/>

        <i className="mdi mdi-24px mdi-chevron-right"/>
      </React.Fragment>
    ));

    tempNode = tempNode.parent;
  }

  crumbs.reverse();

  function addNewFolder() {
    (async () => {
      const { dismiss, value: folderName } = await Swal.fire({
        title: 'Add Folder',
        text: 'Provide a name for the folder:',
        input: 'text',
        inputValue: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Add Folder',
        inputValidator: (value: string) => {
          if (!(value || '').trim()) {
            return 'You must enter a valid folder name';
          }

          return null;
        },
      });

      if (dismiss) {
        return;
      }

      const newFolderFilePath = path.join(currentNode.filePath, folderName, '/');
      props.addFolder(newFolderFilePath);
    })();
  }

  function addNewDefaultFolder() {
    if(!defaultFolders) {
      return;
    }

    (async () => {
      const { dismiss, value: folderName } = await Swal.fire({
        title: 'Add Default Folder',
        text: 'Provide a name for the default folder:',
        input: 'text',
        inputValue: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Add Folder',
        inputValidator: (value: string) => {
          if (!(value || '').trim()) {
            return 'You must enter a valid folder name';
          }

          return null;
        },
      });

      if (dismiss) {
        return;
      }

      const newFolderFilePath = path.join(currentNode.filePath, folderName, '/');
      defaultFolders.addFolder(newFolderFilePath);
    })();
  }

  function selectAll() {
    const files = _.compact(_.map(currentNode.children, (c) => c.data));

    props.selectAll(files);
  }

  function deleteSelectedFiles() {
    if(!deleteFiles) {
      return;
    }

    if (_.isEmpty(selectedFiles)) {
      return;
    }

    (async () => {
      const { dismiss } = await Swal.fire({
        title: `Delete Selected File${sOrNoS(selectedFiles)}`,
        inputValue: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `Delete File${sOrNoS(selectedFiles)}`,
      });

      if (dismiss) {
        return;
      }

      deleteFiles.delete(selectedFiles);
    })();
  }

  return (
    <>
      <FlexRow justifyBetween alignCenter childSpacingX={2}>
        <FlexRow alignCenter childSpacingX={1}>
          {crumbs}
          <span>{currentNode.name}</span>
        </FlexRow>

        <Div childSpacingX={1}>
          {permissions.canCreateFolder(currentNode.filePath) && (
            <UncontrolledButtonDropdown>
              <DropdownToggle color="light" onClick={addNewFolder}>
                Add Folder
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Add New Folder...</DropdownItem>
                {defaultFolders && <DropdownItem onClick={addNewDefaultFolder}>Add New Default Folder...</DropdownItem>}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}

          {upload && (
            <Button
              color="light"
              onClick={upload.startUploading}
            >
              Upload...
            </Button>
          )}

          <UncontrolledButtonDropdown>
            <DropdownToggle color="light">
              Select <i className="mdi mdi-menu-down"/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={props.selectNone}>
                None
              </DropdownItem>
              <DropdownItem onClick={selectAll}>
                All
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <Button
            color="light"
            disabled={_.isEmpty(selectedFiles)}
            onClick={showMoveFilesModal}
          >
            Move {_.size(selectedFiles)} File{sOrNoS(selectedFiles)} Here
          </Button>

          {deleteFiles && (
            <Button
              color="light"
              disabled={_.isEmpty(selectedFiles)}
              onClick={deleteSelectedFiles}
            >
              Delete {_.size(selectedFiles)} File{sOrNoS(selectedFiles)}
            </Button>
          )}

          {!!hiddenFolders && (
            <UncontrolledButtonDropdown>
              <DropdownToggle color="light">
                More... <i className="mdi mdi-menu-down"/>
              </DropdownToggle>
              <DropdownMenu right>
                {hiddenFolders && (
                  <DropdownItem onClick={hiddenFolders.toggle}>
                    <i className={classnames('mdi', {
                      'mdi-checkbox-blank-outline': !hiddenFolders.isShowing,
                      'mdi-checkbox-marked-outline': hiddenFolders.isShowing,
                    })}/> Show Hidden
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
        </Div>
      </FlexRow>

      <MoveToFolderModal
        show={isShowingMoveFilesModal}
        directoryPath={currentNode.filePath}
        libraryFiles={selectedFiles}
        moveFiles={props.moveFiles}
        accessors={accessors}
        hide={hideMoveFilesModal}
      />
    </>
  );
};

export default FileTreeBreadcrumbs;
