import React from 'react';
import _ from 'lodash';
import { FormGroup, Label } from 'reactstrap';

import { getValueByKey } from 'core';
import { useOperatorAttributeTypes } from 'hooks';
import { DebounceInput, FlexLoader } from 'Components';
import { ModifyOperatorAttribute, OperatorAttribute } from 'Modules/operatorAttributes/index';

interface Props {
  operatorAttributesByType: {[operatorAttributeTypeId: number]: OperatorAttribute};
  operatorAttributeTypeValues: {[operatorAttributeTypeId: number]: Partial<ModifyOperatorAttribute>};
  setValues: (attributeTypeId: number, values: Partial<ModifyOperatorAttribute>) => void;
}

const OperatorProfileFormControls = ({ operatorAttributesByType, operatorAttributeTypeValues, ...props }: Props) => {
  const [ operatorAttributeTypes, isLoadingAttributeTypes ] = useOperatorAttributeTypes();

  if(isLoadingAttributeTypes) {
    return (
      <FlexLoader />
    );
  }

  return (
    <>
      {
        _.map(operatorAttributeTypes, (operatorAttributeType) => {
          const existingValue = getValueByKey(operatorAttributesByType, operatorAttributeType.id);
          const workingValue = getValueByKey(operatorAttributeTypeValues, operatorAttributeType.id);
          const resolvedValue = existingValue?.value ?? workingValue?.value;

          return (
            <FormGroup key={operatorAttributeType.id}>
              <Label>{operatorAttributeType.name}</Label>

              <DebounceInput value={resolvedValue || ''} onChange={(e) => props.setValues(operatorAttributeType.id, { value: e.target.value })} />
            </FormGroup>
          );
        })
      }
    </>
  );
};

export default OperatorProfileFormControls;
