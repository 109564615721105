import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../Modules/reducers';
import { AssignedTaskModel } from '../../models';
import { getTask, setGlobalSelectedTask } from '../../Modules/tasks/actions';
import TaskModal, { TaskTab } from './TaskModal';
import queryString from 'query-string';
import { initialSearchQuery } from '../../App/App';

interface LocalProps {
  show: boolean;
  taskId?: number;
  defaultTab?: TaskTab;
  hide: () => void;
}

interface StateProps {
  task?: AssignedTaskModel;
}

interface DispatchProps {
  getTask: typeof getTask;
  setGlobalSelectedTask: typeof setGlobalSelectedTask;
}

type Props = LocalProps & StateProps & DispatchProps;

class AutoTaskModal extends React.Component<Props> {
  public componentDidUpdate (prevProps: Readonly<Props>) {
    const { show, taskId } = this.props;

    const modalWasOpened = taskId && !prevProps.show && show;
    const taskIdWasChanged = show && taskId && prevProps.taskId !== taskId;

    if (modalWasOpened || taskIdWasChanged) {
      this.props.getTask(taskId!);
    }
  }

  private defaultTaskId = () => {
    const initLocation = queryString.parse(initialSearchQuery);

    if (!initLocation?.showTaskId) {
      return;
    }

    const defaultTaskId = parseInt(initLocation.showTaskId as string, 10);

    this.props.setGlobalSelectedTask(defaultTaskId);
  }

  public componentDidMount () {
    this.defaultTaskId();
  }

  public render () {
    const { show, task, defaultTab } = this.props;

    return (
      <TaskModal show={show} task={task} hide={this.props.hide} defaultTab={defaultTab} />
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: LocalProps) => {
  return {
    task: ownProps.taskId ? state.tasks.entities.tasks[ownProps.taskId] : undefined,
  };
};

const mapDispatchToProps = {
  getTask,
  setGlobalSelectedTask,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(AutoTaskModal);
