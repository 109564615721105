import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreateUserRequest } from 'Modules/users/models';
import { userActions } from 'Modules/users';
import { getNewUser } from 'Modules/users/instantiations';
import { useMergedEntity, useUserForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, UserForm, Panel, PanelHeader } from 'Components';

const CreateUserPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootUser = useMemo(() => getNewUser(), []);
  const workingUser = useStateSelector((state) => state.users.ui.maintenance.workingCreateUser);
  const user = useMergedEntity(rootUser, workingUser);
  const { insertUser, cancelUserModification, loading } = useUserForm();

  function setWorkingUserValues(user: Partial<CreateUserRequest>) {
    dispatch(userActions.setWorkingCreateUserValues(user));
  }

  function handleSave() {
    if (!user) {
      return;
    }

    insertUser(user, (user) => {
      history.push(`/admin/users/${user.userId}`);
    });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New User</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelUserModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingUser,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <UserForm
              isCreatingNewUser
              user={user}
              setValues={setWorkingUserValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreateUserPanel;
