import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminDashboard from '../Pages/Dashboards/Admin/AdminDashboardPage';
import AdminTasksPage from '../Pages/Admin/Tasks/TasksPage';
import HomePage from '../Pages/Home/HomePage';
import LibraryPage from '../Pages/Documents/Library/LibraryPage';
import OperatorDashboardPage from '../Pages/Dashboards/Operator/OperatorDashboardPage';
import OperatorGroupPage from '../Pages/OperatorGroup/OperatorGroupPage';
import OperatorGroupSelectionPage from '../Pages/OperatorGroup/OperatorGroupSelectionPage';
import OperatorGroupsPage from '../Pages/Admin/OperatorGroups/OperatorGroupsPage';
import OperatorsPage from '../Pages/Admin/Operators/OperatorsPage';
import PollMaintenancePage from '../Pages/Polls/PollMaintenancePage';
import ProgramsPage from '../Pages/Programs/ProgramsPage';
import ReferenceMaterialsPage from '../Pages/ReferenceMaterials/ReferenceMaterialsPage';
import SafetyCoachDashboardPage from '../Pages/Dashboards/SafetyCoach/SafetyCoachDashboardPage';
import ShareBoardPage from '../Pages/ShareBoard/ShareBoardPage';
import SmartRoute from 'Components/SmartRoute/SmartRoute';
import TasksPage from '../Pages/Tasks/TasksPage';
import TemplatesPage from '../Pages/Templates/TemplatesPage';
import UsersPage from '../Pages/Admin/Users/UsersPage';
import AnalyticsPage from '../Pages/Analytics/AnalyticsPage';
import PollCategoryMaintenancePage from '../Pages/PollCategory/PollMaintenancePage';
import OperatorAttributeTypeMaintenancePage
  from '../Pages/OperatorAttributes/Maintenance/OperatorAttributeTypeMaintenancePage';
import OperatorProfilePage from '../Pages/OperatorProfile/OperatorProfilePage';
import RolesPage from '../Pages/Admin/Roles/RolesPage';
import { Permission } from 'Modules/roles';
import ShareBoardCategoryMaintenancePage from '../Pages/ShareBoardCategories/ShareBoardCategoryMaintenancePage';
import SurveyPage from '../Pages/Survey/SurveyPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={HomePage}/>
    <Route exact path="/survey" component={SurveyPage}/>
    <SmartRoute accountOnly exact path="/dashboards/operator" component={OperatorDashboardPage}/>
    <SmartRoute safetyCoachOnly exact path="/dashboards/coach" component={SafetyCoachDashboardPage}/>
    <SmartRoute requirePermission={Permission.ViewActiveUsers} exact path="/dashboards/admin" component={AdminDashboard}/>
    <SmartRoute requirePermission={Permission.OperatorGroupMaintenance} exact path="/admin/operatorGroups" component={OperatorGroupsPage}/>
    <SmartRoute requirePermission={Permission.OperatorMaintenance} path="/admin/operators" component={OperatorsPage}/>
    <SmartRoute requirePermission={Permission.PollMaintenance} path="/admin/polls" component={PollMaintenancePage}/>
    <SmartRoute requirePermission={Permission.GlobalShareBoardMaintenance} path="/admin/shareBoardCategories" component={ShareBoardCategoryMaintenancePage}/>
    <SmartRoute requirePermission={Permission.PollMaintenance} path="/admin/pollCategories" component={PollCategoryMaintenancePage}/>
    <SmartRoute requirePermission={Permission.OperatorAttributeTypeMaintenance} path="/admin/operatorAttributeTypes" component={OperatorAttributeTypeMaintenancePage}/>
    <SmartRoute requirePermission={Permission.GlobalRoleMaintenance} path="/admin/roles" component={RolesPage}/>
    <SmartRoute requirePermission={Permission.GlobalUserMaintenance} path="/admin/users" component={UsersPage}/>
    <SmartRoute requirePermission={Permission.GlobalTaskMaintenance} exact path="/admin/tasks" component={AdminTasksPage}/>
    <SmartRoute requirePermission={Permission.GlobalAuditTemplateMaintenance} exact path="/admin/templates" component={TemplatesPage}/>
    <SmartRoute requirePermission={Permission.ProgramMaintenance} exact path="/admin/programs" component={ProgramsPage}/>
    <SmartRoute requireSelectedOperator exact path="/tasks" component={TasksPage}/>
    <SmartRoute requireSelectedOperator exact path="/operatorGroups" component={OperatorGroupSelectionPage}/>
    <SmartRoute requireSelectedOperator exact path="/operatorProfile" component={OperatorProfilePage}/>
    <SmartRoute requireSelectedOperator exact path="/operatorGroups/:operatorGroupId([0-9]+)" component={OperatorGroupPage}/>
    <SmartRoute requireSelectedOperator exact path="/referenceMaterials" component={ReferenceMaterialsPage}/>
    <SmartRoute requireSelectedOperator exact path="/share-board" component={ShareBoardPage}/>
    <SmartRoute requireSelectedOperator exact path="/library" component={LibraryPage}/>
    <SmartRoute requireSelectedOperator path="/analytics/:tabId?" component={AnalyticsPage}/>

    <Redirect to="/"/>
  </Switch>
);

export default Routes;
