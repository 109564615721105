import _ from 'lodash';
import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { CreateOperatorAttribute, ModifyOperatorAttribute, OperatorAttribute, operatorAttributesActions, UpdateOperatorAttribute } from 'Modules/operatorAttributes';

const useOperatorAttributeForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingOperatorAttribute = useStateSelector((state) => state.operatorAttributes.loading.isInsertingOperatorAttribute);
  const isUpdatingOperatorAttribute = useStateSelector((state) => state.operatorAttributes.loading.isUpdatingOperatorAttribute);
  const isDeletingOperatorAttribute = useStateSelector((state) => state.operatorAttributes.loading.isDeletingOperatorAttribute);

  function validateOperatorAttribute(operatorAttribute: ModifyOperatorAttribute) {
    const errors: string[] = [];

    return errors;
  }

  function insertOperatorAttribute(operatorId: number, operatorAttribute: CreateOperatorAttribute) {
    const errorMessages = validateOperatorAttribute(operatorAttribute);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      throw new Error(errorMessages[0]);
    }

    return dispatch(operatorAttributesActions.async.insertOperatorAttribute(operatorId, operatorAttribute))
      .then((operatorAttribute: OperatorAttribute) => {
        return operatorAttribute;
      });
  }

  function updateOperatorAttribute(operatorId: number, operatorAttributeId: number, operatorAttribute: UpdateOperatorAttribute) {
    const errorMessages = validateOperatorAttribute(operatorAttribute);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(operatorAttributesActions.async.updateOperatorAttribute(operatorId, operatorAttributeId, operatorAttribute))
      .then((operatorAttribute: OperatorAttribute) => {
        return operatorAttribute;
      });
  }

  function deleteOperatorAttribute(operatorId: number, operatorAttribute: OperatorAttribute) {
    return dispatch(operatorAttributesActions.async.deleteOperatorAttribute(operatorId, operatorAttribute.id))
      .then((operatorAttribute: OperatorAttribute) => {
        return operatorAttribute;
      });
  }

  function cancelOperatorAttributeModification() {
    dispatch(operatorAttributesActions.cancelOperatorAttributeModification());
  }

  return {
    insertOperatorAttribute,
    updateOperatorAttribute,
    deleteOperatorAttribute,
    cancelOperatorAttributeModification,
    loading: {
      isInsertingOperatorAttribute,
      isUpdatingOperatorAttribute,
      isDeletingOperatorAttribute,
    },
  };
};

export default useOperatorAttributeForm;
