import { feedbackSlice } from './slice';
import * as feedbackApiActions from './apiActions';

const feedbackActions = {
  ...feedbackSlice.actions,
  async: {
    ...feedbackApiActions,
  },
};

export default feedbackActions;
