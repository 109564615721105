import _ from 'lodash';

export const getStringArray = (stringOrStrings: string | string[]): string[] => {
  return _.isArray(stringOrStrings) ? stringOrStrings : [ stringOrStrings ];
};

export const pluralCoalesce = (numOrArray: undefined | number | any[], singular: string, plural: string) => {
  const num = _.isArray(numOrArray) ? _.size(numOrArray) : numOrArray || 0;

  return num === 1 ? singular : plural;
};

export const sOrNoS = (numOrArray: undefined | number | any[]) => {
  return pluralCoalesce(numOrArray, '', 's');
};
