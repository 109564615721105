import { Action, ApiAction } from 'models';
import { commonActions } from '../common/actions';
import { SuperAgent } from 'superagent';
import config from '../../config';
import { CreateOperatorMessage, OperatorMessage, OperatorMessageResponse } from './models';
import { PaginatedRequest } from '../../core';
import { getPaginatedResponse, PaginatedSearchResult } from '../../helpers/pagination';

export const messagesActions = {
  SET_SELECTED_RECIPIENT: 'AVSafetySolutions/messages/SetSelectedRecipient',
  REALTIME_OPERATOR_MESSAGE_RECEIVED: 'AVSafetySolutions/messages/RealtimeOperatorMessageReceived',

  SEARCH_OPERATOR_MESSAGES_REQUEST: 'AVSafetySolutions/messages/SearchOperatorMessagesRequest',
  SEARCH_OPERATOR_MESSAGES_SUCCESS: 'AVSafetySolutions/messages/SearchOperatorMessagesSuccess',
  SEARCH_OPERATOR_MESSAGES_FAILURE: 'AVSafetySolutions/messages/SearchOperatorMessagesFailure',

  INSERT_OPERATOR_MESSAGE_REQUEST: 'AVSafetySolutions/messages/InsertOperatorMessageRequest',
  INSERT_OPERATOR_MESSAGE_SUCCESS: 'AVSafetySolutions/messages/InsertOperatorMessageSuccess',
  INSERT_OPERATOR_MESSAGE_FAILURE: 'AVSafetySolutions/messages/InsertOperatorMessageFailure',

  MARK_ALL_OPERATOR_MESSAGES_VIEWED_REQUEST: 'AVSafetySolutions/messages/MarkAllOperatorMessagesViewedRequest',
  MARK_ALL_OPERATOR_MESSAGES_VIEWED_SUCCESS: 'AVSafetySolutions/messages/MarkAllOperatorMessagesViewedSuccess',
  MARK_ALL_OPERATOR_MESSAGES_VIEWED_FAILURE: 'AVSafetySolutions/messages/MarkAllOperatorMessagesViewedFailure',
};

export const realtimeOperatorMessageReceived = (message: OperatorMessageResponse): Action => {
  return {
    type: messagesActions.REALTIME_OPERATOR_MESSAGE_RECEIVED,
    payload: {
      message,
    },
  };
};

export const searchOperatorMessages = (request: PaginatedRequest): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${config.baseUrl}/api/v1/operators/current/messages/search`)
          .query({ ...request });
      },
      beforeRequest: (): Action => ({ type: messagesActions.SEARCH_OPERATOR_MESSAGES_REQUEST }),
      success: (data: PaginatedSearchResult<OperatorMessage>): Action => ({ type: messagesActions.SEARCH_OPERATOR_MESSAGES_SUCCESS, payload: { response: getPaginatedResponse(request, data) } }),
      failure: (response: any): Action => ({ type: messagesActions.SEARCH_OPERATOR_MESSAGES_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertMessage = (message: CreateOperatorMessage, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${config.baseUrl}/api/v1/operators/current/messages`)
          .send(message);
      },
      beforeRequest: (): Action => ({ type: messagesActions.INSERT_OPERATOR_MESSAGE_REQUEST }),
      success: (data: any): Action => ({ type: messagesActions.INSERT_OPERATOR_MESSAGE_SUCCESS, payload: { message: data } }),
      successCallback,
      failure: (response: any): Action => ({ type: messagesActions.INSERT_OPERATOR_MESSAGE_FAILURE, payload: { ...response } }),
    },
  };
};

export const markAllOperatorMessagesRead = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${config.baseUrl}/api/v1/operators/current/messages/viewed`);
      },
      beforeRequest: (): Action => ({ type: messagesActions.MARK_ALL_OPERATOR_MESSAGES_VIEWED_REQUEST }),
      success: ({ currentUserId }): Action => ({ type: messagesActions.MARK_ALL_OPERATOR_MESSAGES_VIEWED_SUCCESS, payload: { userId: currentUserId } }),
      failure: (response: any): Action => ({ type: messagesActions.MARK_ALL_OPERATOR_MESSAGES_VIEWED_FAILURE, payload: { ...response } }),
    },
  };
};
