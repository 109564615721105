import { compose } from 'redux';

import { Action, ReducerFunction } from 'models';
import makeLoadingReducer from 'Modules/enhancers/makeLoadingReducer';
import loadingConfigs from './loading';
import { userNotificationsActions } from './actions';
import {
  convertPaginatedResponse,
  convertPaginatedSearchResultToState,
  PaginatedSearchResponse, PaginationResultState,
} from '../../helpers/pagination';
import { UserNotification, UserNotificationResponse } from './models';
import { convertToUserNotificationFromResponse } from './instantiations';
import _ from 'lodash';
import { messagesActions } from '../messages/actions';

export interface UserNotificationsState {
  entities: {
    userNotificationCount: {
      [userId: string]: number;
    };
    operatorMessageUserNotificationCount: {
      [userId: string]: number;
    };
    userNotifications: {
      [notificationId: string]: UserNotification;
    };
  };
  mappings: {
    taskUserNotifications: {[taskId: number]: string[]};
  };
  ui: {
    userNotificationPagination: { [userId: string]: PaginationResultState };
    userNotificationIds: { [userId: string]: string[] };
  };
  loading: {
    isLoadingMyNotificationCount: boolean;
    isSearchingMyNotifications: boolean;
  };
}

const initState: UserNotificationsState = {
  entities: {
    userNotificationCount: {},
    operatorMessageUserNotificationCount: {},
    userNotifications: {},
  },
  mappings: {
    taskUserNotifications: {},
  },
  ui: {
    userNotificationIds: {},
    userNotificationPagination: {},
  },
  loading: {
    isLoadingMyNotificationCount: false,
    isSearchingMyNotifications: false,
  },
};

export default compose<ReducerFunction<UserNotificationsState>>(
  makeLoadingReducer<UserNotificationsState>({ loadingKey: 'loading', loadingConfigs: loadingConfigs })
)((state = initState, action: Action): UserNotificationsState => {
  switch (action.type) {
    case userNotificationsActions.COUNT_MY_NOTIFICATIONS_SUCCESS: {
      const { currentUserId, count }: { currentUserId: string; count: number } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotificationCount: {
            ...state.entities.userNotificationCount,
            [currentUserId]: count,
          },
        },
      };
    }
    case userNotificationsActions.MARK_TASK_NOTIFICATIONS_VIEWED_SUCCESS: {
      const { currentUserId, taskId, numNotificationsMarkedViewed }: { currentUserId: string; taskId: number; numNotificationsMarkedViewed: number } = action.payload;

      const numNotifications = (state.entities.userNotificationCount[currentUserId] || 0) - numNotificationsMarkedViewed;

      if (numNotifications < 0) {
        return state;
      }

      const updatedUserNotifications = { ...state.entities.userNotifications };

      if (_.has(state.mappings.taskUserNotifications, taskId)) {
        const taskIds = state.mappings.taskUserNotifications[taskId];

        _.each(taskIds, (taskId) => {
          if (!_.has(updatedUserNotifications, taskId)) {
            return;
          }

          updatedUserNotifications[taskId] = {
            ...updatedUserNotifications[taskId],
            viewed: true,
          };
        });
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotifications: updatedUserNotifications,
          userNotificationCount: {
            ...state.entities.userNotificationCount,
            [currentUserId]: numNotifications,
          },
        },
      };
    }
    case userNotificationsActions.MARK_ALL_USER_NOTIFICATIONS_VIEWED_SUCCESS: {
      const { currentUserId }: { currentUserId: string; numNotificationsMarkedViewed: number } = action.payload;

      const updatedUserNotifications = { ...state.entities.userNotifications };

      _.each(state.entities.userNotifications, (notification) => {
        updatedUserNotifications[notification.notificationId] = {
          ...notification,
          viewed: true,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotifications: updatedUserNotifications,
          userNotificationCount: {
            ...state.entities.userNotificationCount,
            [currentUserId]: 0,
          },
        },
      };
    }
    case userNotificationsActions.COUNT_MY_OPERATOR_MESSAGE_NOTIFICATIONS_SUCCESS: {
      const { currentUserId, count }: { currentUserId: string; count: number } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          operatorMessageUserNotificationCount: {
            ...state.entities.operatorMessageUserNotificationCount,
            [currentUserId]: count,
          },
        },
      };
    }
    case userNotificationsActions.NOTIFICATION_RECEIVED: {
      const { notification }: { notification: UserNotificationResponse } = action.payload;

      if (!_.has(state.entities.userNotificationCount, notification.userId)) {
        return state;
      }

      const updatedTaskUserNotifications = { ...state.mappings.taskUserNotifications };

      if (notification.taskId) {
        updatedTaskUserNotifications[notification.taskId] = _.concat(updatedTaskUserNotifications[notification.taskId], notification.notificationId);
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotificationCount: {
            ...state.entities.userNotificationCount,
            [notification.userId]: state.entities.userNotificationCount[notification.userId] + 1,
          },
        },
        mappings: {
          ...state.mappings,
          taskUserNotifications: updatedTaskUserNotifications,
        },
      };
    }
    case userNotificationsActions.OPERATOR_MESSAGE_NOTIFICATION_RECEIVED: {
      const { userId }: { userId: string } = action.payload;

      if (!_.has(state.entities.operatorMessageUserNotificationCount, userId)) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          operatorMessageUserNotificationCount: {
            ...state.entities.operatorMessageUserNotificationCount,
            [userId]: state.entities.operatorMessageUserNotificationCount[userId] + 1,
          },
        },
      };
    }
    case messagesActions.MARK_ALL_OPERATOR_MESSAGES_VIEWED_SUCCESS: {
      const { userId }: { userId: string } = action.payload;

      if (!_.has(state.entities.operatorMessageUserNotificationCount, userId)) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          operatorMessageUserNotificationCount: {
            ...state.entities.operatorMessageUserNotificationCount,
            [userId]: 0,
          },
        },
      };
    }
    case userNotificationsActions.SEARCH_MY_NOTIFICATIONS_SUCCESS: {
      const { response, currentUserId }: { currentUserId: string; response: PaginatedSearchResponse<UserNotificationResponse> } = action.payload;
      const { pagedResult } = convertPaginatedResponse(response, convertToUserNotificationFromResponse);

      const taskUserNotifications = { ...state.mappings.taskUserNotifications };

      _.each(_.groupBy(pagedResult.items, t => t.taskId), (notifications) => {
        const [ first ] = notifications;

        if (!first.taskId) {
          return;
        }

        taskUserNotifications[first.taskId] = _.map(notifications, n => n.notificationId);
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotifications: {
            ...state.entities.userNotifications,
            ..._.keyBy(pagedResult.items, i => i.notificationId),
          },
        },
        mappings: {
          ...state.mappings,
          taskUserNotifications: {
            ...state.mappings.taskUserNotifications,
            ...taskUserNotifications,
          },
        },
        ui: {
          ...state.ui,
          userNotificationPagination: {
            ...state.ui.userNotificationPagination,
            [currentUserId]: convertPaginatedSearchResultToState(pagedResult),
          },
          userNotificationIds: {
            ...state.ui.userNotificationIds,
            [currentUserId]: _.concat(pagedResult.currentPage > 1 ? state.ui.userNotificationIds[currentUserId] || [] : [], _.map(pagedResult.items, i => i.notificationId)),
          },
        },
      };
    }
    case userNotificationsActions.GET_TASK_USER_NOTIFICATIONS_SUCCESS: {
      const { taskId, userNotifications: responses }: { currentUserId: string; taskId: number; userNotifications: UserNotificationResponse[] } = action.payload;

      const userNotifications = _.map(responses, convertToUserNotificationFromResponse);

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotifications: {
            ...state.entities.userNotifications,
            ..._.keyBy(userNotifications, i => i.notificationId),
          },
        },
        mappings: {
          ...state.mappings,
          taskUserNotifications: {
            ...state.mappings.taskUserNotifications,
            [taskId]: _.map(userNotifications, n => n.notificationId),
          },
        },
      };
    }
    case userNotificationsActions.MARK_NOTIFICATION_VIEWED_SUCCESS: {
      const { currentUserId, userNotification: response }: { currentUserId: string; userNotification: UserNotificationResponse } = action.payload;
      const userNotification = convertToUserNotificationFromResponse(response);

      const wasAlreadyViewed = !!state.entities.userNotifications[userNotification.notificationId]?.viewed;

      if (wasAlreadyViewed) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotifications: {
            ...state.entities.userNotifications,
            [userNotification.notificationId]: userNotification,
          },
          userNotificationCount: {
            ...state.entities.userNotificationCount,
            [currentUserId]: (state.entities.userNotificationCount[currentUserId] || 0) - (wasAlreadyViewed ? 0 : 1),
          },
        },
      };
    }
    case userNotificationsActions.MARK_NOTIFICATION_UNREAD_SUCCESS: {
      const { currentUserId, userNotification: response }: { currentUserId: string; userNotification: UserNotificationResponse } = action.payload;
      const userNotification = convertToUserNotificationFromResponse(response);

      const wasAlreadyViewed = !!state.entities.userNotifications[userNotification.notificationId]?.viewed;

      return {
        ...state,
        entities: {
          ...state.entities,
          userNotifications: {
            ...state.entities.userNotifications,
            [userNotification.notificationId]: userNotification,
          },
          userNotificationCount: {
            ...state.entities.userNotificationCount,
            [currentUserId]: (state.entities.userNotificationCount[currentUserId] || 0) + (wasAlreadyViewed ? 1 : 0),
          },
        },
      };
    }
    default: {
      return state;
    }
  }
});
