import React from 'react';
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStateSelector from 'hooks/useStateSelector';
import { HideWithoutFeature, HideWithoutPermission, NavItem } from 'Components';
import { OperatorFeatureEnum } from 'models';
import { useCurrentUserPermissionChecker } from '../hooks/index';
import { Permission } from '../Modules/roles/index';
import classnames from 'classnames';

const Sidebar = ({ location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const currentUserHasPermission = useCurrentUserPermissionChecker();

  const selectedOperator = useStateSelector(state => state.site.selectedOperator);

  const adminPermissions = [
    Permission.ViewActiveUsers,
    Permission.OperatorGroupMaintenance,
    Permission.OperatorMaintenance,
    Permission.PollMaintenance,
    Permission.PollMaintenance,
    Permission.OperatorAttributeTypeMaintenance,
    Permission.GlobalRoleMaintenance,
    Permission.GlobalUserMaintenance,
    Permission.GlobalTaskMaintenance,
    Permission.GlobalAuditTemplateMaintenance,
    Permission.ProgramMaintenance,
  ];

  const hasAnyAdminPermissions = adminPermissions.some(permission => currentUserHasPermission(permission));

  const isOnOperatorDashboard = location.pathname === '/dashboards/operator';

  return (
    <div className="left-side-menu overflow-auto">
      <div id="sidebar-menu">
        <ul className="metismenu" id="side-menu">
          {selectedOperator?.userIsAccount && (
            <Link to="/dashboards/operator">
              <li className={classnames('d-flex flex-column justify-content-center text-center dashboard-nav-tile', {
                'active': isOnOperatorDashboard,
              })}>
                <div>
                  <i className="mdi mdi-view-dashboard" style={{ fontSize: 55 }}/>
                </div>
                <div className="text-uppercase pb-2">Dashboard</div>
              </li>
            </Link>
          )}

          {selectedOperator?.userIsSafetyCoach && (
            <NavItem pathToLookFor="/dashboards/coach" exact>
              <NavLink
                exact
                to="/dashboards/coach"
                className="waves-effect"
              >
                <i className="mdi mdi-clipboard-account"/>
                <span> {t('safetyCoach')} </span>
              </NavLink>
            </NavItem>
          )}

          <HideWithoutPermission permission={Permission.ViewActiveUsers}>
            <NavItem pathToLookFor="/dashboards/admin" exact>
              <NavLink
                exact
                to="/dashboards/admin"
                className="waves-effect"
              >
                <i className="mdi mdi-home-lock"/>
                <span> Admin </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          {selectedOperator && (
            <HideWithoutFeature feature={OperatorFeatureEnum.Polling}>
              <NavItem pathToLookFor="/analytics">
                <NavLink
                  to="/analytics"
                  className="waves-effect"
                >
                  <i className="mdi mdi-finance"/>
                  <span> Analytics </span>
                </NavLink>
              </NavItem>
            </HideWithoutFeature>
          )}

          {(selectedOperator?.userIsAccount || selectedOperator?.userIsSafetyCoach) && (
            <NavItem pathToLookFor="/tasks" exact>
              <NavLink
                exact
                to="/tasks"
                className="waves-effect"
              >
                <i className="mdi mdi-order-bool-ascending-variant"/>
                <span> Tasks </span>
              </NavLink>
            </NavItem>
          )}

          {selectedOperator && (
            <>
              <NavItem pathToLookFor="/operatorGroups">
                <NavLink
                  to="/operatorGroups"
                  className="waves-effect"
                >
                  <i className="mdi mdi-account-group"/>
                  <span> Operator Groups </span>
                </NavLink>
              </NavItem>
              <NavItem pathToLookFor="/operatorProfile">
                <NavLink
                  to="/operatorProfile"
                  className="waves-effect"
                >
                  <i className="mdi mdi-badge-account-horizontal"/>
                  <span> Operator Profile </span>
                </NavLink>
              </NavItem>

              <li className="menu-title">Documents</li>

              <NavItem pathToLookFor="/library">
                <NavLink
                  to="/library"
                  className="waves-effect"
                >
                  <i className="mdi mdi-library"/>
                  <span> Library </span>
                </NavLink>
              </NavItem>

              <HideWithoutFeature feature={OperatorFeatureEnum.ShareBoard}>
                <NavItem pathToLookFor="/share-board">
                  <NavLink
                    to="/share-board"
                    className="waves-effect"
                  >
                    <i className="mdi mdi-cloud-upload"/>
                    <span> Share Board </span>
                  </NavLink>
                </NavItem>
              </HideWithoutFeature>

              {(selectedOperator?.userIsSafetyCoach || currentUserHasPermission(Permission.ReferenceMaterialMaintenance)) && (
                <NavItem pathToLookFor="/referenceMaterials">
                  <NavLink
                    to="/referenceMaterials"
                    className="waves-effect"
                  >
                    <i className="mdi mdi-file-check"/>
                    <span> Reference Materials </span>
                  </NavLink>
                </NavItem>
              )}
            </>
          )}

          {hasAnyAdminPermissions && (
            <li className="menu-title">Administration</li>
          )}

          <HideWithoutPermission permission={Permission.OperatorMaintenance}>
            <NavItem pathToLookFor="/admin/operators">
              <NavLink
                to="/admin/operators"
                className="waves-effect"
              >
                <i className="mdi mdi-airplane"/>
                <span> Operators </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.OperatorGroupMaintenance}>
            <NavItem pathToLookFor="/admin/operatorGroups">
              <NavLink
                to="/admin/operatorGroups"
                className="waves-effect"
              >
                <i className="mdi mdi-shield-airplane-outline"/>
                <span> Operator Groups </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.OperatorAttributeTypeMaintenance}>
            <NavItem pathToLookFor="/admin/operatorAttributeTypes">
              <NavLink
                to="/admin/operatorAttributeTypes"
                className="waves-effect"
              >
                <i className="mdi mdi-tag-multiple"/>
                <span> Operator Attributes </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.GlobalRoleMaintenance}>
            <NavItem pathToLookFor="/admin/roles">
              <NavLink
                to="/admin/roles"
                className="waves-effect"
              >
                <i className="mdi mdi-lock"/>
                <span> Roles </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.GlobalUserMaintenance}>
            <NavItem pathToLookFor="/admin/users">
              <NavLink
                to="/admin/users"
                className="waves-effect"
              >
                <i className="mdi mdi-account-group"/>
                <span> Users </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.ProgramMaintenance}>
            <NavItem pathToLookFor="/admin/programs">
              <NavLink
                to="/admin/programs"
                className="waves-effect"
              >
                <i className="mdi mdi-sitemap"/>
                <span> Programs </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.PollMaintenance}>
            <NavItem pathToLookFor="/admin/polls">
              <NavLink
                to="/admin/polls"
                className="waves-effect"
              >
                <i className="mdi mdi-chart-pie"/>
                <span> Polls </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.PollMaintenance}>
            <NavItem pathToLookFor="/admin/pollCategories">
              <NavLink
                to="/admin/pollCategories"
                className="waves-effect"
              >
                <i className="mdi mdi-chart-box-plus-outline"/>
                <span> Poll Categories </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.PollMaintenance}>
            <NavItem pathToLookFor="/admin/shareBoardCategories">
              <NavLink
                to="/admin/shareBoardCategories"
                className="waves-effect"
              >
                <i className="mdi mdi-chart-box-plus-outline"/>
                <span> Share Board Categories </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.GlobalAuditTemplateMaintenance}>
            <NavItem pathToLookFor="/admin/templates">
              <NavLink
                to="/admin/templates"
                className="waves-effect"
              >
                <i className="mdi mdi-file-multiple"/>
                <span> Task Card Templates </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>

          <HideWithoutPermission permission={Permission.GlobalTaskMaintenance}>
            <NavItem pathToLookFor="/admin/tasks">
              <NavLink
                to="/admin/tasks"
                className="waves-effect"
              >
                <i className="mdi mdi-file-check"/>
                <span> Audits </span>
              </NavLink>
            </NavItem>
          </HideWithoutPermission>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
