import React, { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import FlexRow from 'Components/FlexRow';
import { ReferenceMaterial } from 'Modules/referenceMaterials/models';
import { LoadableButton } from 'Components';
import { referenceMaterialsActions } from '../../Modules/referenceMaterials';
import useOnModalShow from '../../hooks/useOnModalShow';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import useStateSelector from '../../hooks/useStateSelector';

interface Props {
  show: boolean;
  referenceMaterial: ReferenceMaterial;
  hide: () => void;
}

const ModifyReferenceMaterialModal = ({ show, referenceMaterial, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const [ isFileCleared, setIsFileCleared ] = useState<boolean>(false);
  const [ name, setName ] = useState<string>('');
  const [ file, setFile ] = useState<File | undefined>();
  const isUpdating = useStateSelector(state => state.referenceMaterials.loading.isUpdatingReferenceMaterial || state.referenceMaterials.loading.isUpdatingReferenceMaterialName);

  useOnModalShow(show)
    .then(() => {
      setName(referenceMaterial.name);
      setFile(undefined);
      setIsFileCleared(false);
    });

  function handleFileSelect (e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    setFile(e.target.files[0]);
  }

  function updateReferenceMaterial() {
    if(isFileCleared) {
      if(!file) {
        return;
      }

      dispatch(referenceMaterialsActions.async.updateReferenceMaterial(referenceMaterial.referenceFileId, { name, file }))
        .then(() => {
          props.hide();
        });
    } else if(name !== referenceMaterial.name) {
      dispatch(referenceMaterialsActions.async.updateReferenceMaterialName(referenceMaterial.referenceFileId, name))
        .then(() => {
          props.hide();
        });
    } else {
      // props.hide();
    }
  }

  return (
    <Modal isOpen={show} size="lg" toggle={props.hide}>
      <ModalHeader>Modify Reference Material</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Name:</Label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>

        {!isFileCleared && (
          <FormGroup>
            <Label><Button color="anchor" onClick={() => setIsFileCleared(true)}><i className="mdi mdi-trash-can"/></Button> {referenceMaterial.file.originalFilename}</Label>
          </FormGroup>
        )}

        {isFileCleared && (
          <FormGroup>
            <Input type="file" onChange={handleFileSelect} />
          </FormGroup>
        )}

      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div>
            <Button color="light" onClick={props.hide}>Cancel</Button>
          </div>
          <div>
            <LoadableButton
              onClick={updateReferenceMaterial}
              isLoading={isUpdating}
              color="primary"
              disabled={!name.trim() || (isFileCleared && !file)}>
              Save
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default ModifyReferenceMaterialModal;
