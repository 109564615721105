import React from 'react';

import { FlexColumn, FlexExtendableProps } from 'Components';

interface Props extends FlexExtendableProps {}

const Panel = ({ children, ...props }: Props) => {
  return (
    <FlexColumn {...props} fill overflowHidden>
      {children}
    </FlexColumn>
  );
};

export default Panel;
