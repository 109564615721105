import React, { ReactNode } from 'react';

import { useCurrentUserPermissionChecker } from 'hooks';
import { Permission } from 'Modules/roles';

interface LocalProps {
  isOperatorUser: boolean;
  permission: Permission;
  children: ReactNode;
}

const HideIfNotOperatorAndDoesNotHavePermission = ({ permission, isOperatorUser, children }: LocalProps) => {
  const currentUserHasPermission = useCurrentUserPermissionChecker();

  if (!currentUserHasPermission(permission) && !isOperatorUser) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default HideIfNotOperatorAndDoesNotHavePermission;
