import React from 'react';
import _ from 'lodash';
import FlexRow from '../Components/FlexRow';
import { sOrNoS } from '../helpers/listHelpers';
import useStateSelector from '../hooks/useStateSelector';
import { Div, FeedbackModal, HideWithoutPermission } from '../Components';
import OpenModalButton from '../Components/OpenModalButton/OpenModalButton';
import { Permission } from '../Modules/roles/index';

const Footer = () => {
  const numUsersOnline = useStateSelector(state => _.size(state.site.onlineUsers));
  return (
    <footer className="footer">
      <div className="inner position-relative">
        <div className="container-fluid">
          <FlexRow justifyBetween alignCenter>
            <Div childSpacingX={1}>
              <HideWithoutPermission permission={Permission.ViewActiveUsers}>
                {!!numUsersOnline && (
                  <span className="d-none d-lg-inline">{numUsersOnline} User{sOrNoS(numUsersOnline)} Online</span>
                )}
              </HideWithoutPermission>

              <OpenModalButton
                color="dark"
                renderModal={(show, hide) => (
                  <FeedbackModal show={show} hide={hide}/>
                )}
              >
                Send Feedback
              </OpenModalButton>
            </Div>
            <div>
              {new Date().getFullYear()} © Non Nullable LLC
            </div>
          </FlexRow>
        </div>

        <div className="d-flex align-items-center justify-content-center position-absolute py-2 pointer-events-none" style={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <img className="d-none d-lg-inline" src="/babp-full.png" alt="BABP" style={{ maxHeight: '100%' }}/>
          <img className="d-inline d-lg-none" src="/babp-small.png" alt="BABP" style={{ maxHeight: '100%' }}/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
