import React, { useEffect } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { operatorGroupsActions, operatorGroupsSelectors } from '../../Modules/operatorGroups';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import FlexLoaderPanel from '../../Components/FlexLoaderPanel';
import { RootState } from '../../Modules/reducers';

const OperatorGroupSelectionPage = function () {
  const dispatch = useDispatch();
  const currentUserOperatorGroups = useSelector(operatorGroupsSelectors.operatorGroups.operatorOperatorGroups);
  const isLoadingCurrentUserOperators = useSelector((state: RootState) => state.operators.loading.isLoadingCurrentUserOperators);
  const isLoadingCurrentUserOperatorGroups = useSelector(operatorGroupsSelectors.isLoadingCurrentUserOperatorGroups);

  useEffect(() => {
    dispatch(operatorGroupsActions.async.getCurrentUserOperatorGroupsForOperator());
  }, []);

  return (
    <>
      <Helmet>
        <title>Operator Groups</title>
      </Helmet>

      <FlexLoaderPanel className="pt-2" loadingClassName="p-5" isLoading={isLoadingCurrentUserOperatorGroups || isLoadingCurrentUserOperators} overlay>
        {_.isEmpty(currentUserOperatorGroups) && (
          <Row>
            <Col>
              <h4 className="text-center py-2">You are not assigned to any operator groups</h4>
            </Col>
          </Row>
        )}
        {_.size(currentUserOperatorGroups) > 0 && (
          <Row>
            {
              _.map(currentUserOperatorGroups, (operatorGroup) => {
                return (
                  <Col key={operatorGroup.operatorGroupId} md={3}>
                    <Link to={`/operatorGroups/${operatorGroup.operatorGroupId}`}>
                      <Card className="clickable" style={{ minHeight: 200 }}>
                        <CardBody className="d-flex align-items-center justify-content-center">
                          {operatorGroup.name}
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                );
              })
            }
          </Row>
        )}
      </FlexLoaderPanel>
    </>
  );
};

export default OperatorGroupSelectionPage;
