import React, { useState } from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import { DropEvent, FileRejection } from 'react-dropzone';

import FileDropzone, { FileDropzoneProps } from './FileDropzone';
import UploadingFile from './UploadingFile';
import SelectedFilePlaceholder from './SelectedFilePlaceholder';
import UploadFilePlaceholder from './UploadFilePlaceholder';

interface LocalProps extends Omit<FileDropzoneProps, 'children'> {
  isUploading: boolean;
  uploadFiles: (file: File[]) => Promise<any>;
}

const AutoUploadFileDropzone = function ({ isUploading, ...props }: LocalProps) {
  const [ selectedFiles, setSelectedFiles ] = useState<File[] | undefined>();

  async function onDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    if (_.isEmpty(acceptedFiles)) {
      return;
    }

    setSelectedFiles(acceptedFiles);

    await uploadFiles(acceptedFiles);

    props.onDrop?.(acceptedFiles, fileRejections, event);
  }

  async function uploadFiles(selectedFiles: File[]) {
    await props.uploadFiles(selectedFiles);
    setSelectedFiles(undefined);
  }

  function onDropRejected(fileRejections: FileRejection[], event: DropEvent) {
    toastr.error('File is not valid');

    if (props.onDropRejected) {
      props.onDropRejected(fileRejections, event);
    }
  }

  return (
    <FileDropzone {...props} onDrop={onDrop} onDropRejected={onDropRejected}>
      {isUploading && (
        <UploadingFile/>
      )}
      {!isUploading && (
        <>
          {_.size(selectedFiles) > 0 && (
            <SelectedFilePlaceholder
              selectedFiles={selectedFiles}
            />
          )}
          {_.isEmpty(selectedFiles) && (
            <UploadFilePlaceholder/>
          )}
        </>
      )}
    </FileDropzone>
  );
};

export default AutoUploadFileDropzone;
