import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';
import * as libraryActions from 'Modules/library/actions';
import {
  ApproveShareBoardFilesRequest,
  CreateShareBoardCategory,
  SaveShareBoardPositionRequest,
  ShareBoardCategory,
  ShareBoardFile,
  ShareBoardFilePosition,
  UnApproveShareBoardFilesRequest,
  UpdateShareBoardCategory,
} from './models';
import {
  getDefaultPaginatedSearchContainer,
  getReducerAppendResults,
  PaginatedResult,
  PaginatedSearchContainer,
} from 'core';
import { commonActions } from '../common/actions';

export interface ShareBoardState {
  entities: {
    shareBoardFiles: { [shareBoardFileFile: number]: ShareBoardFile };
    shareBoardCategories: { [shareBoardCategoryId: number]: ShareBoardCategory };
  };
  xrefs: {};
  ui: {
    allApprovedShareBoardFileIdForCurrentOperatorGroup: number[];
    allApprovedShareBoardFileIds: { [operatorGroupId: number]: number[]};
    allUnapprovedShareBoardFileIds: { [operatorGroupId: number]: number[] };
    allShareBoardCategoryIds: number[];
    shareBoardFilePagination: PaginatedSearchContainer,
    createdFolders: string[];
    selectedShareBoardFiles: number[];
    shareBoardFilePositions: { [filePath: string]: number | undefined };
    selectedShareBoardCategory: ShareBoardCategory | undefined | null;
    maintenance: {
      workingCreateCategory: Partial<CreateShareBoardCategory>;
      workingUpdateCategory: Partial<UpdateShareBoardCategory>;
    }
  };
  loading: {
    isLoadingApprovedShareBoardFiles: boolean;
    isLoadingApprovedShareBoardFilesForOperatorGroup: boolean;
    isLoadingUnapprovedShareBoardFilesForOperatorGroup: boolean;
    isLoadingShareBoardFilePositions: boolean;
    isLoadingAllShareBoardCategories: boolean;
    isSearchingShareBoardFiles: boolean;
    isInsertingShareBoardCategory: boolean;
    isUpdatingShareBoardCategory: boolean;
    isUploadingShareBoardCategoryImage: boolean;
    isUpdatingShareBoardFile: boolean;
    isUpdatingShareBoardFileName: boolean;
    isAddingShareBoardFileToCategory: boolean;
    isMovingShareBoardFiles: boolean;
    isRenamingShareBoardFiles: boolean;
    isUpdatingShareBoardFileRating: boolean;
    isUpdatingShareBoardFilePositions: boolean;
    isDeletingShareBoardCategory: boolean;
    isDeletingShareBoardCategoryImage: boolean;
    isActivatingShareBoardCategory: boolean;
  };
}

const initialShareBoardFileState: ShareBoardState = {
  entities: {
    shareBoardFiles: {},
    shareBoardCategories: {},
  },
  xrefs: {},
  ui: {
    allApprovedShareBoardFileIdForCurrentOperatorGroup: [],
    allApprovedShareBoardFileIds: {},
    allUnapprovedShareBoardFileIds: {},
    allShareBoardCategoryIds: [],
    selectedShareBoardFiles: [],
    shareBoardFilePagination: getDefaultPaginatedSearchContainer(),
    createdFolders: [],
    shareBoardFilePositions: {},
    selectedShareBoardCategory: undefined,
    maintenance: {
      workingCreateCategory: {},
      workingUpdateCategory: {},
    },
  },
  loading: {
    isLoadingApprovedShareBoardFiles: false,
    isLoadingApprovedShareBoardFilesForOperatorGroup: false,
    isLoadingUnapprovedShareBoardFilesForOperatorGroup: false,
    isLoadingShareBoardFilePositions: false,
    isSearchingShareBoardFiles: false,
    isLoadingAllShareBoardCategories: false,
    isInsertingShareBoardCategory: false,
    isUpdatingShareBoardCategory: false,
    isUploadingShareBoardCategoryImage: false,
    isUpdatingShareBoardFile: false,
    isUpdatingShareBoardFileName: false,
    isAddingShareBoardFileToCategory: false,
    isMovingShareBoardFiles: false,
    isRenamingShareBoardFiles: false,
    isUpdatingShareBoardFileRating: false,
    isUpdatingShareBoardFilePositions: false,
    isDeletingShareBoardCategory: false,
    isDeletingShareBoardCategoryImage: false,
    isActivatingShareBoardCategory: false,
  },
};

export const shareBoardSlice = createSlice({
  name: 'ShareBoardFile',
  initialState: initialShareBoardFileState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<ShareBoardState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setSelectedShareBoardCategory: (state, action: PayloadAction<ShareBoardCategory | null | undefined>) => {
      state.ui.selectedShareBoardCategory = action.payload;
      state.ui.shareBoardFilePagination = getDefaultPaginatedSearchContainer();
    },
    addCreatedFolder: (state, action: PayloadAction<string>) => {
      state.ui.createdFolders = _.uniq(state.ui.createdFolders.concat(action.payload.trim()));
    },
    setWorkingCreateShareBoardValues: (state, action: PayloadAction<Partial<CreateShareBoardCategory>>) => {
      state.ui.maintenance.workingCreateCategory = {
        ...state.ui.maintenance.workingCreateCategory,
        ...action.payload,
      };
    },
    setWorkingUpdateShareBoardValues: (state, action: PayloadAction<Partial<UpdateShareBoardCategory>>) => {
      state.ui.maintenance.workingUpdateCategory = {
        ...state.ui.maintenance.workingUpdateCategory,
        ...action.payload,
      };
    },
    cancelShareBoardCategoryModification: (state) => {
      state.ui.maintenance.workingUpdateCategory = {};
      state.ui.maintenance.workingCreateCategory = {};
    },
    setSelectedShareBoardFiles: (state, action: PayloadAction<number[]>) => {
      state.ui.selectedShareBoardFiles = action.payload;
    },
    setAllApprovedShareBoardFiles: (state, action: PayloadAction<ShareBoardFile[]>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        ..._.keyBy(action.payload, r => r.id),
      };
      state.ui.allApprovedShareBoardFileIdForCurrentOperatorGroup = action.payload.map(r => r.id);
    },
    setAllApprovedShareBoardFilesForOperatorGroup: (state, action: PayloadAction<{ operatorGroupId: number; files: ShareBoardFile[] }>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        ..._.keyBy(action.payload.files, r => r.id),
      };
      state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId] = action.payload.files.map(r => r.id);
    },
    setAllUnapprovedShareBoardFiles: (state, action: PayloadAction<{ operatorGroupId: number; files: ShareBoardFile[] }>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        ..._.keyBy(action.payload.files, r => r.id),
      };
      state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId] = action.payload.files.map(r => r.id);
    },
    setAllShareBoardFilePositions: (state, action: PayloadAction<ShareBoardFilePosition[]>) => {
      const updatedShareBoardFilePositions = { ...state.ui.shareBoardFilePositions };

      _.each(action.payload, (position) => {
        updatedShareBoardFilePositions[position.filePath] = position.position;
      });

      state.ui.shareBoardFilePositions = updatedShareBoardFilePositions;
    },
    setAllShareBoardCategories: (state, action: PayloadAction<ShareBoardCategory[]>) => {
      state.entities.shareBoardCategories = {
        ...state.entities.shareBoardCategories,
        ..._.keyBy(action.payload, r => r.id),
      };
      state.ui.allShareBoardCategoryIds = action.payload.map(r => r.id);
    },
    setCreatedShareBoardCategory: (state, action: PayloadAction<ShareBoardCategory>) => {
      state.entities.shareBoardCategories = {
        ...state.entities.shareBoardCategories,
        [action.payload.id]: action.payload,
      };
      state.ui.allShareBoardCategoryIds = _.uniq(state.ui.allShareBoardCategoryIds.concat(action.payload.id));
    },
    setUpdatedShareBoardCategory: (state, action: PayloadAction<ShareBoardCategory>) => {
      state.entities.shareBoardCategories = {
        ...state.entities.shareBoardCategories,
        [action.payload.id]: action.payload,
      };
    },
    setShareBoardFiles: (state, action: PayloadAction<ShareBoardFile[]>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        ..._.keyBy(action.payload, r => r.id),
      };
    },
    setShareBoardFileRating: (state, action: PayloadAction<ShareBoardFile>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        [action.payload.id]: action.payload,
      };
    },
    setSearchedShareBoardFiles: (state, action: PayloadAction<PaginatedResult<ShareBoardFile>>) => {
      const { entities, pagination } = getReducerAppendResults(action.payload, state.entities.shareBoardFiles, state.ui.shareBoardFilePagination, (r) => r.id);

      state.entities.shareBoardFiles = entities;
      state.ui.shareBoardFilePagination = pagination;
    },
    setShareBoardFilePositions: (state, action: PayloadAction<SaveShareBoardPositionRequest[]>) => {
      const positions = action.payload;

      const updatedShareBoardFilePositions = { ...state.ui.shareBoardFilePositions };

      _.each(positions, (position) => {
        updatedShareBoardFilePositions[position.filePath] = position.position;
      });

      state.ui.shareBoardFilePositions = updatedShareBoardFilePositions;
    },
    revertShareBoardFilePositions: (state, action: PayloadAction<SaveShareBoardPositionRequest[]>) => {
      const positions = action.payload;

      const updatedShareBoardFilePositions = { ...state.ui.shareBoardFilePositions };

      _.each(positions, (position) => {
        updatedShareBoardFilePositions[position.filePath] = position.originalPosition;
      });

      state.ui.shareBoardFilePositions = updatedShareBoardFilePositions;
    },
    setShareBoardFileUpdated: (state, action: PayloadAction<ShareBoardFile>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        [action.payload.id]: action.payload,
      };
    },
    setAddShareBoardFileToCategory: (state, action: PayloadAction<{ shareBoardFileId: number; category: ShareBoardCategory }>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        [action.payload.shareBoardFileId]: {
          ...state.entities.shareBoardFiles[action.payload.shareBoardFileId],
          categories: _.uniqBy([ ...state.entities.shareBoardFiles[action.payload.shareBoardFileId]?.categories, action.payload.category ], (c) => c.id),
        },
      };
    },
    setRemoveShareBoardFileFromCategory: (state, action: PayloadAction<{ shareBoardFileId: number; category: ShareBoardCategory }>) => {
      state.entities.shareBoardFiles = {
        ...state.entities.shareBoardFiles,
        [action.payload.shareBoardFileId]: {
          ...state.entities.shareBoardFiles[action.payload.shareBoardFileId],
          categories: _.filter(state.entities.shareBoardFiles[action.payload.shareBoardFileId]?.categories, (c) => c.id !== action.payload.category.id),
        },
      };
    },
    setShareBoardFilesApproved: (state, action: PayloadAction<{ operatorGroupId: number; request: ApproveShareBoardFilesRequest }>) => {
      _.each(action.payload.request.shareBoardFileIds, (shareBoardFileId) => {
        if(!_.has(state.entities.shareBoardFiles, shareBoardFileId)) {
          return;
        }

        state.entities.shareBoardFiles[shareBoardFileId].approved = true;
      });

      state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId] = _.difference(state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId], action.payload.request.shareBoardFileIds);
      state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId] = _.union(state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId], action.payload.request.shareBoardFileIds);
    },
    setShareBoardFilesUnApproved: (state, action: PayloadAction<{ operatorGroupId: number; request: UnApproveShareBoardFilesRequest }>) => {
      _.each(action.payload.request.shareBoardFileIds, (shareBoardFileId) => {
        if(!_.has(state.entities.shareBoardFiles, shareBoardFileId)) {
          return;
        }

        state.entities.shareBoardFiles[shareBoardFileId].approved = false;
      });
      state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId] = _.difference(state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId], action.payload.request.shareBoardFileIds);
      state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId] = _.union(state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId], action.payload.request.shareBoardFileIds);
    },
    setShareBoardFilesDeleted: (state, action: PayloadAction<{ operatorGroupId?: number; fileIds: number[] }>) => {
      _.each(action.payload.fileIds, (shareBoardFileId) => {
        _.omit(state.entities.shareBoardFiles, shareBoardFileId);
      });

      state.ui.allApprovedShareBoardFileIdForCurrentOperatorGroup = _.difference(state.ui.allApprovedShareBoardFileIdForCurrentOperatorGroup, action.payload.fileIds);
      state.ui.shareBoardFilePagination.ids = _.difference(state.ui.shareBoardFilePagination.ids, action.payload.fileIds);
      state.ui.shareBoardFilePagination.total = state.ui.shareBoardFilePagination.total - action.payload.fileIds.length;

      if(action.payload.operatorGroupId) {
        state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId] = _.difference(state.ui.allUnapprovedShareBoardFileIds[action.payload.operatorGroupId], action.payload.fileIds);
        state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId] = _.difference(state.ui.allApprovedShareBoardFileIds[action.payload.operatorGroupId], action.payload.fileIds);
      }
    },
    setShareBoardCategoryDeleted: (state, action: PayloadAction<number>) => {
      const existingCategory = state.entities.shareBoardCategories[action.payload];

      if(!existingCategory) {
        return;
      }

      state.entities.shareBoardCategories[action.payload].deletedTimestamp = new Date().toISOString();
      state.entities.shareBoardCategories[action.payload].deletedUser = 'System';

    },
    setShareBoardCategoryImageDeleted: (state, action: PayloadAction<number>) => {
      const existingCategory = state.entities.shareBoardCategories[action.payload];

      if(!existingCategory) {
        return;
      }

      state.entities.shareBoardCategories[action.payload].imageFile = undefined;

    },
    setShareBoardCategoryActivated: (state, action: PayloadAction<number>) => {
      const existingCategory = state.entities.shareBoardCategories[action.payload];

      if(!existingCategory) {
        return;
      }

      state.entities.shareBoardCategories[action.payload].deletedTimestamp = undefined;
      state.entities.shareBoardCategories[action.payload].deletedUser = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(commonActions.PATH_LOCATION_CHANGE, (state, action: PayloadAction<{ shareBoardFiles: ShareBoardFile[] }>) => {
        state.ui.maintenance.workingUpdateCategory = {};
        state.ui.maintenance.workingCreateCategory = {};
      })
      .addCase(libraryActions.libraryActions.POST_LIBRARY_FILES_TO_SHARE_BOARD_SUCCESS, (state, action: PayloadAction<{ shareBoardFiles: ShareBoardFile[] }>) => {
        state.entities.shareBoardFiles = {
          ...state.entities.shareBoardFiles,
          ..._.keyBy(action.payload.shareBoardFiles, (f) => f.id),
        };
      });
  },
});
