import React from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { shouldModalUpdate } from 'helpers/modalHelpers';
import { RootState } from 'Modules/reducers';
import { insertProgramFolder, updateProgramFolder } from '../../Modules/programs/actions';
import { LoadableButton } from 'Components';
import { ProgramFolder } from '../../Modules/programs/models';
import UploadDirectoryPathSelector from '../../AviationSafetySolutions/UploadDirectoryPathSelector';

interface LocalProps {
  show: boolean;
  programFolder?: ProgramFolder;
  hide: () => void;
}

interface LocalState {
  directoryPath: string;
}

interface StateProps {
  isSavingProgramFolder: boolean;
}

interface DispatchProps {
  updateProgramFolder: typeof updateProgramFolder;
  insertProgramFolder: typeof insertProgramFolder;
}

type Props = LocalProps & StateProps & DispatchProps;

class ModifyProgramFolderModal extends React.Component<Props, LocalState> {
  public shouldComponentUpdate = shouldModalUpdate(this);

  constructor (props: Props) {
    super(props);

    this.state = {
      directoryPath: '',
    };
  }

  public componentDidUpdate (prevProps: Readonly<Props>): void {
    const { show, programFolder } = this.props;

    if (prevProps.show && !show) {
      this.setState({
        directoryPath: '',
      });
    }
    if (!prevProps.show && show) {
      this.setState({
        directoryPath: programFolder?.directoryPath || '',
      });
    }
  }

  private saveFolder = () => {
    const { directoryPath } = this.state;
    const { programFolder } = this.props;

    if (!programFolder) {
      return;
    }

    if (programFolder.programFolderId > 0) {
      this.props.updateProgramFolder({
        ...programFolder,
        directoryPath: directoryPath || '/',
      }, this.props.hide);
    } else {
      this.props.insertProgramFolder({
        ...programFolder,
        directoryPath: directoryPath || '/',
      }, this.props.hide);
    }
  };

  private setDirectoryPath = (directoryPath: string) => {
    this.setState({
      directoryPath: directoryPath,
    });
  };

  public render () {
    const { directoryPath } = this.state;
    const { show, isSavingProgramFolder } = this.props;

    return (
      <Modal isOpen={show} toggle={this.props.hide}>
        <ModalHeader>Create Program</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Directory:</Label>
                <UploadDirectoryPathSelector value={directoryPath} onChange={this.setDirectoryPath} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <LoadableButton isLoading={isSavingProgramFolder} LoadingLabel="Saving..." color="primary" onClick={this.saveFolder}>
            Save Folder
          </LoadableButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isSavingProgramFolder: state.programs.loading.isInsertingProgramFolder || state.programs.loading.isUpdatingProgramFolder,
  };
};

const mapDispatchToProps = {
  updateProgramFolder,
  insertProgramFolder,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ModifyProgramFolderModal);
