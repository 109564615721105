import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReactChildren } from '../models';

interface LocalProps extends RouteComponentProps {
  pathToLookFor: string;
  exact?: boolean;
  isDropdown?: boolean;
  children?: ReactChildren;
}

const NavItem = ({ location, pathToLookFor, exact, isDropdown, children }: LocalProps): any => {
  const isActive = exact ? location.pathname === pathToLookFor : _.includes(location.pathname, pathToLookFor);

  return (
    <li className={classnames({ dropdown: isDropdown, 'mm-active': isActive })}>
      {children}
    </li>
  );
};

// @ts-ignore
export default withRouter(NavItem);
