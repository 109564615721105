import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { UsersState } from './reducer';
import { usersActions } from './actions';
import { setLoadingHash } from '../../helpers/loadingHelpers';

const loadingConfigs: LoadingActionConfig<UsersState>[] = [
  {
    start: usersActions.GET_ALL_USERS_REQUEST,
    stop: [ usersActions.GET_ALL_USERS_SUCCESS, usersActions.GET_ALL_USERS_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingAllUsers: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.GET_USER_REQUEST,
    stop: [ usersActions.GET_USER_SUCCESS, usersActions.GET_USER_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingUser: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.INSERT_USER_REQUEST,
    stop: [ usersActions.INSERT_USER_SUCCESS, usersActions.INSERT_USER_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isInsertingUser: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.UPDATE_USER_REQUEST,
    stop: [ usersActions.UPDATE_USER_SUCCESS, usersActions.UPDATE_USER_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isUpdatingUser: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.DELETE_USER_REQUEST,
    stop: [ usersActions.DELETE_USER_SUCCESS, usersActions.DELETE_USER_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingUser: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.SET_USER_ACCOUNT_ENABLED_REQUEST,
    stop: [ usersActions.SET_USER_ACCOUNT_ENABLED_SUCCESS, usersActions.SET_USER_ACCOUNT_ENABLED_FAILURE ],
    updater: (state: UsersState, isLoading, { userId }: { userId: number }): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          updatingUsers: setLoadingHash(state.loading.updatingUsers, userId, isLoading),
        },
      };
    },
  },
  {
    start: usersActions.INVITE_USER_REQUEST,
    stop: [ usersActions.INVITE_USER_SUCCESS, usersActions.INVITE_USER_FAILURE ],
    updater: (state: UsersState, isLoading, { userId }: { userId: string }): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          invitingUsers: setLoadingHash(state.loading.invitingUsers, userId, isLoading),
        },
      };
    },
  },
  {
    start: usersActions.DELETE_USER_REQUEST,
    stop: [ usersActions.DELETE_USER_SUCCESS, usersActions.DELETE_USER_FAILURE ],
    updater: (state: UsersState, isLoading, { userId }): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          deletingUsers: setLoadingHash(state.loading.invitingUsers, userId, isLoading),
        },
      };
    },
  },
  {
    start: usersActions.AGREE_TO_TERMS_OF_SERVICE_REQUEST,
    stop: [ usersActions.AGREE_TO_TERMS_OF_SERVICE_SUCCESS, usersActions.AGREE_TO_TERMS_OF_SERVICE_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isAgreeingToTermsOfService: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.SET_PROFILE_PICTURE_REQUEST,
    stop: [ usersActions.SET_PROFILE_PICTURE_SUCCESS, usersActions.SET_PROFILE_PICTURE_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSettingProfilePicture: isLoading,
        },
      };
    },
  },
  {
    start: usersActions.DELETE_PROFILE_PICTURE_REQUEST,
    stop: [ usersActions.DELETE_PROFILE_PICTURE_SUCCESS, usersActions.DELETE_PROFILE_PICTURE_FAILURE ],
    updater: (state: UsersState, isLoading): UsersState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingProfilePicture: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
