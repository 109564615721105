import { shareBoardCategoryTagsSlice } from './slice';
import * as shareBoardCategoryTagsApiActions from './apiActions';

const shareBoardCategoryTagsActions = {
  ...shareBoardCategoryTagsSlice.actions,
  async: {
    ...shareBoardCategoryTagsApiActions,
  },
};

export default shareBoardCategoryTagsActions;
