import React from 'react';
import _ from 'lodash';
import { Col } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { connect } from 'react-redux';

import Task from './Task';
import { AssignedTaskModel, OperatorAuditModel, UpdateTaskPosition } from '../../../models';
import { updateTaskPositions } from '../../../Modules/tasks/actions';
import { RootState } from '../../../Modules/reducers';
import { CategoryModel } from '../../../Modules/categories/models';
import FlexColumn from '../../../Components/FlexColumn';

interface LocalProps {
  operatorAudit: OperatorAuditModel;
  tasks: AssignedTaskModel[];
  category?: CategoryModel;
}

interface LocalState {
  tasks: AssignedTaskModel[];
}

interface StateProps {}

interface DispatchProps {
  updateTaskPositions: typeof updateTaskPositions;
}

type Props = LocalProps & DispatchProps;

class CategoryTaskGroup extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      tasks: props.tasks,
    };
  }

  public componentDidUpdate (prevProps: Readonly<LocalProps>) {
    const { tasks } = this.props;

    if (prevProps.tasks !== tasks) {
      this.setState({ tasks });
    }
  }

  private setTasks = (tasks: AssignedTaskModel[]) => {
    this.setState({ tasks: tasks });
  }

  private persistPositions = () => {
    const { tasks } = this.state;

    const dtos: UpdateTaskPosition[] = [];

    _.each(tasks, ({ taskId, position }, index) => {
      if (position !== index) {
        dtos.push({ taskId, position: index });
      }
    });

    if (_.isEmpty(dtos)) {
      return;
    }

    this.props.updateTaskPositions(dtos);
  }

  public render () {
    const { tasks } = this.state;
    const { operatorAudit, category } = this.props;

    return (
      <FlexColumn fill>
        <h6>{category?.name || <em>Uncategorized</em>}</h6>

        <ReactSortable<any> className="row" list={tasks} setList={this.setTasks} onEnd={this.persistPositions}>
          {
            _.map(tasks, (task) => {
              const { taskId } = task;

              return (
                <Col key={taskId} md={4}>
                  <Task task={task} operatorAudit={operatorAudit} />
                </Col>
              );
            })
          }
        </ReactSortable>
      </FlexColumn>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  updateTaskPositions,
};

// @ts-ignore
export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(CategoryTaskGroup);
