import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { getValueByKey } from 'core';
import { operatorAttributesActions, UpdateOperatorAttributeType } from 'Modules/operatorAttributes';
import { useMergedEntity, useOperatorAttributeTypeForm, useRequiredPathId, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, FlexLoader, Panel, PanelHeader, OperatorAttributeTypeFormControls } from 'Components';

const ModifyOperatorAttributeTypePanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const operatorAttributeTypeId = useRequiredPathId('operatorAttributeTypeId');
  const workingOperatorAttributeType = useStateSelector((state) => state.operatorAttributes.ui.maintenance.workingUpdateOperatorAttributeType);
  const isLoadingOperatorAttributeType = useStateSelector((state) => state.operatorAttributes.loading.isLoadingOperatorAttributeType);
  const canEdit = true; //todo permissions useHasPermission(Permission.OperatorAttributeTypeCategoriesEdit);
  const canDeactivate = true; //todo permissions useHasPermission(Permission.OperatorAttributeTypeCategoriesDeactivate);
  const rootOperatorAttributeType = useStateSelector((state) => getValueByKey(state.operatorAttributes.entities.operatorAttributeTypes, operatorAttributeTypeId));
  const operatorAttributeType = useMergedEntity(rootOperatorAttributeType, workingOperatorAttributeType);
  const { updateOperatorAttributeType, deleteOperatorAttributeType, cancelOperatorAttributeTypeModification, loading } = useOperatorAttributeTypeForm();

  useEffect(() => {
    dispatch(operatorAttributesActions.async.getOperatorAttributeType(operatorAttributeTypeId));
  }, [ operatorAttributeTypeId ]);

  function setWorkingOperatorAttributeTypeValues(operatorAttributeType: Partial<UpdateOperatorAttributeType>) {
    dispatch(operatorAttributesActions.setWorkingUpdateOperatorAttributeTypeValues(operatorAttributeType));
  }

  function handleDelete() {
    if (!operatorAttributeType) {
      return;
    }

    deleteOperatorAttributeType(operatorAttributeType)
      .then(() => {
        history.push('/admin/operatorAttributeTypes');
      });
  }

  if (isLoadingOperatorAttributeType) {
    return (
      <FlexLoader />
    );
  }

  if (!operatorAttributeType) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader white>
        Updating Operator Attribute
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelOperatorAttributeTypeModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingOperatorAttributeType,
              children: 'Delete',
              hidden: !canDeactivate,
            }}
            saveButton={{
              onClick: () => updateOperatorAttributeType(operatorAttributeTypeId, operatorAttributeType),
              isLoading: loading.isUpdatingOperatorAttributeType,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <OperatorAttributeTypeFormControls
              operatorAttributeType={operatorAttributeType}
              setValues={setWorkingOperatorAttributeTypeValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyOperatorAttributeTypePanel;
