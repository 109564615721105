import React, { ReactNode, useState } from 'react';
import { Button, ButtonProps } from 'reactstrap';

interface Props extends ButtonProps {
  renderModal: (show: boolean, hide: () => void) => ReactNode;
}

const OpenModalButton = ({ renderModal, children, ...props }: Props) => {
  const [ isShowingModal, setIsShowingModal ] = useState(false);

  function showModal() {
    setIsShowingModal(true);
  }

  function hideModal() {
    setIsShowingModal(false);
  }

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (props.onClick) {
      props.onClick(e);
    }

    showModal();
  }

  return (
    <>
      <Button {...props} onClick={handleClick}>
        {children}
      </Button>

      {renderModal(isShowingModal, hideModal)}
    </>
  );
};

export default OpenModalButton;
