import _ from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { UserModel } from '../../models';

const getUserEntities = (state: RootState) => state.users.entities.users;
const getAllUserIds = (state: RootState) => state.users.ui.allUserIds;

export const getAllUsers = createSelector([
  getUserEntities,
  getAllUserIds,
], (
  users,
  userIds
): UserModel[] => {
  return _.map(userIds, (o) => users[o]);
});

export const getValidUsersList = createSelector([ getAllUsers ], (users) => {
  return _.filter(users || [], u => !!u.email);
});
