import appConfig from 'config';
import { commonActions } from 'Modules/common/actions';
import {
  Action,
  ApiAction,
  CreateAuditTemplate,
  CreateTemplateTask,
  TemplateTask,
  UpdateTemplateTaskPosition,
} from 'models';
import _ from 'lodash';

export const templateActions = {
  SET_SELECTED_AUDIT_TEMPLATE: 'AVSolutions/templates/SetSelectedAuditTemplate',

  GET_ALL_AUDIT_TEMPLATES_REQUEST: 'AVSolutions/templates/GetAllAuditTemplatesRequest',
  GET_ALL_AUDIT_TEMPLATES_SUCCESS: 'AVSolutions/templates/GetAllAuditTemplatesSuccess',
  GET_ALL_AUDIT_TEMPLATES_FAILURE: 'AVSolutions/templates/GetAllAuditTemplatesFailure',

  GET_AUDIT_TEMPLATE_TASKS_REQUEST: 'AVSolutions/templates/GetAuditTemplateTasksRequest',
  GET_AUDIT_TEMPLATE_TASKS_SUCCESS: 'AVSolutions/templates/GetAuditTemplateTasksSuccess',
  GET_AUDIT_TEMPLATE_TASKS_FAILURE: 'AVSolutions/templates/GetAuditTemplateTasksFailure',

  GET_PARENT_CHILDREN_TEMPLATE_TASKS_REQUEST: 'AVSolutions/templates/GetParentChildrenTemplateTasksRequest',
  GET_PARENT_CHILDREN_TEMPLATE_TASKS_SUCCESS: 'AVSolutions/templates/GetParentChildrenTemplateTasksSuccess',
  GET_PARENT_CHILDREN_TEMPLATE_TASKS_FAILURE: 'AVSolutions/templates/GetParentChildrenTemplateTasksFailure',

  GET_AUDIT_TEMPLATES_FOR_PROGRAM_REQUEST: 'AVSolutions/templates/GetAuditTemplatesForProgramRequest',
  GET_AUDIT_TEMPLATES_FOR_PROGRAM_SUCCESS: 'AVSolutions/templates/GetAuditTemplatesForProgramSuccess',
  GET_AUDIT_TEMPLATES_FOR_PROGRAM_FAILURE: 'AVSolutions/templates/GetAuditTemplatesForProgramFailure',

  UPDATE_TEMPLATE_TASK_REQUEST: 'AVSolutions/templates/UpdateTemplateTaskRequest',
  UPDATE_TEMPLATE_TASK_SUCCESS: 'AVSolutions/templates/UpdateTemplateTaskSuccess',
  UPDATE_TEMPLATE_TASK_FAILURE: 'AVSolutions/templates/UpdateTemplateTaskFailure',

  UPDATE_TEMPLATE_TASK_POSITIONS_REQUEST: 'AVSolutions/templates/UpdateTemplateTaskPositionsRequest',
  UPDATE_TEMPLATE_TASK_POSITIONS_SUCCESS: 'AVSolutions/templates/UpdateTemplateTaskPositionsSuccess',
  UPDATE_TEMPLATE_TASK_POSITIONS_FAILURE: 'AVSolutions/templates/UpdateTemplateTaskPositionsFailure',

  INSERT_TEMPLATE_TASK_REQUEST: 'AVSolutions/templates/InsertTemplateTaskRequest',
  INSERT_TEMPLATE_TASK_SUCCESS: 'AVSolutions/templates/InsertTemplateTaskSuccess',
  INSERT_TEMPLATE_TASK_FAILURE: 'AVSolutions/templates/InsertTemplateTaskFailure',

  CREATE_AUDIT_TEMPLATE_REQUEST: 'AVSolutions/templates/CreateAuditTemplateRequest',
  CREATE_AUDIT_TEMPLATE_SUCCESS: 'AVSolutions/templates/CreateAuditTemplateSuccess',
  CREATE_AUDIT_TEMPLATE_FAILURE: 'AVSolutions/templates/CreateAuditTemplateFailure',

  CREATE_AUDIT_TEMPLATE_FROM_AUDIT_REQUEST: 'AVSolutions/templates/CreateAuditTemplateFromAuditRequest',
  CREATE_AUDIT_TEMPLATE_FROM_AUDIT_SUCCESS: 'AVSolutions/templates/CreateAuditTemplateFromAuditSuccess',
  CREATE_AUDIT_TEMPLATE_FROM_AUDIT_FAILURE: 'AVSolutions/templates/CreateAuditTemplateFromAuditFailure',

  UPDATE_AUDIT_TEMPLATE_REQUEST: 'AVSolutions/templates/UpdateAuditTemplateRequest',
  UPDATE_AUDIT_TEMPLATE_SUCCESS: 'AVSolutions/templates/UpdateAuditTemplateSuccess',
  UPDATE_AUDIT_TEMPLATE_FAILURE: 'AVSolutions/templates/UpdateAuditTemplateFailure',

  DELETE_AUDIT_TEMPLATE_REQUEST: 'AVSolutions/templates/DeleteAuditTemplateRequest',
  DELETE_AUDIT_TEMPLATE_SUCCESS: 'AVSolutions/templates/DeleteAuditTemplateSuccess',
  DELETE_AUDIT_TEMPLATE_FAILURE: 'AVSolutions/templates/DeleteAuditTemplateFailure',

  DELETE_TEMPLATE_TASK_REQUEST: 'AVSolutions/templates/DeleteTemplateTaskRequest',
  DELETE_TEMPLATE_TASK_SUCCESS: 'AVSolutions/templates/DeleteTemplateTaskSuccess',
  DELETE_TEMPLATE_TASK_FAILURE: 'AVSolutions/templates/DeleteTemplateTaskFailure',
};

export const setSelectedAuditTemplate = (auditTemplateId?: number): Action => {
  return {
    type: templateActions.SET_SELECTED_AUDIT_TEMPLATE,
    payload: {
      auditTemplateId: auditTemplateId || null,
    },
  };
};

export const getAllAuditTemplates = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/templates`);
      },
      beforeRequest: () => ({ type: templateActions.GET_ALL_AUDIT_TEMPLATES_REQUEST }),
      success: (data) => ({ type: templateActions.GET_ALL_AUDIT_TEMPLATES_SUCCESS, payload: { auditTemplates: data } }),
      failure: (response) => ({ type: templateActions.GET_ALL_AUDIT_TEMPLATES_FAILURE, payload: { ...response } }),
    },
  };
};

export const getAuditTemplatesForProgram = (programId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/programs/${programId}/templates`);
      },
      beforeRequest: () => ({ type: templateActions.GET_AUDIT_TEMPLATES_FOR_PROGRAM_REQUEST }),
      success: (data) => ({ type: templateActions.GET_AUDIT_TEMPLATES_FOR_PROGRAM_SUCCESS, payload: { auditTemplates: data } }),
      failure: (response) => ({ type: templateActions.GET_AUDIT_TEMPLATES_FOR_PROGRAM_FAILURE, payload: { ...response } }),
    },
  };
};

export const getAuditTemplateTasks = (auditTemplateId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/templates/${auditTemplateId}/tasks`);
      },
      beforeRequest: () => ({ type: templateActions.GET_AUDIT_TEMPLATE_TASKS_REQUEST }),
      success: (data) => ({ type: templateActions.GET_AUDIT_TEMPLATE_TASKS_SUCCESS, payload: { auditTemplateId, templateTasks: data } }),
      failure: (response) => ({ type: templateActions.GET_AUDIT_TEMPLATE_TASKS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getParentChildrenTemplateTasks = (parentTemplateTaskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/templates/${parentTemplateTaskId}/children`);
      },
      beforeRequest: () => ({ type: templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_REQUEST, payload: { parentTemplateTaskId } }),
      success: (templateTasks) => ({ type: templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_SUCCESS, payload: { parentTemplateTaskId, templateTasks } }),
      failure: (response) => ({ type: templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_FAILURE, payload: { parentTemplateTaskId, ...response } }),
    },
  };
};

export const createAuditTemplate = (createAuditTemplate: CreateAuditTemplate): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/templates/`)
          .send(createAuditTemplate);
      },
      beforeRequest: () => ({ type: templateActions.CREATE_AUDIT_TEMPLATE_REQUEST, payload: { createAuditTemplate } }),
      success: (data) => ({ type: templateActions.CREATE_AUDIT_TEMPLATE_SUCCESS, payload: { template: data } }),
      failure: (response) => ({ type: templateActions.CREATE_AUDIT_TEMPLATE_FAILURE, payload: { ...response } }),
    },
  };
};

export const createAuditTemplateFromAudit = (operatorAuditId: number, name: string): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/templates/operatorAudit/${operatorAuditId}`)
          .query({ name });
      },
      beforeRequest: () => ({ type: templateActions.CREATE_AUDIT_TEMPLATE_FROM_AUDIT_REQUEST, payload: { createAuditTemplate } }),
      success: () => ({ type: templateActions.CREATE_AUDIT_TEMPLATE_FROM_AUDIT_SUCCESS, payload: { } }),
      failure: (response) => ({ type: templateActions.CREATE_AUDIT_TEMPLATE_FROM_AUDIT_FAILURE, payload: { ...response } }),
    },
  };
};

export const updateTemplateTask = (createAuditTemplate: CreateTemplateTask, successCallback?: (templateTask: TemplateTask) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/templates/tasks`)
          .send(createAuditTemplate);
      },
      beforeRequest: () => ({ type: templateActions.UPDATE_TEMPLATE_TASK_REQUEST, payload: { createAuditTemplate } }),
      success: (data) => {
        if (_.isFunction(successCallback)) {
          successCallback(data);
        }
        return { type: templateActions.UPDATE_TEMPLATE_TASK_SUCCESS, payload: { task: data } };
      },
      failure: (response) => ({ type: templateActions.UPDATE_TEMPLATE_TASK_FAILURE, payload: { ...response } }),
    },
  };
};

export const updateTemplateTaskPositions = (positions: UpdateTemplateTaskPosition[], successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/templates/tasks/positions`)
          .send(positions);
      },
      successCallback: successCallback,
      beforeRequest: () => ({ type: templateActions.UPDATE_TEMPLATE_TASK_POSITIONS_REQUEST, payload: { positions } }),
      success: () => ({ type: templateActions.UPDATE_TEMPLATE_TASK_POSITIONS_SUCCESS }),
      failure: (response) => ({ type: templateActions.UPDATE_TEMPLATE_TASK_POSITIONS_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertTemplateTask = (createAuditTemplate: CreateTemplateTask, successCallback?: (templateTask: TemplateTask) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/templates/tasks`)
          .send(createAuditTemplate);
      },
      beforeRequest: () => ({ type: templateActions.INSERT_TEMPLATE_TASK_REQUEST, payload: { createAuditTemplate } }),
      success: (data) => {
        if (_.isFunction(successCallback)) {
          successCallback(data);
        }

        return { type: templateActions.INSERT_TEMPLATE_TASK_SUCCESS, payload: { task: data } };
      },
      failure: (response) => ({ type: templateActions.INSERT_TEMPLATE_TASK_FAILURE, payload: { ...response } }),
    },
  };
};

export const deleteTemplateTask = (auditTemplateId: number, templateTaskId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/templates/tasks/${templateTaskId}`);
      },
      beforeRequest: () => ({ type: templateActions.DELETE_TEMPLATE_TASK_REQUEST, payload: { templateTaskId } }),
      success: () => {
        return [
          { type: templateActions.DELETE_TEMPLATE_TASK_SUCCESS, payload: { templateTaskId } },
          getAuditTemplateTasks(auditTemplateId),
        ];
      },
      failure: (response) => ({ type: templateActions.DELETE_TEMPLATE_TASK_FAILURE, payload: { ...response, templateTaskId } }),
    },
  };
};

export const deleteAuditTemplate = (auditTemplateId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/templates/${auditTemplateId}`);
      },
      beforeRequest: () => ({ type: templateActions.DELETE_AUDIT_TEMPLATE_REQUEST, payload: { auditTemplateId } }),
      success: () => ({ type: templateActions.DELETE_AUDIT_TEMPLATE_SUCCESS, payload: { auditTemplateId } }),
      failure: (response) => ({ type: templateActions.DELETE_AUDIT_TEMPLATE_FAILURE, payload: { ...response, auditTemplateId } }),
    },
  };
};
