import React from 'react';
import _ from 'lodash';

import { userConfirm } from 'core';
import { Div, FlexRow, LoadableButton, LoadableButtonProps } from 'Components';

interface ConfirmationLoadableButtonProps extends LoadableButtonProps {
  confirmationMessageTitle?: string;
  confirmationMessageText?: string;
}

interface Props {
  cancelButton?: LoadableButtonProps;
  activateButton?: ConfirmationLoadableButtonProps;
  deactivateButton?: ConfirmationLoadableButtonProps;
  saveButton?: LoadableButtonProps;
}

const EditFormFooter = ({ cancelButton, activateButton, deactivateButton, saveButton }: Props) => {
  const getConfirmationButtonClickHandler = (buttonProps?: ConfirmationLoadableButtonProps) => (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!buttonProps || !buttonProps.onClick) {
      return;
    }

    userConfirm({
      title: buttonProps.confirmationMessageTitle!,
      text: buttonProps.confirmationMessageText!,
    }, () => {
      if (buttonProps.onClick) {
        buttonProps.onClick(e);
      }
    });
  };

  const activateWithConfirmation = getConfirmationButtonClickHandler({
    ...activateButton,
    confirmationMessageTitle: activateButton?.confirmationMessageTitle || 'Confirm',
    confirmationMessageText: activateButton?.confirmationMessageText || 'Are you sure you want to activate the selected item?',
  });

  const deactivateWithConfirmation = getConfirmationButtonClickHandler({
    ...deactivateButton,
    confirmationMessageTitle: deactivateButton?.confirmationMessageTitle || 'Confirm',
    confirmationMessageText: deactivateButton?.confirmationMessageText || 'Are you sure you want to delete the selected item?',
  });

  return (
    <FlexRow className="py-2" justifyBetween alignCenter>
      <Div childSpacingX={2}>
        {cancelButton && (
          <LoadableButton
            color="light"
            {...cancelButton}
          >
            {cancelButton.children || 'Cancel'}
          </LoadableButton>
        )}
        {deactivateButton && (
          <LoadableButton
            color="danger"
            LoadingLabel="Deactivating..."
            {..._.omit(deactivateButton, 'confirmationMessageTitle', 'confirmationMessageText')}
            onClick={deactivateWithConfirmation}
          >
            {deactivateButton.children || 'Deactivate'}
          </LoadableButton>
        )}
        {activateButton && (
          <LoadableButton
            color="success"
            LoadingLabel="Activating..."
            {..._.omit(activateButton, 'confirmationMessageTitle', 'confirmationMessageText')}
            onClick={activateWithConfirmation}
          >
            {activateButton.children || 'Activate'}
          </LoadableButton>
        )}
      </Div>

      <Div childSpacingX={2}>
        {saveButton && (
          <LoadableButton
            type="submit"
            color="primary"
            LoadingLabel="Saving..."
            {...saveButton}
          >
            {saveButton.children || 'Save'}
          </LoadableButton>
        )}
      </Div>
    </FlexRow>
  );
};

export default EditFormFooter;
