import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { ProgramsState } from './reducer';
import { programsActions } from './actions';

const loadingConfigs: LoadingActionConfig<ProgramsState>[] = [
  {
    start: programsActions.GET_PROGRAM_REQUEST,
    stop: [ programsActions.GET_PROGRAM_SUCCESS, programsActions.GET_PROGRAM_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingProgram: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.GET_ALL_PROGRAMS_REQUEST,
    stop: [ programsActions.GET_ALL_PROGRAMS_SUCCESS, programsActions.GET_ALL_PROGRAMS_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingAllPrograms: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.INSERT_PROGRAM_REQUEST,
    stop: [ programsActions.INSERT_PROGRAM_SUCCESS, programsActions.INSERT_PROGRAM_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isInsertingProgram: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.UPDATE_PROGRAM_REQUEST,
    stop: [ programsActions.UPDATE_PROGRAM_SUCCESS, programsActions.UPDATE_PROGRAM_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isUpdatingProgram: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.DELETE_PROGRAM_REQUEST,
    stop: [ programsActions.DELETE_PROGRAM_SUCCESS, programsActions.DELETE_PROGRAM_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingProgram: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.INSERT_PROGRAM_TEMPLATE_XREF_REQUEST,
    stop: [ programsActions.INSERT_PROGRAM_TEMPLATE_XREF_SUCCESS, programsActions.INSERT_PROGRAM_TEMPLATE_XREF_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isInsertingProgramTemplateXref: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.DELETE_PROGRAM_TEMPLATE_XREF_REQUEST,
    stop: [ programsActions.DELETE_PROGRAM_TEMPLATE_XREF_SUCCESS, programsActions.DELETE_PROGRAM_TEMPLATE_XREF_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingProgramTemplateXref: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.GET_PROGRAM_FOLDERS_REQUEST,
    stop: [ programsActions.GET_PROGRAM_FOLDERS_SUCCESS, programsActions.GET_PROGRAM_FOLDERS_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingProgramFolders: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.INSERT_PROGRAM_FOLDER_REQUEST,
    stop: [ programsActions.INSERT_PROGRAM_FOLDER_SUCCESS, programsActions.INSERT_PROGRAM_FOLDER_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isInsertingProgramFolder: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.UPDATE_PROGRAM_FOLDER_REQUEST,
    stop: [ programsActions.UPDATE_PROGRAM_FOLDER_SUCCESS, programsActions.UPDATE_PROGRAM_FOLDER_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isUpdatingProgramFolder: isLoading,
        },
      };
    },
  },
  {
    start: programsActions.DELETE_PROGRAM_FOLDER_REQUEST,
    stop: [ programsActions.DELETE_PROGRAM_FOLDER_SUCCESS, programsActions.DELETE_PROGRAM_FOLDER_FAILURE ],
    updater: (state: ProgramsState, isLoading): ProgramsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingProgramFolder: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
