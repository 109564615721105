import _ from 'lodash';
import { format, parse } from 'date-fns';
import { Filter, RowInfo } from 'react-table';

export const filterCaseInsensitiveContains = (filter: Filter, row: any): boolean => {
  const id = filter.pivotId || filter.id;
  if (row[id]) {
    return (
      row[id] !== undefined
        ? _.includes(String(row[id].toString().toLowerCase()), filter.value.toLowerCase())
        : true
    );
  }

  return false;
};

export const baseTableOptions = {
  showPageJump: false,
  className: '-striped -hover', // default class name
  minRows: 0, // show no rows by default
  defaultFilterMethod: filterCaseInsensitiveContains,
};

export const renderDate = (property: string) => {
  return {
    accessor: property,
    Cell: ({ original }: RowInfo) => {
      const date = original[property];

      if (!date) {
        return null;
      }

      return format(parse(date, 'yyyy-MM-dd', new Date()), 'LLLL do, yyyy');
    },
  };
};
