import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import FlexRow from '../../../Components/FlexRow';
import { LoadableButton } from '../../../Components';
import { preventDefaultAnd } from '../../../helpers/eventHelpers';
import { OperatorGroup } from '../../../Modules/operatorGroups/models';
import { useSelector } from 'react-redux';
import { operatorGroupsSelectors } from '../../../Modules/operatorGroups';

interface LocalProps {
  operatorGroup: OperatorGroup;
  saveOperatorGroup: (operatorGroup: OperatorGroup) => void;
}

const ModifyOperatorGroupForm = ({ operatorGroup, ...props }: LocalProps) => {
  const [ workingOperatorGroupValues, setWorkingOperatorGroupValues ] = useState<Partial<OperatorGroup>>({});
  const isSavingOperatorGroup = useSelector(operatorGroupsSelectors.maintenance.isSavingOperatorGroup);

  useEffect(() => {
    setWorkingOperatorGroupValues({});
  }, [ operatorGroup.operatorGroupId ]);

  const mergedOperatorGroup: OperatorGroup = {
    ...operatorGroup,
    ...workingOperatorGroupValues,
  };

  function save () {
    props.saveOperatorGroup(mergedOperatorGroup);
  }

  return (
    <form onSubmit={preventDefaultAnd(save)}>
      <Row>
        <Col md={5}>
          <h5>Operator Group</h5>

          <FormGroup>
            <Label>Name</Label>
            <Input value={mergedOperatorGroup.name} onChange={(e) => setWorkingOperatorGroupValues({ ...workingOperatorGroupValues, name: e.target.value })} />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-2 pt-2 border-top">
        <Col md={12}>
          <FlexRow justifyEnd>
            <LoadableButton isLoading={isSavingOperatorGroup} LoadingLabel="Saving..." color="primary" type="submit">
              Save Operator Group
            </LoadableButton>
          </FlexRow>
        </Col>
      </Row>
    </form>
  );
};

export default ModifyOperatorGroupForm;
