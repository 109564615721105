import { ReactNode } from 'react';
import _ from 'lodash';

import { useStateSelector } from 'hooks';
import { CategoryModel } from 'Modules/categories/models';

interface Props {
  appendUndefined: boolean;
  children: (providedProps: { sortedCategories: (CategoryModel | undefined)[] }) => ReactNode;
}

const WithCategories = ({ appendUndefined, children }: Props): ReactNode => {
  const categories = useStateSelector(state => _.map(state.categories.ui.allCategoryIds, (c) => state.categories.entities.categories[c]));
  const sortedCategories = _.sortBy(categories, c => c.name);

  return children({ sortedCategories: appendUndefined ? [ ...sortedCategories, undefined ] : sortedCategories });
};

export default WithCategories;
