import React from 'react';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { format, parseISO } from 'date-fns';

import { ReadableTimestamp } from 'Components';

import { ChatMessage } from './models';
import DraftViewer from 'Components/Draft/Viewer/DraftViewer';

interface Props {
  message: ChatMessage;
  isExpanded: boolean;
  shouldShowSenderName: boolean;
  setExpanded: (messageId: number) => void;
}

const ChatMessageBubble = function ({ message, isExpanded, shouldShowSenderName, ...props }: Props) {
  return (
    <div className="mb-2">
      <div className={classnames({ 'chat-row': true, 'from-me': message.fromMe, 'collapsed': !isExpanded })}>
        <div>
          {shouldShowSenderName && <div className="chat-sender-name">{message.senderName} </div>}
          <div className="chat-bubble" onClick={() => props.setExpanded(message.id)}>
            <DraftViewer content={message.text} />
          </div>
        </div>
      </div>

      <Collapse className={classnames({ 'timestamp-row': true, 'from-me': message.fromMe })} isOpen={isExpanded}>
        <div className="text-right">
          <small title={format(parseISO(message.createdTimestamp), 'PPP pp')}><ReadableTimestamp
            value={message.createdTimestamp}/></small>
        </div>
      </Collapse>
    </div>
  );
};

export default ChatMessageBubble;
