import React from 'react';
import DashboardCard from './DashboardCard';
import _ from 'lodash';
import { pluralCoalesce } from '../../../helpers/listHelpers';

interface LocalProps {
    tasksForThisWeek: number | any[];
}

const NumAuditsCurrentWeekCard = ({ tasksForThisWeek }: LocalProps) => {
  return (
    <DashboardCard
      icon={<i className="mdi mdi-playlist-remove mdi-36px" />}
      heading="Tasks this Week"
      body={`${_.isArray(tasksForThisWeek) ? _.size(tasksForThisWeek) : tasksForThisWeek} ${pluralCoalesce(tasksForThisWeek, 'Task', 'Tasks')}`}
    />
  );
};

export default NumAuditsCurrentWeekCard;
