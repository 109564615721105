import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import useStateSelector from 'hooks/useStateSelector';
import { getSafetyCoachDashboardDashboard } from 'Modules/dashboard/actions';
import { getCurrentUserSafetyCoachDashboard } from 'Modules/dashboard/selectors';
import FlexLoaderPanel from 'Components/FlexLoaderPanel';

import SafetyCoachDashboard from './SafetyCoachDashboard';

const SafetyCoachDashboardPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dashboard = useStateSelector(getCurrentUserSafetyCoachDashboard);
  const isLoadingSafetyCoachDashboard = useStateSelector(state => state.dashboard.loading.isLoadingSafetyCoachDashboard);

  useEffect(() => {
    dispatch(getSafetyCoachDashboardDashboard());
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('safetyCoach')} Dashboard</title>
      </Helmet>

      <div>
        <FlexLoaderPanel loadingClassName="p-3" isLoading={isLoadingSafetyCoachDashboard}>
          {dashboard && (
            <SafetyCoachDashboard dashboard={dashboard}/>
          )}
        </FlexLoaderPanel>
      </div>
    </>
  );
};

export default SafetyCoachDashboardPage;
