import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

import config from 'config';
import { useStateSelector } from 'hooks';
import {
  getCurrentUserNotificationCount,
  getCurrentUserNotifications,
  getNextUserNotificationPageRequest,
} from 'Modules/userNotifications/selectors';
import {
  markAllUserNotificationsViewed,
  markNotificationRead,
  searchMyNotifications,
} from 'Modules/userNotifications/actions';
import { setGlobalSelectedTask } from 'Modules/tasks/actions';
import { UserNotification } from 'Modules/userNotifications/models';
import { sOrNoS } from 'helpers/listHelpers';
import { FlexColumn, FlexLoaderPanel, FlexRow, RealtimeHumanizeDateTime } from 'Components';

interface Props {
  closeSidebar: () => void;
}

const NotificationSidebar = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const isLoadingMyNotificationCount = useStateSelector(state => state.userNotifications.loading.isLoadingMyNotificationCount);
  const operatorUserXrefs = useStateSelector(state => state.site.user?.operatorUserXrefs || []);
  const currentUserNotificationCount = useStateSelector(getCurrentUserNotificationCount);
  const currentUserNotifications = useStateSelector(getCurrentUserNotifications);
  const nextUserNotificationPageRequest = useStateSelector(getNextUserNotificationPageRequest);

  useEffect(() => {
    dispatch(searchMyNotifications(config.defaultPagination));
  }, []);

  function markAllRead() {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Mark All Read',
        text: 'Are you sure you want to mark all notifications as read?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      dispatch(markAllUserNotificationsViewed());
    })();
  }

  function markAsReadAndClose(notification: UserNotification) {
    props.closeSidebar();

    if (!notification.viewed) {
      dispatch(markNotificationRead(notification.notificationId));
    }
  }

  function loadMore() {
    if (!nextUserNotificationPageRequest) {
      return;
    }

    dispatch(searchMyNotifications(nextUserNotificationPageRequest));
  }

  return (
    <FlexColumn className="right-bar">
      <div className="rightbar-title">
        <Button color="anchor" className="right-bar-toggle float-right" onClick={props.closeSidebar}>
          <i className="mdi mdi-close"/>
        </Button>
        <h4 className="m-0 text-white">Notifications</h4>
      </div>

      <FlexLoaderPanel overflowHidden isLoading={isLoadingMyNotificationCount} style={{ minWidth: 400 }}>
        <FlexRow alignCenter justifyBetween className="px-2 py-1 border-bottom">
          <div>
            <span>{currentUserNotificationCount || 0} Unread Notification{sOrNoS(currentUserNotificationCount || 0)}</span>
          </div>
          <div>
            <Button color="light" size="sm" onClick={markAllRead}>
              Mark All Read
            </Button>
          </div>
        </FlexRow>
        <FlexColumn fill className="overflow-auto">
          {
            _.map(currentUserNotifications, (notification) => {
              const shouldHideOperatorName = _.size(operatorUserXrefs) === 1 && operatorUserXrefs[0].operatorId === notification.extraData?.operatorId;

              return (
                <Button
                  key={notification.notificationId}
                  color="anchor"
                  className={classnames('user-notification', { 'unread': !notification.viewed })}
                  onClick={() => {
                    markAsReadAndClose(notification);
                    dispatch(setGlobalSelectedTask(notification.extraData?.taskId));
                  }}
                >
                  <FlexColumn>
                    <div>
                      {notification.subject}
                    </div>

                    {!shouldHideOperatorName && !!notification.extraData?.operatorName && (
                      <div className="text-muted">
                        <small>{notification.extraData.operatorName}</small>
                      </div>
                    )}

                    <div>
                      <RealtimeHumanizeDateTime date={notification.createdTimestamp}/>
                    </div>
                  </FlexColumn>
                </Button>
              );
            })
          }

          {nextUserNotificationPageRequest && (
            <FlexRow className="py-1" justifyCenter>
              <Button color="light" size="sm" block onClick={loadMore}>Load More</Button>
            </FlexRow>
          )}
        </FlexColumn>
      </FlexLoaderPanel>
    </FlexColumn>
  );
};

export default NotificationSidebar;
