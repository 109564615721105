import './base-table.scss';
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTable, { RowInfo, TableProps } from 'react-table';

/* eslint react/no-find-dom-node:0 */

export interface BaseTableProps extends Partial<TableProps> {
  allowColumnOverflow?: boolean;
  filterOverflowVisible?: boolean;
  borderless?: boolean;
  material?: boolean;
  flex?: boolean;
  select?: {
    selected?: { [id: number]: any } | { [id: string]: any };
    idAccessor?: (item: any) => number | string;
    rowClick?: boolean;
    onSelected?: (item: any, isSelected: boolean) => void;
  };
}

export class BaseTable extends React.Component<BaseTableProps> {
  static defaultProps: Partial<BaseTableProps> = {
    showPageJump: false,
    className: '-striped -hover',
  }

  private reactTableRef: any;

  private refNode: any;

  private refNodeBody: any;

  private refNodeHeader: any;

  private refNodeFilter: any;

  private debouncedHandleResize: (refNodeHeader: any, refNodeBody: any) => void;

  constructor (props: BaseTableProps) {
    super(props);

    this.debouncedHandleResize = _.debounce(this.handleResize, 10);
  }

  public componentDidMount () {
    this.refNode = ReactDOM.findDOMNode(this.reactTableRef) as HTMLInputElement;
    this.refNodeBody = this.refNode.getElementsByClassName('rt-tbody')[0];
    this.refNodeHeader = this.refNode.getElementsByClassName('rt-thead -header')[0];
    this.refNodeFilter = this.refNode.getElementsByClassName('rt-thead -filters')[0];

    this.refNodeBody.addEventListener('scroll', _.throttle((e: any) => this.handleScroll(e, this.refNodeHeader)), 10);
    window.addEventListener('resize', _.throttle(() => this.handleResize(), 10));

    this.setScrollbarPadding(this.refNodeHeader, this.refNodeBody);
    this.handleResize();

    if (this.refNodeFilter) {
      this.refNodeBody.addEventListener('scroll', _.debounce((e: any) => this.handleScroll(e, this.refNodeFilter)), 10);
      window.addEventListener('resize', _.debounce(() => this.handleResize(), 10));

      this.setScrollbarPadding(this.refNodeFilter, this.refNodeBody);
      this.handleResize();
    }
  }

  public componentDidUpdate () {
    this.refreshScrollbars();
  }

  public componentWillUnmount () {
    this.refNodeBody.removeEventListener('scroll', _.debounce((e: any) => this.handleScroll(e, this.refNodeHeader)), 10);
    window.removeEventListener('resize', _.debounce(() => this.handleResize(), 10));
  }

  private refreshScrollbars = () => {
    this.refreshScrollbarPadding();

    if (this.refNodeHeader && this.refNodeFilter && this.refNodeBody) {
      this.refNodeHeader.scrollLeft = this.refNodeBody.scrollLeft;
      this.refNodeFilter.scrollLeft = this.refNodeBody.scrollLeft;
    }
  }

  private refreshScrollbarPadding = () => {
    this.setScrollbarPadding(this.refNodeHeader, this.refNodeBody);
    if (this.refNodeFilter) {
      this.setScrollbarPadding(this.refNodeFilter, this.refNodeBody);
    }
  }

  public setScrollbarPadding = (refNodeHeader: any, refNodeBody: any) => {
    const hasVerticalScrollbar = refNodeBody.scrollHeight - 1 > refNodeBody.clientHeight;

    if (refNodeHeader) {
      if (hasVerticalScrollbar) {
        if (!refNodeHeader.classList.contains('rt-thead-scrollbar-padding')) {
          refNodeHeader.classList.add('rt-thead-scrollbar-padding');
        }
      } else if (refNodeHeader.classList.contains('rt-thead-scrollbar-padding')) {
        refNodeHeader.classList.remove('rt-thead-scrollbar-padding');
      }
    }
  };

  public handleResize () {
    this.refreshScrollbarPadding();
  }

  public handleScroll (event: any, refNodeHeader: any) {
    refNodeHeader.scrollLeft = event.target.scrollLeft;
    this.refreshScrollbarPadding();
  }

  private filter = (filter: any, row: any) => {
    const rowData = (String(row[filter.id]) || '').toLowerCase();
    const filterData = filter.value.toLowerCase();
    return rowData.includes(filterData);
  };

  private assignRef = (r: any) => {
    this.reactTableRef = r;
  };

  private getTrProps = (finalState: any, rowInfo: RowInfo, instance: any) => {
    const { select } = this.props;

    if (rowInfo && select && select.onSelected) {
      const isSelected = select.idAccessor && _.has(select.selected, select.idAccessor(rowInfo.original));

      const overridden: any = {};

      if (select.rowClick) {
        overridden.onClick = () => {
          select.onSelected!(rowInfo.original, !isSelected);
        };
      }

      overridden.className = classnames({ 'selected-row': isSelected });

      return {
        ...this.props.getTrProps,
        ...overridden,
      };
    }

    return () => (this.props.getTrProps ? this.props.getTrProps(finalState, rowInfo as any, instance) : undefined);
  };

  private onResizedChange = () => {
    if (this.refNodeFilter) {
      this.debouncedHandleResize(this.refNodeFilter, this.refNodeBody);
    }
  };

  public render () {
    const { className, borderless, allowColumnOverflow, filterOverflowVisible, material, flex, select, filterable, ...rest } = this.props;
    return (
      <ReactTable
        showPageJump={false}
        getTrProps={this.getTrProps as any}
        {...rest}
        defaultFilterMethod={this.filter}
        filterable
        className={classnames(className, 'base-table', {
          'allow-overflow': allowColumnOverflow,
          'borderless': borderless,
          'filter-overflow-visible': filterOverflowVisible,
          'material-table': material,
          'flex-table': flex,
          'select-table': select?.rowClick,
          'filters-active': filterable,
        })}
        ref={(r: any) => this.assignRef(r)}
        onResizedChange={this.onResizedChange}
        onPageSizeChange={this.onResizedChange}
      />
    );
  }
}

export default BaseTable;
