import { PaginatedRequest } from 'core';
import _ from 'lodash';

export interface PaginationResultState {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
}

export interface PaginatedSearchContainer {
  total: number;
  request: PaginatedRequest;
  ids: number[];
}

export interface PaginatedSearchResult<TResult> {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  items: TResult[];
}

export interface PaginatedSearchResponse<TResult> {
  request: PaginatedRequest;
  pagedResult: PaginatedSearchResult<TResult>;
}

export const getPaginatedResponse = <T>(request: PaginatedRequest, result: PaginatedSearchResult<T>): PaginatedSearchResponse<T> => {
  return {
    request,
    pagedResult: result,
  };
};

export const convertPaginatedSearchResult = <T, K>(paginatedSearchResult: PaginatedSearchResult<T>, converter: (item: T) => K): PaginatedSearchResult<K> => {
  return {
    ...paginatedSearchResult,
    items: _.map(paginatedSearchResult.items, (item) => converter(item)),
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const convertPaginatedSearchResultToState = <T>({ items, ...rest }: PaginatedSearchResult<T>): PaginationResultState => {
  return {
    ...rest,
  };
};

export const convertPaginatedResponse = <T, K>(input: PaginatedSearchResponse<T>, converter: (item: T) => K): PaginatedSearchResponse<K> => {
  return {
    ...input,
    pagedResult: convertPaginatedSearchResult(input.pagedResult, converter),
  };
};

export const updatePaginationOnUpdate = (request: PaginationResultState | undefined, numItemsAdded: number): PaginationResultState | undefined => {
  if (!request) {
    return undefined;
  }

  const updatedTotal = request.totalItems + numItemsAdded;
  const updatedTotalPages = Math.ceil(updatedTotal / request.itemsPerPage);

  return {
    ...request,
    totalItems: updatedTotal,
    totalPages: updatedTotalPages,
    currentPage: updatedTotalPages > request.totalPages ? request.currentPage + 1 : request.currentPage,
  };
};

export const updatePaginationOnAdd = (request: PaginationResultState | undefined, numItemsAdded = 1): PaginationResultState | undefined => {
  if (!request) {
    return undefined;
  }

  return updatePaginationOnUpdate(request, numItemsAdded);
};

export const updatePaginationOnDelete = (request: PaginationResultState | undefined, numItemsDeleted = 1): PaginationResultState | undefined => {
  if (!request) {
    return undefined;
  }

  return updatePaginationOnUpdate(request, numItemsDeleted * -1);
};

export const getNextPageRequest = (currentPagination?: PaginationResultState): PaginatedRequest | undefined => {
  if (!currentPagination) {
    return undefined;
  }

  if (currentPagination.currentPage >= currentPagination.totalPages) {
    return undefined;
  }

  return {
    pageSize: currentPagination.itemsPerPage,
    offset: (currentPagination.currentPage) * currentPagination.itemsPerPage,
  };
};
