import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

interface Props {
  addTask: () => void;
  block?: boolean;
  addParentTask: () => void;
}

const AddTaskDropdown = ({ block, ...props }: Props) => {
  return (
    <UncontrolledButtonDropdown className="btn-block">
      <DropdownToggle block={block} color="primary" caret>
        Add Task
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => props.addTask()}>Add Task</DropdownItem>
        <DropdownItem onClick={() => props.addParentTask()}>Add Module</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default AddTaskDropdown;
