import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreateOperator } from 'Modules/operators/models';
import { operatorsActions } from 'Modules/operators';
import { getNewOperator } from 'Modules/operators/instantiations';
import { useMergedEntity, useOperatorForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, OperatorForm, Panel, PanelHeader } from 'Components';
import { OperatorModel } from 'models';

const CreateOperatorPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootOperator = useMemo(() => getNewOperator(), []);
  const workingOperator = useStateSelector((state) => state.operators.ui.maintenance.workingCreateOperator);
  const operator = useMergedEntity(rootOperator, workingOperator);
  const { insertOperator, cancelOperatorModification, loading } = useOperatorForm();

  function setWorkingOperatorValues(operator: Partial<CreateOperator>) {
    dispatch(operatorsActions.setWorkingCreateOperatorValues(operator));
  }

  function handleSave() {
    if (!operator) {
      return;
    }
    insertOperator(operator)
      .then((operator: OperatorModel) => {
        history.push(`/admin/operators/${operator.operatorId}`);
      });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New Operator</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelOperatorModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingOperator,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <OperatorForm
              isCreatingNewOperator
              operator={operator}
              setValues={setWorkingOperatorValues}
              users={[]}
              allUsers={[]}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreateOperatorPanel;
