import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';
import { getValueByKey } from 'core';
import {
  CreateShareBoardCategoryTag,
  ShareBoardCategoryTag,
  UpdateShareBoardCategoryTag,
} from 'Modules/shareBoardCategoryTags/models';
import { commonActions } from 'Modules/common/actions';

export interface ShareBoardCategoryTagsState {
  entities: {
    shareBoardCategoryTags: { [shareBoardCategoryTagId: number]: ShareBoardCategoryTag };
  };
  xrefs: {
    shareBoardCategoryShareBoardCategoryTags: { [shareBoardCategoryId: number]: number[] };
  };
  ui: {
    maintenance: {
      workingCreateShareBoardCategoryTag: Partial<CreateShareBoardCategoryTag>;
      workingUpdateShareBoardCategoryTag: Partial<UpdateShareBoardCategoryTag>;
    }
  };
  loading: {
    isLoadingShareBoardCategoryTag: boolean;
    isLoadingAllShareBoardCategoryTags: boolean;
    isUpdatingShareBoardCategoryTag: boolean;
    isInsertingShareBoardCategoryTag: boolean;
    isDeletingShareBoardCategoryTag: boolean;
  };
}

const initialShareBoardCategoryTagsState: ShareBoardCategoryTagsState = {
  entities: {
    shareBoardCategoryTags: {},
  },
  xrefs: {
    shareBoardCategoryShareBoardCategoryTags: {},
  },
  ui: {
    maintenance: {
      workingCreateShareBoardCategoryTag: {},
      workingUpdateShareBoardCategoryTag: {},
    },
  },
  loading: {
    isLoadingShareBoardCategoryTag: false,
    isLoadingAllShareBoardCategoryTags: false,
    isUpdatingShareBoardCategoryTag: false,
    isInsertingShareBoardCategoryTag: false,
    isDeletingShareBoardCategoryTag: false,
  },
};

export const shareBoardCategoryTagsSlice = createSlice({
  name: 'ShareBoardCategoryTags',
  initialState: initialShareBoardCategoryTagsState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<ShareBoardCategoryTagsState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setWorkingCreateShareBoardCategoryTagValues: (state, action: PayloadAction<Partial<CreateShareBoardCategoryTag>>) => {
      state.ui.maintenance.workingCreateShareBoardCategoryTag = {
        ...state.ui.maintenance.workingCreateShareBoardCategoryTag,
        ...action.payload,
      };
    },
    setWorkingUpdateShareBoardCategoryTagValues: (state, action: PayloadAction<Partial<UpdateShareBoardCategoryTag>>) => {
      state.ui.maintenance.workingUpdateShareBoardCategoryTag = {
        ...state.ui.maintenance.workingUpdateShareBoardCategoryTag,
        ...action.payload,
      };
    },
    cancelShareBoardCategoryTagModification: (state) => {
      state.ui.maintenance.workingUpdateShareBoardCategoryTag = {};
      state.ui.maintenance.workingCreateShareBoardCategoryTag = {};
    },
    setShareBoardCategoryTag: (state, action: PayloadAction<ShareBoardCategoryTag>) => {
      state.entities.shareBoardCategoryTags = {
        ...state.entities.shareBoardCategoryTags,
        [action.payload.id]: action.payload,
      };

      state.xrefs.shareBoardCategoryShareBoardCategoryTags[action.payload.shareBoardCategoryId] = _.union(state.xrefs.shareBoardCategoryShareBoardCategoryTags[action.payload.shareBoardCategoryId], [ action.payload.id ]);
    },
    setShareBoardCategoryTags: (state, action: PayloadAction<{ shareBoardCategoryId: number; tags: ShareBoardCategoryTag[] }>) => {
      state.entities.shareBoardCategoryTags = {
        ...state.entities.shareBoardCategoryTags,
        ..._.keyBy(action.payload.tags, a => a.id),
      };

      state.xrefs.shareBoardCategoryShareBoardCategoryTags[action.payload.shareBoardCategoryId] = action.payload.tags.map(a => a.id);
    },
    shareBoardCategoryTagInserted: (state, action: PayloadAction<ShareBoardCategoryTag>) => {
      state.entities.shareBoardCategoryTags[action.payload.id] = action.payload;
      state.xrefs.shareBoardCategoryShareBoardCategoryTags[action.payload.shareBoardCategoryId] = _.union(state.xrefs.shareBoardCategoryShareBoardCategoryTags[action.payload.shareBoardCategoryId], [ action.payload.id ]);
    },
    shareBoardCategoryTagUpdated: (state, action: PayloadAction<ShareBoardCategoryTag>) => {
      state.entities.shareBoardCategoryTags[action.payload.id] = action.payload;
    },
    shareBoardCategoryTagDeleted: (state, action: PayloadAction<number>) => {
      const existingShareBoardCategoryTag = getValueByKey(state.entities.shareBoardCategoryTags, action.payload);
      if(!existingShareBoardCategoryTag) {
        return;
      }

      state.entities.shareBoardCategoryTags = _.omit(state.entities.shareBoardCategoryTags, action.payload);
      state.xrefs.shareBoardCategoryShareBoardCategoryTags[existingShareBoardCategoryTag.shareBoardCategoryId] = _.without(state.xrefs.shareBoardCategoryShareBoardCategoryTags[existingShareBoardCategoryTag.shareBoardCategoryId], action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(commonActions.PATH_LOCATION_CHANGE, (state) => {
        state.ui.maintenance.workingCreateShareBoardCategoryTag = {};
        state.ui.maintenance.workingUpdateShareBoardCategoryTag = {};
      })
      .addCase(commonActions.AREA_LOCATION_CHANGE, () => {
        return { ...initialShareBoardCategoryTagsState };
      });
  },
});
