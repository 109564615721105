import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const makeGetShareBoardCategoryTags = (shareBoardCategoryId: number) => createSelector([
  (state: RootState) => state.shareBoardCategoryTags.entities.shareBoardCategoryTags,
  (state: RootState) => state.shareBoardCategoryTags.xrefs.shareBoardCategoryShareBoardCategoryTags,
], (
  shareBoardCategoryTags,
  shareBoardCategoryShareBoardCategoryTags) => {
  return _.sortBy(_.map(shareBoardCategoryShareBoardCategoryTags[shareBoardCategoryId], id => shareBoardCategoryTags[id]), o => o.name);
});

