import _ from 'lodash';
import { createSelector } from 'reselect';

import { RootState } from 'Modules/reducers';
import { TemplateTask, TemplateTaskModel } from '../../models';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';

const getAuditTemplates = (state: RootState) => state.templates.entities.auditTemplates;
const getTemplateTasks = (state: RootState) => state.templates.entities.templateTasks;
const getAllAuditTemplateIds = (state: RootState) => state.templates.ui.allAuditTemplateIds;
const getSelectedTemplateId = (state: RootState) => state.templates.ui.selectedTemplateId;
const getAuditTemplateTaskIds = (state: RootState) => state.templates.mappings.auditTemplateTasks;
const getTemplateTaskChildren = (state: RootState) => state.templates.mappings.templateTaskChildren;

export const getSelectedTemplateTasks = createSelector([
  getTemplateTasks,
  getAuditTemplateTaskIds,
  getSelectedTemplateId,
],
(
  templateTasks,
  auditTemplateTaskIds,
  selectedTemplateId
): TemplateTask[] => {
  if (!selectedTemplateId) {
    return [];
  }

  return _.filter(getNumberMappedEntities(templateTasks, auditTemplateTaskIds[selectedTemplateId]), t => !t.parentTemplateTaskId);
});

export const getSelectedTemplateTaskModel = createSelector([
  getSelectedTemplateTasks,
],
(tasks): TemplateTaskModel => {
  const weeklyTasks = _.filter(tasks, t => !!t.weekIndex);
  const sortedWeeklyTasks = _.sortBy(weeklyTasks, t => t.position);
  const tasksPerWeek = _.groupBy(sortedWeeklyTasks, t => t.weekIndex);
  const persistentTasks = _.filter(tasks, t => !t.weekIndex);

  return {
    tasksPerWeek: tasksPerWeek,
    persistentTasks: _.sortBy(persistentTasks, t => t.position),
    numWeeklyTasks: _.size(sortedWeeklyTasks),
    numPersistentTasks: _.size(persistentTasks),
  };
});

export const selectAllTemplates = createSelector(
  [
    getAuditTemplates,
    getAllAuditTemplateIds,
  ],
  (
    auditTemplates,
    allAuditTemplateIds
  ) => {
    return _.sortBy(getNumberMappedEntities(auditTemplates, allAuditTemplateIds), t => t.name);
  }
);

export const selectSelectedTemplate = createSelector(
  [
    getAuditTemplates,
    getSelectedTemplateId,
  ],
  (
    auditTemplates,
    selectedTemplateId
  ) => {
    if (!selectedTemplateId) {
      return undefined;
    }

    return auditTemplates[selectedTemplateId];
  }
);

export const selectTemplateTaskChildren = (parentTaskId?: number) => createSelector([
  getTemplateTasks,
  getTemplateTaskChildren,
], (
  tasks,
  taskChildren
) => {
  if (!parentTaskId) {
    return [];
  }

  return _.sortBy(_.map(taskChildren[parentTaskId], (taskId) => tasks[taskId]), t => t.position);
});
