import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import appConfig from 'config';
import UserXrefType from 'constants/UserXrefType';
import { RootState } from 'Modules/reducers';
import { OperatorFeatureEnum, OperatorModel, OperatorUserXrefModel } from 'models';
import {
  CreateOperator,
  OperatorFeature,
  operatorsActions,
  operatorsSlice,
  OperatorsState,
  UpdateOperator,
} from 'Modules/operators';

const operatorsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: OperatorsState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(operatorsSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getOperator = (operatorId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState, isLoading) => operatorsState.loading.isLoadingOperator = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operators/${operatorId}`),
    onSuccess: (data: OperatorModel) => {
      dispatch(operatorsActions.setOperator(data));
    },
  });
};

export const getAllOperators = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState, isLoading) => operatorsState.loading.isLoadingAllOperators = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operators`),
    onSuccess: (data: OperatorModel[]) => {
      dispatch(operatorsActions.setAllOperators(data));
    },
  });
};

export const getCurrentUserOperators = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState, isLoading) => operatorsState.loading.isLoadingCurrentUserOperators = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operators/mine`),
    onSuccess: (data: OperatorModel[]) => {
      dispatch(operatorsActions.setCurrentUserOperators(data));
    },
  });
};

export const getAllOperatorUserXrefs = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState, isLoading) => operatorsState.loading.isLoadingOperatorUserXrefs = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operatorUserXrefs`),
    onSuccess: (data: OperatorUserXrefModel[]) => {
      dispatch(operatorsActions.setAllOperatorUserXrefs(data));
    },
  });
};

export const getOperatorFeatures = (operatorId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState, isLoading) => operatorsState.loading.isLoadingOperatorFeatures = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/features`),
    onSuccess: (data: OperatorFeature[]) => {
      dispatch(operatorsActions.setOperatorFeatures({ operatorId, features: data }));
    },
  });
};

export const insertOperator = (request: CreateOperator): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isInsertingOperator = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/operators`).send(request),
    onSuccess: (data: OperatorModel) => {
      dispatch(operatorsActions.onOperatorInserted(data));
    },
  });
};

export const enableOperatorFeature = (operatorId: number, featureId: OperatorFeatureEnum): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isInsertingOperator = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/features/${featureId}`),
    onSuccess: (data: OperatorFeature) => {
      dispatch(operatorsActions.onOperatorFeatureEnabled(data));
    },
  });
};

export const disableOperatorFeature = (operatorId: number, featureId: OperatorFeatureEnum): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isInsertingOperator = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.del(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/features/${featureId}`),
    onSuccess: (data: OperatorModel) => {
      dispatch(operatorsActions.onOperatorFeatureDisabled({ operatorId, featureId }));
    },
  });
};

export const updateOperator = (operatorId: number, request: UpdateOperator): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isUpdatingOperator = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/operators/${operatorId}`).send(request),
    onSuccess: (data: OperatorModel) => {
      dispatch(operatorsActions.onOperatorUpdated(data));
    },
  });
};

export const mergeOperatorUserXrefs = (operatorId: number, userXrefTypeId: UserXrefType, userIds: string[]): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isUpdatingOperatorDictionary = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/operatorUserXrefs/operator/${operatorId}/merge/${userXrefTypeId}`).send(userIds),
    onSuccess: (data: OperatorUserXrefModel[]) => {
      dispatch(operatorsActions.mergeOperatorUserXrefs({ operatorId, operatorUserXrefs: data }));
    },
  });
};

export const setProfilePicture = (operatorId: number, file: File, callback?: () => void): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  const formData = new window.FormData();

  formData.append('imageFile', file);

  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isSettingProfilePicture = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/image`)
        .send(formData);
    },
    onSuccess: (data: OperatorModel) => {
      dispatch(operatorsActions.setProfilePictureSuccess(data));
      callback?.();
    },
  });
};

export const deleteOperator = (operator: OperatorModel): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isDeletingOperator = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/operators/${operator.operatorId}`),
    onSuccess: () => {
      dispatch(operatorsActions.onOperatorDeleted(operator));
    },
  });
};

export const removeProfilePicture = (operatorId: number, callback?: () => void): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: operatorsLoadingHandler(dispatch, (operatorsState: OperatorsState, isLoading: boolean) => operatorsState.loading.isRemovingProfilePicture = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.del(`${appConfig.baseUrl}/api/v1/operators/${operatorId}/image`);
    },
    onSuccess: () => {
      dispatch(operatorsActions.removeProfilePictureSuccess(operatorId));
      callback?.();
    },
  });
};
