import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiLoadingStatusPayload } from 'api';
import {
  getDefaultPaginatedSearchContainer,
  getReducerAppendResults,
  PaginatedResult,
  PaginatedSearchContainer,
} from 'core';
import { commonActions } from 'Modules/common/actions';

import { CreateRole, PermissionDetail, Role, UpdateRole } from './models';

export interface RolesState {
  entities: {
    roles: { [id: number]: Role };
    permissions: { [id: number]: PermissionDetail };
  };
  xrefs: {
    rolesPermissions: { [roleId: number]: number[] };
  };
  ui: {
    allPermissionIds: number[];
    createdRoleOptionIds: number[];
    rolesPagination: PaginatedSearchContainer,
    maintenance: {
      workingCreateRole: Partial<CreateRole>;
      workingUpdateRole: Partial<UpdateRole>;
    };
  };
  loading: {
    isLoadingRole: boolean;
    isSearchingRoles: boolean;
    isInsertingRole: boolean;
    isUpdatingRole: boolean;
    isDeletingRole: boolean;
  };
}

const initialRolesState: RolesState = {
  entities: {
    roles: {},
    permissions: {},
  },
  xrefs: {
    rolesPermissions: {},
  },
  ui: {
    allPermissionIds: [],
    createdRoleOptionIds: [],
    rolesPagination: getDefaultPaginatedSearchContainer(),
    maintenance: {
      workingCreateRole: {},
      workingUpdateRole: {},
    },
  },
  loading: {
    isLoadingRole: false,
    isSearchingRoles: false,
    isInsertingRole: false,
    isUpdatingRole: false,
    isDeletingRole: false,
  },
};

export const rolesSlice = createSlice({
  name: 'Roles',
  initialState: initialRolesState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<RolesState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setRole: (state, action: PayloadAction<Role>) => {
      state.entities.roles = {
        ...state.entities.roles,
        [action.payload.id]: action.payload,
      };
    },
    setRolePermissions: (state, action: PayloadAction<{ roleId: number; permissions: PermissionDetail[] }>) => {
      state.entities.permissions = {
        ...state.entities.permissions,
        ..._.keyBy(action.payload.permissions, p => p.id),
      };

      state.xrefs.rolesPermissions = {
        ...state.xrefs.rolesPermissions,
        [action.payload.roleId]: action.payload.permissions.map(p => p.id),
      };
    },
    setAllPermissions: (state, action: PayloadAction<PermissionDetail[]>) => {
      state.entities.permissions = {
        ...state.entities.permissions,
        ..._.keyBy(action.payload, p => p.id),
      };
      state.ui.allPermissionIds = action.payload.map((p) => p.id);
    },
    setSearchedRoles: (state, action: PayloadAction<PaginatedResult<Role>>) => {
      const { entities, pagination } = getReducerAppendResults(action.payload, state.entities.roles, state.ui.rolesPagination, (r) => r.id);

      state.entities.roles = entities;
      state.ui.rolesPagination = pagination;
    },
    setWorkingCreateRoleValues: (state, action: PayloadAction<Partial<CreateRole>>) => {
      state.ui.maintenance.workingCreateRole = {
        ...state.ui.maintenance.workingCreateRole,
        ...action.payload,
      };
    },
    setWorkingUpdateRoleValues: (state, action: PayloadAction<Partial<UpdateRole>>) => {
      state.ui.maintenance.workingUpdateRole = {
        ...state.ui.maintenance.workingUpdateRole,
        ...action.payload,
      };
    },
    cancelRoleModification: (state) => {
      state.ui.maintenance.workingUpdateRole = {};
      state.ui.maintenance.workingCreateRole = {};
    },
    onRoleProgramUpdated: (state, action: PayloadAction<Role>) => {
      state.entities.roles = {
        ...state.entities.roles,
        [action.payload.id]: action.payload,
      };

      state.ui.maintenance.workingUpdateRole = {};
    },
    onRoleUpdated: (state, action: PayloadAction<Role>) => {
      state.entities.roles = {
        ...state.entities.roles,
        [action.payload.id]: action.payload,
      };

      state.ui.maintenance.workingUpdateRole = {};
    },
    onRoleInserted: (state, action: PayloadAction<Role>) => {
      state.entities.roles = {
        ...state.entities.roles,
        [action.payload.id]: action.payload,
      };

      state.ui.rolesPagination.ids.push(action.payload.id);
      state.ui.maintenance.workingCreateRole = {};
    },
    onRoleDeleted: (state, action: PayloadAction<Role>) => {
      state.entities.roles = _.omit(state.entities.roles, action.payload.id);
      state.ui.rolesPagination.ids = _.without(state.ui.rolesPagination.ids, action.payload.id);
      state.ui.maintenance.workingUpdateRole = {};
      state.ui.maintenance.workingCreateRole = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(commonActions.PATH_LOCATION_CHANGE, (state) => {
        state.ui.maintenance.workingCreateRole = {};
        state.ui.maintenance.workingUpdateRole = {};
      })
      .addCase(commonActions.AREA_LOCATION_CHANGE, () => {
        return { ...initialRolesState };
      });
  },
});
