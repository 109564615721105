import React, { ReactNode } from 'react';
import _ from 'lodash';
import { CategoryModel } from '../Modules/categories/models';
import WithCategories from './WithCategories';

interface Props {
  appendUndefined: boolean;
  children: (providedProps: { category: CategoryModel | undefined; isLast: boolean }) => ReactNode | null;
}

const WithCategoryIteration = ({ appendUndefined, children }: Props) => {
  return (
    <>
      {/* @ts-ignore */}
      <WithCategories appendUndefined={appendUndefined}>
        {({ sortedCategories }) => (
          <>
            {
              _.map(sortedCategories, (category, i) => {
                const isLast = _.size(sortedCategories) - 1 === i;

                const Child = children({ category, isLast });

                if (!Child) {
                  return null;
                }

                return (
                  <React.Fragment
                    key={category?.categoryId || 'undefined'}
                  >
                    <h4 className="text-white">{category?.name || <em>Uncategorized</em>}</h4>

                    {Child}

                    {!isLast && (
                      <hr/>
                    )}
                  </React.Fragment>
                );
              })
            }
          </>
        )}
      </WithCategories>
    </>
  );
};

export default WithCategoryIteration;
