import React, { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { operatorGroupsActions, operatorGroupsSelectors } from 'Modules/operatorGroups';
import Helmet from 'react-helmet';
import FlexLoaderPanel from '../../../Components/FlexLoaderPanel';
import CollapsibleCardBox from '../../../Components/CollapsibleCardBox';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import PageLayout from '../../../App/PageLayout';
import OperatorGroupsTable from './OperatorGroupsTable';
import { OperatorGroup } from '../../../Modules/operatorGroups/models';
import ModifyOperatorGroupForm from './ModifyOperatorGroupForm';
import OperatorGroupXrefsTable from './OperatorGroupXrefsTable';
import OperatorSearch from '../../../AviationSafetySolutions/OperatorSearch';
import { OperatorModel } from '../../../models';

const OperatorGroupsPage = () => {
  const searchCardRef = useRef<any>();
  const dispatch = useDispatch();
  const isLoadingAllOperatorGroups = useSelector(operatorGroupsSelectors.isLoadingAllOperatorGroups);
  const allOperatorGroups = useSelector(operatorGroupsSelectors.maintenance.allOperatorGroups);
  const selectedOperatorGroup = useSelector(operatorGroupsSelectors.maintenance.selectedOperatorGroup);
  const selectedOperatorGroupOperators = useSelector(operatorGroupsSelectors.maintenance.selectedOperatorGroupOperators);
  const selectedOperatorsHash = useMemo(() => _.keyBy(selectedOperatorGroupOperators, o => o.operatorId), [ selectedOperatorGroupOperators ]);

  useEffect(() => {
    getAllOperatorGroups();
  }, []);

  useEffect(() => {
    if (!_.isUndefined(selectedOperatorGroup?.operatorGroupId)) {
      searchCardRef.current.setIsOpen(false);
    }

    if (selectedOperatorGroup?.operatorGroupId) {
      getOperatorGroupXrefs(selectedOperatorGroup.operatorGroupId);
    }
  }, [ selectedOperatorGroup?.operatorGroupId ]);

  function addOperatorGroup () {
    dispatch(operatorGroupsActions.setSelectedMaintenanceOperatorGroup(0));
  }

  function getOperatorGroupXrefs (operatorGroupId: number) {
    dispatch(operatorGroupsActions.async.getOperatorGroupXrefs(operatorGroupId));
  }

  function setSelectedMaintenanceOperatorGroup (operatorGroup: OperatorGroup) {
    dispatch(operatorGroupsActions.setSelectedMaintenanceOperatorGroup(operatorGroup.operatorGroupId));
  }

  function getAllOperatorGroups () {
    dispatch(operatorGroupsActions.async.getOperatorGroups());
  }

  function addOperatorToGroup (operator: OperatorModel) {
    if (!selectedOperatorGroup?.operatorGroupId) {
      return;
    }

    dispatch(operatorGroupsActions.async.insertOperatorGroupOperator(selectedOperatorGroup.operatorGroupId, operator.operatorId));
  }

  function saveOperatorGroup (operatorGroup: OperatorGroup) {
    if (operatorGroup.operatorGroupId) {
      dispatch(operatorGroupsActions.async.updateOperatorGroup(operatorGroup));
    } else {
      dispatch(operatorGroupsActions.async.insertOperatorGroup(operatorGroup));
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Operator Groups</title>
      </Helmet>

      <PageLayout pageTitle="Operator Group Maintenance" breadcrumbs={[ 'Administration', 'Operator Groups' ]}>
        <FlexLoaderPanel loadingClassName="p-5" isLoading={isLoadingAllOperatorGroups}>
          <CollapsibleCardBox ref={searchCardRef} Header="Operator Groups" defaultOpen>
            <Row className="mt-1">
              <Col md={12}>
                <Button color="primary" onClick={addOperatorGroup}>Add Operator Group...</Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={12}>
                <OperatorGroupsTable
                  operatorGroups={allOperatorGroups}
                  selectOperatorGroup={setSelectedMaintenanceOperatorGroup}
                  selectedOperatorGroupId={selectedOperatorGroup?.operatorGroupId}
                />
              </Col>
            </Row>
          </CollapsibleCardBox>

          {selectedOperatorGroup && (
            <Card>
              <CardHeader className="bg-white">
                Editing: {selectedOperatorGroup.operatorGroupId > 0 ? selectedOperatorGroup.name : <em>New Operator Group</em>}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={{ size: 4 }}>
                    <ModifyOperatorGroupForm
                      operatorGroup={selectedOperatorGroup}
                      saveOperatorGroup={saveOperatorGroup}
                    />
                  </Col>
                  {selectedOperatorGroup.operatorGroupId > 0 && (
                    <Col md={{ size: 8 }}>
                      <FormGroup>
                        <Label>Add Operator to Group:</Label>

                        <OperatorSearch
                          filterOptions={(options) => _.filter(options, (o) => !_.has(selectedOperatorsHash, o.operatorId))}
                          onChange={addOperatorToGroup}
                        />
                      </FormGroup>
                      <OperatorGroupXrefsTable
                        operatorGroup={selectedOperatorGroup}
                        operatorGroupOperatorXrefs={selectedOperatorGroupOperators}
                      />
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          )}
        </FlexLoaderPanel>
      </PageLayout>
    </>
  );
};

export default OperatorGroupsPage;
