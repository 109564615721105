import React, { ReactNode, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Label } from 'reactstrap';

import { Poll, PollResultSummary } from 'Modules/polls';
import { Div, OperatorPollResultSummaryTable } from 'Components';
import PollChart from 'Components/PollChart/PollChart';

interface ProvidedProps {
  isHighlighted: boolean;
  Component: ReactNode;
}

interface Props {
  poll: Poll;
  results: PollResultSummary[];
  children: (props: ProvidedProps) => JSX.Element;
}

const WithPollChartDisplay = ({ poll, results, children }: Props): JSX.Element => {
  const [ highlightedOptionId, setHighlightedOptionId ] = useState<number>();
  const highlightedResults = highlightedOptionId ? results.filter(r => r.optionId === highlightedOptionId) : [];
  const highlightedOptionFirstResult = useMemo(() => results.find(o => o.optionId === highlightedOptionId), [ highlightedOptionId ]);

  const Component = (
    <>
      {!highlightedOptionFirstResult && (
        <>
          {_.size(results) > 0 ? (
            <div style={{ height: 300 }}>
              <PollChart
                type={poll.defaultResultDisplayType}
                results={results}
                onOptionClick={(optionId) => setHighlightedOptionId(optionId)}
              />
            </div>
          ) : (
            <div className="text-center">
              <Label>No Responses Have Been Submitted</Label>
            </div>
          )}
        </>
      )}
      {highlightedOptionFirstResult && (
        <>
          <Div childSpacingY={1}>
            <Button color="anchor" onClick={() => setHighlightedOptionId(undefined)}>
              <i className="mdi mdi-arrow-left-circle" /> Viewing Responses for <strong>{highlightedOptionFirstResult.optionName}</strong>
            </Button>

            <OperatorPollResultSummaryTable pollResultSummaries={highlightedResults} />
          </Div>
        </>
      )}
    </>
  );

  return children({ Component, isHighlighted: !!highlightedOptionFirstResult });
};

export default WithPollChartDisplay;
