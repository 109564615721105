import React from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import useStateSelector from 'hooks/useStateSelector';

import ActiveUsersTable from './ActiveUsersTable';

const AdminDashboardPage = () => {
  const onlineUsers = useStateSelector(state => state.site.onlineUsers);
  return (
    <div className="mt-2">
      <Row>
        <Col md={8}>
          <Card>
            <CardHeader>
              Active Users ({_.size(onlineUsers)})
            </CardHeader>

            <CardBody>
              <ActiveUsersTable users={onlineUsers}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboardPage;
