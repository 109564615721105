import './terms-of-service-modal.scss';

import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useStateSelector } from 'hooks';
import * as usersActions from 'Modules/users/actions';
import { FlexLoader, FlexRow, LoadableButton } from 'Components';
import { useDispatch } from 'react-redux';
import Markdown from 'Components/Markdown/Markdown';
// @ts-ignore
import markdownMd from '../../data/terms-of-service.md';

interface Props {
  show: boolean;
  hide: () => void;
}

const TermsOfServiceModal = ({ show, ...props }: Props) => {
  const dispatch = useDispatch();
  const [ termsMarkdown, setTermsMarkdown ] = useState<string>();
  const isAgreeingToTermsOfService = useStateSelector(state => state.users.loading.isAgreeingToTermsOfService);

  useEffect(() => {
    fetch(markdownMd)
      .then((response) => response.text())
      .then((text) => {
        setTermsMarkdown(text);
      });
  }, []);


  function agreeToTermsOfService() {
    dispatch(usersActions.agreeToTermsOfService(() => {
      props.hide();
    }));
  }

  return (
    <Modal className="terms-of-service-modal" isOpen={show} size="lg" toggle={props.hide}>
      <ModalHeader>Terms of Service</ModalHeader>
      <ModalBody>
        {!termsMarkdown && (
          <FlexLoader />
        )}
        {termsMarkdown && (
          <Markdown source={termsMarkdown}/>
        )}
      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div />
          <div>
            <LoadableButton
              onClick={agreeToTermsOfService}
              isLoading={isAgreeingToTermsOfService}
              color="primary">
              I Agree
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default TermsOfServiceModal;
