import React, { useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { useStateSelector } from 'hooks';
import { getAllStandards } from 'Modules/standards/actions';
import { Standard } from 'Modules/standards/models';
import { selectAllStandards } from 'Modules/standards/selectors';

interface Props extends Omit<ReactSelectProps, 'value'> {
  value?: number;
}

const StandardSearch = ({ value, ...rest }: Props) => {
  const dispatch = useDispatch();
  const standards = useStateSelector(selectAllStandards);
  const isLoadingAllStandards = useStateSelector(state => state.standards.loading.isLoadingAllStandards);

  useEffect(() => {
    dispatch(getAllStandards());
  }, []);

  const standard = _.find(standards, c => c.standardId === value);

  return (
    <Select<Standard>
      isClearable
      {...rest}
      isLoading={isLoadingAllStandards}
      getOptionLabel={c => c.name}
      getOptionValue={c => c.standardId as any}
      value={standard || null}
      options={standards}
    />
  );
};

export default StandardSearch;
