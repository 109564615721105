import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Form, FormProps } from 'reactstrap';

import { VerticalOnlyScrollPanel } from 'Components';

interface Props extends Omit<FormProps, 'content'> {
  Header?: ReactNode;
  content?: {
    className?: string;
  };
  Footer?: ReactNode;
  children: ReactNode;
}

const EditFormPanel = ({ content, Footer, Header, children, ...props }: Props) => {
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (props.onSubmit) {
      props.onSubmit(e);
    }
  }

  return (
    <Form
      {...props}
      onSubmit={handleSubmit}
      className={classnames('d-flex flex-column flex overflow-hidden', props.className)}
    >
      {Header}
      <VerticalOnlyScrollPanel className={classnames('flex', 'p-2', content?.className)}>
        {children}
      </VerticalOnlyScrollPanel>

      {Footer && (
        <div className="bg-light">
          <div className="pl-2 pr-5">
            {Footer}
          </div>
        </div>
      )}
    </Form>
  );
};

export default EditFormPanel;
