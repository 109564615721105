import { useState } from 'react';

import { callApi } from 'api/ApiDao';
import { Response as SuperAgentResponse, SuperAgent, SuperAgentRequest } from 'superagent';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../Modules/site/selectors';
import { downloadFileFromResponse } from '../Modules/common/actions';

interface FileDownloadResponse {
  blob: Blob;
  response: SuperAgentResponse;
}

const useFileDownloader = function (getRequest: (superagent: SuperAgent<any>) => SuperAgentRequest): [() => Promise<FileDownloadResponse>, boolean] {
  const [ isDownloading, setIsDownloading ] = useState(false);
  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);

  function downloadFile(): Promise<FileDownloadResponse> {
    return new Promise((resolve, reject) => {
      setIsDownloading(true);

      callApi<Blob>({
        apiToken: accessToken,
        getRequest: (superagent) => getRequest(superagent).responseType('blob'),
        onSuccess: (data, response) => {
          resolve({ blob: data, response });
          dispatch(downloadFileFromResponse(data, response));
        },
        onFailure: (e) => {
          reject(e);
        },
        onComplete: () => {
          setIsDownloading(false);
        },
      });
    });
  }

  return [ downloadFile, isDownloading ];
};

export default useFileDownloader;
