import _ from 'lodash';
import { Dispatch } from 'redux';
import { FileDownloadAction } from '../models';
import { siteActions } from '../Modules/site';
import * as signalR from '@microsoft/signalr';
import { RootState } from '../Modules/reducers';
import { notificationReceived, operatorMessageNotificationReceived } from '../Modules/userNotifications/actions';
import { coinSound } from '../notifications';
import { getCurrentLoggedInUser } from '../Modules/users/actions';
import { signalrActions } from '../Modules/signalr/actions';
import { operatorGroupsActions } from '../Modules/operatorGroups';
import { ThunkMiddleware } from 'redux-thunk';
import { OperatorGroupSearchMessage } from '../Modules/operatorGroups/models';
import { Permission } from '../Modules/roles/index';

const signalrMiddleware: ThunkMiddleware<RootState> = (store) => (next: Dispatch) => async (action: FileDownloadAction) => {
  if (action.type === signalrActions.INIT_SIGNALR) {
    await initializeHub('/activeUsersHub', (activeUsersHub) => {
      activeUsersHub.on('userCountUpdated', () => {
        const state: RootState = store.getState();

        if (state.site.user?.permissions.includes(Permission.ViewActiveUsers)) {
          // @ts-ignore
          store.dispatch(siteActions.async.getActiveUsers());
        }
      });

      const state: RootState = store.getState();

      if (state.site.user?.permissions.includes(Permission.ViewActiveUsers)) {
        // @ts-ignore
        store.dispatch(siteActions.async.getActiveUsers());
      }
    });

    await initializeHub('/notificationHub', (notificationHub) => {
      notificationHub.on('notificationReceived', (notification) => {
        store.dispatch(notificationReceived(notification));
        coinSound.play();
      });

      notificationHub.send('SubscribeToNotifications');
    });

    await initializeHub('/operatorMessageNotificationHub', (notificationHub) => {
      notificationHub.on('operatorMessageNotificationReceived', (userId, operatorMessageId) => {
        store.dispatch(operatorMessageNotificationReceived(userId, operatorMessageId) as any);
        coinSound.play();
      });

      notificationHub.send('SubscribeToOperatorMessageNotifications');
    });

    await initializeHub('/operatorToOperatorMessageNotificationHub', (notificationHub) => {
      notificationHub.on('operatorToOperatorMessageNotificationReceived', async () => {
        coinSound.play();
      });

      notificationHub.send('SubscribeToOperatorToOperatorMessageNotifications');
    });

    await initializeHub('/operatorGroupMessageNotificationHub', (notificationHub) => {
      notificationHub.on('operatorGroupMessageNotificationReceived', (userId, operatorGroupId, operatorGroupMessageId) => {
        if(window.location.pathname !== `/operatorGroups/${operatorGroupId}`) {
          return;
        }

        store.dispatch(operatorGroupsActions.async.getOperatorGroupSearchMessage(operatorGroupId, operatorGroupMessageId))
          .then((operatorGroupSearchMessage: OperatorGroupSearchMessage) => {
            store.dispatch(operatorGroupsActions.operatorGroupMessageInserted(operatorGroupSearchMessage));
          });

        coinSound.play();
      });

      notificationHub.send('SubscribeToOperatorGroupMessageNotifications');
    });

    await initializeHub('/userUpdatedHub', (notificationHub) => {
      notificationHub.on('userUpdated', () => {
        store.dispatch(getCurrentLoggedInUser());
      });

      notificationHub.send('SubscribeToUserUpdates');
    });
  }

  next(action);

  async function initializeHub (url: string, wireUp?: (hub: signalR.HubConnection) => void) {
    const hub = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(url, {
        accessTokenFactory: () => {
          const state: RootState = store.getState();

          return state.site.authUser?.token || '';
        },
      })
      .withAutomaticReconnect()
      .build();

    await hub.start();

    if (_.isFunction(wireUp)) {
      wireUp(hub);
    }
  }
};

export default signalrMiddleware;
