import React, { useEffect } from 'react';
import { FormGroup, Input, Label, Table } from 'reactstrap';

import { makeFormHelper } from 'core';
import { ModifyRole, PermissionDetail, rolesActions } from 'Modules/roles';
import Toggle from 'react-toggle';
import { useStateSelector } from 'hooks';
import { useDispatch } from 'react-redux';

interface Props {
  isCreatingNewRole?: boolean;
  role: ModifyRole;
  setValues: (values: Partial<ModifyRole>) => void;
}

const RoleForm = ({ role, isCreatingNewRole, ...props }: Props) => {
  const dispatch = useDispatch();
  const canGloballyEdit = true; // todo permissions useHasPermission(Permission.RolesEdit);

  function setWorkingRoleValues(values: Partial<ModifyRole>) {
    props.setValues(values);
  }

  const canEdit = isCreatingNewRole || canGloballyEdit;
  const { getInputProps } = makeFormHelper(role, setWorkingRoleValues);
  const permissions = useStateSelector((state) => state.roles.ui.allPermissionIds.map((id) => state.roles.entities.permissions[id]));

  useEffect(() => {
    dispatch(rolesActions.async.getAllPermissions());
  }, []);

  function handlePermissionToggle(permission: PermissionDetail) {
    const existingPermission = role.permissions.find(p => p.id === permission.id);

    if(existingPermission) {
      props.setValues({ permissions: role.permissions.filter(p => p.id !== permission.id) });
    } else {
      props.setValues({ permissions: [ ...role.permissions, permission ] });
    }
  }

  return (
    <>
      <FormGroup>
        <Label>Name</Label>
        <Input placeholder="Name..." disabled={!canEdit} {...getInputProps('name')} />
      </FormGroup>

      <FormGroup>
        <Table size="sm" hover bordered striped>
          <thead>
            <tr>
              <th>Permission</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {
              permissions.map((permission) => {
                const isEnabled = role.permissions.some(p => p.id === permission.id);
                return (
                  <tr key={permission.id}>
                    <td>{permission.label}</td>
                    <td>
                      <Toggle
                        checked={isEnabled}
                        onChange={() => handlePermissionToggle(permission)}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </FormGroup>
    </>
  );
};

export default RoleForm;
