import { compose } from 'redux';
import _ from 'lodash';
import { Action, AuditTemplate, ReducerFunction } from 'models';
import makeLoadingReducer from 'Modules/enhancers/makeLoadingReducer';
import loadingConfigs from './loading';
import { Program, ProgramFolder, ProgramResponse } from './models';
import { programsActions } from './actions';
import { commonActions } from '../common/actions';

export interface ProgramsState {
  entities: {
    programs: { [programId: number]: Program };
    programFolders: { [programFolderId: number]: ProgramFolder };
    auditTemplates: { [auditTemplateId: number]: AuditTemplate };
  };
  mappings: {
    programAuditTemplates: {[programId: number]: number[]};
    programFolders: {[programId: number]: number[]};
  };
  ui: {
    allProgramIds: number[];
    selectedProgramId?: number;
  };
  loading: {
    isLoadingProgram: boolean;
    isLoadingAllPrograms: boolean;
    isInsertingProgram: boolean;
    isUpdatingProgram: boolean;
    isDeletingProgram: boolean;
    isInsertingProgramTemplateXref: boolean;
    isDeletingProgramTemplateXref: boolean;
    isLoadingProgramFolders: boolean;
    isInsertingProgramFolder: boolean;
    isUpdatingProgramFolder: boolean;
    isDeletingProgramFolder: boolean;
  };
}

const initState: ProgramsState = {
  entities: {
    programs: {},
    programFolders: {},
    auditTemplates: {},
  },
  mappings: {
    programAuditTemplates: {},
    programFolders: {},
  },
  ui: {
    allProgramIds: [],
    selectedProgramId: undefined,
  },
  loading: {
    isLoadingProgram: false,
    isLoadingAllPrograms: false,
    isInsertingProgram: false,
    isUpdatingProgram: false,
    isDeletingProgram: false,
    isInsertingProgramTemplateXref: false,
    isDeletingProgramTemplateXref: false,
    isLoadingProgramFolders: false,
    isInsertingProgramFolder: false,
    isUpdatingProgramFolder: false,
    isDeletingProgramFolder: false,
  },
};

export default compose<ReducerFunction<ProgramsState>>(
  makeLoadingReducer<ProgramsState>({ loadingKey: 'loading', loadingConfigs: loadingConfigs })
)((state = initState, action: Action): ProgramsState => {
  switch (action.type) {
    case programsActions.SELECT_PROGRAM: {
      const { program }: { program: Program | undefined } = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedProgramId: program?.programId,
        },
      };
    }
    case programsActions.GET_ALL_PROGRAMS_SUCCESS: {
      const { programs }: { programs: ProgramResponse[] } = action.payload;

      const programAuditTemplates: {[programId: number]: number[]} = {};

      _.each(programs, p => {
        programAuditTemplates[p.programId] = _.map(p.auditTemplates, a => a.auditTemplateId);
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          programs: {
            ...state.entities.programs,
            ..._.keyBy(programs, p => p.programId),
          },
          auditTemplates: {
            ...state.entities.auditTemplates,
            ..._.keyBy(_.flatMap(programs, p => p.auditTemplates), a => a.auditTemplateId),
          },
        },
        mappings: {
          ...state.mappings,
          programAuditTemplates: {
            ...state.mappings.programAuditTemplates,
            ...programAuditTemplates,
          },
        },
        ui: {
          ...state.ui,
          allProgramIds: _.map(programs, p => p.programId),
        },
      };
    }
    case programsActions.GET_PROGRAM_FOLDERS_SUCCESS: {
      const { programId, folders }: { programId: number; folders: ProgramFolder[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          programFolders: {
            ...state.entities.programFolders,
            ..._.keyBy(folders, pf => pf.programFolderId),
          },
        },
        mappings: {
          ...state.mappings,
          programFolders: {
            ...state.mappings.programFolders,
            [programId]: _.map(folders, f => f.programFolderId),
          },
        },
      };
    }
    case programsActions.UPDATE_PROGRAM_SUCCESS: {
      const { program }: { program: Program } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          programs: {
            ...state.entities.programs,
            [program.programId]: program,
          },
        },
      };
    }
    case programsActions.INSERT_PROGRAM_SUCCESS: {
      const { program }: { program: Program } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          programs: {
            ...state.entities.programs,
            [program.programId]: program,
          },
        },
        ui: {
          ...state.ui,
          allProgramIds: _.concat(state.ui.allProgramIds, program.programId),
        },
      };
    }
    case programsActions.DELETE_PROGRAM_SUCCESS: {
      const { programId }: { programId: number } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          allProgramIds: _.without(state.ui.allProgramIds, programId),
          selectedProgramId: state.ui.selectedProgramId === programId ? undefined : state.ui.selectedProgramId,
        },
      };
    }
    case programsActions.UPDATE_PROGRAM_FOLDER_SUCCESS: {
      const { programFolder }: { programFolder: ProgramFolder } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          programFolders: {
            ...state.entities.programFolders,
            [programFolder.programFolderId]: programFolder,
          },
        },
      };
    }
    case programsActions.INSERT_PROGRAM_FOLDER_SUCCESS: {
      const { programFolder }: { programFolder: ProgramFolder } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          programFolders: {
            ...state.entities.programFolders,
            [programFolder.programFolderId]: programFolder,
          },
        },
        mappings: {
          ...state.mappings,
          programFolders: {
            ...state.mappings.programFolders,
            [programFolder.programId]: _.concat(state.mappings.programFolders[programFolder.programId] || [], programFolder.programFolderId),
          },
        },
      };
    }
    case programsActions.DELETE_PROGRAM_FOLDER_SUCCESS: {
      const { programFolderId }: { programFolderId: number } = action.payload;

      const existingProgramFolder = state.entities.programFolders[programFolderId];

      if (!existingProgramFolder) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          programFolders: _.omit(state.entities.programFolders, programFolderId),
        },
        mappings: {
          ...state.mappings,
          programFolders: {
            ...state.mappings.programFolders,
            [existingProgramFolder.programId]: _.without(state.mappings.programFolders[existingProgramFolder.programId] || [], programFolderId),
          },
        },
      };
    }
    case programsActions.INSERT_PROGRAM_TEMPLATE_XREF_REQUEST:
    case programsActions.DELETE_PROGRAM_TEMPLATE_XREF_FAILURE: {
      const { programId, templateId }: { programId: number; templateId: number } = action.payload;

      return {
        ...state,
        mappings: {
          ...state.mappings,
          programAuditTemplates: {
            ...state.mappings.programAuditTemplates,
            [programId]: _.concat(state.mappings.programAuditTemplates[programId], templateId),
          },
        },
      };
    }
    case programsActions.DELETE_PROGRAM_TEMPLATE_XREF_REQUEST:
    case programsActions.INSERT_PROGRAM_TEMPLATE_XREF_FAILURE: {
      const { programId, templateId }: { programId: number; templateId: number } = action.payload;

      return {
        ...state,
        mappings: {
          ...state.mappings,
          programAuditTemplates: {
            ...state.mappings.programAuditTemplates,
            [programId]: _.without(state.mappings.programAuditTemplates[programId], templateId),
          },
        },
      };
    }
    case commonActions.PATH_LOCATION_CHANGE: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
});
