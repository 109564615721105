import React, { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  heading: ReactNode;
  body: ReactNode;
}

const DashboardCard = ({ icon, heading, body }: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-center p-1">
      <div>
        <div className="text-dark text-center">
          {icon}
        </div>
        <div className="text-danger text-center">
          <p className="my-0 font-17 text-danger">{heading}</p>
          <p className="">{body}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
