import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import _ from 'lodash';
import toastr from 'toastr';
import { useDispatch, useSelector } from 'react-redux';
import { AutoUploadFileDropzone, FlexRow, ModalHeaderWithClose } from 'Components';
import { RootState } from '../Modules/reducers';
import { setProfilePicture } from '../Modules/operators/apiActions';

interface Props {
  show: boolean;
  operatorId: number;
  hide: () => void;
}

const UploadOperatorProfilePictureModal = function ({ show, operatorId, ...props }: Props) {
  const dispatch = useDispatch();
  const isSettingProfilePicture = useSelector((state: RootState) => state.operators.loading.isSettingProfilePicture);
  function handleUploadProfilePicture (selectedFiles: File[]): Promise<void> {
    return new Promise((resolve) => {
      if (_.isEmpty(selectedFiles)) {
        toastr.error('You must select a file to upload.');
        return;
      }

      dispatch(setProfilePicture(operatorId, selectedFiles[0], () => {
        resolve();

        (async () => {
          await Swal.fire({
            title: 'Profile Picture Uploaded',
            text: 'Your profile picture has been successfully uploaded',
            icon: 'success',
            confirmButtonText: 'Okay',
          });
          props.hide();
        })();
      }));
    });
  }

  return (
    <>
      <Modal isOpen={show} toggle={props.hide} size="lg">
        <ModalHeaderWithClose hide={props.hide}>
          Set Operator Profile Picture
        </ModalHeaderWithClose>
        <ModalBody>
          <Row>
            <Col md={12}>
              <AutoUploadFileDropzone accept="image/*" isUploading={isSettingProfilePicture} uploadFiles={handleUploadProfilePicture}/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FlexRow fill justifyBetween alignCenter>
            <div>
              <Button color="light" onClick={props.hide}>Close</Button>
            </div>
            <div/>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadOperatorProfilePictureModal;
