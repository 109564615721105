import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';

import { OperatorAuditModel, OperatorAuditTasks } from 'models';
import CategoryTaskGroup from './CategoryTaskGroup';
import WithCategories from 'Components/WithCategories';
import AddTaskDropdown from 'Components/AddTaskDropdown/AddTaskDropdown';
import WithModifyTaskModal from './WithModifyTaskModal';

interface LocalProps {
  operatorAudit: OperatorAuditModel;
  operatorAuditTasks: OperatorAuditTasks;
}

const PersistentTasks = function (props: LocalProps) {
  const { operatorAudit, operatorAuditTasks } = props;

  return (
    <WithModifyTaskModal operatorAudit={operatorAudit}>
      {({ showCreateTaskModal, showCreateParentTaskModal }) => (
        <>
          <Row>
            <Col md={12}>
              <AddTaskDropdown addTask={showCreateTaskModal} addParentTask={showCreateParentTaskModal} />
            </Col>
          </Row>

          <Row className="mt-2">
            {/* @ts-ignore */}
            <WithCategories appendUndefined>
              {({ sortedCategories }) => (
                <>
                  {
                    _.map(sortedCategories, (category, i) => {
                      const categoryTasks = _.filter(operatorAuditTasks.persistentTasks, t => (t.categoryId || undefined) === category?.categoryId);
                      const isLast = _.size(sortedCategories) - 1 === i;

                      if (_.isEmpty(categoryTasks)) {
                        return null;
                      }

                      return (
                        <Col
                          key={category?.categoryId || 'undefined'}
                          md={12}
                        >
                          <CategoryTaskGroup
                          // @ts-ignore
                            category={category}
                            // @ts-ignore
                            tasks={categoryTasks}
                            // @ts-ignore
                            operatorAudit={operatorAudit}
                          />

                          {!isLast && (
                            <hr/>
                          )}
                        </Col>
                      );
                    })
                  }
                </>
              )}
            </WithCategories>

          </Row>
        </>
      )}
    </WithModifyTaskModal>
  );
};

export default PersistentTasks;
