import { Dispatch } from 'redux';
import { Action, OperatorModel } from '../models';
import { siteActions } from '../Modules/site';
import i18n from 'i18next';
import { operatorsActions } from '../Modules/operators';
import { ThunkMiddleware } from 'redux-thunk';
import { RootState } from '../Modules/reducers';

const i18nLanguageMiddleware: ThunkMiddleware<RootState> = (store) => (next: Dispatch) => async (action: Action) => {
  next(action);

  if (action.type === siteActions.setSelectedOperator.type) {
    const operator: OperatorModel | undefined = action.payload;

    if(operator) {
      i18n.changeLanguage(action.payload.dictionaryTypeName);
    }
  }

  if (action.type === operatorsActions.onOperatorUpdated.type) {
    const state = store.getState();
    const operator = action.payload as OperatorModel;
    if(state.site.selectedOperator?.operatorId === operator.operatorId) {
      i18n.changeLanguage(operator.dictionaryTypeName);
    }
  }
};

export default i18nLanguageMiddleware;
