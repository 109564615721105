import React from 'react';
import classnames from 'classnames';
import { RowInfo } from 'react-table';
import { baseTableOptions, renderDate } from '../../../helpers/tableHelper';
import { OperatorAuditModel } from '../../../models';
import BaseTable from '../../../Components/BaseTable/BaseTable';
import { stopPropagationPreventDefaultAnd } from '../../../helpers/eventHelpers';
import Swal from 'sweetalert2';
import { RootState } from '../../../Modules/reducers';
import { connect } from 'react-redux';
import { deleteAudit } from '../../../Modules/audits/actions';
import _ from 'lodash';
import { Button } from 'reactstrap';

interface LocalProps {
  audits: OperatorAuditModel[];
  selectedAuditId?: number;
  selectAudit: (auditId: OperatorAuditModel | null) => void;
  startEditingAudit: (audit: OperatorAuditModel) => void;
}

interface StateProps {
  updatingOperatorAudits: {[operatorAuditId: number]: number};
}

interface DispatchProps {
  deleteAudit: typeof deleteAudit;
}

type Props = LocalProps & StateProps & DispatchProps;

class OperatorAuditsTable extends React.Component<Props> {
  renderEditButton = ({ original }: { original: OperatorAuditModel }) => {
    return (
      <Button color="anchor" onClick={() => this.props.startEditingAudit(original)}><i className="fa fa-pencil" /></Button>
    );
  };

  private renderDelete = ({ original }: { original: OperatorAuditModel }) => {
    return (
      <Button
        color="anchor"
        onClick={stopPropagationPreventDefaultAnd(async () => {
          const { dismiss } = await Swal.fire({
            title: 'Delete Operator',
            text: 'Are you sure you want to delete this Operator?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          });

          if (dismiss) {
            return;
          }

          return this.props.deleteAudit(original);
        })}
      ><i className="fa fa-trash" />
      </Button>
    );
  }

  render () {
    const { audits, updatingOperatorAudits } = this.props;

    const tableOptions: any = {
      ...baseTableOptions,
      minRows: 10,
      data: audits || [],
      getTrProps: (state: any, row: RowInfo) => {
        if (!row || !row.original) {
          return {};
        }

        const { original } = row;
        const { selectedAuditId } = this.props;
        const { operatorAuditId } = original;

        const isSelected = operatorAuditId === selectedAuditId;

        return {
          onClick: () => {
            this.props.selectAudit(!isSelected ? original : null);
          },
          className: classnames({
            'selected-row': isSelected,
          }),
        };
      },
      columns: [
        {
          Header: '',
          id: 'status',
          className: 'text-center',
          Cell: ({ original }: { original: OperatorAuditModel }) => {
            if (!_.has(updatingOperatorAudits, original.operatorAuditId)) {
              return null;
            }

            return (
              <i className="fa fa-lg fa-spin fa-refresh" />
            );
          },
          width: 40,
        },
        {
          Header: '',
          id: 'edit',
          Cell: this.renderEditButton,
          width: 50,
          className: 'text-center',
        },
        {
          Header: 'Start Prep Date',
          ...renderDate('startPrepDate'),
          width: 245,
        },
        {
          Header: 'Audit Date',
          ...renderDate('auditDate'),
          width: 245,
        },
        {
          Header: 'Delete',
          Cell: this.renderDelete,
          className: 'text-center',
          width: 70,
        },
      ],
      defaultSorted: [ { id: 'email' } ],
    };

    return (
      <div className="bg-white">
        <BaseTable {...tableOptions} />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    updatingOperatorAudits: state.audits.loading.updatingOperatorAudits,
  };
};

const mapDispatchToProps = {
  deleteAudit,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(OperatorAuditsTable);
