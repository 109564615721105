import i18n from 'i18next';

enum UserXrefType {
  SafetyCoach = 1,
  Account = 2,
  ReadOnly = 3,
}

export const PluralUserXrefTypeDescription = {
  [UserXrefType.SafetyCoach]: i18n.t('safetyCoaches'),
  [UserXrefType.Account]: 'Accounts',
  [UserXrefType.ReadOnly]: 'Read Only Users',
};

export default UserXrefType;
