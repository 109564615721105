import { ApiAction } from 'models';
import { commonActions } from '../common/actions';
import appConfig from '../../config';

export const standardsActions = {
  GET_ALL_STANDARDS_REQUEST: 'AVSolutions/standards/GetAllStandardsRequest',
  GET_ALL_STANDARDS_SUCCESS: 'AVSolutions/standards/GetAllStandardsSuccess',
  GET_ALL_STANDARDS_FAILURE: 'AVSolutions/standards/GetAllStandardsFailure',

  GET_STANDARD_REFERENCES_BY_STANDARD_REQUEST: 'AVSolutions/standards/GetStandardReferencesByStandardRequest',
  GET_STANDARD_REFERENCES_BY_STANDARD_SUCCESS: 'AVSolutions/standards/GetStandardReferencesByStandardSuccess',
  GET_STANDARD_REFERENCES_BY_STANDARD_FAILURE: 'AVSolutions/standards/GetStandardReferencesByStandardFailure',
};

export const getAllStandards = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/standards`);
      },
      beforeRequest: () => ({ type: standardsActions.GET_ALL_STANDARDS_REQUEST }),
      success: (data) => ({ type: standardsActions.GET_ALL_STANDARDS_SUCCESS, payload: { standards: data } }),
      failure: (response) => ({ type: standardsActions.GET_ALL_STANDARDS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getStandardReferencesByStandard = (standardId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/standards/${standardId}/references`);
      },
      beforeRequest: () => ({ type: standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_REQUEST, payload: { standardId } }),
      success: (data) => ({ type: standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_SUCCESS, payload: { standardId, references: data } }),
      failure: (response) => ({ type: standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_FAILURE, payload: { ...response, standardId } }),
    },
  };
};
