import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import ModalHeaderWithClose from 'Components/ModalHeaderWithClose';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import FlexRow from 'Components/FlexRow';
import { SuperAgent, SuperAgentRequest } from 'superagent';
import { FileDetails } from 'Modules/library/models';
import { callApi } from 'api/ApiDao';
import useStateSelector from 'hooks/useStateSelector';
import useFileDownloader from 'hooks/useFileDownloader';
import useOnModalShow from 'hooks/useOnModalShow';

import ImagePreview from './PreviewHandlers/ImagePreview';
import PdfPreview from './PreviewHandlers/PdfPreview';

interface LocalProps {
  show: boolean;
  getPreviewRequest: (superagent: SuperAgent<any>) => SuperAgentRequest;
  getDownloadRequest: (superagent: SuperAgent<any>) => SuperAgentRequest;
  hide: () => void;
}

interface BasePreviewHandlerProps {
  fileDetails: FileDetails;
  downloadFile: () => void;
  isDownloading: boolean;
}

export const previewFileHandlers: { extensions: string[]; Component: React.FC<BasePreviewHandlerProps> }[] = [
  {
    extensions: [ 'jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg' ],
    Component: ImagePreview,
  },
  {
    extensions: [ 'pdf' ],
    Component: PdfPreview,
  },
];

const PreviewFileModal = ({ show, getDownloadRequest, getPreviewRequest, ...props }: LocalProps) => {
  const [ downloadFile, isDownloading ] = useFileDownloader(getDownloadRequest);

  const [ fileDetails, setFileDetails ] = useState<FileDetails|undefined>(undefined);
  const token = useStateSelector((state) => state.site.authUser?.token);
  const extension = useMemo(() => fileDetails?.originalFilename.split('.').pop(), [ fileDetails ]);

  useOnModalShow(show)
    .then(() => {
      callApi<Blob>({
        apiToken: token,
        getRequest: (superagent) => getPreviewRequest(superagent).responseType('blob'),
        onSuccess: (data, response) => {
          const originalFilename = response.get('content-disposition').split('filename=')[1].split(';')[0].replace(/"/g, '');

          getBase64(response.body as Blob)
            .then((dataUrl) => {
              setFileDetails({
                dataUrl: dataUrl,
                originalFilename,
              });
            });
        },
      });
    });

  function getBase64 (blob: Blob): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        resolve(reader.result as string);
      };
    });
  }

  function extensionIsIncluded (includedExtensions: string []) {
    return _.includes(includedExtensions, extension?.toLowerCase());
  }

  const PreviewHandler = _.find(previewFileHandlers, (fh) => extensionIsIncluded(fh.extensions))?.Component;

  return (
    <Modal isOpen={show} toggle={props.hide} size="lg">
      <ModalHeaderWithClose hide={props.hide}>
        Preview File
      </ModalHeaderWithClose>
      <ModalBody>
        <Row>
          <Col md={12}>
            {fileDetails && (
              <>
                {!PreviewHandler && (
                  <h3>Unable to Preview File</h3>
                )}
                {!!PreviewHandler && (
                  <PreviewHandler
                    fileDetails={fileDetails}
                    isDownloading={isDownloading}
                    downloadFile={downloadFile}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div>
            <Button color="light" onClick={props.hide}>Close</Button>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default PreviewFileModal;
