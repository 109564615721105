export interface Role {
  id: number;
  name: string;
  operatorId?: number;
  permissions: PermissionDetail[];
}

export interface CreateRole {
  name: string;
  permissions: PermissionDetail[];
}

export interface UpdateRole {
  name: string;
  permissions: PermissionDetail[];
}

export type ModifyRole = CreateRole | UpdateRole;

export interface PermissionDetail {
  id: number;
  label: string;
}

export enum Permission {
  GlobalRoleMaintenance = 1,
  GlobalAuditTemplateMaintenance = 2,
  GlobalTaskMaintenance = 3,
  GlobalUserMaintenance = 5,
  GlobalShareBoardMaintenance = 6,
  ViewFeedback = 7,
  ViewAllUsers = 8,
  OperatorAttributeTypeMaintenance = 9,
  OperatorAuditMaintenance = 10,
  OperatorMaintenance = 11,
  OperatorGroupMaintenance = 12,
  PollMaintenance = 13,
  ProgramMaintenance = 14,
  ReferenceMaterialMaintenance = 16,
  ViewActiveUsers = 17,
  ViewStandardReference = 18,
}
