import React from 'react';
import ReactMarkdown from 'react-markdown';

const Markdown = ({ source }: { source: string }) => {
  return (
    <div className="markdown">
      <ReactMarkdown>{source}</ReactMarkdown>
    </div>
  );
};

export default Markdown;
