import React, { ReactNode } from 'react';
import { EditorState } from 'draft-js';
import { Col, Row } from 'reactstrap';

import ActionType, { ActionTypeOptions } from 'constants/ActionType';
import CategorySearch from '../../../AviationSafetySolutions/CategorySearch';
import { CategoryModel } from 'Modules/categories/models';
import UploadDirectoryPathSelector from '../../../AviationSafetySolutions/UploadDirectoryPathSelector';
import StandardSearch from '../../../AviationSafetySolutions/StandardSearch';
import { Standard, StandardReference } from 'Modules/standards/models';
import StandardReferenceSearch from '../../../AviationSafetySolutions/StandardReferenceSearch';
import { Task } from 'Modules/tasks/models';
import { AdminDraftEditor, ButtonGroup } from 'Components';
import Toggle from 'react-toggle';

interface Props {
  task: Partial<Task>;
  values: Partial<Task>;
  descriptionEditorState: EditorState;
  setDescriptionEditorState: (editorState: EditorState) => void;
  PreSummary?: ReactNode;
  PostSummary?: ReactNode;
  PreSpecs?: ReactNode;
  PostSpecs?: ReactNode;
  setValues: (values: Partial<Task>) => void;
}

const TaskMaintenanceForm = ({ task, descriptionEditorState, values, PreSummary, PostSummary, PreSpecs, PostSpecs, ...props }: Props) => {
  function getMergedTask (): Partial<Task> {
    return {
      ...task,
      ...values,
    };
  }

  function setText (property: keyof Task) {
    return function (e: any) {
      props.setValues({
        [property]: e.target.value,
      });
    };
  }

  function setActionType (value: ActionType) {
    props.setValues({
      uploadToDirectoryPath: undefined,
      actionTypeId: value,
    });
  }

  function setCategory (category?: CategoryModel) {
    props.setValues({
      categoryId: category?.categoryId,
    });
  }

  function setStandard (standard?: Standard) {
    props.setValues({
      standardId: standard?.standardId,
      standardReferenceId: undefined,
    });
  }

  function setStandardReference (standardReference?: StandardReference) {
    props.setValues({
      standardReferenceId: standardReference?.standardReferenceId,
    });
  }

  function setUploadToDirectoryPath (uploadToDirectoryPath: string) {
    props.setValues({
      uploadToDirectoryPath: uploadToDirectoryPath,
    });
  }

  function onDescriptionEditorStateChange (editorState: EditorState) {
    props.setDescriptionEditorState(editorState);
  }

  const modifyingTask = getMergedTask();

  const { summary, actionTypeId, uploadToDirectoryPath, categoryId, standardId, standardReferenceId, required } = modifyingTask;

  return (
    <Row>
      <Col md={8}>
        {PreSummary}
        <div className="form-group">
          <label>Summary:</label>
          <input className="form-control" placeholder="Summary..." value={summary || ''} onChange={setText('summary')} />
        </div>

        {!task.isParent && (
          <>
            <div className="form-group">
              <label>Description:</label>
              <AdminDraftEditor
                editorState={descriptionEditorState}
                onEditorStateChange={onDescriptionEditorStateChange}
              />
            </div>

            <div className="form-group">
              <label>Action:</label>
              <div>
                <ButtonGroup options={ActionTypeOptions} value={actionTypeId} onClick={setActionType} />
              </div>
            </div>

            {actionTypeId === ActionType.Upload && (
              <>
                <div className="form-group">
                  <label>Upload to Library Path:</label>

                  <UploadDirectoryPathSelector
                    value={uploadToDirectoryPath || ''}
                    onChange={setUploadToDirectoryPath}
                  />
                </div>
              </>
            )}
          </>
        )}

        {PostSummary}
      </Col>

      <Col md={4}>
        {PreSpecs}
        {!task.parentTaskId && (
          <>
            <div className="form-group">
              <label>Required:</label>
              <div>
                <Toggle
                  checked={required}
                  onChange={() => props.setValues({ required: !values.required })}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Category:</label>
              <CategorySearch isClearable value={categoryId} onChange={setCategory}/>
            </div>

            <div className="form-group">
              <label>Standard:</label>
              <div>
                <StandardSearch value={standardId} onChange={setStandard}/>
              </div>
            </div>

            {!!standardId && (
              <div className="form-group">
                <label>Standard Reference:</label>
                <div>
                  <StandardReferenceSearch standardId={standardId} value={standardReferenceId}
                    onChange={setStandardReference}/>
                </div>
              </div>
            )}
          </>
        )}

        {PostSpecs}
      </Col>
    </Row>
  );
};

export default TaskMaintenanceForm;
