import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import Dropzone, { DropzoneProps } from 'react-dropzone';

export interface FileDropzoneProps extends Omit<DropzoneProps, 'children' | 'onDragEnter' | 'onDragLeave'> {
  className?: string;
  noHeight?: boolean;
  isUploading?: boolean;
  square?: boolean;
  style?: React.CSSProperties;
  children: ReactNode;
}

const FileDropzone = (props: FileDropzoneProps) => {
  const [ isHovered, setIsHovered ] = useState<boolean>(false);
  const { isUploading, children, square, className, style, ...rest } = props;

  function onDragEnter() {
    if (isHovered) {
      return;
    }

    setIsHovered(true);
  }

  function onDragLeave() {
    if (!isHovered) {
      return;
    }

    setIsHovered(false);
  }

  function onDrop(...args: any[]) {
    setIsHovered(false);

    props.onDrop?.apply(null, args as any);
  }

  return (
    <>
      <Dropzone {...rest} onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
        {({ getRootProps, getInputProps }) => (
          <div style={style} className={classnames('file-dropzone', className, { 'square': square, 'no-height': props.noHeight })} {...getRootProps()}>
            <input {...getInputProps()} />
            {!isHovered && (
              <>
                {children}
              </>
            )}
            {isHovered && (
              <div className="dropzone-hover">
                <span><i className="mdi mdi-36px mdi-file-outline"/></span>
                <span>Drop File Here</span>
              </div>
            )}
            {isUploading && (
              <div className="dropzone-hover top">
                <span><i className="mdi mdi-36px mdi-spin mdi-refresh"/></span>
                <span>Uploading File..</span>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </>
  );
};

export default FileDropzone;
