import _ from 'lodash';

export interface TreeNode<T> {
  fileTree: FileTree<T>;
  name: string;
  filePath: string;
  isFolder: boolean;
  data?: T;
  parent?: TreeNode<T>;
  children: TreeNode<T>[];
}

export class FileTree<T> {
  private readonly filePathSelector: (item: T) => string;

  constructor ({ filePathSelector }: { filePathSelector: (item: T) => string }) {
    this.filePathSelector = filePathSelector;
  }

  private rootNode: TreeNode<T> = {
    fileTree: this,
    name: '/',
    filePath: '/',
    isFolder: false,
    children: [],
  };

  private treeNodeLookup: { [filePath: string]: TreeNode<T> } = {
    '/': this.rootNode,
  };

  public getRoot (): TreeNode<T> {
    return this.rootNode;
  }

  public getNodeByPath (filePath: string): TreeNode<T> | undefined {
    return this.treeNodeLookup[filePath];
  }

  public getData (filePath: string): T | undefined {
    return this.treeNodeLookup[filePath]?.data;
  }

  public addAllFiles (data: T[]) {
    for (const item of data) {
      this.addFile(item);
    }
  }

  public addFile (data: T) {
    this.add(this.filePathSelector(data), data);
  }

  public addFolder (filePath: string) {
    if (_.has(this.treeNodeLookup, filePath)) {
      return;
    }

    this.add(filePath);
  }

  public getDescendantsForNode (node: TreeNode<T>): TreeNode<T>[] {
    const descendants: TreeNode<T>[] = [];

    populateNodeChildren(node);

    return descendants;

    function populateNodeChildren (currentNode: TreeNode<T>) {
      if (_.isEmpty(currentNode.children)) {
        return;
      }

      for (const child of currentNode.children) {
        descendants.push(child);
        populateNodeChildren(child);
      }
    }
  }

  public getDescendantDataForNode (node: TreeNode<T>): T[] {
    const descendants = this.getDescendantsForNode(node);

    return _.compact(_.map(descendants, d => d.data));
  }

  private add (filePath: string, data?: T) {
    const filePathSegments = filePath.replace(/\/$/, '').split('/');

    const fileNode: TreeNode<T> = {
      fileTree: this,
      name: _.last(filePathSegments)!,
      filePath: filePath,
      data: data,
      children: [],
      isFolder: !data,
    };

    this.treeNodeLookup[filePath] = fileNode;

    let childNode: TreeNode<T> = fileNode;
    let parentPathSegments = _.initial(filePathSegments);

    while (_.size(parentPathSegments) > 0) {
      const directoryFilePath = `${parentPathSegments.join('/')}/`;

      if (!_.has(this.treeNodeLookup, directoryFilePath)) {
        this.treeNodeLookup[directoryFilePath] = {
          fileTree: this,
          name: _.last(parentPathSegments)!,
          filePath: directoryFilePath,
          children: [],
          isFolder: true,
        };
      }

      const parentDirectory = this.treeNodeLookup[directoryFilePath];
      // eslint-disable-next-line
      if (!_.some(parentDirectory.children, t => t.filePath === childNode.filePath)) {
        parentDirectory.children.push(childNode);
      }

      childNode.parent = parentDirectory;

      childNode = this.treeNodeLookup[directoryFilePath];
      parentPathSegments = _.initial(parentPathSegments);
    }
  }
}

export interface DeleteLibraryFolderRequest {
  operatorId: number;
  folderPath: string;
  moveFilesToDirectoryPath: string;
}

export interface OperatorHiddenFolder {
  operatorHiddenFolderId: number;
  operatorId: number;
  directoryPath: string;
}

export interface LibraryPosition {
  libraryPositionId: number;
  operatorId: number;
  filePath: string;
  position: number;
}

export interface UpdateLibraryFilePosition {
  filePath: string;
  position: number;
  originalPosition?: number;
}

export interface FileDetails {
  dataUrl: string;
  originalFilename: string;
}
