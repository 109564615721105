import React, { CSSProperties } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Props as ReactSelectProps } from 'react-select/src/Select';
import { DictionaryType, SelectOption } from '../models';

interface LocalProps extends Omit<ReactSelectProps, 'value'> {
  value?: DictionaryType;
  onChange: (dictionaryType?: DictionaryType) => void;
}

const selectStyles = {
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 3000 }),
};

const options: SelectOption[] = [
  { label: 'Safety Coach', value: DictionaryType.SafetyCoach },
  { label: 'FAA', value: DictionaryType.FAA },
  { label: 'Auditor', value: DictionaryType.Auditor },
  { label: 'Consultant', value: DictionaryType.Consultant },
];

const DictionarySelect = ({ value, ...rest }: LocalProps) => {
  const selectedValue = _.find(options, (t) => t.value === value);

  return (
    <Select<SelectOption, false>
      {...rest}
      placeholder="Select a Dictionary..."
      menuPortalTarget={document.body}
      value={selectedValue || null}
      options={options}
      styles={selectStyles as any}
      onChange={(option) => rest.onChange(option?.value || undefined)}
    />
  );
};

export default DictionarySelect;
