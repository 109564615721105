import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { MessagesState } from './reducer';
import { messagesActions } from './actions';

const loadingConfigs: LoadingActionConfig<MessagesState>[] = [
  {
    start: messagesActions.SEARCH_OPERATOR_MESSAGES_REQUEST,
    stop: [ messagesActions.SEARCH_OPERATOR_MESSAGES_SUCCESS, messagesActions.SEARCH_OPERATOR_MESSAGES_FAILURE ],
    updater: (state: MessagesState, isLoading): MessagesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSearchingOperatorMessages: isLoading,
        },
      };
    },
  },
  {
    start: messagesActions.INSERT_OPERATOR_MESSAGE_REQUEST,
    stop: [ messagesActions.INSERT_OPERATOR_MESSAGE_SUCCESS, messagesActions.INSERT_OPERATOR_MESSAGE_FAILURE ],
    updater: (state: MessagesState, isLoading): MessagesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isInsertingOperatorMessage: isLoading,
        },
      };
    },
  },
];
export default loadingConfigs;
