import React from 'react';

import { baseTableOptions } from 'helpers/tableHelper';
import { Button } from 'reactstrap';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import { OperatorGroup } from 'Modules/operatorGroups/models';
import { stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import BaseTable from 'Components/BaseTable/BaseTable';
import { operatorGroupsActions } from '../../../Modules/operatorGroups';
import { useDispatch } from 'react-redux';

interface LocalProps {
  operatorGroups: OperatorGroup[];
  selectedOperatorGroupId?: number;
  selectOperatorGroup: (operatorGroup: OperatorGroup) => void;
}

const OperatorGroupsTable = ({ operatorGroups, selectedOperatorGroupId, ...props }: LocalProps) => {
  const dispatch = useDispatch();
  function deleteProgram (operatorGroup: OperatorGroup) {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete this operator group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete it',
      });

      if (dismiss) {
        return;
      }

      dispatch(operatorGroupsActions.async.deleteOperatorGroup(operatorGroup.operatorGroupId));
    })();
  }

  const columns: Column<OperatorGroup>[] = [
    {
      Header: 'Id',
      id: 'id',
      accessor: o => o.operatorGroupId,
      width: 45,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: p => p.name,
    },
    {
      Header: 'Delete',
      Cell: ({ original }: { original: OperatorGroup }) => {
        return (
          <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => deleteProgram(original))}>
            <i className="fa fa-trash" />
          </Button>
        );
      },
      width: 90,
    },
  ];

  return (
    <div className="bg-white">
      <BaseTable
        {...baseTableOptions}
        data={operatorGroups}
        minRows={10}
        columns={columns}
        defaultSorted={[ { id: 'name', desc: false } ]}
        select={{
          rowClick: true,
          idAccessor: (program: OperatorGroup) => program.operatorGroupId,
          selected: selectedOperatorGroupId ? { [selectedOperatorGroupId]: true } : {},
          onSelected: props.selectOperatorGroup,
        }}
      />
    </div>
  );
};

export default OperatorGroupsTable;
