import React from 'react';
import _ from 'lodash';
import { FormGroup, Input, Label, Table } from 'reactstrap';
import { makeFormHelper } from 'core';
import { useCurrentUserPermissionChecker } from '../../../hooks/index';
import { Permission, Role } from '../../../Modules/roles/index';
import { UpsertUserRequest } from '../../../Modules/users/models';
import { usePaginatedRoles } from '../../../Modules/roles/pagination';
import Toggle from 'react-toggle';

interface Props {
  isCreatingNewUser?: boolean;
  disabled?: boolean;
  user: UpsertUserRequest;
  setValues: (values: Partial<UpsertUserRequest>) => void;
}

const UserForm = ({ user, isCreatingNewUser, disabled, ...props }: Props) => {
  const currentUserHasPermission = useCurrentUserPermissionChecker();
  const canGloballyEdit = currentUserHasPermission(Permission.GlobalUserMaintenance);
  const { data: roles } = usePaginatedRoles({ searchOnMount: true });

  function setWorkingUserValues(values: Partial<UpsertUserRequest>) {
    props.setValues(values);
  }

  function handleRoleToggle(role: Role) {
    const isCurrentlyActive = user.roles.find((r) => r.id === role.id);

    if(isCurrentlyActive) {
      setWorkingUserValues({
        roles: user.roles.filter((r) => r.id !== role.id),
      });
    } else {
      setWorkingUserValues({
        roles: [ ...user.roles, role ],
      });
    }
  }

  const canEdit = !disabled && (isCreatingNewUser || canGloballyEdit);
  const { getInputProps } = makeFormHelper(user, setWorkingUserValues);

  return (
    <>
      <FormGroup>
        <Label>First Name</Label>
        <Input placeholder="First Name..." disabled={!canEdit} {...getInputProps('firstName')} />
      </FormGroup>

      <FormGroup>
        <Label>Last Name</Label>
        <Input placeholder="Last Name..." disabled={!canEdit} {...getInputProps('lastName')} />
      </FormGroup>

      <FormGroup>
        <Label>Display Name</Label>
        <Input placeholder="Display Name..." disabled={!canEdit} {...getInputProps('displayName')} />
      </FormGroup>

      <FormGroup>
        <Label>Email</Label>
        <Input placeholder="Email..." disabled={!canEdit} {...getInputProps('email')} />
      </FormGroup>

      <FormGroup>
        <Label>Roles</Label>
        <Table size="sm" hover bordered striped>
          <thead>
            <tr>
              <th>Role</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {
              _.map(roles, (role) => {
                return (
                  <tr key={role.id}>
                    <td>{role.name}</td>
                    <td>
                      <Toggle
                        disabled={!canEdit}
                        checked={!!user.roles.find((r) => r.id === role.id)}
                        onChange={() => handleRoleToggle(role)}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </FormGroup>
    </>
  );
};

export default UserForm;
