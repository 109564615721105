import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedIdStateSelector, useStateSelector } from 'hooks';
import { OperatorAttribute, operatorAttributesActions, operatorAttributesSelectors } from 'Modules/operatorAttributes';

const useOperatorAttributes = (operatorId: number): [ OperatorAttribute[], boolean ] => {
  const dispatch = useDispatch();
  const operatorAttributes = useMemoizedIdStateSelector(operatorId, operatorAttributesSelectors.makeGetOperatorAttributes);
  const isLoadingOperatorAttributes = useStateSelector(state => state.operatorAttributes.loading.isLoadingAllOperatorAttributes);

  useEffect(() => {
    dispatch(operatorAttributesActions.async.getOperatorAttributes(operatorId));
  }, [ operatorId ]);

  return [ operatorAttributes, isLoadingOperatorAttributes ];
};

export default useOperatorAttributes;
