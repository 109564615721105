import { useMemo } from 'react';
import _ from 'lodash';

import { useStateSelector } from 'hooks';
import { RootState } from 'Modules/reducers';

type StringOrNumber = string | number;
type MakeSelector<TReturn, TKeyType extends StringOrNumber> = (id: TKeyType) => (state: RootState) => TReturn;

function useMemoizedIdStateSelector<TReturn extends any>(id: string, makeSelector: MakeSelector<TReturn, string>): TReturn;
function useMemoizedIdStateSelector<TReturn extends any>(id: number, makeSelector: MakeSelector<TReturn, number>): TReturn;
function useMemoizedIdStateSelector<TReturn extends any>(id: undefined, makeSelector: MakeSelector<TReturn, any>): undefined;
function useMemoizedIdStateSelector<TReturn extends any>(id: number | undefined, makeSelector: MakeSelector<TReturn, number>): TReturn | undefined;
function useMemoizedIdStateSelector<TReturn extends any>(id: string | undefined, makeSelector: MakeSelector<TReturn, string>): TReturn | undefined;
function useMemoizedIdStateSelector<TReturn extends any>(id: number | string | undefined, makeSelector: MakeSelector<TReturn, any>) {
  const memoizedSelector = useMemo(() => {
    if (_.isNil(id)) {
      return () => undefined;
    }

    return makeSelector(id);
  }, [ id ]);
  return useStateSelector((state) => {
    if (_.isNil(id)) {
      return undefined;
    }

    return memoizedSelector(state);
  });
}

export default useMemoizedIdStateSelector;
