import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

interface LocalProps {
  value: any;
  options: any[];
  vertical?: boolean;
  block?: boolean;
  buttonGroupClassName?: string;
  baseClassName?: string;
  defaultClassName?: string;
  primaryClassName?: string;
  buttonProps?: any;
  onClick: (value: any, option: any, e: any) => void;
}

const ButtonGroup = ({
  buttonGroupClassName,
  value: selectedValue,
  options,
  vertical,
  block,
  baseClassName,
  defaultClassName,
  primaryClassName,
  buttonProps,
  ...props
}: LocalProps) => {
  function onClick(value: any, option: any, e: any): void {
    if (!_.isFunction(onClick)) {
      return;
    }

    props.onClick(value, option, e);
  }

  return (
    <div className={classnames({
      [buttonGroupClassName || '']: !!buttonGroupClassName,
      'btn-group': !vertical,
      'btn-group-vertical': vertical,
      'btn-block': block,
    })}>
      {
        _.map(options, (option) => {
          const { label, value } = option;
          const btnClass = classnames({
            [baseClassName || 'btn']: true,
            [defaultClassName || 'btn-light']: value !== selectedValue,
            [primaryClassName || 'btn-primary']: value === selectedValue,
          });

          return (
            <button key={value} type="button" className={btnClass}
              onClick={(e): void => onClick(value, option, e)} {...buttonProps}>{label}</button>
          );
        })
      }
    </div>
  );
};

export default ButtonGroup;
