import React from 'react';
import { Moment } from 'moment';

import RealtimeFormattedDate from './RealtimeFormattedDate';

interface LocalProps {
  date: Moment;
}

function formatDate(date: Moment) {
  return date.fromNow();
}

const RealtimeHumanizeDateTime = function (props: LocalProps) {
  return (
    <RealtimeFormattedDate date={props.date} formatDate={formatDate}/>
  );
};

export default RealtimeHumanizeDateTime;
