import { pollsSlice } from './slice';
import * as pollsApiActions from './apiActions';

const pollsActions = {
  ...pollsSlice.actions,
  async: {
    ...pollsApiActions,
  },
};

export default pollsActions;
