import { usePaginatedTermSearch } from 'hooks';
import { RootState } from '../reducers';
import rolesActions from './actions';

export const usePaginatedRoles = usePaginatedTermSearch({
  selectors: {
    pagination: (state: RootState) => state.roles.ui.rolesPagination,
    entities: (state: RootState) => state.roles.entities.roles,
    isSearching: (state: RootState) => state.roles.loading.isSearchingRoles,
  },
  handlers: {
    fetchPage: (request) => rolesActions.async.searchRoles({
      ...request,
      term: request.filter,
    } as any),
  },
});
