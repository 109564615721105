import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { UserModel } from 'models';
import { userActions } from 'Modules/users';
import { CreateUserRequest, UpdateUserRequest } from 'Modules/users/models';

const useUserForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingUser = useStateSelector((state) => state.users.loading.isInsertingUser);
  const isUpdatingUser = useStateSelector((state) => state.users.loading.isUpdatingUser);
  const isDeletingUser = useStateSelector((state) => state.users.loading.isDeletingUser);

  function insertUser(user: CreateUserRequest, successCallback?: (user: UserModel) => void) {
    dispatch(userActions.insertUser(user, (user) => {
      toast.success('User successfully created.');
      successCallback?.(user);
    }));
  }

  function updateUser(userId: string, user: UpdateUserRequest, successCallback?: (user: UserModel) => void) {
    dispatch(userActions.updateUser(userId, user, (user) => {
      toast.success('User successfully saved.');
      successCallback?.(user);
    }));
  }

  function deleteUser(user: UserModel, successCallback?: () => void) {
    dispatch(userActions.deleteUser(user.userId, () => {
      toast.success('User successfully deactivated.');
      successCallback?.();
    }));
  }

  function cancelUserModification() {
    dispatch(userActions.cancelUserModification());
  }

  return {
    insertUser,
    updateUser,
    deleteUser,
    cancelUserModification,
    loading: {
      isInsertingUser,
      isUpdatingUser,
      isDeletingUser,
    },
  };
};

export default useUserForm;
