import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const getAllActiveOperatorAttributeTypes = createSelector([
  (state: RootState) => state.operatorAttributes.entities.operatorAttributeTypes,
  (state: RootState) => state.operatorAttributes.ui.allOperatorAttributeTypeIds,
], (
  operatorAttributeTypes,
  allOperatorAttributeTypeIds) => {
  return _.sortBy(_.map(allOperatorAttributeTypeIds, id => operatorAttributeTypes[id]), o => o.name.toLowerCase()).filter(a => !a.deletedTimestamp);
});

export const makeGetOperatorAttributes = (operatorId: number) => createSelector([
  (state: RootState) => state.operatorAttributes.entities.operatorAttributes,
  (state: RootState) => state.operatorAttributes.xrefs.operatorOperatorAttributes,
], (
  operatorAttributes,
  operatorOperatorAttributes) => {
  return _.sortBy(_.map(operatorOperatorAttributes[operatorId], id => operatorAttributes[id]), o => o.value?.toLowerCase());
});

