import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';

import AutoTaskModal from 'AviationSafetySolutions/Tasks/AutoTaskModal';
import TaskStatusType from 'constants/TaskStatusType';
import { OperatorDashboardCurrentWeek, OperatorFeatureEnum } from 'models';
import { BackgroundImage, FlexRow, HideWithoutFeature, WithCategoryTasksIteration } from 'Components';

import DashboardTask from './DashboardTask';
import NextAuditCard from '../Components/NextAuditCard';
import NumPastDueTasksCard from '../Components/NumPastDueTasksCard';
import NumIncompleteTasksCard from '../Components/NumIncompleteTasksCard';
import { useSelectedOperatorOrThrow, useStateSelector } from '../../../hooks';
import PendingPollsCard from '../Components/PendingPollsCard';
import { TaskTab } from '../../../AviationSafetySolutions/Tasks/TaskModal';
import SafetyCoachTiles from './SafetyCoachTiles';
import { useDispatch } from 'react-redux';
import { getCurrentOperatorPendingPollCount } from '../../../Modules/polls/apiActions';
import { makeGetEnabledOperatorFeatures } from '../../../Modules/operators/selectors';

interface LocalProps {
  operatorDashboard: OperatorDashboardCurrentWeek;
}

const OperatorDashboard = ({ operatorDashboard }: LocalProps) => {
  const dispatch = useDispatch();
  const selectedOperator = useSelectedOperatorOrThrow();
  const [ selectedTaskId, setSelectedTaskId ] = useState<{ taskId: number; defaultTab: TaskTab } | undefined>();
  const currentOperatorPendingPollCount = useStateSelector(state => state.polls.ui.currentOperatorPendingPollCount);
  const pollingIsEnabled = useStateSelector(makeGetEnabledOperatorFeatures(selectedOperator.operatorId))[OperatorFeatureEnum.Polling];
  const { nextAudit, safetyCoaches, pastDueNeedingFeedbackTasks, tasksForThisWeek, persistentTasks } = operatorDashboard;

  useEffect(() => {
    if(pollingIsEnabled) {
      dispatch(getCurrentOperatorPendingPollCount());
    }
  }, []);

  const availableTasksForThisWeek = _.filter(tasksForThisWeek, (t) => t.currentStatus === TaskStatusType.Created);
  const rejectedTasksForThisWeek = _.filter(tasksForThisWeek, (t) => t.currentStatus === TaskStatusType.Rejected);
  const currentWeekTasksRequiringAttention = pastDueNeedingFeedbackTasks.concat(rejectedTasksForThisWeek).concat(availableTasksForThisWeek);

  const availablePersistentTasks = _.filter(persistentTasks, (t) => t.currentStatus === TaskStatusType.Created);
  const rejectedPersistentTasks = _.filter(persistentTasks, (t) => t.currentStatus === TaskStatusType.Rejected);
  const orderedPersistentTasks = rejectedPersistentTasks.concat(availablePersistentTasks);

  const incompleteTasks = currentWeekTasksRequiringAttention.concat(orderedPersistentTasks);

  const DashboardDivider = (
    <div className="py-3">
      <div className="h-100 border-left border-dark"/>
    </div>
  );

  return (
    <>
      <div className="d-flex flex-row flex-wrap justify-content-center justify-content-lg-between align-items-center mt-2">
        <div className="d-flex flex-row child-space-x-1 px-2 shadow-lg mb-2 mb-lg-0" style={{ 'background': '#ffffff42' }}>
          <div>
            <NextAuditCard audit={nextAudit}/>
          </div>

          {DashboardDivider}

          <div>
            <NumIncompleteTasksCard incompleteTasks={incompleteTasks}/>
          </div>

          {DashboardDivider}

          <div>
            <NumPastDueTasksCard pastDueTasks={pastDueNeedingFeedbackTasks}/>
          </div>

          <HideWithoutFeature feature={OperatorFeatureEnum.Polling}>
            {DashboardDivider}
            <div>
              <PendingPollsCard numPendingPolls={currentOperatorPendingPollCount} />
            </div>
          </HideWithoutFeature>
        </div>
        <div>
          <FlexRow childSpacingX={2}>
            <div className="d-flex flex-column align-items-center">
              <BackgroundImage
                src={selectedOperator.imageFile?.sasUrl || '/assets/img/default-user.svg'}
                alt={selectedOperator.name}
                title={selectedOperator.name}
                style={{ width: 80, height: 80 }}
              />

              <div className="text-center font-weight-bold text-primary">
                <strong>{selectedOperator.name}</strong>
              </div>
            </div>

            <SafetyCoachTiles safetyCoaches={safetyCoaches}/>
          </FlexRow>
        </div>
      </div>

      {_.size(currentWeekTasksRequiringAttention) > 0 && (
        <Row>
          <Col md={12}>
            <h3 className="text-secondary">Tasks for This Week</h3>

            <WithCategoryTasksIteration masonry appendUndefined tasks={currentWeekTasksRequiringAttention} idAccessor={(t) => t.taskId}>
              {({ task }) => (
                <div className="mb-2">
                  <DashboardTask
                    task={task}
                    onTaskClick={(task) => setSelectedTaskId({ taskId: task.taskId, defaultTab: TaskTab.Summary })}
                    messageSafetyCoach={() => setSelectedTaskId({ taskId: task.taskId, defaultTab: TaskTab.Messages })}
                    uploadFile={() => setSelectedTaskId({ taskId: task.taskId, defaultTab: TaskTab.Files })}
                  />
                </div>
              )}
            </WithCategoryTasksIteration>
          </Col>
        </Row>
      )}

      {_.size(orderedPersistentTasks) > 0 && (
        <Row>
          <Col md={12}>
            <h3 className="text-secondary">Incomplete Tasks</h3>

            <WithCategoryTasksIteration masonry appendUndefined tasks={orderedPersistentTasks}
              idAccessor={(t) => t.taskId}>
              {({ task }) => (
                <DashboardTask
                  task={task}
                  onTaskClick={(task) => setSelectedTaskId({ taskId: task.taskId, defaultTab: TaskTab.Summary })}
                  messageSafetyCoach={() => setSelectedTaskId({ taskId: task.taskId, defaultTab: TaskTab.Messages })}
                  uploadFile={() => setSelectedTaskId({ taskId: task.taskId, defaultTab: TaskTab.Files })}
                />
              )}
            </WithCategoryTasksIteration>
          </Col>
        </Row>
      )}

      <AutoTaskModal
        show={!!selectedTaskId} taskId={selectedTaskId?.taskId}
        defaultTab={selectedTaskId?.defaultTab}
        hide={() => setSelectedTaskId(undefined)}
      />
    </>
  );
};

export default OperatorDashboard;
