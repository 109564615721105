import React, { useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { preventDefaultAnd } from '../../helpers/eventHelpers';
import { submitTaskMessage } from '../../Modules/tasks/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Modules/reducers';
import { LoadableButton } from '../../Components';

interface Props {
  taskId: number;
}

const TaskQuickMessage = ({ taskId }: Props) => {
  const dispatch = useDispatch();
  const isSubmittingTaskMessage = useSelector((state: RootState) => state.tasks.loading.isSubmittingTaskMessage);
  const [ message, setMessage ] = useState('');

  function handleSubmit () {
    if (!message.trim()) {
      return;
    }

    dispatch(submitTaskMessage(taskId, message.trim(), true));
  }

  return (
    <Form onSubmit={preventDefaultAnd(handleSubmit)}>
      <FormGroup>
        <Input type="textarea" value={message} onChange={(e) => setMessage(e.target.value)} />
      </FormGroup>

      <LoadableButton
        block
        isLoading={isSubmittingTaskMessage}
        LoadingLabel="Sending..."
        color="success"
        className="primary"
        type="submit">
        Send Message
      </LoadableButton>
    </Form>
  );
};

export default TaskQuickMessage;
