import { useEffect } from 'react';
import usePrevious from './usePrevious';

interface Payload<T> {
  value: T;
  previousValue?: T;
}

const useOnPrevious = <T>(value: T, conditional: (previousValue: T | undefined, currentValue: T) => boolean): Promise<Payload<T>> => {
  return new Promise((resolve) => {
    const previousValue = usePrevious(value);

    useEffect(() => {
      if (conditional(previousValue, value)) {
        return resolve({ value, previousValue });
      }
    }, [ value, previousValue ]);
  });
};

export default useOnPrevious;
