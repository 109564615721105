import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import auditorTranslation from './translations/auditor.json';
import consultantTranslation from './translations/consultant.json';
import defaultTranslation from './translations/default.json';
import faaTranslation from './translations/faa.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'Default',
    resources: {
      Auditor: {
        translation: auditorTranslation,
      },
      Consultant: {
        translation: consultantTranslation,
      },
      FAA: {
        translation: faaTranslation,
      },
      Default: {
        translation: defaultTranslation,
      },
    },
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    missingKeyHandler: console.error,
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;
