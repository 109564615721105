import React, { ReactNode, useEffect } from 'react';
import { compose } from 'recompose';
import { useAuth0, withAuth0, WithAuth0Props, withAuthenticationRequired } from '@auth0/auth0-react';
import useStateSelector from './hooks/useStateSelector';
import { useDispatch } from 'react-redux';
import { siteActions } from './Modules/site';

interface LocalProps {
  children: ReactNode;
}

type Props = LocalProps & WithAuth0Props;

const AuthLoader = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const userIsLoaded = useStateSelector(state => !!state.site.authUser);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();

      dispatch(siteActions.setToken({ token, user }));
    })();
  }, [ user, getAccessTokenSilently ]);

  if (!userIsLoaded) {
    return null;
  }

  return <>{children}</>;
};

export default compose<Props, LocalProps>(
  withAuthenticationRequired,
  withAuth0
)(AuthLoader);
