import React from 'react';
import PreviewFileModal from 'Components/PreviewFileModal/PreviewFileModal';

interface Props {
  show: boolean;
  libraryFileId?: number;
  hide: () => void;
}

const PreviewLibraryFileModal = ({ show, libraryFileId, ...props }: Props) => {
  return (
    <PreviewFileModal
      show={show}
      getPreviewRequest={(superagent) => superagent.get(`/api/v1/libraryFiles/${libraryFileId}/preview`)}
      getDownloadRequest={(superagent) => superagent.post('/api/v1/libraryFiles/download').send([ libraryFileId ])}
      hide={props.hide}
    />
  );
};

export default PreviewLibraryFileModal;
