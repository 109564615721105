import './draft-editor.scss';

import React from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import classnames from 'classnames';

import CustomBlock from './Blocks/CustomBlock';

interface Props extends EditorProps {}

const DraftEditor = (props: Props) => {
  return (
    <Editor
      wrapperClassName={classnames(props.wrapperClassName, { 'read-only': props.readOnly })}
      customBlockRenderFunc={(block) => {
        if (block.getType() === 'atomic') {
          return {
            component: CustomBlock,
            editable: false,
          };
        }

        return null;
      }}
      {...props}
    />
  );
};

export default DraftEditor;
