import React from 'react';
import _ from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { AuditTemplate, TemplateTaskModel } from 'models';
import ModifyTemplateTaskModal from './ModifyTemplateTaskModal';
import { RootState } from '../../Modules/reducers';
import { updateTemplateTaskPositions } from '../../Modules/templates/actions';
import CategoryTemplateTaskGroup from './CategoryTemplateTaskGroup';
import WithCategories from '../../Components/WithCategories';

interface LocalProps {
  template: AuditTemplate;
  templateTasks: TemplateTaskModel;
}

interface LocalState {
  isCreatingNewTemplateTask: boolean;
}

interface StateProps {
}

interface DispatchProps {
  updateTemplateTaskPositions: typeof updateTemplateTaskPositions;
}

type Props = LocalProps & StateProps & DispatchProps;

class PersistentTasks extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      isCreatingNewTemplateTask: false,
    };
  }

  private startCreatingNewTask = () => {
    this.setState({ isCreatingNewTemplateTask: true });
  }

  private stopCreatingNewTask = () => {
    this.setState({ isCreatingNewTemplateTask: false });
  }

  public render () {
    const { isCreatingNewTemplateTask } = this.state;
    const { template, templateTasks } = this.props;

    return (
      <>
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-between">
              <div>
                <Button size="sm" color="primary" onClick={this.startCreatingNewTask}>Add Task...</Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={12}>
            {/* @ts-ignore */}
            <WithCategories appendUndefined>
              {({ sortedCategories }) => (
                <>
                  {
                    _.map(sortedCategories, (category, i) => {
                      const categoryTasks = _.filter(templateTasks.persistentTasks, t => (t.categoryId || undefined) === category?.categoryId);
                      const isLast = _.size(sortedCategories) - 1 === i;

                      if (_.isEmpty(categoryTasks)) {
                        return null;
                      }

                      return (
                        <React.Fragment
                          key={category?.categoryId || 'undefined'}
                        >
                          <Row>
                            <Col md={12}>
                              <CategoryTemplateTaskGroup
                                // @ts-ignore
                                tasks={categoryTasks}
                                // @ts-ignore
                                category={category}
                                // @ts-ignore
                                template={template}
                              />
                            </Col>
                          </Row>

                          {!isLast && (
                            <hr />
                          )}
                        </React.Fragment>
                      );
                    })
                  }
                </>
              )}
            </WithCategories>
          </Col>
        </Row>
        <ModifyTemplateTaskModal
          show={isCreatingNewTemplateTask}
          template={template}
          templateTask={{ templateTaskId: 0 }}
          hide={this.stopCreatingNewTask}
        />
      </>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  updateTemplateTaskPositions,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(PersistentTasks);
