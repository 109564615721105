import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiLoadingStatusPayload } from 'api';
import config from 'config';
import { PaginatedSearchContainer } from 'helpers/pagination';
import { commonActions } from 'Modules/common/actions';

import {
  DeleteOperatorGroupOperatorXrefsPayload,
  OperatorGroup,
  OperatorGroupMessageFileSummary,
  OperatorGroupOperatorXref,
  OperatorGroupSearchMessage,
  OperatorMessageGroupFileSearchRequest,
  OperatorOperatorGroupPayload,
  SetOperatorGroupOperatorXrefsPayload,
  SetSearchedOperatorGroupMessagesPayload,
} from './models';
import { PaginatedResult } from 'core/index';

export interface OperatorGroupsState {
  entities: {
    operatorGroups: { [operatorGroupId: number]: OperatorGroup };
    operatorGroupMessages: { [operatorGroupMessageId: number]: OperatorGroupSearchMessage };
    operatorGroupMessageFileSummaries: { [operatorGroupMessageFileId: number]: OperatorGroupMessageFileSummary };
    operatorGroupOperatorXrefs: { [operatorGroupOperatorXrefId: number]: OperatorGroupOperatorXref };
  };
  xrefs: {
    operatorGroupOperatorXrefs: {[operatorGroupId: number]: number[]};
  };
  ui: {
    currentOperatorGroupIds: number[];
    maintenance: {
      allOperatorGroupIds: number[];
      selectedOperatorGroupId?: number;
    };
    operatorGroups: {
      messagesPagination: PaginatedSearchContainer;
      messageFilesPagination: PaginatedSearchContainer;
    };
  };
  loading: {
    isLoadingAllOperatorGroups: boolean;
    isLoadingOperatorGroup: boolean;
    isLoadingOperatorGroupXrefs: boolean;
    isSearchingForOperatorMessages: boolean;
    isSearchingForOperatorMessageFiles: boolean;
    isLoadingOperatorGroupSearchMessage: boolean;
    isLoadingCurrentUserOperatorGroups: boolean;
    isUpdatingOperatorGroup: boolean;
    isInsertingOperatorGroup: boolean;
    isUpdatingOperatorGroupOperator: boolean;
    isInsertingOperatorGroupOperator: boolean;
    isDeletingOperatorGroup: boolean;
    isDeletingOperatorGroupOperator: boolean;
  };
}

const initialOperatorGroupsState: OperatorGroupsState = {
  entities: {
    operatorGroups: {},
    operatorGroupMessages: {},
    operatorGroupMessageFileSummaries: {},
    operatorGroupOperatorXrefs: {},
  },
  xrefs: {
    operatorGroupOperatorXrefs: {},
  },
  ui: {
    currentOperatorGroupIds: [],
    maintenance: {
      allOperatorGroupIds: [],
      selectedOperatorGroupId: undefined,
    },
    operatorGroups: {
      messagesPagination: {
        total: 0,
        ids: [],
        request: config.defaultPagination,
      },
      messageFilesPagination: {
        total: 0,
        ids: [],
        request: config.defaultPagination,
      },
    },
  },
  loading: {
    isLoadingAllOperatorGroups: false,
    isLoadingOperatorGroup: false,
    isLoadingOperatorGroupXrefs: false,
    isSearchingForOperatorMessages: false,
    isSearchingForOperatorMessageFiles: false,
    isLoadingOperatorGroupSearchMessage: false,
    isLoadingCurrentUserOperatorGroups: true,
    isUpdatingOperatorGroup: false,
    isInsertingOperatorGroup: false,
    isUpdatingOperatorGroupOperator: false,
    isInsertingOperatorGroupOperator: false,
    isDeletingOperatorGroup: false,
    isDeletingOperatorGroupOperator: false,
  },
};

export const operatorGroupsSlice = createSlice({
  name: 'OperatorGroups',
  initialState: initialOperatorGroupsState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<OperatorGroupsState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setSelectedMaintenanceOperatorGroup: (state, action: PayloadAction<number>) => {
      state.ui.maintenance.selectedOperatorGroupId = action.payload === state.ui.maintenance.selectedOperatorGroupId ? undefined : action.payload;
    },
    setAllOperatorGroups: (state, action: PayloadAction<OperatorGroup[]>) => {
      state.entities.operatorGroups = {
        ...state.entities.operatorGroups,
        ..._.keyBy(action.payload, (o) => o.operatorGroupId),
      };

      state.ui.maintenance.allOperatorGroupIds = _.map(action.payload, (o) => o.operatorGroupId);
    },
    setOperatorGroup: (state, action: PayloadAction<OperatorGroup>) => {
      state.entities.operatorGroups[action.payload.operatorGroupId] = action.payload;
    },
    setOperatorGroupOperatorXrefs: (state, action: PayloadAction<SetOperatorGroupOperatorXrefsPayload>) => {
      state.entities.operatorGroupOperatorXrefs = {
        ...state.entities.operatorGroupOperatorXrefs,
        ..._.keyBy(action.payload.xrefs, (o) => o.operatorGroupOperatorXrefId),
      };

      state.xrefs.operatorGroupOperatorXrefs[action.payload.operatorGroupId] = _.map(action.payload.xrefs, x => x.operatorGroupOperatorXrefId);
    },
    setCurrentUserOperatorGroups: (state, action: PayloadAction<OperatorOperatorGroupPayload>) => {
      state.entities.operatorGroups = {
        ...state.entities.operatorGroups,
        ..._.keyBy(action.payload.operatorGroups, (o) => o.operatorGroupId),
      };

      state.ui.currentOperatorGroupIds = _.map(action.payload.operatorGroups, o => o.operatorGroupId);
    },
    setSearchedOperatorGroupMessages: (state, action: PayloadAction<SetSearchedOperatorGroupMessagesPayload>) => {
      state.entities.operatorGroupMessages = {
        ...state.entities.operatorGroupMessages,
        ..._.keyBy(action.payload.result.items, (o) => o.operatorGroupMessageId),
      };

      state.ui.operatorGroups.messagesPagination = {
        total: action.payload.result.totalItems,
        request: action.payload.request,
        ids: _.concat(action.payload.result.currentPage > 1 ? state.ui.operatorGroups.messagesPagination.ids : [], _.map(action.payload.result.items, i => i.operatorGroupMessageId)),
      };
    },
    setSearchedOperatorGroupMessageFiles: (state, action: PayloadAction<{ operatorGroupId: number; request: OperatorMessageGroupFileSearchRequest; result: PaginatedResult<OperatorGroupMessageFileSummary> }>) => {
      state.entities.operatorGroupMessageFileSummaries = {
        ...state.entities.operatorGroupMessageFileSummaries,
        ..._.keyBy(action.payload.result.results, (o) => o.id),
      };

      state.ui.operatorGroups.messageFilesPagination = {
        total: action.payload.result.total,
        request: action.payload.request,
        ids: action.payload.result.results.map(r => r.id),
      };
    },
    operatorGroupUpdated: (state, action: PayloadAction<OperatorGroup>) => {
      state.entities.operatorGroups = {
        ...state.entities.operatorGroups,
        [action.payload.operatorGroupId]: action.payload,
      };
    },
    operatorGroupInserted: (state, action: PayloadAction<OperatorGroup>) => {
      state.entities.operatorGroups = {
        ...state.entities.operatorGroups,
        [action.payload.operatorGroupId]: action.payload,
      };

      state.ui.maintenance.allOperatorGroupIds.push(action.payload.operatorGroupId);
      state.ui.maintenance.selectedOperatorGroupId = action.payload.operatorGroupId;
    },
    operatorGroupOperatorUpdated: (state, action: PayloadAction<OperatorGroupOperatorXref>) => {
      state.entities.operatorGroupOperatorXrefs = {
        ...state.entities.operatorGroupOperatorXrefs,
        [action.payload.operatorGroupOperatorXrefId]: action.payload,
      };
    },
    operatorGroupOperatorInserted: (state, action: PayloadAction<OperatorGroupOperatorXref>) => {
      state.entities.operatorGroupOperatorXrefs = {
        ...state.entities.operatorGroupOperatorXrefs,
        [action.payload.operatorGroupOperatorXrefId]: action.payload,
      };

      state.xrefs.operatorGroupOperatorXrefs[action.payload.operatorGroupId] = _.concat(state.xrefs.operatorGroupOperatorXrefs[action.payload.operatorGroupId], action.payload.operatorGroupOperatorXrefId);
    },
    operatorGroupMessageInserted: (state, action: PayloadAction<OperatorGroupSearchMessage>) => {
      state.ui.operatorGroups.messagesPagination.ids = [ action.payload.operatorGroupMessageId ].concat(state.ui.operatorGroups.messagesPagination.ids);
      state.ui.operatorGroups.messagesPagination.total = state.ui.operatorGroups.messagesPagination.total + 1;
      state.entities.operatorGroupMessages[action.payload.operatorGroupMessageId] = action.payload;
    },
    operatorGroupDeleted: (state, action: PayloadAction<number>) => {
      state.entities.operatorGroups = _.omit(state.entities.operatorGroups, action.payload);
      state.ui.maintenance.allOperatorGroupIds = _.without(state.ui.maintenance.allOperatorGroupIds, action.payload);
      state.ui.maintenance.selectedOperatorGroupId = undefined;
    },
    operatorGroupOperatorDeleted: (state, action: PayloadAction<DeleteOperatorGroupOperatorXrefsPayload>) => {
      state.entities.operatorGroupOperatorXrefs = _.omit(state.entities.operatorGroupOperatorXrefs, action.payload.operatorGroupOperatorXrefId);
      state.xrefs.operatorGroupOperatorXrefs[action.payload.operatorGroupId] = _.without(state.xrefs.operatorGroupOperatorXrefs[action.payload.operatorGroupId], action.payload.operatorGroupOperatorXrefId);
    },
  },
  extraReducers: {
    [commonActions.PATH_LOCATION_CHANGE]: () => {
      return { ...initialOperatorGroupsState };
    },
  },
});
