import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { StandardsState } from './reducer';
import { standardsActions } from './actions';

const loadingConfigs: LoadingActionConfig<StandardsState>[] = [
  {
    start: standardsActions.GET_ALL_STANDARDS_REQUEST,
    stop: [ standardsActions.GET_ALL_STANDARDS_SUCCESS, standardsActions.GET_ALL_STANDARDS_FAILURE ],
    updater: (state: StandardsState, isLoading): StandardsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingAllStandards: isLoading,
        },
      };
    },
  },
  {
    start: standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_REQUEST,
    stop: [ standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_SUCCESS, standardsActions.GET_STANDARD_REFERENCES_BY_STANDARD_FAILURE ],
    updater: (state: StandardsState, isLoading): StandardsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingStandardReferences: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
