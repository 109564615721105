import Swal from 'sweetalert2';

interface UserConfirmParams {
  title: string;
  text: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const userConfirm = (config: UserConfirmParams, confirmCallback: () => void, dismissCallback?: () => void) => {
  (async () => {
    const { dismiss } = await Swal.fire({
      title: config.title,
      text: config.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: config.confirmButtonText || 'Confirm',
      cancelButtonText: config.cancelButtonText,
    });

    if (dismiss) {
      dismissCallback?.();
      return;
    }

    confirmCallback();
  })();
};
