import React from 'react';
import { Button, Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import ModalHeaderWithClose from 'Components/ModalHeaderWithClose';
import { ShareBoardCategory, ShareBoardFile } from '../../Modules/shareBoard/models';
import { useThunkDispatch } from '../../hooks/index';
import { shareBoardActions } from '../../Modules/shareBoard/index';
import _ from 'lodash';
import { FlexColumn, FlexRow, Panel, ShareBoardFileTile, VerticalOnlyScrollPanel } from '../../Components/index';
import useShareBoardCategories from '../../hooks/useShareBoardCategories';

interface Props {
  show: boolean;
  operatorGroupId: number | undefined,
  shareBoardFile: ShareBoardFile;
  onCategoryToggled?: (shareBoardCategory: ShareBoardCategory) => void;
  hide: () => void;
}

const ShareBoardFileCategoryModal = ({ show, shareBoardFile, operatorGroupId, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const { shareBoardCategories } = useShareBoardCategories({ enabled: show });

  async function toggleShareBoardFileCategory(shareBoardCategory: ShareBoardCategory) {
    const currentlySelected = shareBoardFile.categories.find((category) => category.id === shareBoardCategory.id);
    if (currentlySelected) {
      await dispatch(shareBoardActions.async.removeShareBoardFileFromCategory(shareBoardFile.id, shareBoardCategory));
    } else {
      await dispatch(shareBoardActions.async.addShareBoardFileToCategory(shareBoardFile.id, shareBoardCategory));
    }

    props.onCategoryToggled?.(shareBoardCategory);
  }

  function toggleShareBoardFileApproval(shareBoardFile: ShareBoardFile) {
    if(!operatorGroupId) {
      return;
    }

    if(shareBoardFile.approved) {
      dispatch(shareBoardActions.async.unApproveShareBoardFiles(operatorGroupId, {
        shareBoardFileIds: [ shareBoardFile.id ],
      }));
    } else {
      dispatch(shareBoardActions.async.approveShareBoardFiles(operatorGroupId, {
        shareBoardFileIds: [ shareBoardFile.id ],
      }));
    }
  }

  return (
    <>
      <Modal isOpen={show} toggle={props.hide} size="">
        <ModalHeaderWithClose hide={props.hide}>
          Approve Share Board Files
        </ModalHeaderWithClose>
        <ModalBody>
          <Panel style={{ maxHeight: '80vh' }}>
            <VerticalOnlyScrollPanel>
              <Row>
                <Col md={12}>
                  <ShareBoardFileTile
                    operatorGroupId={operatorGroupId}
                    shareBoardFile={shareBoardFile}
                    toggleShareBoardFileApproval={toggleShareBoardFileApproval}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12}>
                  <Label>Categories:</Label>

                  <div>
                    {
                      shareBoardCategories.map((category) => {
                        const isInCategory = _.some(shareBoardFile.categories, (selectedShareBoardFileCategory) => selectedShareBoardFileCategory.id === category.id);
                        return (
                          <Button
                            key={category.id}
                            color={isInCategory ? 'primary' : 'light'}
                            className="mr-2 mb-2"
                            onClick={() => toggleShareBoardFileCategory(category)}
                          >
                            {category.name}
                          </Button>
                        );
                      })
                    }
                  </div>
                </Col>
              </Row>
            </VerticalOnlyScrollPanel>

            <FlexRow childSpacingX={2} className="mt-2" justifyBetween>
              <div />
              <FlexRow childSpacingX={1}>
                <FlexColumn>
                  <Button
                    color="light"
                    onClick={props.hide}
                  >
                    Close
                  </Button>
                </FlexColumn>
              </FlexRow>
            </FlexRow>
          </Panel>
        </ModalBody>
      </Modal>
    </>
  );
};


export default ShareBoardFileCategoryModal;
