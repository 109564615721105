import './panel-header.scss';

import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Flex, FlexColumn, FlexRow } from 'Components';

interface Props {
  children: ReactNode;
  pad?: boolean;
  small?: boolean;
  overflowHidden?: boolean;
  className?: string;
  white?: boolean;
  RightContent?: ReactNode;
  BottomContent?: ReactNode;
}

const PanelHeader = ({
  children,
  className,
  pad = true,
  small,
  white,
  overflowHidden,
  RightContent,
  BottomContent,
}: Props) => {
  return (
    <div
      className={classnames(className, 'panel-header child-spacing-x-2', {
        'bg-white': white,
        'px-2 pb-1 pt-1': pad,
        'small': small,
      })}
    >
      <FlexColumn fill>
        <FlexRow childSpacingX={1} fill alignCenter>
          <Flex fill overflowHidden={overflowHidden}>
            <h4 className={classnames('m-0 header-text', { 'overflow-hidden': overflowHidden })}>{children}</h4>
          </Flex>
          {RightContent}
        </FlexRow>

        {BottomContent}
      </FlexColumn>
    </div>
  );
};

export default React.memo(PanelHeader);
