import _ from 'lodash';
import { auditsActions } from './actions';
import { format, isAfter, isBefore, isSameDay, parse, parseISO } from 'date-fns';
import { dashboardActions } from '../dashboard/actions';
import {
  Action,
  AssignedTaskModel,
  AuditWeekModel,
  OperatorAuditModel,
  OperatorDashboardModelResponse,
  ReducerFunction,
  SafetyCoachDashboardResponse,
} from '../../models';
import { commonActions } from '../common/actions';
import { compose } from 'redux';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import loadingConfigs from './loading';

export interface AuditState {
  entities: {
    audits: { [auditId: number]: OperatorAuditModel };
  };
  mappings: {
    operatorAuditIds: {[operatorId: number]: number[]};
    auditTasks: {[auditId: number]: number[]};
  };
  ui: {
    modifyingAudit?: any;
  };
  loading: {
    updatingOperatorAudits: {[operatorAuditId: number]: number};
    isSavingOperatorAudit: boolean;
    isLoadingOperatorAudit: boolean;
    isLoadingOperatorAuditTasks: boolean;
    isSyncingAuditTemplateToAudit: boolean;
  };
}

const initState: AuditState = {
  entities: {
    audits: {},
  },
  mappings: {
    operatorAuditIds: {},
    auditTasks: {},
  },
  ui: {
    modifyingAudit: undefined,
  },
  loading: {
    updatingOperatorAudits: {},
    isSavingOperatorAudit: false,
    isLoadingOperatorAudit: false,
    isLoadingOperatorAuditTasks: false,
    isSyncingAuditTemplateToAudit: false,
  },
};

export default compose<ReducerFunction<AuditState>>(
  makeLoadingReducer<AuditState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): AuditState => {
  switch (action.type) {
    case auditsActions.CHANGE_MODIFYING_AUDIT_VALUES: {
      const { values } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          modifyingAudit: values ? {
            ...state.ui.modifyingAudit,
            ...values,
          } : undefined,
        },
      };
    }
    case auditsActions.GET_OPERATOR_AUDITS_SUCCESS: {
      const { audits, operatorId } = action.payload;

      const auditModels = _.map(audits, getAuditModel);

      return {
        ...state,
        entities: {
          ...state.entities,
          audits: {
            ...state.entities.audits,
            ..._.keyBy(auditModels, 'operatorAuditId'),
          },
        },
        mappings: {
          ...state.mappings,
          operatorAuditIds: {
            ...state.mappings.operatorAuditIds,
            [operatorId]: _.map(auditModels, 'operatorAuditId'),
          },
        },
      };
    }
    case auditsActions.GET_OPERATOR_AUDIT_SUCCESS: {
      const { audit }: { audit?: OperatorAuditModel } = action.payload;

      if (!audit) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          audits: {
            ...state.entities.audits,
            [audit.operatorAuditId]: getAuditModel(audit),
          },
        },
      };
    }
    case dashboardActions.GET_SAFETY_COACH_DASHBOARD_SUCCESS:
    case dashboardActions.GET_OPERATOR_DASHBOARD_SUCCESS: {
      const { operatorNextAudit, operatorAuditTasks } = action.payload as SafetyCoachDashboardResponse | OperatorDashboardModelResponse;

      if(!operatorNextAudit) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          audits: {
            ...state.entities.audits,
            [operatorNextAudit.operatorAuditId]: getAuditModel(operatorNextAudit),
          },
        },
        mappings: {
          ...state.mappings,
          auditTasks: {
            ...state.mappings.auditTasks,
            [operatorNextAudit.operatorAuditId]: _.sortBy(operatorAuditTasks, t => t.taskDate).map(t => t.taskId),
          },
        },
      };
    }
    case auditsActions.GET_OPERATOR_AUDIT_TASKS_SUCCESS: {
      const { auditId, tasks }: { auditId: number; tasks: AssignedTaskModel[] } = action.payload;

      return {
        ...state,
        mappings: {
          ...state.mappings,
          auditTasks: {
            ...state.mappings.auditTasks,
            [auditId]: _.chain(tasks)
              .sortBy(t => t.taskDate)
              .map(t => t.taskId)
              .value(),
          },
        },
      };
    }
    case auditsActions.UPDATE_OPERATOR_AUDIT_SUCCESS: {
      const { audit } = action.payload;
      const { operatorAuditId } = audit;

      return {
        ...state,
        entities: {
          ...state.entities,
          audits: {
            ...state.entities.audits,
            [operatorAuditId]: getAuditModel(audit),
          },
        },
        ui: {
          ...state.ui,
          modifyingAudit: undefined,
        },
      };
    }
    case auditsActions.INSERT_OPERATOR_AUDIT_SUCCESS: {
      const { audit } = action.payload;
      const { operatorAuditId, operatorId } = audit;

      return {
        ...state,
        entities: {
          ...state.entities,
          audits: {
            ...state.entities.audits,
            [operatorAuditId]: getAuditModel(audit),
          },
        },
        mappings: {
          ...state.mappings,
          operatorAuditIds: {
            ...state.mappings.operatorAuditIds,
            [operatorId]: _.get(state.mappings.operatorAuditIds, operatorId, []).concat(operatorAuditId),
          },
        },
        ui: {
          ...state.ui,
          modifyingAudit: undefined,
        },
      };
    }
    case auditsActions.DELETE_OPERATOR_AUDIT_SUCCESS: {
      const { operatorAuditId, operatorId }: { operatorAuditId: number; operatorId: number } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          audits: _.omit(state.entities.audits, operatorAuditId),
        },
        mappings: {
          ...state.mappings,
          operatorAuditIds: {
            ...state.mappings.operatorAuditIds,
            [operatorId]: _.without(state.mappings.operatorAuditIds[operatorId], operatorAuditId),
          },
        },
        ui: {
          ...state.ui,
          modifyingAudit: undefined,
        },
      };
    }
    case commonActions.PATH_LOCATION_CHANGE: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
});

function getAuditModel (audit: OperatorAuditModel): OperatorAuditModel {
  const parsedStartPrepDate = parseISO(audit.startPrepDate);
  const parsedAuditDate = parseISO(audit.auditDate);
  return {
    ...audit,
    parsedAuditDate: parsedAuditDate,
    parsedStartPrepDate: parsedStartPrepDate,
    displayStartPrepDate: format(parsedStartPrepDate, 'MMMM dd, yyyy'),
    displayAuditDate: format(parsedAuditDate, 'MMMM dd, yyyy'),
    weeks: _.map(audit.weeks, getWeekModel),
  };
}

function getWeekModel (week: AuditWeekModel): AuditWeekModel {
  const parsedStartDate = parse(week.startDate, 'yyyy-MM-dd', new Date());
  const parsedEndDate = parse(week.endDate, 'yyyy-MM-dd', new Date());
  return {
    ...week,
    parsedStartDate: parsedStartDate,
    parsedEndDate: parsedEndDate,
    isCurrentWeek: isSameDay(new Date(), parsedStartDate) || isSameDay(new Date(), parsedEndDate) || (isAfter(new Date(), parsedStartDate) && isBefore(new Date(), parsedEndDate)),
    displayStartDate: format(parsedStartDate, 'MMMM dd, yyyy'),
    displayEndDate: format(parsedEndDate, 'MMMM dd, yyyy'),
    displayShortStartDate: format(parsedStartDate, 'MMMM dd'),
    displayShortEndDate: format(parsedEndDate, 'MMMM dd'),
  };
}
