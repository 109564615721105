import _ from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';
import { FileTree } from './models';
import { LibraryFile } from '../../models';

const getFiles = (state: RootState) => state.library.entities.files;
const getOperatorHiddenFolders = (state: RootState) => state.library.entities.operatorHiddenFolders;
const getOperatorUploadFileIds = (state: RootState) => state.library.mappings.operatorUploadFiles;
const getOperatorHiddenFoldersIds = (state: RootState) => state.library.mappings.operatorHiddenFolders;
const getOperatorCreatedFolders = (state: RootState) => state.library.ui.operatorCreatedFolders;
const getOperatorFolders = (state: RootState) => state.library.ui.operatorFolders;
const getOperatorSelectedFiles = (state: RootState) => state.library.ui.operatorSelectedFiles;
const getShowingHiddenFolders = (state: RootState) => state.library.ui.showingHiddenFolders;

export const selectOperatorFileTree = createSelector([
  getFiles,
  getOperatorHiddenFolders,
  getOperatorUploadFileIds,
  getOperatorHiddenFoldersIds,
  getOperatorCreatedFolders,
  getOperatorFolders,
  getShowingHiddenFolders,
  (state: RootState, operatorId: number) => operatorId,
], (
  files,
  operatorHiddenFolders,
  operatorFileIds,
  operatorHiddenFoldersIds,
  operatorCreatedFolders,
  operatorFolders,
  showingHiddenFolders: boolean,
  operatorId: number
) => {
  const operatorFiles = getNumberMappedEntities(files, operatorFileIds[operatorId]);
  const hiddenDirectories = _.map(getNumberMappedEntities(operatorHiddenFolders, operatorHiddenFoldersIds[operatorId]), f => f.directoryPath);

  function pathStartsWithHiddenDirectory (path: string) {
    return !showingHiddenFolders && _.some(hiddenDirectories, d => _.startsWith(path, d));
  }

  const fileTree = new FileTree<LibraryFile>({ filePathSelector: f => f.filePath });

  fileTree.addAllFiles(_.filter(operatorFiles, f => !pathStartsWithHiddenDirectory(f.directoryPath)));

  _.each(operatorCreatedFolders[operatorId], (folder) => {
    if (pathStartsWithHiddenDirectory(folder)) {
      return;
    }
    fileTree.addFolder(folder);
  });

  _.each(operatorFolders[operatorId], (folder) => {
    if (pathStartsWithHiddenDirectory(folder)) {
      return;
    }
    fileTree.addFolder(folder);
  });

  return fileTree;
});

export const selectOperatorSelectedFiles = createSelector([
  getFiles,
  getOperatorSelectedFiles,
  (state: RootState, operatorId: number) => operatorId,
], (
  files,
  operatorSelectedFiles,
  operatorId: number
) => {
  return getNumberMappedEntities(files, operatorSelectedFiles[operatorId]);
});
