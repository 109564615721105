import { commonActions } from '../common/actions';
import appConfig from '../../config';
import { Action, ApiAction } from '../../models';

export const dashboardActions = {
  GET_OPERATOR_DASHBOARD_REQUEST: 'AVSafetySolutions/dashboard/getOperatorDashboardRequest',
  GET_OPERATOR_DASHBOARD_SUCCESS: 'AVSafetySolutions/dashboard/getOperatorDashboardSuccess',
  GET_OPERATOR_DASHBOARD_FAILURE: 'AVSafetySolutions/dashboard/getOperatorDashboardFailure',

  GET_SAFETY_COACH_DASHBOARD_REQUEST: 'AVSafetySolutions/dashboard/getSafetyCoachDashboardRequest',
  GET_SAFETY_COACH_DASHBOARD_SUCCESS: 'AVSafetySolutions/dashboard/getSafetyCoachDashboardSuccess',
  GET_SAFETY_COACH_DASHBOARD_FAILURE: 'AVSafetySolutions/dashboard/getSafetyCoachDashboardFailure',
};

export const getOperatorDashboard = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/dashboard/operators`);
      },
      beforeRequest: (): Action => ({ type: dashboardActions.GET_OPERATOR_DASHBOARD_REQUEST }),
      success: (data): Action => ({ type: dashboardActions.GET_OPERATOR_DASHBOARD_SUCCESS, payload: data }),
      failure: (response): Action => ({ type: dashboardActions.GET_OPERATOR_DASHBOARD_FAILURE, payload: { ...response } }),
    },
  };
};

export const getSafetyCoachDashboardDashboard = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/dashboard/coaches`);
      },
      beforeRequest: (): Action => ({ type: dashboardActions.GET_SAFETY_COACH_DASHBOARD_REQUEST }),
      success: (data): Action => ({ type: dashboardActions.GET_SAFETY_COACH_DASHBOARD_SUCCESS, payload: data }),
      failure: (response): Action => ({ type: dashboardActions.GET_SAFETY_COACH_DASHBOARD_FAILURE, payload: { ...response } }),
    },
  };
};
