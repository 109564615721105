import { CreateOperator } from './models';
import { DictionaryType } from '../../models';

export const getNewOperator = (): CreateOperator => {
  return {
    name: '',
    dictionaryTypeId: DictionaryType.SafetyCoach,
    programId: undefined,
  };
};
