import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import _ from 'lodash';

import { useOperatorAttributeForm, useOperatorAttributes, useSelectedOperatorOrThrow } from 'hooks';
import { ModifyOperatorAttribute, OperatorAttribute } from 'Modules/operatorAttributes';
import {
  BackgroundImage,
  DomClass,
  EditFormFooter,
  EditFormPanel,
  FlexLoader,
  FlexRow,
  OpenModalButton,
  OperatorProfileFormControls,
  Panel,
  PanelHeader,
} from 'Components';
import { useDispatch } from 'react-redux';
import { removeProfilePicture } from '../../Modules/operators/apiActions';
import UploadOperatorProfilePictureModal from '../../Components/UploadOperatorProfilePictureModal';

const OperatorProfilePage = () => {
  const dispatch = useDispatch();
  const selectedOperator = useSelectedOperatorOrThrow();
  const [ operatorAttributes, isLoadingOperatorAttributes ] = useOperatorAttributes(selectedOperator.operatorId);
  const { updateOperatorAttribute, insertOperatorAttribute } = useOperatorAttributeForm();
  const [ isSaving, setIsSaving ] = useState(false);
  const [ operatorAttributeValues, setOperatorAttributeValues ] = useState<{[operatorAttributeTypeId: number]: Partial<ModifyOperatorAttribute>}>({});

  const operatorAttributesByType = useMemo(() => {
    return _.keyBy(operatorAttributes, o => o.attributeTypeId);
  }, [ operatorAttributes ]);

  function handleAttributeValueChange(attributeTypeId: number, values: Partial<ModifyOperatorAttribute>) {
    setOperatorAttributeValues({
      ...operatorAttributeValues,
      [attributeTypeId]: {
        ...operatorAttributeValues[attributeTypeId],
        ...values,
      },
    });
  }

  async function handleSave() {
    setIsSaving(true);
    try {
      const promises = _.map(operatorAttributeValues, (operatorAttributeValues, operatorAttributeTypeIdString): Promise<OperatorAttribute> => {
        const operatorAttributeTypeId = parseInt(operatorAttributeTypeIdString, 10);
        const existing = operatorAttributes.find(o => o.attributeTypeId === operatorAttributeTypeId);

        if (existing) {
          return updateOperatorAttribute(selectedOperator.operatorId, existing.id, {
            ...operatorAttributeValues,
            attributeTypeId: operatorAttributeTypeId,
          });
        } else {
          return insertOperatorAttribute(selectedOperator.operatorId, {
            ...operatorAttributeValues,
            attributeTypeId: operatorAttributeTypeId,
          });
        }
      });

      await Promise.all(promises);

      setOperatorAttributeValues({});
    } finally {
      setIsSaving(false);
    }
  }

  if (isLoadingOperatorAttributes) {
    return (
      <FlexLoader />
    );
  }

  return (
    <>
      <Helmet>
        <title>{selectedOperator.name} Profile</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white className="operator-group">
        <PanelHeader>{selectedOperator.name} Profile</PanelHeader>
        <EditFormPanel
          Footer={(
            <EditFormFooter
              saveButton={{
                onClick: handleSave,
                isLoading: isSaving,
              }}
            />
          )}
        >
          <Row>
            <Col md={8}>
              <OperatorProfileFormControls
                operatorAttributesByType={operatorAttributesByType}
                operatorAttributeTypeValues={operatorAttributeValues}
                setValues={handleAttributeValueChange}
              />
            </Col>

            <Col md={4}>
              <FormGroup>
                <div>
                  <Label>Profile Picture</Label>
                </div>
                {selectedOperator.imageFile ? (
                  <div className="child-spacing-y-1">
                    <BackgroundImage
                      src={selectedOperator.imageFile.sasUrl}
                      style={{ width: 300, height: 300 }}
                    />

                    <FlexRow childSpacingX={1}>
                      <OpenModalButton
                        size="sm"
                        color="primary"
                        renderModal={(show, hide) => (
                          <UploadOperatorProfilePictureModal
                            show={show}
                            operatorId={selectedOperator.operatorId}
                            hide={hide}
                          />
                        )}
                      >
                        Change Profile Picture
                      </OpenModalButton>

                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => dispatch(removeProfilePicture(selectedOperator.operatorId))}
                      >
                        Remove Profile Picture
                      </Button>
                    </FlexRow>
                  </div>
                ) : (
                  <OpenModalButton
                    renderModal={(show, hide) => (
                      <UploadOperatorProfilePictureModal
                        show={show}
                        operatorId={selectedOperator.operatorId}
                        hide={hide}
                      />
                    )}
                  >
                    Upload Profile Picture
                  </OpenModalButton>
                )}
              </FormGroup>
            </Col>
          </Row>
        </EditFormPanel>
      </Panel>
    </>
  );
};

export default OperatorProfilePage;
