import { operatorsSlice } from './slice';
import * as operatorsApiActions from './apiActions';

const operatorsActions = {
  ...operatorsSlice.actions,
  async: {
    ...operatorsApiActions,
  },
};

export default operatorsActions;
