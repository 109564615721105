import React from 'react';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { baseTableOptions } from 'helpers/tableHelper';
import { PollOption, pollsActions } from 'Modules/polls';
import { BaseTable, CheckboxIcon } from 'Components';

interface LocalProps {
  pollOptions: PollOption[];
}

const PollOptionsTable = ({ pollOptions }: LocalProps) => {
  const dispatch = useDispatch();

  function deletePollOption(pollOption: PollOption) {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Delete Option',
        text: 'Are you sure you want to delete this poll option?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      dispatch(pollsActions.async.deletePollOption(pollOption));
    })();
  }

  const tableOptions: any = {
    ...baseTableOptions,
    minRows: 10,
    data: pollOptions,
    columns: [
      {
        id: 'id',
        Header: 'Id',
        accessor: (r: PollOption) => r.id,
        width: 45,
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: (r: PollOption) => r.name,
      },
      {
        id: 'predefined',
        Header: 'Predefined',
        width: 85,
        accessor: (r: PollOption) => r.predefined,
        Cell: ({ original }: { original: PollOption }) => (
          <span><CheckboxIcon isSelected={original.predefined}/></span>
        ),
      },
      {
        Header: '',
        id: 'delete',
        className: 'text-center',
        width: 32,
        Cell: ({ original }: { original: PollOption }) => {
          return (
            <Button color="anchor" size="sm" onClick={() => deletePollOption(original)}>
              <i className="mdi mdi-trash-can" />
            </Button>
          );
        },
      },
    ],
    defaultSorted: [ { id: 'name', desc: false } ],
  };

  return (
    <div className="bg-white">
      <BaseTable {...tableOptions} />
    </div>
  );
};

export default PollOptionsTable;
