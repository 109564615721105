import { OperatorModel } from '../models';
import useSelectedOperator from './useSelectedOperator';

const useSelectedOperatorOrThrow = (): OperatorModel => {
  const selectedOperator = useSelectedOperator();

  if(!selectedOperator) {
    throw new Error('Operator Expected but not selected.');
  }

  return selectedOperator;
};

export default useSelectedOperatorOrThrow;
