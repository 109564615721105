import './file-tile.scss';

import React, { ReactNode } from 'react';
import moment from 'moment';

import { Flex, FlexRow, RealtimeHumanizeDateTime } from 'Components';

interface Props {
  fileName: string;
  createdTimestamp: string;
  Thumbnail?: ReactNode;
  TopLeft?: ReactNode;
  TopRight?: ReactNode;
  children?: ReactNode;
  onTileClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const FileTile = ({ fileName, createdTimestamp, TopLeft, TopRight, Thumbnail, children, ...props }: Props) => {
  return (
    <div className="file-tile" onClick={props.onTileClick}>
      <div className="text-center">
        {Thumbnail && (
          <div className="position-relative">
            <Flex center>
              {Thumbnail}
            </Flex>
          </div>
        )}
        <h4>
          <FlexRow center childSpacingX={1}>
            <span className="folder-name">{fileName}</span>
          </FlexRow>
        </h4>

        <h5 className="m-0"><small><RealtimeHumanizeDateTime date={moment.utc(createdTimestamp)}/></small></h5>
      </div>

      {TopLeft && (
        <div className="top-left-overlay p-1">
          {TopLeft}
        </div>
      )}

      {TopRight && (
        <div className="top-right-overlay p-1">
          {TopRight}
        </div>
      )}

      {children}
    </div>
  );
};

export default FileTile;
