import _ from 'lodash';
import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { CreatePoll, ModifyPoll, Poll, pollsActions, UpdatePoll } from 'Modules/polls';

const usePollForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingPoll = useStateSelector((state) => state.polls.loading.isInsertingPoll);
  const isUpdatingPoll = useStateSelector((state) => state.polls.loading.isUpdatingPoll);
  const isDeletingPoll = useStateSelector((state) => state.polls.loading.isDeletingPoll);

  function validatePoll(poll: ModifyPoll) {
    const errors: string[] = [];

    if(!poll.startDate) {
      errors.push('You must select a start date for this poll');
    }

    return errors;
  }

  function insertPoll(poll: CreatePoll) {
    const errorMessages = validatePoll(poll);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(pollsActions.async.insertPoll(poll))
      .then((poll: Poll) => {
        toast.success('Poll successfully created.');
        return poll;
      });
  }

  function updatePoll(pollId: number, poll: UpdatePoll) {
    const errorMessages = validatePoll(poll);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(pollsActions.async.updatePoll(pollId, poll))
      .then((poll: Poll) => {
        toast.success('Poll successfully saved.');
        return poll;
      });
  }

  function deletePoll(poll: Poll) {
    return dispatch(pollsActions.async.deletePoll(poll))
      .then((poll: Poll) => {
        toast.success('Poll successfully deactivated.');
        return poll;
      });
  }

  function cancelPollModification() {
    dispatch(pollsActions.cancelPollModification());
  }

  return {
    insertPoll,
    updatePoll,
    deletePoll,
    cancelPollModification,
    loading: {
      isInsertingPoll,
      isUpdatingPoll,
      isDeletingPoll,
    },
  };
};

export default usePollForm;
