import React, { useMemo } from 'react';
import Select from 'react-select';
import { Props as ReactSelectProps } from 'react-select/src/Select';
import { OperatorGroup } from 'Modules/operatorGroups/models';
import useAllOperatorGroups from '../hooks/useAllOperatorGroups';

interface LocalProps extends Omit<ReactSelectProps, 'value'> {
  value?: OperatorGroup;
  filterOptions?: (operators: OperatorGroup[]) => OperatorGroup[];
}

const OperatorGroupSearch = ({ value, filterOptions, ...props }: LocalProps) => {
  const [ allOperatorGroups ] = useAllOperatorGroups();

  const options = useMemo(() => {
    if (!filterOptions) {
      return allOperatorGroups;
    }

    return filterOptions(allOperatorGroups);
  }, [ filterOptions, allOperatorGroups ]);

  return (
    <Select
      placeholder="Select a Operator Group..."
      isClearable
      menuPortalTarget={document.body}
      {...props}
      value={value || null}
      getOptionLabel={(option: OperatorGroup) => option.name}
      getOptionValue={(option: OperatorGroup) => option.operatorGroupId as any}
      options={options as any[]}
    />
  );
};

export default OperatorGroupSearch;
