import React, { ReactNode, useState } from 'react';

import { OperatorAuditModel } from 'models';
import ActionType from 'constants/ActionType';

import ModifyTaskModal from './ModifyTaskModal';

interface ProvidedProps {
  showCreateTaskModal: () => void;
  showCreateParentTaskModal: () => void;
  hideModal: () => void;
}

interface Props {
  operatorAudit: OperatorAuditModel;
  startDate?: string;
  children?: (providedProps: ProvidedProps) => ReactNode;
}

enum ShowingTaskModalType {
  NewTask,
  NewParentTask
}

const WithModifyTaskModal = ({ operatorAudit, startDate, children }: Props) => {
  const [ showingTaskModalType, setShowingTaskModalType ] = useState<ShowingTaskModalType | undefined>();

  function showCreateTaskModal() {
    setShowingTaskModalType(ShowingTaskModalType.NewTask);
  }

  function showCreateParentTaskModal() {
    setShowingTaskModalType(ShowingTaskModalType.NewParentTask);
  }

  function hideModal() {
    setShowingTaskModalType(undefined);
  }

  return (
    <>
      {children?.({
        showCreateTaskModal,
        showCreateParentTaskModal,
        hideModal,
      })}

      <ModifyTaskModal
        show={showingTaskModalType === ShowingTaskModalType.NewTask}
        operatorAudit={operatorAudit}
        task={{ taskId: 0, taskDate: startDate }}
        hide={hideModal}
      />

      <ModifyTaskModal
        show={showingTaskModalType === ShowingTaskModalType.NewParentTask}
        operatorAudit={operatorAudit}
        task={{ taskId: 0, taskDate: startDate, isParent: true, actionTypeId: ActionType.Check }}
        hide={hideModal}
      />
    </>
  );
};

export default WithModifyTaskModal;
