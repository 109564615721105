import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '../reducers';
import { getStringMappedEntities } from '../../helpers/entityHelpers';
import { getNextPageRequest } from '../../helpers/pagination';
import { PaginatedRequest } from 'core';

const getCurrentUserId = (state: RootState): string => state.site.user!.userId;
export const getUserNotificationCount = (state: RootState) => state.userNotifications.entities.userNotificationCount;
export const getOperatorMessageUserNotificationCount = (state: RootState) => state.userNotifications.entities.operatorMessageUserNotificationCount;
export const getUserNotifications = (state: RootState) => state.userNotifications.entities.userNotifications;
export const userNotificationIds = (state: RootState) => state.userNotifications.ui.userNotificationIds;
export const getUserNotificationPagination = (state: RootState) => state.userNotifications.ui.userNotificationPagination;

export const getCurrentUserNotificationCount = createSelector(
  [
    getUserNotificationCount,
    getCurrentUserId,
  ],
  (
    userNotificationCount,
    currentUserId
  ) => {
    if (!currentUserId || !_.has(userNotificationCount, currentUserId)) {
      return undefined;
    }

    return userNotificationCount[currentUserId];
  }
);

export const getCurrentUserOperatorMessageNotificationCount = createSelector(
  [
    getOperatorMessageUserNotificationCount,
    getCurrentUserId,
  ],
  (
    operatorMessageUserNotificationCount,
    currentUserId
  ) => {
    if (!currentUserId || !_.has(operatorMessageUserNotificationCount, currentUserId)) {
      return undefined;
    }

    return operatorMessageUserNotificationCount[currentUserId];
  }
);

export const getCurrentUserNotifications = createSelector(
  [
    getUserNotifications,
    userNotificationIds,
    getCurrentUserId,
  ],
  (
    userNotifications,
    userNotificationIds,
    currentUserId
  ) => {
    if (!currentUserId) {
      return [];
    }

    return getStringMappedEntities(userNotifications, userNotificationIds[currentUserId]);
  }
);

export const getNextUserNotificationPageRequest = createSelector(
  [
    getUserNotificationPagination,
    getCurrentUserId,
  ],
  (
    userNotificationPagination,
    currentUserId
  ): PaginatedRequest | undefined => {
    if (!currentUserId) {
      return undefined;
    }

    const currentPagination = userNotificationPagination[currentUserId];

    return getNextPageRequest(currentPagination);
  }
);
