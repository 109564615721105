import React, { useState } from 'react';

import { PaginatedSearchContainer, TermSearchRequest } from 'core';
import { RootState } from 'Modules/reducers';

import usePaginatedSearch, {
  DispatchableAction,
  PaginatedSearchProvidedProps,
  UsePaginatedSearchProps,
} from './usePaginatedSearch';
import useOnPrevious from './useOnPrevious';

export interface PaginatedTermSearchProvidedProps<T, TPaginatedRequest extends TermSearchRequest = TermSearchRequest> extends PaginatedSearchProvidedProps<T, TPaginatedRequest> {
  filterText: string;
  setFilterText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearFilterText: () => void;
}

export interface UsePaginatedTermSearchProps extends UsePaginatedSearchProps {
  defaultFilterText?: string;
}

interface ScopeProps<TEntity, TPaginatedRequest extends TermSearchRequest = TermSearchRequest> {
  selectors: {
    pagination: (state: RootState) => PaginatedSearchContainer<TPaginatedRequest>;
    entities: (state: RootState) => { [id: number]: TEntity };
    isSearching: (state: RootState) => boolean;
  };
  handlers: {
    fetchPage: (request: TPaginatedRequest & TermSearchRequest) => DispatchableAction | void;
  }
}

const usePaginatedTermSearch = <TEntity extends any, TPaginatedRequest extends TermSearchRequest = TermSearchRequest>({ selectors, handlers }: ScopeProps<TEntity, TPaginatedRequest>) => (usePaginatedSearchProps: UsePaginatedTermSearchProps = {}): PaginatedTermSearchProvidedProps<TEntity, TPaginatedRequest> => {
  const [ filterText, setFilterText ] = useState(usePaginatedSearchProps.defaultFilterText || '');
  const paginatedSearch = usePaginatedSearch<TEntity, TPaginatedRequest>({
    selectors,
    handlers: {
      ...handlers,
      fetchPage: searchForData,
    },
  })(usePaginatedSearchProps);

  useOnPrevious(filterText, (previous, current) => (previous || '') !== (current || ''))
    .then(() => {
      paginatedSearch.setOffset(0);
    });

  function searchForData(paginatedRequest: TPaginatedRequest) {
    return handlers.fetchPage({
      ...paginatedRequest,
      filter: filterText,
    });
  }

  function clearFilterText() {
    setFilterText('');
  }

  function handleFilterTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterText(e.target.value);
  }

  return {
    ...paginatedSearch,
    filterText,
    setFilterText: handleFilterTextChange,
    clearFilterText,
  };
};

export default usePaginatedTermSearch;
