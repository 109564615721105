import { referenceMaterialsSlice } from './slice';
import * as referenceMaterialsApiActions from './apiActions';

const referenceMaterialsActions = {
  ...referenceMaterialsSlice.actions,
  async: {
    ...referenceMaterialsApiActions,
  },
};

export default referenceMaterialsActions;
