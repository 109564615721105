import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';

import { useOperatorAttributeTypes } from 'hooks';
import { DomClass, ListPanel, ListPanelHeader, NavMenuButton, Panel, SplitPanel } from 'Components';

import CreateOperatorAttributeTypePanel from './CreateOperatorAttributeTypePanel';
import ModifyOperatorAttributeTypePanel from './ModifyOperatorAttributeTypePanel';


const OperatorAttributeTypeMaintenancePage = () => {
  const [ operatorAttributeTypes, isLoading ] = useOperatorAttributeTypes();
  return (
    <>
      <Helmet>
        <title>Operator Attribute Type Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 2,
            Component: (
              <>
                <ListPanel
                  Header={(
                    <ListPanelHeader>Operator Attributes ({_.size(operatorAttributeTypes)})</ListPanelHeader>
                  )}
                  isLoading={isLoading}
                  Tools={(
                    <Button tag={Link} to="/admin/operatorAttributeTypes/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                >
                  <div className="p-1">
                    {
                      _.map(operatorAttributeTypes, (operatorAttributeType) => {
                        return (
                          <NavMenuButton key={operatorAttributeType.id} to={`/admin/operatorAttributeTypes/${operatorAttributeType.id}`}>
                            {operatorAttributeType.name}
                          </NavMenuButton>
                        );
                      })
                    }
                  </div>
                </ListPanel>
              </>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/operatorAttributeTypes/create" component={CreateOperatorAttributeTypePanel} />
                <Route exact path="/admin/operatorAttributeTypes/:operatorAttributeTypeId([0-9]+)" component={ModifyOperatorAttributeTypePanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default OperatorAttributeTypeMaintenancePage;
