import _ from 'lodash';

export const isIntegerInputValid = (value: string) => {
  return !_.trim(value) || /^(\d+)$/.test(value);
};

export const isDecimalInputValid = (value: string) => {
  return !_.trim(value) || /^(\d+\.?\d*|\.\d+)$/.test(value);
};

export const isInteger = (value: string) => {
  return !_.trim(value) || /^\d+$/.test(value);
};

export const isDecimal = (value: string) => {
  return !_.trim(value) || /^(\d*\.)?\d+$/.test(value);
};

export const getOnCancelForPartialUpdates = <T extends any>(workingUpdates: Partial<T>, cancelChanges: () => void) => {
  if (_.size(workingUpdates) > 0) {
    return () => {
      cancelChanges();
    };
  }

  return undefined;
};
