import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Toggle from 'react-toggle';
import ReactSelect from 'react-select';

import { makeFormHelper } from 'core';
import { ModifyPoll, PollResultDisplayType } from 'Modules/polls';
import { StringDatePicker } from 'Components';
import PollCategorySearch from 'Components/Searches/PollCategorySearch';

interface Props {
  isCreatingNewPoll?: boolean;
  poll: ModifyPoll;
  setValues: (values: Partial<ModifyPoll>) => void;
}
const options = [
  { label: 'Pie Chart', value: PollResultDisplayType.PieChart },
  { label: 'Horizontal Bar Graph', value: PollResultDisplayType.HorizontalBarGraph },
];

const PollForm = ({ poll, isCreatingNewPoll, ...props }: Props) => {
  const canGloballyEdit = true; // todo permissions useHasPermission(Permission.PollsEdit);

  function setWorkingPollValues(values: Partial<ModifyPoll>) {
    props.setValues(values);
  }

  const canEdit = isCreatingNewPoll || canGloballyEdit;
  const { getInputProps } = makeFormHelper(poll, setWorkingPollValues);
  const selectedDisplayType = options.find(o => o.value === poll.defaultResultDisplayType);

  return (
    <>
      <FormGroup>
        <Label>Question</Label>
        <Input placeholder="Question..." disabled={!canEdit} {...getInputProps('name')} />
      </FormGroup>

      <FormGroup>
        <Label>Start Date</Label>
        <div>
          <StringDatePicker
            value={poll.startDate}
            onChange={(date) => setWorkingPollValues({ startDate: date })}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>End Date</Label>
        <div>
          <StringDatePicker
            value={poll.endDate}
            onChange={(date) => setWorkingPollValues({ endDate: date })}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>Poll Type</Label>
        <div>
          <ReactSelect<any, false>
            value={selectedDisplayType || null}
            menuPortalTarget={document.body}
            onChange={({ value }) => setWorkingPollValues({ defaultResultDisplayType: value || undefined })}
            options={options}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>Poll Category</Label>
        <div>
          <PollCategorySearch
            isClearable
            value={poll.category}
            menuPortalTarget={document.body}
            onChange={(category) => setWorkingPollValues({ category: category || undefined })}
            options={options}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>Allow Multiple Answers</Label>
        <div>
          <Toggle
            checked={poll.allowMultipleAnswers}
            onChange={(e) => setWorkingPollValues({ allowMultipleAnswers: e.target.checked })}
          />
        </div>
      </FormGroup>
    </>
  );
};

export default PollForm;
