import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import ReactSelect, { InputActionMeta, Props as ReactSelectProps } from 'react-select';
import { ActionMeta } from 'react-select/src/types';

export interface CommonSearchProps<T> extends Omit<ReactSelectProps, 'onChange'> {
  value?: T | T[] | null;
  onChange?: (option: T | null, action: ActionMeta<any>) => void;
  onMultiChange?: (option: T[], action: ActionMeta<any>) => void;
}

interface LocalProps<T> extends CommonSearchProps<T> {
  minInputLength?: number;
  debounceWait?: number;
  searchOnMount?: boolean;
  searchOnAdditionalParamsChange?: boolean;
  searchOnFocus?: boolean;
  modal?: boolean;
  additionalParams?: any;
  searchForData: (term: string) => Promise<T[]>;
}

const CommonSearch = <T extends any>({ minInputLength, debounceWait, searchForData, searchOnMount, searchOnAdditionalParamsChange, modal, additionalParams, searchOnFocus, ...props }: LocalProps<T>) => {
  const [ isSearchingForOptions, setIsSearchingForOptions ] = useState(false);
  const [ options, setOptions ] = useState<T[]>([]);

  useEffect(() => {
    if(searchOnAdditionalParamsChange) {
      searchForOptions('');
    }
  }, [ additionalParams ]);

  useEffect(() => {
    if(searchOnMount) {
      searchForOptions('');
    }
  }, []);

  const debouncedHandleInputChange = useMemo(() => _.debounce(handleInputChange, debounceWait), [ debounceWait ]);

  function handleInputChange(newValue: string, actionMeta: InputActionMeta) {
    if (minInputLength === undefined) {
      return;
    }

    if (actionMeta.action !== 'input-change') {
      return;
    }

    const trimmedSearchTerm = _.trim(newValue);

    if (_.size(trimmedSearchTerm) < minInputLength) {
      return;
    }

    searchForOptions(newValue);
  }

  function handleOnFocus(event: React.FocusEvent<HTMLElement>) {
    if(props.onFocus) {
      props.onFocus(event);
    }

    if(searchOnFocus) {
      searchForOptions('');
    }
  }

  function searchForOptions (term: string) {
    (async () => {
      try {
        setIsSearchingForOptions(true);
        const page = await searchForData(term);

        setOptions(page || []);
      } finally {
        setIsSearchingForOptions(false);
      }
    })();
  }

  function handleOnChange(value: T | T[] | null, action: ActionMeta<any>) {
    if(props.onChange && !_.isArray(value)) {
      props.onChange(value, action);
    }

    if(props.onMultiChange && props.isMulti) {
      if(!value) {
        props.onMultiChange([], action);
      }

      props.onMultiChange(value as T[], action);
    }
  }

  return (
    <ReactSelect
      menuPortalTarget={document.body}
      styles={modal ? { menuPortal: (base: any) => ({ ...base, zIndex: 3051 }) } : undefined}
      {...props}
      isLoading={props.isLoading || isSearchingForOptions}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      options={options as any[]}
      onInputChange={debouncedHandleInputChange} />
  );
};

export default CommonSearch;
