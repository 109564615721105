import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';

export const getStandards = (state: RootState) => state.standards.entities.standards;
export const getStandardReferences = (state: RootState) => state.standards.entities.standardReferences;
export const getStandardReferenceMappings = (state: RootState) => state.standards.mappings.standardReferences;
export const getAllStandardIds = (state: RootState) => state.standards.ui.allStandardIds;

export const selectAllStandards = createSelector(
  [ getStandards, getAllStandardIds ],
  (standards, allStandardIds) => {
    return getNumberMappedEntities(standards, allStandardIds);
  }
);

export const selectAllStandardReferencesByStandardId = createSelector(
  [ getStandardReferences, getStandardReferenceMappings, (state: RootState, standardId: number) => standardId ],
  (standardReferences, standardReferenceMappings, standardId) => {
    return getNumberMappedEntities(standardReferences, standardReferenceMappings[standardId]);
  }
);
