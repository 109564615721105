import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import _ from 'lodash';
import toastr from 'toastr';

import FlexRow from '../../../Components/FlexRow';
import { uploadOperatorLibraryFiles } from '../../../Modules/library/actions';
import { pluralCoalesce, sOrNoS } from '../../../helpers/listHelpers';
import { shouldModalUpdate } from '../../../helpers/modalHelpers';
import ModalHeaderWithClose from '../../../Components/ModalHeaderWithClose';
import AutoUploadFileDropzone from '../../../Components/AutoUploadFileDropzone';
import { RootState } from '../../../Modules/reducers';

export interface UserDefinableTaskModalProps {
}

interface LocalProps extends UserDefinableTaskModalProps{
  show: boolean;
  directoryPath: string;
  operatorId: number;
  hide: () => void;
}

interface StateProps {
  isUploading: boolean;
}

interface DispatchProps {
  uploadOperatorLibraryFiles: typeof uploadOperatorLibraryFiles;
}

type Props = LocalProps & StateProps & DispatchProps;

class UploadToFolderModal extends React.Component<Props> {
  public shouldComponentUpdate = shouldModalUpdate(this);

  private uploadTaskFiles = (selectedFiles: File[]) => {
    const { operatorId, directoryPath } = this.props;
    return new Promise<void>(resolve => {
      if (_.isEmpty(selectedFiles)) {
        toastr.error('You must select a file to upload.');
        return;
      }

      this.props.uploadOperatorLibraryFiles(operatorId, directoryPath, selectedFiles, () => {
        resolve();
        (async () => {
          await Swal.fire({
            title: 'File Uploaded',
            text: `Your file${sOrNoS(selectedFiles)} ${pluralCoalesce(selectedFiles, 'has', 'have')} been successfully uploaded. You can continue to upload files if you'd like or close this dialog.`,
            icon: 'success',
            confirmButtonText: 'Okay',
          });

          this.props.hide();
        })();
      });
    });
  };

  public render () {
    const { show, isUploading } = this.props;

    return (
      <>
        <Modal isOpen={show} toggle={this.props.hide} size="lg">
          <ModalHeaderWithClose hide={this.props.hide}>
            Upload Files to Folder
          </ModalHeaderWithClose>
          <ModalBody>
            <Row>
              <Col md={12}>
                <AutoUploadFileDropzone isUploading={isUploading} uploadFiles={this.uploadTaskFiles} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FlexRow fill justifyBetween alignCenter>
              <div>
                <Button color="light" onClick={this.props.hide}>Close</Button>
              </div>
              <div />
            </FlexRow>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isUploading: state.library.loading.isUploadingLibraryFiles,
  };
};

const mapDispatchToProps = {
  uploadOperatorLibraryFiles,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(UploadToFolderModal);
