import React, { CSSProperties } from 'react';
import Select from 'react-select';
import { Props as ReactSelectProps } from 'react-select/src/Select';
import { UserModel } from 'models';

interface Props extends Omit<ReactSelectProps<UserModel, true>, 'value'> {
  value: UserModel[];
  users: UserModel[];
  onChange: (users: readonly UserModel[]) => void;
}

const selectStyles = {
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 3000 }),
};

const UserSearch = ({ value, users, ...props }: Props) => {
  return (
    <Select<UserModel, true>
      {...props}
      isMulti
      isClearable
      placeholder="Select Users..."
      menuPortalTarget={document.body}
      value={value}
      getOptionLabel={(option: UserModel) => option.displayName}
      getOptionValue={(option: UserModel) => option.userId}
      options={users as UserModel[]}
      styles={selectStyles as any}
      onChange={(users) => props.onChange(users || [])}
    />
  );
};

export default UserSearch;
