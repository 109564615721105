import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import { useSelectedOperatorOrThrow, useThunkDispatch } from 'hooks';
import { Poll, PollOption, PollResultSummary, pollsActions } from 'Modules/polls';
import { FlexLoader } from 'Components';
import useLoggedInUserOrThrow from 'hooks/useLoggedInUserOrThrow';

interface Props {
  poll: Poll;
  options: PollOption[];
  pollResults: PollResultSummary[];
  viewResults: () => void;
}

const OperatorPollCardQuery = ({ poll, pollResults, options, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const loggedInUser = useLoggedInUserOrThrow();
  const selectedOperator = useSelectedOperatorOrThrow();
  const currentOperatorResults = pollResults.filter(pr => pr.operatorId === selectedOperator.operatorId);

  const [ selectedOptionIds, _setSelectedOptionIds ] = useState<number[]>(currentOperatorResults.map(r => r.optionId));
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const [ customOption, setCustomOption ] = useState<string>('');

  const validOptions = useMemo(() => {
    const predefined = options.filter(o => o.predefined || o.createdUser === loggedInUser.userId);
    const predefinedPollOptionIds = predefined.map(pr => pr.id);
    const resultPollOptionIds = _.uniq(pollResults.map(pr => pr.optionId));
    const optionIds = _.union(predefinedPollOptionIds, resultPollOptionIds);
    return _.sortBy(_.compact(optionIds.map(id => _.find(options, o => o.id === id))), o => o.name.toLowerCase());
  }, [ pollResults, options ]);

  function setSelectedOption(optionId: number){
    const isAlreadySelected = _.includes(selectedOptionIds, optionId);
    if(poll.allowMultipleAnswers) {
      if(isAlreadySelected) {
        _setSelectedOptionIds(_.without(selectedOptionIds, optionId));
      } else {
        _setSelectedOptionIds(_.union(selectedOptionIds, [ optionId ]));
      }
    } else {
      if(isAlreadySelected) {
        _setSelectedOptionIds([]);
      } else {
        _setSelectedOptionIds([ optionId ]);
      }
    }
  }

  async function handleAddCustomOption() {
    const cleanedCustomOption = _.trim(customOption);

    if (!cleanedCustomOption) {
      return;
    }

    const pollOption = await dispatch(pollsActions.async.insertPollOption(poll.id, {
      name: cleanedCustomOption,
    }));

    setSelectedOption(pollOption.id);
    setCustomOption('');
  }

  function submitResponse() {
    (async () => {
      try {
        setIsSubmitting(true);
        await dispatch(pollsActions.async.insertPollResult(poll.id, {
          pollOptionIds: selectedOptionIds,
        }));

        await dispatch(pollsActions.async.getAllPollResultSummaries(poll.id));
        props.viewResults();
      } finally {
        setIsSubmitting(false);
      }
    })();
  }

  if(isSubmitting) {
    return (
      <FlexLoader />
    );
  }

  return (
    <>
      {
        _.map(validOptions, (pollOption) => {
          const isChecked = _.includes(selectedOptionIds, pollOption.id);
          return (
            <FormGroup key={pollOption.id} check>
              <Label check>
                <Input type={poll.allowMultipleAnswers ? 'checkbox' : 'radio'} checked={isChecked} onChange={() => setSelectedOption(pollOption.id)}/>{' '} {pollOption.name}
              </Label>
            </FormGroup>
          );
        })
      }
      <FormGroup className="mt-1">
        <Label>
          Other (please specify)
        </Label>

        <InputGroup size="sm">
          <Input bsSize="sm" value={customOption} onChange={(e) => setCustomOption(e.target.value)} />
          <InputGroupAddon addonType="append">
            <Button color="dark" onClick={handleAddCustomOption}>Add</Button>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>

      <Button onClick={submitResponse} color="primary" className="mt-2" disabled={!selectedOptionIds.length}>
        Submit Response
      </Button>

      <div className="text-center">
        <Button color="anchor" onClick={props.viewResults}>
          View Results
        </Button>
      </div>
    </>
  );
};

export default OperatorPollCardQuery;
