import { CreateUserRequest } from './models';

export const getNewUser = (): CreateUserRequest => {
  return {
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
    accountEnabled: true,
    roles: [],
  };
};
