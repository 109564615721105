import React, { useState } from 'react';
import { matchSorter } from 'match-sorter';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Masonry from 'react-masonry-css';

import { LibraryFile } from 'models';
import * as libraryActions from 'Modules/library/actions';

import FileCard from './FileCard';

interface Props {
  isLoading: boolean;
  files: LibraryFile[];
}

const FileGrid = function ({ isLoading, files }: Props) {
  const dispatch = useDispatch();
  const [ filterText, setFilterText ] = useState<string>('');

  function getFilteredLibraryFiles(files: LibraryFile[], searchText: string) {
    return matchSorter(files, searchText, { keys: [ 'file.originalFilename' ] });
  }

  function downloadTaskFiles() {
    const filteredFiles = getFilteredLibraryFiles(files, filterText);

    dispatch(libraryActions.downloadLibraryFiles(_.map(filteredFiles, f => f.libraryFileId)));
  }

  if (_.isEmpty(files)) {
    return (
      <div className="pt-0 p-3 d-flex align-items-center justify-content-center">
        <h5>No Files Found</h5>
      </div>
    );
  }

  const filteredLibraryFiles = getFilteredLibraryFiles(files, filterText);

  return (
    <div>
      {isLoading && (
        <div className="row">
          <div className="col-md-12 loading-c">
            <i className="fa fa-spin fa-refresh"/>
          </div>
        </div>
      )}

      {!isLoading && (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="table-header">
                <div>
                  <button className="btn btn-primary" onClick={downloadTaskFiles}>Download All</button>
                </div>
                <div>
                  <input className="form-control" placeholder="Search..." value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}/>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <Masonry
                breakpointCols={{
                  default: 3,
                  500: 1,
                }} className="project-masonry" columnClassName="project-masonry-item"
              >
                {
                  _.map(filteredLibraryFiles, (libraryFile) => {
                    return (
                      <div key={libraryFile.libraryFileId} className="position-relative">
                        <FileCard libraryFile={libraryFile}/>
                      </div>
                    );
                  })
                }
              </Masonry>
            </div>
          </div>
        </>)}
    </div>
  );
};

export default FileGrid;
