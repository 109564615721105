import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { PaginatedRequest, PaginatedSearchContainer } from 'core';
import { RootState } from 'Modules/reducers';

export type DispatchableAction = Action | ThunkAction<any, RootState, any, any>;

export interface PaginatedSearchProvidedProps<T, TPaginatedRequest extends PaginatedRequest = PaginatedRequest> {
  data: T[];
  pagination: PaginatedSearchContainer<TPaginatedRequest>;
  isSearching: boolean;
  setOffset: (offset: number) => void;
  changePageSize: (pageSize: number) => void;
  loadMore: () => void;
  refresh: () => void;
  hasMore: () => boolean;
  searchForData: (request: TPaginatedRequest) => void;
}

interface ScopeProps<TEntity, TPaginatedRequest extends PaginatedRequest = PaginatedRequest> {
  selectors: {
    pagination: (state: RootState) => PaginatedSearchContainer<TPaginatedRequest>;
    entities: (state: RootState) => { [id: number]: TEntity };
    isSearching: (state: RootState) => boolean;
  };
  handlers: {
    fetchPage: (request: TPaginatedRequest) => DispatchableAction | void;
  }
}

export interface UsePaginatedSearchProps {
  searchOnMount?: boolean;
}

const usePaginatedSearch = <TEntity extends any, TPaginatedRequest extends PaginatedRequest = PaginatedRequest>({ selectors, handlers }: ScopeProps<TEntity, TPaginatedRequest>) => ({ searchOnMount }: UsePaginatedSearchProps = {}): PaginatedSearchProvidedProps<TEntity, TPaginatedRequest> => {
  const dispatch = useDispatch();
  const pagination = useSelector(selectors.pagination);
  const entities = useSelector(selectors.entities);
  const isSearching = useSelector(selectors.isSearching);
  const currentPageData = useMemo(() => _.map(pagination.ids, (id) => entities[id]), [ entities, pagination ]);

  useEffect(() => {
    if (searchOnMount) {
      refresh();
    }
  }, []);

  function hasMore(): boolean {
    return _.size(currentPageData) < pagination.total;
  }

  function loadMore() {
    if (!hasMore()) {
      return;
    }

    searchForData({
      ...pagination.request,
      offset: pagination.request.offset + pagination.request.pageSize,
    });
  }

  function refresh() {
    searchForData(pagination.request);
  }

  function setOffset(offset: number) {
    searchForData({ ...pagination.request, offset });
  }

  function changePageSize(pageSize: number) {
    searchForData({ ...pagination.request, offset: 0, pageSize });
  }

  function searchForData(request: TPaginatedRequest) {
    const action = handlers.fetchPage(request);

    if (!action) {
      return;
    }

    dispatch(action);
  }

  return {
    data: currentPageData,
    pagination,
    isSearching,
    setOffset,
    changePageSize,
    loadMore,
    refresh,
    hasMore,
    searchForData,
  };
};

export default usePaginatedSearch;
