import { usePaginatedTermSearch } from 'hooks';
import { RootState } from '../reducers';
import pollsActions from './actions';
import { PollSearchRequest } from 'Modules/polls/models';

export const usePaginatedPolls = usePaginatedTermSearch({
  selectors: {
    pagination: (state: RootState) => state.polls.ui.pollsPagination,
    entities: (state: RootState) => state.polls.entities.polls,
    isSearching: (state: RootState) => state.polls.loading.isSearchingPolls,
  },
  handlers: {
    fetchPage: pollsActions.async.searchPolls,
  },
});

export const usePaginatedAllPollCategories = usePaginatedTermSearch({
  selectors: {
    pagination: (state: RootState) => state.polls.ui.pollCategoryPagination,
    entities: (state: RootState) => state.polls.entities.pollCategories,
    isSearching: (state: RootState) => state.polls.loading.isSearchingAllPollCategories,
  },
  handlers: {
    fetchPage: pollsActions.async.searchAllPollCategories,
  },
});

export const usePaginatedPollCategories = usePaginatedTermSearch({
  selectors: {
    pagination: (state: RootState) => state.polls.ui.pollCategoryPagination,
    entities: (state: RootState) => state.polls.entities.pollCategories,
    isSearching: (state: RootState) => state.polls.loading.isSearchingPollCategories,
  },
  handlers: {
    fetchPage: pollsActions.async.searchPollCategories,
  },
});

export const usePaginatedCurrentOperatorPolls = (searchRequest: Partial<PollSearchRequest> = {}) => usePaginatedTermSearch({
  selectors: {
    pagination: (state: RootState) => state.polls.ui.operatorPollPagination,
    entities: (state: RootState) => state.polls.entities.polls,
    isSearching: (state: RootState) => state.polls.loading.isSearchingOperatorPolls,
  },
  handlers: {
    fetchPage: (request) => pollsActions.async.searchCurrentOperatorsPolls({
      ...request,
      ...searchRequest,
    }),
  },
});
