import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import { AssignedTaskModel, OperatorAuditModel } from 'models';
import { changeModifyingTaskValues, deleteTask } from 'Modules/tasks/actions';
import { RootState } from 'Modules/reducers';
import { ActionTypeIcon, DisplayActionType } from 'constants/ActionType';
import { preventDefaultAnd } from 'helpers/eventHelpers';

import ModifyTaskModal from './ModifyTaskModal';
import DraftTextViewer from 'Components/Draft/Viewer/DraftTextViewer';
import classnames from 'classnames';

interface LocalProps {
  operatorAudit: OperatorAuditModel;
  task: AssignedTaskModel;
}

interface LocalState {
  isModifyingTask: boolean;
}

interface StateProps {
  isDeleting: boolean;
}

interface DispatchProps {
  deleteTask: typeof deleteTask;
  changeModifyingTaskValues: typeof changeModifyingTaskValues;
}

type Props = LocalProps & StateProps & DispatchProps;

class Task extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      isModifyingTask: false,
    };
  }

  deleteTask = async () => {
    const { task } = this.props;
    const { taskId } = task;

    const { dismiss } = await Swal.fire({
      title: 'Confirm',
      text: 'Are you sure you want to delete this task?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (dismiss) {
      return;
    }

    this.props.deleteTask(taskId);
  };

  private startEditingTask = () => {
    this.setState({ isModifyingTask: true });
  };

  private stopEditingTask = () => {
    this.setState({ isModifyingTask: false });
  };

  render () {
    const { isModifyingTask } = this.state;
    const { task, operatorAudit, isDeleting } = this.props;
    const { summary, description, actionTypeId } = task;

    return (
      <>
        <Card className={classnames({ 'bg-light': task.templateTaskId })}>
          <CardBody>
            <Row>
              <Col md={12} className="pb-1">
                <div className="float-left">
                  <strong title={task.templateTaskId ? 'This task is from a template' : ''}>{summary}</strong>
                </div>
              </Col>

              <Col md={12}>
                <p className="respect-white-space"><DraftTextViewer content={description} /></p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="float-left">
                  <i className={ActionTypeIcon[actionTypeId]} title={`${DisplayActionType[actionTypeId]} Action`} />
                </div>

                <div className="float-right space-children-x">
                  <>
                    <Button color="anchor" onClick={preventDefaultAnd(this.startEditingTask)}><i className="fa fa-pencil" /></Button>
                  </>
                  <>
                    {isDeleting && <Button color="anchor" className="text-danger"><i className="fa fa-spin fa-refresh" /></Button>}
                    {!isDeleting && <Button color="anchor" className="text-danger" onClick={preventDefaultAnd(this.deleteTask)}><i className="fa fa-trash" /></Button>}
                  </>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ModifyTaskModal
          show={isModifyingTask}
          operatorAudit={operatorAudit}
          task={task}
          hide={this.stopEditingTask}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState, { task }: LocalProps) => {
  return {
    isDeleting: _.has(state.tasks.loading.deletingTasks, task.taskId),
  };
};

const mapDispatchToProps = {
  deleteTask: deleteTask,
  changeModifyingTaskValues: changeModifyingTaskValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
