import React from 'react';
import classnames from 'classnames';
import { Button, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { RootState } from 'Modules/reducers';
import CollapsibleCardBox, { CollapsibleCardBoxRef } from 'Components/CollapsibleCardBox';
import { AuditTemplate, TemplateTaskModel } from 'models';

import TemplatesTable from './TemplatesTable';
import CreateTemplateModal from '../Admin/Tasks/CreateTemplateModal';
import TemplateWeeks from './TemplateWeeks';
import { getAllAuditTemplates, getAuditTemplateTasks, setSelectedAuditTemplate } from 'Modules/templates/actions';
import {
  getSelectedTemplateTaskModel,
  selectAllTemplates,
  selectSelectedTemplate,
} from '../../Modules/templates/selectors';
import PersistentTasks from './PersistentTasks';
import FlexLoaderPanel from '../../Components/FlexLoaderPanel';
import PageLayout from '../../App/PageLayout';
import Helmet from 'react-helmet';
import { getAvailableDirectories } from '../../Modules/library/actions';

enum SelectedTaskTab {
  Weekly = 1,
  Persistent = 2
}

interface LocalProps {}

interface LocalState {
  isShowingCreateModal: boolean;
  selectedTab: SelectedTaskTab;
}

interface StateProps {
  templates: AuditTemplate[];
  selectedTemplate?: AuditTemplate;
  templateTaskModel: TemplateTaskModel;
  isLoadingAllAuditTemplates: boolean;
  isLoadingAuditTemplateTasks: boolean;
}

interface DispatchProps {
  setSelectedAuditTemplate: typeof setSelectedAuditTemplate;
  getAllAuditTemplates: typeof getAllAuditTemplates;
  getAuditTemplateTasks: typeof getAuditTemplateTasks;
  getAvailableDirectories: typeof getAvailableDirectories;
}

type Props = LocalProps & StateProps & DispatchProps;

class TemplatesPage extends React.Component<Props, LocalState> {
  private templateTableRef: CollapsibleCardBoxRef | null = null;

  constructor (props: Props) {
    super(props);

    this.state = {
      isShowingCreateModal: false,
      selectedTab: SelectedTaskTab.Weekly,
    };
  }

  public componentDidMount (): void {
    this.props.getAllAuditTemplates();
    this.props.getAvailableDirectories();
  }

  private showCreateModal = () => {
    this.setState({ isShowingCreateModal: true });
  };

  private hideCreateModal = () => {
    this.setState({ isShowingCreateModal: false });
  };

  private selectTemplate = (template: AuditTemplate, selected: boolean) => {
    this.props.setSelectedAuditTemplate(selected ? template.auditTemplateId : undefined);

    if (selected) {
      this.props.getAuditTemplateTasks(template.auditTemplateId);

      if (this.templateTableRef) {
        this.templateTableRef.setIsOpen(false);
      }
    }
  };

  private setTemplateTableRef = (c: CollapsibleCardBoxRef) => {
    this.templateTableRef = c;
  }

  public render () {
    const { isShowingCreateModal, selectedTab } = this.state;
    const { templates, selectedTemplate, templateTaskModel, isLoadingAllAuditTemplates, isLoadingAuditTemplateTasks } = this.props;

    return (
      <>
        <Helmet>
          <title>Admin - Templates</title>
        </Helmet>

        <PageLayout pageTitle="Task Card Template Maintenance" breadcrumbs={[ 'Administration', 'Task Card Templates' ]}>
          <FlexLoaderPanel loadingClassName="p-5" isLoading={isLoadingAllAuditTemplates}>

            <CollapsibleCardBox ref={this.setTemplateTableRef} Header="Task Card Templates" defaultOpen>
              <Row className="mt-1">
                <Col md={12}>
                  <Button color="primary" onClick={this.showCreateModal}>Add Task Card Template...</Button>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={12}>
                  <TemplatesTable
                    templates={templates}
                    selectedTemplateId={selectedTemplate?.auditTemplateId}
                    selectTemplate={this.selectTemplate}
                  />
                </Col>
              </Row>
            </CollapsibleCardBox>

            {(selectedTemplate) && (
              <FlexLoaderPanel loadingClassName="p-5" isLoading={isLoadingAuditTemplateTasks}>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <Nav pills>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: selectedTab === SelectedTaskTab.Weekly })}
                            onClick={() => this.setState({ selectedTab: SelectedTaskTab.Weekly })}
                          >
                            Weekly Tasks ({templateTaskModel.numWeeklyTasks})
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: selectedTab === SelectedTaskTab.Persistent })}
                            onClick={() => this.setState({ selectedTab: SelectedTaskTab.Persistent })}
                          >
                            Persistent Tasks ({templateTaskModel.numPersistentTasks})
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <div />
                  </div>

                  <Row className="mt-2">
                    <Col md={12}>
                      {selectedTab === SelectedTaskTab.Weekly && (
                        <TemplateWeeks template={selectedTemplate} templateTasks={templateTaskModel} />
                      )}
                      {selectedTab === SelectedTaskTab.Persistent && (
                        <PersistentTasks
                          template={selectedTemplate}
                          templateTasks={templateTaskModel}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </FlexLoaderPanel>
            )}
            <CreateTemplateModal show={isShowingCreateModal} hide={this.hideCreateModal} />
          </FlexLoaderPanel>
        </PageLayout>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    templates: selectAllTemplates(state),
    selectedTemplate: selectSelectedTemplate(state),
    templateTaskModel: getSelectedTemplateTaskModel(state),
    isLoadingAllAuditTemplates: state.templates.loading.isLoadingAllAuditTemplates,
    isLoadingAuditTemplateTasks: state.templates.loading.isLoadingAuditTemplateTasks,
  };
};

const mapDispatchToProps = {
  setSelectedAuditTemplate,
  getAllAuditTemplates,
  getAuditTemplateTasks,
  getAvailableDirectories,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplatesPage);
