import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '../reducers';
import { ChatMessage } from '../../Components/Chat/models';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';
import { PaginatedRequest } from '../../core';

export const getOperatorMessagePagination = (state: RootState) => state.peerOperators.ui.searchedPeerMessagesPagination;
export const getOperatorMessages = (state: RootState) => state.peerOperators.entities.operatorToOperatorMessages;

export const getSelectedRecipientChatMessages = (peerOperatorId: number) => createSelector(
  [
    getOperatorMessages,
    getOperatorMessagePagination,
  ],
  (
    operatorMessages,
    pagination
  ): ChatMessage[] => {
    if(!pagination[peerOperatorId]) {
      return [];
    }

    const messages = getNumberMappedEntities(operatorMessages, pagination[peerOperatorId].ids);

    return _.uniqBy(_.reverse(_.map(messages, (message): ChatMessage => ({
      id: message.id,
      senderName: message.fromOperatorCodeName,
      text: message.messageText,
      createdTimestamp: message.createdTimestamp,
      fromMe: message.isFromMe,
    }))), m => m.id);
  }
);

export const getNextChatMessagePageRequest = (peerOperatorId: number) => createSelector(
  [
    getOperatorMessagePagination,
  ],
  (
    operatorMessagePagination
  ): PaginatedRequest | undefined => {
    const pagination = operatorMessagePagination[peerOperatorId];

    if(!pagination) {
      return undefined;
    }

    if (pagination.request.offset + pagination.request.pageSize >= pagination.total) {
      return undefined;
    }

    return {
      pageSize: pagination.request.pageSize,
      offset: pagination.request.offset + pagination.request.pageSize,
    };
  }
);
