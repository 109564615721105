import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import PageLayout from 'App/PageLayout';
import { useCurrentUserPermissionChecker, useModalState, useStateSelector, useThunkDispatch } from 'hooks';
import { referenceMaterialsActions, referenceMaterialsSelectors } from 'Modules/referenceMaterials';
import { ReferenceMaterial } from 'Modules/referenceMaterials/models';
import { TreeNode } from 'Modules/library/models';
import { FileTreeBreadcrumbs, FlexLoader, FlexLoaderPanel, Sortable } from 'Components';

import ReferenceMaterialFolder from './ReferenceMaterialFolder';
import ReferenceFileTile from './ReferenceFileTile';
import CreateReferenceMaterialModal from './CreateReferenceMaterialModal';
import { Permission } from '../../Modules/roles/index';

const ReferenceMaterialsPage = () => {
  const dispatch = useThunkDispatch();
  const currentUserHasPermission = useCurrentUserPermissionChecker();
  const [ currentFilePath, setCurrentFilePath ] = useState<string>('/');
  const { isShowing: isShowingCreateNewModal, show: showCreateNewModal, hide: hideCreateNewModal } = useModalState();
  const referenceMaterialPositions = useStateSelector(state => state.referenceMaterials.ui.referenceMaterialPositions);
  const isLoadingReferenceMaterials = useStateSelector(state => state.referenceMaterials.loading.isLoadingReferenceMaterials);
  const isLoadingReferenceMaterialPositions = useStateSelector(state => state.referenceMaterials.loading.isLoadingReferenceMaterialPositions);
  const referenceFileTree = useSelector(referenceMaterialsSelectors.selectReferenceFileTree);
  const selectedReferenceMaterials = useSelector(referenceMaterialsSelectors.selectedReferenceMaterials);
  const currentNode = referenceFileTree.getNodeByPath(currentFilePath);

  const selectedReferenceMaterialHash = useMemo(() => _.keyBy(selectedReferenceMaterials, r => r.referenceFileId), [ selectedReferenceMaterials ]);

  useEffect(() => {
    dispatch(referenceMaterialsActions.async.getAllReferenceMaterials());
    dispatch(referenceMaterialsActions.async.getAllReferenceMaterialPositions());
  }, []);

  function getFilteredNodes() {
    return _.orderBy(currentNode?.children || [], [ c => {
      const explicitPosition = referenceMaterialPositions[c.filePath];

      if(!_.isNil(explicitPosition)) {
        return explicitPosition;
      }

      return c.isFolder ? Number.MIN_VALUE : Number.MIN_VALUE + 1;
    }, c => c.name ]);
  }

  function addNewFolder(folderPath: string) {
    dispatch(referenceMaterialsActions.addCreatedFolder(folderPath));
    setCurrentFilePath(folderPath);
  }

  function selectAll(referenceMaterials: ReferenceMaterial[]) {
    dispatch(referenceMaterialsActions.setSelectedReferenceMaterials(referenceMaterials.map(r => r.referenceFileId)));
  }

  function toggleSelection(referenceMaterial: ReferenceMaterial) {
    const isSelected = _.has(selectedReferenceMaterialHash, referenceMaterial.referenceFileId);
    if(isSelected) {
      dispatch(referenceMaterialsActions.setSelectedReferenceMaterials(selectedReferenceMaterials.filter(r => r.referenceFileId !== referenceMaterial.referenceFileId).map(r => r.referenceFileId)));
    } else {
      dispatch(referenceMaterialsActions.setSelectedReferenceMaterials(selectedReferenceMaterials.map(r => r.referenceFileId).concat(referenceMaterial.referenceFileId)));
    }
  }

  function selectNone() {
    dispatch(referenceMaterialsActions.setSelectedReferenceMaterials([]));
  }

  function moveReferenceMaterials(directoryPath: string, referenceMaterials: ReferenceMaterial[]) {
    return dispatch(referenceMaterialsActions.async.moveReferenceMaterials(directoryPath, referenceMaterials.map(r => r.referenceFileId)))
      .then(() => {
        selectNone();
      });
  }

  function updatePositions(referenceMaterials: TreeNode<ReferenceMaterial>[]) {
    dispatch(referenceMaterialsActions.async.updateReferenceFilesPositions(referenceMaterials));
  }

  if(isLoadingReferenceMaterials || isLoadingReferenceMaterialPositions) {
    return (
      <FlexLoader />
    );
  }

  if(!currentNode) {
    return null;
  }

  const currentNodes = getFilteredNodes();

  return (
    <PageLayout pageTitle="Reference Materials" breadcrumbs={[ 'Administration', 'Reference Materials' ]}>
      <Row>
        <Col>
          <FileTreeBreadcrumbs<ReferenceMaterial>
            currentNode={currentNode}
            addFolder={addNewFolder}
            selectAll={selectAll}
            selectNone={selectNone}
            selectedFiles={selectedReferenceMaterials}
            setCurrentFilePath={(filePath) => setCurrentFilePath(filePath)}
            permissions={{
              canCreateFolder: () => currentUserHasPermission(Permission.ReferenceMaterialMaintenance),
            }}
            accessors={{
              filePath: f => f.filePath,
              originalFilename: f => f.file.originalFilename,
              name: f => f.name,
            }}
            upload={{
              startUploading: showCreateNewModal,
            }}
            moveFiles={moveReferenceMaterials}
          />
        </Col>
      </Row>

      <FlexLoaderPanel loadingClassName="p-5" isLoading={false}>
        <Sortable
          className="row mt-2"
          items={currentNodes}
          idAccessor={r => r.filePath}
          onOrderChange={updatePositions}
        >
          {
            _.map(currentNodes, (fileTreeNode) => {
              return (
                <Col key={fileTreeNode.filePath} xs={12} lg={4} xl={3} className="mb-3">
                  {fileTreeNode.isFolder && (
                    <ReferenceMaterialFolder
                      folder={fileTreeNode}
                      onFolderSelect={(node) => setCurrentFilePath(node.filePath)}
                    />
                  )}

                  {!fileTreeNode.isFolder && fileTreeNode.data && (
                    <ReferenceFileTile
                      referenceMaterial={fileTreeNode.data}
                      isSelected={_.has(selectedReferenceMaterialHash, fileTreeNode.data.referenceFileId)}
                      toggleSelection={toggleSelection}
                    />
                  )}
                </Col>
              );
            })
          }
        </Sortable>
      </FlexLoaderPanel>

      <CreateReferenceMaterialModal
        show={isShowingCreateNewModal}
        currentFilePath={currentFilePath}
        hide={hideCreateNewModal}
      />
    </PageLayout>
  );
};

export default ReferenceMaterialsPage;
