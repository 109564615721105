import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Input } from 'reactstrap';
import FlexColumn from '../Components/FlexColumn';
import { useDispatch } from 'react-redux';
import useStateSelector from '../hooks/useStateSelector';
import { peerOperatorActions } from '../Modules/peerOperators/index';
import SafetyCoachChatFeed from './SafetyCoachChatFeed';
import PeerOperatorChatFeed from './PeerOperatorChatFeed';

interface Props {
  closeSidebar: () => void;
}

const ChatSidebar = function ({ ...props }: Props) {
  const dispatch = useDispatch();
  const [ selectedPeerOperatorId, setSelectedPeerOperatorId ] = useState<number>();
  const currentUserOperatorGroupPeers = useStateSelector(state => state.peerOperators.ui.currentUserOperatorGroupPeers);
  const selectedPeerOperator = _.find(currentUserOperatorGroupPeers, { operatorPeerId: selectedPeerOperatorId });

  useEffect(() => {
    dispatch(peerOperatorActions.async.getCurrentUserPeerOperators());
  }, []);

  return (
    <FlexColumn className="right-bar">
      <div className="rightbar-title">
        <Button color="anchor" className="right-bar-toggle float-right" onClick={props.closeSidebar}>
          <i className="mdi mdi-close"/>
        </Button>
        <h4 className="m-0 text-white">Chat</h4>
      </div>

      <div className="p-1">
        <Input
          type="select"
          value={selectedPeerOperatorId || -1}
          onChange={(e) => setSelectedPeerOperatorId(parseInt(e.target.value))}
        >
          <option value="-1">Chat with Safety Coach</option>
          {
            _.map(currentUserOperatorGroupPeers, (peerOperator) => {
              return (
                <option key={peerOperator.operatorPeerId} value={peerOperator.operatorPeerId}>
                  {peerOperator.operatorPeerCodeName}
                </option>
              );
            })
          }
        </Input>
      </div>
      <FlexColumn overflowHidden fill>
        {selectedPeerOperator ? (
          <PeerOperatorChatFeed selectedPeerOperator={selectedPeerOperator} />
        ) : (
          <SafetyCoachChatFeed />
        )}
      </FlexColumn>
    </FlexColumn>
  );
};

export default ChatSidebar;
