import React from 'react';
import { FileDetails } from 'Modules/library/models';

interface LocalProps {
  fileDetails: FileDetails;
  downloadFile: () => void;
}

const ImagePreview = ({ fileDetails }: LocalProps) => (
  <img className="img-fluid" alt="Preview of File" src={fileDetails?.dataUrl} />
);

export default ImagePreview;
