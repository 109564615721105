import React, { useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { useStateSelector } from 'hooks';
import { getAllUsers } from '../Modules/users/selectors';
import { userActions } from '../Modules/users';
import { UserModel } from '../models';

interface Props extends Omit<ReactSelectProps, 'value'> {
  value?: string;
}

const SingleUserSearch = ({ value, ...props }: Props) => {
  const dispatch = useDispatch();

  const allUsers = useStateSelector(getAllUsers);
  const isLoadingAllUsers = useStateSelector(state => state.users.loading.isLoadingAllUsers);

  useEffect(() => {
    dispatch(userActions.getAllUsers());
  }, []);

  const user = _.find(allUsers, c => c.userId === value);

  return (
    <Select<UserModel>
      {...props}
      isLoading={isLoadingAllUsers}
      getOptionLabel={c => c.displayName}
      getOptionValue={c => c.userId}
      value={user}
      options={allUsers}
    />
  );
};

export default SingleUserSearch;
