import React from 'react';
import { useDispatch } from 'react-redux';
import { format, subDays } from 'date-fns';
import _ from 'lodash';
import toastr from 'toastr';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useStateSelector } from 'hooks';
import { preventDefaultAnd } from 'helpers/eventHelpers';
import { changeModifyingAuditValues, hideAuditModifyModal, insertAudit, updateAudit } from 'Modules/audits/actions';
import { DatePicker } from 'Components';

const ModifyOperatorAuditModal = () => {
  const dispatch = useDispatch();
  const show = useStateSelector(state => !!state.audits.ui.modifyingAudit);
  const modifyingAudit = useStateSelector(state => state.audits.ui.modifyingAudit);
  const isSavingOperatorAudit = useStateSelector(state => state.audits.loading.isSavingOperatorAudit);

  function onAuditDateChange(selectedDay: Date | undefined) {
    if (!selectedDay) {
      return false;
    }

    const { startPrepDate } = modifyingAudit;

    dispatch(changeModifyingAuditValues({
      auditDate: selectedDay,
      startPrepDate: startPrepDate || subDays(selectedDay, 52 * 7),
    }));
  }

  function onStartPrepDateChange(selectedDay: Date | undefined) {
    if (!selectedDay) {
      return false;
    }

    dispatch(changeModifyingAuditValues({
      startPrepDate: selectedDay,
    }));
  }

  function saveOperatorAudit() {
    const { operatorAuditId, operatorId, startPrepDate, auditDate } = modifyingAudit;

    if (!_.isDate(startPrepDate)) {
      toastr.error('You must specify a date to begin preparing for the audit.');
      return;
    }

    if (!_.isDate(auditDate)) {
      toastr.error('You must specify an audit date.');
      return;
    }

    const dto: any = {
      operatorAuditId: operatorAuditId,
      operatorId: operatorId,
      auditDate: format(auditDate, 'yyyy-MM-dd'),
      startPrepDate: format(startPrepDate, 'yyyy-MM-dd'),
    };

    if (modifyingAudit.operatorAuditId > 0) {
      dispatch(updateAudit(dto));
    } else {
      dispatch(insertAudit(dto));
    }
  }

  const { operatorAuditId, auditDate, startPrepDate } = modifyingAudit || {};

  const isExistingOperatorAudit = operatorAuditId > 0;

  function hide() {
    dispatch(hideAuditModifyModal());
  }

  return (
    <Modal isOpen={show} toggle={hide}>
      <form onSubmit={preventDefaultAnd(saveOperatorAudit)}>
        <ModalHeader>{isExistingOperatorAudit ? 'Update Audit' : 'Create Audit'}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Begin Preparation Date:</label>
                <div>
                  <DatePicker inputProps={{ disabled: !auditDate }} value={startPrepDate}
                    onChange={onStartPrepDateChange}/>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Audit Date:</label>
                <div>
                  <DatePicker value={auditDate} onChange={onAuditDateChange}/>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-md-12">
              <div className="pull-right">
                {!isSavingOperatorAudit && <button className="btn btn-primary" type="submit">Save</button>}
                {isSavingOperatorAudit && (
                  <button className="btn btn-primary" type="button"><i className="fa fa-spin fa-refresh"/> Saving...
                  </button>
                )}
              </div>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModifyOperatorAuditModal;
