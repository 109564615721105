import React, { useMemo } from 'react';
import { Options as SplideOptions, Splide, SplideSlide } from '@splidejs/react-splide';
import useWindowBreakpoints from '../hooks/useWindowBreakpoints';

interface Props {}

const itemHeight = 70;
const ShareBoardSponsorBanner = (props: Props) => {
  const windowBreakpoints = useWindowBreakpoints();

  const splideOptions = useMemo((): Partial<SplideOptions> => {
    const perPage = (() => {
      if(windowBreakpoints.xs || windowBreakpoints.sm || windowBreakpoints.md) {
        return 1;
      }

      if(windowBreakpoints.lg) {
        return 4;
      }

      return 4;
    })();
    return {
      rewind: true,
      type: 'loop',
      arrows: false,
      autoplay: true,
      pagination: false,
      perPage: perPage,
      perMove: 1,
    };
  }, [ windowBreakpoints ]);

  return (
    <div className="sponsor-bar bg-primary d-flex flex-row align-items-center" style={{ height: 80, marginTop: 70 }}>
      <div className="text-center px-1 text-white" style={{ width: 275, opacity: .7 }}>
        Thank you to these Non-Participant Industry Organizations
      </div>

      <div className="flex-1">
        <Splide aria-label="Aviation Safety Solutions News" options={splideOptions}>
          <SplideSlide>
            <img className="img-fluid" style={{ height: itemHeight }} src="/assets/img/sponsors/AirCare.png" alt="Air Care" />
          </SplideSlide>
          <SplideSlide>
            <img className="img-fluid" style={{ height: itemHeight }} src="/assets/img/sponsors/APS.png" alt="APS" />
          </SplideSlide>
          <SplideSlide>
            <img className="img-fluid" style={{ height: itemHeight }} src="/assets/img/sponsors/Fireside.png" alt="Fireside" />
          </SplideSlide>
          <SplideSlide>
            <img className="img-fluid" style={{ height: itemHeight }} src="/assets/img/sponsors/IBAC.png" alt="IBAC" />
          </SplideSlide>
          <SplideSlide>
            <img className="img-fluid" style={{ height: itemHeight }} src="/assets/img/sponsors/Mittelman.png" alt="Mittelman" />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
};

export default ShareBoardSponsorBanner;
