import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreateOperatorAttributeType, OperatorAttributeType } from 'Modules/operatorAttributes/models';
import { operatorAttributesActions } from 'Modules/operatorAttributes';
import { getNewOperatorAttributeType } from 'Modules/operatorAttributes/instantiations';
import { useMergedEntity, useOperatorAttributeTypeForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, Panel, PanelHeader, OperatorAttributeTypeFormControls } from 'Components';

const CreateOperatorAttributeTypePanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootOperatorAttributeType = useMemo(() => getNewOperatorAttributeType(), []);
  const workingOperatorAttributeType = useStateSelector((state) => state.operatorAttributes.ui.maintenance.workingCreateOperatorAttributeType);
  const operatorAttributeType = useMergedEntity(rootOperatorAttributeType, workingOperatorAttributeType);
  const { insertOperatorAttributeType, cancelOperatorAttributeTypeModification, loading } = useOperatorAttributeTypeForm();

  function setWorkingOperatorAttributeTypeValues(operatorAttributeType: Partial<CreateOperatorAttributeType>) {
    dispatch(operatorAttributesActions.setWorkingCreateOperatorAttributeTypeValues(operatorAttributeType));
  }

  function handleSave() {
    if (!operatorAttributeType) {
      return;
    }

    insertOperatorAttributeType(operatorAttributeType)
      .then((operatorAttributeType: OperatorAttributeType) => {
        history.push(`/admin/operatorAttributeTypes/${operatorAttributeType.id}`);
      });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New Operator Attribute</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelOperatorAttributeTypeModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingOperatorAttributeType,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <OperatorAttributeTypeFormControls
              isCreatingNewOperatorAttributeType
              operatorAttributeType={operatorAttributeType}
              setValues={setWorkingOperatorAttributeTypeValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreateOperatorAttributeTypePanel;
