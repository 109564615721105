import React from 'react';

import { baseTableOptions } from 'helpers/tableHelper';
import { RootState } from '../../Modules/reducers';
import { connect } from 'react-redux';
import { stopPropagationPreventDefaultAnd } from '../../helpers/eventHelpers';
import BaseTable from '../../Components/BaseTable/BaseTable';
import { Button } from 'reactstrap';
import { Column } from 'react-table';
import { Program } from '../../Modules/programs/models';
import { deleteProgram } from '../../Modules/programs/actions';
import Swal from 'sweetalert2';
import toastr from 'toastr';

interface LocalProps {
  programs: Program[];
  selectedProgramId?: number;
  selectProgram: (program: Program) => void;
}

interface StateProps {}

interface DispatchProps {
  deleteProgram: typeof deleteProgram;
}

type Props = LocalProps & StateProps & DispatchProps;

class ProgramsTable extends React.Component<Props> {
  private deleteProgram = async (program: Program) => {
    const { dismiss } = await Swal.fire({
      title: 'Confirm',
      text: 'Are you sure you want to delete this program?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete it',
    });

    if (dismiss) {
      return;
    }

    this.props.deleteProgram(program.programId, () => {
      toastr.success('Successfully deleted the program. All operators have been unassociated with this program and all tasks removed from their audits.');
    });
  };

  private renderDelete = ({ original }: { original: Program }) => {
    return (
      <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => this.deleteProgram(original))}><i className="fa fa-trash" /></Button>
    );
  }

  private columns: Column<Program>[] = [
    {
      Header: 'Id',
      id: 'id',
      accessor: p => p.programId,
      width: 45,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: p => p.name,
    },
    {
      Header: 'Delete',
      Cell: this.renderDelete,
      width: 90,
    },
  ];

  public render () {
    const { programs, selectedProgramId } = this.props;

    return (
      <div className="bg-white">
        <BaseTable
          {...baseTableOptions}
          data={programs}
          minRows={10}
          columns={this.columns}
          defaultSorted={[ { id: 'name', desc: false } ]}
          select={{
            rowClick: true,
            idAccessor: (program: Program) => program.programId,
            selected: selectedProgramId ? { [selectedProgramId]: true } : {},
            onSelected: this.props.selectProgram,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  deleteProgram,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ProgramsTable);
