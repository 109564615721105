import React, { useState } from 'react';
import { AssignedTaskModel } from 'models';
import DraftViewer from 'Components/Draft/Viewer/DraftViewer';
import ActionType from '../../constants/ActionType';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { submitTask } from '../../Modules/tasks/actions';
import { stopPropagationAnd } from '../../helpers/eventHelpers';
import TaskQuickMessage from './TaskQuickMessage';
import CollapsableTaskFileDropzone from './CollapsableTaskFileDropzone';
import { sOrNoS } from '../../helpers/listHelpers';
import { taskIsOperatorComplete, taskIsOperatorInProgress } from '../../core/tasks';
import FlexColumn from '../../Components/FlexColumn';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  task: AssignedTaskModel;
}

const TaskSummary = ({ task }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ isFileDropzoneOpen, setIsFileDropzoneOpen ] = useState(task.numFiles === 0);

  function handleTaskSubmission () {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Submit Task',
        text: `Are you ready to send this task to your ${t('safetyCoach')} for approval?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      dispatch(submitTask(task.taskId));
    })();
  }

  const isInProgress = taskIsOperatorInProgress(task);
  const isComplete = taskIsOperatorComplete(task);

  return (
    <Row>
      <Col className="d-flex flex-column" md={8}>
        <Card className="flex">
          <CardHeader>Task Description</CardHeader>
          <CardBody>
            <h4>{task.summary}</h4>
            <DraftViewer content={task.description} />
          </CardBody>
        </Card>
      </Col>

      <Col className="d-flex flex-column" md={4}>
        <Card className="flex">
          <CardHeader>To Complete this Card</CardHeader>
          {isInProgress && (
            <CardBody className="d-flex flex flex-column">
              {task.actionTypeId === ActionType.Upload && (
                <>
                  <FlexColumn className="mb-2" fill>
                    <p>This is an upload task. Below you can upload one or more files that will be attached to this task and automatically added to your library.</p>

                    <CollapsableTaskFileDropzone
                      task={task}
                      open={isFileDropzoneOpen}
                      setOpen={(open) => setIsFileDropzoneOpen(open)}
                      onFileUploaded={() => {
                        setIsFileDropzoneOpen(false);
                      }}
                    />

                    <strong className={classnames('text-center child-spacing-x-1', { 'text-success': task.numFiles })}>
                      {task.numFiles > 0 && (<i className="mdi mdi-check-circle" />)}
                      <span>{task.numFiles} File{sOrNoS(task.numFiles)} Uploaded</span>
                    </strong>
                  </FlexColumn>

                  <div className="text-center">
                    <div>
                      <Button disabled={task.numFiles === 0} block color="success" onClick={stopPropagationAnd(handleTaskSubmission)}>Submit to {t('safetyCoach')}</Button>
                    </div>
                  </div>
                </>
              )}
              {task.actionTypeId === ActionType.Check && (
                <>
                  <FlexColumn fill alignCenter>
                    <p>This is a review task, you just need to acknowledge the content and that&apos;  s it!</p>
                  </FlexColumn>

                  <Button block color="success" onClick={stopPropagationAnd(handleTaskSubmission)}>
                    Acknowledge Task
                  </Button>
                </>
              )}
              {task.actionTypeId === ActionType.Email && (
                <>
                  <FlexColumn fill>
                    <p>This is a message task. Your {t('safetyCoach')} is looking for some input from you in order to complete this task. Use the form below to submit a message to them.</p>
                  </FlexColumn>

                  <div>
                    <Label>Send a Message to your {t('safetyCoach')}</Label>
                    <TaskQuickMessage taskId={task.taskId} />
                  </div>
                </>
              )}
            </CardBody>
          )}

          {isComplete && (
            <CardBody className="d-flex flex flex-column justify-content-center align-items-center text-success">
              <i className="mdi mdi-36px mdi-check-circle" />
              <h3 className="text-center">This Task is Complete!</h3>
            </CardBody>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TaskSummary;
