import { Dispatch } from '@reduxjs/toolkit';
import { shareBoardSlice, ShareBoardState } from './slice';
import { callApi } from 'api';
import shareBoardsActions from './actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'Modules/reducers';
import appConfig from 'config';
import {
  ApproveShareBoardFilesRequest, CreateShareBoardCategory,
  ShareBoardCategory,
  ShareBoardFile,
  ShareBoardFileSearchRequest,
  UnApproveShareBoardFilesRequest,
} from './models';
import { RenameFileFolderRequest, RenameFileRequest } from 'Modules/files/models';
import { PaginatedResult } from '../../core/index';

const shareBoardsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: ShareBoardState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(shareBoardSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getAllApprovedShareBoardFiles = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile[]>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingApprovedShareBoardFiles = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (axios) => axios.get('/api/v1/shareBoardFiles'),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setAllApprovedShareBoardFiles(data));
    },
  });
};

export const getAllApprovedShareBoardFilesForOperatorGroup = (operatorGroupId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile[]>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingApprovedShareBoardFilesForOperatorGroup = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (axios) => axios.get(`/api/v1/operatorGroups/${operatorGroupId}/shareBoardFiles`),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setAllApprovedShareBoardFilesForOperatorGroup({ files: data, operatorGroupId }));
    },
  });
};

export const getAllUnapprovedShareBoardFilesForOperatorGroup = (operatorGroupId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile[]>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingUnapprovedShareBoardFilesForOperatorGroup = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (axios) => axios.get(`/api/v1/operatorGroups/${operatorGroupId}/shareBoardFiles/unapproved`),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setAllUnapprovedShareBoardFiles({ files: data, operatorGroupId }));
    },
  });
};

export const getAllShareBoardCategories = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardCategory[]>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllShareBoardCategories = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.get(`${appConfig.baseUrl}/api/v1/shareBoardCategories`);
    },
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setAllShareBoardCategories(data));
    },
  });
};

export const searchShareBoardFiles = (request: ShareBoardFileSearchRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<PaginatedResult<ShareBoardFile>>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllShareBoardCategories = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/shareBoardFiles/search`).send(request),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setSearchedShareBoardFiles(data));
    },
  });
};

export const createShareBoardCategory = (request: CreateShareBoardCategory): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardCategory>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingShareBoardCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.post(`${appConfig.baseUrl}/api/v1/shareBoardCategories`).send(request);
    },
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setCreatedShareBoardCategory(data));
    },
  });
};

export const updateShareBoardCategory = (categoryId: number, name: string): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardCategory>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/shareBoardCategories/${categoryId}`).query({ name });
    },
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setUpdatedShareBoardCategory(data));
    },
  });
};

export const uploadShareBoardCategoryImage = (categoryId: number, imageFile: File): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  const formData = new window.FormData();
  formData.append('imageFile', imageFile);
  return callApi<ShareBoardCategory>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUploadingShareBoardCategoryImage = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/shareBoardCategories/${categoryId}/image`).send(formData);
    },
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setUpdatedShareBoardCategory(data));
    },
  });
};

export const renameShareBoardFiles = (request: RenameFileFolderRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile[]>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isRenamingShareBoardFiles = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/shareBoardFiles/folders/rename`)
        .send(request);
    },
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setShareBoardFiles(data));
    },
  });
};

export const updateShareBoardFileRating = (shareBoardFileId: number, rating?: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardFileRating = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/shareBoardFiles/${shareBoardFileId}/rate`)
        .query({ rating });
    },
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setShareBoardFileRating(data));
    },
  });
};

export const updateShareBoardName = (shareBoardId: number, request: RenameFileRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardFileName = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .put(`/api/v1/shareBoardFiles/${shareBoardId}/rename`)
      .send(request),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setShareBoardFileUpdated(data));
    },
  });
};

export const addShareBoardFileToCategory = (shareBoardFileId: number, category: ShareBoardCategory): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isAddingShareBoardFileToCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .post(`/api/v1/shareBoardFiles/${shareBoardFileId}/categories/${category.id}`),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setAddShareBoardFileToCategory({ shareBoardFileId, category }));
    },
  });
};

export const removeShareBoardFileFromCategory = (shareBoardFileId: number, category: ShareBoardCategory): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<void>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isAddingShareBoardFileToCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .del(`/api/v1/shareBoardFiles/${shareBoardFileId}/categories/${category.id}`),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setRemoveShareBoardFileFromCategory({ shareBoardFileId, category }));
    },
  });
};

export const approveShareBoardFiles = (operatorGroupId: number, request: ApproveShareBoardFilesRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardFileName = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .put('/api/v1/shareBoardFiles/approve')
      .send(request),
    onSuccess: () => {
      dispatch(shareBoardsActions.setShareBoardFilesApproved({ request, operatorGroupId }));
    },
  });
};

export const unApproveShareBoardFiles = (operatorGroupId: number, request: UnApproveShareBoardFilesRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardFileName = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .put('/api/v1/shareBoardFiles/unapprove')
      .send(request),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setShareBoardFilesUnApproved({ request, operatorGroupId }));
    },
  });
};

export const deleteShareBoardFiles = (operatorGroupId: number | undefined, shareBoardFileIds: number[]): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ShareBoardFile>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingShareBoardFileName
      = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .del('/api/v1/shareBoardFiles')
      .send(shareBoardFileIds),
    onSuccess: () => {
      dispatch(shareBoardsActions.setShareBoardFilesDeleted({ fileIds: shareBoardFileIds, operatorGroupId }));
    },
  });
};

export const deleteShareBoardCategory = (categoryId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<void>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingShareBoardCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.del(`${appConfig.baseUrl}/api/v1/shareBoardCategories/${categoryId}`),
    onSuccess: (data) => {
      dispatch(shareBoardsActions.setShareBoardCategoryDeleted(categoryId));
    },
  });
};

export const deleteShareBoardCategoryImage = (categoryId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<void>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingShareBoardCategoryImage = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.del(`${appConfig.baseUrl}/api/v1/shareBoardCategories/${categoryId}/image`),
    onSuccess: () => {
      dispatch(shareBoardsActions.setShareBoardCategoryImageDeleted(categoryId));
    },
  });
};

export const activateShareBoardCategory = (categoryId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<void>({
    loading: shareBoardsLoadingHandler(dispatch, (state, isLoading) => state.loading.isActivatingShareBoardCategory = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/shareBoardCategories/${categoryId}/activate`),
    onSuccess: () => {
      dispatch(shareBoardsActions.setShareBoardCategoryActivated(categoryId));
    },
  });
};
