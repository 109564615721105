import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';
import { ReferenceMaterial, ReferenceMaterialPosition, UpdateReferenceMaterialPosition } from './models';
import { commonActions } from '../common/actions';

export interface ReferenceMaterialsState {
  entities: {
    referenceMaterials: { [referenceMaterialId: number]: ReferenceMaterial };
  };
  xrefs: {};
  ui: {
    allReferenceMaterialIds: number[];
    createdFolders: string[];
    selectedReferenceMaterials: number[];
    referenceMaterialPositions: { [filePath: string]: number | undefined };
  };
  loading: {
    isLoadingReferenceMaterials: boolean;
    isLoadingReferenceMaterialPositions: boolean;
    isUploadingReferenceMaterial: boolean;
    isUpdatingReferenceMaterial: boolean;
    isUpdatingReferenceMaterialName: boolean;
    isMovingReferenceMaterials: boolean;
    isRenamingReferenceMaterials: boolean;
    isUpdatingReferenceMaterialPositions: boolean;
  };
}

const initialReferenceMaterialsState: ReferenceMaterialsState = {
  entities: {
    referenceMaterials: {},
  },
  xrefs: {},
  ui: {
    allReferenceMaterialIds: [],
    selectedReferenceMaterials: [],
    createdFolders: [],
    referenceMaterialPositions: {},
  },
  loading: {
    isLoadingReferenceMaterials: false,
    isLoadingReferenceMaterialPositions: false,
    isUploadingReferenceMaterial: false,
    isUpdatingReferenceMaterial: false,
    isUpdatingReferenceMaterialName: false,
    isMovingReferenceMaterials: false,
    isRenamingReferenceMaterials: false,
    isUpdatingReferenceMaterialPositions: false,
  },
};

export const referenceMaterialsSlice = createSlice({
  name: 'ReferenceMaterials',
  initialState: initialReferenceMaterialsState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<ReferenceMaterialsState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    addCreatedFolder: (state, action: PayloadAction<string>) => {
      state.ui.createdFolders = _.uniq(state.ui.createdFolders.concat(action.payload.trim()));
    },
    setSelectedReferenceMaterials: (state, action: PayloadAction<number[]>) => {
      state.ui.selectedReferenceMaterials = action.payload;
    },
    setAllReferenceMaterials: (state, action: PayloadAction<ReferenceMaterial[]>) => {
      state.entities.referenceMaterials = {
        ...state.entities.referenceMaterials,
        ..._.keyBy(action.payload, r => r.referenceFileId),
      };
      state.ui.allReferenceMaterialIds = action.payload.map(r => r.referenceFileId);
    },
    setAllReferenceMaterialPositions: (state, action: PayloadAction<ReferenceMaterialPosition[]>) => {
      const updatedReferenceMaterialPositions = { ...state.ui.referenceMaterialPositions };

      _.each(action.payload, (position) => {
        updatedReferenceMaterialPositions[position.filePath] = position.position;
      });

      state.ui.referenceMaterialPositions = updatedReferenceMaterialPositions;
    },
    setReferenceMaterials: (state, action: PayloadAction<ReferenceMaterial[]>) => {
      state.entities.referenceMaterials = {
        ...state.entities.referenceMaterials,
        ..._.keyBy(action.payload, r => r.referenceFileId),
      };
    },
    setReferenceMaterialPositions: (state, action: PayloadAction<UpdateReferenceMaterialPosition[]>) => {
      const positions = action.payload;

      const updatedReferenceMaterialPositions = { ...state.ui.referenceMaterialPositions };

      _.each(positions, (position) => {
        updatedReferenceMaterialPositions[position.filePath] = position.position;
      });

      state.ui.referenceMaterialPositions = updatedReferenceMaterialPositions;
    },
    revertReferenceMaterialPositions: (state, action: PayloadAction<UpdateReferenceMaterialPosition[]>) => {
      const positions = action.payload;

      const updatedReferenceMaterialPositions = { ...state.ui.referenceMaterialPositions };

      _.each(positions, (position) => {
        updatedReferenceMaterialPositions[position.filePath] = position.originalPosition;
      });

      state.ui.referenceMaterialPositions = updatedReferenceMaterialPositions;
    },
    setReferenceMaterialCreated: (state, action: PayloadAction<ReferenceMaterial>) => {
      state.entities.referenceMaterials = {
        ...state.entities.referenceMaterials,
        [action.payload.referenceFileId]: action.payload,
      };
      state.ui.allReferenceMaterialIds = state.ui.allReferenceMaterialIds.concat(action.payload.referenceFileId);
    },
    setReferenceMaterialUpdated: (state, action: PayloadAction<ReferenceMaterial>) => {
      state.entities.referenceMaterials = {
        ...state.entities.referenceMaterials,
        [action.payload.referenceFileId]: action.payload,
      };
    },
  },
  extraReducers: {
    [commonActions.PATH_LOCATION_CHANGE]: () => {
      return { ...initialReferenceMaterialsState };
    },
  },
});
