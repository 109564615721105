import React from 'react';

import { OperatorGroupMessageFileSummary } from 'Modules/operatorGroups/models';
import { FilePreview, FileTile, FlexRow } from 'Components';

interface Props {
  operatorMessageFile: OperatorGroupMessageFileSummary;
}

const OperatorGroupMessageFileTile = function ({ operatorMessageFile }: Props) {
  const extension = operatorMessageFile.fileName.split('.').pop()!;

  return (
    <>
      <FileTile
        fileName={operatorMessageFile.fileName}
        createdTimestamp={operatorMessageFile.createdTimestamp}
        Thumbnail={(
          <FilePreview extension={extension} sasUrl={operatorMessageFile.url} />
        )}
        TopRight={(
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <a href={operatorMessageFile.url} target="_blank" rel="noopener noreferrer">
                <i className="mdi mdi-24px mdi-download"/>
              </a>
            </div>
          </FlexRow>
        )}
      />
    </>
  );
};

export default OperatorGroupMessageFileTile;
