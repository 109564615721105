import React from 'react';
import { EditorProps } from 'react-draft-wysiwyg';
import classnames from 'classnames';

import UploadFileOption from './Options/UploadFileOption';
import DraftEditor from './DraftEditor';

interface Props extends EditorProps {}

const AdminDraftEditor = (props: Props) => {
  const CustomOptions = [
    // @ts-ignore
    <UploadFileOption key="upload-file" />,
  ];

  return (
    <DraftEditor
      wrapperClassName={classnames(props.wrapperClassName, { 'read-only': props.readOnly })}
      toolbar={{
        options: [
          'inline',
          'link',
        ],
        inline: {
          options: [ 'bold', 'italic', 'underline', 'strikethrough' ],
        },
      }}
      toolbarCustomButtons={CustomOptions}
      {...props}
    />
  );
};

export default AdminDraftEditor;
