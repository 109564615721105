import React, { ReactNode, useEffect } from 'react';

import { siteActions } from 'Modules/site';
import { FlexLoader } from 'Components';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useStateSelector from 'hooks/useStateSelector';
import { Helmet } from 'react-helmet';

interface Props {
  children: ReactNode;
}

const PageModelWrapper = ({ children }: Props) => {
  const dispatch = useThunkDispatch();
  const pageModel = useStateSelector(state => state.site.pageModel);

  useEffect(() => {
    dispatch(siteActions.async.getPageModel());
  }, []);

  if(!pageModel) {
    return (
      <FlexLoader />
    );
  }

  return (
    <>
      {pageModel.microsoftClarityProjectId && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${pageModel.microsoftClarityProjectId}");`,
            },
          ]}
        />
      )}
      {children}
    </>
  );
};

export default PageModelWrapper;
