import React, { ReactNode, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Button, Collapse } from 'reactstrap';

import { ReactChildren } from '../models';

interface LocalProps {
  Header: ReactNode;
  defaultOpen?: boolean;
  children?: ReactChildren;
}

export interface CollapsibleCardBoxRef {
  setIsOpen: (isOpen: boolean) => void,
  toggleIsOpen: () => void,
}

const CollapsibleCardBox = React.forwardRef((props: LocalProps, ref: React.Ref<CollapsibleCardBoxRef>) => {
  const [ isOpenValue, setIsOpenValue ] = useState<boolean>(_.isNil(props.defaultOpen) ? false : props.defaultOpen);

  React.useImperativeHandle(ref, () => ({
    setIsOpen,
    toggleIsOpen,
  }));

  function toggleIsOpen(){
    setIsOpenValue(!isOpenValue);
  }

  function setIsOpen(isOpen: boolean) {
    setIsOpenValue(isOpen);
  }

  const { Header, children } = props;
  return (
    <div className="card-box card-box-condensed">
      <Button color="anchor" className="no-outline" onClick={toggleIsOpen}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <i className={classnames({
              fa: true,
              'fa-chevron-right': !isOpenValue,
              'fa-chevron-down': isOpenValue,
            })}
            />
          </div>
          <div style={{ flex: 1 }}>
            {_.isString(Header) && <h4 className="header-title m-0 my-1 ml-1">{Header}</h4>}
            {(Header && !_.isString(Header)) &&
            (
              <>
                {Header}
              </>
            )}
          </div>
        </div>
      </Button>

      <Collapse isOpen={isOpenValue}>
        <div>
          {children}
        </div>
      </Collapse>
    </div>
  );
});

export default CollapsibleCardBox;
