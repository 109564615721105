import React from 'react';
import classnames from 'classnames';
import { getFileIconOrDefault } from 'constants/FileExtensionIcons';
import { BackgroundImage } from 'Components/index';

interface Props {
  sasUrl?: string;
  extension: string;
}

const FilePreview = ({ sasUrl, extension }: Props) => {
  return (
    <div className={classnames({ 'thumbnail-container': sasUrl })}>
      {!sasUrl && (
        <i
          className={classnames('folder-icon', getFileIconOrDefault(extension, 'mdi mdi-file-question-outline'))}/>
      )}
      {sasUrl && (
        <BackgroundImage className="flex h-100 w-100" src={sasUrl}/>
      )}
    </div>
  );
};

export default FilePreview;
