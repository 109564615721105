import React, { ReactNode } from 'react';
import _ from 'lodash';
import { Button, ButtonProps } from 'reactstrap';
import classnames from 'classnames';

export interface LoadableButtonProps extends ButtonProps {
  isLoading?: boolean;
  LoadingLabel?: ReactNode | null;
  iconClassName?: string
}

interface Props extends LoadableButtonProps {
}

const LoadableButton = ({ isLoading, children, type, LoadingLabel, className, iconClassName, ...rest }: Props) => {
  return (
    <>
      <Button
        className={classnames(className, 'loadable-button')}
        onClick={isLoading ? _.noop : rest.onClick}
        type={isLoading ? 'button' : type}
        {...rest}
      >
        {!isLoading && (
          <span>{children}</span>

        )}
        {isLoading && (
          <span>
            <i className={classnames('mdi mdi-spin mdi-refresh', iconClassName)} /> {_.isUndefined(LoadingLabel) ? <span>Loading...</span> : LoadingLabel}
          </span>
        )}
      </Button>
    </>
  );
};

export default LoadableButton;
