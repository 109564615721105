import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { OperatorModel } from 'models';
import { operatorsActions, operatorsSelectors } from 'Modules/operators';

interface LocalProps extends Omit<ReactSelectProps, 'value'> {
  value?: OperatorModel;
  filterOptions?: (operators: OperatorModel[]) => OperatorModel[];
}

const OperatorSearch = ({ value, filterOptions, ...props }: LocalProps) => {
  const dispatch = useDispatch();
  const allOperators = useSelector(operatorsSelectors.getAllOperators);

  const options = useMemo(() => {
    if (!filterOptions) {
      return allOperators;
    }

    return filterOptions(allOperators);
  }, [ filterOptions, allOperators ]);

  useEffect(() => {
    dispatch(operatorsActions.async.getAllOperators());
  }, []);

  return (
    <Select
      placeholder="Select a Operator..."
      isClearable
      menuPortalTarget={document.body}
      {...props}
      value={value || null}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.programId}
      options={options as any[]}
    />
  );
};

export default OperatorSearch;
