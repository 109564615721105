import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import TaskStatusType from '../constants/TaskStatusType';
import { AssignedTaskModel } from '../models';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useCurrentUserPermissionChecker, useStateSelector } from '../hooks';

import * as taskActions from 'Modules/tasks/actions';
import { Permission } from '../Modules/roles/index';

interface Props {
  task?: AssignedTaskModel;
}

const SetTaskStatusDropdown = ({ task }: Props) => {
  const dispatch = useDispatch();
  const currentUserHasPermission = useCurrentUserPermissionChecker();

  const canSetStatus = useStateSelector(state => currentUserHasPermission(Permission.GlobalTaskMaintenance) || !!(state.site.selectedOperator?.userIsSafetyCoach));

  const submitTask = async () => {
    if (!task) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Submit Task',
      text: 'Are you sure you want to force submit this task?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (dismiss) {
      return;
    }

    dispatch(taskActions.submitTask(task.taskId));
  };

  const notApplicableTask = async () => {
    if (!task) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Mark Task N/A',
      text: 'Are you sure you want to mark this task as not applicable?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (dismiss) {
      return;
    }

    dispatch(taskActions.notApplicableTask(task.taskId));
  };

  const resetTask = async () => {
    if (!task) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Reset Task',
      text: 'Are you sure you want to reset this task? This will move the task back to the operator view.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (dismiss) {
      return;
    }

    dispatch(taskActions.resetTask(task.taskId));
  };

  const completeTask = async () => {
    if (!task) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Complete Task',
      text: 'Are you sure you want to complete this task?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (dismiss) {
      return;
    }

    dispatch(taskActions.completeTask(task.taskId));
  };

  const rejectTask = async () => {
    if (!task) {
      return;
    }

    const { dismiss } = await Swal.fire({
      title: 'Reject Task',
      text: 'Are you sure you want to reject this task?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (dismiss) {
      return;
    }

    dispatch(taskActions.rejectTask(task.taskId));
  };

  if (!canSetStatus) {
    return null;
  }

  if (!task) {
    return null;
  }

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle color="light">
        Set Status <i className="mdi mdi-menu-down"/>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Auditing</DropdownItem>
        {task?.currentStatus !== TaskStatusType.Complete &&
          <DropdownItem onClick={completeTask}>Complete</DropdownItem>}
        {task?.currentStatus !== TaskStatusType.Rejected && <DropdownItem onClick={rejectTask}>Rejected</DropdownItem>}

        <DropdownItem header>Overrides</DropdownItem>
        {task?.currentStatus !== TaskStatusType.Created && <DropdownItem onClick={resetTask}>Reset Task</DropdownItem>}
        {task?.currentStatus !== TaskStatusType.PendingApproval &&
          <DropdownItem onClick={submitTask}>Force Submit Task</DropdownItem>}
        {task?.currentStatus !== TaskStatusType.NotApplicable &&
          <DropdownItem onClick={notApplicableTask}>Not Applicable</DropdownItem>}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default SetTaskStatusDropdown;
