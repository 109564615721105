import React from 'react';
import _ from 'lodash';
import { OperatorAuditModel, OperatorAuditTasks } from '../../../models';
import WeekTasks from './WeekTasks';

interface LocalProps {
  operatorAudit: OperatorAuditModel;
  operatorAuditTasks: OperatorAuditTasks;
}

const WeeklyTasks = (props: LocalProps) => {
  const { operatorAuditTasks, operatorAudit } = props;

  return (
    <>
      {
        _.map(operatorAuditTasks.weeks, (week, index) => {
          const isLast = _.size(operatorAuditTasks.weeks) === index - 1;
          return (
            <React.Fragment key={week.weekIndex}>
              <WeekTasks
                operatorAudit={operatorAudit}
                operatorAuditTasks={operatorAuditTasks}
                auditWeek={week}
              />
              {!isLast && <hr />}
            </React.Fragment>
          );
        })
      }
    </>
  );
};

export default WeeklyTasks;
