import React from 'react';

import { baseTableOptions } from 'helpers/tableHelper';
import { PollResultSummary } from 'Modules/polls';
import { BaseTable } from 'Components';

interface LocalProps {
  pollResultSummaries: PollResultSummary[];
}

const OperatorPollResultSummaryTable = ({ pollResultSummaries }: LocalProps) => {
  const tableOptions: any = {
    ...baseTableOptions,
    minRows: 10,
    data: pollResultSummaries,
    columns: [
      {
        id: 'operatorName',
        Header: 'Operator',
        accessor: (r: PollResultSummary) => {
          if(r.isCurrentOperatorsResult) {
            return 'Current Operator';
          }

          if(r.operatorCodeName) {
            return r.operatorCodeName.trim();
          }

          return 'REDACTED';
        },
      },
    ],
    defaultSorted: [ { id: 'operatorName' } ],
  };

  return (
    <div className="bg-white">
      <BaseTable filterable {...tableOptions} />
    </div>
  );
};

export default OperatorPollResultSummaryTable;
