import { Dispatch } from '@reduxjs/toolkit';
import { feedbackSlice, FeedbackState } from './slice';
import { callApi } from 'api';
import { CreateFeedback } from './models';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';

const feedbackLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: FeedbackState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(feedbackSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const insertFeedback = (feedback: CreateFeedback): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: feedbackLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingFeedback = isLoading),
    getRequest: (superagent) => superagent.post('/api/v1/feedback').send(feedback),
  });
};
