import React from 'react';
import _ from 'lodash';
import { Column } from 'react-table';
import { AssignedTaskModel, OperatorAuditModel } from '../../models';
import BaseTable from '../../Components/BaseTable/BaseTable';
import TaskStatusBadge from '../../Components/TaskStatusBadge';
import TaskModal from '../../AviationSafetySolutions/Tasks/TaskModal';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import TaskNotificationIcon from '../../Components/TaskNotificationIcon';
import ActionType from '../../constants/ActionType';
import { sOrNoS } from '../../helpers/listHelpers';
import DraftTextViewer from '../../Components/Draft/Viewer/DraftTextViewer';

interface LocalProps {
  tasks: AssignedTaskModel[];
  audit: OperatorAuditModel;
}

interface LocalState {
  selectedTask?: AssignedTaskModel;
}

class TaskTable extends React.Component<LocalProps, LocalState> {
  constructor (props: LocalProps) {
    super(props);

    this.state = {
      selectedTask: undefined,
    };
  }

  public componentDidUpdate (prevProps: Readonly<LocalProps>) {
    const { selectedTask } = this.state;
    const { tasks } = this.props;
    if (selectedTask && prevProps.tasks !== tasks) {
      const updatedSelectedTask = _.find(tasks, t => t.taskId === selectedTask.taskId);

      if (updatedSelectedTask) {
        this.setState({ selectedTask: updatedSelectedTask });
      }
    }
  }

  private getAuditWeek = (task: AssignedTaskModel) => {
    const { audit } = this.props;

    if (!task.parsedTaskDate) {
      return undefined;
    }

    return _.find(audit.weeks, ({ parsedStartDate, parsedEndDate }) => {
      return (isSameDay(parsedStartDate, task.parsedTaskDate!) || isAfter(parsedStartDate, task.parsedTaskDate!)) &&
        (isSameDay(parsedEndDate, task.parsedTaskDate!) || isBefore(task.parsedTaskDate!, parsedEndDate));
    });
  }

  private columns: Column<AssignedTaskModel>[] = [
    {
      id: 'notification',
      Header: '',
      width: 26,
      filterable: false,
      accessor: t => t.hasUnreadNotifications,
      Cell: ({ original }: { original: AssignedTaskModel }) => {
        if (!original.hasUnreadNotifications) {
          return null;
        }

        return (
          <TaskNotificationIcon />
        );
      },
    },
    {
      id: 'week',
      Header: 'Week',
      width: 80,
      filterable: false,
      accessor: (t) => {
        const week = this.getAuditWeek(t);

        if (!week) {
          return 99999;
        }

        return week.weekIndex + 1;
      },
      Cell: ({ original }: { original: AssignedTaskModel }) => {
        const week = this.getAuditWeek(original);

        if (_.isNil(week?.weekIndex)) {
          return null;
        }

        return `Week ${week!.weekIndex + 1}`;
      },
    },
    {
      id: 'status',
      Header: 'Status',
      width: 115,
      filterable: false,
      accessor: (t) => t.currentStatus,
      Cell: ({ original }: { original: AssignedTaskModel }) => (<TaskStatusBadge status={original.currentStatus} />),
    },
    {
      id: 'summary',
      Header: 'Summary',
      width: 375,
      accessor: (t) => t.summary,
    },
    {
      id: 'numFiles',
      Header: '',
      width: 55,
      className: 'text-center',
      filterable: false,
      accessor: t => t.hasUnreadNotifications,
      Cell: ({ original }: { original: AssignedTaskModel }) => {
        if (original.actionTypeId !== ActionType.Upload) {
          return null;
        }

        return (
          <div>
            {original.numFiles > 0 && <span title={`${original.numFiles} File${sOrNoS(original.numFiles)} Uploaded`}><i className="mdi mdi-file-upload" /> {original.numFiles}</span>}
          </div>
        );
      },
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: (t) => t.description,
      Cell: ({ original }: { original: AssignedTaskModel }) => {
        return (
          <span>
            <DraftTextViewer content={original.description} />
          </span>
        );
      },
    },
  ]

  setSelectedTask = (task: AssignedTaskModel) => {
    this.setState({ selectedTask: task });
  }

  clearSelectedTask = () => {
    this.setState({ selectedTask: undefined });
  }

  render () {
    const { selectedTask } = this.state;
    const { tasks } = this.props;

    return (
      <>
        <BaseTable
          filterable
          data={tasks}
          defaultSorted={[ { id: 'week', desc: false } ]}
          columns={this.columns}
          select={{
            rowClick: true,
            onSelected: this.setSelectedTask,
          }}
        />

        <TaskModal
          show={!!selectedTask}
          task={selectedTask}
          hide={this.clearSelectedTask}
        />
      </>
    );
  }
}

export default TaskTable;
