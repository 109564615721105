import React from 'react';
import { OperatorAuditModel } from '../../../models';
import DashboardCard from './DashboardCard';

interface LocalProps {
  audit?: OperatorAuditModel;
}
const NextAuditCard = ({ audit }: LocalProps) => {
  return (
    <DashboardCard
      icon={<i className="mdi mdi-calendar-month mdi-36px" />}
      heading="Next Audit:"
      body={audit?.displayAuditDate || 'Not Scheduled'}
    />
  );
};

export default NextAuditCard;
