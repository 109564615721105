import React, { ReactNode, useMemo } from 'react';
import { Button, Col, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';
import TaskStatusType from 'constants/TaskStatusType';
import { AssignedTaskModel } from 'models';
import DraftTextViewer from 'Components/Draft/Viewer/DraftTextViewer';
import { FlexRow } from '../../../Components/index';
import { getEditorStateFromString, getTextFromEditorState } from '../../../core/index';
import ActionType from '../../../constants/ActionType';
import { stopPropagationAnd } from '../../../helpers/eventHelpers';
import Swal from 'sweetalert2';
import { submitTask } from '../../../Modules/tasks/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface LocalProps {
  task: AssignedTaskModel;
  includeAction: boolean;
  messageSafetyCoach?: () => void;
  uploadFile?: () => void;
  children?: ReactNode;
  onTaskClick: (task: AssignedTaskModel) => void;
}

const BaseDashboardTask = function ({ task, includeAction, ...props }: LocalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { summary, description, currentStatus, isPastDue, hasUnreadNotifications, actionTypeId } = task;
  const standardDescription = task?.standardReferenceId ? `${task?.standardName} - ${task?.standardReferenceLookup}` : '';
  const editorState = useMemo(() => getEditorStateFromString(description), [ description ]);
  const text = useMemo(() => getTextFromEditorState(editorState), [ editorState ]);

  function handleTaskSubmission () {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Submit Task',
        text: `Are you ready to send this task to your ${t('safetyCoach')} for approval?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      dispatch(submitTask(task.taskId));
    })();
  }

  return (
    <>
      <div
        className="ribbon-box dashboard-task bg-white border-dark p-1 shadow"
        onClick={() => props.onTaskClick(task)}
        style={{ borderWidth: 3, borderStyle: 'solid' }}
      >
        <div>
          {hasUnreadNotifications && (
            <div className="ribbon ribbon-danger float-right"><span>Updated</span></div>
          )}

          <div className="d-flex flex-row">
            <h4 className="mb-0 flex-1 text-ellipsis" title={summary}>
              {summary}
            </h4>

            <div>
              {standardDescription && (
                <FlexRow>
                  <span className="text-dark" id={`standard-popover-${task.taskId}`}>
                    <i className="mdi mdi-information"/>
                  </span>

                  <UncontrolledPopover trigger="click hover" placement="bottom" target={`standard-popover-${task.taskId}`}>
                    <PopoverHeader>{task?.standardName} - {task?.standardReferenceLookup}</PopoverHeader>
                    <PopoverBody>{task?.standardReferenceDescription}</PopoverBody>
                  </UncontrolledPopover>
                </FlexRow>
              )}
            </div>
          </div>

          {text && (
            <div>
              <DraftTextViewer content={description} />
            </div>
          )}

          <div className="d-flex justify-content-between mt-1">
            {includeAction && (
              <div className="flex-1 text-center px-3">
                <div>
                  {props.uploadFile && actionTypeId === ActionType.Upload && (
                    <Button block color="secondary" onClick={stopPropagationAnd(props.uploadFile)}>
                      Upload
                    </Button>
                  )}

                  {actionTypeId === ActionType.Check && (
                    <>
                      <Button block color="secondary" onClick={stopPropagationAnd(handleTaskSubmission)}>
                        Acknowledge Task
                      </Button>
                    </>
                  )}
                </div>

                {props.messageSafetyCoach && (
                  <div className="mt-1">
                    <Button block color="primary" onClick={stopPropagationAnd(props.messageSafetyCoach)}>
                      Message Safety Coach
                    </Button>
                  </div>
                )}
              </div>
            )}

            {task.taskDate && (
              <div className="flex-1">
                <div className="border">
                  <div className="border-bottom text-center">
                    <div className="font-weight-bold">Due Date</div>
                    <div>{task.displayTaskDate}</div>
                  </div>

                  <div className="text-center">
                    {isPastDue && <strong className="text-danger"> PAST DUE</strong>}
                    {currentStatus === TaskStatusType.Rejected && <strong className="text-danger"> REJECTED</strong>}
                  </div>
                </div>
              </div>
            )}
          </div>

          <Row>
            <Col md={12}>
              {props.children}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default BaseDashboardTask;
