import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { signalrActions } from '../Modules/signalr/actions';

const SignalRInitializer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: signalrActions.INIT_SIGNALR });
  }, []);

  return null;
};

export default SignalRInitializer;
