import { peerOperatorsSlice } from './slice';
import * as peerOperatorApiActions from './apiActions';

const peerOperatorActions = {
  ...peerOperatorsSlice.actions,
  async: {
    ...peerOperatorApiActions,
  },
};

export default peerOperatorActions;
