import React from 'react';
import _ from 'lodash';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

import useStateSelector from 'hooks/useStateSelector';
import { Permission } from '../../Modules/roles/index';
import { useCurrentUserPermissionChecker } from '../../hooks/index';

interface Props extends RouteProps {
  safetyCoachOnly?: boolean;
  accountOnly?: boolean;
  requireSelectedOperator?: boolean;
  requirePermission?: Permission;
}

const SmartRoute = ({ accountOnly, safetyCoachOnly, requireSelectedOperator, requirePermission, ...props }: Props) => {
  const selectedOperator = useStateSelector(state => state.site.selectedOperator);
  const currentUserHasPermission = useCurrentUserPermissionChecker();

  if(!_.isNil(requirePermission) && !currentUserHasPermission(requirePermission)) {
    return null;
  }

  if(requireSelectedOperator && !selectedOperator) {
    return null;
  }

  if(safetyCoachOnly && !selectedOperator?.userIsSafetyCoach) {
    return null;
  }

  if(accountOnly && !selectedOperator?.userIsAccount) {
    return null;
  }

  return (
    <Route {...props} />
  );
};

export default SmartRoute;
