import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { getValueByKey } from 'core';
import { UpdatePollCategory } from 'Modules/polls/models';
import { pollsActions } from 'Modules/polls';
import { useMergedEntity, usePollCategoryForm, useRequiredPathId, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, FlexLoader, Panel, PanelHeader, PollCategoryForm } from 'Components';

const ModifyPollCategoryPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pollCategoryId = useRequiredPathId('pollCategoryId');
  const workingPollCategory = useStateSelector((state) => state.polls.ui.categoryMaintenance.workingUpdatePollCategory);
  const isLoadingPollCategory = useStateSelector((state) => state.polls.loading.isLoadingPollCategory);
  const canEdit = true; //todo permissions useHasPermission(Permission.PollCategoryCategoriesEdit);
  const canDeactivate = true; //todo permissions useHasPermission(Permission.PollCategoryCategoriesDeactivate);
  const rootPollCategory = useStateSelector((state) => getValueByKey(state.polls.entities.pollCategories, pollCategoryId));
  const pollCategory = useMergedEntity(rootPollCategory, workingPollCategory);
  const { updatePollCategory, deletePollCategory, cancelPollCategoryModification, loading } = usePollCategoryForm();

  useEffect(() => {
    dispatch(pollsActions.async.getPollCategory(pollCategoryId));
  }, [ pollCategoryId ]);

  function setWorkingPollCategoryValues(pollCategory: Partial<UpdatePollCategory>) {
    dispatch(pollsActions.setWorkingUpdatePollCategoryValues(pollCategory));
  }

  function handleDelete() {
    if (!pollCategory) {
      return;
    }

    deletePollCategory(pollCategory)
      .then(() => {
        history.push('/admin/pollCategories');
      });
  }

  if (isLoadingPollCategory) {
    return (
      <FlexLoader />
    );
  }

  if (!pollCategory) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader white>
        Updating Poll Category
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelPollCategoryModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingPollCategory,
              children: 'Delete',
              hidden: !canDeactivate,
            }}
            saveButton={{
              onClick: () => updatePollCategory(pollCategoryId, pollCategory),
              isLoading: loading.isUpdatingPollCategory,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <PollCategoryForm
              pollCategory={pollCategory}
              setValues={setWorkingPollCategoryValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyPollCategoryPanel;
