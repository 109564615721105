import React, { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { FlexRow, LoadableButton } from 'Components';
import useStateSelector from '../../hooks/useStateSelector';
import { referenceMaterialsActions } from '../../Modules/referenceMaterials';
import useThunkDispatch from '../../hooks/useThunkDispatch';

interface Props {
  show: boolean;
  currentFilePath: string;
  hide: () => void;
}

const CreateReferenceMaterialModal = ({ show, currentFilePath, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const [ name, setName ] = useState<string>('');
  const [ file, setFile ] = useState<File | undefined>();
  const isUploadingReferenceMaterial = useStateSelector(state => state.referenceMaterials.loading.isUploadingReferenceMaterial);

  function handleFileSelect (e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    setFile(e.target.files[0]);
  }

  function uploadReferenceMaterials() {
    if(!file) {
      return;
    }

    dispatch(referenceMaterialsActions.async.uploadReferenceMaterial({ name, uploadDirectoryPath: currentFilePath, file }))
      .then(() => {
        props.hide();
      });
  }

  return (
    <Modal isOpen={show} size="lg" toggle={props.hide}>
      <ModalHeader>Create Reference Material</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Name:</Label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input type="file" onChange={handleFileSelect} />
        </FormGroup>


      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div>
            <Button color="light" onClick={props.hide}>Cancel</Button>
          </div>
          <div>
            <LoadableButton
              onClick={uploadReferenceMaterials}
              isLoading={isUploadingReferenceMaterial}
              color="primary"
              disabled={!name.trim() || !file}>
              Upload
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default CreateReferenceMaterialModal;
