import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';
import { LoginUserModel, OperatorModel, SidebarType, UserModel } from 'models';
import claims from 'claims';
import { commonActions } from 'Modules/common/actions';
import { usersActions } from 'Modules/users/actions';
import { operatorsActions, SelectedOperator } from 'Modules/operators';

import { ActiveUser, AuthUser, PageModel, SetTokenPayload } from './models';

export interface SiteState {
  operatorNeedsToBeSelected: boolean;
  promptTos: boolean;
  selectedOperator?: SelectedOperator;
  isShowingImpersonationModal: boolean;
  authUser?: AuthUser;
  pageModel?: PageModel;
  user?: LoginUserModel;
  currentPathname?: string;
  currentLocation?: string;
  lastPathname?: string;
  lastLocation?: string;
  translationKey?: string;
  sidebarType?: SidebarType;
  onlineUsers: ActiveUser[];
  loading: {
    isLoadingPageModel: boolean;
  }
}

const initialSiteState: SiteState = {
  operatorNeedsToBeSelected: false,
  promptTos: false,
  selectedOperator: undefined,
  sidebarType: undefined,
  isShowingImpersonationModal: false,
  authUser: undefined,
  user: undefined,
  lastLocation: '',
  lastPathname: '',
  onlineUsers: [],
  loading: {
    isLoadingPageModel: false,
  },
};

export const siteSlice = createSlice({
  name: 'Site',
  initialState: initialSiteState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<SiteState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setToken: (state, action: PayloadAction<SetTokenPayload>) => {
      const { user, token } = action.payload;

      return {
        ...state,
        authUser: {
          token: token,
          userId: user[claims.UserId],
        },
      };
    },
    setSidebarType: (state, action: PayloadAction<SidebarType | undefined>) => {
      state.sidebarType = action.payload;
    },
    initSignalr: (state, action: PayloadAction<PageModel>) => {
      state.pageModel = action.payload;
    },
    setPageModel: (state, action: PayloadAction<PageModel>) => {
      state.pageModel = action.payload;
    },
    setActiveUsers: (state, action: PayloadAction<ActiveUser[]>) => {
      state.onlineUsers = action.payload;
    },
    setSelectedOperator: (state, action: PayloadAction<SelectedOperator | undefined>) => {
      state.selectedOperator = action.payload;
    },
    setOperatorNeedsToBeSelected: (state, action: PayloadAction<boolean>) => {
      state.operatorNeedsToBeSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersActions.GET_CURRENT_LOGGED_IN_USER_SUCCESS, (state, action: PayloadAction<{ user: LoginUserModel}>) => {
        const { user } = action.payload;

        return {
          ...state,
          user,
        };
      })
      .addCase(usersActions.SET_PROFILE_PICTURE_SUCCESS, (state, action: PayloadAction<{ user: UserModel}>) => {
        const { user } = action.payload;

        if(state.user?.userId !== user.userId) {
          return state;
        }

        return {
          ...state,
          user: {
            ...state.user,
            profilePictureFile: user.profilePictureFile,
          },
        };
      })
      .addCase(usersActions.DELETE_PROFILE_PICTURE_SUCCESS, (state, action: PayloadAction<{ userId: string}>) => {
        const { userId } = action.payload;

        if(state.user?.userId !== userId) {
          return state;
        }

        return {
          ...state,
          user: {
            ...state.user,
            profilePictureFile: undefined,
          },
        };
      })
      .addCase(operatorsActions.onOperatorUpdated, (state, action: PayloadAction<OperatorModel>) => {
        if(action.payload.operatorId !== state.selectedOperator?.operatorId) {
          return state;
        }

        state.selectedOperator = {
          ...state.selectedOperator,
          ...action.payload,
        };
      })
      .addCase(operatorsActions.setProfilePictureSuccess, (state, action: PayloadAction<OperatorModel>) => {
        if(!state.selectedOperator) {
          return state;
        }

        if(action.payload.operatorId !== state.selectedOperator.operatorId) {
          return state;
        }

        state.selectedOperator = {
          ...state.selectedOperator,
          imageFile: action.payload.imageFile,
        };
      })
      .addCase(operatorsActions.removeProfilePictureSuccess, (state, action: PayloadAction<number>) => {
        if(action.payload !== state.selectedOperator?.operatorId) {
          return state;
        }

        state.selectedOperator = {
          ...state.selectedOperator,
          imageFile: undefined,
        };
      })
      .addCase(commonActions.LOCATION_CHANGE, (state, action: PayloadAction<any>) => {
        const { location } = action.payload;

        return {
          ...state,
          onlineUsers: state.onlineUsers,
          lastLocation: state.currentLocation,
          lastPathname: state.currentPathname,
          currentPathname: location.pathname,
          currentLocation: location.pathname + location.search + location.hash,
        };
      })
      .addCase(commonActions.TOS_NOT_AGREED_UPON, (state): SiteState => {
        return {
          ...state,
          promptTos: true,
        };
      })
      .addCase(usersActions.AGREE_TO_TERMS_OF_SERVICE_SUCCESS, (state, action: PayloadAction<{ user: UserModel }>): SiteState => {
        if(!state.user) {
          return state;
        }

        return {
          ...state,
          user: {
            ...state.user,
            termsOfServiceAgreedOn: action.payload.user.termsOfServiceAgreedOn,
          },
        };
      });
  },
});
