import _ from 'lodash';
import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { CreateRole, ModifyRole, Role, rolesActions, UpdateRole } from 'Modules/roles';

const useRoleForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingRole = useStateSelector((state) => state.roles.loading.isInsertingRole);
  const isUpdatingRole = useStateSelector((state) => state.roles.loading.isUpdatingRole);
  const isDeletingRole = useStateSelector((state) => state.roles.loading.isDeletingRole);

  function validateRole(role: ModifyRole) {
    const errors: string[] = [];

    return errors;
  }

  function insertRole(role: CreateRole) {
    const errorMessages = validateRole(role);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(rolesActions.async.insertRole(role))
      .then((role: Role) => {
        toast.success('Role successfully created.');
        return role;
      });
  }

  function updateRole(roleId: number, role: UpdateRole) {
    const errorMessages = validateRole(role);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(rolesActions.async.updateRole(roleId, role))
      .then((role: Role) => {
        toast.success('Role successfully saved.');
        return role;
      });
  }

  function deleteRole(role: Role) {
    return dispatch(rolesActions.async.deleteRole(role))
      .then((role: Role) => {
        toast.success('Role successfully deactivated.');
        return role;
      });
  }

  function cancelRoleModification() {
    dispatch(rolesActions.cancelRoleModification());
  }

  return {
    insertRole,
    updateRole,
    deleteRole,
    cancelRoleModification,
    loading: {
      isInsertingRole,
      isUpdatingRole,
      isDeletingRole,
    },
  };
};

export default useRoleForm;
