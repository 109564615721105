import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import { OperatorModel } from 'models';
import { CreateOperator, operatorsActions, UpdateOperator } from 'Modules/operators';

const useOperatorForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingOperator = useStateSelector((state) => state.operators.loading.isInsertingOperator);
  const isUpdatingOperator = useStateSelector((state) => state.operators.loading.isUpdatingOperator);
  const isDeletingOperator = useStateSelector((state) => state.operators.loading.isDeletingOperator);

  function insertOperator(operator: CreateOperator) {
    return dispatch(operatorsActions.async.insertOperator(operator))
      .then((operator: OperatorModel) => {
        toast.success('Operator successfully created.');
        return operator;
      });
  }

  function updateOperator(operatorId: number, operator: UpdateOperator) {
    return dispatch(operatorsActions.async.updateOperator(operatorId, operator))
      .then((operator: OperatorModel) => {
        toast.success('Operator successfully saved.');
        return operator;
      });
  }

  function deleteOperator(operator: OperatorModel) {
    return dispatch(operatorsActions.async.deleteOperator(operator))
      .then((operator: OperatorModel) => {
        toast.success('Operator successfully deactivated.');
        return operator;
      });
  }

  function cancelOperatorModification() {
    dispatch(operatorsActions.cancelOperatorModification());
  }

  return {
    insertOperator,
    updateOperator,
    deleteOperator,
    cancelOperatorModification,
    loading: {
      isInsertingOperator,
      isUpdatingOperator,
      isDeletingOperator,
    },
  };
};

export default useOperatorForm;
