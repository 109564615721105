import React from 'react';
import { Collapse } from 'reactstrap';
import { DebounceInputProps, SearchInputGroup } from 'Components';

interface Props extends DebounceInputProps {
  isShowing: boolean;
  isLoading?: boolean;
}

const SearchInputCollapse = ({ isShowing, isLoading, ...props }: Props) => {
  return (
    <Collapse isOpen={isShowing}>
      <SearchInputGroup
        isLoading={isLoading}
        {...props}
      />
    </Collapse>
  );
};

export default SearchInputCollapse;
