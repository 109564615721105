import React from 'react';
import { LibraryFile } from '../models';
import LibraryFileTile from '../Pages/Documents/Library/LibraryFileTile/LibraryFileTile';

interface Props {
  libraryFile: LibraryFile;
}

const FileCard = ({ libraryFile }: Props) => (
  <div className="widget-simple text-center card-box p-0 position-relative">
    <LibraryFileTile libraryFile={libraryFile}/>
  </div>
);

export default React.memo(FileCard);
