import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';

import { canLoadMore } from 'core';
import { usePaginatedPolls } from 'Modules/polls/pagination';
import { DomClass, ListPanel, ListPanelHeader, NavMenuButton, Panel, SplitPanel } from 'Components';

import CreatePollPanel from './CreatePollPanel';
import ModifyPollPanel from './ModifyPollPanel';


const PollMaintenancePage = () => {
  const { data: polls, pagination, filterText, setFilterText, loadMore, isSearching } = usePaginatedPolls({ searchOnMount: true });

  return (
    <>
      <Helmet>
        <title>Poll Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 1,
            Component: (
              <>
                <ListPanel
                  Header={(
                    <ListPanelHeader>Polls ({_.size(polls)})</ListPanelHeader>
                  )}
                  isLoading={isSearching}
                  search={{
                    inputProps: { autoFocus: true },
                    isLoading: isSearching,
                    value: filterText,
                    onChange: setFilterText,
                  }}
                  loadMore={{
                    hasMore: canLoadMore(pagination),
                    pagination: pagination,
                    onClick: loadMore,
                  }}
                  Tools={(
                    <Button tag={Link} to="/admin/polls/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                >
                  <div className="p-1">
                    {
                      _.map(polls, (poll) => {
                        return (
                          <NavMenuButton key={poll.id} to={`/admin/polls/${poll.id}`}>
                            {poll.name}
                          </NavMenuButton>
                        );
                      })
                    }
                  </div>
                </ListPanel>
              </>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/polls/create" component={CreatePollPanel} />
                <Route exact path="/admin/polls/:pollId([0-9]+)" component={ModifyPollPanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default PollMaintenancePage;
