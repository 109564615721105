import { CombinedState, combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import auditsReducer, { AuditState } from './audits/reducer';
import categoriesReducer, { CategoriesState } from './categories/reducer';
import dashboardReducer, { DashboardState } from './dashboard/reducer';
import libraryReducer, { LibraryState } from './library/reducer';
import messagesReducer, { MessagesState } from './messages/reducer';
import { operatorsReducer, OperatorsState } from './operators';
import programsReducer, { ProgramsState } from './programs/reducer';
import standardsReducer, { StandardsState } from './standards/reducer';
import tasksReducer, { TasksState } from './tasks/reducer';
import templatesReducer, { TemplatesState } from './templates/reducer';
import userNotificationsReducer, { UserNotificationsState } from './userNotifications/reducer';
import usersReducer, { UsersState } from './users/reducer';
import { operatorGroupsReducer, OperatorGroupsState } from './operatorGroups';
import { referenceMaterialsReducer, ReferenceMaterialsState } from './referenceMaterials';
import { siteReducer, SiteState } from './site';
import { shareBoardReducer } from './shareBoard';
import { ShareBoardState } from './shareBoard/slice';
import { feedbackReducer } from './feedback';
import { FeedbackState } from './feedback/slice';
import { pollsReducer } from './polls';
import { peerOperatorReducer } from './peerOperators';
import { rolesReducer, RolesState } from './roles';
import { PollsState } from './polls/slice';
import { operatorAttributesReducer, OperatorAttributesState } from './operatorAttributes';
import { shareBoardCategoryTagsReducer, ShareBoardCategoryTagsState } from './shareBoardCategoryTags';
import { PeerOperatorState } from './peerOperators/slice';
// --PlopReducerImport--

export interface RootState {
  audits: AuditState;
  categories: CategoriesState;
  dashboard: DashboardState;
  feedback: FeedbackState;
  library: LibraryState;
  messages: MessagesState;
  operatorAttributes: OperatorAttributesState;
  operatorGroups: OperatorGroupsState;
  operators: OperatorsState;
  peerOperators: PeerOperatorState;
  polls: PollsState;
  programs: ProgramsState;
  referenceMaterials: ReferenceMaterialsState;
  roles: RolesState;
  router: RouterState;
  shareBoard: ShareBoardState;
  shareBoardCategoryTags: ShareBoardCategoryTagsState;
  site: SiteState;
  standards: StandardsState;
  tasks: TasksState;
  templates: TemplatesState;
  userNotifications: UserNotificationsState;
  users: UsersState;
// --PlopReducerInterface--
}

export default (history: any): Reducer<CombinedState<RootState>> => combineReducers<RootState>({
  audits: auditsReducer,
  categories: categoriesReducer,
  dashboard: dashboardReducer,
  feedback: feedbackReducer,
  library: libraryReducer,
  messages: messagesReducer,
  operatorAttributes: operatorAttributesReducer,
  operatorGroups: operatorGroupsReducer,
  operators: operatorsReducer,
  peerOperators: peerOperatorReducer,
  polls: pollsReducer,
  programs: programsReducer,
  referenceMaterials: referenceMaterialsReducer,
  roles: rolesReducer,
  router: connectRouter(history),
  shareBoard: shareBoardReducer,
  shareBoardCategoryTags: shareBoardCategoryTagsReducer,
  site: siteReducer,
  standards: standardsReducer,
  tasks: tasksReducer,
  templates: templatesReducer,
  userNotifications: userNotificationsReducer,
  users: usersReducer,
// --PlopReducerExport--
});
