import React from 'react';
import TaskStatusType from '../constants/TaskStatusType';
import { Badge } from 'reactstrap';

interface LocalProps {
  status?: TaskStatusType;
}

const TaskStatusBadge = ({ status }: LocalProps) => {
  if (!status) {
    return null;
  }

  return (
    <div>
      {status === TaskStatusType.Created && <Badge color="light">Created</Badge>}
      {status === TaskStatusType.PendingApproval && <Badge color="info">Pending Approval</Badge>}
      {status === TaskStatusType.Complete && <Badge color="success">Complete</Badge>}
      {status === TaskStatusType.Rejected && <Badge color="danger">Rejected</Badge>}
      {status === TaskStatusType.NotApplicable && <Badge color="secondary">Not Applicable</Badge>}
    </div>
  );
};

export default React.memo(TaskStatusBadge);
