import React from 'react';
import AutoUploadFileDropzone from '../../Components/AutoUploadFileDropzone';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Modules/reducers';
import { AssignedTaskModel } from '../../models';
import _ from 'lodash';
import toastr from 'toastr';
import Swal from 'sweetalert2';
import { uploadTaskFiles } from '../../Modules/library/actions';

interface Props {
  task: AssignedTaskModel;
  onFileUploaded?: () => void;
}

const TaskFileDropzone = ({ task, ...props }: Props) => {
  const dispatch = useDispatch();
  const isUploading = useSelector((state: RootState) => state.library.loading.isUploadingFiles);

  function handleUploadTaskFiles (selectedFiles: File[]): Promise<void> {
    return new Promise((resolve) => {
      const { taskId } = task;

      if (_.isEmpty(selectedFiles)) {
        toastr.error('You must select a file to upload.');
        return;
      }

      dispatch(uploadTaskFiles(taskId, selectedFiles, () => {
        resolve();

        if(props.onFileUploaded) {
          props.onFileUploaded();
        }

        (async () => {
          await Swal.fire({
            title: 'File Uploaded',
            text: 'Your file has been successfully uploaded',
            icon: 'success',
            confirmButtonText: 'Okay',
          });
        })();
      }));
    });
  }

  return (
    <AutoUploadFileDropzone isUploading={isUploading} uploadFiles={handleUploadTaskFiles} />
  );
};

export default TaskFileDropzone;
