import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import { PaginatedResult, TermSearchRequest } from 'core';
import appConfig from 'config';
import { RootState } from 'Modules/reducers';
import { CreateRole, PermissionDetail, Role, rolesActions, rolesSlice, RolesState, UpdateRole } from 'Modules/roles';

const rolesLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: RolesState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(rolesSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getRole = (roleId: number): ThunkAction<Promise<Role>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState, isLoading) => rolesState.loading.isLoadingRole = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/roles/${roleId}`),
    onSuccess: (data: Role) => {
      dispatch(rolesActions.setRole(data));
    },
  });
};

export const getPermissionsByRole = (roleId: number): ThunkAction<Promise<PermissionDetail[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState, isLoading) => rolesState.loading.isLoadingRole = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/roles/${roleId}/permissions`),
    onSuccess: (data: PermissionDetail[]) => {
      dispatch(rolesActions.setRolePermissions({ roleId, permissions: data }));
    },
  });
};

export const searchRoles = (request: TermSearchRequest): ThunkAction<Promise<PaginatedResult<Role>>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState, isLoading) => rolesState.loading.isSearchingRoles = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/roles/search`).query(request),
    onSuccess: (data: PaginatedResult<Role>) => {
      dispatch(rolesActions.setSearchedRoles(data));
    },
  });
};

export const getAllPermissions = (): ThunkAction<Promise<PermissionDetail[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState, isLoading) => rolesState.loading.isSearchingRoles = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/permissions`),
    onSuccess: (data: PermissionDetail[]) => {
      dispatch(rolesActions.setAllPermissions(data));
    },
  });
};

export const insertRole = (request: CreateRole): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState: RolesState, isLoading: boolean) => rolesState.loading.isInsertingRole = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/roles`).send(request),
    onSuccess: (data: Role) => {
      dispatch(rolesActions.onRoleInserted(data));
    },
  });
};

export const updateRole = (roleId: number, request: UpdateRole): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState: RolesState, isLoading: boolean) => rolesState.loading.isUpdatingRole = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.put(`${appConfig.baseUrl}/api/v1/roles/${roleId}`).send(request),
    onSuccess: (data: Role) => {
      dispatch(rolesActions.onRoleUpdated(data));
    },
  });
};

export const deleteRole = (role: Role): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: rolesLoadingHandler(dispatch, (rolesState: RolesState, isLoading: boolean) => rolesState.loading.isDeletingRole = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.delete(`${appConfig.baseUrl}/api/v1/roles/${role.id}`),
    onSuccess: () => {
      dispatch(rolesActions.onRoleDeleted(role));
    },
  });
};
