import React, { ReactNode } from 'react';
import { Button, ModalHeader, ModalHeaderProps } from 'reactstrap';
import FlexRow from './FlexRow';
import classnames from 'classnames';

interface LocalProps extends ModalHeaderProps {
  children?: ReactNode;
  hide: () => void;
}

const ModalHeaderWithClose = ({ children, hide, ...props }: LocalProps) => {
  return (
    <ModalHeader {...props} className={classnames('with-close', props.className)}>
      <FlexRow fill childSpacingX={1}>
        <span className="flex">
          {children}
        </span>
        <span>
          <Button
            color="anchor"
            onClick={hide}
          >
            <i className="mdi mdi-24px mdi-close" />
          </Button>
        </span>
      </FlexRow>
    </ModalHeader>
  );
};

export default React.memo(ModalHeaderWithClose);
