import { compose } from 'redux';
import _ from 'lodash';

import { Action, ReducerFunction } from 'models';
import makeLoadingReducer from 'Modules/enhancers/makeLoadingReducer';
import loadingConfigs from './loading';
import { OperatorMessage, OperatorMessageResponse } from './models';
import { messagesActions } from './actions';
import {
  convertPaginatedResponse,
  convertPaginatedSearchResultToState,
  PaginatedSearchResponse,
  PaginationResultState,
  updatePaginationOnAdd,
} from '../../helpers/pagination';
import { convertToOperatorMessageFromResponse } from './instantiations';
import { siteActions } from '../site';

export interface MessagesState {
  entities: {
    operatorMessages: { [operatorMessageId: number]: OperatorMessage };
  };
  mappings: {
  };
  ui: {
    operatorMessagePagination?: PaginationResultState;
    operatorMessageIds: number[];
  };
  loading: {
    isSearchingOperatorMessages: boolean;
    isInsertingOperatorMessage: boolean;
  };
}

const initState: MessagesState = {
  entities: {
    operatorMessages: {},
  },
  ui: {
    operatorMessagePagination: undefined,
    operatorMessageIds: [],
  },
  mappings: {
  },
  loading: {
    isSearchingOperatorMessages: false,
    isInsertingOperatorMessage: false,
  },
};

export default compose<ReducerFunction<MessagesState>>(
  makeLoadingReducer<MessagesState>({ loadingKey: 'loading', loadingConfigs: loadingConfigs })
)((state = initState, action: Action): MessagesState => {
  switch (action.type) {
    case messagesActions.SEARCH_OPERATOR_MESSAGES_SUCCESS: {
      const { response }: { response: PaginatedSearchResponse<OperatorMessageResponse>; } = action.payload;
      const { pagedResult } = convertPaginatedResponse(response, convertToOperatorMessageFromResponse);

      return {
        ...state,
        entities: {
          ...state.entities,
          operatorMessages: {
            ...state.entities.operatorMessages,
            ..._.keyBy(pagedResult.items, i => i.operatorMessageId),
          },
        },
        ui: {
          ...state.ui,
          operatorMessagePagination: convertPaginatedSearchResultToState(pagedResult),
          operatorMessageIds: _.concat(pagedResult.currentPage > 1 ? state.ui.operatorMessageIds || [] : [], _.map(pagedResult.items, i => i.operatorMessageId)),
        },
      };
    }
    case messagesActions.REALTIME_OPERATOR_MESSAGE_RECEIVED:
    case messagesActions.INSERT_OPERATOR_MESSAGE_SUCCESS: {
      const { message }: { message: OperatorMessageResponse } = action.payload;

      const updatedPagination = updatePaginationOnAdd(state.ui.operatorMessagePagination);

      if (!updatedPagination) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          operatorMessages: {
            ...state.entities.operatorMessages,
            [message.operatorMessageId]: convertToOperatorMessageFromResponse(message),
          },
        },
        ui: {
          ...state.ui,
          operatorMessagePagination: updatedPagination,
          operatorMessageIds: _.uniq(_.concat(message.operatorMessageId, state.ui.operatorMessageIds)),
        },
      };
    }
    case siteActions.setSelectedOperator.type: {
      return initState;
    }
    default: {
      return state;
    }
  }
});
