import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { BackgroundImage, ClickableDiv, FlexColumn } from '../../Components/index';
import DefaultImage from '../../assets/img/default-share-board-category.png';
import { ShareBoardCategory, ShareBoardFile } from '../../Modules/shareBoard/models';

interface Props {
  categories: ShareBoardCategory[];
  allShareBoardFiles: ShareBoardFile[];
  setShareBoardCategory: (category: ShareBoardCategory | null) => void;
}

const ShareBoardCategoryListView = ({ categories, allShareBoardFiles, ...props }: Props) => {
  return (
    <Row>
      {[ ...categories, null ].map((category) => {
        const filesForCategory = allShareBoardFiles.filter((file) => {
          if(category === null) {
            return file.categories.length === 0;
          }

          return file.categories.some((fileCategory) => fileCategory.id === category?.id);
        });
        return (
          <Col key={category?.id || 'unspecified'} className="col-md-12 mb-2">
            <ClickableDiv
              className="card h-100"
              onClick={() => props.setShareBoardCategory(category)}
            >
              <CardBody className="d-flex flex-row align-items-center p-0 child-spacing-x-1">
                <div className="px-2">
                  <BackgroundImage
                    style={{ height: 50, width: 50 }}
                    src={category?.imageFile?.sasUrl ?? DefaultImage}
                  />
                </div>
                <FlexColumn fill justifyCenter>
                  <h4 className="m-0">{category?.name || <em>Unspecified Category</em>}</h4>
                  <p className="m-0">{filesForCategory.length} files</p>
                </FlexColumn>
              </CardBody>
            </ClickableDiv>
          </Col>
        );
      })}
    </Row>
  );
};

export default ShareBoardCategoryListView;
