import React from 'react';
import { Column } from 'react-table';

import BaseTable from 'Components/BaseTable/BaseTable';

export interface MoveToFolderFileAccessors<T> {
  name: (row: T) => string;
  originalFilename: (row: T) => string;
  filePath: (row: T) => string;
}

interface LocalProps<T> {
  files: T[];
  accessors: MoveToFolderFileAccessors<T>;
}

const MoveToFolderFileTable = <T extends any>({ files, accessors }: LocalProps<T>) => {
  const columns: Column<T>[] = [
    {
      Header: 'Name',
      id: 'name',
      accessor: accessors.name,
      width: 210,
    },
    {
      Header: 'Original Name',
      id: 'originalName',
      accessor: accessors.originalFilename,
      width: 210,
    },
    {
      Header: 'Current Location',
      id: 'currentLocation',
      accessor: accessors.filePath,
    },
  ];

  return (
    <div className="bg-white">
      <BaseTable
        data={files}
        minRows={10}
        columns={columns}
        defaultSorted={[ { id: 'name', desc: false } ]}
      />
    </div>
  );
};

export default MoveToFolderFileTable;
