import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { BackgroundImage, ClickableDiv, FlexColumn } from '../../Components/index';
import DefaultImage from '../../assets/img/default-share-board-category.png';
import { ShareBoardCategory, ShareBoardFile } from '../../Modules/shareBoard/models';

interface Props {
  categories: ShareBoardCategory[];
  allShareBoardFiles: ShareBoardFile[];
  setShareBoardCategory: (category: ShareBoardCategory | null) => void;
}

const ShareBoardCategoryTileView = ({ categories, allShareBoardFiles, ...props }: Props) => {
  return (
    <Row>
      {[ ...categories, null ].map((category) => {
        const filesForCategory = allShareBoardFiles.filter((file) => {
          if(category === null) {
            return file.categories.length === 0;
          }

          return file.categories.some((fileCategory) => fileCategory.id === category?.id);
        });
        return (
          <Col key={category?.id || 'unspecified'} className="col-md-6 col-lg-4 col-xl-3 mb-2">
            <ClickableDiv
              className="card h-100"
              onClick={() => props.setShareBoardCategory(category)}
            >
              <CardBody className="d-flex flex-column justify-content-between align-items-center child-spacing-y-2">
                <div>
                  <BackgroundImage
                    style={{ height: 200, width: 200 }}
                    src={category?.imageFile?.sasUrl ?? DefaultImage}
                  />
                </div>
                <FlexColumn fill justifyCenter>
                  <h4 className="text-center m-0">{category?.name || <em>Unspecified Category</em>}</h4>
                  <p className="text-center m-0">{filesForCategory.length} files</p>
                </FlexColumn>
              </CardBody>
            </ClickableDiv>
          </Col>
        );
      })}
    </Row>
  );
};

export default ShareBoardCategoryTileView;
