import React from 'react';
import _ from 'lodash';
import { AuditTemplate, TemplateTaskModel } from 'models';

import TemplateWeek from './TemplateWeek';

interface Props {
  template: AuditTemplate;
  templateTasks: TemplateTaskModel;
}

const TemplateWeeks = ({ template, templateTasks }: Props) => {
  return (
    <div>
      {
        _.map([ ...new Array(template.numWeeks) ], (data, index) => {
          const weekIndex = index + 1;

          const tasks = _.get(templateTasks.tasksPerWeek, weekIndex, []);

          return (
            <React.Fragment key={weekIndex}>
              <TemplateWeek template={template} weekIndex={weekIndex} tasks={tasks}/>
              <hr/>
            </React.Fragment>
          );
        })
      }
    </div>
  );
};

export default TemplateWeeks;
