import React, { useEffect } from 'react';
import _ from 'lodash';
import { operatorsActions, operatorsSelectors } from 'Modules/operators';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ClickableDiv from 'Components/ClickableDiv';
import { OperatorModel } from 'models';
import { siteActions } from 'Modules/site';
import useThunkDispatch from 'hooks/useThunkDispatch';
import PageLayout from 'App/PageLayout';
import classnames from 'classnames';
import useStateSelector from 'hooks/useStateSelector';

const SelectOperatorPage = () => {
  const dispatch = useThunkDispatch();
  const currentUserOperators = useStateSelector(operatorsSelectors.getCurrentUserOperators);
  const selectedOperator = useStateSelector(state => state.site.selectedOperator);

  useEffect(() => {
    dispatch(operatorsActions.async.getCurrentUserOperators());
  }, []);

  function selectOperator(operator: OperatorModel) {
    dispatch(siteActions.async.setUserSelectedOperator(operator.operatorId));
  }

  return (
    <PageLayout pageTitle="Select an Operator">
      <Container>
        <Row>
          {
            _.map(currentUserOperators, (operator) => (
              <Col className="p-1" key={operator.operatorId} md={4}>
                <Card className={classnames('d-flex selectable', { 'selected': selectedOperator?.operatorId === operator.operatorId })} style={{ height: 200 }} onClick={() => selectOperator(operator)} tag={ClickableDiv}>
                  <CardBody className="d-flex justify-content-center align-items-center flex">
                    {operator.name}
                  </CardBody>
                </Card>
              </Col>
            ))
          }
        </Row>
      </Container>
    </PageLayout>
  );
};

export default SelectOperatorPage;
