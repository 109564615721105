import React from 'react';
import { AuditTemplate } from 'models';
import { RootState } from '../../Modules/reducers';
import { connect } from 'react-redux';
import { deleteAuditTemplate } from '../../Modules/templates/actions';
import { stopPropagationPreventDefaultAnd } from '../../helpers/eventHelpers';
import BaseTable from '../../Components/BaseTable/BaseTable';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { Column } from 'react-table';

interface LocalProps {
  templates: AuditTemplate[];
  selectedTemplateId?: number;
  selectTemplate: (template: AuditTemplate, selected: boolean) => void;
}

interface StateProps {}

interface DispatchProps {
  deleteAuditTemplate: typeof deleteAuditTemplate;
}

type Props = LocalProps & StateProps & DispatchProps;

class TemplatesTable extends React.Component<Props> {
  private deleteAuditTemplate = ({ auditTemplateId }: AuditTemplate) => {
    Swal.fire({
      title: 'Delete Template',
      text: 'Are you sure you want to delete this Template?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(({ dismiss }) => {
      if (dismiss) {
        return;
      }

      this.props.deleteAuditTemplate(auditTemplateId);
    });
  }

  private renderDelete = ({ original }: { original: AuditTemplate }) => {
    return (
      <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => this.deleteAuditTemplate(original))}><i className="fa fa-trash" /></Button>
    );
  }

  private columns: Column<AuditTemplate>[] = [
    {
      Header: 'Id',
      accessor: 'auditTemplateId',
      width: 45,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
    },
    {
      Header: '# Weeks',
      accessor: 'numWeeks',
      width: 90,
    },
    {
      Header: 'Delete',
      Cell: this.renderDelete,
      width: 90,
    },
  ];

  public render () {
    const { templates, selectedTemplateId } = this.props;

    return (
      <div className="bg-white">
        <BaseTable
          data={templates}
          columns={this.columns}
          select={{
            rowClick: true,
            selected: selectedTemplateId ? { [selectedTemplateId]: true } : {},
            onSelected: this.props.selectTemplate,
            idAccessor: (template: AuditTemplate) => template.auditTemplateId,
          }}
          defaultSorted={[ { id: 'name', desc: false } ]}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  deleteAuditTemplate,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplatesTable);
