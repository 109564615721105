import _ from 'lodash';
import { Dispatch, MiddlewareAPI } from 'redux';
import { LocationChangeAction, LocationChangePayload } from 'connected-react-router';

import { commonActions } from 'Modules/common/actions';
import { RootState } from 'Modules/reducers';

const areaRegex = /^\/([^/]+)/;

const getAreaFromPathname = (pathname?: string): string | undefined => {
  if (!pathname) {
    return undefined;
  }

  const result = areaRegex.exec(pathname);

  return _.has(result, 1) ? result![1] : undefined;
};

export interface PageChangePayload extends LocationChangePayload {
  lastPathname?: string;
  lastArea?: string;
  nextArea?: string;
  areaChanged: boolean;
}

const pageChangeMiddleware = ({ dispatch, getState }: MiddlewareAPI<any, RootState>) => (next: Dispatch) => async (action: LocationChangeAction) => {
  next(action);

  if (action.type !== commonActions.LOCATION_CHANGE) {
    return;
  }

  const { site: { lastPathname } } = getState();

  if (lastPathname !== action.payload.location.pathname) {
    const lastArea = getAreaFromPathname(lastPathname);
    const nextArea = getAreaFromPathname(action.payload.location.pathname);
    const areaChanged = lastArea !== nextArea;

    const pageChangePayload: PageChangePayload = {
      ...action.payload,
      lastPathname,
      lastArea,
      nextArea,
      areaChanged,
    };

    dispatch({
      type: commonActions.PATH_LOCATION_CHANGE,
      payload: pageChangePayload,
    });

    if (areaChanged) {
      dispatch({
        type: commonActions.AREA_LOCATION_CHANGE,
        payload: pageChangePayload,
      });
    }
  }
};

export default pageChangeMiddleware;
