import React from 'react';

import ScrollPanel from './ScrollPanel';

export interface Props extends React.HTMLProps<HTMLDivElement> {
  ref: React.Ref<HTMLDivElement>;
}

const VerticalOnlyScrollPanel = React.forwardRef<HTMLDivElement, Props>(({ children, ref, ...props }, forwardedRef) => {
  return (
    <ScrollPanel ref={forwardedRef} {...props} verticalOnly>
      {children}
    </ScrollPanel>
  );
});

export default VerticalOnlyScrollPanel;
