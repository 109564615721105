import React, { useEffect } from 'react';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { useStateSelector } from 'hooks';
import { CategoryModel } from 'Modules/categories/models';
import { getAllCategories, insertCategory } from 'Modules/categories/actions';

interface Props extends Omit<ReactSelectProps, 'value'> {
  value?: string;
}

const CategorySearch = ({ value, ...props }: Props) => {
  const dispatch = useDispatch();

  const categories = useStateSelector(state => _.map(state.categories.ui.allCategoryIds, (c) => state.categories.entities.categories[c]));
  const isLoadingAllCategories = useStateSelector(state => state.categories.loading.isLoadingAllCategories);
  const isInsertingCategory = useStateSelector(state => state.categories.loading.isInsertingCategory);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  function createCategoryFromName(name: string) {
    dispatch(insertCategory({ name }, (category) => {
      props.onChange(category);
    }));
  }

  const category = _.find(categories, c => c.categoryId === value);

  return (
    <CreatableSelect<CategoryModel>
      {...props}
      isLoading={isInsertingCategory || isLoadingAllCategories}
      getOptionLabel={c => c.name}
      getOptionValue={c => c.categoryId}
      value={category}
      getNewOptionData={c => ({ categoryId: '', name: c })}
      options={categories}
      formatCreateLabel={c => <span>Create Category: <em>{c}</em></span>}
      onCreateOption={createCategoryFromName}
    />
  );
};

export default CategorySearch;
