import React, { useEffect, useState } from 'react';
import { callApi } from 'api/ApiDao';
import { useSelector } from 'react-redux';
import { getAccessToken } from 'Modules/site/selectors';
import { SuperAgent, SuperAgentRequest } from 'superagent';

interface Props {
  id: number;
  getRequest: (superagent: SuperAgent<any>) => SuperAgentRequest;
}

const styles = {
  width: '100%',
  maxWidth: 400,
};

const MessageFile = ({ id, getRequest }: Props) => {
  const [ streamUrl, setStreamUrl ] = useState<string | undefined>();
  const [ isLoading, setIsLoading ] = useState(false);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    setIsLoading(true);
    callApi<string>({
      apiToken: accessToken,
      getRequest: getRequest,
      onSuccess: (streamUrl, response) => {
        setStreamUrl(response.text);
      },
      onComplete: () => {
        setIsLoading(false);
      },
    });
  }, [ accessToken, id ]);

  if (isLoading) {
    return (
      <i className="mdi mdi-spin mdi-refresh" />
    );
  }

  return (
    <div>
      <img alt="Message File" src={streamUrl} style={styles} />
    </div>
  );
};

export default MessageFile;
