import { Action as ReduxAction } from 'redux';
import UserXrefType from './constants/UserXrefType';
import { Response, SuperAgent } from 'superagent';
import ActionType from './constants/ActionType';
import TaskStatusType from './constants/TaskStatusType';
import { RootState } from './Modules/reducers';
import { ThunkAction } from 'redux-thunk';
import { File, ThumbnailFile } from 'Modules/files/models';
import { Permission, Role } from 'Modules/roles';

export type ReactChildren = React.ReactNode | React.ReactNode[];

export interface Action extends ReduxAction {
    payload?: any;
}

export interface EnumOption<T> {
    label: string;
    value: T;
}

export type ReducerFunction<T> = (state: T | undefined, action: Action) => T;

export interface AssignedTaskModel {
    taskId: number;
    parentTaskId?: number;
    operatorAuditId: number;
    assignedToUserId?: string;
    operatorId: number;
    taskDate?: string;
    summary: string;
    description?: string;
    numFiles: number;
    numMessages: number;
    numStatusChanges: number;
    categoryId?: string;
    templateTaskId?: number;
    standardId?: number;
    standardReferenceId?: number;
    standardName?: string;
    standardReferenceDescription?: string;
    standardReferenceLookup?: string;
    actionTypeId: number;
    position: number;
    currentStatus: TaskStatusType;
    uploadToDirectoryPath?: string;
    isParent: boolean;
    required: boolean;

    displayTaskDate: string;
    parsedTaskDate?: Date;
    isThisWeek: boolean;
    isFutureWeek: boolean;
    isPastDue: boolean;
    hasUnreadNotifications: boolean;
}

export interface AuditWeekModel {
    weekIndex: number;
    startDate: string;
    endDate: string;

    parsedStartDate: Date;
    parsedEndDate: Date;
    isCurrentWeek: boolean;
    displayStartDate: string;
    displayEndDate: string;
    displayShortStartDate: string;
    displayShortEndDate: string;
}

export interface OperatorAuditWeekTasksModel extends AuditWeekModel{
    tasks: AssignedTaskModel[];
}

export interface OperatorAuditTasks extends OperatorAuditModel{
    numWeeklyTasks: number;
    numPersistentTasks: number;
    persistentTasks: AssignedTaskModel[];
    weeks: OperatorAuditWeekTasksModel[];
}

export interface DashboardOperatorModel {
    operatorId: number;
    name: string;
    safetyCoaches: UserModel[];
}

export interface UserModel {
    userId: string;
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    termsOfServiceAgreedOn?: string;
    accountEnabled: boolean;
    roles: Role[];
    profilePictureFile?: ThumbnailFile;
}

export interface UserOperatorModel extends UserModel {
    userXrefTypeId: UserXrefType;
}

export interface AdminOperatorModel extends OperatorModel {
    users: UserOperatorModel[];
    safetyCoaches: UserOperatorModel[];
    readOnlyUsers: UserOperatorModel[];
    accounts: UserOperatorModel[];
    numSafetyCoaches: number;
    numAccounts: number;
}

export interface LoginUserModel {
    userId: string;
    firstName: string;
    lastName: string;
    displayName?: string;
    email: string;
    accountEnabled: boolean;
    termsOfServiceAgreedOn?: string;
    operatorUserXrefs: OperatorUserXrefModel[];
    operators: OperatorModel[];
    permissions: Permission[];
    profilePictureFile?: ThumbnailFile;
}

export interface OperatorAuditModel {
    operatorAuditId: number;
    operatorId: number;
    startPrepDate: string;
    auditDate: string;
    weeks: AuditWeekModel[];
    daysUntilAudit: number;
    totalPrepDays: number;
    percentComplete: number;

    parsedAuditDate: Date;
    parsedStartPrepDate: Date;
    displayStartPrepDate: string;
    displayAuditDate: string;
}

export enum DictionaryType {
    SafetyCoach = 1,
    FAA = 2,
    Auditor = 3,
    Consultant = 4,
}

export interface OperatorModel {
    operatorId: number;
    name: string;
    nextAuditId?: number;
    nextAuditDate?: string;
    programId?: number;
    programName?: string;
    dictionaryTypeId: DictionaryType;
    dictionaryTypeName: string;
    imageFile?: ThumbnailFile;
}

export interface OperatorUserXrefModel {
    operatorUserXrefId: number;
    operatorId: number;
    operatorName: string;
    userId: string;
    userXrefTypeId: UserXrefType;
}

export interface SelectOption {
    label: string;
    value: any;
}

export interface LibraryFile {
    libraryFileId: number;
    shareBoardFileId?: number;
    thumbnailFile?: ThumbnailFile;
    file: File;
    directoryPath: string;
    fileName: string;
    filePath: string;
    sizeInMb: number;
    createdTimestamp: string;
    createdUser: string;
    lastChangedTimestamp?: string;
    lastChangedUser?: string;
    deletedUser?: string;
    deletedTimestamp?: string;
    taskId?: number;
    operatorId: number;
    uploadedBySafetyCoach: boolean;
}

export interface ApiError {
    validationError: boolean;
    message: string | null;
    error: any;
    response: Response;
}

export interface ApiPayload {
    shouldRunRequest?: (state: RootState) => false | any;
    getRequest: (superagent: SuperAgent<any>) => any;
    beforeRequest: () => Action;
    success: (data: any, response: Response) => Action | (Action | ThunkAction<any, any, any, any>)[] | ThunkAction<any, any, any, any>;
    successCallback?: (data: any) => void;
    failure: (response: ApiError) => Action;
}

export interface ApiAction extends Action {
    payload: ApiPayload;
}

export interface DependantApiAction extends Action {
    payload: {
        shouldRunRequest?: (state: RootState) => false | any;
        apiActions: ((previousResolvedData: any) => ApiAction)[];
        beforeRequest: () => Action;
        success: (data: any) => Action;
        failure: () => Action;
    };
}

export interface FileDownloadAction extends Action {
    file: Blob;
    filename: string;
}

export interface OperatorDashboardModelResponse {
    userId: string;
    operator: DashboardOperatorModel;
    operatorNextAudit?: OperatorAuditModel;
    operatorAuditTasks: AssignedTaskModel[];
}

export interface SafetyCoachDashboardResponse {
    userId: string;
    operator: OperatorModel;
    operatorNextAudit?: OperatorAuditModel;
    operatorAuditTasks: AssignedTaskModel[];
}

export interface UserOperatorDashboardModel {
    operatorId: number;
    operatorNextAuditId?: number;
    operatorSafetyCoaches: UserModel[];
}

export interface UserSafetyCoachDashboardModel {
    operatorId: number;
    operatorNextAuditId?: number;
}

export interface BaseOperatorDashboardModel {
    nextAudit?: OperatorAuditModel;
    nextAuditTasks: AssignedTaskModel[];
    numTasksForThisWeek: number;
    tasksForThisWeek: AssignedTaskModel[];
    pastDueNeedingFeedbackTasks: AssignedTaskModel[];
    pastDueNeedingApprovalTasks: AssignedTaskModel[];
    persistentTasks: AssignedTaskModel[];
}

export interface OperatorDashboardCurrentWeek extends BaseOperatorDashboardModel{
    safetyCoaches: UserModel[];
}

export interface SafetyCoachOperatorDashboardModel extends BaseOperatorDashboardModel{
}

export interface TaskDetailsModel {
    uploadedFiles: LibraryFile[];
    taskStatusHistory: TaskStatusModel[];
}

export interface TaskMessageModel {
    taskMessageId: number;
    taskId: number;
    createdUser: string;
    createdTimestamp: string;
    messageText: string;
    createdByUser: UserModel;
}

export interface TaskMessageFile {
    id: number;
    fileId: number;
    taskId: number;
}

export interface TaskStatusModel {
    taskStatusId: string;
    taskId: number;
    statusUpdate?: string;
    status: TaskStatusType;
    statusDescription: string;
    createdUser: string;
    createdByUser: UserModel;
    createdTimestamp: string;
}

export interface AuditTemplate {
    auditTemplateId: number;
    name: string;
    numWeeks: number;
    templateData: AuditTemplateData | null;
    createdTimestamp: string;
    createdUser: string;
    lastChangedTimestamp: string | null;
    lastChangedUser: string | null;
    deletedUser: string | null;
    deletedTimestamp: string | null;
}

export interface CreateAuditTemplate {
    auditTemplateId?: number;
    name: string;
    numWeeks: number;
}

export interface AuditTemplateData {
    templateTasks: TemplateTask[];
}

export interface TemplateTask {
    templateTaskId: number;
    auditTemplateId: number;
    weekIndex?: number;
    isParent?: boolean;
    parentTemplateTaskId?: number;
    summary: string;
    description: string;
    position: number;
    actionTypeId: ActionType;
    uploadToDirectoryPath?: string;
    createdTimestamp: string;
    createdUser: string;
    lastChangedTimestamp: string | null;
    lastChangedUser: string | null;
    categoryId?: string;
    standardId?: number;
    standardReferenceId?: number;
    createdByUser: UserModel;
    lastChangedByUser: UserModel;
}

export interface TemplateTaskModel {
    tasksPerWeek: { [weekIndex: number]: TemplateTask[] };
    persistentTasks: TemplateTask[];
    numWeeklyTasks: number;
    numPersistentTasks: number;
}

export interface CreateTemplateTask {
    templateTaskId: number;
    isParent: boolean;
    parentTemplateTaskId?: number;
    auditTemplateId: number;
    weekIndex?: number;
    summary: string;
    description: string;
    actionTypeId: ActionType;
    categoryId?: string;
    uploadToDirectoryPath?: string;
    standardId?: number;
    standardReferenceId?: number;
}

export interface UpdateTaskPosition {
    taskId: number;
    position: number;
}

export interface UpdateTemplateTaskPosition {
    templateTaskId: number;
    position: number;
}

export enum SidebarType {
    Chat = 1,
    Notification = 2
}

export interface PaginatedOffsetRequest {
    pageSize: number;
    offset: number;
}

export interface PaginatedOffsetSearchState extends PaginatedOffsetRequest {
    total: number;
}

export enum OperatorFeatureEnum {
    ShareBoard = 1,
    Polling = 2,
}

export enum SortDirectionEnum {
    Ascending = 1,
    Descending = 2,
}
