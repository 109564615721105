import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

const useStringPathId = (routeIdParam: string) => {
  const { params } = useRouteMatch<any>();
  const routeIdString: string = params[routeIdParam];

  if (!_.has(params, routeIdParam) || _.isNil(routeIdString)) {
    return undefined;
  }

  return routeIdString;
};

export default useStringPathId;
