import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import i18n from 'i18next';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';

import { getFileNameWithoutExtension } from 'core';
import { stopPropagationAnd, stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import { useModalState, useStateSelector } from 'hooks';
import { LibraryFile, OperatorFeatureEnum } from 'models';
import * as libraryActions from 'Modules/library/actions';
import { previewFileHandlers } from 'Components/PreviewFileModal/PreviewFileModal';
import PreviewLibraryFileModal from '../PreviewLibraryFileModal';
import { FilePreview, FileTile, FlexRow, HideWithoutFeature, HideWithoutPermission } from 'Components';
import { Permission } from '../../../../Modules/roles/index';

interface Props {
  libraryFile: LibraryFile;
  allowSelection?: boolean;
}

const LibraryFileTile = function ({ libraryFile, allowSelection }: Props) {
  const dispatch = useDispatch();
  const { isShowing: isShowingLibraryPreviewModal, show: showLibraryPreviewModal, hide: hideLibraryPreviewModal } = useModalState();
  const isSelected = useStateSelector(state => _.includes(state.library.ui.operatorSelectedFiles[libraryFile.operatorId], libraryFile.libraryFileId));

  const extension = libraryFile.file.originalFilename.split('.').pop()!;

  const previewIsSupported = _.some(previewFileHandlers, (fh) => {
    return _.includes(fh.extensions, extension);
  });

  function downloadFile() {
    dispatch(libraryActions.downloadLibraryFiles([ libraryFile.libraryFileId ]));
  }

  function selectFile() {
    if (allowSelection) {
      dispatch(libraryActions.selectFile(libraryFile.operatorId, libraryFile.libraryFileId));
    }
  }

  function toggleFileShareBoard() {
    if (!libraryFile.shareBoardFileId) {
      dispatch(libraryActions.postLibraryFilesToShareBoard([ libraryFile.libraryFileId ]));
    } else {
      dispatch(libraryActions.unPostFromShareBoard([ libraryFile.libraryFileId ]));
    }
  }

  function renameFile() {
    (async () => {
      const { dismiss, value: updatedFileName } = await Swal.fire({
        title: 'Rename File',
        text: 'Provide a new name for the file:',
        input: 'text',
        inputValue: (getFileNameWithoutExtension(libraryFile.fileName || '')),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Rename File',
        inputValidator: (value: string) => {
          if (!(value || '').trim()) {
            return 'You must enter a valid file name';
          }

          return null;
        },
      });

      if (dismiss) {
        return;
      }

      dispatch(libraryActions.renameLibraryFile(libraryFile.libraryFileId, updatedFileName));
    })();
  }

  async function deleteFile() {
    const { dismiss } = await Swal.fire({
      title: 'Delete File',
      text: 'Are you sure you want to delete this file. The file will be entirely removed. This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete It',
    });

    if (dismiss) {
      return;
    }

    dispatch(libraryActions.deleteLibraryFile(libraryFile, () => {
      toastr.clear();
      toastr.success('File deleted.');
    }));
  }

  return (
    <>
      <FileTile
        fileName={libraryFile.fileName}
        createdTimestamp={libraryFile.createdTimestamp}
        onTileClick={selectFile}
        Thumbnail={(
          <FilePreview extension={extension} sasUrl={libraryFile.thumbnailFile?.sasUrl} />
        )}
        TopLeft={allowSelection ? (
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(selectFile)}>
                <i className={classnames('mdi mdi-24px', {
                  'mdi-checkbox-blank-outline': !isSelected,
                  'mdi-checkbox-marked-outline': isSelected,
                })}/>
              </Button>
            </div>

            <div>
              <HideWithoutFeature feature={OperatorFeatureEnum.ShareBoard}>
                <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(toggleFileShareBoard)}>
                  <i
                    className={classnames('mdi mdi-24px', {
                      'mdi-cloud-off-outline': !libraryFile.shareBoardFileId,
                      'mdi-cloud-upload': libraryFile.shareBoardFileId,
                    })}
                    title={classnames({
                      'This file is not available in the public share board': !libraryFile.shareBoardFileId,
                      'This file is available in the public share board': libraryFile.shareBoardFileId,
                    })}
                  />
                </Button>
              </HideWithoutFeature>
            </div>
          </FlexRow>
        ) : undefined}
        TopRight={(
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(downloadFile)}>
                <i className="mdi mdi-24px mdi-download"/>
              </Button>
            </div>

            {previewIsSupported && (
              <div>
                <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(showLibraryPreviewModal)}>
                  <i className="mdi mdi-24px mdi-eye"/>
                </Button>
              </div>
            )}

            <HideWithoutPermission permission={Permission.OperatorMaintenance}>
              <div>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="anchor" caret onClick={stopPropagationAnd()}>
                    <i className="mdi mdi-24px mdi-cog"/>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={stopPropagationPreventDefaultAnd(renameFile)}>
                      <i className="mdi mdi-pencil"/> Edit Name
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={stopPropagationPreventDefaultAnd(deleteFile)}>
                      <i className="mdi mdi-trash-can"/> Delete File
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </HideWithoutPermission>
          </FlexRow>
        )}
      >
        <div className="flex-overlay justify-content-end align-items-end pointer-events-none p-1">
          <div className="pointer-events-auto"/>
          <div className="pointer-events-auto">
            {libraryFile.uploadedBySafetyCoach && (
              <i title={`Uploaded by the ${i18n.t('safetyCoach')}`} className="mdi mdi-star"/>
            )}
          </div>
        </div>
      </FileTile>

      <PreviewLibraryFileModal
        show={isShowingLibraryPreviewModal}
        libraryFileId={libraryFile.libraryFileId}
        hide={hideLibraryPreviewModal}
      />
    </>
  );
};

export default LibraryFileTile;
