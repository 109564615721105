import React from 'react';
import { AuditTemplate } from 'models';
import { RootState } from '../../Modules/reducers';
import { connect } from 'react-redux';
import { deleteAuditTemplate } from '../../Modules/templates/actions';
import BaseTable from '../../Components/BaseTable/BaseTable';
import { Column } from 'react-table';

interface LocalProps {
  templates: AuditTemplate[];
  selectedTemplates: {[auditTemplateId: number]: any};
  selectTemplate: (template: AuditTemplate, isSelected: boolean) => void;
}

interface StateProps {}

interface DispatchProps {}

type Props = LocalProps & StateProps & DispatchProps;

class ProgramTemplatesTable extends React.Component<Props> {
  private columns: Column<AuditTemplate>[] = [
    {
      Header: 'Id',
      id: 'auditTemplateId',
      accessor: t => t.auditTemplateId,
      width: 45,
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: t => t.name,
      width: 300,
    },
    {
      Header: '# Weeks',
      id: 'numWeeks',
      accessor: t => t.numWeeks,
      width: 90,
    },
  ];

  public render () {
    const { templates, selectedTemplates } = this.props;

    return (
      <div className="bg-white">
        <BaseTable
          data={templates}
          columns={this.columns}
          minRows={0}
          defaultSorted={[ { id: 'name', desc: false } ]}
          select={{
            selected: selectedTemplates,
            rowClick: true,
            idAccessor: (template: AuditTemplate) => template.auditTemplateId,
            onSelected: this.props.selectTemplate,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  deleteAuditTemplate,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ProgramTemplatesTable);
