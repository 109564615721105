import React from 'react';
import { Button, Label } from 'reactstrap';

import { Poll, PollResultSummary } from 'Modules/polls';
import { WithPollChartDisplay } from 'Components';
import _ from 'lodash';
import { useSelectedOperatorOrThrow } from 'hooks/index';

interface Props {
  poll: Poll;
  results: PollResultSummary[];
  changeAnswer: () => void;
}

const OperatorPollCardResults = ({ poll, results, ...props }: Props) => {
  const selectedOperator = useSelectedOperatorOrThrow();
  const currentOperatorResults = results.filter(pr => pr.operatorId === selectedOperator.operatorId);
  const isAnswered = _.size(currentOperatorResults) > 0;

  return (
    <div>
      <WithPollChartDisplay poll={poll} results={results}>
        {({ Component, isHighlighted }) => (
          <>
            {isAnswered && !isHighlighted && (
              <div className="text-center">
                <Label>You answered: {currentOperatorResults.map(o => o.optionName).join(', ')}</Label>
              </div>
            )}

            {Component}
          </>
        )}
      </WithPollChartDisplay>

      <div className="text-center">
        <Button color="link" onClick={props.changeAnswer}>
          Change Answer
        </Button>
      </div>
    </div>
  );
};

export default OperatorPollCardResults;
