import './chat.scss';

import React, { useState } from 'react';
import { convertToRaw, EditorState } from 'draft-js';

import { preventDefaultAnd } from 'helpers/eventHelpers';
import MessageDraftEditor from 'Components/Draft/Editor/MessageDraftEditor';
import Flex from 'Components/Flex';
import { Button } from 'reactstrap';

interface LocalProps {
  fileType: string;
  direction?: 'col' | 'row';
  uploadFile?: (file: File) => Promise<any>;
  onMessageSubmit?: (message: string) => void | Promise<void>;
}

const ChatMessageInput = ({ direction = 'row', ...props }: LocalProps) => {
  const [ messageEditorState, setMessageEditorState ] = useState(EditorState.createEmpty());

  function submitMessage() {
    const res = props.onMessageSubmit?.(JSON.stringify(convertToRaw(messageEditorState.getCurrentContent())));

    if(res) {
      res.then(() => {
        setMessageEditorState(EditorState.createEmpty());
      });
    } else {
      setMessageEditorState(EditorState.createEmpty());
    }
  }

  return (
    <form onSubmit={preventDefaultAnd(submitMessage)}>
      <Flex direction={direction} className={direction === 'row' ? 'child-spacing-x-2' : 'child-spacing-y-2'} alignCenter={direction === 'row'}>
        <div className={direction === 'row' ? 'flex' : undefined}>
          <MessageDraftEditor
            fileType={props.fileType}
            uploadFile={props.uploadFile}
            editorState={messageEditorState}
            onEditorStateChange={setMessageEditorState}
          />
        </div>
        <div>
          <Button color="primary" block={direction === 'col'} type="submit">Send Message</Button>
        </div>
      </Flex>
    </form>
  );
};

export default ChatMessageInput;
