import { CategoryModel, InsertCategoryRequest } from './models';
import { Action, ApiAction } from '../../models';
import { commonActions } from '../common/actions';
import { SuperAgent } from 'superagent';
import appConfig from '../../config';

export const categoriesActions = {
  GET_ALL_CATEGORIES_REQUEST: 'AVSolutions/categories/GetAllCategoriesRequest',
  GET_ALL_CATEGORIES_SUCCESS: 'AVSolutions/categories/GetAllCategoriesSuccess',
  GET_ALL_CATEGORIES_FAILURE: 'AVSolutions/categories/GetAllCategoriesFailure',

  GET_CATEGORY_REQUEST: 'AVSolutions/categories/GetCategoryRequest',
  GET_CATEGORY_SUCCESS: 'AVSolutions/categories/GetCategorySuccess',
  GET_CATEGORY_FAILURE: 'AVSolutions/categories/GetCategoryFailure',

  INSERT_CATEGORY_REQUEST: 'AVSolutions/categories/InsertCategoryRequest',
  INSERT_CATEGORY_SUCCESS: 'AVSolutions/categories/InsertCategorySuccess',
  INSERT_CATEGORY_FAILURE: 'AVSolutions/categories/InsertCategoryFailure',
};

export const getCategory = (category: CategoryModel): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/categories/${category.categoryId}`);
      },
      beforeRequest: (): Action => ({ type: categoriesActions.GET_CATEGORY_REQUEST }),
      success: (data: any): Action => ({ type: categoriesActions.GET_CATEGORY_SUCCESS, payload: { category: data } }),
      failure: (response: any): Action => ({ type: categoriesActions.GET_CATEGORY_FAILURE, payload: { ...response } }),
    },
  };
};

export const getAllCategories = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/categories/`);
      },
      beforeRequest: (): Action => ({ type: categoriesActions.GET_ALL_CATEGORIES_REQUEST }),
      success: (data: any): Action => ({ type: categoriesActions.GET_ALL_CATEGORIES_SUCCESS, payload: { categories: data } }),
      failure: (response: any): Action => ({ type: categoriesActions.GET_ALL_CATEGORIES_FAILURE, payload: { ...response } }),
    },
  };
};

export const insertCategory = (request: InsertCategoryRequest, successCallback?: (category: CategoryModel) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${appConfig.baseUrl}/api/categories`)
          .send(request);
      },
      beforeRequest: (): Action => ({ type: categoriesActions.INSERT_CATEGORY_REQUEST }),
      success: (data: any): Action => ({ type: categoriesActions.INSERT_CATEGORY_SUCCESS, payload: { category: data } }),
      successCallback,
      failure: (response: any): Action => ({ type: categoriesActions.INSERT_CATEGORY_FAILURE, payload: { ...response } }),
    },
  };
};
