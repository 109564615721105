import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import _ from 'lodash';
import { connect } from 'react-redux';
import { matchSorter } from 'match-sorter';
import { Col, Input, Row } from 'reactstrap';

import { RootState } from 'Modules/reducers';
import { LibraryFile, OperatorModel } from 'models';
import { selectOperatorFileTree, selectOperatorSelectedFiles } from 'Modules/library/selectors';
import { FileTree, TreeNode } from 'Modules/library/models';
import {
  addOperatorLibraryFolder,
  deleteLibraryFiles,
  downloadLibraryFiles,
  downloadOperatorFiles,
  getLibraryPositions,
  getOperatorHiddenFolders,
  insertOperatorFolder,
  moveLibraryFiles,
  selectAllFiles,
  selectNoneFiles,
  toggleShowingHiddenFolders,
  updateLibraryFilesPositions,
  uploadOperatorLibraryFiles,
} from 'Modules/library/actions';
import { Div, FileTreeBreadcrumbs, FlexLoader, FlexRow, LoadableButton } from 'Components';

import LibraryFileTile from './LibraryFileTile/LibraryFileTile';
import UploadToFolderModal from './UploadToFolderModal';
import LibraryFolder from './LibraryFolder';
import { Permission } from '../../../Modules/roles/index';

interface LocalProps {
  operator: OperatorModel;
}

interface LocalState {
  currentFilePath: string;
  filterText: string;
  isShowingUploadModal: boolean;
}

interface StateProps {
  operatorFileTree: FileTree<LibraryFile>;
  selectedFiles: LibraryFile[];
  libraryPositions: { [filePath: string]: number | undefined };
  isDownloadingFiles: boolean;
  showingHiddenFolders: boolean;
  currentUserCanMaintainOperators: boolean;
  isLoadingLibraryPositions: boolean;
  isLoadingOperatorHiddenFolders: boolean;
}

interface DispatchProps {
  selectAllFiles: typeof selectAllFiles;
  selectNoneFiles: typeof selectNoneFiles;
  getOperatorHiddenFolders: typeof getOperatorHiddenFolders;
  moveLibraryFiles: typeof moveLibraryFiles;
  downloadOperatorFiles: typeof downloadOperatorFiles;
  downloadLibraryFiles: typeof downloadLibraryFiles;
  deleteLibraryFiles: typeof deleteLibraryFiles;
  insertOperatorFolder: typeof insertOperatorFolder;
  addOperatorLibraryFolder: typeof addOperatorLibraryFolder;
  uploadOperatorLibraryFiles: typeof uploadOperatorLibraryFiles;
  toggleShowingHiddenFolders: typeof toggleShowingHiddenFolders;
  updateLibraryFilesPositions: typeof updateLibraryFilesPositions;
  getLibraryPositions: typeof getLibraryPositions;
}

type Props = LocalProps & StateProps & DispatchProps;

class OperatorLibrary extends React.Component<Props, LocalState> {
  private draggingNodes?: TreeNode<LibraryFile>[];

  constructor (props: Props) {
    super(props);

    this.state = {
      currentFilePath: '/',
      filterText: '',
      isShowingUploadModal: false,
    };
  }

  public componentDidUpdate () {
    const { currentFilePath } = this.state;
    const { operatorFileTree } = this.props;

    const currentSelectedNode = operatorFileTree.getNodeByPath(currentFilePath);

    if (currentFilePath && !currentSelectedNode) {
      this.setState({ currentFilePath: '/' });
    }
  }

  public componentDidMount () {
    const { operator } = this.props;
    this.props.getOperatorHiddenFolders(operator.operatorId);
    this.props.getLibraryPositions();
  }

  private setFilterText = (e: any) => {
    this.setState({ filterText: e.target.value });
  };

  private getCurrentNode = () => {
    const { currentFilePath } = this.state;
    const { operatorFileTree } = this.props;

    return operatorFileTree.getNodeByPath(currentFilePath);
  }

  private getFilteredFolders = () => {
    const { filterText } = this.state;
    const { libraryPositions } = this.props;

    const currentNode = this.getCurrentNode();

    if (!_.trim(filterText)) {
      return _.orderBy(currentNode?.children || [], [ c => {
        const explicitPosition = libraryPositions[c.filePath];

        if(!_.isNil(explicitPosition)) {
          return explicitPosition;
        }

        return c.isFolder ? Number.MIN_VALUE : Number.MIN_VALUE + 1;
      }, c => c.name ]);
    }

    return matchSorter(currentNode?.children || [], filterText, { keys: [ 'name' ] });
  }

  private addNewFolder = (newFolderFilePath: string) => {
    const { operator } = this.props;
    this.props.addOperatorLibraryFolder(operator.operatorId, newFolderFilePath);
    this.setState({ currentFilePath: newFolderFilePath });
  }

  private addNewDefaultFolder = (newFolderFilePath: string) => {
    const { operator } = this.props;
    this.props.insertOperatorFolder(operator.operatorId, newFolderFilePath, () => {
      this.setState({ currentFilePath: newFolderFilePath });
    });
  }

  private downloadAllFilesInFolder = () => {
    const currentNode = this.getCurrentNode();

    if (!currentNode) {
      return;
    }

    const files = currentNode.fileTree.getDescendantDataForNode(currentNode);

    this.props.downloadLibraryFiles(_.map(files, f => f.libraryFileId));
  }

  private deleteSelectedFiles = (selectedFiles: LibraryFile[]) => {
    this.props.deleteLibraryFiles(selectedFiles);
  }

  private showUploadModal = () => {
    this.setState({ isShowingUploadModal: true });
  }

  private hideUploadModal = () => {
    this.setState({ isShowingUploadModal: false });
  }

  private selectAll = () => {
    const { operator } = this.props;
    const currentNode = this.getCurrentNode();

    if (!currentNode) {
      return;
    }

    const files = _.compact(_.map(currentNode.children, (c) => c.data));

    this.props.selectAllFiles(operator.operatorId, _.map(files, f => f.libraryFileId));
  }

  private selectNone = () => {
    const { operator } = this.props;

    this.props.selectNoneFiles(operator.operatorId);
  }

  private moveFiles = (directoryPath: string, libraryFiles: LibraryFile[]) => {
    return new Promise<void>((resolve) => {
      this.props.moveLibraryFiles(directoryPath, _.map(libraryFiles, l => l.libraryFileId), () => {
        resolve();
      });
    });
  }

  private setList = (nodes: TreeNode<LibraryFile>[]) => {
    this.draggingNodes = nodes;
  };

  private updateLibraryFilesPositions = () => {
    if (!this.draggingNodes) {
      return;
    }

    this.props.updateLibraryFilesPositions(this.draggingNodes);
    this.draggingNodes = undefined;
  };

  public render () {
    const { filterText, isShowingUploadModal } = this.state;
    const { isDownloadingFiles, operator, isLoadingOperatorHiddenFolders, showingHiddenFolders, currentUserCanMaintainOperators, selectedFiles, isLoadingLibraryPositions } = this.props;
    const currentNode = this.getCurrentNode();

    if (!currentNode) {
      return null;
    }

    if(isLoadingLibraryPositions || isLoadingOperatorHiddenFolders) {
      return (
        <FlexLoader />
      );
    }

    const filteredFolders = this.getFilteredFolders();

    return (
      <>
        <Row className="child-spacing-y-1">
          <Col md={12}>
            <FileTreeBreadcrumbs
              currentNode={currentNode}
              addFolder={this.addNewFolder}
              defaultFolders={{
                addFolder: this.addNewDefaultFolder,
              }}
              permissions={{
                canCreateFolder: () => currentUserCanMaintainOperators,
              }}
              selectAll={this.selectAll}
              selectNone={this.selectNone}
              selectedFiles={selectedFiles}
              hiddenFolders={{
                isShowing: showingHiddenFolders,
                toggle: this.props.toggleShowingHiddenFolders,
              }}
              setCurrentFilePath={(newFolderFilePath) => this.setState({ currentFilePath: newFolderFilePath })}
              accessors={{
                filePath: (libraryFile) => libraryFile.filePath,
                originalFilename: (libraryFile) => libraryFile.file.originalFilename,
                name: (libraryFile) => libraryFile.fileName,
              }}
              upload={{
                startUploading: this.showUploadModal,
              }}
              deleteFiles={{
                delete: this.deleteSelectedFiles,
              }}
              moveFiles={this.moveFiles}
            />
          </Col>

          <Col md={12}>
            <FlexRow childSpacingX={1}>
              <Div fill>
                <Input placeholder="Search Folders..." value={filterText} onChange={this.setFilterText} />
              </Div>

              <LoadableButton color="light" isLoading={isDownloadingFiles} LoadingLabel="Downloading Files..." onClick={this.downloadAllFilesInFolder}>
                <span>Download Files in Folder</span>
              </LoadableButton>
            </FlexRow>
          </Col>

          <Col md={12}>
            <ReactSortable<any> className="row" list={filteredFolders} setList={this.setList} onEnd={this.updateLibraryFilesPositions}>
              {
                _.map(filteredFolders, (folder) => {
                  return (
                    <Col key={folder.filePath} xs={12} lg={4} xl={3} className="mb-3">
                      {folder.isFolder && (
                        <LibraryFolder
                          operator={operator}
                          folder={folder}
                          onFolderSelect={(node) => this.setState({ currentFilePath: node.filePath })}
                        />
                      )}

                      {!folder.isFolder && folder.data && (
                        <LibraryFileTile
                          allowSelection
                          libraryFile={folder.data}
                        />
                      )}
                    </Col>
                  );
                })
              }
            </ReactSortable>
          </Col>
        </Row>

        <UploadToFolderModal
          show={isShowingUploadModal}
          operatorId={operator.operatorId}
          directoryPath={currentNode.filePath}
          hide={this.hideUploadModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: LocalProps) => {
  return {
    operatorFileTree: selectOperatorFileTree(state, ownProps.operator.operatorId),
    selectedFiles: selectOperatorSelectedFiles(state, ownProps.operator.operatorId),
    isDownloadingFiles: state.library.loading.isDownloadingFiles,
    isLoadingLibraryPositions: state.library.loading.isLoadingLibraryPositions,
    isLoadingOperatorHiddenFolders: state.library.loading.isLoadingOperatorHiddenFolders,
    showingHiddenFolders: state.library.ui.showingHiddenFolders,
    libraryPositions: state.library.ui.libraryPositions,
    currentUserCanMaintainOperators: !!state.site.user?.permissions.includes(Permission.OperatorMaintenance),
  };
};

const mapDispatchToProps = {
  selectAllFiles,
  selectNoneFiles,
  getOperatorHiddenFolders,
  getLibraryPositions,
  downloadOperatorFiles,
  downloadLibraryFiles,
  insertOperatorFolder,
  deleteLibraryFiles,
  addOperatorLibraryFolder,
  uploadOperatorLibraryFiles,
  toggleShowingHiddenFolders,
  moveLibraryFiles,
  updateLibraryFilesPositions,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(OperatorLibrary);
