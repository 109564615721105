import './task-modal.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import _ from 'lodash';
import { AssignedTaskModel } from '../../models';
import TaskFileGrid from '../TaskFileGrid';
import TaskHistory from './TaskHistory';
import TaskMessages from './TaskMessages';
import ModalHeaderWithClose from '../../Components/ModalHeaderWithClose';
import TaskHeader from '../../Components/TaskHeader';
import { getTaskUserNotifications, markTaskNotificationsViewed } from '../../Modules/userNotifications/actions';
import { NotificationTypeEnum } from '../../constants/NotificationType';
import TaskNotificationIcon from '../../Components/TaskNotificationIcon';
import TaskSummary from './TaskSummary';
import CollapsableTaskFileDropzone from './CollapsableTaskFileDropzone';
import useOnModalShow from '../../hooks/useOnModalShow';
import useOnModalHide from '../../hooks/useOnModalHide';
import { RootState } from '../../Modules/reducers';
import { selectTaskChildren } from '../../Modules/tasks/selectors';
import { getParentChildrenTasks } from '../../Modules/tasks/actions';
import FlexRow from '../../Components/FlexRow';
import Steps from '../../Components/Steps/Steps';
import { taskIsOperatorComplete, taskIsOperatorInProgress } from '../../core/tasks';

interface Props {
  show: boolean;
  defaultTab?: TaskTab;
  task?: AssignedTaskModel | null;
  hide: () => void;
}

export enum TaskTab {
  Summary,
  Files,
  Messages,
  History,
}

const TaskModal = ({ show, task, defaultTab, ...props }: Props) => {
  const dispatch = useDispatch();
  const [ activeTaskTab, setActiveTaskTab ] = useState<TaskTab>(defaultTab || TaskTab.Summary);
  const [ unreadNotificationTypes, setUnreadNotificationTypes ] = useState<number[]>([]);
  const [ activeChildTaskId, setActiveChildTaskId ] = useState<number | undefined>();

  useOnModalShow(show)
    .then(() => {
      setActiveTaskTab(defaultTab || TaskTab.Summary);
      if (task && task.hasUnreadNotifications) {
        dispatch(getTaskUserNotifications(task.taskId, ({ userNotifications }) => {
          setUnreadNotificationTypes(_.uniq(_.map(_.filter(userNotifications, un => !un.viewed), un => un.notificationTypeId)));
          dispatch(markTaskNotificationsViewed(task.taskId));
        }));
      }

      if (task && task.isParent) {
        dispatch(getParentChildrenTasks(task.taskId));
      }
    });

  useOnModalHide(show)
    .then(() => {
      setUnreadNotificationTypes([]);
      setActiveChildTaskId(undefined);
      setActiveTaskTab(TaskTab.Summary);
    });

  const childTasks = useSelector((state: RootState) => {
    if (!task || !show || !task.isParent) {
      return [];
    }

    return selectTaskChildren(task.taskId)(state);
  });

  useEffect(() => {
    if (activeChildTaskId) {
      return;
    }

    if (_.isEmpty(childTasks)) {
      return;
    }

    const inProgressTasks = childTasks.find(taskIsOperatorInProgress);

    setActiveChildTaskId(inProgressTasks ? inProgressTasks.taskId : childTasks[0].taskId);
  }, [ childTasks ]);

  const activeChildTask = useMemo(() => {
    if (!activeChildTaskId) {
      return undefined;
    }
    return childTasks?.find(t => t.taskId === activeChildTaskId);
  }, [ activeChildTaskId, childTasks ]);

  if (!task) {
    return null;
  }

  return (
    <>
      <Modal isOpen={show} toggle={props.hide} size="xl">
        <ModalHeaderWithClose hide={props.hide}>
          <TaskHeader task={task} />
        </ModalHeaderWithClose>
        <ModalBody>
          {show && (
            <>
              {!task.isParent && (
                <Row>
                  <Col md={12}>
                    <Nav className="navtab-bg" pills justified>
                      <NavItem onClick={() => setActiveTaskTab(TaskTab.Summary)}>
                        <NavLink active={activeTaskTab === TaskTab.Summary}>
                          <span className="d-inline-block d-sm-none"><i className="mdi mdi-information" /></span>
                          <span className="d-none d-sm-inline-block">Summary</span>
                        </NavLink>
                      </NavItem>

                      <NavItem onClick={() => setActiveTaskTab(TaskTab.Files)}>
                        <NavLink active={activeTaskTab === TaskTab.Files}>
                          <span className="d-inline-block d-sm-none"><i className="mdi mdi-file-search" /></span>
                          <span className="d-none d-sm-inline-block">Files ({task.numFiles})</span>
                        </NavLink>
                      </NavItem>

                      <NavItem onClick={() => setActiveTaskTab(TaskTab.Messages)}>
                        <NavLink active={activeTaskTab === TaskTab.Messages}>
                          <span className="d-inline-block d-sm-none"><i className="mdi mdi-email-search" /> {_.includes(unreadNotificationTypes, NotificationTypeEnum.TaskMessageSent) && (
                            <TaskNotificationIcon />
                          )}
                          </span>
                          <span className="d-none d-sm-inline-block">
                            Messages ({task.numMessages}) {_.includes(unreadNotificationTypes, NotificationTypeEnum.TaskMessageSent) && (
                              <TaskNotificationIcon />
                            )}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem onClick={() => setActiveTaskTab(TaskTab.History)}>
                        <NavLink active={activeTaskTab === TaskTab.History}>
                          <span className="d-inline-block d-sm-none"><i className="mdi mdi-history" /></span>
                          <span className="d-none d-sm-inline-block">History ({task.numStatusChanges})</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={12}>
                  <TabContent activeTab={activeTaskTab}>
                    <TabPane tabId={TaskTab.Summary}>
                      <Container fluid>
                        {task.isParent && (
                          <>
                            <FlexRow className="mb-2" justifyCenter>
                              <div className="steps-container">
                                <Steps
                                  keyAccessor={t => t.taskId}
                                  items={childTasks}
                                  activeItems={activeChildTask ? [ activeChildTask ] : undefined}
                                  completeItems={childTasks.filter(taskIsOperatorComplete)}
                                  CompleteIcon={<i className="mdi mdi-check" />}
                                  onStepClick={(task) => setActiveChildTaskId(task.taskId)}
                                />
                              </div>
                            </FlexRow>
                            {activeChildTask && <TaskSummary key={activeChildTask.taskId} task={activeChildTask} />}
                          </>
                        )}
                        {!task.isParent && (
                          <TaskSummary task={task} />
                        )}
                      </Container>
                    </TabPane>
                    <TabPane tabId={TaskTab.Files}>
                      <Container fluid>
                        <CollapsableTaskFileDropzone task={task} defaultOpen={task.numFiles === 0} />

                        <TaskFileGrid taskId={task.taskId} />
                      </Container>
                    </TabPane>
                    <TabPane tabId={TaskTab.Messages}>
                      <Container fluid>
                        <TaskMessages task={task} />
                      </Container>
                    </TabPane>
                    <TabPane tabId={TaskTab.History}>
                      <Container fluid>
                        <TaskHistory taskId={task.taskId} />
                      </Container>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex flex-row justify-content-between">
            <div>
              <Button color="light" onClick={props.hide}>Close</Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TaskModal;
