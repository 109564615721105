import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreateRole, Role } from 'Modules/roles/models';
import { rolesActions } from 'Modules/roles';
import { getNewRole } from 'Modules/roles/instantiations';
import { useMergedEntity, useRoleForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, RoleForm, Panel, PanelHeader } from 'Components';

const CreateRolePanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootRole = useMemo(() => getNewRole(), []);
  const workingRole = useStateSelector((state) => state.roles.ui.maintenance.workingCreateRole);
  const role = useMergedEntity(rootRole, workingRole);
  const { insertRole, cancelRoleModification, loading } = useRoleForm();

  function setWorkingRoleValues(role: Partial<CreateRole>) {
    dispatch(rolesActions.setWorkingCreateRoleValues(role));
  }

  function handleSave() {
    if (!role) {
      return;
    }
    insertRole(role)
      .then((role: Role) => {
        history.push(`/admin/roles/${role.id}`);
      });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New Role</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelRoleModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingRole,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <RoleForm
              isCreatingNewRole
              role={role}
              setValues={setWorkingRoleValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreateRolePanel;
