import React, { ReactNode } from 'react';
import { useCurrentUserPermissionChecker } from '../hooks';
import { Permission } from '../Modules/roles';

interface LocalProps {
  permission: Permission;
  children: ReactNode;
}

const HideWithPermission = ({ permission, children }: LocalProps) => {
  const currentUserHasPermission = useCurrentUserPermissionChecker();


  if (currentUserHasPermission(permission)) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default HideWithPermission;
