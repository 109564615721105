import React from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { shouldModalUpdate } from 'helpers/modalHelpers';
import { RootState } from 'Modules/reducers';
import { createAuditTemplate, createAuditTemplateFromAudit } from 'Modules/templates/actions';

interface LocalProps {
  show: boolean;
  operatorAuditId?: number;
  hide: () => void;
}

interface LocalState {
  name: string;
}

interface StateProps {
  isCreatingAuditTemplate: boolean;
}

interface DispatchProps {
  createAuditTemplate: typeof createAuditTemplate;
  createAuditTemplateFromAudit: typeof createAuditTemplateFromAudit;
}

type Props = LocalProps & StateProps & DispatchProps;

class CreateTemplateFromAuditModal extends React.Component<Props, LocalState> {
  shouldComponentUpdate = shouldModalUpdate(this);

  constructor (props: Props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  public componentDidUpdate (prevProps: Readonly<Props>): void {
    if (prevProps.isCreatingAuditTemplate && !this.props.isCreatingAuditTemplate) {
      this.props.hide();
    }

    if (prevProps.show && !this.props.show) {
      this.setState({
        name: '',
      });
    }
  }

  private createTemplate = () => {
    const { name } = this.state;
    const { operatorAuditId } = this.props;

    if (!_.trim(name)) {
      toastr.error('You must specify a name for the template');
      return;
    }

    if (!operatorAuditId) {
      return;
    }

    this.props.createAuditTemplateFromAudit(operatorAuditId, name);
  };

  setName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.target.value,
    });
  };

  public render () {
    const { name } = this.state;
    const { show, isCreatingAuditTemplate } = this.props;

    return (
      <Modal isOpen={show} toggle={this.props.hide}>
        <ModalHeader>Create Template</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Name:</Label>
                <Input value={name} onChange={this.setName} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {!isCreatingAuditTemplate && (
            <Button color="primary" onClick={this.createTemplate}>
              Create Template
            </Button>
          )}

          {isCreatingAuditTemplate && (
            <Button color="primary"><i className="fa fa-spin fa-cog" /> Saving Template...</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isCreatingAuditTemplate: state.templates.loading.isCreatingAuditTemplate,
    isDeletingAuditTemplate: state.templates.loading.isDeletingAuditTemplate,
  };
};

const mapDispatchToProps = {
  createAuditTemplate,
  createAuditTemplateFromAudit,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(CreateTemplateFromAuditModal);
