import React from 'react';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

import { useStateSelector } from 'hooks';
import { FlexRow } from 'Components';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const UploadDirectoryPathSelector = function ({ value, ...props }: Props) {
  const indexedLookup = useStateSelector(state => {
    const indexedLookup: { [index: number]: string[] } = {};

    _.each(state.library.ui.availableDirectories, (directory) => {
      const segments = _.compact(_.split(directory, '/'));

      _.each(segments, (segment, i) => {
        if (!_.has(indexedLookup, i)) {
          indexedLookup[i] = [];
        }

        indexedLookup[i].push(segment);
      });
    });

    return indexedLookup;
  });

  function onChange(index: number, option: { value: string }) {
    const { value: name } = option || {};

    handleChange(index, name);
  }

  function createOption(index: number, name: string) {
    handleChange(index, name);
  }

  function handleChange(index: number, name: string) {
    const currentSegments = getCurrentSegments();
    let updatedSegments = [ ...currentSegments ];

    updatedSegments[index] = name;

    updatedSegments = _.compact(updatedSegments);

    props.onChange(`/${updatedSegments.join('/')}/`.replace(/\/\//g, '/'));
  }

  function getCurrentSegments() {
    return _.compact(_.split(_.trim(value, '/'), '/'));
  }

  const segments = getCurrentSegments();

  return (
    <FlexRow className="flex-wrap" childSpacingX={1} childSpacingY={1} alignCenter>
      <span>/</span>

      {
        _.map(segments, (segment, i) => {
          return (
            <React.Fragment key={i}>
              <CreatableSelect
                value={{ label: segment, value: segment } as any}
                isClearable
                getNewOptionData={c => ({ label: c, value: c }) as any}
                options={_.map(_.uniq(indexedLookup[i]), (segment) => ({ label: segment, value: segment })) as any || []}
                formatCreateLabel={c => <span>Create Folder: <em>{c}</em></span>}
                onCreateOption={(option) => createOption(i, option)}
                onChange={(option) => onChange(i, option as any)}
                styles={{ container: (base) => ({ ...base, minWidth: 180 }) }}
              />

              <span>/</span>
            </React.Fragment>
          );
        })
      }

      <CreatableSelect
        getNewOptionData={c => ({ label: c, value: c }) as any}
        value={null}
        formatCreateLabel={c => <span>Create Folder: <em>{c}</em></span>}
        options={_.map(_.uniq(indexedLookup[_.size(segments)]), (segment) => ({
          label: segment,
          value: segment,
        })) as any || []}
        onCreateOption={(option) => createOption(_.size(segments), option)}
        onChange={(option) => onChange(_.size(segments), option as any)}
        styles={{ container: (base) => ({ ...base, minWidth: 180 }) }}
      />

      <span>/</span>
    </FlexRow>
  );
};

export default UploadDirectoryPathSelector;
