import './split-panel.scss';

import React, { CSSProperties, ReactNode } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useWindowBreakpoints } from '../../hooks';
import { mergeCssProperties } from 'core';

interface RatioBreakpoints {
  xs: number | string;
  sm: number | string;
  md: number | string;
  lg: number | string;
  xl: number | string;
}

export interface SplitPanelPane extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  Component?: ReactNode;
  ratio?: number | string | RatioBreakpoints;
  width?: number | string;
  minWidth?: number | string;
  hidden?: boolean;
}

interface Props {
  className?: string;
  outerStyle?: CSSProperties;
  innerStyle?: CSSProperties;
  innerContentScroll?: boolean;
  borderless?: boolean;
  panes: SplitPanelPane[];
}

const SplitPanel = (props: Props) => {
  const {
    className,
    outerStyle,
    innerStyle,
    innerContentScroll,
    borderless,
    panes,
  } = props;

  const windowBreakpoints = useWindowBreakpoints();

  const resolvedPanes = _.map(panes, (pane) => {
    const resolvedRatio = _.last(_.filter([
      !_.isObject(pane.ratio) ? pane.ratio : undefined,
      windowBreakpoints.xs ? (pane.ratio as RatioBreakpoints).xs : undefined,
      windowBreakpoints.sm ? (pane.ratio as RatioBreakpoints).sm : undefined,
      windowBreakpoints.md ? (pane.ratio as RatioBreakpoints).md : undefined,
      windowBreakpoints.lg ? (pane.ratio as RatioBreakpoints).lg : undefined,
      windowBreakpoints.xl ? (pane.ratio as RatioBreakpoints).xl : undefined,
    ], (value) => !_.isUndefined(value)));

    return {
      ...pane,
      ratio: pane.ratio ? resolvedRatio : undefined,
    };
  });

  const rootProps = {
    className: classnames('split-pane', className, { 'borderless': borderless }),
    style: outerStyle,
  };

  return (
    <div {...rootProps}>
      <div className={classnames('d-flex flex overflow-hidden', { 'overflow-auto': innerContentScroll })} style={innerStyle}>
        {
          _.map(resolvedPanes, ({ Component, hidden, className, ratio, width, minWidth, style, ...divProps }, i) => {
            const paneProps = {
              key: i,
              ...divProps,
              className: classnames(className, 'pane', { 'invisible': hidden }),
              style: mergeCssProperties(style, { flex: ratio, width: width, minWidth: minWidth }),
            };

            return (
              <div {...paneProps}>
                {Component}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default SplitPanel;
