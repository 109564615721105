import _ from 'lodash';

import usePathId from './usePathId';

const useRequiredPathId = (routeIdParam: string) => {
  const pathId = usePathId(routeIdParam);

  if (_.isNil(pathId)) {
    throw new Error('Route id not found in route.');
  }

  return pathId;
};

export default useRequiredPathId;
