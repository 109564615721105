import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { TemplatesState } from './reducer';
import { templateActions } from './actions';

const loadingConfigs: LoadingActionConfig<TemplatesState>[] = [
  {
    start: templateActions.GET_ALL_AUDIT_TEMPLATES_REQUEST,
    stop: [ templateActions.GET_ALL_AUDIT_TEMPLATES_SUCCESS, templateActions.GET_ALL_AUDIT_TEMPLATES_FAILURE ],
    updater: (state: TemplatesState, isLoading): TemplatesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingAllAuditTemplates: isLoading,
        },
      };
    },
  },
  {
    start: templateActions.CREATE_AUDIT_TEMPLATE_REQUEST,
    stop: [ templateActions.CREATE_AUDIT_TEMPLATE_SUCCESS, templateActions.CREATE_AUDIT_TEMPLATE_FAILURE ],
    updater: (state: TemplatesState, isLoading): TemplatesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isCreatingAuditTemplate: isLoading,
        },
      };
    },
  },
  {
    start: templateActions.DELETE_AUDIT_TEMPLATE_REQUEST,
    stop: [ templateActions.DELETE_AUDIT_TEMPLATE_SUCCESS, templateActions.DELETE_AUDIT_TEMPLATE_FAILURE ],
    updater: (state: TemplatesState, isLoading): TemplatesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingAuditTemplate: isLoading,
        },
      };
    },
  },
  {
    start: templateActions.GET_AUDIT_TEMPLATE_TASKS_REQUEST,
    stop: [ templateActions.GET_AUDIT_TEMPLATE_TASKS_SUCCESS, templateActions.GET_AUDIT_TEMPLATE_TASKS_FAILURE ],
    updater: (state: TemplatesState, isLoading): TemplatesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingAuditTemplateTasks: isLoading,
        },
      };
    },
  },
  {
    start: templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_REQUEST,
    stop: [ templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_SUCCESS, templateActions.GET_PARENT_CHILDREN_TEMPLATE_TASKS_FAILURE ],
    updater: (state: TemplatesState, isLoading): TemplatesState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingParentChildrenTemplateTasks: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
