import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';

interface LocalProps {
  date: Moment;
  formatDate: (date: Moment) => string;
}

const NUM_SECONDS_TO_UPDATE = 15 * 1000;

const RealtimeFormattedDate = ({ date, formatDate }: LocalProps) => {
  const [ displayDate, setDisplayDate ] = useState<string>(formatDate(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayDate(formatDate(date));
    }, NUM_SECONDS_TO_UPDATE);
    return () => clearInterval(interval);
  }, [ date, formatDate ]);

  return (
    <>
      {displayDate}
    </>
  );
};

export default React.memo(RealtimeFormattedDate);
