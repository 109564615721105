import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { Button, Input } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';

import { usePaginatedRoles } from 'Modules/roles/pagination';
import {
  DomClass,
  FlexLoaderPanel,
  NavMenuButton,
  Panel,
  PanelHeader,
  SplitPanel,
  VerticalOnlyScrollPanel,
} from 'Components';

import CreateRolePanel from './CreateRolePanel';
import ModifyRolePanel from './ModifyRolePanel';

const RolesPage = () => {
  const { data: roles, isSearching, pagination, filterText, setFilterText } = usePaginatedRoles({ searchOnMount: true });

  return (
    <>
      <Helmet>
        <title>Role Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 1,
            Component: (
              <Panel>
                <PanelHeader
                  white
                  RightContent={(
                    <Button tag={Link} to="/admin/roles/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                  BottomContent={(
                    <div>
                      <Input autoFocus value={filterText} onChange={setFilterText} />
                    </div>
                  )}
                >
                  Roles ({pagination.total})
                </PanelHeader>

                <Panel className="position-relative">
                  <FlexLoaderPanel isLoading={isSearching}>
                    <VerticalOnlyScrollPanel className="p-1">
                      {
                        _.map(roles, (role) => {
                          return (
                            <NavMenuButton key={role.id} to={`/admin/roles/${role.id}`}>
                              {role.name}
                            </NavMenuButton>
                          );
                        })
                      }
                    </VerticalOnlyScrollPanel>
                  </FlexLoaderPanel>
                </Panel>
              </Panel>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/roles/create" component={CreateRolePanel} />
                <Route exact path="/admin/roles/:roleId([0-9]+)" component={ModifyRolePanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default RolesPage;
