import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedIdStateSelector, useStateSelector } from 'hooks';
import { pollsActions, pollsSelectors } from 'Modules/polls';

const usePollOperatorGroups = (pollId: number) => {
  const dispatch = useDispatch();
  const isLoadingPollOperatorGroups = useStateSelector(state => state.polls.loading.isLoadingPollOperatorGroups);
  const pollOperatorGroups = useMemoizedIdStateSelector(pollId, pollsSelectors.makeGetPollOperatorGroupsByPoll);
  useEffect(() => {
    dispatch(pollsActions.async.getPollOperatorGroupsForPoll(pollId));
  }, [ pollId ]);

  return [ pollOperatorGroups, isLoadingPollOperatorGroups ] as const;
};

export default usePollOperatorGroups;
