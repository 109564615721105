import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';
import _ from 'lodash';
import { ShareBoardCategory, ShareBoardFile } from './models';

export const approvedShareBoardFilesForCurrentOperatorGroup = createSelector([
  (state: RootState) => state.shareBoard.entities.shareBoardFiles,
  (state: RootState) => state.shareBoard.ui.allApprovedShareBoardFileIdForCurrentOperatorGroup,
], (
  shareBoardFiles,
  allApprovedShareBoardFileIdForCurrentOperatorGroup
): ShareBoardFile[] => {
  const ordered = _.orderBy(getNumberMappedEntities(shareBoardFiles, allApprovedShareBoardFileIdForCurrentOperatorGroup), [ f => f.rating ?? Number.MIN_VALUE, f => f.id ], [ 'desc', 'asc' ]);
  return ordered.filter(f => f.approved);
});

export const allShareBoardCategories = createSelector([
  (state: RootState) => state.shareBoard.entities.shareBoardCategories,
  (state: RootState) => state.shareBoard.ui.allShareBoardCategoryIds,
], (
  shareBoardCategories,
  allShareBoardCategoryIds
): ShareBoardCategory[] => {
  return _.sortBy(getNumberMappedEntities(shareBoardCategories, allShareBoardCategoryIds), c => c.name.toLowerCase());
});

export const unapprovedShareBoardFiles = (operatorGroupId: number | undefined) => createSelector([
  (state: RootState) => state.shareBoard.entities.shareBoardFiles,
  (state: RootState) => state.shareBoard.ui.allUnapprovedShareBoardFileIds,
], (
  shareBoardFiles,
  allUnapprovedShareBoardFileIds
): ShareBoardFile[] => {
  if(!operatorGroupId) {
    return [];
  }
  return getNumberMappedEntities(shareBoardFiles, allUnapprovedShareBoardFileIds[operatorGroupId]);
});

export const approvedShareBoardFiles = (operatorGroupId: number | undefined) => createSelector([
  (state: RootState) => state.shareBoard.entities.shareBoardFiles,
  (state: RootState) => state.shareBoard.ui.allApprovedShareBoardFileIds,
], (
  shareBoardFiles,
  allApprovedShareBoardFileIds
): ShareBoardFile[] => {
  if(!operatorGroupId) {
    return [];
  }
  return getNumberMappedEntities(shareBoardFiles, allApprovedShareBoardFileIds[operatorGroupId]);
});
