import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';

import { canLoadMore } from 'core';
import { usePaginatedAllPollCategories } from 'Modules/polls/pagination';
import { DomClass, ListPanel, ListPanelHeader, NavMenuButton, Panel, SplitPanel } from 'Components';

import CreatePollCategoryPanel from './CreatePollCategoryPanel';
import ModifyPollCategoryPanel from './ModifyPollCategoryPanel';


const PollCategoryMaintenancePage = () => {
  const { data: pollCategories, pagination, filterText, setFilterText, loadMore, isSearching } = usePaginatedAllPollCategories({ searchOnMount: true });

  return (
    <>
      <Helmet>
        <title>Poll Category Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 1,
            Component: (
              <>
                <ListPanel
                  Header={(
                    <ListPanelHeader>Poll Categories ({_.size(pollCategories)})</ListPanelHeader>
                  )}
                  isLoading={isSearching}
                  search={{
                    inputProps: { autoFocus: true },
                    isLoading: isSearching,
                    value: filterText,
                    onChange: setFilterText,
                  }}
                  loadMore={{
                    hasMore: canLoadMore(pagination),
                    pagination: pagination,
                    onClick: loadMore,
                  }}
                  Tools={(
                    <Button tag={Link} to="/admin/pollCategories/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                >
                  <div className="p-1">
                    {
                      _.map(pollCategories, (pollCategory) => {
                        return (
                          <NavMenuButton key={pollCategory.id} to={`/admin/pollCategories/${pollCategory.id}`}>
                            {pollCategory.name}
                          </NavMenuButton>
                        );
                      })
                    }
                  </div>
                </ListPanel>
              </>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/pollCategories/create" component={CreatePollCategoryPanel} />
                <Route exact path="/admin/pollCategories/:pollCategoryId([0-9]+)" component={ModifyPollCategoryPanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default PollCategoryMaintenancePage;
