import _ from 'lodash';

interface FileExtensionIcon {
  icon: string;
  extension: string;
}

const fileExtensionIcons: FileExtensionIcon[] = [
  { icon: 'mdi mdi-file-image-outline', extension: '3dm' },
  { icon: 'mdi mdi-file-image-outline', extension: '3ds' },
  { icon: 'mdi mdi-file-image-outline', extension: 'max' },
  { icon: 'mdi mdi-file-image-outline', extension: 'bmp' },
  { icon: 'mdi mdi-file-image-outline', extension: 'dds' },
  { icon: 'mdi mdi-file-image-outline', extension: 'gif' },
  { icon: 'mdi mdi-file-image-outline', extension: 'jpg' },
  { icon: 'mdi mdi-file-image-outline', extension: 'jpeg' },
  { icon: 'mdi mdi-file-image-outline', extension: 'png' },
  { icon: 'mdi mdi-file-image-outline', extension: 'psd' },
  { icon: 'mdi mdi-file-image-outline', extension: 'xcf' },
  { icon: 'mdi mdi-file-image-outline', extension: 'tga' },
  { icon: 'mdi mdi-file-image-outline', extension: 'thm' },
  { icon: 'mdi mdi-file-image-outline', extension: 'tif' },
  { icon: 'mdi mdi-file-image-outline', extension: 'tiff' },
  { icon: 'mdi mdi-file-image-outline', extension: 'yuv' },
  { icon: 'mdi mdi-file-image-outline', extension: 'ai' },
  { icon: 'mdi mdi-file-image-outline', extension: 'eps' },
  { icon: 'mdi mdi-file-image-outline', extension: 'ps' },
  { icon: 'mdi mdi-file-image-outline', extension: 'svg' },
  { icon: 'mdi mdi-file-image-outline', extension: 'dwg' },
  { icon: 'mdi mdi-file-image-outline', extension: 'dxf' },
  { icon: 'mdi mdi-file-image-outline', extension: 'gpx' },
  { icon: 'mdi mdi-file-image-outline', extension: 'kml' },
  { icon: 'mdi mdi-file-image-outline', extension: 'kmz' },
  { icon: 'mdi mdi-file-image-outline', extension: 'webp' },
  { icon: 'mdi mdi-file-image-outline', extension: 'eot' },
  { icon: 'mdi mdi-file-image-outline', extension: 'otf' },
  { icon: 'mdi mdi-file-image-outline', extension: 'ttf' },
  { icon: 'mdi mdi-file-image-outline', extension: 'woff' },
  { icon: 'mdi mdi-file-image-outline', extension: 'woff2' },

  { icon: 'mdi mdi-file-link-outline', extension: '7z' },
  { icon: 'mdi mdi-file-link-outline', extension: 'a' },
  { icon: 'mdi mdi-file-link-outline', extension: 'apk' },
  { icon: 'mdi mdi-file-link-outline', extension: 'ar' },
  { icon: 'mdi mdi-file-link-outline', extension: 'bz2' },
  { icon: 'mdi mdi-file-link-outline', extension: 'cab' },
  { icon: 'mdi mdi-file-link-outline', extension: 'cpio' },
  { icon: 'mdi mdi-file-link-outline', extension: 'deb' },
  { icon: 'mdi mdi-file-link-outline', extension: 'dmg' },
  { icon: 'mdi mdi-file-link-outline', extension: 'egg' },
  { icon: 'mdi mdi-file-link-outline', extension: 'gz' },
  { icon: 'mdi mdi-file-link-outline', extension: 'iso' },
  { icon: 'mdi mdi-file-link-outline', extension: 'jar' },
  { icon: 'mdi mdi-file-link-outline', extension: 'lha' },
  { icon: 'mdi mdi-file-link-outline', extension: 'mar' },
  { icon: 'mdi mdi-file-link-outline', extension: 'pea' },
  { icon: 'mdi mdi-file-link-outline', extension: 'rar' },
  { icon: 'mdi mdi-file-link-outline', extension: 'rpm' },
  { icon: 'mdi mdi-file-link-outline', extension: 's7z' },
  { icon: 'mdi mdi-file-link-outline', extension: 'shar' },
  { icon: 'mdi mdi-file-link-outline', extension: 'tar' },
  { icon: 'mdi mdi-file-link-outline', extension: 'tbz2' },
  { icon: 'mdi mdi-file-link-outline', extension: 'tgz' },
  { icon: 'mdi mdi-file-link-outline', extension: 'tlz' },
  { icon: 'mdi mdi-file-link-outline', extension: 'war' },
  { icon: 'mdi mdi-file-link-outline', extension: 'whl' },
  { icon: 'mdi mdi-file-link-outline', extension: 'xpi' },
  { icon: 'mdi mdi-file-link-outline', extension: 'zip' },
  { icon: 'mdi mdi-file-link-outline', extension: 'zipx' },
  { icon: 'mdi mdi-file-link-outline', extension: 'deb' },
  { icon: 'mdi mdi-file-link-outline', extension: 'rpm' },
  { icon: 'mdi mdi-file-link-outline', extension: 'xz' },
  { icon: 'mdi mdi-file-link-outline', extension: 'pak' },
  { icon: 'mdi mdi-file-link-outline', extension: 'crx' },
  { icon: 'mdi mdi-file-link-outline', extension: 'exe' },
  { icon: 'mdi mdi-file-link-outline', extension: 'msi' },
  { icon: 'mdi mdi-file-link-outline', extension: 'bin' },

  { icon: 'mdi mdi-file-music-outline', extension: 'aac' },
  { icon: 'mdi mdi-file-music-outline', extension: 'aiff' },
  { icon: 'mdi mdi-file-music-outline', extension: 'ape' },
  { icon: 'mdi mdi-file-music-outline', extension: 'au' },
  { icon: 'mdi mdi-file-music-outline', extension: 'flac' },
  { icon: 'mdi mdi-file-music-outline', extension: 'gsm' },
  { icon: 'mdi mdi-file-music-outline', extension: 'it' },
  { icon: 'mdi mdi-file-music-outline', extension: 'm3u' },
  { icon: 'mdi mdi-file-music-outline', extension: 'm4a' },
  { icon: 'mdi mdi-file-music-outline', extension: 'mid' },
  { icon: 'mdi mdi-file-music-outline', extension: 'mod' },
  { icon: 'mdi mdi-file-music-outline', extension: 'mp3' },
  { icon: 'mdi mdi-file-music-outline', extension: 'mpa' },
  { icon: 'mdi mdi-file-music-outline', extension: 'pls' },
  { icon: 'mdi mdi-file-music-outline', extension: 'ra' },
  { icon: 'mdi mdi-file-music-outline', extension: 's3m' },
  { icon: 'mdi mdi-file-music-outline', extension: 'sid' },
  { icon: 'mdi mdi-file-music-outline', extension: 'wav' },
  { icon: 'mdi mdi-file-music-outline', extension: 'wma' },
  { icon: 'mdi mdi-file-music-outline', extension: 'xm' },

  { icon: 'mdi mdi-file-table-outline', extension: 'ods' },
  { icon: 'mdi mdi-file-table-outline', extension: 'xls' },
  { icon: 'mdi mdi-file-table-outline', extension: 'xlsx' },
  { icon: 'mdi mdi-file-table-outline', extension: 'csv' },
  { icon: 'mdi mdi-file-table-outline', extension: 'ics' },
  { icon: 'mdi mdi-file-table-outline', extension: 'vcf' },

  { icon: 'mdi mdi-file-powerpoint-outline', extension: 'ppt' },
  { icon: 'mdi mdi-file-powerpoint-outline', extension: 'odp' },

  { icon: 'mdi mdi-file-document-outline', extension: 'doc' },
  { icon: 'mdi mdi-file-document-outline', extension: 'docx' },
  { icon: 'mdi mdi-file-document-outline', extension: 'ebook' },
  { icon: 'mdi mdi-file-document-outline', extension: 'log' },
  { icon: 'mdi mdi-file-document-outline', extension: 'md' },
  { icon: 'mdi mdi-file-document-outline', extension: 'msg' },
  { icon: 'mdi mdi-file-document-outline', extension: 'odt' },
  { icon: 'mdi mdi-file-document-outline', extension: 'org' },
  { icon: 'mdi mdi-file-document-outline', extension: 'pages' },
  { icon: 'mdi mdi-file-document-outline', extension: 'rtf' },
  { icon: 'mdi mdi-file-document-outline', extension: 'rst' },
  { icon: 'mdi mdi-file-document-outline', extension: 'tex' },
  { icon: 'mdi mdi-file-document-outline', extension: 'txt' },
  { icon: 'mdi mdi-file-document-outline', extension: 'wpd' },
  { icon: 'mdi mdi-file-document-outline', extension: 'wps' },

  { icon: 'mdi mdi-file-pdf-outline', extension: 'pdf' },

  { icon: 'mdi mdi-file-video-outline', extension: '3g2' },
  { icon: 'mdi mdi-file-video-outline', extension: '3gp' },
  { icon: 'mdi mdi-file-video-outline', extension: 'aaf' },
  { icon: 'mdi mdi-file-video-outline', extension: 'asf' },
  { icon: 'mdi mdi-file-video-outline', extension: 'avchd' },
  { icon: 'mdi mdi-file-video-outline', extension: 'avi' },
  { icon: 'mdi mdi-file-video-outline', extension: 'drc' },
  { icon: 'mdi mdi-file-video-outline', extension: 'flv' },
  { icon: 'mdi mdi-file-video-outline', extension: 'm2v' },
  { icon: 'mdi mdi-file-video-outline', extension: 'm4p' },
  { icon: 'mdi mdi-file-video-outline', extension: 'm4v' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mkv' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mng' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mov' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mp2' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mp4' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mpe' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mpeg' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mpg' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mpv' },
  { icon: 'mdi mdi-file-video-outline', extension: 'mxf' },
  { icon: 'mdi mdi-file-video-outline', extension: 'nsv' },
  { icon: 'mdi mdi-file-video-outline', extension: 'ogg' },
  { icon: 'mdi mdi-file-video-outline', extension: 'ogv' },
  { icon: 'mdi mdi-file-video-outline', extension: 'ogm' },
  { icon: 'mdi mdi-file-video-outline', extension: 'qt' },
  { icon: 'mdi mdi-file-video-outline', extension: 'rm' },
  { icon: 'mdi mdi-file-video-outline', extension: 'rmvb' },
  { icon: 'mdi mdi-file-video-outline', extension: 'roq' },
  { icon: 'mdi mdi-file-video-outline', extension: 'srt' },
  { icon: 'mdi mdi-file-video-outline', extension: 'svi' },
  { icon: 'mdi mdi-file-video-outline', extension: 'vob' },
  { icon: 'mdi mdi-file-video-outline', extension: 'webm' },
  { icon: 'mdi mdi-file-video-outline', extension: 'wmv' },
  { icon: 'mdi mdi-file-video-outline', extension: 'yuv' },
];

export const keyedFileExtensionIcons = _.keyBy(fileExtensionIcons, 'extension');

export const getFileIconOrDefault = (extension: string | undefined, defaultIcon: string) => {
  const lowerExtension = _.toLower(extension);

  if (_.has(keyedFileExtensionIcons, lowerExtension)) {
    return keyedFileExtensionIcons[lowerExtension].icon;
  }

  return defaultIcon;
};

export default fileExtensionIcons;
