import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const makeGetOptionsByPoll = (pollId: number) => createSelector([
  (state: RootState) => state.polls.entities.pollOptions,
  (state: RootState) => state.polls.xrefs.pollOptions,
], (
  pollOptions,
  pollOptionsXrefs) => {
  return _.sortBy(_.map(pollOptionsXrefs[pollId], id => pollOptions[id]), o => o.name);
});

export const makeGetPollResultSummariesByPoll = (pollId: number) => createSelector([
  (state: RootState) => state.polls.entities.pollResultSummaries,
  (state: RootState) => state.polls.xrefs.pollResultSummaries,
], (
  pollResultSummaries,
  pollResultSummaryXrefs) => {
  return _.map(pollResultSummaryXrefs[pollId], id => pollResultSummaries[id]);
});

export const makeGetPollOperatorGroupsByPoll = (pollId: number) => createSelector([
  (state: RootState) => state.polls.entities.pollOperatorGroups,
  (state: RootState) => state.polls.xrefs.pollOperatorGroups,
], (
  pollOperatorGroups,
  pollOperatorGroupXrefs) => {
  return _.map(pollOperatorGroupXrefs[pollId], id => pollOperatorGroups[id]);
});

