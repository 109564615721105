import _ from 'lodash';

const betaAuthConfig = {
  domain: 'avsafetysolutions-beta.us.auth0.com',
  client_id: 'Hxw9Hf8b1WptXKHg45UyUOYaxb6tG7SD',
  audience: 'aviation-beta.controlsevenconcepts.com/api',
  redirect_uri: window.location.origin,
};

const prodAuthConfig = {
  domain: 'avsafetysolutions.us.auth0.com',
  client_id: 'NofYJzasTrbudf7RQs2ml3QFIGkSo3l4',
  audience: 'aviation.controlsevenconcepts.com/api',
  redirect_uri: window.location.origin,
};

const isProduction = _.includes([ 'aviation-safety.azurewebsites.net', 'app.avsafetysolutions.com', 'www.app.avsafetysolutions.com' ], window.location.host);

export const authConfig = isProduction ? prodAuthConfig : betaAuthConfig;
