import React from 'react';

import { PaginatedRequest } from 'core';

interface Props {
  total: number;
  request: PaginatedRequest;
}

const PaginationShowing = ({ total, request }: Props) => {
  const endRow = request.offset + request.pageSize;
  const start = total > 0 ? request.offset + 1 : 0;
  const end = endRow > total ? total : endRow;

  return (
    <span>
      {start} - {end} of {total}
    </span>
  );
};

export default React.memo(PaginationShowing);
