import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { makeFormHelper } from 'core';
import { ModifyPollCategory } from 'Modules/polls';

interface Props {
  isCreatingNewPollCategory?: boolean;
  pollCategory: ModifyPollCategory;
  setValues: (values: Partial<ModifyPollCategory>) => void;
}
const PollCategoryForm = ({ pollCategory, isCreatingNewPollCategory, ...props }: Props) => {
  const canGloballyEdit = true; // todo permissions useHasPermission(Permission.PollCategoriesEdit);

  function setWorkingPollCategoryValues(values: Partial<ModifyPollCategory>) {
    props.setValues(values);
  }

  const canEdit = isCreatingNewPollCategory || canGloballyEdit;
  const { getInputProps } = makeFormHelper(pollCategory, setWorkingPollCategoryValues);

  return (
    <>
      <FormGroup>
        <Label>Name</Label>
        <Input placeholder="Name..." disabled={!canEdit} {...getInputProps('name')} />
      </FormGroup>
    </>
  );
};

export default PollCategoryForm;
