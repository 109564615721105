import _ from 'lodash';

// @ts-ignore
export function setLoadingHash (currentHash, idOrIds: any | any[], isLoading: boolean): { [id: any]: any } {
  const updatedHash = { ...currentHash };

  _.each(_.isArray(idOrIds) ? idOrIds : [ idOrIds ], (id) => {
    if (isLoading) {
      updatedHash[id] = id;
    } else {
      delete updatedHash[id];
    }
  });

  return updatedHash;
}
