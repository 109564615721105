import React, { useEffect } from 'react';
import _ from 'lodash';
import config from '../config';
import ChatFeed from '../Components/Chat/ChatFeed';
import { useDispatch } from 'react-redux';
import useStateSelector from '../hooks/useStateSelector';
import * as peerOperatorsSelectors from '../Modules/peerOperators/selectors';
import { peerOperatorActions } from '../Modules/peerOperators/index';
import { OperatorGroupPeer } from '../Modules/peerOperators/models';

interface Props {
  selectedPeerOperator: OperatorGroupPeer;
}

const PeerOperatorChatFeed = function ({ selectedPeerOperator }: Props) {
  const dispatch = useDispatch();
  const nextPeerOperatorChatMessagePageRequest = useStateSelector(peerOperatorsSelectors.getNextChatMessagePageRequest(selectedPeerOperator.operatorPeerId));
  const peerChatMessages = useStateSelector(peerOperatorsSelectors.getSelectedRecipientChatMessages(selectedPeerOperator.operatorPeerId));

  useEffect(() => {
    dispatch(peerOperatorActions.async.getCurrentUserPeerOperators());
  }, []);

  useEffect(() => {
    if(!selectedPeerOperator) {
      return;
    }
    dispatch(peerOperatorActions.async.searchPeerOperatorCategories(selectedPeerOperator.operatorPeerId, config.defaultPagination));
  }, [ selectedPeerOperator ]);

  function submitMessage(messageText: string) {
    if (!_.trim(messageText)) {
      return;
    }

    return new Promise<void>((resolve) => {
      dispatch(peerOperatorActions.async.submitPeerOperatorMessage(selectedPeerOperator.operatorPeerId, {
        messageText: messageText,
      }, () => {
        resolve();
      }));
    });
  }


  function loadNextPage() {
    if(!nextPeerOperatorChatMessagePageRequest) {
      return;
    }
    dispatch(peerOperatorActions.async.searchPeerOperatorCategories(selectedPeerOperator.operatorPeerId, nextPeerOperatorChatMessagePageRequest));
  }

  return (
    <ChatFeed
      direction="col"
      canLoadMore={!!nextPeerOperatorChatMessagePageRequest}
      messages={peerChatMessages}
      onLoadMore={loadNextPage}
      input={{
        className: 'p-1',
        fileType: 'operatorMessage',
      }}
      onMessageSubmit={submitMessage}
    />
  );
};

export default PeerOperatorChatFeed;
