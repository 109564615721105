import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { AuditState } from './reducer';
import { auditsActions } from './actions';
import { setLoadingHash } from '../../helpers/loadingHelpers';

const loadingConfigs: LoadingActionConfig<AuditState>[] = [
  {
    start: auditsActions.DELETE_OPERATOR_AUDIT_REQUEST,
    stop: [ auditsActions.DELETE_OPERATOR_AUDIT_SUCCESS, auditsActions.DELETE_OPERATOR_AUDIT_FAILURE ],
    updater: (state: AuditState, isLoading, { operatorAuditId }): AuditState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          updatingOperatorAudits: setLoadingHash(state.loading.updatingOperatorAudits, operatorAuditId, isLoading),
        },
      };
    },
  },
  {
    start: [ auditsActions.UPDATE_OPERATOR_AUDIT_REQUEST, auditsActions.INSERT_OPERATOR_AUDIT_REQUEST ],
    stop: [ auditsActions.UPDATE_OPERATOR_AUDIT_SUCCESS, auditsActions.UPDATE_OPERATOR_AUDIT_FAILURE, auditsActions.INSERT_OPERATOR_AUDIT_SUCCESS, auditsActions.INSERT_OPERATOR_AUDIT_FAILURE ],
    updater: (state: AuditState, isLoading): AuditState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSavingOperatorAudit: isLoading,
        },
      };
    },
  },
  {
    start: auditsActions.GET_OPERATOR_AUDIT_REQUEST,
    stop: [ auditsActions.GET_OPERATOR_AUDIT_SUCCESS, auditsActions.GET_OPERATOR_AUDIT_FAILURE ],
    updater: (state: AuditState, isLoading): AuditState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingOperatorAudit: isLoading,
        },
      };
    },
  },
  {
    start: auditsActions.GET_OPERATOR_AUDIT_TASKS_REQUEST,
    stop: [ auditsActions.GET_OPERATOR_AUDIT_TASKS_SUCCESS, auditsActions.GET_OPERATOR_AUDIT_TASKS_FAILURE ],
    updater: (state: AuditState, isLoading): AuditState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingOperatorAuditTasks: isLoading,
        },
      };
    },
  },
  {
    start: auditsActions.SYNC_AUDIT_TEMPLATE_TO_AUDIT_REQUEST,
    stop: [ auditsActions.SYNC_AUDIT_TEMPLATE_TO_AUDIT_SUCCESS, auditsActions.SYNC_AUDIT_TEMPLATE_TO_AUDIT_FAILURE ],
    updater: (state: AuditState, isLoading): AuditState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSyncingAuditTemplateToAudit: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
