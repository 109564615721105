import React, { useState } from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';

import { SafetyCoachOperatorDashboardModel } from 'models';
import TaskStatusType from 'constants/TaskStatusType';

import NextAuditCard from '../Components/NextAuditCard';
import NumAuditsCurrentWeekCard from '../Components/NumAuditsCurrentWeekCard';
import SafetyCoachDashboardTask from './SafetyCoachDashboardTask';
import NumPendingApprovalCard from './Cards/NumPendingApprovalCard';
import NumPastDueTasksCard from '../Components/NumPastDueTasksCard';
import WithCategoryTasksIteration from 'Components/WithCategoryTasksIteration';
import AutoTaskModal from 'AviationSafetySolutions/Tasks/AutoTaskModal';

interface LocalProps {
  dashboard: SafetyCoachOperatorDashboardModel;
}

const SafetyCoachDashboard = (props: LocalProps) => {
  const [ selectedTaskId, setSelectedTaskId ] = useState<number | undefined>();

  const { dashboard } = props;
  const { nextAudit, numTasksForThisWeek, pastDueNeedingApprovalTasks, tasksForThisWeek, persistentTasks } = dashboard;

  const pendingTasksForThisWeek = _.filter(tasksForThisWeek, (t) => _.includes([ TaskStatusType.PendingApproval, TaskStatusType.NotApplicable ], t.currentStatus));
  const currentWeekTasksRequiringAttention = pastDueNeedingApprovalTasks.concat(pendingTasksForThisWeek);

  const pendingPersistentTasks = _.filter(persistentTasks, (t) => _.includes([ TaskStatusType.PendingApproval, TaskStatusType.NotApplicable ], t.currentStatus));

  const DashboardDivider = (
    <div className="py-3">
      <div className="h-100 border-left border-dark"/>
    </div>
  );

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mt-2">
        <div className="d-flex flex-row child-space-x-1 px-2 shadow-lg" style={{ 'background': '#ffffff42' }}>
          <div>
            <NextAuditCard audit={nextAudit}/>
          </div>

          {DashboardDivider}

          <div>
            <NumAuditsCurrentWeekCard tasksForThisWeek={numTasksForThisWeek}/>
          </div>

          {DashboardDivider}

          <div>
            <NumPastDueTasksCard pastDueTasks={pastDueNeedingApprovalTasks}/>
          </div>

          {DashboardDivider}

          <div>
            <NumPendingApprovalCard tasksPendingApproval={pendingTasksForThisWeek}/>
          </div>
        </div>
      </div>

      {_.size(currentWeekTasksRequiringAttention) > 0 && (
        <Row>
          <Col md={12}>
            <h4>Tasks for This Week</h4>

            <WithCategoryTasksIteration masonry appendUndefined tasks={currentWeekTasksRequiringAttention}
              idAccessor={(t) => t.taskId}>
              {({ task }) => (
                <SafetyCoachDashboardTask task={task} onTaskClick={(task) => setSelectedTaskId(task.taskId)}/>
              )}
            </WithCategoryTasksIteration>
          </Col>
        </Row>
      )}

      {_.size(pendingPersistentTasks) > 0 && (
        <Row>
          <Col md={12}>
            <h4>Persistent Tasks</h4>

            <WithCategoryTasksIteration masonry appendUndefined tasks={pendingPersistentTasks}
              idAccessor={(t) => t.taskId}>
              {({ task }) => (
                <SafetyCoachDashboardTask task={task} onTaskClick={(task) => setSelectedTaskId(task.taskId)}/>
              )}
            </WithCategoryTasksIteration>
          </Col>
        </Row>
      )}
      <AutoTaskModal show={!!selectedTaskId} taskId={selectedTaskId} hide={() => setSelectedTaskId(undefined)}/>
    </>
  );
};

export default SafetyCoachDashboard;
