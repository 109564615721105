import { usePaginatedSearch } from 'hooks';

import operatorGroupsActions from './actions';

export const usePaginatedOperatorGroupMessageSearch = ({ operatorGroupId }: { operatorGroupId: number }) => usePaginatedSearch({
  selectors: {
    pagination: (state) => state.operatorGroups.ui.operatorGroups.messagesPagination,
    entities: (state) => state.operatorGroups.entities.operatorGroupMessages,
    isSearching: (state) => state.operatorGroups.loading.isSearchingForOperatorMessages,
  },
  handlers: {
    fetchPage: (request) => operatorGroupsActions.async.searchOperatorGroupMessages(operatorGroupId, request),
  },
});

export const usePaginatedOperatorGroupMessageFileSearch = ({ operatorGroupId }: { operatorGroupId: number }) => usePaginatedSearch({
  selectors: {
    pagination: (state) => state.operatorGroups.ui.operatorGroups.messageFilesPagination,
    entities: (state) => state.operatorGroups.entities.operatorGroupMessageFileSummaries,
    isSearching: (state) => state.operatorGroups.loading.isSearchingForOperatorMessageFiles,
  },
  handlers: {
    fetchPage: (request) => operatorGroupsActions.async.searchOperatorGroupMessageFiles(operatorGroupId, request),
  },
});
