import React from 'react';
import { WaveLoader } from 'react-loaders-kit';

const Loader = () => {
  return (
    <WaveLoader loading />
  );
};

export default React.memo(Loader);
