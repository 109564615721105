import _ from 'lodash';

import { useStringPathId } from 'hooks';

const usePathId = (routeIdParam: string) => {
  const routeIdString = useStringPathId(routeIdParam);

  if (_.isNil(routeIdString)) {
    return undefined;
  }

  const routeParamsId = parseInt(routeIdString, 10);

  if (_.isNaN(routeParamsId)) {
    throw new Error('Route id cannot be parsed.');
  }

  return routeParamsId;
};

export default usePathId;
