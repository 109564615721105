import _ from 'lodash';
import { compose } from 'redux';

import { usersActions } from './actions';
import loadingConfigs from './loading';
import { Action, ReducerFunction, UserModel } from '../../models';
import { commonActions } from '../common/actions';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import { CreateUserRequest, UpdateUserRequest } from './models';

export interface UsersState {
  entities: {
    users: { [userId: string]: UserModel };
  };
  ui: {
    modifyingUser?: any;
    allUserIds: string[];
    maintenance: {
      workingCreateUser: Partial<CreateUserRequest>;
      workingUpdateUser: Partial<UpdateUserRequest>;
    };
  };
  loading: {
    isLoadingAllUsers: boolean;
    isLoadingUser: boolean;
    isInsertingUser: boolean;
    isUpdatingUser: boolean;
    isDeletingUser: boolean;
    isAgreeingToTermsOfService: boolean;
    isSettingProfilePicture: boolean;
    isDeletingProfilePicture: boolean;
    updatingUsers: {[userId: string]: string};
    invitingUsers: {[userId: string]: string};
    deletingUsers: {[userId: string]: string};
  };
}

const initState: UsersState = {
  entities: {
    users: {},
  },
  ui: {
    modifyingUser: undefined,
    allUserIds: [],
    maintenance: {
      workingCreateUser: {},
      workingUpdateUser: {},
    },
  },
  loading: {
    isLoadingAllUsers: false,
    isLoadingUser: false,
    isInsertingUser: false,
    isUpdatingUser: false,
    isDeletingUser: false,
    isAgreeingToTermsOfService: false,
    isSettingProfilePicture: false,
    isDeletingProfilePicture: false,
    updatingUsers: {},
    invitingUsers: {},
    deletingUsers: {},
  },
};

export default compose<ReducerFunction<UsersState>>(
  makeLoadingReducer<UsersState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): UsersState => {
  switch (action.type) {
    case usersActions.SET_WORKING_CREATE_USER_VALUES: {
      const { values }: { values: Partial<CreateUserRequest> } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          maintenance: {
            ...state.ui.maintenance,
            workingCreateUser: {
              ...state.ui.maintenance.workingCreateUser,
              ...values,
            },
          },
        },
      };
    }
    case usersActions.SET_WORKING_UPDATE_USER_VALUES: {
      const { values }: { values: Partial<UpdateUserRequest> } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          maintenance: {
            ...state.ui.maintenance,
            workingUpdateUser: {
              ...state.ui.maintenance.workingUpdateUser,
              ...values,
            },
          },
        },
      };
    }
    case usersActions.CANCEL_USER_MODIFICATION: {
      return {
        ...state,
        ui: {
          ...state.ui,
          maintenance: {
            ...state.ui.maintenance,
            workingCreateUser: {},
            workingUpdateUser: {},
          },
        },
      };
    }

    case usersActions.GET_ALL_USERS_SUCCESS: {
      const { users }: { users: UserModel[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          users: {
            ...state.entities.users,
            ..._.keyBy(users, u => u.userId),
          },
        },
        ui: {
          ...state.ui,
          allUserIds: _.map(users, u => u.userId),
        },
      };
    }
    case usersActions.GET_USER_SUCCESS: {
      const { user }: { user: UserModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          users: {
            ...state.entities.users,
            [user.userId]: user,
          },
        },
      };
    }
    case usersActions.SET_PROFILE_PICTURE_SUCCESS: {
      const { user }: { user: UserModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          users: {
            ...state.entities.users,
            [user.userId]: user,
          },
        },
      };
    }
    case usersActions.DELETE_PROFILE_PICTURE_SUCCESS: {
      const { userId }: { userId: string } = action.payload;

      if(!state.entities.users[userId]) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          users: {
            ...state.entities.users,
            [userId]: {
              ...state.entities.users[userId],
              profilePictureFile: undefined,
            },
          },
        },
      };
    }
    case usersActions.SET_USER_ACCOUNT_ENABLED_SUCCESS: {
      const { user }: { user: UserModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          users: {
            ...state.entities.users,
            [user.userId]: user,
          },
        },
        ui: {
          ...state.ui,
          modifyingUser: undefined,
        },
      };
    }
    case usersActions.INSERT_USER_SUCCESS: {
      const { user }: { user: UserModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          users: {
            ...state.entities.users,
            [user.userId]: user,
          },
        },
        ui: {
          ...state.ui,
          modifyingUser: undefined,
          allUserIds: state.ui.allUserIds.concat(user.userId),
        },
      };
    }
    case usersActions.DELETE_USER_SUCCESS: {
      const { userId }: { userId: string } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          users: _.omit(state.entities.users, userId),
        },
        ui: {
          ...state.ui,
          allUserIds: _.without(state.ui.allUserIds, userId),
        },
      };
    }
    case commonActions.AREA_LOCATION_CHANGE: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
});
