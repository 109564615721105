import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { usePollOptions, usePollResultSummaries, useSelectedOperatorOrThrow } from 'hooks';
import { Poll } from 'Modules/polls';
import { FlexLoader, FlexRow, Icon } from 'Components';

import OperatorPollCardQuery from './OperatorPollCardQuery';
import OperatorPollCardResults from './OperatorPollCardResults';

interface Props {
  poll: Poll;
}

enum View {
  Query,
  Results
}

const OperatorPollCard = ({ poll }: Props) => {
  const selectedOperator = useSelectedOperatorOrThrow();
  const [ pollResults, isLoadingPollResults ] = usePollResultSummaries(poll.id);
  const [ options, isLoadingOptions ] = usePollOptions(poll.id);
  const currentOperatorResults = pollResults.filter(pr => pr.operatorId === selectedOperator.operatorId);
  const isAnswered = _.size(currentOperatorResults) > 0;
  const [ currentView, setCurrentView ] = useState<View>(View.Query);

  useEffect(() => {
    setCurrentView(isAnswered ? View.Results : View.Query);
  }, [ isAnswered ]);

  const isLoading = isLoadingPollResults || isLoadingOptions;

  return (
    <Card>
      <CardHeader>
        <FlexRow justifyBetween alignCenter>
          <div>
            {poll.name}
          </div>
          {!isLoading && (
            <div>
              {isAnswered ? (
                <Icon title="You have provided a response to this poll!" className="text-success mdi-check-circle" size="md" />
              ) : (
                <Icon title="You have not provided a response to this poll yet" className="text-danger mdi-alert-circle" size="md" />
              )}
            </div>
          )}
        </FlexRow>
      </CardHeader>
      <CardBody>
        {isLoading && (
          <FlexLoader />
        )}
        {!isLoading && (
          <>
            {currentView === View.Query && (
              <OperatorPollCardQuery
                poll={poll}
                pollResults={pollResults}
                options={options}
                viewResults={() => setCurrentView(View.Results)}
              />
            )}
            {currentView === View.Results && (
              <OperatorPollCardResults
                poll={poll}
                results={pollResults}
                changeAnswer={() => setCurrentView(View.Query)}
              />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default OperatorPollCard;
