import React, { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { DomClass, FlexRow, OpenModalButton, Panel } from 'Components';
import { shareBoardActions, shareBoardSelectors } from '../../Modules/shareBoard';
import { ShareBoardCategory, ShareBoardFile } from '../../Modules/shareBoard/models';
import SelectCategoryPanel from './SelectCategoryPanel';
import CategoryFilesPanel from './CategoryFilesPanel';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useStateSelector, useThunkDispatch } from 'hooks';
import ApproveShareBoardFilesModal from './ApproveShareBoardFilesModal';
import OperatorGroupSearch from '../../AviationSafetySolutions/OperatorGroupSearch';
import { OperatorGroup } from '../../Modules/operatorGroups/models';

const AdminShareBoardPage = () => {
  const dispatch = useThunkDispatch();

  const selectedShareBoardCategory = useStateSelector(state => state.shareBoard.ui.selectedShareBoardCategory);
  const [ adminSelectedOperatorGroup, setAdminSelectedOperatorGroup ] = useState<OperatorGroup>();
  const approvedShareBoardFiles = useSelector(shareBoardSelectors.approvedShareBoardFiles(adminSelectedOperatorGroup?.operatorGroupId));
  const unapprovedShareBoardFiles = useSelector(shareBoardSelectors.unapprovedShareBoardFiles(adminSelectedOperatorGroup?.operatorGroupId));

  const filteredShareBoardFiles = useMemo(() => {
    if(selectedShareBoardCategory === null) {
      return approvedShareBoardFiles.filter(s => !s.categories.length);
    }

    if(!selectedShareBoardCategory) {
      return [];
    }

    return approvedShareBoardFiles.filter(s => s.categories.some(c => c.id === selectedShareBoardCategory.id));
  }, [ selectedShareBoardCategory, approvedShareBoardFiles ]);

  useEffect(() => {
    if(!adminSelectedOperatorGroup) {
      return;
    }

    refreshShareBoardFiles(adminSelectedOperatorGroup.operatorGroupId);
  }, [ adminSelectedOperatorGroup ]);

  function toggleShareBoardFileApproval(shareBoardFile: ShareBoardFile) {
    if(!adminSelectedOperatorGroup) {
      return;
    }

    if(shareBoardFile.approved) {
      dispatch(shareBoardActions.async.unApproveShareBoardFiles(adminSelectedOperatorGroup.operatorGroupId, {
        shareBoardFileIds: [ shareBoardFile.id ],
      }));
    } else {
      dispatch(shareBoardActions.async.approveShareBoardFiles(adminSelectedOperatorGroup.operatorGroupId, {
        shareBoardFileIds: [ shareBoardFile.id ],
      }));
    }
  }

  function refreshShareBoardFiles(operatorGroupId: number | undefined) {
    if(!operatorGroupId) {
      return;
    }

    dispatch(shareBoardActions.async.getAllUnapprovedShareBoardFilesForOperatorGroup(operatorGroupId));
    dispatch(shareBoardActions.async.getAllApprovedShareBoardFilesForOperatorGroup(operatorGroupId));
  }

  function setSelectedShareBoardCategory(shareBoardCategory: ShareBoardCategory | undefined | null) {
    dispatch(shareBoardActions.setSelectedShareBoardCategory(shareBoardCategory));
  }

  return (
    <>
      <Helmet>
        <title>Share Board</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white className="share-board" style={{ flex: 10 }}>
        <FlexRow childSpacingX={1} alignCenter className="px-2 pt-2 pb-0">
          <label className="mb-0">Admin: </label>

          <div style={{ minWidth: 250 }}>
            <OperatorGroupSearch
              value={adminSelectedOperatorGroup}
              onChange={setAdminSelectedOperatorGroup}
            />
          </div>

          <div>
            {unapprovedShareBoardFiles.length > 0 && (
              <div>
                <OpenModalButton
                  color="danger"
                  disabled={!adminSelectedOperatorGroup}
                  renderModal={(show, hide) => (
                    <ApproveShareBoardFilesModal
                      show={show}
                      operatorGroupId={adminSelectedOperatorGroup!.operatorGroupId}
                      unapprovedShareBoardFiles={unapprovedShareBoardFiles}
                      hide={hide}
                    />
                  )}
                >
                  {_.size(unapprovedShareBoardFiles)} Files Awaiting Approval
                </OpenModalButton>
              </div>
            )}
          </div>
        </FlexRow>

        {!adminSelectedOperatorGroup && (
          <>
            <div className="px-2 pt-2 pb-0">
              <h3>Select an Operator Group</h3>
            </div>
          </>
        )}

        {adminSelectedOperatorGroup && (
          <>
            {_.isUndefined(selectedShareBoardCategory) && (
              <SelectCategoryPanel
                allShareBoardFiles={approvedShareBoardFiles}
                setShareBoardCategory={setSelectedShareBoardCategory}
              />
            )}
            {!_.isUndefined(selectedShareBoardCategory) && (
              <CategoryFilesPanel
                operatorGroupId={adminSelectedOperatorGroup.operatorGroupId}
                shareBoardFiles={filteredShareBoardFiles}
                shareBoardCategory={selectedShareBoardCategory}
                toggleShareBoardFileApproval={toggleShareBoardFileApproval}
                clearShareBoardCategory={() => setSelectedShareBoardCategory(undefined)}
                refresh={() => refreshShareBoardFiles(adminSelectedOperatorGroup?.operatorGroupId)}
              />
            )}
          </>
        )}
      </Panel>
    </>
  );
};

export default AdminShareBoardPage;
