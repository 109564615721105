import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { RootState } from 'Modules/reducers';
import CollapsibleCardBox, { CollapsibleCardBoxRef } from 'Components/CollapsibleCardBox';
import FlexLoaderPanel from '../../Components/FlexLoaderPanel';
import PageLayout from '../../App/PageLayout';
import Helmet from 'react-helmet';
import { selectAllPrograms, selectSelectedProgram } from '../../Modules/programs/selectors';
import { Program } from '../../Modules/programs/models';
import ProgramsTable from './ProgramsTable';
import { getAllPrograms, getProgramFolders, selectProgram } from '../../Modules/programs/actions';
import CreateProgramModal from './ModifyProgramModal';
import ModifyProgramForm from './ModifyProgramForm';
import { getAllAuditTemplates } from '../../Modules/templates/actions';

interface LocalProps {}

interface LocalState {
  isShowingCreateModal: boolean;
}

interface StateProps {
  isLoadingAllPrograms: boolean;
  programs: Program[];
  selectedProgram?: Program;
}

interface DispatchProps {
  selectProgram: typeof selectProgram;
  getAllPrograms: typeof getAllPrograms;
  getAllAuditTemplates: typeof getAllAuditTemplates;
  getProgramFolders: typeof getProgramFolders;
}

type Props = LocalProps & StateProps & DispatchProps;

class ProgramsPage extends React.Component<Props, LocalState> {
  private programTableRef: CollapsibleCardBoxRef | null = null;

  constructor (props: Props) {
    super(props);

    this.state = {
      isShowingCreateModal: false,
    };
  }

  public componentDidMount (): void {
    this.props.getAllPrograms();
    this.props.getAllAuditTemplates();
  }

  private showCreateModal = () => {
    this.setState({ isShowingCreateModal: true });
  };

  private selectProgram = (program: Program) => {
    this.props.selectProgram(program);

    if (program) {
      this.props.getProgramFolders(program.programId);
      if (this.programTableRef) {
        this.programTableRef.setIsOpen(false);
      }
    }
  };

  private hideCreateModal = () => {
    this.setState({ isShowingCreateModal: false });
  };

  private setProgramCardRef = (c: CollapsibleCardBoxRef) => {
    this.programTableRef = c;
  }

  public render () {
    const { isShowingCreateModal } = this.state;
    const { isLoadingAllPrograms, selectedProgram, programs } = this.props;

    return (
      <>
        <Helmet>
          <title>Admin - Programs</title>
        </Helmet>

        <PageLayout pageTitle="Program Maintenance" breadcrumbs={[ 'Administration', 'Programs' ]}>
          <FlexLoaderPanel loadingClassName="p-5" isLoading={isLoadingAllPrograms}>
            <CollapsibleCardBox ref={this.setProgramCardRef} Header="Programs" defaultOpen>
              <Row className="mt-1">
                <Col md={12}>
                  <Button color="primary" onClick={this.showCreateModal}>Add Program...</Button>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={12}>
                  <ProgramsTable
                    selectProgram={this.selectProgram}
                    selectedProgramId={selectedProgram?.programId}
                    programs={programs}
                  />
                </Col>
              </Row>
            </CollapsibleCardBox>

            {selectedProgram && (
              <Card>
                <CardHeader className="bg-white">
                  Editing: {selectedProgram.name}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={{ size: 8 }}>
                      <ModifyProgramForm program={selectedProgram} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </FlexLoaderPanel>
        </PageLayout>

        <CreateProgramModal
          show={isShowingCreateModal}
          hide={this.hideCreateModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingAllPrograms: state.programs.loading.isLoadingAllPrograms,
    selectedProgram: selectSelectedProgram(state),
    programs: selectAllPrograms(state),
  };
};

const mapDispatchToProps = {
  selectProgram,
  getAllPrograms,
  getAllAuditTemplates,
  getProgramFolders,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ProgramsPage);
