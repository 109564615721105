import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getValueByKey } from 'core';
import { UpdatePoll } from 'Modules/polls/models';
import { pollsActions, pollsSelectors } from 'Modules/polls';
import { useMergedEntity, usePollForm, useRequiredPathId, useStateSelector } from 'hooks';
import {
  EditFormFooter,
  EditFormPanel,
  FlexLoader,
  FlexRow,
  OpenModalButton,
  Panel,
  PanelHeader,
  PollChart,
  PollForm,
} from 'Components';
import PollOptionsTable from './PollOptionsTable';
import useMemoizedIdStateSelector from '../../hooks/useMemoizedIdStateSelector';
import usePollResultSummaries from '../../hooks/usePollResultSummaries';
import classnames from 'classnames';
import PollResultSummaryTable from './PollResultSummaryTable';
import _ from 'lodash';
import MultipleOperatorGroupSelect from '../../AviationSafetySolutions/MultipleOperatorGroupSelect';
import useAllOperatorGroups from '../../hooks/useAllOperatorGroups';
import usePollOperatorGroups from '../../hooks/usePollOperatorGroups';
import { OperatorGroup } from '../../Modules/operatorGroups/models';
import MergePollOptionsModal from '../../Components/MergePollOptionsModal/MergePollOptionsModal';

enum Tab {
  Form,
  Results,
}

const ModifyPollPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pollId = useRequiredPathId('pollId');
  const workingPoll = useStateSelector((state) => state.polls.ui.maintenance.workingUpdatePoll);
  const isLoadingPoll = useStateSelector((state) => state.polls.loading.isLoadingPoll);
  const canEdit = true; //todo permissions useHasPermission(Permission.PollsEdit);
  const canDeactivate = true; //todo permissions useHasPermission(Permission.PollsDeactivate);
  const [ allOperatorGroups, isLoadingAllOperatorGroups ] = useAllOperatorGroups();
  const rootPoll = useStateSelector((state) => getValueByKey(state.polls.entities.polls, pollId));
  const pollOptions = useMemoizedIdStateSelector(pollId, pollsSelectors.makeGetOptionsByPoll);
  const [ pollResultSummaries ] = usePollResultSummaries(pollId);
  const [ pollOperatorGroups ] = usePollOperatorGroups(pollId);
  const poll = useMergedEntity(rootPoll, workingPoll);
  const { updatePoll, deletePoll, cancelPollModification, loading } = usePollForm();
  const [ currentTab, setCurrentTab ] = useState(Tab.Form);

  const selectedOperatorGroups = useMemo(() => pollOperatorGroups.map((pollOperatorGroup) => pollOperatorGroup.operatorGroup), [ pollOperatorGroups ]);

  useEffect(() => {
    dispatch(pollsActions.async.getPoll(pollId));
    dispatch(pollsActions.async.getAllPollOptions(pollId));
  }, [ pollId ]);

  function setWorkingPollValues(poll: Partial<UpdatePoll>) {
    dispatch(pollsActions.setWorkingUpdatePollValues(poll));
  }

  function addPollOption() {
    (async () => {
      const { dismiss, value: pollOptionName } = await Swal.fire({
        title: 'Add Poll Option',
        input: 'text',
        inputValue: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Add',
        inputValidator: (value: string) => {
          if (!(value || '').trim()) {
            return 'You must enter a poll option';
          }

          return null;
        },
      });

      if (dismiss) {
        return;
      }

      dispatch(pollsActions.async.insertPredefinedPollOption(pollId, { name: pollOptionName }));
    })();
  }

  function handlePollOperatorGroupUpdate(operatorGroups: readonly OperatorGroup[]) {
    const addedOperatorGroup = _.differenceBy(operatorGroups, selectedOperatorGroups, o => o.operatorGroupId);
    const removedOperatorGroup = _.differenceBy(selectedOperatorGroups, operatorGroups, o => o.operatorGroupId);

    if (addedOperatorGroup.length > 0) {
      dispatch(pollsActions.async.insertPollOperatorGroup(pollId, {
        operatorGroup: addedOperatorGroup[0],
      }));

      return;
    }


    if(removedOperatorGroup.length > 0) {
      const removedPollOperatorGroup = pollOperatorGroups.find(pog => pog.operatorGroup.operatorGroupId === removedOperatorGroup[0].operatorGroupId);

      if(!removedPollOperatorGroup) {
        return;
      }

      dispatch(pollsActions.async.deletePollOperatorGroup(pollId, removedPollOperatorGroup.id));
    }
  }

  function handleDelete() {
    if (!poll) {
      return;
    }

    deletePoll(poll)
      .then(() => {
        history.push('/admin/polls');
      });
  }

  if (isLoadingPoll) {
    return (
      <FlexLoader />
    );
  }

  if (!poll) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader white>
        Updating Poll
      </PanelHeader>

      <EditFormPanel
        Footer={(canEdit || canDeactivate) && (
          <EditFormFooter
            cancelButton={{
              onClick: cancelPollModification,
            }}
            deactivateButton={{
              onClick: handleDelete,
              isLoading: loading.isDeletingPoll,
              children: 'Delete',
              hidden: !canDeactivate,
            }}
            saveButton={{
              onClick: () => updatePoll(pollId, poll),
              isLoading: loading.isUpdatingPoll,
              hidden: !canEdit,
            }}
          />
        )}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: currentTab === Tab.Form })}
              onClick={() => setCurrentTab(Tab.Form)}
            >
              Edit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: currentTab === Tab.Results })}
              onClick={() => setCurrentTab(Tab.Results)}
            >
              Results
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentTab}>
          <TabPane className="child-spacing-y-2" tabId={Tab.Form}>
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label>Operator Group</Label>
                  <div>
                    <MultipleOperatorGroupSelect
                      isClearable={false}
                      isLoading={isLoadingAllOperatorGroups}
                      options={allOperatorGroups}
                      value={selectedOperatorGroups}
                      onChange={handlePollOperatorGroupUpdate}
                    />
                  </div>
                </FormGroup>

                <hr />

                <PollForm
                  poll={poll}
                  setValues={setWorkingPollValues}
                />

                <FormGroup>
                  <FlexRow alignCenter justifyBetween>
                    <div>
                      <Label>Options:</Label>
                    </div>
                    <div className="child-spacing-x-2">
                      <OpenModalButton
                        color="anchor"
                        renderModal={(show, hide) => (
                          <MergePollOptionsModal
                            pollId={pollId}
                            show={show}
                            hide={hide}
                          />
                        )}
                      >
                        Merge Poll Options
                      </OpenModalButton>

                      <Button color="anchor" onClick={addPollOption}><i className="mdi mdi-plus" /> Add Poll Option</Button>
                    </div>
                  </FlexRow>
                  <PollOptionsTable pollOptions={pollOptions} />
                </FormGroup>
              </Col>
            </Row>
          </TabPane>
          <TabPane className="child-spacing-y-2" tabId={Tab.Results}>
            <Row>
              <Col md={12}>
                {_.size(pollResultSummaries) > 0 ? (
                  <div style={{ height: 300 }}>
                    <PollChart
                      type={poll.defaultResultDisplayType}
                      results={pollResultSummaries}
                    />
                  </div>
                ) : (
                  <div className="text-center">
                    <Label>No Responses Have Been Submitted</Label>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Responses ({pollResultSummaries.length})</Label>
                </FormGroup>

                <PollResultSummaryTable
                  pollId={pollId}
                  pollResultSummaries={pollResultSummaries}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </EditFormPanel>
    </Panel>
  );
};

export default ModifyPollPanel;
