import React from 'react';
import _ from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { PollResultSummary } from 'Modules/polls';
import { sOrNoS } from 'helpers/listHelpers';
import { getValueByKey, schemeTableau10 } from 'core/index';


interface Props {
  results: PollResultSummary[];
  onOptionClick?: (optionId: number) => void;
}

const PollHorizontalBarChart = ({ results, ...props }: Props) => {
  const optionGroups = _.groupBy(results, r => r.optionId);

  let index = 0;
  const pieChartData = _.map(optionGroups, (options) => {
    const [ first ] = options;

    return {
      id: first.optionId,
      label: first.optionName,
      value: options.length,
      color: schemeTableau10[index++ % schemeTableau10.length],
    };
  });

  return (
    <ResponsiveBar
      data={pieChartData}
      layout="horizontal"
      colors={bar => bar.data.color}
      tooltip={({ color, value }) => (
        <div
          style={{
            padding: 12,
            color,
            background: '#222222',
          }}
        >
          <strong>
            {value} Response{sOrNoS(value)}
          </strong>
        </div>
      )}
      axisLeft={{
        tickValues: 1,
        format: (optionId) => {
          const values = getValueByKey(optionGroups, optionId);

          if(!values) {
            return optionId;
          }

          const [ first ] = values;

          return first.optionName;
        },
      }}
      axisBottom={{
        tickValues: 1,
      }}
      margin={{ top: 0, right: 20, bottom: 20, left: 180 }}
      onClick={({ indexValue }: any) => props.onOptionClick?.(indexValue)}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.2,
          ],
        ],
      }}
    />
  );
};

export default PollHorizontalBarChart;
