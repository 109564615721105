import React from 'react';
import classnames from 'classnames';

import Loader from './Loader';
import Flex from './Flex';

interface LocalProps {
  className?: string;
  overlay?: boolean;
}

const FlexLoader = (props: LocalProps) => {
  return (
    <Flex center fill className={classnames(props.className, { 'flex-overlay': props.overlay })}>
      <Loader />
    </Flex>
  );
};

export default React.memo(FlexLoader);
