import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedIdStateSelector, useStateSelector } from 'hooks';
import { PollResultSummary, pollsActions, pollsSelectors } from 'Modules/polls';

const usePollResultSummaries = (pollId: number): [ PollResultSummary[], boolean ] => {
  const dispatch = useDispatch();
  const isLoadingPollResultSummaries = useStateSelector(state => state.polls.loading.isLoadingPollResultSummaries);
  const pollResultSummaries = useMemoizedIdStateSelector(pollId, pollsSelectors.makeGetPollResultSummariesByPoll);
  useEffect(() => {
    dispatch(pollsActions.async.getAllPollResultSummaries(pollId));
  }, [ pollId ]);

  return [ pollResultSummaries, isLoadingPollResultSummaries ];
};

export default usePollResultSummaries;
