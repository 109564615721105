import _ from 'lodash';

import ActionType from 'constants/ActionType';

interface ValidateTaskRequest {
  summary?: string;
  description?: string;
  isParent?: boolean;
  actionTypeId?: number;
  uploadToDirectoryPath?: string;
}

export const validateTask = (task: ValidateTaskRequest) => {
  const validationMessages: string[] = [];

  if (_.isEmpty(task.summary)) {
    validationMessages.push('You must specify a summary for the task.');
  }

  if (!task.actionTypeId) {
    validationMessages.push('You must specify the action that needs to be taken for the task.');
  }

  if (task.actionTypeId === ActionType.Upload && !task.uploadToDirectoryPath) {
    validationMessages.push('You must specify an upload to directory when the action type is a file upload.');
  }

  return validationMessages;
};
