import React, { ReactNode } from 'react';

import { FlexExtendableProps, FlexLoader, Panel } from 'Components';

interface LocalProps extends Omit<FlexExtendableProps, 'children'> {
  isLoading: boolean;
  overlay?: boolean;
  loadingClassName?: string;
  children: ReactNode;
}

const FlexLoaderPanel = ({ isLoading, overlay, children, className, loadingClassName, ...rest }: LocalProps) => {
  return (
    <Panel {...rest} className={className}>
      {(!isLoading || overlay) && (
        <>
          {children}
        </>
      )}

      {isLoading && (
        <FlexLoader className={loadingClassName} overlay={overlay} />
      )}
    </Panel>
  );
};

export default React.memo(FlexLoaderPanel);
