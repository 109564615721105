import './library.scss';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PageLayout from 'App/PageLayout';
import useSelectedOperatorOrThrow from 'hooks/useSelectedOperatorOrThrow';
import { libraryActions } from 'Modules/library';

import OperatorLibrary from './OperatorLibrary';

const LibraryPage = () => {
  const dispatch = useDispatch();
  const operator = useSelectedOperatorOrThrow();

  useEffect(() => {
    dispatch(libraryActions.getOperatorFolders(operator.operatorId));
    dispatch(libraryActions.getOperatorFiles(operator.operatorId));
  });

  return (
    <PageLayout pageTitle="Library" breadcrumbs={[ 'Administration', 'Library' ]}>
      <div className="library-page pt-2 mb-1">
        <OperatorLibrary operator={operator}/>
      </div>
    </PageLayout>
  );
};

export default LibraryPage;
