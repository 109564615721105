import React, { ReactNode } from 'react';
import { useSelectedOperatorOrThrow, useStateSelector } from '../hooks';
import { makeGetEnabledOperatorFeatures } from '../Modules/operators/selectors';
import { OperatorFeatureEnum } from '../models';

interface LocalProps {
  feature: OperatorFeatureEnum;
  children: ReactNode;
}

const HideWithoutFeature = ({ feature, children }: LocalProps) => {
  const operator = useSelectedOperatorOrThrow();
  const enabledFeatures = useStateSelector(makeGetEnabledOperatorFeatures(operator.operatorId));


  if (!enabledFeatures[feature]) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default HideWithoutFeature;
