import { useEffect, useMemo } from 'react';
import { useStateSelector, useThunkDispatch } from './index';
import Fuse from 'fuse.js';
import { shareBoardActions, shareBoardSelectors } from '../Modules/shareBoard/index';

interface Options {
  searchText?: string;
  activeOnly?: boolean;
  enabled?: boolean;
}

const useShareBoardCategories = ({ searchText, enabled = true, activeOnly = true }: Options = {}) => {
  const dispatch = useThunkDispatch();
  const allShareBoardCategories = useStateSelector(shareBoardSelectors.allShareBoardCategories);
  const isLoadingAllShareBoardCategories = useStateSelector(state => state.shareBoard.loading.isLoadingAllShareBoardCategories);

  const activeShareBoardCategories = useMemo(() => {
    return allShareBoardCategories.filter(c => !c.deletedTimestamp);
  }, [ allShareBoardCategories ]);

  const sourceShareBoardCategories = activeOnly ? activeShareBoardCategories : allShareBoardCategories;

  const shareBoardCategoriesSearchIndex = useMemo(() => {
    return new Fuse(sourceShareBoardCategories, {
      keys: [ 'name', 'tags.name' ],
      threshold: 0.3,
    });
  }, [ sourceShareBoardCategories ]);

  const filteredCategories = useMemo(() => {
    if(!searchText?.trim()) {
      return sourceShareBoardCategories;
    }

    return shareBoardCategoriesSearchIndex.search(searchText).map(({ item }) => item);
  }, [ shareBoardCategoriesSearchIndex, searchText ]);

  useEffect(() => {
    if(!enabled) {
      return;
    }

    dispatch(shareBoardActions.async.getAllShareBoardCategories());
  }, [ enabled ]);

  return {
    shareBoardCategories: sourceShareBoardCategories,
    isLoadingAllShareBoardCategories,
    filteredCategories,
    shareBoardCategoriesSearchIndex,
  };
};

export default useShareBoardCategories;
