import React, { ReactNode } from 'react';
import _ from 'lodash';
import { CategoryModel } from '../Modules/categories/models';
import Masonry from 'react-masonry-css';
import WithCategoryIteration from './WithCategoryIteration';

interface LocalProps<T extends { categoryId?: string }> {
  appendUndefined: boolean;
  masonry: boolean;
  tasks: T[];
  idAccessor: (task: T) => any;
  children: (providedProps: { category: CategoryModel | undefined; task: T }) => ReactNode;
}

const WithCategoryTasksIteration = <T extends { categoryId?: string }>({ appendUndefined, tasks, masonry, children, ...props }: LocalProps<T>) => {
  return (
    <WithCategoryIteration appendUndefined={appendUndefined}>
      {({ category }) => {
        const filteredTasks = _.filter(tasks, t => (t.categoryId || undefined) === category?.categoryId);

        if (_.isEmpty(filteredTasks)) {
          return null;
        }

        const Items = _.map(filteredTasks, (task) => {
          const key = props.idAccessor(task);

          return (
            <React.Fragment key={key}>
              {children({ category, task })}
            </React.Fragment>
          );
        });

        if (masonry) {
          return (
            <Masonry breakpointCols={{ default: 3, 991: 1 }} className="row" columnClassName="task-masonry-cell">
              {Items}
            </Masonry>
          );
        }

        return (
          <>
            {Items}
          </>
        );
      }}
    </WithCategoryIteration>
  );
};

export default WithCategoryTasksIteration;
