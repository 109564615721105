import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../Modules/reducers';

const useThunkDispatch = (): ThunkDispatch<RootState, {}, Action<string>> => {
  return useDispatch();
};

export default useThunkDispatch;
