import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import ModalHeaderWithClose from 'Components/ModalHeaderWithClose';
import { ShareBoardCategory, ShareBoardFile } from '../../Modules/shareBoard/models';
import { useThunkDispatch } from '../../hooks/index';
import { shareBoardActions } from '../../Modules/shareBoard/index';
import _ from 'lodash';
import { FlexColumn, FlexRow, Panel, ShareBoardFileTile, VerticalOnlyScrollPanel } from '../../Components/index';
import Swal from 'sweetalert2';
import toastr from 'toastr';
import useShareBoardCategories from '../../hooks/useShareBoardCategories';

interface Props {
  show: boolean;
  operatorGroupId: number;
  unapprovedShareBoardFiles: ShareBoardFile[];
  hide: () => void;
}

const ApproveShareBoardFilesModal = ({ show, unapprovedShareBoardFiles, operatorGroupId, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const { shareBoardCategories } = useShareBoardCategories({ enabled: show });
  const [ selectedShareBoardFileId, setSelectedShareBoardFileId ] = useState<number>();
  const selectedShareBoardFile = _.find(unapprovedShareBoardFiles, (shareBoardFile) => shareBoardFile.id === selectedShareBoardFileId);

  function toggleShareBoardFileApproval(shareBoardFile: ShareBoardFile) {
    if(shareBoardFile.approved) {
      dispatch(shareBoardActions.async.unApproveShareBoardFiles(operatorGroupId, {
        shareBoardFileIds: [ shareBoardFile.id ],
      }));
    } else {
      dispatch(shareBoardActions.async.approveShareBoardFiles(operatorGroupId, {
        shareBoardFileIds: [ shareBoardFile.id ],
      }));
    }
  }

  function toggleShareBoardFileCategory(shareBoardFile: ShareBoardFile, shareBoardCategory: ShareBoardCategory) {
    const currentlySelected = shareBoardFile.categories.find((category) => category.id === shareBoardCategory.id);
    if(currentlySelected) {
      dispatch(shareBoardActions.async.removeShareBoardFileFromCategory(shareBoardFile.id, shareBoardCategory));
    } else {
      dispatch(shareBoardActions.async.addShareBoardFileToCategory(shareBoardFile.id, shareBoardCategory));
    }
  }

  async function deleteFile() {
    if(!selectedShareBoardFile) {
      return;
    }

    if (selectedShareBoardFile.libraryFileId) {
      const { dismiss } = await Swal.fire({
        title: 'Remove File from Share Board?',
        text: 'Are you sure you want to remove this file from the share board. The file will remain in the operators library, but be removed from the share board.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove It',
      });

      if (dismiss) {
        return;
      }
    } else {
      const { dismiss } = await Swal.fire({
        title: 'Delete Share Board File?',
        text: 'Are you sure you want to delete this file. The file will be entirely removed. This cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete It',
      });

      if (dismiss) {
        return;
      }
    }

    dispatch(shareBoardActions.async.deleteShareBoardFiles(operatorGroupId, [ selectedShareBoardFile.id ]))
      .then(() => {
        toastr.clear();
        toastr.success('File deleted.');
      });
  }

  return (
    <>
      <Modal isOpen={show} toggle={props.hide} size="xl">
        <ModalHeaderWithClose hide={props.hide}>
          Approve Share Board Files
        </ModalHeaderWithClose>
        <ModalBody>
          <Panel style={{ maxHeight: '80vh' }}>
            <FlexRow fill overflowHidden childSpacingX={2}>
              <FlexColumn style={{ flex: 3 }} fill overflowHidden>
                <VerticalOnlyScrollPanel>
                  <Row className="pr-1 child-spacing-y-2">
                    {
                      _.map(unapprovedShareBoardFiles, (unapprovedShareBoardFile) => {
                        const isShareBoardFileSelected = selectedShareBoardFile?.id === unapprovedShareBoardFile.id;
                        return (
                          <Col key={unapprovedShareBoardFile.id} md={12} className="overflow-hidden">
                            <ShareBoardFileTile
                              operatorGroupId={operatorGroupId}
                              style={{ backgroundColor: isShareBoardFileSelected ? '#efeded' : undefined }}
                              shareBoardFile={unapprovedShareBoardFile}
                              toggleShareBoardFileApproval={toggleShareBoardFileApproval}
                              onClick={() => isShareBoardFileSelected ? setSelectedShareBoardFileId(undefined) : setSelectedShareBoardFileId(unapprovedShareBoardFile.id)}
                              selection={{
                                isSelected: selectedShareBoardFile?.id === unapprovedShareBoardFile.id,
                                toggleSelection: () => isShareBoardFileSelected ? setSelectedShareBoardFileId(undefined) : setSelectedShareBoardFileId(unapprovedShareBoardFile.id),
                              }}
                            />
                          </Col>
                        );
                      })
                    }
                  </Row>
                </VerticalOnlyScrollPanel>
              </FlexColumn>
              <FlexColumn style={{ flex: 9 }} fill overflowHidden>
                {selectedShareBoardFile && (
                  <Panel>
                    <VerticalOnlyScrollPanel>
                      <Row>
                        <Col md={{ size: 4 }}>
                          <ShareBoardFileTile
                            shareBoardFile={selectedShareBoardFile}
                            operatorGroupId={operatorGroupId}
                            toggleShareBoardFileApproval={toggleShareBoardFileApproval}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={12}>
                          <Label>Categories:</Label>

                          <div>
                            {
                              shareBoardCategories.map((category) => {
                                const isInCategory = _.some(selectedShareBoardFile.categories, (selectedShareBoardFileCategory) => selectedShareBoardFileCategory.id === category.id);
                                return (
                                  <Button
                                    key={category.id}
                                    color={isInCategory ? 'primary' : 'light'}
                                    className="mr-2 mb-2"
                                    onClick={() => toggleShareBoardFileCategory(selectedShareBoardFile, category)}
                                  >
                                    {category.name}
                                  </Button>
                                );
                              })
                            }
                          </div>
                        </Col>
                      </Row>
                    </VerticalOnlyScrollPanel>

                    <FlexRow childSpacingX={2} className="mt-2" justifyBetween>
                      <div />
                      <FlexRow childSpacingX={1}>
                        <FlexColumn>
                          <Button
                            color="success"
                            onClick={() => {
                              dispatch(shareBoardActions.async.approveShareBoardFiles(operatorGroupId, {
                                shareBoardFileIds: [ selectedShareBoardFile.id ],
                              }));
                            }}
                          >
                            Approve
                          </Button>
                        </FlexColumn>
                        <FlexColumn>
                          <Button
                            color="danger"
                            onClick={deleteFile}
                          >
                            Delete
                          </Button>
                        </FlexColumn>
                      </FlexRow>
                    </FlexRow>
                  </Panel>
                )}
              </FlexColumn>
            </FlexRow>
          </Panel>
        </ModalBody>
      </Modal>
    </>
  );
};


export default ApproveShareBoardFilesModal;
