import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreateShareBoardCategory, ShareBoardCategory } from 'Modules/shareBoard/models';
import { shareBoardActions } from 'Modules/shareBoard';
import { getNewShareBoardCategory } from 'Modules/shareBoard/instantiations';
import { useMergedEntity, useShareBoardCategoryForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, Panel, PanelHeader, ShareBoardCategoryForm } from 'Components';

const CreateShareBoardCategoryPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootShareBoardCategory = useMemo(() => getNewShareBoardCategory(), []);
  const workingShareBoardCategory = useStateSelector((state) => state.shareBoard.ui.maintenance.workingCreateCategory);
  const shareBoardCategory = useMergedEntity(rootShareBoardCategory, workingShareBoardCategory);
  const { insertShareBoardCategory, cancelShareBoardCategoryModification, loading } = useShareBoardCategoryForm();

  function setWorkingShareBoardCategoryValues(shareBoardCategory: Partial<CreateShareBoardCategory>) {
    dispatch(shareBoardActions.setWorkingCreateShareBoardValues(shareBoardCategory));
  }

  function handleSave() {
    if (!shareBoardCategory) {
      return;
    }
    insertShareBoardCategory(shareBoardCategory)
      .then((shareBoardCategory: ShareBoardCategory) => {
        history.push(`/admin/shareBoardCategories/${shareBoardCategory.id}`);
      });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New Share Board Category</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelShareBoardCategoryModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingShareBoardCategory,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <ShareBoardCategoryForm
              isCreatingNewShareBoardCategory
              shareBoardCategory={shareBoardCategory}
              setValues={setWorkingShareBoardCategoryValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreateShareBoardCategoryPanel;
