import React from 'react';
import _ from 'lodash';
import DashboardCard from './DashboardCard';
import { pluralCoalesce } from '../../../helpers/listHelpers';

interface LocalProps {
    pastDueTasks: number | any[];
}

const NumPastDueTasksCard = ({ pastDueTasks }: LocalProps) => {
  return (
    <DashboardCard
      icon={<i className="mdi mdi-playlist-remove mdi-36px" />}
      heading="Past Due:"
      body={`${_.isArray(pastDueTasks) ? _.size(pastDueTasks) : pastDueTasks} ${pluralCoalesce(pastDueTasks, 'Task', 'Tasks')}`}
    />
  );
};

export default NumPastDueTasksCard;
