import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { makeFormHelper } from 'core';
import { UpsertShareBoardCategory } from 'Modules/shareBoard/models';
import { Permission } from '../../../Modules/roles/index';
import { useCurrentUserPermissionChecker } from '../../../hooks/index';

interface Props {
  isCreatingNewShareBoardCategory?: boolean;
  shareBoardCategory: UpsertShareBoardCategory;
  setValues: (values: Partial<UpsertShareBoardCategory>) => void;
}

const ShareBoardCategoryForm = ({ shareBoardCategory, isCreatingNewShareBoardCategory, ...props }: Props) => {
  const canGloballyEdit = useCurrentUserPermissionChecker()(Permission.GlobalShareBoardMaintenance);

  function setWorkingShareBoardCategoryValues(values: Partial<UpsertShareBoardCategory>) {
    props.setValues(values);
  }

  const canEdit = isCreatingNewShareBoardCategory || canGloballyEdit;
  const { getInputProps } = makeFormHelper(shareBoardCategory, setWorkingShareBoardCategoryValues);

  return (
    <>
      <FormGroup>
        <Label>Name</Label>
        <Input placeholder="Name..." disabled={!canEdit} {...getInputProps('name')} />
      </FormGroup>
    </>
  );
};

export default ShareBoardCategoryForm;
