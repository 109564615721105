import React, { useState } from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import FlexRow from 'Components/FlexRow';
import ModalHeaderWithClose from 'Components/ModalHeaderWithClose';
import { TreeNode } from 'Modules/library/models';
import UploadDirectoryPathSelector from 'AviationSafetySolutions/UploadDirectoryPathSelector';
import MoveToFolderFileTable, { MoveToFolderFileAccessors } from './MoveToFolderFileTable';

interface LocalProps<T> {
  show: boolean;
  folder: TreeNode<T>;
  hide: () => void;
  deleteFolder: (folder: TreeNode<T>, moveFilesToDirectoryPath: string) => Promise<void>;
  accessors: MoveToFolderFileAccessors<T>;
}

const DeleteFileTreeFolderModal = <T extends any>({ show, folder, accessors, ...props }: LocalProps<T>) => {
  const [ moveFilesToDirectoryPath, setMoveFilesToDirectoryPath ] = useState<string>('');

  const descendantFiles = folder.fileTree.getDescendantDataForNode(folder);

  function deleteFolder() {
    props.deleteFolder(folder, moveFilesToDirectoryPath)
      .then(() => {
        props.hide();
      });
  }

  return (
    <>
      <Modal isOpen={show} toggle={props.hide} size="lg">
        <ModalHeaderWithClose hide={props.hide}>
          Delete Folder
        </ModalHeaderWithClose>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Move Files to:</Label>

                <UploadDirectoryPathSelector
                  value={moveFilesToDirectoryPath}
                  onChange={setMoveFilesToDirectoryPath}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <MoveToFolderFileTable
                files={descendantFiles}
                accessors={accessors}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FlexRow fill justifyBetween alignCenter>
            <div>
              <Button color="light" onClick={props.hide}>Close</Button>
            </div>
            <div>
              <Button color="primary" onClick={deleteFolder}>Delete Folder</Button>
            </div>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteFileTreeFolderModal;
