import React from 'react';

import CommonSearch, { CommonSearchProps } from './CommonSearch';
import appConfig from 'config';
import { callApi } from '../../api/ApiDao';
import { ReferenceMaterial } from '../../Modules/referenceMaterials/models';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../Modules/site/selectors';

interface Props extends CommonSearchProps<ReferenceMaterial> {
  debounceWait?: number;
  minInputLength?: number;
}

const ReferenceFileSearch = ({ minInputLength = 0, debounceWait = 300, ...props }: Props) => {
  const accessToken = useSelector(getAccessToken);

  async function searchForData(term: string) {
    const referenceMaterials = await callApi<ReferenceMaterial[]>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent.get('/api/v1/referenceMaterials/search').query({
        ...appConfig.defaultPagination,
        term,
      }),
    });

    return referenceMaterials;
  }

  return (
    <CommonSearch
      {...props}
      modal
      searchOnFocus
      getOptionLabel={((option: ReferenceMaterial) => option.name) as any}
      getOptionValue={((option: ReferenceMaterial) => option.referenceFileId) as any}
      debounceWait={debounceWait}
      minInputLength={minInputLength}
      searchForData={searchForData}
    />
  );
};

export default ReferenceFileSearch;
