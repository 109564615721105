import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TaskHistoryTable from './TaskHistoryTable';
import { tasksActions, tasksSelectors } from 'Modules/tasks';
import useStateSelector from '../../hooks/useStateSelector';

interface Props {
  taskId: number;
}

const TaskHistory = ({ taskId }: Props) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const taskHistory = useStateSelector(state => tasksSelectors.getTaskStatusesForTask(state, taskId));

  useEffect(() => {
    dispatch(tasksActions.getTaskHistory(taskId));
  }, [ taskId ]);

  return (
    <TaskHistoryTable history={taskHistory}/>
  );
};

export default TaskHistory;
