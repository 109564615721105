import './share-board-file-tile.scss';

import React from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import moment from 'moment';
import classnames from 'classnames';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import Swal from 'sweetalert2';

import { getFileNameWithoutExtension } from 'core/files';
import { stopPropagationAnd, stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import useFileDownloader from 'hooks/useFileDownloader';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { ShareBoardCategory, ShareBoardFile } from 'Modules/shareBoard/models';
import { shareBoardActions } from 'Modules/shareBoard';
import {
  FilePreview,
  FlexRow,
  HideIfNotOperatorAndDoesNotHavePermission, HideWithoutPermission,
  LoadableButton,
  RealtimeHumanizeDateTime,
  ShareBoardPreviewModal,
} from 'Components';
import { previewFileHandlers } from 'Components/PreviewFileModal/PreviewFileModal';
import { Permission } from '../../Modules/roles/index';
import { useCurrentUserPermissionChecker } from 'hooks';
import ShareBoardFileCategoryModal from '../../Pages/ShareBoard/ShareBoardFileCategoryModal';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  shareBoardFile: ShareBoardFile;
  selection?: {
    isSelected: boolean;
    toggleSelection: (shareBoardFile: ShareBoardFile) => void;
  };
  operatorGroupId: number | undefined,
  toggleShareBoardFileApproval: (shareBoardFile: ShareBoardFile) => void;
  onClick?: (shareBoardFile: ShareBoardFile) => void;
  onCategoryToggled?: (shareBoardFile: ShareBoardFile, shareBoardCategory: ShareBoardCategory) => void;
  children?: React.ReactNode;
}

const ShareBoardFileTile = ({ className, shareBoardFile, selection, style, children, operatorGroupId, ...props }: Props) => {
  const dispatch = useThunkDispatch();
  const { isShowing: isShowingPreviewModal, show: showPreviewModal, hide: hidePreviewModal } = useModalState();
  const { isShowing: isShowingCategoryModal, show: showCategoryModal, hide: hideCategoryModal } = useModalState();
  const extension = shareBoardFile.file.originalFilename.split('.').pop()!;
  const [ downloadFile, isDownloading ] = useFileDownloader((superagent) => superagent.post('/api/v1/shareBoardFiles/download').send([ shareBoardFile.id ]));
  const currentUserHasPermission = useCurrentUserPermissionChecker();
  const canApproveShareboardFiles = currentUserHasPermission(Permission.GlobalShareBoardMaintenance);
  const previewIsSupported = _.some(previewFileHandlers, (fh) => _.includes(fh.extensions, extension));

  function renameFile() {
    (async () => {
      const { dismiss, value: updatedFileName } = await Swal.fire({
        title: 'Rename File',
        text: 'Provide a new name for the file:',
        input: 'text',
        inputValue: (getFileNameWithoutExtension(shareBoardFile.fileName || '')),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Rename File',
        inputValidator: (value: string) => {
          if (!(value || '').trim()) {
            return 'You must enter a valid file name';
          }

          return null;
        },
      });

      if (dismiss) {
        return;
      }

      dispatch(shareBoardActions.async.updateShareBoardName(shareBoardFile.id, {
        updatedFilename: updatedFileName,
      }));
    })();
  }

  async function deleteFile() {
    if (shareBoardFile.libraryFileId) {
      const { dismiss } = await Swal.fire({
        title: 'Remove File from Share Board?',
        text: 'Are you sure you want to remove this file from the share board. The file will remain in the operators library, but be removed from the share board.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove It',
      });

      if (dismiss) {
        return;
      }
    } else {
      const { dismiss } = await Swal.fire({
        title: 'Delete Share Board File?',
        text: 'Are you sure you want to delete this file. The file will be entirely removed. This cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete It',
      });

      if (dismiss) {
        return;
      }
    }

    dispatch(shareBoardActions.async.deleteShareBoardFiles(operatorGroupId, [ shareBoardFile.id ]))
      .then(() => {
        toastr.clear();
        toastr.success('File deleted.');
      });
  }

  const approvalStatusIcon = (<i
    className={classnames('mdi mdi-24px', {
      'mdi-eye-off text-danger': !shareBoardFile.approved,
      'mdi-eye-check text-success': shareBoardFile.approved,
    })}
    title={classnames({
      'This file is pending administrator approval. It can currently only be viewed by administrators and the operator who uploaded it until it is approved.': !shareBoardFile.approved,
      'This file is approved and all operators with share board functionality can see it.': shareBoardFile.approved,
    })}
  />);

  return (
    <>
      <div
        className={classnames('share-board-file-tile file-tile', className, { 'unapproved': !shareBoardFile.approved })}
        onClick={() => props.onClick?.(shareBoardFile)}
        style={style}
      >
        <div className="text-center">
          <div className="position-relative overflow-hidden">
            <FilePreview extension={extension} sasUrl={shareBoardFile.thumbnailFile?.sasUrl} />
          </div>

          <div className="text-center">
            <h4 className="mb-0">
              <span className="folder-name">{shareBoardFile.fileName}</span>
            </h4>

            <div className="file-name">{shareBoardFile.file.originalFilename}</div>

            <div className="uploaded-by">
              {shareBoardFile.operatorCodeName && (
                <div>Uploaded By: {shareBoardFile.operatorCodeName}</div>
              )}
            </div>

          </div>

          <h5 className="m-0"><small><RealtimeHumanizeDateTime date={moment.utc(shareBoardFile.file.createdTimestamp)}/></small></h5>

          {children}
        </div>

        <div className="top-left-overlay p-1">
          <FlexRow alignCenter childSpacingX={1}>
            <HideIfNotOperatorAndDoesNotHavePermission isOperatorUser={shareBoardFile.isOperatorUser} permission={Permission.GlobalShareBoardMaintenance}>
              {selection && (
                <div>
                  <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => selection.toggleSelection(shareBoardFile))}>
                    <i className={classnames('mdi mdi-24px', { 'mdi-checkbox-blank-outline': !selection.isSelected, 'mdi-checkbox-marked-outline': selection.isSelected })} />
                  </Button>
                </div>
              )}

              <div>
                {canApproveShareboardFiles ?
                  <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => props.toggleShareBoardFileApproval(shareBoardFile))}>
                    {approvalStatusIcon}
                  </Button> :
                  null
                }
              </div>
            </HideIfNotOperatorAndDoesNotHavePermission>
          </FlexRow>
        </div>

        <div className="top-right-overlay p-1">
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <LoadableButton LoadingLabel={null} isLoading={isDownloading} color="anchor" onClick={stopPropagationPreventDefaultAnd(downloadFile)}>
                <i className="mdi mdi-24px mdi-download"/>
              </LoadableButton>
            </div>

            {previewIsSupported && (
              <div>
                <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(showPreviewModal)}>
                  <i className="mdi mdi-24px mdi-eye"/>
                </Button>
              </div>
            )}

            <HideIfNotOperatorAndDoesNotHavePermission isOperatorUser={shareBoardFile.isOperatorUser} permission={Permission.GlobalShareBoardMaintenance}>
              <div>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="anchor" caret onClick={stopPropagationAnd()}>
                    <i className="mdi mdi-24px mdi-cog"/>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={stopPropagationPreventDefaultAnd(renameFile)}>
                      <i className="mdi mdi-pencil"/> Edit Name
                    </DropdownItem>
                    <HideWithoutPermission permission={Permission.GlobalShareBoardMaintenance}>
                      <DropdownItem onClick={stopPropagationPreventDefaultAnd(showCategoryModal)}>
                        <i className="mdi mdi-pencil"/> Change Categories
                      </DropdownItem>
                    </HideWithoutPermission>
                    <DropdownItem divider />
                    <DropdownItem onClick={stopPropagationPreventDefaultAnd(deleteFile)}>
                      <i className="mdi mdi-trash-can"/> Delete File
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </HideIfNotOperatorAndDoesNotHavePermission>
          </FlexRow>
        </div>
      </div>

      <ShareBoardFileCategoryModal
        show={isShowingCategoryModal}
        shareBoardFile={shareBoardFile}
        operatorGroupId={operatorGroupId}
        onCategoryToggled={(shareBoardCategory) => props.onCategoryToggled?.(shareBoardFile, shareBoardCategory)}
        hide={hideCategoryModal}
      />

      <ShareBoardPreviewModal
        show={isShowingPreviewModal}
        shareBoardId={shareBoardFile.id}
        hide={hidePreviewModal}
      />
    </>
  );
};

export default ShareBoardFileTile;
