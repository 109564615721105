import React from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useStringPathId } from 'hooks';
import { DomClass, Panel } from 'Components';

import OperatorPollsTab from './OperatorPollsTab';

export enum AnalyticsTab {
  Polls = 'polls',
}

const AnalyticsPage = () => {
  const history = useHistory();
  const currentTab = useStringPathId('tabId');

  return (
    <>
      <Helmet>
        <title>Analytics</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel className="p-2" white>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: currentTab === AnalyticsTab.Polls })}
              onClick={() => history.push(`/analytics/${AnalyticsTab.Polls}`)}
            >
              Polls
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="flex-tabs" activeTab={currentTab}>
          <TabPane className="child-spacing-y-2" tabId={AnalyticsTab.Polls}>
            <Switch>
              <Route path={`/analytics/${AnalyticsTab.Polls}/:categoryId([0-9]+)?`} component={OperatorPollsTab}/>
              <Redirect to={`/analytics/${AnalyticsTab.Polls}`} />
            </Switch>
          </TabPane>
        </TabContent>
      </Panel>
    </>
  );
};

export default AnalyticsPage;
