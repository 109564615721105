import React from 'react';
import * as _ from 'lodash';

import { ClickableDiv, FlexRow, Panel, ShareBoardFileTile, VerticalOnlyScrollPanel } from 'Components';
import { ShareBoardCategory, ShareBoardFile } from 'Modules/shareBoard/models';
import { Card, Col, Row } from 'reactstrap';
import RatingRow from './RatingRow';

interface Props {
  shareBoardCategory: ShareBoardCategory | null;
  shareBoardFiles: ShareBoardFile[];
  operatorGroupId: number | undefined;
  toggleShareBoardFileApproval: (shareBoardFile: ShareBoardFile) => void;
  refresh: () => void;
  clearShareBoardCategory: () => void;
}

const CategoryFilesPanel = ({ shareBoardCategory, shareBoardFiles, operatorGroupId, ...props }: Props) => {
  return (
    <Panel>
      <FlexRow childSpacingX={2} alignCenter className="px-2 py-1">
        <ClickableDiv
          onClick={() => {
            props.clearShareBoardCategory();
          }}
        >
          &lt;&lt; Back
        </ClickableDiv>
        <h4>{shareBoardCategory ? shareBoardCategory.name : <em>Unspecified Category</em>}</h4>
      </FlexRow>

      <VerticalOnlyScrollPanel className="p-2">
        <Row className="child-spacing-y-2">
          {
            _.map(shareBoardFiles, (shareBoardFile) => {
              return (
                <Col key={shareBoardFile.id} className="d-flex flex-column" md={6} lg={4} xl={3}>
                  <Card className="flex">
                    <ShareBoardFileTile
                      operatorGroupId={operatorGroupId}
                      shareBoardFile={shareBoardFile}
                      toggleShareBoardFileApproval={props.toggleShareBoardFileApproval}
                      onCategoryToggled={props.refresh}>
                      <FlexRow justifyCenter>
                        <RatingRow shareBoardFile={shareBoardFile}/>
                      </FlexRow>
                    </ShareBoardFileTile>
                  </Card>
                </Col>
              );
            })
          }
        </Row>
      </VerticalOnlyScrollPanel>
    </Panel>
  );
};

export default CategoryFilesPanel;
