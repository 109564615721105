import React, { useState } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';
import { DomClass, ListPanel, ListPanelHeader, NavMenuButton, Panel, SplitPanel } from 'Components';

import CreateShareBoardCategoryPanel from './CreateShareBoardCategoryPanel';
import ModifyShareBoardCategoryPanel from './ModifyShareBoardCategoryPanel';
import classnames from 'classnames';
import useShareBoardCategories from '../../hooks/useShareBoardCategories';


const ShareBoardCategoryMaintenancePage = () => {
  const [ searchText, setSearchText ] = useState<string>('');
  const { shareBoardCategories, isLoadingAllShareBoardCategories, filteredCategories } = useShareBoardCategories({
    searchText,
    activeOnly: false,
  });

  return (
    <>
      <Helmet>
        <title>Share Board Category Maintenance</title>
      </Helmet>

      <DomClass className="flex-enabled" element={document.body} />

      <Panel white>
        <SplitPanel panes={[
          {
            ratio: 1,
            Component: (
              <>
                <ListPanel
                  Header={(
                    <ListPanelHeader>Share Board Categories ({_.size(shareBoardCategories)})</ListPanelHeader>
                  )}
                  isLoading={isLoadingAllShareBoardCategories}
                  search={{
                    inputProps: { autoFocus: true },
                    isLoading: isLoadingAllShareBoardCategories,
                    value: searchText,
                    onChange: (e) => setSearchText(e.target.value),
                  }}
                  Tools={(
                    <Button tag={Link} to="/admin/shareBoardCategories/create" color="anchor">
                      <i className="mdi mdi-24px mdi-plus" />
                    </Button>
                  )}
                >
                  <div className="p-1">
                    {
                      _.map(filteredCategories, (shareBoardCategory) => {
                        return (
                          <NavMenuButton key={shareBoardCategory.id} to={`/admin/shareBoardCategories/${shareBoardCategory.id}`}>
                            <span
                              className={classnames({ 'text-strikethrough': shareBoardCategory.deletedTimestamp })}
                            >
                              {shareBoardCategory.name}
                            </span>
                          </NavMenuButton>
                        );
                      })
                    }
                  </div>
                </ListPanel>
              </>
            ),
          },
          {
            ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
            Component: (
              <Switch>
                <Route exact path="/admin/shareBoardCategories/create" component={CreateShareBoardCategoryPanel} />
                <Route exact path="/admin/shareBoardCategories/:shareBoardCategoryId([0-9]+)" component={ModifyShareBoardCategoryPanel} />
              </Switch>
            ),
          },
        ]}/>
      </Panel>
    </>
  );
};

export default ShareBoardCategoryMaintenancePage;
