import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import { ReactSortable } from 'react-sortablejs';
import { Button, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { convertToRaw, EditorState } from 'draft-js';

import { AssignedTaskModel, OperatorAuditModel, UpdateTaskPosition, UserModel } from 'models';
import TaskMaintenanceForm from 'AviationSafetySolutions/TaskMaintenance/TaskMaintenanceForm/TaskMaintenanceForm';
import { useDispatch, useSelector } from 'react-redux';
import { getParentChildrenTasks, insertTask, updateTask, updateTaskPositions } from 'Modules/tasks/actions';
import { RootState } from 'Modules/reducers';
import { useOnModalHide, useOnModalShow } from 'hooks';
import { getEditorStateFromString } from 'core';
import { selectTaskChildren } from 'Modules/tasks/selectors';
import { FlexRow, LoadableButton } from 'Components';
import { CreateTaskRequest, UpdateTaskRequest } from 'Modules/tasks/models';
import { validateTask } from 'Modules/tasks/validations';
import SingleUserSearch from '../../../AviationSafetySolutions/SingleUserSearch';

export interface ModifyTaskModalProps {
  show: boolean;
  operatorAudit: OperatorAuditModel;
  task: Partial<AssignedTaskModel> & { taskId: number };
  hide: () => void;
}

const ModifyTaskModal = ({ show, task, operatorAudit, ...props }: ModifyTaskModalProps) => {
  const dispatch = useDispatch();
  const tempChildTasks = useRef<AssignedTaskModel[] | null>();
  const [ modifyingTask, setModifyingTask ] = useState<Partial<AssignedTaskModel>>({});
  const [ createdParentTask, setCreatedParentTask ] = useState<AssignedTaskModel | undefined>();
  const [ descriptionEditorState, setDescriptionEditorState ] = useState(EditorState.createEmpty());
  const [ modifyingChildTask, setModifyingChildTask ] = useState<Partial<AssignedTaskModel> & { taskId: number } | undefined>();
  const isSavingTask = useSelector((state: RootState) => state.tasks.loading.isSavingTask);
  const isLoadingTaskChildren = useSelector((state: RootState) => state.tasks.loading.isLoadingTaskChildren);
  const childTasks = useSelector((state: RootState) => {
    if (!show || !task.isParent) {
      return [];
    }

    const { taskId } = createdParentTask || task;

    return selectTaskChildren(taskId)(state);
  });

  const mergedTask = useMemo(() => ({ ...task, ...modifyingTask, ...createdParentTask }), [ task, modifyingTask, createdParentTask ]);

  useEffect(() => {
    if(!createdParentTask) {
      return;
    }

    addChildTask(createdParentTask.taskId);
  }, [ createdParentTask ]);

  useOnModalShow(show).then(() => {
    setDescriptionEditorState(getEditorStateFromString(mergedTask.description));
    if (task.isParent) {
      dispatch(getParentChildrenTasks(mergedTask.taskId));
    }
  });

  useOnModalHide(show).then(() => {
    setCreatedParentTask(undefined);
    setModifyingTask({});
  });

  function setTaskDate (e: React.ChangeEvent<HTMLSelectElement>) {
    setModifyingTask({
      ...modifyingTask,
      taskDate: e.target.value || undefined,
    });
  }

  function saveTask () {
    const { taskId, summary, parentTaskId, actionTypeId, isParent, taskDate, categoryId, uploadToDirectoryPath, standardId, standardReferenceId, required, assignedToUserId } = mergedTask;

    const validationMessages = validateTask(mergedTask);

    if(_.size(validationMessages) > 0) {
      const [ validationMessage ] = validationMessages;

      toastr.error(validationMessage);
      return;
    }

    if (mergedTask.taskId && mergedTask.taskId > 0) {
      const dto: UpdateTaskRequest = {
        taskId: taskId,
        operatorAuditId: operatorAudit.operatorAuditId,
        summary: summary!,
        isParent: isParent!,
        assignedToUserId: assignedToUserId,
        parentTaskId: parentTaskId,
        description: JSON.stringify(convertToRaw(descriptionEditorState.getCurrentContent())),
        categoryId: categoryId,
        taskDate: taskDate,
        actionTypeId: actionTypeId!,
        uploadToDirectoryPath: uploadToDirectoryPath,
        standardId: standardId,
        required: !!required,
        standardReferenceId: standardReferenceId,
      };

      dispatch(updateTask(dto, () => props.hide()));
    } else {
      const dto: CreateTaskRequest = {
        operatorAuditId: operatorAudit.operatorAuditId,
        summary: summary!,
        isParent: isParent!,
        parentTaskId: parentTaskId,
        description: JSON.stringify(convertToRaw(descriptionEditorState.getCurrentContent())),
        categoryId: categoryId,
        taskDate: taskDate,
        required: !!required,
        actionTypeId: actionTypeId!,
        uploadToDirectoryPath: uploadToDirectoryPath,
        standardId: standardId,
        standardReferenceId: standardReferenceId,
      };
      dispatch(insertTask(dto, () => props.hide()));
    }
  }

  function createParentAndAddChild () {
    const { summary, parentTaskId, actionTypeId, isParent, taskDate, categoryId, uploadToDirectoryPath, standardId, standardReferenceId, required } = mergedTask;

    const validationMessages = validateTask(mergedTask);

    if(_.size(validationMessages) > 0) {
      const [ validationMessage ] = validationMessages;

      toastr.error(validationMessage);
      return;
    }

    const dto: CreateTaskRequest = {
      operatorAuditId: operatorAudit.operatorAuditId,
      summary: summary!,
      isParent: isParent!,
      parentTaskId: parentTaskId,
      description: JSON.stringify(convertToRaw(descriptionEditorState.getCurrentContent())),
      categoryId: categoryId,
      taskDate: taskDate,
      required: !!required,
      actionTypeId: actionTypeId!,
      uploadToDirectoryPath: uploadToDirectoryPath,
      standardId: standardId,
      standardReferenceId: standardReferenceId,
    };
    dispatch(insertTask(dto, (parent) => {
      setCreatedParentTask(parent);
    }));
  }

  const weekOptions = _.map(operatorAudit.weeks, ({ startDate, displayShortStartDate, displayShortEndDate }, weekIndex) => {
    return {
      value: startDate,
      label: `Week ${weekIndex + 1} (${displayShortStartDate} - ${displayShortEndDate})`,
    };
  });

  function persistPositions () {
    const updatedChildTasks = tempChildTasks.current || [];

    const dtos: UpdateTaskPosition[] = [];

    _.each(updatedChildTasks, ({ taskId, position }, index) => {
      if (position !== index) {
        dtos.push({ taskId, position: index });
      }
    });

    if (_.isEmpty(dtos)) {
      return;
    }

    dispatch(updateTaskPositions(dtos));
  }

  function getModalTitle (task: Partial<AssignedTaskModel>) {
    const isExistingTask = task?.taskId && task.taskId > 0;

    if (task.isParent) {
      return isExistingTask ? 'Update Parent Task' : 'Create Parent Task';
    }

    return isExistingTask ? 'Update Task' : 'Create Task';
  }

  function addChildTask(taskId: number) {
    setModifyingChildTask({ taskId: 0, parentTaskId: taskId });
  }

  return (
    <Modal isOpen={show} size="xl" toggle={props.hide}>
      <ModalHeader>{getModalTitle(mergedTask)}</ModalHeader>
      <ModalBody>
        <TaskMaintenanceForm
          task={mergedTask}
          values={modifyingTask}
          descriptionEditorState={descriptionEditorState}
          setDescriptionEditorState={(editorState) => {
            setDescriptionEditorState(editorState);
          }}
          setValues={(values) => setModifyingTask({ ...modifyingTask, ...values })}
          PostSpecs={(
            <>
              {!mergedTask.parentTaskId && (
                <div className="form-group">
                  <label>Assigned to User:</label>
                  <div>
                    <SingleUserSearch value={mergedTask.assignedToUserId} onChange={(user: UserModel | null) => setModifyingTask({ ...modifyingTask, assignedToUserId: user?.userId })} />
                  </div>
                </div>
              )}
            </>
          )}
          PostSummary={(
            <>
              {!mergedTask.parentTaskId && (
                <div className="form-group">
                  <label>Week:</label>
                  <select className="form-control" value={mergedTask.taskDate || undefined} onChange={setTaskDate}>
                    <option value="">None (Persistent)</option>
                    {
                      _.map(weekOptions, ({ value, label }) => {
                        return (
                          <option key={value} value={value}>{label}</option>
                        );
                      })
                    }
                  </select>
                </div>
              )}

              {isLoadingTaskChildren && (
                <h4>Loading Children...</h4>
              )}

              {task.isParent && !isLoadingTaskChildren && (
                <>
                  {mergedTask.taskId > 0 && (
                    <>
                      <Button className="mb-1" color="primary" onClick={() => addChildTask(mergedTask.taskId)}>Add Child Task</Button>

                      <ReactSortable<any>
                        className="child-spacing-y-1"
                        list={childTasks}
                        setList={(updatedChildTasks) => tempChildTasks.current = updatedChildTasks}
                        onEnd={persistPositions}
                      >
                        {
                          _.map(childTasks, (childTask) => {
                            return (
                              <CardHeader key={childTask.taskId}>
                                <FlexRow justifyBetween>
                                  <span>
                                    <i className="mdi mdi-drag" /> {childTask.summary}
                                  </span>

                                  <span>
                                    <Button color="anchor" onClick={() => setModifyingChildTask(childTask)}>
                                      <i className="mdi mdi-pencil" />
                                    </Button>
                                  </span>
                                </FlexRow>
                              </CardHeader>
                            );
                          })
                        }
                      </ReactSortable>

                      {modifyingChildTask && (
                        <ModifyTaskModal
                          show
                          operatorAudit={operatorAudit}
                          task={modifyingChildTask}
                          hide={() => setModifyingChildTask(undefined)}
                        />
                      )}
                    </>
                  )}
                  {mergedTask.taskId <= 0 && (
                    <>
                      <Button color="primary" onClick={createParentAndAddChild}>Create and Add Child...</Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div>
            <Button color="light" onClick={props.hide}>Cancel</Button>
          </div>

          <div>
            <LoadableButton color="primary" isLoading={isSavingTask} LoadingLabel="Saving..." onClick={saveTask}>
              Save
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default ModifyTaskModal;
