import React from 'react';
import FlexColumn from './FlexColumn';

const UploadingFile = () => (
  <FlexColumn fill center>
    <span><i className="mdi mdi-spin mdi-refresh mdi-36px" /></span>
    <span>Uploading...</span>
  </FlexColumn>
);

export default UploadingFile;
