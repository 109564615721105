import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiLoadingStatusPayload } from 'api';
import { commonActions } from 'Modules/common/actions';
import { OperatorFeatureEnum, OperatorModel, OperatorUserXrefModel } from 'models';

import { CreateOperator, OperatorFeature, UpdateOperator } from './models';
import { dashboardActions } from '../dashboard/actions';

export interface OperatorsState {
  entities: {
    operators: { [operatorId: number]: OperatorModel };
    operatorFeatures: { [operatorFeatureId: number]: OperatorFeature };
    operatorUserXrefs: {[operatorUserXrefId: number]: OperatorUserXrefModel};
  };
  xrefs: {
    operatorUserXrefs: {[operatorId: number]: number[]};
    operatorFeatures: {[operatorId: number]: number[]};
  };
  ui: {
    allOperatorIds: number[];
    safetyCoachDashboardOperatorIds: number[];
    operatorDashboardOperatorIds: number[];
    currentUserOperatorIds: number[];
    maintenance: {
      workingCreateOperator: Partial<CreateOperator>;
      workingUpdateOperator: Partial<UpdateOperator>;
    };
  };
  loading: {
    isLoadingOperator: boolean;
    isLoadingAllOperators: boolean;
    isLoadingOperatorFeatures: boolean;
    isLoadingOperatorUserXrefs: boolean;
    isUpdatingOperatorDictionary: boolean;
    isInsertingOperator: boolean;
    isUpdatingOperator: boolean;
    updatingOperators: {[operatorId: number]: number};
    isSavingOperatorUsers: boolean;
    isUpdatingOperatorProgram: boolean;
    isSettingProfilePicture: boolean;
    isDeletingOperator: boolean;
    isLoadingCurrentUserOperators: boolean;
    isRemovingProfilePicture: boolean;
  };
}

const initialOperatorsState: OperatorsState = {
  entities: {
    operators: {},
    operatorUserXrefs: {},
    operatorFeatures: {},
  },
  xrefs: {
    operatorUserXrefs: {},
    operatorFeatures: {},
  },
  ui: {
    allOperatorIds: [],
    safetyCoachDashboardOperatorIds: [],
    operatorDashboardOperatorIds: [],
    currentUserOperatorIds: [],
    maintenance: {
      workingCreateOperator: {},
      workingUpdateOperator: {},
    },
  },
  loading: {
    isLoadingOperator: false,
    isLoadingAllOperators: false,
    isLoadingOperatorFeatures: false,
    isInsertingOperator: false,
    isUpdatingOperator: false,
    isDeletingOperator: false,
    isLoadingOperatorUserXrefs: false,
    isUpdatingOperatorDictionary: false,
    isSavingOperatorUsers: false,
    updatingOperators: {},
    isUpdatingOperatorProgram: false,
    isSettingProfilePicture: false,
    isLoadingCurrentUserOperators: false,
    isRemovingProfilePicture: false,
  },
};

export const operatorsSlice = createSlice({
  name: 'Operators',
  initialState: initialOperatorsState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<OperatorsState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setOperator: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators = {
        ...state.entities.operators,
        [action.payload.operatorId]: action.payload,
      };
    },
    setAllOperators: (state, action: PayloadAction<OperatorModel[]>) => {
      state.entities.operators = {
        ...state.entities.operators,
        ..._.keyBy(action.payload, o => o.operatorId),
      };

      state.ui.allOperatorIds = _.map(action.payload, o => o.operatorId);
    },
    setCurrentUserOperators: (state, action: PayloadAction<OperatorModel[]>) => {
      state.entities.operators = {
        ...state.entities.operators,
        ..._.keyBy(action.payload, o => o.operatorId),
      };

      state.ui.currentUserOperatorIds = _.map(action.payload, o => o.operatorId);
    },
    setAllOperatorUserXrefs: (state, action: PayloadAction<OperatorUserXrefModel[]>) => {
      const operatorUserXrefs = action.payload;
      const operatorUserXrefMappings: { [operatorId: number]: number[] } = {};

      _.each(operatorUserXrefs, (x) => {
        if (!_.has(operatorUserXrefMappings, x.operatorId)) {
          operatorUserXrefMappings[x.operatorId] = [];
        }

        operatorUserXrefMappings[x.operatorId].push(x.operatorUserXrefId);
      });

      state.entities.operatorUserXrefs = {
        ...state.entities.operatorUserXrefs,
        ..._.keyBy(operatorUserXrefs, o => o.operatorUserXrefId),
      };

      state.xrefs.operatorUserXrefs = operatorUserXrefMappings;
    },
    setOperatorFeatures: (state, action: PayloadAction<{ operatorId: number; features: OperatorFeature[] }>) => {
      const { operatorId, features } = action.payload;

      state.entities.operatorFeatures = {
        ...state.entities.operatorFeatures,
        ..._.keyBy(features, o => o.id),
      };

      state.xrefs.operatorFeatures[operatorId] = features.map(f => f.id);
    },
    mergeOperatorUserXrefs: (state, action: PayloadAction<{ operatorId: number; operatorUserXrefs: OperatorUserXrefModel[] }>) => {
      const { operatorId: mergedOperatorId, operatorUserXrefs } = action.payload;

      state.entities.operatorUserXrefs = {
        ...state.entities.operatorUserXrefs,
        ..._.keyBy(operatorUserXrefs, o => o.operatorUserXrefId),
      };

      state.xrefs.operatorUserXrefs[mergedOperatorId] = _.map(operatorUserXrefs, (o) => o.operatorUserXrefId);
    },
    setProfilePictureSuccess: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators[action.payload.operatorId] = action.payload;
    },
    setWorkingCreateOperatorValues: (state, action: PayloadAction<Partial<CreateOperator>>) => {
      state.ui.maintenance.workingCreateOperator = {
        ...state.ui.maintenance.workingCreateOperator,
        ...action.payload,
      };
    },
    setWorkingUpdateOperatorValues: (state, action: PayloadAction<Partial<UpdateOperator>>) => {
      state.ui.maintenance.workingUpdateOperator = {
        ...state.ui.maintenance.workingUpdateOperator,
        ...action.payload,
      };
    },
    cancelOperatorModification: (state) => {
      state.ui.maintenance.workingUpdateOperator = {};
      state.ui.maintenance.workingCreateOperator = {};
    },
    onOperatorProgramUpdated: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators = {
        ...state.entities.operators,
        [action.payload.operatorId]: action.payload,
      };

      state.ui.maintenance.workingUpdateOperator = {};
    },
    onOperatorDictionaryUpdated: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators = {
        ...state.entities.operators,
        [action.payload.operatorId]: action.payload,
      };

      state.ui.maintenance.workingUpdateOperator = {};
    },
    onOperatorUpdated: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators = {
        ...state.entities.operators,
        [action.payload.operatorId]: action.payload,
      };

      state.ui.maintenance.workingUpdateOperator = {};
    },
    onOperatorInserted: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators = {
        ...state.entities.operators,
        [action.payload.operatorId]: action.payload,
      };

      state.ui.allOperatorIds.push(action.payload.operatorId);
      state.ui.maintenance.workingCreateOperator = {};
    },
    onOperatorFeatureEnabled: (state, action: PayloadAction<OperatorFeature>) => {
      state.entities.operatorFeatures = {
        ...state.entities.operatorFeatures,
        [action.payload.id]: action.payload,
      };

      state.xrefs.operatorFeatures[action.payload.operatorId] = _.union(state.xrefs.operatorFeatures[action.payload.operatorId], [ action.payload.id ]);
    },
    onOperatorFeatureDisabled: (state, action: PayloadAction<{ operatorId: number; featureId: OperatorFeatureEnum }>) => {
      const { operatorId, featureId } = action.payload;
      const existing = _.map(state.xrefs.operatorFeatures[operatorId], (id) => state.entities.operatorFeatures[id]);
      const deleted = _.find(existing, f => f.featureId === featureId);

      if(!deleted) {
        return state;
      }

      state.entities.operatorFeatures = _.omit(state.entities.operatorFeatures, deleted.id);
      state.xrefs.operatorFeatures[action.payload.operatorId] = _.without(state.xrefs.operatorFeatures[action.payload.operatorId], deleted.id);
    },
    onOperatorDeleted: (state, action: PayloadAction<OperatorModel>) => {
      state.entities.operators = _.omit(state.entities.operators, action.payload.operatorId);
      state.ui.maintenance.workingUpdateOperator = {};
      state.ui.maintenance.workingCreateOperator = {};
    },
    removeProfilePictureSuccess: (state, action: PayloadAction<number>) => {
      if(!state.entities.operators[action.payload]) {
        return state;
      }

      state.entities.operators[action.payload] = {
        ...state.entities.operators[action.payload],
        imageFile: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardActions.GET_OPERATOR_DASHBOARD_SUCCESS, (state, action: PayloadAction<{ operators: OperatorModel[] }>) => {
        state.entities.operators = {
          ...state.entities.operators,
          ..._.keyBy(action.payload.operators, o => o.operatorId),
        };

        state.ui.operatorDashboardOperatorIds = _.map(action.payload.operators, o => o.operatorId);
      })
      .addCase(dashboardActions.GET_SAFETY_COACH_DASHBOARD_SUCCESS, (state, action: PayloadAction<{ operators: OperatorModel[] }>) => {
        state.entities.operators = {
          ...state.entities.operators,
          ..._.keyBy(action.payload.operators, o => o.operatorId),
        };

        state.ui.safetyCoachDashboardOperatorIds = _.map(action.payload.operators, o => o.operatorId);
      })
      .addCase(commonActions.PATH_LOCATION_CHANGE, (state) => {
        state.ui.maintenance.workingCreateOperator = {};
        state.ui.maintenance.workingUpdateOperator = {};
      })
      .addCase(commonActions.AREA_LOCATION_CHANGE, (state) => {
        return {
          ...initialOperatorsState,
          entities: {
            ...initialOperatorsState.entities,
            operatorFeatures: state.entities.operatorFeatures,
          },
          xrefs: {
            ...initialOperatorsState.xrefs,
            operatorFeatures: state.xrefs.operatorFeatures,
          },
        };
      });
  },
});
