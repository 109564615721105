import React from 'react';
import PreviewFileModal from 'Components/PreviewFileModal/PreviewFileModal';

interface Props {
  show: boolean;
  referenceFileId?: number;
  hide: () => void;
}

const ReferenceFilePreviewModal = ({ show, referenceFileId, ...props }: Props) => {
  return (
    <PreviewFileModal
      show={show}
      getPreviewRequest={(superagent) => superagent.get(`/api/v1/referenceMaterials/${referenceFileId}/download`)}
      getDownloadRequest={(superagent) => superagent.get(`/api/v1/referenceMaterials/${referenceFileId}/download`)}
      hide={props.hide}
    />
  );
};

export default ReferenceFilePreviewModal;
