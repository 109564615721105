import React, { CSSProperties, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { selectAllPrograms } from 'Modules/programs/selectors';
import { Program } from 'Modules/programs/models';
import * as programsActions from 'Modules/programs/actions';
import useStateSelector from 'hooks/useStateSelector';

interface Props extends Omit<ReactSelectProps<Program, false>, 'value'> {
  value?: Program;
  simpleValue?: number;
  onChange: (program?: Program) => void;
}

const selectStyles = {
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 3000 }),
};

const ProgramSearch = (props: Props) => {
  const dispatch = useDispatch();
  const programs = useStateSelector(selectAllPrograms);
  const { value, simpleValue, ...rest } = props;

  const selectedValue = value || (!!simpleValue && _.find(programs, (t) => t.programId === simpleValue));

  useEffect(() => {
    dispatch(programsActions.getAllPrograms());
  }, []);

  return (
    <Select<Program, false>
      {...rest}
      isClearable
      placeholder="Select a Program..."
      menuPortalTarget={document.body}
      value={selectedValue || null}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.programId}
      options={programs as any[]}
      styles={selectStyles as any}
      onChange={(program) => props.onChange(program || undefined)}
    />
  );
};

export default ProgramSearch;
