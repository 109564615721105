import useOnPrevious from './useOnPrevious';

const useOnModalShow = (show: boolean): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    useOnPrevious(show, (previousShow, currentShow) => !previousShow && currentShow)
      .then(({ value: currentShow }) => resolve(currentShow));
  });
};

export default useOnModalShow;
