import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import { RootState } from 'Modules/reducers';

import operatorAttributesActions from './actions';
import {
  CreateOperatorAttribute,
  CreateOperatorAttributeType,
  OperatorAttribute,
  OperatorAttributeType,
  UpdateOperatorAttribute,
  UpdateOperatorAttributeType,
} from './models';
import { operatorAttributesSlice, OperatorAttributesState } from './slice';

const operatorAttributesLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: OperatorAttributesState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(operatorAttributesSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getOperatorAttribute = (operatorId: number, operatorAttributeId: number): ThunkAction<Promise<OperatorAttribute>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingOperatorAttribute = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operators/${operatorId}/attributes/${operatorAttributeId}`),
    onSuccess: (data: OperatorAttribute) => {
      dispatch(operatorAttributesActions.setOperatorAttribute(data));
    },
  });
};

export const getOperatorAttributes = (operatorId: number): ThunkAction<Promise<OperatorAttribute[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllOperatorAttributes = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operators/${operatorId}/attributes`),
    onSuccess: (data: OperatorAttribute[]) => {
      dispatch(operatorAttributesActions.setOperatorAttributes({ operatorId, attributes: data }));
    },
  });
};

export const getOperatorAttributeTypes = (): ThunkAction<Promise<OperatorAttributeType[]>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllOperatorAttributeTypes = isLoading),
    getRequest: (superagent) => superagent.get('/api/v1/operators/attributes/types'),
    onSuccess: (data: OperatorAttributeType[]) => {
      dispatch(operatorAttributesActions.setAllOperatorAttributeTypes(data));
    },
  });
};

export const getOperatorAttributeType = (operatorAttributeTypeId: number): ThunkAction<Promise<OperatorAttributeType>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingOperatorAttributeType = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operators/attributes/types/${operatorAttributeTypeId}`),
    onSuccess: (data: OperatorAttributeType) => {
      dispatch(operatorAttributesActions.setOperatorAttributeType(data));
    },
  });
};

export const updateOperatorAttribute = (operatorId: number, operatorAttributeId: number, updateOperatorAttribute: UpdateOperatorAttribute): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingOperatorAttribute = isLoading),
    getRequest: (superagent) => superagent.put(`/api/v1/operators/${operatorId}/attributes/${operatorAttributeId}`).send(updateOperatorAttribute),
    onSuccess: (data: OperatorAttribute) => {
      dispatch(operatorAttributesActions.operatorAttributeUpdated(data));
    },
  });
};

export const updateOperatorAttributeType = (operatorAttributeTypeId: number, updateOperatorAttributeType: UpdateOperatorAttributeType): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingOperatorAttributeType = isLoading),
    getRequest: (superagent) => superagent.put(`/api/v1/operators/attributes/types/${operatorAttributeTypeId}`).send(updateOperatorAttributeType),
    onSuccessToast: (toastr) => toastr.success('Successfully saved operator attribute'),
    onSuccess: (data: OperatorAttributeType) => {
      dispatch(operatorAttributesActions.operatorAttributeTypeUpdated(data));
    },
  });
};

export const insertOperatorAttribute = (operatorId: number, createOperatorAttribute: CreateOperatorAttribute): ThunkAction<Promise<OperatorAttribute>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingOperatorAttribute = isLoading),
    getRequest: (superagent) => superagent.post(`/api/v1/operators/${operatorId}/attributes`).send(createOperatorAttribute),
    onSuccess: (data: OperatorAttribute) => {
      dispatch(operatorAttributesActions.operatorAttributeInserted(data));
    },
  });
};

export const insertOperatorAttributeType = (createOperatorAttributeType: CreateOperatorAttributeType): ThunkAction<Promise<OperatorAttributeType>, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingOperatorAttributeType = isLoading),
    getRequest: (superagent) => superagent.post('/api/v1/operators/attributes/types').send(createOperatorAttributeType),
    onSuccess: (data: OperatorAttributeType) => {
      dispatch(operatorAttributesActions.operatorAttributeTypeInserted(data));
    },
  });
};

export const deleteOperatorAttribute = (operatorId: number, operatorAttributeId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingOperatorAttribute = isLoading),
    getRequest: (superagent) => superagent.del(`/api/v1/operators/${operatorId}/attributes/${operatorAttributeId}`),
    onSuccess: () => {
      dispatch(operatorAttributesActions.operatorAttributeDeleted(operatorAttributeId));
    },
  });
};

export const deleteOperatorAttributeType = (operatorAttributeTypeId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorAttributesLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingOperatorAttributeType = isLoading),
    getRequest: (superagent) => superagent.del(`/api/v1/operators/attributes/types/${operatorAttributeTypeId}`),
    onSuccessToast: (toastr) => toastr.success('Successfully deleted operator attribute type'),
    onSuccess: () => {
      dispatch(operatorAttributesActions.operatorAttributeTypeDeleted(operatorAttributeTypeId));
    },
  });
};
