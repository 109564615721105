import { operatorAttributesSlice } from './slice';
import * as operatorAttributesApiActions from './apiActions';

const operatorAttributesActions = {
  ...operatorAttributesSlice.actions,
  async: {
    ...operatorAttributesApiActions,
  },
};

export default operatorAttributesActions;
