import React, { useRef } from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  preventDefault?: boolean;
  stopPropagation?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

const ClickableDiv = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  function handleKeyDown(e: React.KeyboardEvent) {
    if(props.preventDefault) {
      e.preventDefault();
    }
    if(props.stopPropagation) {
      e.stopPropagation();
    }
    if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
      props.onClick?.();
    }
  }

  return (
    <div
      {...props}
      ref={ref}
      role="button"
      aria-pressed="false"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={handleKeyDown}
    >
      {props.children}
    </div>
  );
};

export default ClickableDiv;
