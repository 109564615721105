import { CreatePoll, CreatePollCategory, PollResultDisplayType } from './models';
import moment from 'moment';

export const getNewPoll = (): CreatePoll => {
  return {
    name: '',
    startDate: moment().format('YYYY-MM-DD'),
    allowMultipleAnswers: false,
    defaultResultDisplayType: PollResultDisplayType.PieChart,
  };
};

export const getNewPollCategory = (): CreatePollCategory => {
  return {
    name: '',
  };
};
