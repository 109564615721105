import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import { AuditTemplate, TemplateTask } from 'models';

import ModifyTemplateTaskModal from './ModifyTemplateTaskModal';
import { ActionTypeIcon, DisplayActionType } from '../../constants/ActionType';
import { preventDefaultAnd } from '../../helpers/eventHelpers';
import { RootState } from '../../Modules/reducers';
import { connect } from 'react-redux';
import { deleteTemplateTask } from '../../Modules/templates/actions';
import Swal from 'sweetalert2';
import DraftTextViewer from '../../Components/Draft/Viewer/DraftTextViewer';

interface LocalProps {
  template: AuditTemplate;
  task: TemplateTask;
  weekIndex?: number;
}

interface LocalState {
  isShowingModifyModal: boolean;
}

interface StateProps {
  isDeleting: boolean;
}

interface DispatchProps {
  deleteTemplateTask: typeof deleteTemplateTask;
}

type Props = LocalProps & StateProps & DispatchProps;

class TemplateTaskCard extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      isShowingModifyModal: false,
    };
  }

  private showModifyModal = () => {
    this.setState({ isShowingModifyModal: true });
  };

  private hideModifyModal = () => {
    this.setState({ isShowingModifyModal: false });
  };

  private deleteTask = async () => {
    const { task } = this.props;

    const { dismiss } = await Swal.fire({
      title: 'Confirm',
      text: 'Are you sure you want to delete this template task?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (dismiss) {
      return;
    }

    this.props.deleteTemplateTask(task.auditTemplateId, task.templateTaskId);
  };

  public render () {
    const { isShowingModifyModal } = this.state;
    const { task, isDeleting, template } = this.props;
    const { summary, description, actionTypeId } = task;

    return (
      <>
        <Card>
          <CardBody>
            <Row>
              <Col md={12} className="pb-1">
                <div className="float-left">
                  <strong>{summary}</strong>
                </div>
              </Col>

              <Col md={12}>
                <p className="respect-white-space"><DraftTextViewer content={description} /></p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="float-left">
                  <i className={ActionTypeIcon[actionTypeId]} title={`${DisplayActionType[actionTypeId]} Action`} />
                </div>

                <div className="float-right space-children-x">
                  <>
                    <Button color="anchor" onClick={preventDefaultAnd(this.showModifyModal)}><i className="fa fa-pencil" /></Button>
                  </>
                  <>
                    {isDeleting && <Button color="anchor" className="text-danger" onClick={preventDefaultAnd()}><i className="fa fa-spin fa-refresh" /></Button>}
                    {!isDeleting && <Button color="anchor" className="text-danger" onClick={preventDefaultAnd(this.deleteTask)}><i className="fa fa-trash" /></Button>}
                  </>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ModifyTemplateTaskModal
          show={isShowingModifyModal}
          template={template}
          templateTask={task}
          hide={this.hideModifyModal}
        />
      </>
    );
  }
}

const mapStateToProps = () => {
  return {
    isDeleting: false,
  };
};

const mapDispatchToProps = {
  deleteTemplateTask,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateTaskCard);
