import './chat.scss';

import _ from 'lodash';
import classnames from 'classnames';
import React, { useState } from 'react';
// @ts-ignore
import ScrollToBottom from 'react-scroll-to-bottom';

import { ChatMessage } from './models';
import ChatMessageInput from './ChatMessageInput';
import ChatMessageBubble from './ChatMessageBubble';
import { Button } from 'reactstrap';
import FlexRow from '../FlexRow';

interface LocalProps {
  messages: ChatMessage[];
  input?: {
    className?: string;
    fileType: string;
    uploadFile?: (file: File) => Promise<any>;
  };
  canLoadMore?: boolean;
  onLoadMore?: () => void;
  direction?: 'col' | 'row';
  onMessageSubmit?: (message: string) => void | Promise<void>;
}

const ChatFeed = ({ messages, canLoadMore, input, direction, ...props }: LocalProps) => {
  const [ collapsedMessageIds, setCollapsedMessageIds ] = useState<{[messageId: number]: true}>({});

  function setExpanded(messageId: number) {
    const isCurrentlyCollapsed = _.has(collapsedMessageIds, messageId);

    setCollapsedMessageIds(isCurrentlyCollapsed ? _.omit(collapsedMessageIds, messageId) : { ...collapsedMessageIds, [messageId]: true });
  }

  let lastSenderName: string | null = null;

  return (
    <>
      <div className="chat-feed px-1">
        <ScrollToBottom className="chat-feed-c"
          scrollViewClassName={classnames('chat-messages-c', { 'is-empty': _.isEmpty(messages) })}
          followButtonClassName="follow-button mdi mdi-arrow-down-circle">
          {canLoadMore && (
            <FlexRow className="py-1" justifyCenter>
              <Button color="light" size="sm" onClick={() => props.onLoadMore?.()}>Load More</Button>
            </FlexRow>
          )}

          {_.isEmpty(messages) && (
            <div><h4 className="text-center">No Messages Yet...</h4></div>
          )}

          {
            _.map(messages, (message) => {
              const isCollapsed = _.has(collapsedMessageIds, message.id);
              const shouldShowSenderName = lastSenderName !== message.senderName;
              lastSenderName = message.senderName;

              return (
                <ChatMessageBubble
                  key={message.id}
                  message={message}
                  shouldShowSenderName={shouldShowSenderName}
                  isExpanded={!isCollapsed}
                  setExpanded={setExpanded}
                />
              );
            })
          }
        </ScrollToBottom>
      </div>

      {input && (
        <div className={classnames('mt-2', input.className)}>
          <ChatMessageInput direction={direction} fileType={input.fileType} uploadFile={input.uploadFile} onMessageSubmit={props.onMessageSubmit}/>
        </div>
      )}
    </>
  );
};

export default ChatFeed;
