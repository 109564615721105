import { Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api/ApiDao';
import appConfig from 'config';

import {
  CreateReferenceMaterialRequest,
  ReferenceMaterial, ReferenceMaterialPosition,
  UpdateReferenceMaterialPosition,
  UpdateReferenceMaterialRequest,
} from './models';
import referenceMaterialsActions from './actions';
import { RootState } from '../reducers';
import { TreeNode } from '../library/models';
import { referenceMaterialsSlice, ReferenceMaterialsState } from './slice';

const referenceMaterialsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: ReferenceMaterialsState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(referenceMaterialsSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getAllReferenceMaterials = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ReferenceMaterial[]>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingReferenceMaterials = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (axios) => axios.get('/api/v1/referenceMaterials'),
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setAllReferenceMaterials(data));
    },
  });
};

export const getAllReferenceMaterialPositions = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ReferenceMaterialPosition[]>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingReferenceMaterialPositions = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.get(`${appConfig.baseUrl}/api/v1/referenceMaterials/positions`);
    },
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setAllReferenceMaterialPositions(data));
    },
  });
};

export const moveReferenceMaterials = (updatedDirectoryPath: string, referenceFileIds: number[]): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ReferenceMaterial[]>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isMovingReferenceMaterials = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/referenceMaterials/move`)
        .send({ updatedDirectoryPath, referenceFileIds });
    },
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setReferenceMaterials(data));
    },
  });
};

export const renameReferenceMaterials = (referenceFileIds: number[], previousDirectoryPath: string, updatedDirectoryPath: string): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<ReferenceMaterial[]>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isRenamingReferenceMaterials = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/referenceMaterialsFolder/rename`)
        .send({ referenceFileIds, previousDirectoryPath, updatedDirectoryPath });
    },
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setReferenceMaterials(data));
    },
  });
};

export const updateReferenceFilesPositions = (referenceMaterials: TreeNode<ReferenceMaterial>[]): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  const currentLibraryPositions = getState().referenceMaterials.ui.referenceMaterialPositions;

  const positions = _.compact(_.map(referenceMaterials, (f, i): UpdateReferenceMaterialPosition | null => {
    const currentPosition = currentLibraryPositions[f.filePath];
    if (currentPosition === i) {
      return null;
    }

    return { filePath: f.filePath, position: i, originalPosition: currentPosition };
  }));

  if (_.isEmpty(positions)) {
    return;
  }

  return callApi({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingReferenceMaterialPositions = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => {
      return superagent.put(`${appConfig.baseUrl}/api/v1/referenceMaterials/positions`)
        .send(positions);
    },
    onRequest: () => {
      dispatch(referenceMaterialsActions.setReferenceMaterialPositions(positions));
    },
    onFailure: () => {
      dispatch(referenceMaterialsActions.revertReferenceMaterialPositions(positions));
    },
  });
};

export const uploadReferenceMaterial = (request: CreateReferenceMaterialRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  const formData = new window.FormData();

  formData.append('file', request.file);

  return callApi<ReferenceMaterial>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUploadingReferenceMaterial = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .post('/api/v1/referenceMaterials')
      .query(request)
      .send(formData),
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setReferenceMaterialCreated(data));
    },
  });
};

export const updateReferenceMaterial = (referenceMaterialId: number, request: UpdateReferenceMaterialRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  const formData = new window.FormData();

  formData.append('file', request.file);

  return callApi<ReferenceMaterial>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingReferenceMaterial = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .put(`/api/v1/referenceMaterials/${referenceMaterialId}/upload`)
      .query(request)
      .send(formData),
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setReferenceMaterialUpdated(data));
    },
  });
};

export const updateReferenceMaterialName = (referenceMaterialId: number, name: string): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  const formData = new window.FormData();

  return callApi<ReferenceMaterial>({
    loading: referenceMaterialsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingReferenceMaterialName = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent
      .put(`/api/v1/referenceMaterials/${referenceMaterialId}/name`)
      .query({ name: name.trim() })
      .send(formData),
    onSuccess: (data) => {
      dispatch(referenceMaterialsActions.setReferenceMaterialUpdated(data));
    },
  });
};
