import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { UserNotificationsState } from './reducer';
import { userNotificationsActions } from './actions';

const loadingConfigs: LoadingActionConfig<UserNotificationsState>[] = [
  {
    start: userNotificationsActions.COUNT_MY_NOTIFICATIONS_REQUEST,
    stop: [ userNotificationsActions.COUNT_MY_NOTIFICATIONS_SUCCESS, userNotificationsActions.COUNT_MY_NOTIFICATIONS_FAILURE ],
    updater: (state: UserNotificationsState, isLoading): UserNotificationsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingMyNotificationCount: isLoading,
        },
      };
    },
  },
  {
    start: userNotificationsActions.SEARCH_MY_NOTIFICATIONS_REQUEST,
    stop: [ userNotificationsActions.SEARCH_MY_NOTIFICATIONS_SUCCESS, userNotificationsActions.SEARCH_MY_NOTIFICATIONS_FAILURE ],
    updater: (state: UserNotificationsState, isLoading): UserNotificationsState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isSearchingMyNotifications: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
