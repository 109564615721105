import React from 'react';
import _ from 'lodash';
import { Col } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { connect } from 'react-redux';
import { AuditTemplate, TemplateTask, UpdateTemplateTaskPosition } from '../../models';
import TemplateTaskCard from './TemplateTaskCard';
import { CategoryModel } from '../../Modules/categories/models';
import { RootState } from '../../Modules/reducers';
import FlexColumn from '../../Components/FlexColumn';
import { updateTemplateTaskPositions } from '../../Modules/templates/actions';

interface LocalProps {
  weekIndex?: number;
  template: AuditTemplate;
  tasks: TemplateTask[];
  category?: CategoryModel;
}

interface LocalState {
  tasks: TemplateTask[];
}

interface StateProps {}

interface DispatchProps {
  updateTemplateTaskPositions: typeof updateTemplateTaskPositions;
}

type Props = LocalProps & DispatchProps;

class CategoryTemplateTaskGroup extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      tasks: props.tasks,
    };
  }

  public componentDidUpdate (prevProps: Readonly<LocalProps>) {
    const { tasks } = this.props;

    if (prevProps.tasks !== tasks) {
      this.setState({ tasks });
    }
  }

  private setTasks = (tasks: TemplateTask[]) => {
    this.setState({ tasks: tasks });
  }

  private persistPositions = () => {
    const { tasks } = this.state;

    const dtos: UpdateTemplateTaskPosition[] = [];

    _.each(tasks, ({ templateTaskId, position }, index) => {
      if (position !== index) {
        dtos.push({ templateTaskId, position: index });
      }
    });

    if (_.isEmpty(dtos)) {
      return;
    }

    this.props.updateTemplateTaskPositions(dtos);
  }

  public render () {
    const { tasks } = this.state;
    const { weekIndex, template, category } = this.props;

    return (
      <FlexColumn fill>
        <h6>{category?.name || <em>Uncategorized</em>}</h6>

        <ReactSortable<any> className="row" list={tasks} setList={this.setTasks} onEnd={this.persistPositions}>
          {
            _.map(tasks, (task) => {
              return (
                <Col key={task.templateTaskId} md={4}>
                  <TemplateTaskCard task={task} weekIndex={weekIndex} template={template} />
                </Col>
              );
            })
          }
        </ReactSortable>
      </FlexColumn>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  updateTemplateTaskPositions,
};

// @ts-ignore
export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(CategoryTemplateTaskGroup);
