import React from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { shouldModalUpdate } from 'helpers/modalHelpers';
import { RootState } from 'Modules/reducers';
import { createAuditTemplate } from 'Modules/templates/actions';
import IntegerInput from '../../../Components/IntegerInput';

interface LocalProps {
  show: boolean;
  hide: () => void;
}

interface LocalState {
  name: string;
  numWeeks?: number;
}

interface StateProps {
  isCreatingAuditTemplate: boolean;
}

interface DispatchProps {
  createAuditTemplate: typeof createAuditTemplate;
}

type Props = LocalProps & StateProps & DispatchProps;

class CreateTemplateModal extends React.Component<Props, LocalState> {
  shouldComponentUpdate = shouldModalUpdate(this);

  constructor (props: Props) {
    super(props);

    this.state = {
      name: '',
      numWeeks: 52,
    };
  }

  public componentDidUpdate (prevProps: Readonly<Props>): void {
    if (prevProps.isCreatingAuditTemplate && !this.props.isCreatingAuditTemplate) {
      this.props.hide();
    }

    if (prevProps.show && !this.props.show) {
      this.setState({
        name: '',
        numWeeks: 52,
      });
    }
  }

  private createTemplate = () => {
    const { name, numWeeks } = this.state;

    if (!_.trim(name)) {
      toastr.error('You must specify a name for the template');
      return;
    }

    if (!numWeeks) {
      toastr.error('You must specify the number of weeks for the template');
      return;
    }

    this.props.createAuditTemplate({
      name: name,
      numWeeks: numWeeks,
    });
  };

  setName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.target.value,
    });
  };

  setNumWeeks = (numWeeks?: number) => {
    this.setState({
      numWeeks: numWeeks,
    });
  };

  public render () {
    const { name, numWeeks } = this.state;
    const { show, isCreatingAuditTemplate } = this.props;

    return (
      <Modal isOpen={show} toggle={this.props.hide}>
        <ModalHeader>Create Template</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Name:</Label>
                <Input value={name} onChange={this.setName} />
              </FormGroup>

              <FormGroup>
                <Label>Number of Weeks:</Label>
                <IntegerInput value={numWeeks?.toString() || ''} onNumberChange={this.setNumWeeks} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {!isCreatingAuditTemplate && (
            <Button color="primary" onClick={this.createTemplate}>
              Create Template
            </Button>
          )}

          {isCreatingAuditTemplate && (
            <Button color="primary"><i className="fa fa-spin fa-cog" /> Saving Template...</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isCreatingAuditTemplate: state.templates.loading.isCreatingAuditTemplate,
    isDeletingAuditTemplate: state.templates.loading.isDeletingAuditTemplate,
  };
};

const mapDispatchToProps = {
  createAuditTemplate,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(CreateTemplateModal);
