import React from 'react';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TreeNode } from 'Modules/library/models';
import { ReferenceMaterial } from 'Modules/referenceMaterials/models';
import FileTreeFolder from 'Components/LibraryFolder/FileTreeFolder';
import { referenceMaterialsActions } from '../../Modules/referenceMaterials';

interface Props {
  folder: TreeNode<ReferenceMaterial>;
  onFolderSelect: (folder: TreeNode<ReferenceMaterial>) => void;
}

const ReferenceMaterialFolder = ({ folder, ...props }: Props) => {
  const dispatch = useDispatch();

  function renameLibraryFolder(folder: TreeNode<ReferenceMaterial>, updatedDirectoryFilePath: string) {
    const decedentFiles = folder.fileTree.getDescendantDataForNode(folder);
    dispatch(referenceMaterialsActions.async.renameReferenceMaterials(decedentFiles.map(r => r.referenceFileId), folder.filePath, updatedDirectoryFilePath));
  }

  return (
    <FileTreeFolder<ReferenceMaterial>
      folder={folder}
      onFolderSelect={props.onFolderSelect}
      renameLibraryFolder={renameLibraryFolder}
      accessors={{
        filePath: f => f.filePath,
        originalFilename: f => f.file.originalFilename,
        name: f => f.name,
      }}
    />
  );
};

export default ReferenceMaterialFolder;
