import _ from 'lodash';
import { tasksActions } from './actions';
import { auditsActions } from '../audits/actions';
import { dashboardActions } from '../dashboard/actions';
import { libraryActions } from '../library/actions';
import {
  Action,
  AssignedTaskModel,
  OperatorDashboardModelResponse,
  ReducerFunction,
  SafetyCoachDashboardResponse,
  TaskDetailsModel,
  LibraryFile,
  TaskMessageModel,
  TaskStatusModel,
  UpdateTaskPosition,
} from '../../models';
import { commonActions } from '../common/actions';
import { compose } from 'redux';
import makeLoadingReducer from '../enhancers/makeLoadingReducer';
import loadingConfigs from './loading';
import { getTaskModel } from './instantiations';
import { userNotificationsActions } from '../userNotifications/actions';
import { UserNotificationResponse } from '../userNotifications/models';

export interface TasksState {
  entities: {
    tasks: {[taskId: number]: AssignedTaskModel};
    taskMessages: {[taskMessageId: number]: TaskMessageModel};
    taskStatuses: {[taskStatusId: string]: TaskStatusModel};
    taskFiles: {[libraryFileId: number]: LibraryFile};
  };
  mappings: {
    taskMessages: { [taskId: number]: number[] };
    taskStatuses: { [taskId: number]: string[] };
    taskFiles: { [taskId: number]: number[] };
    taskChildren: { [parentTaskId: number]: number[] };
  };
  ui: {
    taskDetails: {[taskId: number]: TaskDetailsModel};
    selectedOperatorId?: any;
    selectedAuditId?: number;
    selectedGlobalTaskId?: number;
    modifyingTask?: any;
  };
  loading: {
    isSavingTask: boolean;
    deletingTasks: {[taskId: number]: number};
    loadingTaskApprovals: {[taskId: number]: number};
    isSubmittingTaskMessage: boolean;
    isLoadingTaskFiles: boolean;
    isLoadingTaskMessages: boolean;
    isLoadingTask: boolean;
    isLoadingTaskChildren: boolean;
    isLoadingTaskHistory: boolean;
  };
}

const initState: TasksState = {
  entities: {
    tasks: {},
    taskMessages: {},
    taskStatuses: {},
    taskFiles: {},
  },
  mappings: {
    taskMessages: {},
    taskStatuses: {},
    taskFiles: {},
    taskChildren: {},
  },
  ui: {
    taskDetails: {},
    selectedGlobalTaskId: undefined,
    selectedOperatorId: undefined,
    selectedAuditId: undefined,
    modifyingTask: undefined,
  },
  loading: {
    isSavingTask: false,
    deletingTasks: {},
    loadingTaskApprovals: {},
    isSubmittingTaskMessage: false,
    isLoadingTask: false,
    isLoadingTaskChildren: false,
    isLoadingTaskMessages: false,
    isLoadingTaskFiles: false,
    isLoadingTaskHistory: false,
  },
};

export default compose<ReducerFunction<TasksState>>(
  makeLoadingReducer<TasksState>({ loadingKey: 'loading', loadingConfigs })
)((state = initState, action: Action): TasksState => {
  switch (action.type) {
    case tasksActions.SET_SELECTED_OPERATOR: {
      const { selectedOperator } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          selectedOperatorId: selectedOperator?.operatorId,
          selectedAuditId: undefined,
        },
      };
    }
    case tasksActions.SET_GLOBAL_SELECTED_TASK: {
      const { taskId }: { taskId?: number } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          selectedGlobalTaskId: taskId,
        },
      };
    }
    case tasksActions.SET_SELECTED_AUDIT: {
      const { selectedAudit } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          selectedAuditId: selectedAudit?.operatorAuditId,
        },
      };
    }
    case tasksActions.CHANGE_MODIFYING_TASK_VALUES: {
      const { values } = action.payload;

      const modifyingTask = values ? {
        ...state.ui.modifyingTask,
        ...values,
      } : null;

      return {
        ...state,
        ui: {
          ...state.ui,
          modifyingTask: modifyingTask,
        },
      };
    }
    case tasksActions.GET_TASK_MESSAGES_SUCCESS: {
      const { taskId, taskMessages }: { taskId: number; taskMessages: TaskMessageModel[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          taskMessages: {
            ...state.entities.taskMessages,
            ..._.keyBy(taskMessages, t => t.taskMessageId),
          },
        },
        mappings: {
          ...state.mappings,
          taskMessages: {
            ...state.mappings.taskMessages,
            [taskId]: _.map(taskMessages, t => t.taskMessageId),
          },
        },
      };
    }
    case tasksActions.GET_TASK_FILES_SUCCESS: {
      const { taskId, files }: { taskId: number; files: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          taskFiles: {
            ...state.entities.taskFiles,
            ..._.keyBy(files, t => t.libraryFileId),
          },
        },
        mappings: {
          ...state.mappings,
          taskFiles: {
            ...state.mappings.taskFiles,
            [taskId]: _.map(files, t => t.libraryFileId),
          },
        },
      };
    }
    case tasksActions.GET_TASK_HISTORY_SUCCESS: {
      const { taskId, taskHistory }: { taskId: number; taskHistory: TaskStatusModel[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          taskStatuses: {
            ...state.entities.taskStatuses,
            ..._.keyBy(taskHistory, t => t.taskStatusId),
          },
        },
        mappings: {
          ...state.mappings,
          taskStatuses: {
            ...state.mappings.taskStatuses,
            [taskId]: _.map(taskHistory, t => t.taskStatusId),
          },
        },
      };
    }
    case tasksActions.UPDATE_TASK_POSITIONS_REQUEST: {
      const { positions }: { positions: UpdateTaskPosition[] } = action.payload;

      const updatedTasks = { ...state.entities.tasks };

      _.each(positions, ({ taskId, position }) => {
        updatedTasks[taskId] = {
          ...updatedTasks[taskId],
          position: position,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: updatedTasks,
        },
      };
    }
    case libraryActions.UPLOAD_TASK_FILES_SUCCESS: {
      const { task, libraryFiles }: { task: AssignedTaskModel; libraryFiles: LibraryFile[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [task.taskId]: getTaskModel(task),
          },
          taskFiles: {
            ...state.entities.taskFiles,
            ..._.keyBy(libraryFiles, f => f.libraryFileId),
          },
        },
        mappings: {
          ...state.mappings,
          taskFiles: {
            ...state.mappings.taskFiles,
            [task.taskId]: _.concat(state.mappings.taskFiles[task.taskId] || [], _.map(libraryFiles, f => f.libraryFileId)),
          },
        },
        ui: {
          ...state.ui,
          modifyingTask: undefined,
        },
      };
    }
    case libraryActions.RENAME_LIBRARY_FILE_SUCCESS: {
      const { libraryFile }: { libraryFile: LibraryFile } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          taskFiles: {
            ...state.entities.taskFiles,
            [libraryFile.libraryFileId]: libraryFile,
          },
        },
      };
    }
    case tasksActions.GET_TASK_SUCCESS: {
      const { task }: { task: AssignedTaskModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [task.taskId]: getTaskModel(task),
          },
        },
      };
    }
    case tasksActions.GET_PARENT_CHILDREN_TASKS_SUCCESS: {
      const { parentTaskId, tasks }: { parentTaskId: number; tasks: AssignedTaskModel[] } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            ..._.keyBy(_.map(tasks, getTaskModel), t => t.taskId),
          },
        },
        mappings: {
          ...state.mappings,
          taskChildren: {
            ...state.mappings.taskChildren,
            [parentTaskId]: _.map(tasks, t => t.taskId),
          },
        },
      };
    }
    case tasksActions.SUBMIT_TASK_SUCCESS:
    case tasksActions.REJECT_TASK_SUCCESS:
    case tasksActions.RESET_TASK_SUCCESS:
    case tasksActions.COMPLETE_TASK_SUCCESS:
    case tasksActions.NOT_APPLICABLE_TASK_SUCCESS:
    case tasksActions.UPDATE_TASK_SUCCESS:
    case tasksActions.INSERT_TASK_SUCCESS: {
      const { task }: { task: AssignedTaskModel } = action.payload;

      let updatedMappings = state.mappings;

      if (task.parentTaskId) {
        const siblingTaskIds = _.union(state.mappings.taskChildren[task.parentTaskId] || [], [ task.taskId ]);

        updatedMappings = {
          ...state.mappings,
          taskChildren: {
            ...state.mappings.taskChildren,
            [task.parentTaskId]: siblingTaskIds,
          },
        };
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [task.taskId]: getTaskModel(task),
          },
        },
        mappings: updatedMappings,
        ui: {
          ...state.ui,
          modifyingTask: undefined,
        },
      };
    }
    case tasksActions.DELETE_TASK_SUCCESS: {
      const { taskId } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: _.omit(state.entities.tasks, taskId),
        },
      };
    }
    case auditsActions.GET_OPERATOR_AUDIT_TASKS_SUCCESS: {
      const { tasks } = action.payload;

      const taskModels = _.map(tasks, getTaskModel);

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            ..._.keyBy(taskModels, t => t.taskId),
          },
        },
      };
    }
    case tasksActions.SUBMIT_TASK_MESSAGE_SUCCESS: {
      const { task, taskMessage }: { task: AssignedTaskModel; taskMessage: TaskMessageModel } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [task.taskId]: getTaskModel(task),
          },
          taskMessages: {
            ...state.entities.taskMessages,
            [taskMessage.taskMessageId]: taskMessage,
          },
        },
        mappings: {
          ...state.mappings,
          taskMessages: {
            ...state.mappings.taskMessages,
            [task.taskId]: _.concat(state.mappings.taskMessages[task.taskId], taskMessage.taskMessageId),
          },
        },
      };
    }
    case dashboardActions.GET_SAFETY_COACH_DASHBOARD_SUCCESS:
    case dashboardActions.GET_OPERATOR_DASHBOARD_SUCCESS: {
      const { operatorAuditTasks } = action.payload as SafetyCoachDashboardResponse | OperatorDashboardModelResponse;

      const allTasks = _.flatten(_.values(operatorAuditTasks));

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            ..._.keyBy(_.map(allTasks, getTaskModel), t => t.taskId),
          },
        },
      };
    }
    case auditsActions.UPDATE_OPERATOR_AUDIT_SUCCESS:
    case auditsActions.INSERT_OPERATOR_AUDIT_SUCCESS: {
      const { audit } = action.payload;
      const { operatorAuditId } = audit;
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedAuditId: operatorAuditId,
        },
      };
    }
    case tasksActions.GET_TASK_APPROVAL_DETAILS_SUCCESS: {
      const { taskId, taskDetails }: { taskId: number; taskDetails: TaskDetailsModel } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          taskDetails: {
            ...state.ui.taskDetails,
            [taskId]: taskDetails,
          },
        },
      };
    }
    case libraryActions.DELETE_FILES_SUCCESS: {
      const { libraryFiles }: { libraryFiles: LibraryFile[] } = action.payload;

      let tasks = state.entities.tasks;
      let taskFiles = state.mappings.taskFiles;

      _.each(libraryFiles, (libraryFile) => {
        if (!libraryFile.taskId || !_.has(tasks, libraryFile.taskId)) {
          return;
        }

        tasks = {
          ...tasks,
          [libraryFile.taskId]: {
            ...tasks[libraryFile.taskId],
            numFiles: tasks[libraryFile.taskId].numFiles - 1,
          },
        };

        taskFiles = {
          ...taskFiles,
          [libraryFile.taskId]: _.without(taskFiles[libraryFile.taskId], libraryFile.libraryFileId),
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks,
        },
        mappings: {
          ...state.mappings,
          taskFiles,
        },
      };
    }
    case userNotificationsActions.MARK_TASK_NOTIFICATIONS_VIEWED_SUCCESS: {
      const { taskId }: { currentUserId: string; taskId: number; numNotificationsMarkedViewed: number } = action.payload;

      const task = state.entities.tasks[taskId];

      if (!task) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [taskId]: {
              ...task,
              hasUnreadNotifications: false,
            },
          },
        },
      };
    }
    case userNotificationsActions.NOTIFICATION_RECEIVED: {
      const { notification }: { notification: UserNotificationResponse } = action.payload;

      if (!notification.taskId || !_.has(state.entities.tasks, notification.taskId)) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [notification.taskId]: {
              ...state.entities.tasks[notification.taskId],
              hasUnreadNotifications: true,
            },
          },
        },
      };
    }
    case commonActions.PATH_LOCATION_CHANGE: {
      return {
        ...initState,
        ui: {
          ...initState.ui,
          selectedGlobalTaskId: state.ui.selectedGlobalTaskId,
        },
      };
    }
    default: {
      return state;
    }
  }
});
