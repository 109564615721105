import _ from 'lodash';
import toast from 'toastr';

import { useStateSelector, useThunkDispatch } from 'hooks';
import {
  CreateShareBoardCategory,
  ShareBoardCategory,
  UpdateShareBoardCategory,
  UpsertShareBoardCategory,
} from 'Modules/shareBoard/models';
import { shareBoardActions } from '../../Modules/shareBoard/index';

const useShareBoardCategoryForm = () => {
  const dispatch = useThunkDispatch();

  const isInsertingShareBoardCategory = useStateSelector((state) => state.shareBoard.loading.isInsertingShareBoardCategory);
  const isUpdatingShareBoardCategory = useStateSelector((state) => state.shareBoard.loading.isUpdatingShareBoardCategory);
  const isDeletingShareBoardCategory = useStateSelector((state) => state.shareBoard.loading.isDeletingShareBoardCategory);
  const isActivatingShareBoardCategory = useStateSelector((state) => state.shareBoard.loading.isActivatingShareBoardCategory);

  function validateShareBoardCategory(shareBoardCategory: UpsertShareBoardCategory) {
    const errors: string[] = [];

    return errors;
  }

  function insertShareBoardCategory(shareBoardCategory: CreateShareBoardCategory) {
    const errorMessages = validateShareBoardCategory(shareBoardCategory);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(shareBoardActions.async.createShareBoardCategory(shareBoardCategory))
      .then((shareBoardCategory: ShareBoardCategory) => {
        toast.success('ShareBoardCategory successfully created.');
        return shareBoardCategory;
      });
  }

  function updateShareBoardCategory(shareBoardCategoryId: number, shareBoardCategory: UpdateShareBoardCategory) {
    const errorMessages = validateShareBoardCategory(shareBoardCategory);

    if(_.size(errorMessages) > 0) {
      toast.error(errorMessages[0]);
      return;
    }

    return dispatch(shareBoardActions.async.updateShareBoardCategory(shareBoardCategoryId, shareBoardCategory.name))
      .then((shareBoardCategory: ShareBoardCategory) => {
        toast.success('Share Board Category successfully saved.');
        return shareBoardCategory;
      });
  }

  function deleteShareBoardCategory(shareBoardCategory: ShareBoardCategory) {
    return dispatch(shareBoardActions.async.deleteShareBoardCategory(shareBoardCategory.id))
      .then((shareBoardCategory: ShareBoardCategory) => {
        toast.success('Share Board Category successfully deactivated.');
        return shareBoardCategory;
      });
  }

  function activateShareBoardCategory(shareBoardCategory: ShareBoardCategory) {
    return dispatch(shareBoardActions.async.activateShareBoardCategory(shareBoardCategory.id))
      .then((shareBoardCategory: ShareBoardCategory) => {
        toast.success('Share Board Category successfully activated.');
        return shareBoardCategory;
      });
  }

  function cancelShareBoardCategoryModification() {
    dispatch(shareBoardActions.cancelShareBoardCategoryModification());
  }

  return {
    insertShareBoardCategory,
    updateShareBoardCategory,
    deleteShareBoardCategory,
    activateShareBoardCategory,
    cancelShareBoardCategoryModification,
    loading: {
      isInsertingShareBoardCategory,
      isUpdatingShareBoardCategory,
      isDeletingShareBoardCategory,
      isActivatingShareBoardCategory,
    },
  };
};

export default useShareBoardCategoryForm;
