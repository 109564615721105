import { LoadingActionConfig } from '../enhancers/makeLoadingReducer';
import { LibraryState } from './reducer';
import { libraryActions } from './actions';
import { setLoadingHash } from '../../helpers/loadingHelpers';

const loadingConfigs: LoadingActionConfig<LibraryState>[] = [
  {
    start: libraryActions.DOWNLOAD_TASK_FILES_REQUEST,
    stop: [ libraryActions.DOWNLOAD_TASK_FILES_SUCCESS, libraryActions.DOWNLOAD_TASK_FILES_FAILURE ],
    updater: (state: LibraryState, isLoading, { taskIds }): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDownloadingFiles: isLoading,
          downloadingFiles: setLoadingHash(state.loading.downloadingFiles, taskIds, isLoading),
        },
      };
    },
  },
  {
    start: libraryActions.DOWNLOAD_OPERATOR_FILES_REQUEST,
    stop: [ libraryActions.DOWNLOAD_OPERATOR_FILES_SUCCESS, libraryActions.DOWNLOAD_OPERATOR_FILES_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDownloadingOperatorFiles: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.UPLOAD_TASK_FILES_REQUEST,
    stop: [ libraryActions.UPLOAD_TASK_FILES_SUCCESS, libraryActions.UPLOAD_TASK_FILES_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isUploadingFiles: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.UPLOAD_OPERATOR_LIBRARY_FILES_REQUEST,
    stop: [ libraryActions.UPLOAD_OPERATOR_LIBRARY_FILES_SUCCESS, libraryActions.UPLOAD_OPERATOR_LIBRARY_FILES_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isUploadingLibraryFiles: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.GET_OPERATOR_UPLOAD_FOLDERS_REQUEST,
    stop: [ libraryActions.GET_OPERATOR_UPLOAD_FOLDERS_SUCCESS, libraryActions.GET_OPERATOR_UPLOAD_FOLDERS_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingLibraryFolders: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.GET_OPERATOR_FILES_REQUEST,
    stop: [ libraryActions.GET_OPERATOR_FILES_SUCCESS, libraryActions.GET_OPERATOR_FILES_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingLibraryFiles: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.DELETE_LIBRARY_FOLDER_REQUEST,
    stop: [ libraryActions.DELETE_LIBRARY_FOLDER_SUCCESS, libraryActions.DELETE_LIBRARY_FOLDER_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isDeletingLibraryFolder: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.MOVE_LIBRARY_FILES_REQUEST,
    stop: [ libraryActions.MOVE_LIBRARY_FILES_SUCCESS, libraryActions.MOVE_LIBRARY_FILES_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isMovingLibraryFiles: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.GET_LIBRARY_POSITIONS_REQUEST,
    stop: [ libraryActions.GET_LIBRARY_POSITIONS_SUCCESS, libraryActions.GET_LIBRARY_POSITIONS_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingLibraryPositions: isLoading,
        },
      };
    },
  },
  {
    start: libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_REQUEST,
    stop: [ libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_SUCCESS, libraryActions.GET_OPERATOR_HIDDEN_FOLDERS_FAILURE ],
    updater: (state: LibraryState, isLoading): LibraryState => {
      return {
        ...state,
        loading: {
          ...state.loading,
          isLoadingOperatorHiddenFolders: isLoading,
        },
      };
    },
  },
];

export default loadingConfigs;
