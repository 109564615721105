import _ from 'lodash';

export function getHashSet<T>(items: T[], idSelector: (item: T) => string | undefined): { [id: number]: true };
export function getHashSet<T>(items: T[], idSelector: (item: T) => number | undefined): { [id: string]: true };
export function getHashSet<T>(items: T[], idSelector: (((item: T) => string | undefined) | ((item: T) => number | undefined))): ({ [id: string]: true } | { [id: number]: true }) {
  const hash: any = {};

  _.each(items, (item) => {
    const id = idSelector(item);

    if (!id) {
      return;
    }

    hash[id] = true;
  });

  return hash;
}

export const containsKey = <T>(item: T, key: keyof T) => {
  return _.has(item, key);
};

export const getValueByKey = <T>(items: Record<number | string, T>, id?: number): T | undefined => {
  if(_.isNil(id)) {
    return undefined;
  }
  return items[id];
};
