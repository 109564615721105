import { Permission } from '../Modules/roles/index';
import useLoggedInUserOrThrow from './useLoggedInUserOrThrow';

const useCurrentUserPermissionChecker = () => {
  const loggedInUser = useLoggedInUserOrThrow();
  return (permission: Permission) => {
    return loggedInUser.permissions.includes(permission);
  };
};

export default useCurrentUserPermissionChecker;
