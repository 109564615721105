import React from 'react';

import { baseTableOptions } from 'helpers/tableHelper';
import { Button } from 'reactstrap';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import { OperatorGroup, OperatorGroupOperatorXref } from 'Modules/operatorGroups/models';
import { stopPropagationPreventDefaultAnd } from 'helpers/eventHelpers';
import BaseTable from 'Components/BaseTable/BaseTable';
import { useDispatch } from 'react-redux';
import { operatorGroupsActions } from '../../../Modules/operatorGroups';

interface LocalProps {
  operatorGroup: OperatorGroup;
  operatorGroupOperatorXrefs: OperatorGroupOperatorXref[];
}

const OperatorGroupXrefsTable = ({ operatorGroup, operatorGroupOperatorXrefs }: LocalProps) => {
  const dispatch = useDispatch();

  function deleteOperatorGroupOperator (operatorGroup: OperatorGroupOperatorXref) {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete this operator from the group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
      });

      if (dismiss) {
        return;
      }

      dispatch(operatorGroupsActions.async.deleteOperatorGroupOperatorXref(operatorGroup.operatorGroupId, operatorGroup.operatorGroupOperatorXrefId));
    })();
  }

  const columns: Column<OperatorGroupOperatorXref>[] = [
    {
      Header: 'Id',
      id: 'id',
      accessor: o => o.operatorGroupOperatorXrefId,
      width: 45,
    },
    {
      id: 'operatorName',
      Header: 'Name',
      accessor: p => p.operatorName,
    },
    {
      id: 'codeName',
      Header: 'Code Name',
      accessor: p => p.codeName,
      Cell: ({ original }: { original: OperatorGroupOperatorXref }) => {
        function promptUserForCodeName () {
          (async () => {
            const { value: codeName, dismiss } = await Swal.fire({
              title: 'Update Code Name',
              input: 'text',
              inputValue: original.codeName || '',
              inputPlaceholder: 'Enter a Code Name...',
              showCancelButton: true,
            });

            if (dismiss) {
              return;
            }

            dispatch(operatorGroupsActions.async.updateOperatorGroupOperator(operatorGroup.operatorGroupId, {
              operatorGroupOperatorXrefId: original.operatorGroupOperatorXrefId,
              codeName: codeName,
            }));
          })();
        }
        return (
          <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(promptUserForCodeName)}>
            {original.codeName ? original.codeName : <em className="text-danger">None</em>} <i className="mdi mdi-pencil" />
          </Button>
        );
      },
    },
    {
      Header: 'Delete',
      Cell: ({ original }: { original: OperatorGroupOperatorXref }) => {
        return (
          <Button color="anchor" onClick={stopPropagationPreventDefaultAnd(() => deleteOperatorGroupOperator(original))}>
            <i className="fa fa-trash" />
          </Button>
        );
      },
      width: 90,
    },
  ];

  return (
    <div className="bg-white">
      <BaseTable
        {...baseTableOptions}
        data={operatorGroupOperatorXrefs}
        minRows={10}
        columns={columns}
        defaultSorted={[ { id: 'name', desc: false } ]}
      />
    </div>
  );
};

export default OperatorGroupXrefsTable;
