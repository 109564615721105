import React, { CSSProperties, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Props as ReactSelectProps } from 'react-select/src/Select';

import { AuditTemplate } from 'models';
import useStateSelector from 'hooks/useStateSelector';
import { templatesActions } from 'Modules/templates';
import { selectAllTemplates } from 'Modules/templates/selectors';

interface Props extends Omit<ReactSelectProps, 'value'> {
  value?: AuditTemplate;
  simpleValue?: number;
}

const selectStyles = {
  menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 3000 }),
};

const AuditTemplateSearch = ({ value, simpleValue, ...rest }: Props) => {
  const dispatch = useDispatch();
  const auditTemplates= useStateSelector(selectAllTemplates);

  useEffect(() => {
    dispatch(templatesActions.getAllAuditTemplates());
  }, []);

  const selectedValue = value ||
    (!!simpleValue && _.find(auditTemplates, (t) => t.auditTemplateId === simpleValue));

  return (
    <Select
      {...rest}
      isClearable
      placeholder="Select a Template..."
      menuPortalTarget={document.body}
      value={selectedValue || null}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.auditTemplateId}
      options={auditTemplates as any[]}
      styles={selectStyles as any}
    />
  );
};

export default AuditTemplateSearch;
