import { rolesSlice } from './slice';
import * as rolesApiActions from './apiActions';

const rolesActions = {
  ...rolesSlice.actions,
  async: {
    ...rolesApiActions,
  },
};

export default rolesActions;
