import _ from 'lodash';
import superagent, { Response } from 'superagent';
import { Dispatch, MiddlewareAPI } from 'redux';

import { commonActions, tosNotAgreedUpon, unauthorized } from 'Modules/common/actions';
import { Action, ApiAction, ApiPayload, DependantApiAction } from 'models';
import { RootState } from 'Modules/reducers';
import { Http403ForbiddenStatusCodes } from 'core/http';

const apiMiddleware = ({ dispatch, getState }: MiddlewareAPI<any, RootState>) => (next: Dispatch) => async (action: Action) => {
  switch (action.type) {
    case commonActions.API: {
      await runSingleApiRequest(action as ApiAction);
      break;
    }
    case commonActions.API_DEPENDANT_GETS: {
      await runDependantApiRequests(action as DependantApiAction);
      break;
    }
    default: {
      next(action);
    }
  }

  async function runSingleApiRequest (apiAction: ApiAction) {
    if (!shouldRun(apiAction.payload.shouldRunRequest)) {
      return;
    }

    try {
      await runApiRequest(apiAction);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Single API Request failed: ', e);
    }
  }

  async function runDependantApiRequests (action: DependantApiAction) {
    if (!shouldRun(action.payload.shouldRunRequest)) {
      return;
    }

    const resolvedData: any[] = [];

    const { payload: { apiActions, beforeRequest, success, failure } } = action;

    try {
      dispatch(beforeRequest());

      for (let i = 0; i < _.size(apiActions); i++) {
        const apiAction = apiActions[i];
        // eslint-disable-next-line no-await-in-loop
        const resolved = await runApiRequest(apiAction(resolvedData));
        resolvedData.push(resolved);
      }

      dispatch(success(resolvedData));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Dependant API Request failed: ', e);
      dispatch(failure());
    }
  }

  async function runApiRequest (action: ApiAction): Promise<any | undefined> {
    const { beforeRequest, success, successCallback, failure } = action.payload;

    if (_.isFunction(beforeRequest)) {
      dispatch(beforeRequest());
    }

    try {
      const { site: { authUser } } = getState();
      const request = await buildRequest(action.payload, authUser?.token);

      const response = await request;
      const { body: result } = response;

      const successActionOrActions = success(result, response);

      if (_.isArray(successActionOrActions)) {
        _.each(successActionOrActions, (action) => dispatch(action));
      } else {
        dispatch(successActionOrActions);
      }

      if (_.isFunction(successCallback)) {
        successCallback(result);
      }

      return result;
    } catch (err: any) {
      console.error(err);
      const { response } = err || {};

      if(response) {
        const apiResponse = response as Response;

        if(apiResponse.forbidden && apiResponse.body.statusDetail === Http403ForbiddenStatusCodes.TermsOfServiceNotAgreed) {
          dispatch(tosNotAgreedUpon());
        }
      }

      if (response && response.unauthorized) {
        dispatch(unauthorized());
        return;
      }

      dispatch(failure({
        validationError: response && response.badRequest,
        message: _.get(response, 'body.message'),
        error: err,
        response: response,
      }));

      console.error(response);
      console.error(err);
      throw err;
    }
  }

  function shouldRun (shouldRunRequest?: (state: RootState) => boolean) {
    if (!shouldRunRequest) {
      return true;
    }

    return shouldRunRequest(getState());
  }
};

async function buildRequest (payload: ApiPayload, token?: string) {
  const { getRequest } = payload;

  const request = getRequest(superagent);

  if (token) {
    request.set({ Authorization: `Bearer ${token}` });
  }

  return request;
}

export default apiMiddleware;
