import { operatorGroupsSlice } from './slice';
import * as operatorGroupsApiActions from './apiActions';

const operatorGroupsActions = {
  ...operatorGroupsSlice.actions,
  async: {
    ...operatorGroupsApiActions,
  },
};

export default operatorGroupsActions;
