import toastr from 'toastr';
import { ApiAction, UserModel } from 'models';
import appConfig from 'config';

import { commonActions } from '../common/actions';
import { CreateUserRequest, UpdateUserRequest } from './models';

export const usersActions = {
  SET_WORKING_CREATE_USER_VALUES: 'AVSolutions/users/setWorkingCreateUserValues',
  SET_WORKING_UPDATE_USER_VALUES: 'AVSolutions/users/setWorkingUpdateUserValues',
  CANCEL_USER_MODIFICATION: 'AVSolutions/users/cancelUserModification',

  GET_CURRENT_LOGGED_IN_USER_REQUEST: 'AVSolutions/users/GetCurrentLoggedInUserRequest',
  GET_CURRENT_LOGGED_IN_USER_SUCCESS: 'AVSolutions/users/GetCurrentLoggedInUserSuccess',
  GET_CURRENT_LOGGED_IN_USER_FAILURE: 'AVSolutions/users/GetCurrentLoggedInUserFailure',

  INVITE_USER_REQUEST: 'AVSolutions/users/InviteUserRequest',
  INVITE_USER_SUCCESS: 'AVSolutions/users/InviteUserSuccess',
  INVITE_USER_FAILURE: 'AVSolutions/users/InviteUserFailure',

  GET_ALL_USERS_REQUEST: 'AVSolutions/users/getAllUsersRequest',
  GET_ALL_USERS_SUCCESS: 'AVSolutions/users/getAllUsersSuccess',
  GET_ALL_USERS_FAILURE: 'AVSolutions/users/getAllUsersFailure',

  GET_USER_REQUEST: 'AVSolutions/users/getUserRequest',
  GET_USER_SUCCESS: 'AVSolutions/users/getUserSuccess',
  GET_USER_FAILURE: 'AVSolutions/users/getUserFailure',

  GET_PASSWORD_RESET_URL_REQUEST: 'AVSolutions/users/GetPasswordResetUrlRequest',
  GET_PASSWORD_RESET_URL_SUCCESS: 'AVSolutions/users/GetPasswordResetUrlSuccess',
  GET_PASSWORD_RESET_URL_FAILURE: 'AVSolutions/users/GetPasswordResetUrlFailure',

  SET_USER_ACCOUNT_ENABLED_REQUEST: 'AVSolutions/users/SetUserAccountEnabledRequest',
  SET_USER_ACCOUNT_ENABLED_SUCCESS: 'AVSolutions/users/SetUserAccountEnabledSuccess',
  SET_USER_ACCOUNT_ENABLED_FAILURE: 'AVSolutions/users/SetUserAccountEnabledFailure',

  INSERT_USER_REQUEST: 'AVSolutions/users/InsertUserRequest',
  INSERT_USER_SUCCESS: 'AVSolutions/users/InsertUserSuccess',
  INSERT_USER_FAILURE: 'AVSolutions/users/InsertUserFailure',

  UPDATE_USER_REQUEST: 'AVSolutions/users/UpdateUserRequest',
  UPDATE_USER_SUCCESS: 'AVSolutions/users/UpdateUserSuccess',
  UPDATE_USER_FAILURE: 'AVSolutions/users/UpdateUserFailure',

  AGREE_TO_TERMS_OF_SERVICE_REQUEST: 'AVSolutions/users/AgreeToTermsOfServiceRequest',
  AGREE_TO_TERMS_OF_SERVICE_SUCCESS: 'AVSolutions/users/AgreeToTermsOfServiceSuccess',
  AGREE_TO_TERMS_OF_SERVICE_FAILURE: 'AVSolutions/users/AgreeToTermsOfServiceFailure',

  SET_PROFILE_PICTURE_REQUEST: 'AVSolutions/users/SetProfilePictureRequest',
  SET_PROFILE_PICTURE_SUCCESS: 'AVSolutions/users/SetProfilePictureSuccess',
  SET_PROFILE_PICTURE_FAILURE: 'AVSolutions/users/SetProfilePictureFailure',

  DELETE_PROFILE_PICTURE_REQUEST: 'AVSolutions/users/DeleteProfilePictureRequest',
  DELETE_PROFILE_PICTURE_SUCCESS: 'AVSolutions/users/DeleteProfilePictureSuccess',
  DELETE_PROFILE_PICTURE_FAILURE: 'AVSolutions/users/DeleteProfilePictureFailure',

  DELETE_USER_REQUEST: 'AVSolutions/users/DeleteUserRequest',
  DELETE_USER_SUCCESS: 'AVSolutions/users/DeleteUserSuccess',
  DELETE_USER_FAILURE: 'AVSolutions/users/DeleteUserFailure',
};

export const setWorkingCreateUserValues = (values: Partial<CreateUserRequest>) => {
  return {
    type: usersActions.SET_WORKING_CREATE_USER_VALUES,
    payload: {
      values: values,
    },
  };
};

export const setWorkingUpdateUserValues = (values: Partial<UpdateUserRequest>) => {
  return {
    type: usersActions.SET_WORKING_UPDATE_USER_VALUES,
    payload: {
      values: values,
    },
  };
};

export const cancelUserModification = () => {
  return {
    type: usersActions.CANCEL_USER_MODIFICATION,
    payload: {},
  };
};

export const getCurrentLoggedInUser = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/users/me`);
      },
      beforeRequest: () => ({ type: usersActions.GET_CURRENT_LOGGED_IN_USER_REQUEST }),
      success: (data) => ({ type: usersActions.GET_CURRENT_LOGGED_IN_USER_SUCCESS, payload: { user: data } }),
      failure: (response) => ({ type: usersActions.GET_CURRENT_LOGGED_IN_USER_FAILURE, payload: { ...response } }),
    },
  };
};

export const getUser = (userId: string): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/users/${userId}`);
      },
      beforeRequest: () => ({ type: usersActions.GET_USER_REQUEST }),
      success: (data) => ({ type: usersActions.GET_USER_SUCCESS, payload: { user: data } }),
      failure: (response) => ({ type: usersActions.GET_USER_FAILURE, payload: { ...response } }),
    },
  };
};

export const getAllUsers = (includeRoles?: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/users`)
          .query({ includeRoles });
      },
      beforeRequest: () => ({ type: usersActions.GET_ALL_USERS_REQUEST }),
      success: (data) => ({ type: usersActions.GET_ALL_USERS_SUCCESS, payload: { users: data } }),
      failure: (response) => ({ type: usersActions.GET_ALL_USERS_FAILURE, payload: { ...response } }),
    },
  };
};

export const getPasswordResetUrl = (successCallback?: (passwordResetUrl: string) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/users/passwordReset`);
      },
      beforeRequest: () => ({ type: usersActions.GET_PASSWORD_RESET_URL_REQUEST }),
      success: (data) => ({ type: usersActions.GET_PASSWORD_RESET_URL_SUCCESS, payload: { passwordResetUrl: data } }),
      successCallback,
      failure: (response) => ({ type: usersActions.GET_PASSWORD_RESET_URL_FAILURE, payload: { ...response } }),
    },
  };
};

export const inviteUser = (userId: string, successCallback?: (data: { passwordResetUrl: string }) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/users/${userId}/invite`);
      },
      beforeRequest: () => ({ type: usersActions.INVITE_USER_REQUEST, payload: { userId } }),
      success: () => {
        toastr.success('Invite email has been sent.');
        return {
          type: usersActions.INVITE_USER_SUCCESS,
          payload: {
            userId,
          },
        };
      },
      successCallback: successCallback,
      failure: (response) => ({ type: usersActions.INVITE_USER_FAILURE, payload: { ...response, userId } }),
    },
  };
};

export const setUserEnabled = (userId: string, enabled: boolean, includeRoles?: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/users/${userId}/enabled/${enabled}`)
          .query({ includeRoles });
      },
      beforeRequest: () => ({ type: usersActions.SET_USER_ACCOUNT_ENABLED_REQUEST, payload: { userId, enabled } }),
      success: (data) => ({ type: usersActions.SET_USER_ACCOUNT_ENABLED_SUCCESS, payload: { userId, user: data } }),
      failure: (response) => ({ type: usersActions.SET_USER_ACCOUNT_ENABLED_FAILURE, payload: { ...response, userId, enabled } }),
    },
  };
};

export const insertUser = (user: CreateUserRequest, successCallback?: (user: UserModel) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/users`)
          .send(user);
      },
      beforeRequest: () => ({ type: usersActions.INSERT_USER_REQUEST, payload: { user } }),
      success: (data) => ({ type: usersActions.INSERT_USER_SUCCESS, payload: { user: data } }),
      successCallback,
      failure: (response) => ({ type: usersActions.INSERT_USER_FAILURE, payload: { ...response, user } }),
    },
  };
};

export const updateUser = (userId: string, user: UpdateUserRequest, successCallback?: (user: UserModel) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/users/${userId}`)
          .send(user);
      },
      beforeRequest: () => ({ type: usersActions.UPDATE_USER_REQUEST, payload: { user } }),
      success: (data) => ({ type: usersActions.UPDATE_USER_SUCCESS, payload: { user: data } }),
      successCallback,
      failure: (response) => ({ type: usersActions.UPDATE_USER_FAILURE, payload: { ...response, user } }),
    },
  };
};

export const agreeToTermsOfService = (callback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/users/me/tos/accept`);
      },
      beforeRequest: () => ({ type: usersActions.AGREE_TO_TERMS_OF_SERVICE_REQUEST, payload: { } }),
      success: (data) => ({ type: usersActions.AGREE_TO_TERMS_OF_SERVICE_SUCCESS, payload: { user: data } }),
      successCallback: callback,
      failure: (response) => ({ type: usersActions.AGREE_TO_TERMS_OF_SERVICE_FAILURE, payload: { ...response } }),
    },
  };
};

export const setProfilePicture = (userId: string, file: File, callback?: () => void): ApiAction => {
  const formData = new window.FormData();

  formData.append('imageFile', file);

  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/users/${userId}/image`)
          .send(formData);
      },
      beforeRequest: () => ({ type: usersActions.SET_PROFILE_PICTURE_REQUEST, payload: { } }),
      success: (data) => ({ type: usersActions.SET_PROFILE_PICTURE_SUCCESS, payload: { user: data } }),
      successCallback: callback,
      failure: (response) => ({ type: usersActions.SET_PROFILE_PICTURE_FAILURE, payload: { ...response } }),
    },
  };
};

export const removeProfilePicture = (userId: string, callback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/users/${userId}/image`);
      },
      beforeRequest: () => ({ type: usersActions.DELETE_PROFILE_PICTURE_REQUEST, payload: { } }),
      success: () => ({ type: usersActions.DELETE_PROFILE_PICTURE_SUCCESS, payload: { userId } }),
      successCallback: callback,
      failure: (response) => ({ type: usersActions.DELETE_PROFILE_PICTURE_FAILURE, payload: { ...response } }),
    },
  };
};

export const deleteUser = (userId: string, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent) => {
        return superagent.del(`${appConfig.baseUrl}/api/v1/users/${userId}`);
      },
      beforeRequest: () => ({ type: usersActions.DELETE_USER_REQUEST, payload: { userId } }),
      success: () => ({ type: usersActions.DELETE_USER_SUCCESS, payload: { userId: userId } }),
      successCallback,
      failure: (response) => ({ type: usersActions.DELETE_USER_FAILURE, payload: { ...response, userId } }),
    },
  };
};
