import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { CreatePoll, Poll } from 'Modules/polls/models';
import { pollsActions } from 'Modules/polls';
import { getNewPoll } from 'Modules/polls/instantiations';
import { useMergedEntity, usePollForm, useStateSelector } from 'hooks';
import { EditFormFooter, EditFormPanel, Panel, PanelHeader, PollForm } from 'Components';

const CreatePollPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rootPoll = useMemo(() => getNewPoll(), []);
  const workingPoll = useStateSelector((state) => state.polls.ui.maintenance.workingCreatePoll);
  const poll = useMergedEntity(rootPoll, workingPoll);
  const { insertPoll, cancelPollModification, loading } = usePollForm();

  function setWorkingPollValues(poll: Partial<CreatePoll>) {
    dispatch(pollsActions.setWorkingCreatePollValues(poll));
  }

  function handleSave() {
    if (!poll) {
      return;
    }

    insertPoll(poll)
      .then((poll: Poll) => {
        history.push(`/admin/polls/${poll.id}`);
      });
  }

  return (
    <Panel>
      <PanelHeader white>
        <em>Creating New Poll</em>
      </PanelHeader>

      <EditFormPanel
        Footer={(
          <EditFormFooter
            cancelButton={{
              onClick: cancelPollModification,
            }}
            saveButton={{
              onClick: handleSave,
              isLoading: loading.isInsertingPoll,
            }}
          />
        )}
      >
        <Row>
          <Col md={8}>
            <PollForm
              isCreatingNewPoll
              poll={poll}
              setValues={setWorkingPollValues}
            />
          </Col>
        </Row>
      </EditFormPanel>
    </Panel>
  );
};

export default CreatePollPanel;
