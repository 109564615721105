import _ from 'lodash';
import { createSelector } from 'reselect';
import UserXrefType from '../../constants/UserXrefType';
import { RootState } from '../reducers';
import { AdminOperatorModel, OperatorFeatureEnum, OperatorModel, UserOperatorModel } from '../../models';
import { getHashSet } from '../../core';

const getOperatorsEntities = (state: RootState) => state.operators.entities.operators;
const getOperatorUserXrefEntities = (state: RootState) => state.operators.entities.operatorUserXrefs;
const getOperatorUserXrefMappings = (state: RootState) => state.operators.xrefs.operatorUserXrefs;
const getAllOperatorIds = (state: RootState) => state.operators.ui.allOperatorIds;
const getCurrentUserOperatorIds = (state: RootState) => state.operators.ui.currentUserOperatorIds;
const getUserEntities = (state: RootState) => state.users.entities.users;

export const getCurrentUserOperators = createSelector([
  getOperatorsEntities,
  getCurrentUserOperatorIds,
], (
  operators,
  currentUserOperators
): OperatorModel[] => {
  return _.map(currentUserOperators, (o) => operators[o]);
});

export const getAllOperators = createSelector([
  getOperatorsEntities,
  getAllOperatorIds,
], (
  operators,
  allOperatorIds
): OperatorModel[] => {
  return _.sortBy(_.map(allOperatorIds, (o) => operators[o]), o => o.name);
});

export const getUsersByOperator = createSelector([
  getAllOperators,
  getOperatorUserXrefEntities,
  getOperatorUserXrefMappings,
  getUserEntities,
], (
  operators,
  operatorUserXrefs,
  operatorUserXrefMappings,
  users) => {
  const usersByOperator = {} as { [operatorId: number]: UserOperatorModel[]};

  _.each(operators, (o) => {
    const xrefs = _.map(operatorUserXrefMappings[o.operatorId], (xrefId) => operatorUserXrefs[xrefId]);

    _.each(xrefs, ({ operatorId, userId, userXrefTypeId }) => {
      if (!_.has(usersByOperator, operatorId)) {
        usersByOperator[operatorId] = [];
      }

      const user = users[userId];

      if (user) {
        usersByOperator[operatorId].push({
          ...user,
          userXrefTypeId: userXrefTypeId,
        });
      }
    });
  });

  return usersByOperator;
});

export const getAllOperatorModels = createSelector(
  [ getUsersByOperator, getAllOperators ],
  (usersByOperator, operators): AdminOperatorModel[] => {
    return _.map(operators, (operator) => {
      const users = _.get(usersByOperator, operator.operatorId, []);
      const readOnlyUsers = _.filter(users, { userXrefTypeId: UserXrefType.ReadOnly });
      const accounts = _.filter(users, { userXrefTypeId: UserXrefType.Account });
      const safetyCoaches = _.filter(users, { userXrefTypeId: UserXrefType.SafetyCoach });
      const adminOperatorModel: AdminOperatorModel = {
        ...operator,
        users: users,
        numSafetyCoaches: _.size(safetyCoaches),
        safetyCoaches: safetyCoaches,
        numAccounts: _.size(accounts),
        accounts: accounts,
        readOnlyUsers: readOnlyUsers,
      };

      return adminOperatorModel;
    });
  });

export const makeGetOperatorUsers = (operatorId: number) => createSelector(
  [ getUsersByOperator ],
  (usersByOperator) => {
    return _.get(usersByOperator, operatorId, []);
  });

export const makeGetEnabledOperatorFeatures = (operatorId: number) => createSelector(
  [
    (state: RootState) => state.operators.entities.operatorFeatures,
    (state: RootState) => state.operators.xrefs.operatorFeatures,
  ],
  (operatorFeatures, operatorFeatureXrefs): Record<OperatorFeatureEnum, boolean> => {
    const ids = _.get(operatorFeatureXrefs, operatorId, []);

    const features = ids.map(id => operatorFeatures[id]);

    const enabledFeatureIds = getHashSet(features, f => f.featureId);

    return {
      [OperatorFeatureEnum.ShareBoard]: _.has(enabledFeatureIds, OperatorFeatureEnum.ShareBoard),
      [OperatorFeatureEnum.Polling]: _.has(enabledFeatureIds, OperatorFeatureEnum.Polling),
    };
  });
