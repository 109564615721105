import _ from 'lodash';
import { createSelector } from 'reselect';
import TaskStatusType from '../../constants/TaskStatusType';
import { RootState } from '../reducers';
import {
  AssignedTaskModel,
  BaseOperatorDashboardModel,
  OperatorAuditModel,
  OperatorDashboardCurrentWeek,
  OperatorModel,
  SafetyCoachOperatorDashboardModel,
} from '../../models';

const getCurrentUserId = (state: RootState): string => state.site.user!.userId;
const getOperatorsEntities = (state: RootState) => state.operators.entities.operators;
const getTaskEntities = (state: RootState) => state.tasks.entities.tasks;
const getOperatorDashboardOperatorIds = (state: RootState) => state.operators.ui.operatorDashboardOperatorIds;
const getSafetyCoachDashboardOperatorIds = (state: RootState) => state.operators.ui.safetyCoachDashboardOperatorIds;
const getAuditEntities = (state: RootState) => state.audits.entities.audits;
const getAuditTaskIds = (state: RootState) => state.audits.mappings.auditTasks;
const getOperatorDashboard = (state: RootState) => state.dashboard.operatorDashboard;
const getSafetyCoachDashboard = (state: RootState) => state.dashboard.safetyCoachDashboard;

export const getOperatorDashboardOperators = createSelector([
  getOperatorsEntities,
  getOperatorDashboardOperatorIds,
], (
  operators,
  operatorDashboardOperatorIds
) => {
  return _.map(operatorDashboardOperatorIds, (o) => operators[o]);
});

export const getKeyedOperatorDashboardOperators = createSelector([
  getOperatorDashboardOperators,
], (
  operators
): {[operatorId: number]: OperatorModel} => {
  return _.keyBy(operators, (o) => o.operatorId);
});

export const getSafetyCoachDashboardOperators = createSelector([
  getOperatorsEntities,
  getSafetyCoachDashboardOperatorIds,
], (
  operators,
  safetyCoachDashboardOperatorIds
) => {
  return _.map(safetyCoachDashboardOperatorIds, (o) => operators[o]);
});

export const getKeyedSafetyCoachDashboardOperators = createSelector([
  getSafetyCoachDashboardOperators,
], (
  operators
): {[operatorId: number]: OperatorModel} => {
  return _.keyBy(operators, (o) => o.operatorId);
});

export const getCurrentUserOperatorDashboard = createSelector([ getOperatorDashboard, getCurrentUserId, getKeyedOperatorDashboardOperators, getAuditEntities, getTaskEntities, getAuditTaskIds ],
  (operatorDashboard, userId, operators, audits, tasks, auditTasks) => {
    if(!operatorDashboard) {
      return undefined;
    }

    const { operatorNextAuditId, operatorSafetyCoaches } = operatorDashboard;

    const baseOperatorDashboard = getBaseOperatorDashboard(audits, tasks, operatorNextAuditId ? auditTasks[operatorNextAuditId] : [], operatorNextAuditId);

    const models: OperatorDashboardCurrentWeek = {
      ...baseOperatorDashboard,
      safetyCoaches: operatorSafetyCoaches,
    };

    return models;
  });

export const getCurrentUserSafetyCoachDashboard = createSelector([ getSafetyCoachDashboard, getCurrentUserId, getKeyedSafetyCoachDashboardOperators, getAuditEntities, getTaskEntities, getAuditTaskIds ],
  (safetyCoachDashboard, userId, operators, audits, tasks, auditTasks) => {
    if(!safetyCoachDashboard) {
      return undefined;
    }

    const { operatorNextAuditId } = safetyCoachDashboard;

    const baseOperatorDashboard = getBaseOperatorDashboard(audits, tasks, operatorNextAuditId ? auditTasks[operatorNextAuditId] : [], operatorNextAuditId);

    const dashboardModel: SafetyCoachOperatorDashboardModel = {
      ...baseOperatorDashboard,
    };

    return dashboardModel;
  });

function getBaseOperatorDashboard (
  audits: {[auditId: number]: OperatorAuditModel},
  tasks: {[taskId: number]: AssignedTaskModel},
  nextAuditTaskIds: number[],
  nextAuditId: number | undefined
): BaseOperatorDashboardModel {
  const nextAudit = nextAuditId ? _.get(audits, nextAuditId) : undefined;
  const nextAuditTasks = _.sortBy(_.map(nextAuditTaskIds, (taskId) => tasks[taskId]), t => t.position);
  const pastDueNeedingFeedbackTasks = _.filter(nextAuditTasks, t => t.isPastDue && !_.includes([ TaskStatusType.PendingApproval, TaskStatusType.Complete, TaskStatusType.NotApplicable ], t.currentStatus));
  const pastDueNeedingApprovalTasks = _.filter(nextAuditTasks, t => t.isPastDue && _.includes([ TaskStatusType.PendingApproval, TaskStatusType.NotApplicable ], t.currentStatus));
  const tasksForCurrentWeek = _.filter(nextAuditTasks, t => t.isThisWeek);
  const persistentTasks = _.filter(nextAuditTasks, t => !t.taskDate);

  return {
    nextAudit: nextAudit,
    nextAuditTasks: nextAuditTasks,
    numTasksForThisWeek: _.size(tasksForCurrentWeek),
    tasksForThisWeek: tasksForCurrentWeek,
    pastDueNeedingApprovalTasks: pastDueNeedingApprovalTasks,
    pastDueNeedingFeedbackTasks: pastDueNeedingFeedbackTasks,
    persistentTasks: persistentTasks,
  };
}
