import React from 'react';
import { Redirect } from 'react-router-dom';

import useStateSelector from 'hooks/useStateSelector';
import { useCurrentUserPermissionChecker } from '../../hooks/index';
import { Permission } from '../../Modules/roles/index';

const HomePage = () => {
  const currentUserHasPermission = useCurrentUserPermissionChecker();
  const selectedOperator = useStateSelector(state => state.site.selectedOperator);

  if (currentUserHasPermission(Permission.ViewActiveUsers)) {
    return <Redirect to="/dashboards/admin"/>;
  }

  if (selectedOperator?.userIsSafetyCoach) {
    return <Redirect to="/dashboards/coach"/>;
  }

  if (selectedOperator?.userIsAccount) {
    return <Redirect to="/dashboards/operator"/>;
  }

  return (
    <div>
      Nothing to show...
    </div>
  );
};

export default HomePage;
