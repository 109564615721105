import _ from 'lodash';
import appConfig from 'config';
import { SortDirectionEnum } from 'models';

export interface PaginatedRequest {
  pageSize: number;
  offset: number;
}

export interface TermSearchRequest extends PaginatedRequest {
  filter?: string;
}

export interface PaginatedDashboardRequest<TFieldType extends number, TRequestField extends PaginatedDashboardRequestField<TFieldType> = PaginatedDashboardRequestField<TFieldType>> extends PaginatedRequest {
  globalFilter?: string;
  fields: TRequestField[];
}

export interface PaginatedResult<TResult> {
  results: TResult[];
  page: number;
  pageSize: number;
  total: number;
}

export interface PaginatedSearchContainer<TPaginatedRequest extends PaginatedRequest = PaginatedRequest> {
  total: number;
  request: TPaginatedRequest;
  ids: any[];
}

export const getDefaultPaginatedSearchContainer = (): PaginatedSearchContainer => {
  return {
    request: appConfig.defaultPagination,
    total: 0,
    ids: [],
  };
};

export interface PaginatedDashboardSearchContainer<TFieldType extends number, TRequestField extends PaginatedDashboardRequestField<TFieldType> = PaginatedDashboardRequestField<TFieldType>, TPaginatedDashboardRequest extends PaginatedDashboardRequest<TFieldType, TRequestField> = PaginatedDashboardRequest<TFieldType, TRequestField>> {
  total: number;
  request: TPaginatedDashboardRequest;
  ids: any[];
}

export interface PaginatedDashboardRequestField<TFieldType extends number> {
  field: TFieldType;
  filters: string[];
  sort?: SortDirectionEnum;
}

export const canLoadMore = (pagination: PaginatedSearchContainer) => {
  const hasMore = _.size(pagination.ids) < pagination.total;
  const canLoadMore = (pagination.request.offset + pagination.request.pageSize) < pagination.total;
  if (hasMore && !canLoadMore) {
    return false;
  }
  return hasMore;
};

export const convertPaginatedResultToPaginatedSearchState = <T>({ total, page, pageSize }: PaginatedResult<T>) => ({
  request: {
    offset: page * pageSize,
    pageSize,
  },
  total,
});

export const getReducerAppendResults = <T>(
  response: PaginatedResult<T>,
  existingEntities: { [id: number]: T } | undefined,
  existingPagination: PaginatedSearchContainer,
  idSelector: (item: T) => number
): { entities: { [id: number]: T }; pagination: PaginatedSearchContainer } => {
  const loadedMore = response.page > 0;

  const ids = loadedMore ? existingPagination?.ids || [] : [];

  return {
    entities: {
      ...existingEntities,
      ..._.keyBy(response.results, idSelector),
    },
    pagination: {
      ...convertPaginatedResultToPaginatedSearchState(response),
      ids: _.union(ids, _.map(response.results, idSelector)),
    },
  };
};

export const getReducerReplaceResults = <T>(
  response: PaginatedResult<T>,
  existingEntities: { [id: number]: T } | undefined,
  existingPagination: PaginatedSearchContainer,
  idSelector: (item: T) => number
): { entities: { [id: number]: T }; pagination: PaginatedSearchContainer } => {
  return {
    entities: {
      ...existingEntities,
      ..._.keyBy(response.results, idSelector),
    },
    pagination: {
      ...convertPaginatedResultToPaginatedSearchState(response),
      ids: _.map(response.results, idSelector),
    },
  };
};
