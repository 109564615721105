import { useMemo } from 'react';

function useMergedEntity<T>(rootEntity: T, workingEntity: Partial<T>): T;
function useMergedEntity<T>(rootEntity: undefined, workingEntity: Partial<T>): T | undefined;
function useMergedEntity<T>(rootEntity: T | undefined, workingEntity: Partial<T>): T | undefined {
  return useMemo(() => {
    if (!rootEntity) {
      return undefined;
    }
    return { ...rootEntity, ...workingEntity };
  }, [ rootEntity, workingEntity ]);
}

export default useMergedEntity;
