import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiLoadingStatusPayload } from 'api/ApiCallParameters';
import { getValueByKey } from 'core';
import {
  CreateOperatorAttribute,
  CreateOperatorAttributeType,
  OperatorAttribute,
  OperatorAttributeType,
  UpdateOperatorAttribute,
  UpdateOperatorAttributeType,
} from 'Modules/operatorAttributes/models';
import { commonActions } from 'Modules/common/actions';

export interface OperatorAttributesState {
  entities: {
    operatorAttributes: { [operatorAttributeId: number]: OperatorAttribute };
    operatorAttributeTypes: { [operatorAttributeTypeId: number]: OperatorAttributeType };
  };
  xrefs: {
    operatorOperatorAttributes: { [operatorId: number]: number[] };
  };
  ui: {
    allOperatorAttributeTypeIds: number[];
    maintenance: {
      workingCreateOperatorAttribute: Partial<CreateOperatorAttribute>;
      workingUpdateOperatorAttribute: Partial<UpdateOperatorAttribute>;
      workingCreateOperatorAttributeType: Partial<CreateOperatorAttributeType>;
      workingUpdateOperatorAttributeType: Partial<UpdateOperatorAttributeType>;
    }
  };
  loading: {
    isLoadingOperatorAttribute: boolean;
    isLoadingOperatorAttributeType: boolean;
    isLoadingAllOperatorAttributes: boolean;
    isLoadingAllOperatorAttributeTypes: boolean;
    isUpdatingOperatorAttribute: boolean;
    isUpdatingOperatorAttributeType: boolean;
    isInsertingOperatorAttribute: boolean;
    isInsertingOperatorAttributeType: boolean;
    isDeletingOperatorAttribute: boolean;
    isDeletingOperatorAttributeType: boolean;
  };
}

const initialOperatorAttributesState: OperatorAttributesState = {
  entities: {
    operatorAttributes: {},
    operatorAttributeTypes: {},
  },
  xrefs: {
    operatorOperatorAttributes: {},
  },
  ui: {
    allOperatorAttributeTypeIds: [],
    maintenance: {
      workingCreateOperatorAttribute: {},
      workingUpdateOperatorAttribute: {},
      workingCreateOperatorAttributeType: {},
      workingUpdateOperatorAttributeType: {},
    },
  },
  loading: {
    isLoadingOperatorAttribute: false,
    isLoadingOperatorAttributeType: false,
    isLoadingAllOperatorAttributes: false,
    isLoadingAllOperatorAttributeTypes: false,
    isUpdatingOperatorAttribute: false,
    isUpdatingOperatorAttributeType: false,
    isInsertingOperatorAttribute: false,
    isInsertingOperatorAttributeType: false,
    isDeletingOperatorAttribute: false,
    isDeletingOperatorAttributeType: false,
  },
};

export const operatorAttributesSlice = createSlice({
  name: 'OperatorAttributes',
  initialState: initialOperatorAttributesState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<OperatorAttributesState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setWorkingCreateOperatorAttributeValues: (state, action: PayloadAction<Partial<CreateOperatorAttribute>>) => {
      state.ui.maintenance.workingCreateOperatorAttribute = {
        ...state.ui.maintenance.workingCreateOperatorAttribute,
        ...action.payload,
      };
    },
    setWorkingUpdateOperatorAttributeValues: (state, action: PayloadAction<Partial<UpdateOperatorAttribute>>) => {
      state.ui.maintenance.workingUpdateOperatorAttribute = {
        ...state.ui.maintenance.workingUpdateOperatorAttribute,
        ...action.payload,
      };
    },
    cancelOperatorAttributeModification: (state) => {
      state.ui.maintenance.workingUpdateOperatorAttribute = {};
      state.ui.maintenance.workingCreateOperatorAttribute = {};
    },
    setWorkingCreateOperatorAttributeTypeValues: (state, action: PayloadAction<Partial<CreateOperatorAttributeType>>) => {
      state.ui.maintenance.workingCreateOperatorAttributeType = {
        ...state.ui.maintenance.workingCreateOperatorAttributeType,
        ...action.payload,
      };
    },
    setWorkingUpdateOperatorAttributeTypeValues: (state, action: PayloadAction<Partial<UpdateOperatorAttributeType>>) => {
      state.ui.maintenance.workingUpdateOperatorAttributeType = {
        ...state.ui.maintenance.workingUpdateOperatorAttributeType,
        ...action.payload,
      };
    },
    cancelOperatorAttributeTypeModification: (state) => {
      state.ui.maintenance.workingUpdateOperatorAttributeType = {};
      state.ui.maintenance.workingCreateOperatorAttributeType = {};
    },
    setOperatorAttribute: (state, action: PayloadAction<OperatorAttribute>) => {
      state.entities.operatorAttributes = {
        ...state.entities.operatorAttributes,
        [action.payload.id]: action.payload,
      };

      state.xrefs.operatorOperatorAttributes[action.payload.operatorId] = _.union(state.xrefs.operatorOperatorAttributes[action.payload.operatorId], [ action.payload.id ]);
    },
    setOperatorAttributeType: (state, action: PayloadAction<OperatorAttributeType>) => {
      state.entities.operatorAttributeTypes = {
        ...state.entities.operatorAttributeTypes,
        [action.payload.id]: action.payload,
      };

      state.ui.allOperatorAttributeTypeIds = _.union(state.ui.allOperatorAttributeTypeIds, [ action.payload.id ]);
    },
    setOperatorAttributes: (state, action: PayloadAction<{ operatorId: number; attributes: OperatorAttribute[] }>) => {
      state.entities.operatorAttributes = {
        ...state.entities.operatorAttributes,
        ..._.keyBy(action.payload.attributes, a => a.id),
      };

      state.xrefs.operatorOperatorAttributes[action.payload.operatorId] = action.payload.attributes.map(a => a.id);
    },
    setAllOperatorAttributeTypes: (state, action: PayloadAction<OperatorAttributeType[]>) => {
      state.entities.operatorAttributeTypes = {
        ...state.entities.operatorAttributeTypes,
        ..._.keyBy(action.payload, a => a.id),
      };

      state.ui.allOperatorAttributeTypeIds = action.payload.map(a => a.id);
    },
    operatorAttributeInserted: (state, action: PayloadAction<OperatorAttribute>) => {
      state.entities.operatorAttributes[action.payload.id] = action.payload;
      state.xrefs.operatorOperatorAttributes[action.payload.operatorId] = _.union(state.xrefs.operatorOperatorAttributes[action.payload.operatorId], [ action.payload.id ]);
    },
    operatorAttributeTypeInserted: (state, action: PayloadAction<OperatorAttributeType>) => {
      state.entities.operatorAttributeTypes[action.payload.id] = action.payload;
      state.ui.allOperatorAttributeTypeIds = _.union(state.ui.allOperatorAttributeTypeIds, [ action.payload.id ]);
    },
    operatorAttributeUpdated: (state, action: PayloadAction<OperatorAttribute>) => {
      state.entities.operatorAttributes[action.payload.id] = action.payload;
    },
    operatorAttributeTypeUpdated: (state, action: PayloadAction<OperatorAttributeType>) => {
      state.entities.operatorAttributeTypes[action.payload.id] = action.payload;
    },
    operatorAttributeDeleted: (state, action: PayloadAction<number>) => {
      const existingOperatorAttribute = getValueByKey(state.entities.operatorAttributes, action.payload);
      if(!existingOperatorAttribute) {
        return;
      }

      state.entities.operatorAttributes = _.omit(state.entities.operatorAttributes, action.payload);
      state.xrefs.operatorOperatorAttributes[existingOperatorAttribute.operatorId] = _.without(state.xrefs.operatorOperatorAttributes[existingOperatorAttribute.operatorId], action.payload);
    },
    operatorAttributeTypeDeleted: (state, action: PayloadAction<number>) => {
      state.entities.operatorAttributeTypes = _.omit(state.entities.operatorAttributeTypes, action.payload);
      state.ui.allOperatorAttributeTypeIds = _.without(state.ui.allOperatorAttributeTypeIds, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(commonActions.PATH_LOCATION_CHANGE, (state) => {
        state.ui.maintenance.workingCreateOperatorAttribute = {};
        state.ui.maintenance.workingCreateOperatorAttributeType = {};
        state.ui.maintenance.workingUpdateOperatorAttribute = {};
        state.ui.maintenance.workingUpdateOperatorAttributeType = {};
      })
      .addCase(commonActions.AREA_LOCATION_CHANGE, () => {
        return { ...initialOperatorAttributesState };
      });
  },
});
