import React, { ReactNode } from 'react';
import _ from 'lodash';

import { useLoggedInUser, useStateSelector } from 'hooks';
import { TermsOfServiceModal } from 'Components';

interface Props {
  children: ReactNode;
}

const TermsOfServiceWrapper = ({ children }: Props) => {
  const loggedInUser = useLoggedInUser();
  const pageModel = useStateSelector(state => state.site.pageModel);
  const promptTos = useStateSelector(state => state.site.promptTos);

  const Modal = <TermsOfServiceModal show hide={_.noop} />;

  if(promptTos) {
    return (
      <>
        {Modal}
      </>
    );
  }

  if(!pageModel) {
    return null;
  }

  if(pageModel.forceTermsOfServiceAgreement && !loggedInUser?.termsOfServiceAgreedOn) {
    return (
      <>
        {Modal}
      </>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default TermsOfServiceWrapper;
