import React, { useRef, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import { useSelector } from 'react-redux';
import { getAccessToken } from 'Modules/site/selectors';
import { callApi } from 'api/ApiDao';
import { LoadableButton } from 'Components';
import { ReferenceMaterial } from 'Modules/referenceMaterials/models';
import ReferenceFileSearch from '../../../Searches/ReferenceFileSearch';

interface Props {
  onChange: Function;
  editorState: EditorState;
}

enum FileType {
  File = 'file',
  Video = 'video'
}

const fileTypeOptions = {
  [FileType.File]: { accept: '', title: 'Embed File', label: 'File Label' },
  [FileType.Video]: { accept: 'video/mp4', title: 'Embed Video', label: 'Video Label' },
};

const UploadFileOption = ({ editorState, onChange }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [ fileType, setFileType ] = useState<FileType | undefined>();
  const [ name, setName ] = useState<string>('');
  const [ file, setFile ] = useState<File | undefined>();
  const [ referenceMaterial, setReferenceMaterial ] = useState<ReferenceMaterial | undefined>();
  const [ isLoading, setIsLoading ] = useState(false);

  const accessToken = useSelector(getAccessToken);

  function addFile (referenceFileId: number) {
    if(!fileType) {
      return;
    }
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      fileType,
      'IMMUTABLE',
      { referenceFileId }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity }
    );

    onChange(AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' '
    ));
  }

  function handleFileSelect (e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    setFile(e.target.files[0]);
  }

  function handleSubmit (e: React.FormEvent) {
    e.preventDefault();

    if (!file || !name.trim()) {
      return;
    }

    setIsLoading(true);

    const formData = new window.FormData();

    formData.append('file', file);

    callApi<ReferenceMaterial>({
      apiToken: accessToken,
      getRequest: (superagent) => superagent
        .post('/api/v1/referenceMaterials')
        .query({ name: name.trim() })
        .send(formData),
      onSuccess: (referenceFile) => {
        addFile(referenceFile.referenceFileId);
      },
      onComplete: () => {
        setIsLoading(false);
      },
    });
  }

  function handleReferenceFileSave () {
    if (!referenceMaterial) {
      return;
    }

    addFile(referenceMaterial.referenceFileId);
  }

  return (
    <div className="rdw-history-wrapper">
      <UncontrolledButtonDropdown>
        <DropdownToggle size="sm" color="light" id="UploadFile">
          <i className="mdi mdi-file-plus" /> Embed Reference Material
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setFileType(FileType.File)}>Embed File...</DropdownItem>
          <DropdownItem onClick={() => setFileType(FileType.Video)}>Embed Video...</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>

      <Popover trigger="legacy" isOpen={!!fileType} placement="bottom" target="UploadFile" toggle={() => setFileType(undefined)}>
        {
          (() => {
            if(!fileType) {
              return null;
            }

            const { title, label, accept } = fileTypeOptions[fileType];

            return (
              <div ref={(c) => ref.current = c}>
                <PopoverHeader>{title}</PopoverHeader>
                <PopoverBody>
                  <form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label>Existing Reference Material</Label>

                      <ReferenceFileSearch
                        menuPortalTarget={undefined}
                        isClearable
                        value={referenceMaterial}
                        onChange={(referenceMaterial) => setReferenceMaterial(referenceMaterial || undefined)}
                      />
                    </FormGroup>

                    <LoadableButton
                      isLoading={isLoading}
                      color="primary"
                      disabled={!referenceMaterial}
                      onClick={handleReferenceFileSave}
                      type="button">
                      Add
                    </LoadableButton>

                    <hr />
                    <FormGroup>
                      <Label>{label}:</Label>
                      <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                      <Input accept={accept} type="file" onChange={handleFileSelect} />
                    </FormGroup>

                    <LoadableButton
                      isLoading={isLoading}
                      color="primary"
                      disabled={!name.trim() || !file}
                      type="submit">
                      Upload
                    </LoadableButton>
                  </form>
                </PopoverBody>
              </div>
            );
          })()
        }
      </Popover>
    </div>
  );
};

export default UploadFileOption;
