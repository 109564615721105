import React from 'react';
import { DebounceInput, DebounceInputProps } from 'Components/index';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

interface Props extends DebounceInputProps {
  isLoading?: boolean;
}

const SearchInputGroup = ({ isLoading, ...props }: Props) => {
  return (
    <InputGroup className="transparent-addon-append no-events mb-2">
      <DebounceInput
        {...props}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          {isLoading && (
            <i className="mdi mdi-spin mdi-refresh" />
          )}
          {!isLoading && (
            <i className="mdi mdi-magnify" />
          )}
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default SearchInputGroup;
