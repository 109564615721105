import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { makeFormHelper } from 'core';
import { ModifyOperatorAttributeType } from 'Modules/operatorAttributes';

interface Props {
  isCreatingNewOperatorAttributeType?: boolean;
  operatorAttributeType: ModifyOperatorAttributeType;
  setValues: (values: Partial<ModifyOperatorAttributeType>) => void;
}

const OperatorAttributeTypeFormControls = ({ operatorAttributeType, isCreatingNewOperatorAttributeType, ...props }: Props) => {
  const canGloballyEdit = true; // todo permissions useHasPermission(Permission.OperatorAttributeTypesEdit);

  function setWorkingOperatorAttributeTypeValues(values: Partial<ModifyOperatorAttributeType>) {
    props.setValues(values);
  }

  const canEdit = isCreatingNewOperatorAttributeType || canGloballyEdit;
  const { getInputProps } = makeFormHelper(operatorAttributeType, setWorkingOperatorAttributeTypeValues);

  return (
    <>
      <FormGroup>
        <Label>Question</Label>
        <Input placeholder="Question..." disabled={!canEdit} {...getInputProps('name')} />
      </FormGroup>
    </>
  );
};

export default OperatorAttributeTypeFormControls;
