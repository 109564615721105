import { useEffect } from 'react';

interface LocalProps {
  className: string;
  element: HTMLElement;
}

const DomClass = ({ className, element }: LocalProps) => {
  useEffect(() => {
    element.classList.add(className);

    return () => {
      element.classList.remove(className);
    };
  }, []);
  return null;
};

export default DomClass;
