import React from 'react';
import _ from 'lodash';
import { pluralCoalesce } from '../../../helpers/listHelpers';
import DashboardCard from './DashboardCard';

interface LocalProps {
    incompleteTasks: number | any[];
}

const NumIncompleteTasksCard = ({ incompleteTasks }: LocalProps) => {
  return (
    <DashboardCard
      icon={<i className="mdi mdi-format-list-checks mdi-36px" />}
      heading="Incomplete:"
      body={`${_.isArray(incompleteTasks) ? _.size(incompleteTasks) : incompleteTasks} ${pluralCoalesce(incompleteTasks, 'Task', 'Tasks')}`}
    />
  );
};

export default NumIncompleteTasksCard;
