import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


import { ApiLoadingStatusPayload } from 'api';
import {
  getDefaultPaginatedSearchContainer,
  getReducerAppendResults,
  getReducerReplaceResults,
  PaginatedResult,
  PaginatedSearchContainer,
} from 'core';
import { commonActions } from 'Modules/common/actions';

import {
  CreatePoll,
  CreatePollCategory,
  Poll,
  PollCategory, PollOperatorGroup,
  PollOption,
  PollResult,
  PollResultSummary,
  UpdatePoll,
  UpdatePollCategory,
} from './models';

export interface PollsState {
  entities: {
    polls: { [id: number]: Poll };
    pollCategories: { [id: number]: PollCategory };
    pollOptions: { [pollOptionId: number]: PollOption };
    pollResultSummaries: { [pollResultId: number]: PollResultSummary };
    pollOperatorGroups: { [pollId: number]: PollOperatorGroup };
  };
  xrefs: {
    pollOptions: { [pollId: number]: number[] };
    pollResultSummaries: { [pollId: number]: number[] };
    pollOperatorGroups: { [pollId: number]: number[] };
  };
  ui: {
    createdPollOptionIds: number[];
    currentOperatorPendingPollCount: number | undefined;
    pollsPagination: PaginatedSearchContainer,
    pollCategoryPagination: PaginatedSearchContainer,
    operatorPollPagination: PaginatedSearchContainer,
    maintenance: {
      workingCreatePoll: Partial<CreatePoll>;
      workingUpdatePoll: Partial<UpdatePoll>;
    };
    categoryMaintenance: {
      workingCreatePollCategory: Partial<CreatePollCategory>;
      workingUpdatePollCategory: Partial<UpdatePollCategory>;
    };
  };
  loading: {
    isLoadingPoll: boolean;
    isLoadingPollCategory: boolean;
    isSearchingPolls: boolean;
    isSearchingAllPollCategories: boolean;
    isSearchingPollCategories: boolean;
    isSearchingOperatorPolls: boolean;
    isLoadingPollOptions: boolean;
    isLoadingPollOption: boolean;
    isLoadingPollResultSummaries: boolean;
    isLoadingPollResultSummary: boolean;
    isLoadingPollOperatorGroups: boolean;
    isInsertingPoll: boolean;
    isInsertingPollCategory: boolean;
    isInsertingPollOption: boolean;
    isMergingPollOptions: boolean;
    isInsertingPollOperatorGroup: boolean;
    isUpdatingPollResult: boolean;
    isInsertingPollResult: boolean;
    isUpdatingPoll: boolean;
    isUpdatingPollCategory: boolean;
    updatingPolls: {[id: number]: number};
    isDeletingPoll: boolean;
    isDeletingPollOperatorGroup: boolean;
    isDeletingPollResponse: boolean;
    isDeletingPollCategory: boolean;
  };
}

const initialPollsState: PollsState = {
  entities: {
    polls: {},
    pollCategories: {},
    pollOptions: {},
    pollResultSummaries: {},
    pollOperatorGroups: {},
  },
  xrefs: {
    pollOptions: {},
    pollResultSummaries: {},
    pollOperatorGroups: {},
  },
  ui: {
    createdPollOptionIds: [],
    currentOperatorPendingPollCount: undefined,
    pollsPagination: getDefaultPaginatedSearchContainer(),
    pollCategoryPagination: getDefaultPaginatedSearchContainer(),
    operatorPollPagination: getDefaultPaginatedSearchContainer(),
    maintenance: {
      workingCreatePoll: {},
      workingUpdatePoll: {},
    },
    categoryMaintenance: {
      workingCreatePollCategory: {},
      workingUpdatePollCategory: {},
    },
  },
  loading: {
    isLoadingPoll: false,
    isLoadingPollCategory: false,
    isSearchingPolls: false,
    isSearchingAllPollCategories: false,
    isSearchingPollCategories: false,
    isSearchingOperatorPolls: false,
    isLoadingPollOptions: false,
    isLoadingPollOption: false,
    isLoadingPollResultSummaries: false,
    isLoadingPollResultSummary: false,
    isLoadingPollOperatorGroups: false,
    isInsertingPoll: false,
    isInsertingPollCategory: false,
    isInsertingPollOption: false,
    isInsertingPollOperatorGroup: false,
    isMergingPollOptions: false,
    isUpdatingPollResult: false,
    isInsertingPollResult: false,
    isUpdatingPoll: false,
    isUpdatingPollCategory: false,
    isDeletingPoll: false,
    isDeletingPollOperatorGroup: false,
    isDeletingPollCategory: false,
    isDeletingPollResponse: false,
    updatingPolls: {},
  },
};

export const pollsSlice = createSlice({
  name: 'Polls',
  initialState: initialPollsState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<PollsState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    setPoll: (state, action: PayloadAction<Poll>) => {
      state.entities.polls = {
        ...state.entities.polls,
        [action.payload.id]: action.payload,
      };
    },
    setPollCategory: (state, action: PayloadAction<PollCategory>) => {
      state.entities.pollCategories = {
        ...state.entities.pollCategories,
        [action.payload.id]: action.payload,
      };
    },
    setPollOptions: (state, action: PayloadAction<{ pollId: number; options: PollOption[] }>) => {
      state.entities.pollOptions = {
        ...state.entities.pollOptions,
        ..._.keyBy(action.payload.options, o => o.id),
      };

      state.xrefs.pollOptions[action.payload.pollId] = action.payload.options.map(o => o.id);
    },
    setPollOption: (state, action: PayloadAction<PollOption>) => {
      state.entities.pollOptions = {
        ...state.entities.pollOptions,
        [action.payload.id]: action.payload,
      };
    },
    setPollResultSummaries: (state, action: PayloadAction<{ pollId: number; results: PollResultSummary[] }>) => {
      state.entities.pollResultSummaries = {
        ...state.entities.pollResultSummaries,
        ..._.keyBy(action.payload.results, o => o.pollResultId),
      };

      state.xrefs.pollResultSummaries[action.payload.pollId] = action.payload.results.map(o => o.pollResultId);
    },
    setPollOperatorGroupsForPoll: (state, action: PayloadAction<{ pollId: number; pollOperatorGroups: PollOperatorGroup[] }>) => {
      state.entities.pollOperatorGroups = {
        ...state.entities.pollOperatorGroups,
        ..._.keyBy(action.payload.pollOperatorGroups, o => o.id),
      };

      state.xrefs.pollOperatorGroups[action.payload.pollId] = action.payload.pollOperatorGroups.map(o => o.id);
    },
    setPollResultSummary: (state, action: PayloadAction<PollResultSummary>) => {
      state.entities.pollResultSummaries = {
        ...state.entities.pollResultSummaries,
        [action.payload.pollResultId]: action.payload,
      };
    },
    setCurrentOperatorPendingPollCount: (state, action: PayloadAction<number>) => {
      state.ui.currentOperatorPendingPollCount = action.payload;
    },
    setSearchedPolls: (state, action: PayloadAction<PaginatedResult<Poll>>) => {
      const { entities, pagination } = getReducerAppendResults(action.payload, state.entities.polls, state.ui.pollsPagination, (r) => r.id);

      state.entities.polls = entities;
      state.ui.pollsPagination = pagination;
    },
    setSearchedPollCategories: (state, action: PayloadAction<PaginatedResult<PollCategory>>) => {
      const { entities, pagination } = getReducerAppendResults(action.payload, state.entities.pollCategories, state.ui.pollCategoryPagination, (r) => r.id);

      state.entities.pollCategories = entities;
      state.ui.pollCategoryPagination = pagination;
    },
    setSearchedCurrentOperatorsPolls: (state, action: PayloadAction<PaginatedResult<Poll>>) => {
      const { entities, pagination } = getReducerReplaceResults(action.payload, state.entities.polls, state.ui.pollsPagination, (r) => r.id);

      state.entities.polls = entities;
      state.ui.operatorPollPagination = pagination;
    },
    setWorkingCreatePollValues: (state, action: PayloadAction<Partial<CreatePoll>>) => {
      state.ui.maintenance.workingCreatePoll = {
        ...state.ui.maintenance.workingCreatePoll,
        ...action.payload,
      };
    },
    setWorkingUpdatePollValues: (state, action: PayloadAction<Partial<UpdatePoll>>) => {
      state.ui.maintenance.workingUpdatePoll = {
        ...state.ui.maintenance.workingUpdatePoll,
        ...action.payload,
      };
    },
    setWorkingCreatePollCategoryValues: (state, action: PayloadAction<Partial<CreatePollCategory>>) => {
      state.ui.categoryMaintenance.workingCreatePollCategory = {
        ...state.ui.categoryMaintenance.workingCreatePollCategory,
        ...action.payload,
      };
    },
    setWorkingUpdatePollCategoryValues: (state, action: PayloadAction<Partial<UpdatePollCategory>>) => {
      state.ui.categoryMaintenance.workingUpdatePollCategory = {
        ...state.ui.categoryMaintenance.workingUpdatePollCategory,
        ...action.payload,
      };
    },
    cancelPollModification: (state) => {
      state.ui.maintenance.workingUpdatePoll = {};
      state.ui.maintenance.workingCreatePoll = {};
    },
    cancelPollCategoryModification: (state) => {
      state.ui.categoryMaintenance.workingCreatePollCategory = {};
      state.ui.categoryMaintenance.workingUpdatePollCategory = {};
    },
    onPollProgramUpdated: (state, action: PayloadAction<Poll>) => {
      state.entities.polls = {
        ...state.entities.polls,
        [action.payload.id]: action.payload,
      };

      state.ui.maintenance.workingUpdatePoll = {};
    },
    onPollUpdated: (state, action: PayloadAction<Poll>) => {
      state.entities.polls = {
        ...state.entities.polls,
        [action.payload.id]: action.payload,
      };

      state.ui.maintenance.workingUpdatePoll = {};
    },
    onPollCategoryUpdated: (state, action: PayloadAction<PollCategory>) => {
      state.entities.pollCategories = {
        ...state.entities.pollCategories,
        [action.payload.id]: action.payload,
      };

      state.ui.categoryMaintenance.workingUpdatePollCategory = {};
    },
    onPollInserted: (state, action: PayloadAction<Poll>) => {
      state.entities.polls = {
        ...state.entities.polls,
        [action.payload.id]: action.payload,
      };

      state.ui.pollsPagination.ids.push(action.payload.id);
      state.ui.maintenance.workingCreatePoll = {};
    },
    onPollCategoryInserted: (state, action: PayloadAction<PollCategory>) => {
      state.entities.pollCategories = {
        ...state.entities.pollCategories,
        [action.payload.id]: action.payload,
      };

      state.ui.pollCategoryPagination.ids.push(action.payload.id);
      state.ui.categoryMaintenance.workingCreatePollCategory = {};
    },
    onPollOptionInserted: (state, action: PayloadAction<PollOption>) => {
      state.entities.pollOptions = {
        ...state.entities.pollOptions,
        [action.payload.id]: action.payload,
      };

      state.xrefs.pollOptions[action.payload.pollId] = _.union(state.xrefs.pollOptions[action.payload.pollId], [ action.payload.id ]);
      state.ui.createdPollOptionIds = state.ui.createdPollOptionIds.concat(action.payload.id);
    },
    onPollOptionMerged: (state, action: PayloadAction<{ pollId: number; optionToKeepId: number; optionToDeleteId: number; }>) => {
      state.xrefs.pollOptions[action.payload.pollId] = _.without(state.xrefs.pollOptions[action.payload.pollId], action.payload.optionToDeleteId);
      state.ui.createdPollOptionIds = _.without(state.ui.createdPollOptionIds, action.payload.optionToDeleteId);
    },
    onPollOperatorGroupInserted: (state, action: PayloadAction<PollOperatorGroup>) => {
      state.entities.pollOperatorGroups = {
        ...state.entities.pollOperatorGroups,
        [action.payload.id]: action.payload,
      };

      state.xrefs.pollOperatorGroups[action.payload.pollId] = _.union(state.xrefs.pollOperatorGroups[action.payload.pollId], [ action.payload.id ]);
    },
    onPollResultUpdated: (state, action: PayloadAction<{ pollOption: PollOption; result: PollResult; resultSummary: PollResultSummary; }>) => {
      state.entities.pollResultSummaries = {
        ...state.entities.pollResultSummaries,
        [action.payload.resultSummary.pollResultId]: action.payload.resultSummary,
      };
    },
    onPollDeleted: (state, action: PayloadAction<Poll>) => {
      state.entities.polls = _.omit(state.entities.polls, action.payload.id);
      state.ui.pollsPagination.ids = _.without(state.ui.pollsPagination.ids, action.payload.id);
      state.ui.maintenance.workingUpdatePoll = {};
      state.ui.maintenance.workingCreatePoll = {};
    },
    onPollCategoryDeleted: (state, action: PayloadAction<PollCategory>) => {
      state.entities.pollCategories = _.omit(state.entities.pollCategories, action.payload.id);
      state.ui.pollCategoryPagination.ids = _.without(state.ui.pollCategoryPagination.ids, action.payload.id);
      state.ui.categoryMaintenance.workingCreatePollCategory = {};
      state.ui.categoryMaintenance.workingUpdatePollCategory = {};
    },
    onPollOptionDeleted: (state, action: PayloadAction<PollOption>) => {
      state.entities.pollOptions = _.omit(state.entities.pollOptions, action.payload.id);
      state.xrefs.pollOptions[action.payload.pollId] = _.without(state.xrefs.pollOptions[action.payload.pollId], action.payload.id);
    },
    onPollOperatorGroupDeleted: (state, action: PayloadAction<number>) => {
      const pollOperatorGroup = state.entities.pollOperatorGroups[action.payload];

      if(!pollOperatorGroup) {
        return;
      }

      state.entities.pollOperatorGroups = _.omit(state.entities.pollOperatorGroups, pollOperatorGroup.id);
      state.xrefs.pollOperatorGroups[pollOperatorGroup.pollId] = _.without(state.xrefs.pollOperatorGroups[pollOperatorGroup.pollId], pollOperatorGroup.id);
    },
    onPollResponseDeleted: (state, action: PayloadAction<{ pollId: number; id: number }>) => {
      const pollResultSummary = state.entities.pollResultSummaries[action.payload.id];

      if(!pollResultSummary) {
        return;
      }

      state.entities.pollResultSummaries = _.omit(state.entities.pollResultSummaries, pollResultSummary.pollResultId);
      state.xrefs.pollResultSummaries[action.payload.pollId] = _.without(state.xrefs.pollResultSummaries[action.payload.pollId], pollResultSummary.pollResultId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(commonActions.PATH_LOCATION_CHANGE, (state) => {
        state.ui.maintenance.workingCreatePoll = {};
        state.ui.maintenance.workingUpdatePoll = {};
      })
      .addCase(commonActions.AREA_LOCATION_CHANGE, () => {
        return { ...initialPollsState };
      });
  },
});
