import React, { useEffect } from 'react';
import _ from 'lodash';
import Masonry from 'react-masonry-css';

import { canLoadMore } from 'core';
import { usePathId } from 'hooks';
import { usePaginatedCurrentOperatorPolls, usePaginatedPollCategories } from 'Modules/polls/pagination';
import {
  FlexLoaderPanel,
  ListPanel,
  ListPanelHeader,
  NavMenuButton,
  OperatorPollCard,
  Panel,
  SearchInputGroup,
  SplitPanel,
  VerticalOnlyScrollPanel,
} from 'Components';
import { AnalyticsTab } from 'Pages/Analytics/AnalyticsPage';

const OperatorPollsTab = () => {
  const selectedCategoryId = usePathId('categoryId');
  const pollCategoryPagination = usePaginatedPollCategories({ searchOnMount: true });
  const { data: operatorPolls, refresh, isSearching, filterText, setFilterText } = usePaginatedCurrentOperatorPolls({ categoryId: selectedCategoryId })();

  useEffect(() => {
    refresh();
  }, [ selectedCategoryId ]);

  return (
    <SplitPanel panes={[
      {
        ratio: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
        Component: (
          <>
            <ListPanel
              Header={(
                <ListPanelHeader>Poll Categories</ListPanelHeader>
              )}
              search={{
                isLoading: pollCategoryPagination.isSearching,
                value: pollCategoryPagination.filterText,
                onChange: pollCategoryPagination.setFilterText,
              }}
              loadMore={{
                hasMore: canLoadMore(pollCategoryPagination.pagination),
                pagination: pollCategoryPagination.pagination,
                onClick: pollCategoryPagination.loadMore,
              }}
            >
              <div className="p-1">
                <NavMenuButton exact to={`/analytics/${AnalyticsTab.Polls}`}>
                  View All
                </NavMenuButton>

                <hr />

                {
                  _.map(pollCategoryPagination.data, (pollCategory) => (
                    <NavMenuButton key={pollCategory.id} to={`/analytics/${AnalyticsTab.Polls}/${pollCategory.id}`}>
                      {pollCategory.name}
                    </NavMenuButton>
                  ))
                }
              </div>
            </ListPanel>
          </>
        ),
      },
      {
        ratio: { xs: 1, sm: 2, md: 3, lg: 5, xl: 5 },
        Component: (
          <Panel className="px-2" childSpacingY={1}>
            <SearchInputGroup
              placeholder="Search Polls..."
              className="form-control-lg"
              value={filterText}
              isLoading={isSearching}
              onChange={setFilterText}
            />

            <FlexLoaderPanel isLoading={isSearching}>
              <VerticalOnlyScrollPanel>
                <Masonry breakpointCols={{ default: 2, 1190: 1 }} className="row" columnClassName="task-masonry-cell">
                  {
                    _.map(operatorPolls, (poll) => {
                      return (
                        <React.Fragment key={poll.id}>
                          <OperatorPollCard poll={poll} />
                        </React.Fragment>
                      );
                    })
                  }
                </Masonry>
              </VerticalOnlyScrollPanel>
            </FlexLoaderPanel>
          </Panel>
        ),
      },
    ]}
    />
  );
};

export default OperatorPollsTab;
