import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { operatorGroupsActions, operatorGroupsSelectors } from '../Modules/operatorGroups/index';
import useStateSelector from './useStateSelector';

const useAllOperatorGroups = () => {
  const dispatch = useDispatch();
  const allOperatorGroups = useSelector(operatorGroupsSelectors.maintenance.allOperatorGroups);
  const isLoadingAllOperatorGroups = useStateSelector(state => state.operatorGroups.loading.isLoadingAllOperatorGroups);

  useEffect(() => {
    dispatch(operatorGroupsActions.async.getOperatorGroups());
  }, []);

  return [ allOperatorGroups, isLoadingAllOperatorGroups ] as const;
};

export default useAllOperatorGroups;
