import React, { useEffect } from 'react';
import { useCurrentUserPermissionChecker } from '../../hooks/index';
import { Permission } from '../../Modules/roles/index';
import AdminShareBoardPage from './AdminShareBoardPage';
import NonAdminShareBoardPage from './NonAdminShareBoardPage';

const ShareBoardPage = () => {
  const globalShareBoardMaintenance = useCurrentUserPermissionChecker()(Permission.GlobalShareBoardMaintenance);

  useEffect(() => {
    document.body.classList.add('sponsor-bar-enabled');

    return () => {
      document.body.classList.remove('sponsor-bar-enabled');
    };
  }, []);

  return globalShareBoardMaintenance ? (
    <AdminShareBoardPage />
  ) : (
    <NonAdminShareBoardPage />
  );
};

export default ShareBoardPage;
