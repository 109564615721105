import React, { ReactNode } from 'react';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Button, ButtonProps } from 'reactstrap';

interface Props extends ButtonProps {
  to: string;
  exact?: boolean;
  children: ReactNode;
}

const NavMenuButton = ({ children, to, exact, ...props }: Props) => {
  const location = useLocation();
  const path = exact ? location.pathname === to : _.startsWith(location.pathname, to);

  return (
    <Button
      {...props}
      color={path ? 'primary' : 'light'}
      block
      tag={Link}
      to={to}
    >
      {children}
    </Button>
  );
};

export default NavMenuButton;
