import React from 'react';
import { AssignedTaskModel } from '../../../models';
import BaseDashboardTask from '../Components/BaseDashboardTask';

interface LocalProps {
  task: AssignedTaskModel;
  messageSafetyCoach?: () => void;
  uploadFile?: () => void;
  onTaskClick: (task: AssignedTaskModel) => void;
}

const DashboardTask = ({ task, ...props }: LocalProps) => {
  return (
    <>
      <BaseDashboardTask
        task={task}
        includeAction
        messageSafetyCoach={props.messageSafetyCoach}
        uploadFile={props.uploadFile}
        onTaskClick={props.onTaskClick}
      />
    </>
  );
};

export default React.memo(DashboardTask);
