import { ContentState, convertFromRaw, EditorState, convertToRaw } from 'draft-js';

export const getEditorStateFromString = (input?: string) => {
  if (!input) {
    return EditorState.createEmpty();
  }

  try {
    const parsed = JSON.parse(input);
    return EditorState.createWithContent(convertFromRaw(parsed));
  } catch (e) {
    console.error({ e });
  }
  return EditorState.createWithContent(ContentState.createFromText(input));
};

export const getTextFromEditorState = (editorState: EditorState) => {
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  return blocks.map(block => (!block.text.trim() && '\n') || block.text).filter(t => t.trim()).join('\n');
};
