import React from 'react';
import { RootState } from '../../Modules/reducers';
import { connect } from 'react-redux';
import BaseTable from '../../Components/BaseTable/BaseTable';
import { Column } from 'react-table';
import { ProgramFolder } from '../../Modules/programs/models';
import { deleteProgramFolder } from '../../Modules/programs/actions';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

interface LocalProps {
  folders: ProgramFolder[];
  startEditingProgramFolder: (programFolder: ProgramFolder) => void;
}

interface StateProps {}

interface DispatchProps {
  deleteProgramFolder: typeof deleteProgramFolder;
}

type Props = LocalProps & StateProps & DispatchProps;

class ProgramFoldersTable extends React.Component<Props> {
  private deleteFolder = (programFolder: ProgramFolder) => {
    (async () => {
      const { dismiss } = await Swal.fire({
        title: 'Delete Folder',
        text: 'Are you sure you want to delete this folder?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      });

      if (dismiss) {
        return;
      }

      this.props.deleteProgramFolder(programFolder.programFolderId);
    })();
  }

  private columns: Column<ProgramFolder>[] = [
    {
      Header: '',
      id: 'edit',
      className: 'text-center',
      width: 32,
      Cell: ({ original }: { original: ProgramFolder }) => {
        return (
          <Button color="anchor" size="sm" onClick={() => this.props.startEditingProgramFolder(original)}>
            <i className="mdi mdi-pencil" />
          </Button>
        );
      },
    },
    {
      Header: 'Directory',
      id: 'directoryPath',
      accessor: t => t.directoryPath,
    },
    {
      Header: '',
      id: 'delete',
      className: 'text-center',
      width: 32,
      Cell: ({ original }: { original: ProgramFolder }) => {
        return (
          <Button color="anchor" size="sm" onClick={() => this.deleteFolder(original)}>
            <i className="mdi mdi-trash-can" />
          </Button>
        );
      },
    },
  ];

  public render () {
    const { folders } = this.props;

    return (
      <div className="bg-white">
        <BaseTable
          data={folders}
          columns={this.columns}
          minRows={0}
          defaultSorted={[ { id: 'directoryPath', desc: false } ]}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  deleteProgramFolder,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ProgramFoldersTable);
