import { Response } from 'superagent';

import { Action } from 'models';

export const commonActions = {
  INIT: '@@init',
  API: 'AVSolutions/common/AVSolutions_API',
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  PATH_LOCATION_CHANGE: 'PATH_LOCATION_CHANGE',
  AREA_LOCATION_CHANGE: 'AREA_LOCATION_CHANGE',
  API_DEPENDANT_GETS: 'AVSolutions/common/AVSolutions_API_DEPENDANT_GETS',
  DOWNLOAD_FILE: 'AVSolutions/common/DOWNLOAD_FILE',
  LOGOUT_USER: 'AVSolutions/auth/logoutUser',
  UNAUTHORIZED: 'AVSolutions/auth/unauthorized',
  TOS_NOT_AGREED_UPON: 'AVSolutions/auth/tosNotAgreedUpon',
};

export const unauthorized = (): Action => {
  return {
    type: commonActions.UNAUTHORIZED,
  };
};

export const tosNotAgreedUpon = (): Action => {
  return {
    type: commonActions.TOS_NOT_AGREED_UPON,
  };
};

export const downloadFileFromResponse = (data: any, response: Response): Action => {
  return {
    type: commonActions.DOWNLOAD_FILE,
    payload: { file: data, filename: response.get('content-disposition').split('filename=')[1].split(';')[0].replace(/"/g, '') },
  };
};
