import React, { useMemo } from 'react';
import _ from 'lodash';
import { getEditorStateFromString } from 'core/draft';
import DraftEditor from '../Editor/DraftEditor';

interface Props {
  content?: string;
}

const DraftViewer = ({ content }: Props) => {
  const editorState = useMemo(() => getEditorStateFromString(content), [ content ]);
  return (
    <DraftEditor toolbarHidden readOnly editorState={editorState} onChange={_.noop} />
  );
};

export default DraftViewer;
