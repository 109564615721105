import React from 'react';
import _ from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { OperatorAuditModel, OperatorAuditTasks, OperatorAuditWeekTasksModel } from 'models';
import WithCategories from 'Components/WithCategories';
import AddTaskDropdown from 'Components/AddTaskDropdown/AddTaskDropdown';

import WithModifyTaskModal from './WithModifyTaskModal';
import CategoryTaskGroup from './CategoryTaskGroup';

interface Props {
  operatorAudit: OperatorAuditModel;
  operatorAuditTasks: OperatorAuditTasks;
  auditWeek: OperatorAuditWeekTasksModel;
}

const WeekTasks = ({ operatorAudit, auditWeek }: Props) => {
  const { weekIndex, startDate, displayStartDate, displayEndDate, isCurrentWeek } = auditWeek;

  return (
    <WithModifyTaskModal operatorAudit={operatorAudit} startDate={startDate}>
      {({ showCreateTaskModal, showCreateParentTaskModal }) => (
        <Row style={{ marginTop: 10 }}>
          <Col md={3} lg={2}>
            <Card className="outer text-center" style={{ marginBottom: 0 }}>
              {isCurrentWeek && (
                <div className="inner d-flex p-2" title="This is the current week">
                  <i className="mdi mdi-star"/>
                </div>
              )}
              <CardBody>
                <div><strong>Week {weekIndex + 1}</strong></div>
                <div>{displayStartDate}</div>
                <div>to</div>
                <div>{displayEndDate}</div>
              </CardBody>
            </Card>

            <AddTaskDropdown
              block
              addTask={showCreateTaskModal}
              addParentTask={showCreateParentTaskModal}
            />
          </Col>
          <Col md={9} lg={10}>
            {/* @ts-ignore */}
            <WithCategories appendUndefined>
              {({ sortedCategories }) => (
                <>
                  {
                    _.map(sortedCategories, (category, i) => {
                      const categoryTasks = _.filter(auditWeek.tasks, t => (t.categoryId || undefined) === category?.categoryId);
                      const isLast = _.size(sortedCategories) - 1 === i;

                      if (_.isEmpty(categoryTasks)) {
                        return null;
                      }

                      return (
                        <React.Fragment
                          key={category?.categoryId || 'undefined'}
                        >
                          <Row>
                            <Col md={12}>
                              <CategoryTaskGroup
                                // @ts-ignore
                                category={category}
                                // @ts-ignore
                                tasks={categoryTasks}
                                // @ts-ignore
                                operatorAudit={operatorAudit}
                              />
                            </Col>
                          </Row>

                          {!isLast && (
                            <hr/>
                          )}
                        </React.Fragment>
                      );
                    })
                  }
                </>
              )}
            </WithCategories>

          </Col>
        </Row>
      )}
    </WithModifyTaskModal>
  );
};

export default WeekTasks;
