import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { useStateSelector } from 'hooks';
import { countMyOperatorMessageNotifications } from 'Modules/userNotifications/actions';
import { getCurrentUserOperatorMessageNotificationCount } from 'Modules/userNotifications/selectors';

interface Props {
  openSidebar: () => void;
}

const OperatorMessageUserNotificationButton = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const isLoadingMyNotificationCount = useStateSelector(state => state.userNotifications.loading.isLoadingMyNotificationCount);
  const currentUserOperatorMessageNotificationCount = useStateSelector(getCurrentUserOperatorMessageNotificationCount);

  useEffect(() => {
    dispatch(countMyOperatorMessageNotifications());
  }, []);

  return (
    <button className="button-menu-mobile waves-effect waves-light" onClick={props.openSidebar}>
      <i className="mdi mdi-message-text-outline"/>
      <span
        className={classnames('badge rounded-circle notification-icon-badge', { 'badge-danger': (currentUserOperatorMessageNotificationCount || 0) > 0 })}>
        {isLoadingMyNotificationCount && (
          <i className="mdi mdi-spin mdi-refresh"/>
        )}
        {!!(!isLoadingMyNotificationCount && currentUserOperatorMessageNotificationCount) && (
          <>{currentUserOperatorMessageNotificationCount}</>
        )}
      </span>
    </button>
  );
};

export default OperatorMessageUserNotificationButton;
