import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { getNumberMappedEntities } from '../../helpers/entityHelpers';
import _ from 'lodash';
import { FileTree } from '../library/models';
import { ReferenceMaterial } from './models';

export const allReferenceMaterials = createSelector([
  (state: RootState) => state.referenceMaterials.entities.referenceMaterials,
  (state: RootState) => state.referenceMaterials.ui.allReferenceMaterialIds,
], (
  referenceMaterials,
  allReferenceMaterialIds
) => getNumberMappedEntities(referenceMaterials, allReferenceMaterialIds));

export const selectedReferenceMaterials = createSelector([
  (state: RootState) => state.referenceMaterials.entities.referenceMaterials,
  (state: RootState) => state.referenceMaterials.ui.selectedReferenceMaterials,
], (
  referenceMaterials,
  selectedReferenceMaterials
) => getNumberMappedEntities(referenceMaterials, selectedReferenceMaterials));

export const selectReferenceFileTree = createSelector([
  allReferenceMaterials,
  (state: RootState) => state.referenceMaterials.ui.createdFolders,
], (
  allReferenceMaterials,
  createdFolders
) => {
  const fileTree = new FileTree<ReferenceMaterial>({ filePathSelector: f => f.filePath });

  fileTree.addAllFiles(allReferenceMaterials);

  _.each(createdFolders, (folder) => {
    fileTree.addFolder(folder);
  });

  return fileTree;
});
