import { Dispatch } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { callApi } from 'api';
import { PaginatedRequest, PaginatedResult } from 'core';
import { PaginatedSearchResult } from 'helpers/pagination';
import { RootState } from 'Modules/reducers';

import operatorGroupsActions from './actions';
import {
  CreateOperatorGroup,
  CreateOperatorGroupMessage,
  OperatorGroup,
  OperatorGroupMessageFileSummary,
  OperatorGroupOperatorXref,
  OperatorGroupSearchMessage,
  OperatorMessageGroupFileSearchRequest,
  UpdateOperatorGroup,
  UpdateOperatorGroupOperatorXref,
} from './models';
import { operatorGroupsSlice, OperatorGroupsState } from './slice';

const operatorGroupsLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: OperatorGroupsState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(operatorGroupsSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getOperatorGroups = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllOperatorGroups = isLoading),
    getRequest: (superagent) => superagent.get('/api/v1/operatorGroups'),
    onSuccess: (data: OperatorGroup[]) => {
      dispatch(operatorGroupsActions.setAllOperatorGroups(data));
    },
  });
};

export const getOperatorGroup = (operatorGroupId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingAllOperatorGroups = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operatorGroups/${operatorGroupId}`),
    onSuccess: (data: OperatorGroup) => {
      dispatch(operatorGroupsActions.setOperatorGroup(data));
    },
  });
};

export const getOperatorGroupXrefs = (operatorGroupId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingOperatorGroupXrefs = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operatorGroups/${operatorGroupId}/xrefs`),
    onSuccess: (data: OperatorGroupOperatorXref[]) => {
      dispatch(operatorGroupsActions.setOperatorGroupOperatorXrefs({
        operatorGroupId,
        xrefs: data,
      }));
    },
  });
};

export const searchOperatorGroupMessages = (operatorGroupId: number, request: PaginatedRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isSearchingForOperatorMessages = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operatorGroups/${operatorGroupId}/messages/search`).query(request),
    onSuccess: (data: PaginatedSearchResult<OperatorGroupSearchMessage>) => {
      dispatch(operatorGroupsActions.setSearchedOperatorGroupMessages({
        operatorGroupId,
        request,
        result: data,
      }));
    },
  });
};

export const searchOperatorGroupMessageFiles = (operatorGroupId: number, request: OperatorMessageGroupFileSearchRequest): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isSearchingForOperatorMessages = isLoading),
    getRequest: (superagent) => superagent.post(`/api/v1/operatorGroups/${operatorGroupId}/messages/files/search`).send(request),
    onSuccess: (data: PaginatedResult<OperatorGroupMessageFileSummary>) => {
      dispatch(operatorGroupsActions.setSearchedOperatorGroupMessageFiles({
        operatorGroupId,
        request,
        result: data,
      }));
    },
  });
};

export const getCurrentUserOperatorGroupsForOperator = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingCurrentUserOperatorGroups = isLoading),
    getRequest: (superagent) => superagent.get('/api/v1/users/current/operatorGroups'),
    onSuccess: (data: OperatorGroup[]) => {
      dispatch(operatorGroupsActions.setCurrentUserOperatorGroups({ operatorGroups: data }));
    },
  });
};

export const updateOperatorGroup = (updateOperatorGroup: UpdateOperatorGroup): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingOperatorGroup = isLoading),
    getRequest: (superagent) => superagent.put('/api/v1/operatorGroups').send(updateOperatorGroup),
    onSuccessToast: (toastr) => toastr.success('Successfully saved operator group'),
    onSuccess: (data: OperatorGroup) => {
      dispatch(operatorGroupsActions.operatorGroupUpdated(data));
    },
  });
};

export const insertOperatorGroup = (createOperatorGroup: CreateOperatorGroup): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingOperatorGroup = isLoading),
    getRequest: (superagent) => superagent.post('/api/v1/operatorGroups').send(createOperatorGroup),
    onSuccessToast: (toastr) => toastr.success('Successfully saved operator group'),
    onSuccess: (data: OperatorGroup) => {
      dispatch(operatorGroupsActions.operatorGroupInserted(data));
    },
  });
};

export const insertOperatorGroupMessage = (createOperatorGroupMessage: CreateOperatorGroupMessage): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingOperatorGroup = isLoading),
    getRequest: (superagent) => superagent.post(`/api/v1/operatorGroups/${createOperatorGroupMessage.operatorGroupId}/messages`).send(createOperatorGroupMessage),
    onSuccess: (data: OperatorGroupSearchMessage) => {
      dispatch(operatorGroupsActions.operatorGroupMessageInserted(data));
    },
  });
};

export const getOperatorGroupSearchMessage = (operatorGroupId: number, operatorGroupMessageId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi<OperatorGroupSearchMessage>({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingOperatorGroupSearchMessage = isLoading),
    getRequest: (superagent) => superagent.get(`/api/v1/operatorGroups/${operatorGroupId}/messages/${operatorGroupMessageId}`),
  });
};

export const updateOperatorGroupOperator = (operatorGroupId: number, request: UpdateOperatorGroupOperatorXref): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isUpdatingOperatorGroupOperator = isLoading),
    getRequest: (superagent) => superagent.put(`/api/v1/operatorGroups/${operatorGroupId}/xrefs`).send(request),
    onSuccessToast: (toastr) => toastr.success('Successfully saved operator'),
    onSuccess: (data: OperatorGroupOperatorXref) => {
      dispatch(operatorGroupsActions.operatorGroupOperatorUpdated(data));
    },
  });
};

export const insertOperatorGroupOperator = (operatorGroupId: number, operatorId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isInsertingOperatorGroupOperator = isLoading),
    getRequest: (superagent) => superagent.post(`/api/v1/operatorGroups/${operatorGroupId}/xrefs`).send({ operatorGroupId, operatorId }),
    onSuccessToast: (toastr) => toastr.success('Successfully added operator to group'),
    onSuccess: (data: OperatorGroupOperatorXref) => {
      dispatch(operatorGroupsActions.operatorGroupOperatorInserted(data));
    },
  });
};

export const deleteOperatorGroup = (operatorGroupId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingOperatorGroup = isLoading),
    getRequest: (superagent) => superagent.del(`/api/v1/operatorGroups/${operatorGroupId}`),
    onSuccessToast: (toastr) => toastr.success('Successfully deleted operator group'),
    onSuccess: () => {
      dispatch(operatorGroupsActions.operatorGroupDeleted(operatorGroupId));
    },
  });
};

export const deleteOperatorGroupOperatorXref = (operatorGroupId: number, operatorGroupOperatorXrefId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    apiToken: getState().site.authUser?.token,
    loading: operatorGroupsLoadingHandler(dispatch, (state, isLoading) => state.loading.isDeletingOperatorGroupOperator = isLoading),
    getRequest: (superagent) => superagent.del(`/api/v1/operatorGroups/${operatorGroupId}/xrefs/${operatorGroupOperatorXrefId}`),
    onSuccessToast: (toastr) => toastr.success('Successfully deleted operator from group'),
    onSuccess: () => {
      dispatch(operatorGroupsActions.operatorGroupOperatorDeleted({ operatorGroupId, operatorGroupOperatorXrefId }));
    },
  });
};
