import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import AuditTemplateSearch from 'AviationSafetySolutions/AuditTemplateSearch';
import { useOnModalShow, useStateSelector } from 'hooks';
import { AuditTemplate } from 'models';
import * as auditsActions from 'Modules/audits/actions';
import { FlexRow, LoadableButton } from 'Components';

interface Props {
  show: boolean;
  operatorAuditId: number;
  onSyncComplete?: () => void;
  hide: () => void;
}

const SyncAuditTemplateToAuditModal = ({ show, operatorAuditId, ...props }: Props) => {
  const dispatch = useDispatch();

  const [ selectedAuditTemplate, setSelectedAuditTemplate ] = useState<AuditTemplate>();
  const isSyncingAuditTemplateToAudit = useStateSelector(state => state.audits.loading.isSyncingAuditTemplateToAudit);

  useOnModalShow(show)
    .then(() => {
      setSelectedAuditTemplate(undefined);
    });

  function sync() {
    if(!selectedAuditTemplate) {
      toastr.error('You must select a template to sync to this audit.');
      return;
    }

    dispatch(auditsActions.syncAuditTemplateToAudit(operatorAuditId, selectedAuditTemplate.auditTemplateId, props.onSyncComplete));
  }


  return (
    <Modal isOpen={show} size="lg" toggle={props.hide}>
      <ModalHeader>Sync Tasks from Template</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Audit Template to Apply:</Label>
          <AuditTemplateSearch
            value={selectedAuditTemplate}
            onChange={(auditTemplate: AuditTemplate | null) => setSelectedAuditTemplate(auditTemplate || undefined)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <FlexRow fill justifyBetween alignCenter>
          <div />
          <div>
            <LoadableButton
              onClick={sync}
              isLoading={isSyncingAuditTemplateToAudit}
              color="primary">
              Sync
            </LoadableButton>
          </div>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};

export default SyncAuditTemplateToAuditModal;
