import { Dispatch } from '@reduxjs/toolkit';

import appConfig from 'config';
import { callApi } from 'api/ApiDao';

import { siteSlice, SiteState } from './slice';
import { ActiveUser, PageModel } from './models';
import siteActions from './actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { SelectedOperator } from '../operators/models';

const siteLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: SiteState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(siteSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const getPageModel = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: siteLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingPageModel = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/site`),
    onSuccess: (data: PageModel) => {
      dispatch(siteActions.setPageModel(data));
    },
  });
};

export const getActiveUsers = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: siteLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingPageModel = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/activeUsers`),
    onSuccess: (data: ActiveUser[]) => {
      dispatch(siteActions.setActiveUsers(data));
    },
  });
};

export const getUserSelectedOperator = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: siteLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingPageModel = isLoading),
    apiToken: getState().site.authUser?.token,
    useDefaultErrorHandlingFeedback: false,
    getRequest: (superagent) => superagent.get(`${appConfig.baseUrl}/api/v1/userSelectedOperator/current`),
    onSuccess: (data: SelectedOperator | undefined) => {
      dispatch(siteActions.setSelectedOperator(data));
    },
    onFailure: (response) => {
      if(response.notFound) {
        dispatch(siteActions.setOperatorNeedsToBeSelected(true));
      }
    },
  }).catch(() => {

  });
};

export const setUserSelectedOperator = (operatorId: number): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
  return callApi({
    loading: siteLoadingHandler(dispatch, (state, isLoading) => state.loading.isLoadingPageModel = isLoading),
    apiToken: getState().site.authUser?.token,
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/v1/userSelectedOperator/current/${operatorId}`),
    onSuccess: () => {
      window.location.reload();
    },
  });
};
