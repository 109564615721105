import React from 'react';
import { TaskStatusModel } from '../../models';
import { baseTableOptions } from '../../helpers/tableHelper';
import ReadableTimestamp from '../../Components/ReadableTimestamp';
import BaseTable from '../../Components/BaseTable/BaseTable';

interface LocalProps {
  history: TaskStatusModel[];
}

const TaskHistoryTable = (props: LocalProps) => {
  const { history } = props;

  const tableOptions: any = {
    ...baseTableOptions,
    minRows: 10,
    data: history || [],
    columns: [
      {
        Header: 'Created At',
        width: 180,
        accessor: 'createdTimestamp',
        Cell: ({ original }: { original: TaskStatusModel }) => (
          <span><ReadableTimestamp value={original.createdTimestamp}/></span>
        ),
      },
      {
        Header: 'History',
        accessor: 'statusDescription',
        width: 135,
      },
      {
        Header: 'User',
        accessor: 'createdByUser.displayName',
        width: 185,
      },
      {
        Header: 'Update',
        accessor: 'statusUpdate',
      },
    ],
    defaultSorted: [ { id: 'createdTimestamp', desc: true } ],
  };

  return (
    <BaseTable {...tableOptions} />
  );
};

export default TaskHistoryTable;
